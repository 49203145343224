import { EnumPages } from "../../../commons/EnumPages";
import { EnumPersonType, sessionValues } from "../../../commons/EnumsGeneral";
import { checkIsNullUndefined, getErrorMessage } from "../../../commons/Utils";
import { controlUserMovement, getApplicationOid, getReplaceApplicationOid, getReplaceMode, getSessionItem, setLanguage, setReplaceMode, setSessionItem, setUserSession } from "../../../services/Commons";
import { GetEnterpriseSelect, GetSSO, PostEnterprise, PostSubscriptionEnterprise, PostSubscriptionUser, PutProfileUser } from "../../../services/ReasonService";
import { PutEnterpriseData, } from "../../../services/EnterpriseDataCallsService";
import { GetMasterLanguage, } from "../../../services/MasterCallsService";
import { mountEnterpriseObject, mountUserObject } from "./FormFunctions";
import { auxFunction } from "./PreparePageFunctions";
import { DeleteUtilitiesCorreosIdEnterprise } from "../../../services/UtilitiesCallsService";

export const manageSavePath = (auxGetters, auxFunctions, auxConst, dataControl) => {
    if (dataControl.pendingData.length == 0) {
        handlePath(auxGetters, auxFunctions, auxConst);
    } else {
        dataControl.setShowPendingData(true);
    };
};

const handlePath = (auxGetters, auxFunctions, auxConst) => {
    auxFunctions.setNextCircularProgress(true);
    // Lógica para saber por qué path ir...
    let auxPath = auxConst.forUser ? userPath : enterprisePath;
    let isCid = getReplaceApplicationOid() == process.env.REACT_APP_APP_OID;
    if (auxConst.isRegistry && !isCid) {
        let auxApplicationOid = getReplaceApplicationOid();
        if (auxConst.isPF === "true") {
            PostSubscriptionUser(auxApplicationOid).then((response) => {
                if (response && (!response.Status || response.Status == 202)) {
                    auxPath(auxGetters, auxConst, auxFunctions);
                };
            })
        } else {
            auxPath(auxGetters, auxConst, auxFunctions);
        };
    } else {
        auxPath(auxGetters, auxConst, auxFunctions);
    };
};

const userPath = (auxGetters, auxConst, auxFunctions) => {
    let auxiliarUserData = mountUserObject(auxGetters.getBothFormValuesObject, auxGetters.getUserFormValuesObject);
    let auxFormSettersObject = auxGetters.getBothFormSettersObject(EnumPersonType.User);
    let auxUserFormSettersObject = auxGetters.getUserFormSetersObject();
    let auxAddressSettersObject = auxGetters.getBothAddressSettersObject(EnumPersonType.User);
    PutProfileUser(auxiliarUserData).then((response) => {
        if (response && !response.Status) {
            auxFormSettersObject.SetOriginalData(response);
            if (!auxConst.isRegistry) {
                //Alerta
                auxFunctions.finishOperation("success", auxFunctions.doTraduction("userDataSuccessUpdate"));
                sessionStorage.removeItem("incompleteUserData");
                sessionStorage.removeItem("verifyEmail");
                sessionStorage.removeItem("verifyPhone");
                GetMasterLanguage(response.idLanguage).then((response1) => {
                    setLanguage(response1[0].code, auxConst.i18n);
                    if (!checkIsNullUndefined(auxFunctions.setUpdateUser)) {
                        auxFunctions.setUpdateUser(true);
                    };
                    auxUserFormSettersObject.SetEditableEmails(response.userEmail);
                    auxUserFormSettersObject.SetEditablePhones(response.userPhone);
                    auxUserFormSettersObject.SetEditableDocuments(response.userDocument);
                    auxAddressSettersObject.SetEditableAddresses(response.userAddress);
                    if (auxConst.forEnterprise) {
                        handleIsTitular(auxGetters, auxConst, auxFunctions);
                    } else {
                        let auxAppOid = getReplaceMode() == "true" ? getReplaceApplicationOid() : getApplicationOid();
                        manageNextUserStep(auxAppOid, auxConst, auxFunctions);
                    };
                });
            } else if (auxConst.isPF == "true") {
                manageNextUserStep(getReplaceApplicationOid(), auxConst, auxFunctions);
            } else {
                enterprisePath(auxGetters, auxConst, auxFunctions);
            };
        } else {
            //Alerta
            auxFunctions.finishOperation("error", getErrorMessage(response, auxFunctions.doTraduction));
            auxFunctions.setNextCircularProgress(false);
        };
    });
};

const manageNextUserStep = (appOid, auxConst, auxFunctions) => {
    // Temporal para ACM *****
    let isNoToken = getSessionItem(sessionValues.isNoToken);
    if (isNoToken == "true") {
        auxFunctions.setOpenACMDialog(true);
    } else if (appOid !== process?.env?.REACT_APP_APP_OID) {
        if (auxConst.isRegistry || !checkIsNullUndefined(getSessionItem(sessionValues.redirectURI))) {
            doSSO(appOid, auxConst, auxFunctions);
        } else {
            if (sessionStorage.getItem("verifyDocument") === "true") {
                auxFunctions.setNeedVerifyDocPopup(true);
                auxFunctions.setOpenDialog(true);
            } else {
                controlUserMovement(false, auxFunctions.finishOperation, auxFunctions.doTraduction);
            };
        };
    } else {
        setReplaceMode(false);
        if (!auxConst.mainPage) {
            controlUserMovement(true, auxFunctions.finishOperation, auxFunctions.doTraduction);
            // goToMain();
        } else {
            auxFunctions.setResetPage(!auxConst.resetPage);
        }
        auxFunctions.setNextCircularProgress(false);
    };
};

const doSSO = (auxApplicationOid, auxConst, auxFunctions) => {
    if (sessionStorage.getItem("verifyDocument") === "true") {
        auxFunctions.setNeedVerifyDocPopup(true);
        auxFunctions.setOpenDialog(true);
    } else {
        GetSSO(auxApplicationOid).then((response) => {
            if (response && (!response.Status || response.Status == 202)) {
                setUserSession(response, auxConst.i18n, auxApplicationOid).then(() => {
                    setReplaceMode(false);
                    controlUserMovement(false, auxFunctions.finishOperation, auxFunctions.doTraduction);
                })
            } else {
                //Alerta
                auxFunctions.finishOperation("error", getErrorMessage(response, auxFunctions.doTraduction));
                auxFunctions.setNextCircularProgress(false);
            };
        });
    };
};

const handleIsTitular = (auxGetters, auxConst, auxFunctions) => {
    if (auxConst.pendingDataPage && !auxConst.isTitular) {
        auxFunctions.setOpenNoHostDialog(true);
    } else {
        enterprisePath(auxGetters, auxConst, auxFunctions);
    };
};

const enterprisePath = (auxGetters, auxConst, auxFunctions) => {
    let auxiliarEnterpriseData = mountEnterpriseObject(auxGetters.getBothFormValuesObject, auxGetters.getBothAddressValuesObject, auxConst.idEnterprise);

    if (checkIsNullUndefined(auxConst.idEnterprise)) {
        PostEnterprise(auxiliarEnterpriseData).then((response) => {
            if (response && !response.Status) {
                auxFunctions.finishOperation("success", auxFunctions.doTraduction("enterpriseDataCreated"));
                sessionStorage.setItem("pendingEnterprise", false);
                auxiliarEnterpriseData.id = parseInt(response, 10);
                if (!checkIsNullUndefined(auxFunctions.setIdEnterprise)) {
                    auxFunctions.setIdEnterprise(response);
                };
                manageNextEnterpriseStep1(response, auxiliarEnterpriseData, auxConst, auxFunctions);
            } else {
                //Alerta
                auxFunctions.finishOperation("error", getErrorMessage(response, auxFunctions.doTraduction));
                auxFunctions.setNextCircularProgress(false);
            };
        });
    } else {
        enterprisePutManager(auxiliarEnterpriseData, auxConst.idEnterprise, auxFunctions, auxConst);
    };
};

const manageNextEnterpriseStep1 = (response, auxiliarEnterpriseData, auxConst, auxFunctions) => {
    let appOid = getReplaceMode() == "true" ? getReplaceApplicationOid() : getApplicationOid();
    if (appOid !== process?.env?.REACT_APP_APP_OID) {
        GetEnterpriseSelect(response).then((response1) => {
            if (response1 && (!response1.Status || response1.Status == 202)) {
                setUserSession(response1, auxConst.i18n, appOid).then(() => {
                    manageNextEnterpriseStep2(appOid, response, auxiliarEnterpriseData, auxFunctions, auxConst);
                });
            } else {
                auxFunctions.finishOperation("error", getErrorMessage(response1, auxFunctions.doTraduction));
                auxFunctions.setNextCircularProgress(false);
            };
        });
    } else {
        enterprisePutManager(auxiliarEnterpriseData, response, auxFunctions, auxConst);
    };
};

const manageNextEnterpriseStep2 = (appOid, response, auxiliarEnterpriseData, auxFunctions, auxConst) => {
    if (getReplaceMode() == "true") {
        PostSubscriptionEnterprise(appOid, response).then((response2) => {
            if (response2 && (!response2.Status || response2.Status == 202)) {
                enterprisePutManager(auxiliarEnterpriseData, response, auxFunctions, auxConst);
            } else {
                auxFunctions.finishOperation("error", getErrorMessage(response, auxFunctions.doTraduction));
                auxFunctions.setNextCircularProgress(false);
            };
        })
    } else {
        enterprisePutManager(auxiliarEnterpriseData, response, auxFunctions, auxConst);
    };
};

const enterprisePutManager = (auxiliarEnterpriseData, auxIdEnterprise, auxFunctions, auxConst) => {
    let appOid = getReplaceMode() == "true" ? getReplaceApplicationOid() : getApplicationOid();
    let isCorreosID = appOid == process?.env?.REACT_APP_APP_OID;
    PutEnterpriseData(auxiliarEnterpriseData, auxIdEnterprise, true).then(
        (response) => {
            if (response && !response.Status) {
                auxFunctions.setEnterpriseOriginalData(response);
                if (!isCorreosID) {
                    if (getReplaceMode() == "true") {
                        doSSO(appOid, auxConst, auxFunctions);
                    } else {
                        if (sessionStorage.getItem("verifyDocument") === "true") {
                            auxFunctions.setNeedVerifyDocPopup(true);
                            auxFunctions.setOpenDialog(true);
                        } else {
                            controlUserMovement(false, auxFunctions.finishOperation, auxFunctions.doTraduction);
                        };
                    };
                } else {
                    if (!auxConst.isRegistry) {
                        let auxUpdateFunction = !checkIsNullUndefined(auxFunctions.setUpdateEnterprises) ? auxFunctions.setUpdateEnterprises : auxFunction;
                        auxUpdateFunction(true);
                        auxFunctions.history.push(
                            "/Index/" + auxIdEnterprise + "/" + EnumPages.EnterpriseData
                        );
                        auxFunctions.setNextCircularProgress(false);
                        auxFunctions.setResetPage(!auxConst.resetPage);
                    } else {
                        setReplaceMode(false);
                        controlUserMovement(true, auxFunctions.finishOperation, auxFunctions.doTraduction);
                    };
                };
            } else {
                //Borramos la empresa para que al volver a ejecutar no se creen duplicadas
                if (auxConst.subscriptionPage) {
                    DeleteUtilitiesCorreosIdEnterprise(auxIdEnterprise).then(() => {
                        auxFunctions.finishOperation("error", getErrorMessage(response, auxFunctions.doTraduction));
                        auxFunctions.setNextCircularProgress(false);
                    });
                } else {
                    auxFunctions.finishOperation("error", getErrorMessage(response, auxFunctions.doTraduction));
                    auxFunctions.setNextCircularProgress(false);
                }
            };
        }
    );
};