import { makeStyles } from "@material-ui/core/styles";

export const StylesResetPassword = makeStyles((theme) => ({
  ErrorTypography: {
    color: "#F32735",
    marginLeft: "0.3rem",
  },

  GridHelperContent: {
    marginTop: "1rem",
    paddingBottom: "1rem",
    padding: theme.spacing(2),
  },

  OtpTextField: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },

  linkHelper: {
    fontSize: "0.8rem",
    color: "#002453",
    cursor: "pointer",
    padding: "0px",
  },

  gridIdentity: {
    // display: "flex",
    margin: "auto"
  },
  textDivLeft:{
    textAlign: "left",
    marginBottom:"1em"
  }
}));
