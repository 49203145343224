import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Paper } from "@material-ui/core";
import { CorreosIdStepper } from "../../components/Stepper/Stepper";
import { BackgroundLogin } from "../../components/Templates/BackgroundLogin";
import { PublicBackGroundPage } from "../../components/Templates/PublicBackGroundPage";
import { Alerts } from "../../components/Alerts/Alerts";
import { CommonStyles } from "../../commons/CommonStyles";
import { RequiredData } from "../../components/RequiredData/RequiredData";
import { missedInfo } from "../../services/Commons";
import { errorCodes } from "../../commons/ConstantObjects";
import { finishGlobalOperation } from "../../commons/Utils";
import { EnumPages } from "../../commons/EnumPages";

export function PendingDataPage() {
  const commonClasses = CommonStyles();
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [needsUserData, setNeedsUserData] = useState(false);
  const [needsEnterpriseData, setNeedsEnterpriseData] = useState(false);
  const [pageCircularProgress, setPageCircularProgress] = useState(true);

  useEffect(() => {
    let missData = missedInfo();
    let userPending = [
      errorCodes[202],
      errorCodes[212],
      errorCodes[222],
      errorCodes[232],
      errorCodes[242],
    ];
    let enterprisePending = [
      errorCodes[999],
      errorCodes[2020],
    ];
    missData.forEach((element) => {
      if (userPending.indexOf(element) != -1) { setNeedsUserData(true) };
      if (enterprisePending.indexOf(element) != -1) { setNeedsEnterpriseData(true) };
    });
    setPageCircularProgress(false);
  }, []);

  const finishOperation = (color, message, auxOpen) => {
    finishGlobalOperation(setAlert, color, message, auxOpen);
  };

  return (
    <Grid>
      {pageCircularProgress ? <CircularProgress /> :
        <PublicBackGroundPage>
          <Grid className={commonClasses.mainDiv}>
            <Alerts alert={alert} setAlert={setAlert}></Alerts>
            <CorreosIdStepper
              step={1}
              page={EnumPages.PendingData}
              isPF={!needsEnterpriseData}
            />
            <BackgroundLogin size="lg" registry>
              <Paper style={{ width: "100%" }}>
                <RequiredData
                  isRegistry={false}
                  pendingDataPage={true}
                  idEnterprise={sessionStorage.getItem('enterpriseSelectedId')}
                  forUser={needsUserData}
                  forEnterprise={needsEnterpriseData}
                  onlyMandatories={true}
                  finishOperation={finishOperation}
                />
              </Paper>
            </BackgroundLogin>
          </Grid>
        </PublicBackGroundPage>
      }
    </Grid>
  );
}
