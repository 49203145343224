import { makeStyles } from "@material-ui/core/styles";

export const CustomCookieBannerStyles = makeStyles((theme) => ({

  messageGlobalCookie: {
    zIndex: theme.zIndex.drawer + 1,
    borderRadius: "0.5em",
    position: "fixed",
    bottom: "0",
    height: "8em",
    marginBottom: "0.5em",
    marginLeft: "0.25em",
    marginRight: "0.25em",
    width: "99.5%",
    background: "#002453",
    color: "white",
    [theme.breakpoints.down("md")]: {
      borderRadius: "0",
      marginLeft: "0",
      marginRight: "0",
      marginBottom: "0",
      width: "100%",
      paddingBottom: "1em",
      height: "auto",
    },
  },

  cookiesText: {
    marginTop: "auto",
    marginBottom: "auto",
    textAlign: "left",
    paddingLeft: "2em",
    paddingRight: "4em",
    fontSize: "1em",
    width: "99.5%",
    [theme.breakpoints.down("md")]: {
      paddingRight: "2em",
      fontSize: "0.8em",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "2em",
    },
  },
  
  buttonsContainer: {
    width: "99.5%",
    [theme.breakpoints.down("md")]: {
      marginBottom: "2em"
    },
  },

  buttonContainerConfigure: {
    textAlign: "right",
    marginTop: "auto",
    marginBottom: "auto",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "2em",
    [theme.breakpoints.down("md")]: {
      paddingTop: "1em",
    },
  },

  buttonContainerAccept: {
    textAlign: "left",
    paddingLeft: "2em",
    marginTop: "auto",
    marginBottom: "auto",
  },

  policyCookiesUrl: {
    color: "white",
    textDecoration: "underline"
  },

  buttonConfigure: {
    marginLeft: "1em"
  },

  buttonAccept: {
    marginLeft: "1em",
    marginRight: "1em"
  },

}));
