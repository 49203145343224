import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Container,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from "@material-ui/core";
import { CommonStyles } from "../../commons/CommonStyles";
import { UserVerificationsStyles } from "../UserVerifications/UserVerificationsStyles";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { useTranslation } from "react-i18next";
import { Dialog2FASetPhone } from "./Dialog2FASetPhone";
import privacyLogo from "../../assets/images/privacidad.svg";
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import { SwitchYesNoButton } from "../CommonComponents/SwitchYesNoButton/SwitchYesNoButton";
import { getErrorMessage } from "../../commons/Utils";
import { GetLegalUserTwoFactorAuthentication, PostLegalUserTwoFactorAuthentication } from "../../services/LegalCallsService";

export function SecondFACard(props) {
  const { finishOperation, emails, hasVerifiedPhones, addVerificable } = props;
  const commonClasses = CommonStyles();
  const classes = UserVerificationsStyles();
  const { t, i18n } = useTranslation();

  const [editableServicesList, setEditableServicesList] = useState([]);
  const [circularProgress, setCircularProgress] = useState(true);
  const [actionCircularProgress, setActionCircularProgress] = useState(false);

  useEffect(() => {
    if (editableServicesList.length == 0) {
      setCircularProgress(true);
      GetLegalUserTwoFactorAuthentication().then((response) => {
        if (response && !response.Status) {
          setEditableServicesList(response);
        } else {
          finishOperation("error", getErrorMessage(response, t));
        };
        setCircularProgress(false);
      });
    };
  }, []);

  const handleSwitchButtons = (code, checked) => {
    let auxServicesList = JSON.parse(JSON.stringify(editableServicesList));
    let auxService = auxServicesList.find((service) => service.applicationCode == code);
    auxService.active = checked;
    setEditableServicesList(auxServicesList);
  };

  const handleSwitchButtonsAll = (checked) => {
    let auxServicesList = JSON.parse(JSON.stringify(editableServicesList));
    auxServicesList.forEach((element) => {
      if (!element.mandatory) {
        element.active = checked;
      };
    });
    setEditableServicesList(auxServicesList);
  };

  const getChecked = (flag) => {
    let auxFlag = true;
    editableServicesList.forEach((element) => {
      if (!element.mandatory && element.active !== flag) {
        auxFlag = false;
      };
    });
    return auxFlag;
  };

  const handleConfirm = () => {
    setActionCircularProgress(true);
    let auxServicesList = JSON.parse(JSON.stringify(editableServicesList));
    auxServicesList.forEach((element) => {
      delete element.applicationName;
      delete element.mandatory;
    });
    PostLegalUserTwoFactorAuthentication(auxServicesList).then((response) => {
      if (response && !response.Status) {
        finishOperation("success", t("verifications2FAConfigSuccess"));
      } else {
        finishOperation("error", getErrorMessage(response, t));
      };
      setActionCircularProgress(false);
    });
  };

  return (
    <Card className={commonClasses.generalCardContainer}>
      {circularProgress ? <CircularProgress className={commonClasses.circularProgress} /> : (
        <CardContent>
          <Grid item xs={12} className={commonClasses.title}>
            <Typography variant="h6" style={{ marginBottom: "1em" }}>
              {t("verifications2FATitle")}
            </Typography>
          </Grid>
          <Grid container item xs={12} className={classes.privacyTitleCard}>
            <Grid item xs={12} md={3}>
              <CardMedia className={classes.media}>
                <img
                  className={classes.imgCardMedia}
                  src={privacyLogo}
                  alt="Logo"
                />
              </CardMedia>
            </Grid>
            <Grid item xs={12} md={9} className={classes.privacyTitleCardTextContainer}>
              <Typography className={classes.privacyTitleCardText}>
                {t("verifications2FAImageText")}
              </Typography>
            </Grid>
          </Grid>
          {editableServicesList.length == 0 ? (
            <Grid container spacing={1} alignItems="flex-end">
              <Typography className={classes.rgpdTitleText} style={{ margin: "auto auto auto 0.25em" }}>
                {t("verifications2FAConfigNoServiceText")}
              </Typography>
            </Grid>
          ) : (
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={12} md={6} style={{ display: "flex", textAlign: "left" }}>
                <FindInPageOutlinedIcon className={classes.imgIcon} style={{ margin: "auto 0.25em auto 0.25em" }}> </FindInPageOutlinedIcon>
                <Typography className={classes.rgpdTitleText} style={{ margin: "auto auto auto 0.25em" }}>
                  {t("verifications2FAConfigText")}
                </Typography>
              </Grid>
              <Grid container item xs={12} md={6} justifyContent="flex-end" style={{ display: "flex" }}>
                <FormControl>
                  <FormGroup row>
                    <FormControlLabel
                      value="end"
                      control={<Checkbox color="primary" onClick={() => handleSwitchButtonsAll(true)} checked={getChecked(true)} size="small" />}
                      label={<Typography className={classes.checkBoxLabel}>{t("privacyEnableAllText")}</Typography>}
                      labelPlacement="end"
                      style={{ margin: "auto 1em auto auto" }}
                    />
                    <FormControlLabel
                      value="end"
                      control={<Checkbox color="primary" onClick={() => handleSwitchButtonsAll(false)} checked={getChecked(false)} size="small" />}
                      label={<Typography className={classes.checkBoxLabel}>{t("privacyDisableAllText")}</Typography>}
                      labelPlacement="end"
                      style={{ margin: "auto 0em auto auto" }}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Container style={{ marginTop: "1em", paddingLeft: "16px", paddingRight: "0" }}>
                <Grid container spacing={1}>
                  {editableServicesList.map((element, index) => {
                    return (
                      <Grid container item xs={12} key={index}>
                        <Grid item xs={8} sm={10} style={{ display: "flex" }}>
                          <Typography variant="h6" style={{ float: "left", margin: "auto auto auto 0" }}>
                            {element.applicationName}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sm={2} style={{ textAlign: "right", paddingLeft: "1em", paddingRight: "0.5em", height: "4em" }}>
                          <SwitchYesNoButton checked={element.active} code={element.applicationCode} handleSwitchButtons={handleSwitchButtons} disabled={element.mandatory} />
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </Container>
            </Grid>
          )}
          <Grid container spacing={1} alignItems="flex-end">
            <Grid
              container
              item
              xs={12}
              className={commonClasses.dialogButtonContainer}
            >
              <Grid item xs={12} sm={6}>
                {!hasVerifiedPhones ? <Dialog2FASetPhone emails={emails} addVerificable={addVerificable} /> : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                {editableServicesList.length == 0 ? null : (
                  <CorreosButton
                    variant="contained"
                    color="primary"
                    className={commonClasses.buttonRightCenter}
                    onClick={handleConfirm}
                    circularProgress={actionCircularProgress}
                  >
                    {t("confirm")}
                  </CorreosButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};
