import React, { useEffect, useState } from "react";
import { Grid, FormControl, InputLabel, Select } from "@material-ui/core";
import { checkIsNullUndefined } from "../../commons/Utils";
import { CommonStyles } from "../../commons/CommonStyles";
import { countryAttributes, documentAttributes, otherAttributes, roadTypeAttributes, addressUseCodeAttributes } from "../../commons/EnumAttributes";
import {
  GetMasterTypeUserDocument,
  GetMasterCountry,
  GetMasterGenders,
  GetMasterProfessionalActivity,
  GetMasterRoadType,
  GetMasterLanguage,
} from "../../services/MasterCallsService";
import { EnumCountries } from "../../commons/EnumCountries";
import { EnumFirstSelectOption } from "../../commons/EnumsGeneral";
import { useTranslation } from "react-i18next";

const AttributesCall = {
  "TDO": GetMasterTypeUserDocument,
  "PEX": GetMasterCountry,
  "NAC": GetMasterCountry,
  "PAI": GetMasterCountry,
  "EPI": GetMasterCountry,
  "ECY": GetMasterCountry,
  "PRE": GetMasterCountry,
  "GEN": GetMasterGenders,
  "LAN": GetMasterLanguage,
  "EPA": GetMasterProfessionalActivity,
  "VIA": GetMasterRoadType,
  "EVI": GetMasterRoadType,
}

export function DropdownAttribute(props) {
  const commonClasses = CommonStyles();
  const { t, i18n } = useTranslation();
  const {
    attribute,
    saveGroupsChanges,
    setIsControlledCountry,
    countryList,
    typeUserDocument,
    roadType,
    optionsList,
  } = props;

  const [dropdownValues, setDropdownValues] = useState([]);
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    getDropdownValues();
  }, []);

  useEffect(() => {
    if (mounted) {
      let auxValue = checkIsNullUndefined(attribute.value)
        ? EnumFirstSelectOption
        : attribute.value;
      if (checkAreDiferent()) {
        let auxiliaryoptions = [];
        optionsList?.forEach((elem) => {
          auxiliaryoptions.push({ code: elem, name: elem, id: elem });
        });
        setOptions(optionsList);
        setDropdownValues(auxiliaryoptions);
        setValue(auxValue);
      } else if (attribute.value !== value) {
        setValue(auxValue);
      }
    }
  }, [optionsList, attribute]);

  const checkAreDiferent = () => {
    let flag = false;
    if (!checkIsNullUndefined(optionsList) && !checkIsNullUndefined(options)) {
      flag = compareOptionsLength();
    } else {
      flag = true;
    }

    return flag;
  };

  const compareOptionsLength = () => {
    if (optionsList?.length == options?.length) {
      return compareOptions();
    }

    return true;
  }

  const compareOptions = () => {
    let flag = false;
    optionsList.forEach((option) => {
      if (options.indexOf(option) == -1) {
        flag = true;
      }
    });

    return flag;
  }

  const handleSetValues = (auxDropdownValues, auxValue) => {
    setDropdownValues(auxDropdownValues);
    if (
      attribute.codeAttribute === "PAI" ||
      attribute.codeAttribute === "EPI"
    ) {
      setIsControlledCountry(
        auxValue == EnumCountries.ESP || auxValue == EnumCountries.AND || checkIsNullUndefined(auxValue)
      );
    };
    // attribute.dropdownValues = auxDropdownValues;
    setValue(checkIsNullUndefined(auxValue) ? EnumFirstSelectOption : auxValue);
    setMounted(true);
  };

  const handleIsAttributeType = (type, typeList) => {
    return type && checkIsNullUndefined(typeList) || typeList?.length === 0
  };

  const handleAuxiliarAttributes = (auxCall, isOther, isDoc, isCount, isRoad) => {
    if (isOther) {
      auxCall().then((response) => {
        handleSetValues(response, attribute.value);
      });
    } else {
      let auxOptions = isDoc ? typeUserDocument : isCount ? countryList : roadType;
      if (handleIsAttributeType(isDoc, typeUserDocument)
        || handleIsAttributeType(isCount, countryList)
        || handleIsAttributeType(isRoad, roadType)) {
        auxCall().then((response) => {
          if (isRoad) {
            response.sort();
          };
          handleSetValues(response, attribute.value);
        });
      } else {
        handleSetValues(auxOptions, attribute.value);
      }
    }
  }

  const getDropdownValues = () => {

    let auxDoc = documentAttributes;
    let auxCount = countryAttributes;
    let auxRoad = roadTypeAttributes;
    let auxOther = otherAttributes;
    let auxCall = AttributesCall[attribute.codeAttribute];

    let isDoc = auxDoc.includes(attribute.codeAttribute);
    let isCount = auxCount.includes(attribute.codeAttribute);
    let isRoad = auxRoad.includes(attribute.codeAttribute);
    let isOther = auxOther.includes(attribute.codeAttribute);

    let isAux = isDoc || isCount || isRoad || isOther;

    if (!isAux) {
      let auxiliaryoptions = [];
      optionsList.forEach((elem) => {
        auxiliaryoptions.push({ code: elem, name: elem, id: elem });
      });
      setOptions(optionsList);
      setDropdownValues(auxiliaryoptions);
      setValue(
        checkIsNullUndefined(attribute.value) ? EnumFirstSelectOption : attribute.value
      );
      setMounted(true);
    } else {
      handleAuxiliarAttributes(auxCall, isOther, isDoc, isCount, isRoad)
    }
  };

  const saveAttributesChanges = (nValue) => {
    let first = false;
    if (
      attribute.codeAttribute === "PAI" ||
      attribute.codeAttribute === "EPI"
    ) {
      first = true;
      setIsControlledCountry(
        nValue == EnumCountries.ESP || nValue == EnumCountries.AND || checkIsNullUndefined(nValue)
      );
    }
    saveGroupsChanges(
      attribute,
      nValue,
      first,
      attribute.codeAttribute === "ALI" || attribute.codeAttribute === "EAL"
    );
  };

  const handleDropdownChange = (event) => {
    // if (
    //   (attribute.codeAttribute !== "ALI" &&
    //     attribute.codeAttribute !== "EAL")
    // ) {
    setValue(event.target.value);
    saveAttributesChanges(event.target.value);
    // }
  };

  const getFirstOption = () => {
    let auxValue = t("noOptionSelected");
    if (
      attribute.codeAttribute === "EMA" ||
      attribute.codeAttribute === "MOV" ||
      attribute.codeAttribute === "ALI" ||
      attribute.codeAttribute === "EAL"
    ) {
      auxValue = t("selectOption");
    }
    return auxValue;
  };

  return (
    <Grid item md={12} xs={12}>
      <FormControl variant="filled" fullWidth required={attribute.mandatory} className={commonClasses.inputsStyle}>
        <InputLabel label={attribute.nameAttritube} color="secondary">
          {attribute.nameAttritube}
        </InputLabel>
        <Select
          nulleable
          color="secondary"
          native
          value={checkIsNullUndefined(value) ? "" : value}
          onChange={handleDropdownChange}
          label={attribute.nameAttritube}
          disabled={attribute.disabled || attribute.codeAttribute === "PRE"}
        >
          {
            <option key={"first"} value={EnumFirstSelectOption}>
              {getFirstOption()}
            </option>
          }

          {dropdownValues
            ? dropdownValues.map((dvalue) => {
              if (attribute.codeAttribute === "PRE") {
                return (
                  <option key={dvalue.code} value={dvalue.id}>
                    {dvalue.code + " (+" + dvalue.phonePrefix + ")"}
                  </option>
                );
              } else if (attribute.codeAttribute === "EPA") {
                return (
                  <option
                    key={dvalue.code}
                    value={dvalue.id}
                    disabled={dvalue.level != 4}
                  >
                    {dvalue.code + " - " + dvalue.name}
                  </option>
                );
              } else if (addressUseCodeAttributes.includes(attribute.codeAttribute)) {
                return (
                  <option key={dvalue.code} value={dvalue.code}>
                    {dvalue.name}
                  </option>
                )
              } else {
                return (
                  <option key={dvalue.code} value={dvalue.id}>
                    {dvalue.name}
                  </option>
                );
              }
            })
            : null}
        </Select>
      </FormControl>
    </Grid>
  );
}
