export const GLTYC1 = {

    //TyCs e páxina de privacidade

    tycUrlsText: "Lea atentamente os termos e condicións de uso facendo clic nas seguintes ligazóns:",
    privacyUrlsText: "Lea atentamente as políticas de privacidade facendo clic nas seguintes ligazóns:",
    privacyNoUrlsText: "Para la aplicación y el tipo de persona indicados no existe privacidad que firmar.",
    tycNoUrlsText: "Para la aplicación y el tipo de persona indicados no existen TyC's que firmar.",
    atcText: "Unha vez comunicado o código de verificación ao operador ATC, os termos e condicións de uso consideraranse aceptados.",

    //Terms and Conditions

    tycHeaderTitle: "Termos e Condicións",

    //WarningLegalTyC Subtittles

    tycWarningLegalSubtittle1:
        "DEREITOS DE PROPIEDADE INTELECTUAL E DE PROPIEDADE INDUSTRIAL",
    tycWarningLegalSubtittle2: "PROTECCIÓN DE DATOS DE CARÁCTER PERSOAL",
    tycWarningLegalSubtittle3: "MEDIDAS DE SEGURIDADE",
    tycWarningLegalSubtittle4: "RESPONSABILIDADES DE CORREOS",
    tycWarningLegalSubtittle5: "RESPONSABILIDADE DO USUARIO",
    tycWarningLegalSubtittle6: "LEI APLICABLE E XURISDICIÓN",

    //WarningLegalTyC texts

    tycWarningLegalText1:
        "En cumprimento do establecido no art. 10 da Lei 34/2002, do 11 de xullo, de servizos da sociedade da información e comercio electrónico (LSSI-CE), informámolo de que esta páxina web é titularidade de Correos.",
    tycWarningLegalText2:
        "O presente documento contén o Aviso legal de “Correos ID” proporcionado pola Sociedad Estatal Correos y Telégrafos, S.A. (en diante, Correos), con domicilio social en Vía Dublín, n.º 7, (Campo de las Naciones) 28070 Madrid (España), CIF número A83052407.",
    tycWarningLegalText3:
        "O contacto para a atención ao cliente do servizo é por medio telefónico a través do número de Atención ao Cliente de Correos: 902.197.197 ou páxina web de Correos.",
    tycWarningLegalText4:
        "Tanto o deseño do Portal e os seus códigos fonte, coma os logos, marcas, e demais signos distintivos que aparecen neste, pertencen a Correos e están protexidos polos correspondentes dereitos de propiedade intelectual e industrial.",
    tycWarningLegalText5:
        "O seu uso, reprodución, distribución, comunicación pública, transformación ou calquera outra actividade similar ou análoga queda totalmente prohibida salvo que medie expresa autorización por escrito de Correos.",
    tycWarningLegalText6:
        "Os contidos do Portal están igualmente protexidos por dereitos de propiedade intelectual de Correos. O uso dos contidos elaborados por Correos ou por algunha das entidades do seu Grupo será permitido unicamente no ámbito dunha relación contractual con esta ou coas devanditas entidades.",
    tycWarningLegalText7:
        "Correos declara o seu respecto aos dereitos de propiedade intelectual e industrial de terceiros; por iso, se considera que este sitio puidese estar a violar os seus dereitos, pregamos que se poña en contacto con Correos no seguinte número de teléfono: 902.197.197.",
    tycWarningLegalText8_1:
        "A navegación a través da nosa páxina web non require o seu rexistro previo. Non obstante, cando vostede visita a nosa páxina web, os nosos servidores web almacenan, de forma estándar, información como o enderezo IP e o dominio dende o que se obtén o acceso, a data e a hora da visita, etc. Por outro lado, certas funcionalidades da nosa páxina web esixen que facilite información adicional a través do correspondente formulario (p. ex. O seu nome e apelidos, enderezo postal ou electrónico de contacto, teléfono...). Correos tratará os seus datos de carácter persoal conforme as condicións publicadas na ",
    tycWarningLegalText8_B: "Política de Privacidade Web ",
    tycWarningLegalText8_2:
        "e, se e o caso, na Política de Protección de Datos que resulte aplicable aos distintos servizos solicitados.",
    tycWarningLegalText9:
        "Informámolo de que foron adoptadas todas as medidas de seguridade de índole técnica, organizativa e de seguridade informática necesarias para a protección de datos de carácter persoal para evitar a súa alteración, perda, tratamento ou acceso non autorizado, todo iso de acordo co establecido na normativa aplicable.",
    tycWarningLegalText10:
        "Uso incorrecto do Portal: Correos creou o Portal para a promoción dos seus produtos e para facilitar o acceso aos seus servizos, pero non pode controlar a utilización deste de forma distinta á prevista no presente Aviso Legal; polo tanto, o acceso ao Portal e o uso correcto da información contida neste son responsabilidade de quen realiza estas accións, polo que Correos non será responsable do uso incorrecto, ilícito ou neglixente que o Usuario puidese facer deste.",
    tycWarningLegalText11:
        "Utilización dos contidos: Correos facilita todos os contidos do seu Portal (Correos ID) de boa fe e realizará os seus mellores esforzos para que estes estean permanentemente actualizados e vixentes; non obstante, Correos non pode asumir responsabilidade ningunha respecto ao uso ou acceso que realicen os Usuarios fóra do ámbito ao que se dirixe o Portal, nin das consecuencias que puidese carrexar a aplicación práctica das opinións, recomendacións ou estudos aos que se poida acceder a través do Portal, cuxa responsabilidade final recaerá sempre sobre o Usuario. Así mesmo, Correos non vai poder controlar os contidos que non fosen elaborados por ela ou por terceiros ao cumprir o seu encargo e, polo tanto, non responderá en ningún caso dos danos que puidesen causarse polos devanditos contidos de terceiros.",
    tycWarningLegalText12:
        "Publicidade: Correos incluirá no Portal publicidade propia ou de terceiros para ofrecerlle produtos ou servizos que entenda que poden ser do seu interese. Non obstante, Correos non pode controlar a aparencia da devandita publicidade, nin a calidade e adecuación dos devanditos produtos ou servizos e, en consecuencia, Correos non responderá de ningún dano que se puidese xerar ao usuario polas devanditas causas.",
    tycWarningLegalText13:
        "Virus: Correos comprométese a aplicar todas as medidas necesarias para intentar garantir ao Usuario a ausencia de virus informáticos e elementos semellantes no seu Portal. Non obstante, estas medidas non son infalibles e, por iso, Correos non pode asegurar totalmente a ausencia dos devanditos elementos nocivos. En consecuencia, Correos non será responsable dos danos que estes puidesen producir ao Usuario.",
    tycWarningLegalText14:
        "Fallos tecnolóxicos: Correos concluíu todos os contratos necesarios para a continuidade do seu Portal e realizará os seus mellores esforzos para que este non sufra interrupcións, pero non pode garantir a ausencia de fallos tecnolóxicos, nin a permanente dispoñibilidade do Portal e dos produtos ou servizos contidos nel e, en consecuencia, non asume responsabilidade ningunha polos danos e prexuízos que poidan xerarse pola falta de dispoñibilidade e polos fallos no acceso ocasionados por desconexións, avarías, sobrecargas ou caídas da rede non imputables a Correos.",
    tycWarningLegalText15:
        "Intercambio de información: o Portal pode conter determinadas zonas pensadas para que os distintos Usuarios poidan poñer en común as súas experiencias e fomentar o intercambio de información. Non obstante, Correos unicamente actúa nestes foros como lugar de reunión e non controla as expresións publicadas nestes, polo que non asume responsabilidade ningunha pola veracidade ou pertinencia das manifestacións efectuadas neles polos Usuarios.",
    tycWarningLegalText16:
        "O Usuario comprométese a utilizar os servizos do Portal de acordo cos termos expresados no presente Aviso Legal e a mera utilización destes implicará a súa aceptación.",
    tycWarningLegalText18:
        "O Usuario que actúe contra a imaxe, bo nome ou reputación de Correos, así como quen utilice ilícita ou fraudulentamente os deseños, logos ou contidos do Portal, será responsable fronte a Correos da súa actuación.",
    tycWarningLegalText19:
        "A lei aplicable en caso de disputa ou conflito de interpretación dos termos que conforman este Aviso Legal, así como calquera cuestión relacionada cos produtos ou servizos do presente Portal, será a lei española, salvo que as normas de protección dos consumidores e usuarios establezan a aplicabilidade dunha lexislación diferente.",
    tycWarningLegalText20:
        "Para a resolución de partes, sométense á xurisdición dos xulgados e tribunais da capital de provincia de España que corresponda.",

    //PrivacyPoliticyTyC Subtittles

    tycPrivacyPoliticyTyCSubtittle1: "Responsable do tratamento",
    tycPrivacyPoliticyTyCSubtittle2: "Finalidade do tratamento e lexitimación",
    tycPrivacyPoliticyTyCSubtittle3: "Tipo de datos tratados",
    tycPrivacyPoliticyTyCSubtittle4: "Prazo de conservación dos datos",
    tycPrivacyPoliticyTyCSubtittle5: "Os seus dereitos",
    tycPrivacyPoliticyTyCSubtittle6: "Acceso a terceiros",

    //PrivacyPoliticyTyC list tittles

    tycPrivacyPoliticyTyCListTittle1: "Prestación do servizo Correos ID",
    tycPrivacyPoliticyTyCListTittle2:
        "Envío de ofertas e promocións de produtos e servizos de Correos",
    tycPrivacyPoliticyTyCListTittle3:
        "Envío de ofertas e promocións doutras empresas do Grupo Correos e de terceiras empresas",
    tycPrivacyPoliticyTyCListTittle4:
        "Comunicación de datos a outras empresas do Grupo Correos e a terceiras empresas",
    tycPrivacyPoliticyTyCListTittle5: "Cumprir coas obrigas legais",
    tycPrivacyPoliticyTyCListTittle6:
        "Información estatística e enquisas de satisfacción",
    tycPrivacyPoliticyTyCListTittle7: "Enriquecer a súa información",
    tycPrivacyPoliticyTyCListTittle8: "Datos proporcionados de forma directa:",
    tycPrivacyPoliticyTyCListTittle9: "Datos solicitados de forma indirecta:",
    tycPrivacyPoliticyTyCListTittle10:
        "Datos derivados da propia prestación do servizo:",

    //PrivacyPoliticyTyC texts

    tycPrivacyPoliticyTyCText1:
        "Para todas as empresas do Grupo Correos, respectar a súa privacidade e cumprir coa normativa de protección de datos é un aspecto de vital importancia.",
    tycPrivacyPoliticyTyCText2_1:
        "O noso desexo é que en todo momento coñeza para que utilizamos, ou queremos utilizar, os seus datos e cales son os seus dereitos. Con este obxectivo, redactamos a seguinte Política de Protección de Datos que regula o tratamento de datos derivado do servizo Correos ID, así como da navegación a través da páxina web ",
    tycPrivacyPoliticyTyCText2_B1: "id.correos.post",
    tycPrivacyPoliticyTyCText2_2: " (con anterioridade ",
    tycPrivacyPoliticyTyCText2_B2: "emiidentidad.correos.post",
    tycPrivacyPoliticyTyCText2_3:
        " ) e de calquera dos seus subdominios ou doutras webs de Correos en cuxo footer se mostre esta Política de Privacidade",
    tycPrivacyPoliticyTyCText3:
        "O seguinte poderá ser obxecto de modificación para calquera adaptación legal a futuras novidades lexislativas ou xurisprudenciais, así como prácticas da industria. En calquera caso, a devandita modificación será comunicada ao Usuario mediante calquera vía de comunicación válida.",
    tycPrivacyPoliticyTyCText4:
        "O responsable do tratamento é “Sociedad Estatal Correos y Telégrafos, S.A., S.M.E” (en diante “Correos”), con CIF A-83052407 e domicilio social en vía Dublín n.º 7 (Campo de las Naciones) 28070 Madrid (España).",
    tycPrivacyPoliticyTyCText5:
        "Para garantir a axeitada xestión dese tratamento, Correos designou un Delegado de Protección de datos (DPD ou DPO), a quen se pode dirixir para calquera cuestión que precise con relación a estes a través do seguinte enderezo de correo electrónico: ",
    tycPrivacyPoliticyTyCText6:
        "O tratamento dos seus datos persegue as seguintes finalidades:",
    tycPrivacyPoliticyTyCText7: "Os seus datos serán tratados para",
    tycPrivacyPoliticyTyCText8:
        "Tramitar o seu rexistro como usuario e proceder á súa alta a través do formulario establecido para tal efecto.",
    tycPrivacyPoliticyTyCText9:
        "Para dalo de alta nalgúns dos servizos dixitais a través de Correos ID, é posible que se solicite información adicional mediante un formulario web establecido para o efecto.",
    tycPrivacyPoliticyTyCText10:
        "Prestar os servizos de “Correos ID” de conformidade coas Condicións Particulares do Servizo.",
    tycPrivacyPoliticyTyCText11:
        "Neste sentido, Correos ID é un xestor centralizado de identidade dixital que permite ao usuario autenticarse ante os distintos servizos dixitais de Correos ou doutros servizos de terceiros que acepten este método como sistema de identificación; é dicir, de forma resumida, permite ao usuario acceder a distintos servizos a través dunha soa instancia de identificación dixital, evitando ter que dispoñer dun contrasinal para cada un destes servizos.",
    tycPrivacyPoliticyTyCText12:
        "En caso de que o servizo Correos ID se utilice como plataforma dixital de identificación ante servizos que presten outras empresas do Grupo Correos ou terceiros colaboradores, é necesario que os datos do usuario se comuniquen a estas empresas para poder levar a cabo a verificación de identidade que foi solicitada.",
    tycPrivacyPoliticyTyCText13:
        "Responder ás súas dúbidas, consultas ou reclamacións.",
    tycPrivacyPoliticyTyCText14:
        "Tramitar a verificación da súa identidade, derivada dun acordo de colaboración ou prestación de servizos que Correos subscribise cun terceiro.",
    tycPrivacyPoliticyTyCText15:
        "Así mesmo, a través do xestor centralizado de identidade dixital, poderase tramitar a verificación da súa identidade, derivada dun acordo de colaboración ou prestación de servizo subscritos entre Correos e un terceiro.",
    tycPrivacyPoliticyTyCText16:
        "En caso de que preste o seu consentimento, Correos poderá remitirlle por calquera canle electrónica, ou non electrónica, información comercial relacionada coa prestación, mellora e actualización dos nosos produtos e servizos, novas tarifas e descontos.",
    tycPrivacyPoliticyTyCText17:
        "Esta información comercial poderá ser xenérica ou personalizada. Neste último suposto, implicará o tratamento previo dos seus datos co obxectivo de poder proceder ao seu perfilado e carteirización, determinar gustos, preferencias e necesidades e, sobre a base destas, identificar as ofertas que poidan resultar máis interesantes.",
    tycPrivacyPoliticyTyCText18:
        "En caso de que preste o seu consentimento, Correos poderá remitirlle por calquera canle electrónica ou non electrónica ofertas e promocións de:",
    tycPrivacyPoliticyTyCText19:
        "Outras empresas do Grupo Correos que prestan servizos relacionados cos sectores que pertenzan ás actividades de servizos de paquetería, de valor engadido ao servizo postal, loxística, márketing e de telecomunicación.",
    tycPrivacyPoliticyTyCText20:
        "Outras empresas coas que Correos subscribira distintos convenios de colaboración e que poden pertencer a sectores como telecomunicacións, financeiro, gran consumo, sanidade e ONG.",
    tycPrivacyPoliticyTyCText21:
        "Esta información comercial poderá ser xenérica ou personalizada. Neste último suposto, implicará o tratamento previo dos seus datos co obxectivo de poder proceder ao seu perfilado e carteirización, determinar gustos, preferencias e necesidades e, sobre a base destas, identificar as ofertas que poidan resultar máis interesantes.",
    tycPrivacyPoliticyTyCText22:
        "En caso de que preste o seu consentimento, Correos poderá comunicar os seus datos ás empresas do Grupo Correos e a terceiras empresas, cuxos sectores se detallan na finalidade anterior, co obxectivo de que estas empresas poidan facerlle chegar, de forma directa e sen a intermediación de Correos, ofertas e promocións relativas aos seus produtos e servizos.",
    tycPrivacyPoliticyTyCText23:
        "Dentro das distintas obrigas legais que asume Correos, encóntranse as derivadas da aplicación da normativa postal (Lei 43/2010 do servizo postal universal, dos dereitos dos usuarios e do mercado postal) e o deber de segredo das comunicacións (art. 18 da Constitución), así como a normativa tributaria.",
    tycPrivacyPoliticyTyCText24:
        "No cumprimento destas obrigas, Correos poderá comunicar os seus datos ás Administracións públicas e tribunais, sempre que tal información sexa requirida conforme os procesos legais establecidos.",
    tycPrivacyPoliticyTyCText25:
        "Correos ten un interese lexítimo en coñecer tanto a opinión dos seus clientes con respecto aos seus produtos e servizos coma os seus hábitos de consumo (p. ex. a frecuencia/recorrencia na contratación dos distintos servizos) co obxectivo de poder ofrecer produtos e servizos máis axustados ás súas necesidades e mellorar tanto os produtos e servizos actuais coma o seu proceso de contratación.",
    tycPrivacyPoliticyTyCText26:
        "Para lograr o obxectivo exposto no parágrafo anterior, Correos poderá tratar os seus datos e:",
    tycPrivacyPoliticyTyCText27:
        "Realizar enquisas de satisfacción tanto telefónicas como por medios electrónicos, mesmo se deixa de ser cliente, neste último caso para coñecer os motivos da súa marcha.",
    tycPrivacyPoliticyTyCText28:
        "En caso de que, durante a enquisa de satisfacción, o cliente manifeste a súa opinión sobre os produtos e servizos dalgunha das empresas do Grupo Correos, o resultado da enquisa e os datos identificadores do participante poderán ser comunicados á empresa do Grupo Correos cuxos servizos se valoran.",
    tycPrivacyPoliticyTyCText29:
        "Proceder á súa anonimización, de forma que non poida volver vincularse a información dos seus hábitos de consumo coa súa persoa e, tras iso, realizar estudos estatísticos e econométricos (p. ex. coñecer o ciclo de vida do cliente ou identificar modelos de propensión ao abandono).",
    tycPrivacyPoliticyTyCText30:
        "En caso de que preste o seu consentimento, Correos poderá obter unha maior información sobre as súas características, gustos e necesidades a través da información proporcionada por distintas fontes externas, tales como rexistros públicos, catastro, redes sociais, ferramentas de xeomárketing, bases de datos de información económica, como INFORMA, ficheiros de información crediticia, como ASNEF, BADEXCUG etc. co obxectivo de introducir melloras nos nosos produtos e servizos ou adaptar e escoller as ofertas dos devanditos produtos e servizos que mellor se axusten ao seu perfil concreto.",
    tycPrivacyPoliticyTyCText31:
        "Para as finalidades expostas no apartado anterior, trátanse un conxunto de datos de carácter persoal que podemos dividir nas seguintes fontes e categorías:",
    tycPrivacyPoliticyTyCText32:
        "No momento do rexistro, o usuario debe proporcionar unha información mínima que resulta necesaria para a súa verificación e alta, polo que, salvo que se advirta o contrario, de non a completar, impedirá a prestación do servizo. Certas funcionalidades (como o acceso a determinados servizos) esixen que facilite información adicional a través do propio servizo.",
    tycPrivacyPoliticyTyCText33:
        "Así mesmo, xunto coa información proporcionada a través do servizo, trataranse datos solicitados polo servidor, como o enderezo IP dende o que accede, o dominio dende o que se obtén o acceso, a data e a hora da visita, a cookie de sesión (esta última elimínase ao pechar o navegador) etc.",
    tycPrivacyPoliticyTyCText34:
        "O cliente garante que os datos facilitados a Correos para a prestación dos servizos solicitados responden con veracidade á situación real e que comunicará calquera modificación que afecte a estes. En consecuencia, o cliente responderá fronte a Correos e terceiros de calquera dano e prexuízo ocasionado como consecuencia do incumprimento das obrigas asumidas nesta cláusula.",
    tycPrivacyPoliticyTyCText35_1:
        "Todos aquellos datos que, en caso de haber prestado su consentimiento, Correos recaba de la tipología de fuentes " +
        'externas enumerada en la finalidad "H" del apartado ',
    tycPrivacyPoliticyTyCText35_B: '"Finalidade do tratamento e lexitimación".',
    tycPrivacyPoliticyTyCText36:
        "Ademais de no caso anterior, terceiras entidades, como Administracións públicas, a empresa na que traballa ou outras institucións coa que Correos teña subscrito un acordo de colaboración ou prestación de servizos, e que estean interesadas en contratar este método como sistema de identificación, poden comunicarnos ou darnos acceso aos seus datos, co fin de que poidamos verificar a súa identidade. As entidades coas que Correos subscribise acordos de colaboración ou prestación de servizos poderán, se é o caso, inhabilitar o sistema de identificación ou aplicar condicións distintas ou adicionais de acordo co recollido nos acordos de colaboración ou de prestación de servizos subscritos.",
    tycPrivacyPoliticyTyCText37:
        "Dentro desta información, inclúense aspectos como: (i) O histórico de produtos e servizos contratados con Correos, o tipo de servizo e as súas características (p. ex. servizos dixitais de Correos nos que se encontra dado de alta, frecuencia de uso destes servizos, uso doutros servizos de Correos como: envíos postais, paquetería, Citypaq...); (ii) reclamacións ou solicitudes de información etc. (iii) información sobre a súa navegación a través das cookies que poden instalarse no seu dispositivo, previo ao seu consentimento —de ser necesario— de conformidade co establecido na nosa Política de Cookies.",
    tycPrivacyPoliticyTyCText38:
        "Os seus datos persoais serán conservados mentres sexan necesarios para a prestación do servizo de Correos ID, salvo que solicite a súa supresión. O proceso de eliminación de Identidade implica un bloqueo ou restrición de uso da súa Identidade e datos durante un período de trinta (30) días, tras o cal se procederá á súa efectiva eliminación. Durante o período de restrición do uso da Identidade, poderá recuperar a súa Identidade seguindo o proceso de Correos ID para iso.",
    tycPrivacyPoliticyTyCText39:
        "Correos procederá ao bloqueo daqueles usuarios que se encontren inactivos —é dicir, que non accedan a ningún dos servizos dixitais de Correos ou doutras empresas que usen Correos ID como plataforma de identificación— por un prazo de 5 anos.",
    tycPrivacyPoliticyTyCText40:
        "Cando xa non se precise o tratamento dos seus datos, suprimiranse conforme o disposto na normativa de protección de datos, o que implica o seu bloqueo, e só estarán dispoñibles por solicitude de xuíces e tribunais, o Ministerio Fiscal ou as Administracións públicas competentes durante o prazo de prescrición das accións que puidesen derivar para ser posteriormente eliminados. Xeralmente, serán borrados unha vez transcorridos 3 anos.",
    tycPrivacyPoliticyTyCText41:
        "A nosa normativa de protección de datos confírelle unha serie de dereitos en relación co tratamento de datos que implican os nosos servizos, que podemos resumir nos seguintes:",
    tycPrivacyPoliticyTyCText42:
        "- Dereito de acceso: Coñecer que tipo de datos estamos a tratar e as características do tratamento que estamos a levar a cabo.",
    tycPrivacyPoliticyTyCText43:
        "- Dereito de rectificación: Poder solicitar a modificación dos seus datos por ser estes inexactos ou non veraces.",
    tycPrivacyPoliticyTyCText44:
        "- Dereito de portabilidade: Poder obter unha copia nun formato interoperable dos datos que estean a ser tratados.",
    tycPrivacyPoliticyTyCText45:
        "- Dereito á limitación do tratamento nos casos recollidos na Lei.",
    tycPrivacyPoliticyTyCText46:
        "- Dereito de supresión: Solicitar a supresión dos seus datos cando o tratamento xa non resulte necesario.",
    tycPrivacyPoliticyTyCText47:
        "- Dereito de oposición: Este dereito permítelle, por exemplo, solicitar o cesamento no envío de comunicacións comerciais.",
    tycPrivacyPoliticyTyCText48: "- Dereito a revogar o consentimento prestado.",
    tycPrivacyPoliticyTyCText49:
        "Pode exercitar os seus dereitos a través dalgunha das seguintes canles:",
    tycPrivacyPoliticyTyCText50:
        "- Enderezo postal: Vía Dublín n.º 7 (Campo de las Naciones) 28070 Madrid (España)",
    tycPrivacyPoliticyTyCText51: "- Correo electrónico: ",
    tycPrivacyPoliticyTyCText52:
        "Así mesmo, todas as comunicacións comerciais electrónicas incluirán un mecanismo de baixa ou similar, que lle permitirá, se así o desexa, non recibir novas comunicacións.",
    tycPrivacyPoliticyTyCText53:
        "Na páxina web da Axencia Española de Protección de datos (AEPD) pode encontrar unha serie de modelos que lle axudarán no exercicio dos seus dereitos. Así mesmo, informámolo de que ten dereito a interpoñer unha reclamación ante a autoridade de control (en España, a AEPD) en caso de que considere infrinxidos os seus dereitos.",
    tycPrivacyPoliticyTyCText54:
        "Para a prestación de determinados servizos pode ser preciso que nos facilite os datos persoais e de contacto de terceiras persoas, como, p. ex., o nome, apelidos, enderezo postal, enderezo de correo electrónico, teléfono de contacto e outros datos ou medios equivalentes de contacto do destinatario dunha comunicación ou envío.",
    tycPrivacyPoliticyTyCText55:
        "Adicionalmente, co obxectivo da mellor prestación do servizo, o número de teléfono ou o enderezo de correo electrónico poderán ser utilizados co obxectivo de informar o destinatario sobre a prestación do servizo e tratar calquera incidencia que puidese afectar a este.",
    tycPrivacyPoliticyTyCText56:
        "Neste sentido, o cliente garante a veracidade e actualización dos datos do destinatario e, se é o caso, asegura ter cumprido con todas aquelas obrigas que, en materia de protección de datos, lle resulten atribuíbles, despois de obter o consentimento do destinatario para que Correos leve a cabo o tratamento exposto nos parágrafos precedentes, en caso de ser necesario tal consentimento.",
    tycPrivacyPoliticyTyCText57:
        "Pola súa banda, en caso de que Correos acceda a datos persoais que resulten necesarios para a prestación do servizo de verificación de identidade, baixo a responsabilidade dunha empresa ou profesional. Correos comprométese a cumprir todas as obrigas inherentes á súa posición como encargado de tratamento e, neste sentido:",
    tycPrivacyPoliticyTyCText58:
        "Accederá e tratará os datos aos que teña acceso suxeito ás súas instrucións, e non os utilizará para finalidades distintas da prestación do servizo. Para iso, poderá contar cos servizos doutros terceiros (subencargados), se resulta necesario para a prestación do servizo e operacións do contrato, ou para a súa conservación, e sempre que (i) o novo encargado quede suxeito ás mesmas condicións (instrucións, obrigas, medidas de seguridade...) e cos mesmos requisitos formais ca el, no referente ao adecuado tratamento dos datos persoais e á garantía dos dereitos das persoas afectadas; (ii) Correos, mediante solicitude expresa, poña á súa disposición unha listaxe na que se identifiquen os servizos subencargados e a identidade dos subencargados; (iii) en caso de incumprimento por parte do subencargado, Correos continúe sendo plenamente responsable.",
    tycPrivacyPoliticyTyCText59:
        "Implantará as medidas de seguridade legais, técnicas e organizativas apropiadas para garantir un nivel de seguridade adecuado ao risco, conforme o exposto no art. 32 do RGPD e, mediante a súa solicitude, informarao da metodoloxía de análise de risco utilizada.",
    tycPrivacyPoliticyTyCText60:
        "Unha vez cumprida a prestación contractual, destruirá ou devolverá os datos tratados, así como calquera soporte ou documento onde consten datos obxecto do tratamento, sen prexuízo da posibilidade de conservar estes datos, debidamente bloqueados, nos termos previstos pola nosa normativa de protección de datos.",
    tycPrivacyPoliticyTyCText61:
        "Asegurará o cumprimento do resto de obrigas recollidas na normativa de protección de datos.",
};
