import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Grid, Stepper, Step, StepLabel } from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import { getReplaceApplicationOid, getSessionItem } from "../../services/Commons";
import { useTranslation } from "react-i18next";
import { CorreosToolTip } from "../CommonComponents/CorreosToolTip/CorreosToolTip";
import { generalPaths, iconsList, sessionValues } from "../../commons/EnumsGeneral";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#cbcbcb",
    borderRadius: 1,
  },
  active: {
    "& $line": {
      backgroundColor: "#002453",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#002453",
      color: "#002453",
    },
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#cbcbcb",
    zIndex: 1,
    color: "#ffffff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: "#002453",
    color: "#ffffff",
  },
  completed: {
    backgroundColor: "#002453",
    color: "#ffcd00",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed, page, isPF } = props;
  const [icons, setIcons] = React.useState([]);

  useEffect(() => {
    selectIconsByPage();
  }, [page, isPF]);

  const selectIconsByPage = () => {
    let auxIconList = isPF ? iconsList.User : iconsList.Enterprise;
    setIcons(auxIconList);
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}

    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "transparent",
    marginBottom: "2em",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  customTooltip: {
    textAlign: "justify",
    backgroundColor: "#002453",
  },
  customArrow: {
    color: "#002453",
  }
}));

export function CorreosIdStepper(props) {
  const classes = useStyles();
  const { step, page } = props;
  const { t, i18n } = useTranslation();

  const [steps, setSteps] = React.useState([]);

  useEffect(() => {
    setSteps(generalPaths[page]);
  }, [page]);

  const getLabel = (label) => {
    let auxLabel = t(label);
    if (label === "subscriptionToApp") {
      let isCid = getReplaceApplicationOid() == process?.env?.REACT_APP_APP_OID;
      if (isCid) {
        auxLabel = t("requiredData");
      } else {
        auxLabel = auxLabel.replace("{appName}", getSessionItem(sessionValues.appName));
      };
    };
    return auxLabel;
  };

  const getToolTip = (label) => {
    let auxLabel = t(label + "TextLabel");
    let isCid = getReplaceApplicationOid() == process?.env?.REACT_APP_APP_OID;
    if (label === "subscriptionToApp" && isCid) {
      auxLabel = t("userIdentityTextLabel");
    };
    return auxLabel;
  };

  return (
    <Grid item sm={6} xs={12} style={{ margin: "auto", marginTop: "2.5em", maxWidth: "100%" }}>
      <Stepper
        alternativeLabel
        activeStep={step}
        className={classes.root}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => (
          <CorreosToolTip text={getToolTip(label)} key={"step" + index}>
            <Step key={label}>
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                StepIconProps={props}
              >
                {getLabel(label)}
              </StepLabel>
            </Step>
          </CorreosToolTip>
        ))}
      </Stepper>
    </Grid>
  );
};
