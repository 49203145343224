import React from "react";
import {
    Grid,
    Container,
    Paper,
    CircularProgress,
    Typography,
    Dialog,
    Button
} from "@material-ui/core";
// import {
//     checkIsNullUndefined,
//     getErrorMessage,
// } from "../../commons/Utils";
// import {
//     obtainAttribute,
//     isResultOk,
//     showAlert,
// } from "../../commons/FormManager";
// import { CommonStyles } from "../../commons/CommonStyles";
// import { CorreosButton } from "../../components/CorreosButton/CorreosButton";
// import { GetUtilitiesCorreosIdConfigApplication, PostUtilitiesCorreosIdUnifyAccounts, } from "../../services/UtilitiesCallsService";
// import { GetMasterCountry, GetMasterTypeUserDocument, GetMasterLanguage, GetMasterGenders, } from "../../services/MasterCallsService";
// import { GetProfileUserExtended } from "../../services/ReasonService";
// import { Attributes } from "../../commons/Attributes";
import { useTranslation } from "react-i18next";
// import history from "../../../services/history";
// import {
//     getApplicationOid, getSecureItem, removeSecureItem,
// } from "../../services/Commons";
// import { BasicDialog } from "../CommonComponents/BasicDialog";
// import { UnifyAccountsStyles } from "./UnifyAccountsStyles";
// import { getScreenWidth } from "../../commons/Utils";
// import { preparePage } from "./Components/PreparePageFunctions";
// import { handleStepAux, mountUserDataTextAux, mountWebFormsAux, mountWebTitlesAux } from "./Components/MountForms";
// import { DataVerification } from "./Components/DataVerification";
// import { secureValues } from "../../commons/EnumsGeneral";

export function UnifyMovil(props) {
    const { 
        openUserDataDialog,
        setOpenUserDataDialog,
        lastLoginCircularProgress,
        userToViewMobileInfoCodeList,
        getInfoUser,
        userToViewCodeList,
        mountUserDataText,
        pageCircularProgress,
        handleStep,
        handlePrevButtons,
        handleNextButtons,
        classesObject,
     } = props;
    // const commonClasses = CommonStyles();
    // const classes = UnifyAccountsStyles();
    // const classesObject = { commonClasses, classes };
    const { t, i18n } = useTranslation();

    return (
        <Container className={classesObject.commonClasses.registerContainer}>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={openUserDataDialog}
                onClose={() => setOpenUserDataDialog(false)}
            >
                <Container className={classesObject.commonClasses.dialogTitle}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                className={classesObject.commonClasses.dialogTitleText}
                            >
                                {t("userDataMobile")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
                <Container className={classesObject.commonClasses.dialogContainer}>
                    <Grid container item xs={12} spacing={1}>
                        {process.env.REACT_APP_ENVELOP_ACTIVE_LAST_LOGIN == "true" ? (
                            <>
                                {lastLoginCircularProgress ? <CircularProgress style={{ margin: "auto" }}></CircularProgress> : (
                                    userToViewMobileInfoCodeList.map((auxCode) => {
                                        return getInfoUser(auxCode);
                                    })
                                )}
                            </>
                        ) : null}
                        {userToViewCodeList.map((auxCode) => {
                            return mountUserDataText(auxCode);
                        })}
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        className={classesObject.commonClasses.dialogButtonContainer}
                    >
                        <Grid item xs={12}>
                            <Button
                                size="small"
                                onClick={() => setOpenUserDataDialog(false)}
                                variant="contained"
                                color="primary"
                                className={classesObject.commonClasses.dialogButtonCenter}
                            >
                                {t("backButton")}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>
            {pageCircularProgress ? (
                <CircularProgress />
            ) : (
                <Grid item container>
                    < Paper style={{ margin: "1em" }}>
                        <Grid item xs={12}>
                            <Typography variant="h4" className={classesObject.commonClasses.gridTitle} style={{ marginTop: "1.5em" }}>
                                <strong>{t("unifyAccountsTitle")}</strong>
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            {handleStep()}
                        </Grid>
                        <Grid container item xs={12} style={{ marginBottom: "1em", padding: "0.25em" }}>
                            <Grid item xs={6}>
                                {handlePrevButtons()}
                            </Grid>
                            <Grid item xs={6}>
                                {handleNextButtons()}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            )}
        </Container>
    );
}
