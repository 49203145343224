import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Card,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import {
  GetUtilitiesCorreosIdApplicationOid,
} from "../../services/UtilitiesCallsService";
import {
  GetMasterTycApplication,
  GetMasterPrivacyApplication
} from "../../services/MasterCallsService";
import { Header } from "./components/Header";
import { checkIsNullUndefined, getErrorMessage } from "../../commons/Utils";
import { TyCPrivacyStyles } from "./components/TyCPrivacyStyles";
import { useTranslation } from "react-i18next";
import { isResultOk, showAlert } from "../../commons/FormManager";
import { Alerts } from "../../components/Alerts/Alerts";
import { Constants } from "../../commons/Constants";


export function TyCPrivacyPage() {

  const classes = TyCPrivacyStyles();
  const { t } = useTranslation();
  const [circularProgress, setCircularProgress] = useState(true);
  const [tycData, setTycData] = useState([]);
  const [privacyData, setPrivacyData] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });

  useEffect(() => {

    const queryString = decodeURIComponent(window.location.search);
    const urlParams = new URLSearchParams(queryString);

    let appCode = urlParams.get("aC");
    let personType = urlParams.get("pT");
    let auxNeedsCID = false;

    if (appCode.length >= 4) {
      auxNeedsCID = appCode.charAt(3) == 1 && appCode.length == 4;
      appCode = appCode.slice(0, 3);
    }
    getInfo(appCode, personType, auxNeedsCID);
  }, []);

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  const getInfo = (appCode, personType, auxNeedsCID) => {
    if (appCode != Constants.cidCode) {
      GetUtilitiesCorreosIdApplicationOid(appCode).then((appOid) => {
        if(appOid && !appOid.Status){
          getPricAndTyCs(appCode, appOid, personType, auxNeedsCID);
        }else{
          finishOperation("error", getErrorMessage(appOid, t));
        };
      });
    } else {
      getPricAndTyCs(appCode, process?.env?.REACT_APP_APP_OID, personType, auxNeedsCID);
    };
  };

  const getPricAndTyCs = (appCode, appOid, personType, auxNeedsCID) => {
    let getMasterTycApplication = GetMasterTycApplication(appOid);
    let getMasterPrivacyApplication = GetMasterPrivacyApplication(appOid);
    let getCidMasterTycApplication = GetMasterTycApplication(process?.env?.REACT_APP_APP_OID);
    let getCidMasterPrivacyApplication = GetMasterPrivacyApplication(process?.env?.REACT_APP_APP_OID);
    Promise.all([getMasterTycApplication, getMasterPrivacyApplication, getCidMasterTycApplication, getCidMasterPrivacyApplication]).then((results) => {
      let masterTycApplication = results[0];
      let masterPrivacyApplication = results[1];
      let cidMasterTycApplication = results[2];
      let cidMasterPrivacyApplication = results[3];
      if (isResultOk([masterTycApplication, masterPrivacyApplication, cidMasterTycApplication, cidMasterPrivacyApplication])) {
        let auxAppTyC = checkIsArray(masterTycApplication);
        let auxCidTyC = checkIsArray(cidMasterTycApplication);
        let auxAppPriv = appCode == Constants.cidCode ? masterPrivacyApplication : filterForNotCid(masterPrivacyApplication);
        let auxCidPriv = cidMasterPrivacyApplication;
        let auxTyC = appCode == Constants.cidCode ? auxAppTyC : auxAppTyC.concat(auxCidTyC);
        let auxPrivacy = appCode == Constants.cidCode ? auxAppPriv : auxAppPriv.concat(auxCidPriv);
        auxTyC = filterPersonType(auxTyC, personType);
        auxPrivacy = filterPersonType(auxPrivacy, personType);
        if (!auxNeedsCID && appCode != Constants.cidCode) {
          auxTyC = filterForNotCid(auxTyC);
          auxPrivacy = filterForNotCid(auxPrivacy);
        }
        setTycData(auxTyC);
        setPrivacyData(auxPrivacy);
        setCircularProgress(false);
      } else {
        showAlert([masterTycApplication, masterPrivacyApplication, cidMasterTycApplication], finishOperation, t);
      };
    });
  };

  const filterForNotCid = (auxList) => {
    return auxList.filter((auxData) => auxData.appName !== "Correos ID");
  };

  const filterPersonType = (auxList, personType) => {
    let auxilList = auxList;
    let particularList = auxList.filter((obj) => checkIsNullUndefined(obj.personType) || obj.personType == 1);
    let enterpriseList = auxList.filter((obj) => checkIsNullUndefined(obj.personType) || obj.personType == 2);
    if (particularList.length !== 0 && enterpriseList.length !== 0) {
      if (checkIsNullUndefined(personType) || personType == 1) {
        auxilList = particularList;
      } else {
        auxilList = enterpriseList;
      };
    };
    return auxilList;
  };

  const checkIsArray = (list) => {
    let auxList = [];
    if (!Array.isArray(list)) {
      auxList.push(list);
    } else {
      auxList = list;
    };
    return auxList;
  };

  return (
    <div>
      <Header setShowSideMenu="false" />
      <Grid className={classes.container}>
        <Alerts alert={alert} setAlert={setAlert}></Alerts>
        {!circularProgress ?
          <Container maxWidth={"lg"} className={classes.mainContainer}>
            <Card className={classes.cardContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography align="justify" className={classes.text}>

                    <p>{t("tycUrlsText")}</p>
                    <ul>

                      {tycData.length !== 0 ? tycData.map((tyc) => {
                        return <li>
                          <a href={tyc.url} className={classes.links} Target="_blank" rel="noopener noreferrer">{tyc.appName}: {tyc.name} </a>
                        </li>
                      }): <p>{t("tycNoUrlsText")}</p>}
                    </ul>
                    <p>{t("privacyUrlsText")}</p>
                    <ul>
                      {privacyData.length !== 0 ? privacyData.map((privacy) => {
                        return <li>
                          <a href={privacy.url} className={classes.links} Target="_blank" rel="noopener noreferrer">{privacy.appName}: {privacy.name} </a>
                        </li>
                      }) : <p>{t("privacyNoUrlsText")}</p>}
                    </ul>
                    <p>{t("atcText")}</p>
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Container>
          : <CircularProgress style={{ marginTop: "20%", marginLeft: "auto", marginRight: "auto" }} />}
      </Grid>
    </div>
  );
}