import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { StylesBackgroundLogin } from "./Styles/StylesBackgroundLogin";
import { Typography, Grid, Container } from "@material-ui/core";
import { checkIsNullUndefined } from "../../commons/Utils";
import { useTranslation } from "react-i18next";
import { getEntranceUrl, getSessionItem, } from "../../services/Commons";
import { EnumPages } from "../../commons/EnumPages";
import { CommonStyles } from "../../commons/CommonStyles";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { CorreosToolTip } from "../CommonComponents/CorreosToolTip/CorreosToolTip";
import { sessionValues } from "../../commons/EnumsGeneral";
import history from "../../services/history.js";
import { Constants } from "../../commons/Constants.js";

export function BackgroundLogin(props) {
  const { idPage } = useParams();
  const classes = StylesBackgroundLogin();
  const { t, i18n } = useTranslation();
  const { size, login, resetPass, registry, step } = props;
  const loginMargin = "1rem";
  const commonClasses = CommonStyles();

  const [appName, setAppName] = useState();
  const [appCode, setAppCode] = useState();
  const [allowSignup, setAllowSignup] = useState();

  useEffect(() => {
    let signupAllowed = getSessionItem(sessionValues.allowSignup);
    setAllowSignup(checkIsNullUndefined(signupAllowed) ? "true" : signupAllowed);
  }, []);

  useEffect(() => {
    setAppName(getSessionItem(sessionValues.appName));
    setAppCode(getSessionItem(sessionValues.appCode));
  }, [idPage]);

  const goToRegistry = () => {
    history.push(getEntranceUrl(EnumPages.Registry));
  };

  return (
    <Grid>
      <Container
        maxWidth={checkIsNullUndefined(size) ? "sm" : size}
        className={login || resetPass ? classes.publicLoginDiv : classes.publicRegistryDiv}
      >
        {login ?
          <Grid style={{ textAlign: "left" }}>
            <Typography variant="h4" className={commonClasses.loginTitleColor}>
              {t("logInTitle")}
            </Typography>
            {appCode === "MOO" || allowSignup === "false" ? null :
              <Grid style={{ marginTop: loginMargin }}>
                <Typography variant="h6">
                  {t("loginPageInfo")}
                  <button
                    onClick={goToRegistry}
                    className={commonClasses.button_link}
                  >
                    {t("loginPageRegistryHere")}
                  </button>
                </Typography>
              </Grid>
            }
            <Grid style={{ marginTop: loginMargin }}>
              <Typography classes={{ root: "font-cartero-light" }} variant="inherit" style={{ fontSize: "150%" }}>
                {checkIsNullUndefined(appCode) || appCode === Constants.cidCode ? t("sublogInTitleCID") : t("sublogInTitle").replace("{appName}", appName)}
                {checkIsNullUndefined(appCode) || appCode === Constants.cidCode ? t("sublogInTitleInfoCID") : t("sublogInTitleInfo")}
                <CorreosToolTip text={t('loginTooltip')}>
                  <InfoOutlinedIcon style={{ color: "#002453", fontSize: '100%', marginLeft: '0.2em', paddingBottom: "0.1em", cursor: "pointer" }} />
                </CorreosToolTip>
              </Typography>
            </Grid>
          </Grid> : null}
        {registry && allowSignup == "true" ? (
          <Grid>
            <Typography variant="h5">
              {step === 0 ? t("registerPagePutData") : step === 1 ? t("registerPageConfirmIdentity") : null}
            </Typography>
          </Grid>
        ) : null}
        {resetPass ? (
          <Grid style={{ textAlign: "left" }}>
            <Grid style={{ marginTop: loginMargin }}>
              <Typography variant="h4">
                {step == 0 ? t("resetPasswordTitle") : step == 1 ? t("resetPasswordTitle2") : t("lCFinalStep")}
              </Typography>
            </Grid>
            {step != 2 ? (
              <Grid style={{ marginTop: loginMargin }}>
                {step == 0 ? (
                  <Typography classes={{ root: "font-cartero-light" }} variant="inherit" style={{ fontSize: "150%" }}>
                    {t("resetPasswordText")}
                  </Typography>
                ) : (
                  <Typography variant="h6" style={{ fontWeight: 300 }}>
                    {t("resetPasswordText2")}
                  </Typography>
                )}
              </Grid>
            ) : null}
            {step == 0 ? (<Grid style={{ marginTop: loginMargin }}>
              <Typography variant="h6" style={{ fontWeight: 300 }}>
                {t("lAddUser")}
              </Typography>
            </Grid>) : null}
          </Grid>
        ) : null}
        {props.children}
      </Container>
    </Grid>
  );
}
