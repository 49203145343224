import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Container,
  CircularProgress,
} from "@material-ui/core";
import { UserVerificationsStyles } from "./UserVerificationsStyles";
import { CommonStyles } from "../../commons/CommonStyles";
import { MdMail } from "react-icons/md";
import { MdPhoneAndroid } from "react-icons/md";
import { FaIdCard } from "react-icons/fa";
import { MdMarkunreadMailbox } from "react-icons/md";
import {
  GetProfileUser,
} from "../../services/ReasonService";
import {
  GetMasterTypeVerification,
  GetMasterTypeVerificationDescription,
  GetMasterCountry,
  GetMasterTypeUserDocument,
} from "../../services/MasterCallsService";
import { AttributeCodes, Attributes } from "../../commons/Attributes";
import { DialogDelete } from "../VerificationComponents/DialogDelete";
import { DialogResetPassword } from "../VerificationComponents/DialogResetPassword";
import { Alerts } from "../Alerts/Alerts";
import { ActionsCard } from "../VerificationComponents/ActionsCard";
import { checkIsNullUndefined, getCountryByAnyIdValue } from "../../commons/Utils";
import { Constants } from "../../commons/Constants";
import { CorreosButton } from "../../components/CorreosButton/CorreosButton";
import { useTranslation } from "react-i18next";
import { DialogShowAddress } from "../CommonComponents/Dialogs/DialogShowAddress";
import { controlUserMovement, getReplaceMode } from "../../services/Commons";
import { SecondFACard } from "../VerificationComponents/SecondFACard";
import { isResultOk, showAlert } from "../../commons/FormManager";
import history from "../../services/history.js";
import { EnumPages } from "../../commons/EnumPages.js";

export function UserVerifications() {
  const commonClasses = CommonStyles();
  const classes = UserVerificationsStyles();
  const { t, i18n } = useTranslation();

  const [circularProgress, setCircularProgress] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [openReset, setOpenReset] = useState(false);
  const [auxVerifyList, setAuxVerifyList] = useState([]);
  const [userEmails, setUserEmails] = useState([]);
  const [userPhones, setUserPhones] = useState([]);
  const [userDocuments, setUserDocuments] = useState([]);
  const [userAddresses, setUserAddresses] = useState([]);
  const [masterCountryList, setMasterCountryList] = useState([]);
  const [masterTypeUserDocument, setMasterTypeUserDocument] = useState([]);
  const [hasVerifiedPhones, setHasVerifiedPhones] = useState(false);

  useEffect(() => {
    chargePageData();
  }, []);

  useEffect(() => {
    let userVerifiedPhone = userPhones.find((phone) => phone.idConTypeVerification >= 5);
    setHasVerifiedPhones(!checkIsNullUndefined(userVerifiedPhone));
  }, [userPhones]);

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  const getVerificableName = (verificable, tipo) => {
    let auxName = verificable[Attributes[tipo].attributeName];
    if (tipo === AttributeCodes.Phone) {
      let country = getCountryByAnyIdValue(masterCountryList, verificable.idCountry, "id");
      auxName = country.phonePrefix + "-" + auxName;
    };
    return auxName;
  };

  const chargePageData = () => {
    setCircularProgress(true);
    let getMasterTypeVerificationDescription = GetMasterTypeVerificationDescription();
    let getMasterTypeVerification = GetMasterTypeVerification();
    let getProfileUser = GetProfileUser();
    let getMasterCountry = GetMasterCountry();
    let getMasterTypeUserDocument = GetMasterTypeUserDocument();
    Promise.all([getMasterTypeVerificationDescription, getMasterTypeVerification, getProfileUser, getMasterCountry, getMasterTypeUserDocument]).then((results) => {
      let masterVerificationDescription = results[0]
      let masterVerification = results[1];
      let profileUser = results[2];
      let masterCountryList = results[3];
      let masterUserDocumentType = results[4];
      if (isResultOk([masterVerificationDescription, masterVerification, profileUser, masterCountryList, masterUserDocumentType])) {
        setMasterCountryList(masterCountryList);
        setMasterTypeUserDocument(masterUserDocumentType);
        let tempVerifyList = [];
        masterVerification.forEach((verification) => {
          let auxVerifyObj = masterVerificationDescription.find((description) => verification.idDescription === description.id);
          if (!checkIsNullUndefined(auxVerifyObj)) {
            tempVerifyList.push({
              id: auxVerifyObj.id,
              idLevel: verification.idLevel,
              description: auxVerifyObj.name,
            });
          };
        });
        setAuxVerifyList(tempVerifyList);
        getSetVerificationLevel(tempVerifyList, profileUser.userEmail);
        getSetVerificationLevel(tempVerifyList, profileUser.userPhone);
        getSetVerificationLevel(tempVerifyList, profileUser.userDocument);
        getSetVerificationLevel(tempVerifyList, profileUser.userAddress);
        setUserEmails(profileUser.userEmail);
        setUserPhones(profileUser.userPhone);
        setUserDocuments(profileUser.userDocument);
        setUserAddresses(profileUser.userAddress);
        setCircularProgress(false);
      } else {
        showAlert([masterVerificationDescription, masterVerification, profileUser, masterCountryList, masterUserDocumentType], finishOperation, t);
      };
    });
  };

  const getSetVerificationLevel = (tempVerifyList, userDataList) => {
    userDataList.forEach((data) => {
      let auxVerifyData = tempVerifyList.find((verify) => verify.id === data.idConTypeVerification);
      if (!checkIsNullUndefined(auxVerifyData)) {
        data.idLevel = auxVerifyData.idLevel;
        data.verifyDesc = auxVerifyData.description;
      };
    });
  };

  const addVerificable = (verificable, tipo, operationType) => {
    let auxObject = getVerificableListAndSetter(tipo);
    let auxList = auxObject.list;
    let auxSet = auxObject.setter;
    let auxVerificable;
    if (operationType == "add") {
      auxVerificable = verificable;
    } else {
      auxVerificable = auxList.find((auxVerificable) => auxVerificable.id === verificable.id);
    };
    let auxIdConTypeVerification = tipo == AttributeCodes.Phone ? 5 : 2;
    let auxVerifyData = auxVerifyList.find((verify) => verify.id === auxIdConTypeVerification);
    auxVerificable.idConTypeVerification = auxIdConTypeVerification;
    auxVerificable.idLevel = auxVerifyData.idLevel;
    auxVerificable.verifyDesc = auxVerifyData.description;
    if (operationType == "add") {
      let auxId = getAuxId(auxList);
      verificable.id = auxId;
      auxList.push(verificable);
    };
    auxSet(auxList);
  };

  const getAuxId = (list) => {
    let auxList = list.map((verificable) => verificable.id);
    let auxId;
    do {
      auxId = Math.floor(Math.random() * 900) + 100; // Genera un número aleatorio de tres cifras
    } while (auxList.includes(auxId));
    return auxId;
  };

  const deleteVerificable = (verificable, tipo) => {
    let auxObject = getVerificableListAndSetter(tipo);
    let auxList = auxObject.list;
    let auxSet = auxObject.setter;
    let newList = auxList.filter((auxVerificable) => auxVerificable.id !== verificable.id);
    auxSet(newList);
  };

  const getVerificableListAndSetter = (tipo) => {
    switch (tipo) {
      case AttributeCodes.Email:
        return { list: JSON.parse(JSON.stringify(userEmails)), setter: setUserEmails };
      case AttributeCodes.Phone:
        return { list: JSON.parse(JSON.stringify(userPhones)), setter: setUserPhones };
      case AttributeCodes.DocNumber:
        return { list: JSON.parse(JSON.stringify(userDocuments)), setter: setUserDocuments };
      case AttributeCodes.Alias:
        return { list: JSON.parse(JSON.stringify(userAddresses)), setter: setUserAddresses };
      default:
        break;
    };
  };

  const getIcon = (tipo) => {
    switch (tipo) {
      case AttributeCodes.Email:
        return <MdMail className={commonClasses.iconStyle} />;
      case AttributeCodes.Phone:
        return <MdPhoneAndroid className={commonClasses.iconStyle} />;
      case AttributeCodes.DocNumber:
        return <FaIdCard className={commonClasses.iconStyle} />;
      case AttributeCodes.Alias:
        return <MdMarkunreadMailbox className={commonClasses.iconStyle} />;
      default:
        break;
    }
  };

  const buildDeleteVerificable = (verificable, fromList, tipo) => {
    let auxVerificable;
    if (fromList) {
      auxVerificable = verificable;
    } else {
      auxVerificable = getVerificableListAndSetter(tipo).list.find((auxilVerificable) => auxilVerificable.id == verificable);
    };
    return (
      <DialogDelete
        verificable={auxVerificable}
        tipo={tipo}
        finishOperation={finishOperation}
        getVerificableName={getVerificableName}
        deleteVerificable={deleteVerificable}
        masterTypeUserDocument={masterTypeUserDocument}
      />
    );
  };

  const buildListCard = (verifyList, tipo) => {
    return (
      <Grid
        item
        container
        spacing={1}
        md={12}
        className={commonClasses.listStyle}
      >
        <Grid item xs={1} className={commonClasses.listItemStyle}>
          {getIcon(tipo)}
        </Grid>
        <Grid item xs={11} className={commonClasses.listItemStyle}>
          <Typography variant="h6">{t("verifications" + tipo)}</Typography>
        </Grid>
        {buildInfoList(verifyList, tipo)}
      </Grid>
    );
  };

  const buildInfoList = (list, tipo) => {
    let auxVerifiedList = list.filter((verificable) => verificable.idLevel >= Constants.lvlVerification.medium);
    let auxNotVerifiedList = list.filter((verificable) => verificable.idLevel < Constants.lvlVerification.medium);
    return (
      <>
        {auxVerifiedList.length == 0 && auxNotVerifiedList.length == 0 ? (
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography className={commonClasses.listTextStyleShow}>
                {t("verificationsEmpty")}
              </Typography>
            </Grid>
          </Grid >
        ) : (
          <>
            {
              auxVerifiedList.length > 0 ? (
                auxVerifiedList.map((verificable, index) => {
                  return infoListForm(tipo, verificable, true, index);
                })
              ) : null
            }
            {
              auxNotVerifiedList.length > 0 ? (
                auxNotVerifiedList.map((verificable, index) => {
                  return infoListForm(tipo, verificable, false, index);
                })
              ) : null
            }
          </>
        )}
      </>
    );
  };

  const infoListForm = (tipo, verificable, verified, index) => {
    return (
      <Grid item container md={12} xs={12} key={index + verified}>
        <Grid item xs={12} sm={6}>
          <Typography className={commonClasses.listTextStyleShow} noWrap>
            {getVerificableName(verificable, tipo)}
          </Typography>
        </Grid>
        <Grid item xs={10} sm={4}>
          <Typography
            className={
              verified
                ? commonClasses.listTextStyleGreen
                : commonClasses.listTextStyleOrange
            }
          >
            {verificable.verifyDesc}
          </Typography>
        </Grid>
        {tipo === AttributeCodes.Alias ? (
          <Grid item xs={1} className={commonClasses.iconGridStyle}>
            <DialogShowAddress address={verificable} isUser={true} />
          </Grid>
        ) : (
          <Grid item xs={1}></Grid>
        )}
        <Grid item xs={1} className={commonClasses.iconGridStyle}>
          {buildDeleteVerificable(verificable, true, tipo)}
        </Grid>
      </Grid>
    );
  };

  const buildActionsCard = (verificables, tipo) => {
    let auxVerifiedList = verificables.filter((verificable) => verificable.idLevel < Constants.lvlVerification.medium);
    if (auxVerifiedList.length > 0) {
      return (
        <ActionsCard
          tipo={tipo}
          verificables={auxVerifiedList}
          getIcon={getIcon}
          getVerificableName={getVerificableName}
          buildDeleteVerificable={buildDeleteVerificable}
          finishOperation={finishOperation}
          masterCountryList={masterCountryList}
          addVerificable={addVerificable}
        />
      );
    }
  };

  const handleResetPassword = () => {
    setOpenReset(true);
  };

  const buildSecondFACard = () => {
    let emailsList = getVerifiedValues(AttributeCodes.Email);
    let emailsNames = emailsList.map((email) => email.email);
    return <SecondFACard
      finishOperation={finishOperation}
      emails={emailsNames}
      hasVerifiedPhones={hasVerifiedPhones}
      addVerificable={addVerificable}
    />;
  };

  const getVerifiedValues = (tipo) => {
    let auxList = getVerificableListAndSetter(tipo).list;
    let auxVerifiedList = auxList.filter((verificable) => verificable.idLevel >= Constants.lvlVerification.medium);
    return auxVerifiedList;
  };

  const goToUnifyAccounts = () => {
    let redirect = "/" + EnumPages.UnifyAccounts;
    history.push(redirect);
  };

  return (
    <Grid>
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      <Typography variant="h4" className={commonClasses.title}>
        {t("UserVerifications")}
      </Typography>
      <Typography
        variant="subtitle2"
        className={commonClasses.title}
        style={{ textAlign: "center", marginBottom: "2em", color: "#666666", fontSize: "120%" }}
      >
        {t("verificationsPageSubtitle")}
      </Typography>
      <Container maxWidth={"lg"} className={commonClasses.mainContainer}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            container
            spacing={1}
            className={commonClasses.actionCardsContainer}
          >
            {circularProgress ? (
              <CircularProgress className={commonClasses.circularProgress} />
            ) : (
              <Grid item md={12} xs={12}>
                {buildSecondFACard()}
                {buildActionsCard(userEmails, AttributeCodes.Email)}
                {buildActionsCard(userPhones, AttributeCodes.Phone)}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} container spacing={1}>
            {circularProgress ? (
              <CircularProgress className={commonClasses.circularProgress} />
            ) : (
              <Grid item xs={12}>
                <Card className={commonClasses.generalCardContainer}>
                  <CardContent>
                    <Grid
                      container
                      style={{ textAlign: "center" }}
                      spacing={1}
                      alignItems="flex-end"
                    >
                      {buildListCard(
                        userEmails,
                        AttributeCodes.Email
                      )}
                      {buildListCard(
                        userPhones,
                        AttributeCodes.Phone
                      )}
                      {buildListCard(
                        userDocuments,
                        AttributeCodes.DocNumber
                      )}
                      {buildListCard(
                        userAddresses,
                        AttributeCodes.Alias
                      )}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      className={classes.buttonContainer}
                    >
                      <Grid item xs={12} sm={6}>
                        <CorreosButton
                          variant="contained"
                          color="primary"
                          onClick={goToUnifyAccounts}
                          className={commonClasses.buttonLeftCenter}
                        >
                          {t("goToUnifyAccountsButton")}
                        </CorreosButton>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CorreosButton
                          variant="contained"
                          color="primary"
                          onClick={() => handleResetPassword()}
                          className={commonClasses.buttonRightCenter}
                        >
                          {t("resetPassword")}
                        </CorreosButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>


        </Grid>
        {getReplaceMode() == "true" ? (
          <Grid container spacing={1} style={{ marginTop: "1.5em" }}>
            <Grid item xs={12}>
              <CorreosButton
                onClick={() => { controlUserMovement(false, finishOperation, t) }}
                variant="contained"
                color="primary"
                className={commonClasses.dialogButtonCenter}
              >
                {t("backToTheApp")}
              </CorreosButton>
            </Grid>
          </Grid>) : null}
        <DialogResetPassword
          verifiedEmails={getVerifiedValues(AttributeCodes.Email)}
          verifiedPhones={getVerifiedValues(AttributeCodes.Phone)}
          open={openReset}
          setOpen={setOpenReset}
          setAlert={setAlert}
        />
      </Container>
    </Grid>
  );
}
