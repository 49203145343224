import React, { useEffect, useState } from "react";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import {
  Grid,
  Dialog,
  Container,
  TextField,
  FormControl,
  Select,
  InputLabel,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { checkIsNullUndefined, getErrorMessage } from "../../../commons/Utils";
import { CommonStyles } from "../../../commons/CommonStyles";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { EnterpriseTeamStyles } from "../EnterpriseTeamStyles";
import { PostInvitationInvitation } from "../../../services/ReasonService";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { useTranslation } from "react-i18next";

export function DialogSendInvitation(props) {
  const { roles, finishOperation, idEnterprise, getInfo } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const classes = EnterpriseTeamStyles();
  const validator = require("react-email-validator");
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const [option, setOption] = useState(3);
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [circularProgressButton, setCircularProgressButton] = useState(false);

  useEffect(() => {
      if (!checkIsNullUndefined(roles[0])) {
        let options = roles.filter((rol) => { return rol.id !== 1 });
        setOption(options[0].id);
      };
  }, [roles]);

  const loadSelect = () => {
    return (
      <Select
        color="secondary"
        native
        required
        id="rol"
        label={t("teamPageAuthorizedRol")}
        value={option}
        onChange={(value) => {
          handleOption(value);
        }}
        displayEmpty
      >
        {roles.map((element, i) => {
          if (element.id != 1) return <option value={element.id}>{element.name}</option>;
        })}
      </Select>
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOption = (value) => {
    setOption(value.target.value);
  };

  const handleChange = (event) => {
    let okEmail = true;
    if (!checkIsNullUndefined(event.target.value)) {
      okEmail = validator.validate(event.target.value);
    }
    setValue(event.target.value);
    setError(!okEmail);
  };

  const handleCancelAdd = () => {
    setOpen(false);
    setValue("");
    setOption(checkIsNullUndefined(roles[0]) ? 3 : roles[0].id);
    setCircularProgressButton(false);
  };

  const handleSendInvitation = () => {
    setCircularProgressButton(true);
    PostInvitationInvitation(
      value,
      parseInt(idEnterprise, 10),
      parseInt(option, 10)
    ).then((response) => {
      if (response && !response.Status) {
        //Alerta
        finishOperation("success", t("teamPageInvitationSuccess"));
        getInfo();
      } else {
        //Alerta
        finishOperation("error", getErrorMessage(response, t));
      }
      handleCancelAdd();
    });
  };

  return (
    <Grid>
      <Tooltip title={t("teamPageButtonAddEmployee")}>
        <IconButton
          onClick={() => handleClickOpen()}
          className={classes.iconButton}
        >
          <AiOutlineUsergroupAdd className={commonClasses.iconActionStyle} />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        open={open}
        onClose={() => handleCancelAdd()}
        PaperProps={{ classes: {root: dialogClasses.dialogWindow }}}
      >
        <Container className={commonClasses.dialogTitle}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={commonClasses.dialogTitleText}
              >
                {t("teamPageInsertNewMail")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={commonClasses.dialogContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
              // className={classes.formSelectorControl}
              >
                <InputLabel color="secondary">
                  {t("teamPageAuthorizedRol")}
                </InputLabel>
                {loadSelect()}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={value}
                color="secondary"
                variant="outlined"
                label={t("teamPageInsertMail")}
                fullWidth
                id="Mail"
                autoFocus
                required
                onChange={handleChange}
                error={error}
                helperText={error ? t("registerPageMailError") : ""}
                className={commonClasses.inputsStyle}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={commonClasses.dialogButtonContainer}
            >
              <Grid item xs={6}>
                <CorreosButton
                  color="secondary"
                  className={commonClasses.dialogButtonLeft}
                  onClick={handleCancelAdd}
                >
                  {t("cancel")}
                </CorreosButton>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  variant="contained"
                  color="primary"
                  className={commonClasses.dialogButtonRight}
                  onClick={handleSendInvitation}
                  circularProgress={circularProgressButton}
                  disabled={
                    checkIsNullUndefined(value) ||
                    checkIsNullUndefined(option) ||
                    error
                  }
                >
                  {t("send")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </Grid>
  );
}
