import { makeStyles } from "@material-ui/core/styles";

export const HeaderTyCStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffffff",
    borderTopWidth: "0.75em",
    borderTopColor: "#FFCD00",
    borderTopStyle: "solid",
  },
  buttonClass:{
    color: "#002453",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  imgIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "2.2em",
    height: "2.2em",
  },
  imgLogin: {
    maxWidth: "1.2em",
    height: "auto",
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
    color: "#002453"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logoutButton: {
    color: "#002453",
  },
  searcher: {
    marginRight: "18px",
    backgroundColor: "#f3f3f3",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    padding: "0px 8px 0px 8px",
  },
}));
