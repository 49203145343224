import { makeStyles } from "@material-ui/core/styles";

export const MainPageStyles = makeStyles((theme) => ({
  mainDiv: {
    textAlign: "center",
    minHeight: "60vh",
    position: "relative",
  },
  dialogButtonRight: {
    margin: "1em",
    border: "2px solid #FFCD00",
    '&:hover': {
        border: "2px solid #FFCD00",
    }
  },
  dialogContainer: {
    padding: "1em 1em 2em 1em",
    width: "98%",
  },
  dialogTitle: {
    padding: "1em 0.25em 0em 1em",
    color: "#002453",
    width: "98%",
  },
  dialogContentText: {
    padding: "1em 2em 0.25em 1em",
    color: "#333333",
  },
  closeButton: {
    // padding: "0px",
    marginTop:"-0.5em",
    marginRight: "-0.25em",
    float:"right"
   },
}))
