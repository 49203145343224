import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Container,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import {
  GetEnterpriseDataEnterprisePendingInvitations,
  GetEnterpriseDataEnterpriseUsers,
} from "../../services/EnterpriseDataCallsService";
import {
  GetMasterEnterpriseRol,
} from "../../services/MasterCallsService";
import { MdMail } from "react-icons/md";
import { MdPhoneAndroid } from "react-icons/md";
import { CommonStyles } from "../../commons/CommonStyles";
import { EnterpriseTeamStyles } from "./EnterpriseTeamStyles";
import ObjectsCard from "./components/ObjectsCard";
import { Alerts } from "../Alerts/Alerts";
import { checkIsNullUndefined, getErrorMessage } from "../../commons/Utils";
import { useTranslation } from "react-i18next";
import { HolderCard } from "./components/HolderCard";
import { UsersCard } from "./components/UsersCard";
import { getSecureItem } from "../../services/Commons";
import { secureValues } from "../../commons/EnumsGeneral";

export function EnterpriseTeam(props) {
  const { idEnterprise } = props;
  const commonClasses = CommonStyles();
  const classes = EnterpriseTeamStyles();
  const { t, i18n } = useTranslation();

  const [circularProgress, setCircularProgress] = useState(true);
  const [datosTitular, setDatosTitular] = useState([]);
  const [authorized, setAuthorized] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isTitular, setIsTitular] = useState(false);
  const [titular, setTitular] = useState({});
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });

  useEffect(() => {
    // Recuperar datos del titular del back
    if (roles.length === 0) {
      GetMasterEnterpriseRol().then((response) => {
        if (response && !response.Status) {
          setRoles(response);
          getInfo(response);
        } else {
          //Alerta
          finishOperation("error", getErrorMessage(response, t));
          setCircularProgress(false);
        }
      });
    } else {
      getInfo(roles);
    }
  }, [idEnterprise]);

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  const getInfo = (auxiliarRoles) => {
    let auxRoles = checkIsNullUndefined(auxiliarRoles) ? roles : auxiliarRoles;
    setCircularProgress(true);
    let actualUser = getSecureItem(secureValues.userOid);
    //Pendiente de recuperar la info correcta del back
    GetEnterpriseDataEnterprisePendingInvitations(idEnterprise).then(
      (response1) => {
        if (response1 && !response1.Status) {
          GetEnterpriseDataEnterpriseUsers(idEnterprise).then((response) => {
            if (response && !response.Status) {
              let titular = [];
              let users = [];
              let auxiliarUsers = response;
              let auxiliarInvitated = response1;
              //Añadimos los invitamos y modificamos los pendientes de proposal
              auxiliarUsers.forEach((enterpriseUser) => {
                auxiliarInvitated.forEach((invitedUser) => {
                  if (
                    invitedUser.valueProposal === enterpriseUser.userEmail ||
                    invitedUser.valueProposal === enterpriseUser.userPhone
                  ) {
                    enterpriseUser.idInvitation = invitedUser.id;
                  }
                });
              });
              auxiliarInvitated.forEach((invitedUser) => {
                let auxArray = auxiliarUsers.filter((enterpriseUser) => {
                  return (
                    invitedUser.valueProposal === enterpriseUser.userEmail ||
                    invitedUser.valueProposal === enterpriseUser.userPhone
                  );
                });
                if (auxArray.length === 0) {
                  let userObject = {};
                  userObject.idInvitation = invitedUser.id;
                  userObject.userOid = null;
                  userObject.idRole = "-";
                  userObject.rolName = t("teamPageInvited");
                  if (invitedUser.valueProposal.indexOf("@") !== -1) {
                    userObject.userEmail = invitedUser.valueProposal;
                    userObject.userPhone = null;
                  } else {
                    userObject.userEmail = null;
                    userObject.userPhone = invitedUser.valueProposal;
                  }
                  users.push(userObject);
                }
              });
              //Preparamos los usuarios
              auxiliarUsers.forEach((user) => {
                setCircularProgress(true);
                if (user.userOid === actualUser) {
                  setIsTitular(user.idRole == 1);
                  setTitular(user);
                }
                if (user.idRole == 1) {
                  titular.push(user);
                  prepareTitular(titular);
                }
                auxRoles.forEach((rol) => {
                  if (user.idRole === rol.id) {
                    user.rolName = rol.name;
                  }
                });
                if (user.idRole === 4) {
                  user.rolName = t("teamPageSystemUser");
                }
                users.push(user);
              });
              setAuthorized(users);
              setCircularProgress(false);
            } else {
              //Alerta
              finishOperation("error", getErrorMessage(response, t));
              setCircularProgress(false);
            }
          });
        } else {
          //Alerta
          finishOperation("error", getErrorMessage(response1, t));
          setCircularProgress(false);
        }
      }
    );
  };

  const getIcon = (name) => {
    switch (name) {
      case "mail":
        return <MdMail className={commonClasses.iconStyle} />;
      case "mobile":
        return <MdPhoneAndroid className={commonClasses.iconStyle} />;
      default:
        break;
    }
  };

  const prepareTitular = (titularList) => {
    let usableTitular = titularList.map((titu) => {
      return [
        {
          atribute: getIcon("mobile"), //holderMobile
          value: checkIsNullUndefined(titu.userPhone)
            ? "-"
            : "+" + titu.userPhone,
        },
        {
          atribute: getIcon("mail"), //holderEmail
          value: checkIsNullUndefined(titu.userEmail) ? "-" : titu.userEmail,
        },
      ];
    });
    setDatosTitular(usableTitular);
  };

  return (
    <Grid>
      <Typography variant="h4" className={commonClasses.title}>
         {t("EnterpriseTeam")}
      </Typography>
      <Typography
          variant="subtitle2"
          className={commonClasses.title}
          style={{ textAlign: "center", marginBottom: "2em", color:"#666666", fontSize:"120%"}}
      >
         {t("teamPageSubtitle")}
      </Typography>
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      {circularProgress ? (
        <CircularProgress className={classes.circularProgress} />
      ) : (
        <Container maxWidth={"lg"} className={commonClasses.mainContainer}>
          {isTitular ? (
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <HolderCard
                  isTitular={isTitular}
                  datosTitular={datosTitular}
                  authorized={authorized}
                  finishOperation={finishOperation}
                  idEnterprise={idEnterprise}
                  getInfo={getInfo}
                />
                <ObjectsCard
                  idEnterprise={idEnterprise}
                  finishOperation={finishOperation}
                  isTitular={isTitular}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <UsersCard
                  idEnterprise={idEnterprise}
                  authorized={authorized}
                  finishOperation={finishOperation}
                  getInfo={getInfo}
                  isTitular={isTitular}
                  roles={roles}
                  titular={titular}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid item xs={12}>
                      <Typography
                        align="center"
                        variant="h6"
                        className={commonClasses.gridTitle}
                      >
                        {t("teamPageAuthorizedTitularOnly")}
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Container>
      )}
    </Grid>
  );
}
