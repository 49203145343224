import React, { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";
import DehazeIcon from "@material-ui/icons/Dehaze";
import correosIdLogoBlue from "../../../assets/images/Cornamusa_Azul.svg";
import { HeaderTyCStyles } from "./HeaderTyCStyles";
import { backToLogin } from "../../../services/Commons";
import { useTranslation } from "react-i18next";

export function HeaderTyC(props) {
  const classes = HeaderTyCStyles();
  const { t } = useTranslation();

  useEffect(() => {}, []);

  const handleLogout = () => {
    backToLogin();
  };

  const handleList = () => {
    props.setShowSideMenu();
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.buttonClass}
          aria-label="menu"
          onClick={() => handleList()}
        >
          <DehazeIcon />
        </IconButton>
        <IconButton
          edge="start"
          color="inherit"
          className={classes.imgIcon}
          aria-label="menu"
          onClick={() => handleLogout()}
        >
          <img
            src={correosIdLogoBlue}
            className={classes.imgLogin}
          />
        </IconButton>
        <Typography variant="h6" className={classes.title} noWrap>
          {t("tycHeaderTitle")}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
