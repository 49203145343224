import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  CircularProgress,
  Card,
  CardContent,
  Typography
} from "@material-ui/core";
import { MdMail } from "react-icons/md";
import { MdPhoneAndroid } from "react-icons/md";
import { FaIdCard } from "react-icons/fa";
import { MdMarkunreadMailbox } from "react-icons/md";
import {
  GetEnterpriseData,
  GetEnterpriseDataRole,
} from "../../services/EnterpriseDataCallsService";
import { Attributes } from "../../commons/Attributes";
import { CommonStyles } from "../../commons/CommonStyles";
import { Alerts } from "../Alerts/Alerts";
import { AttributesVerifiedCard } from "./components/AttributesVerifiedCard";
import { FaFilePdf } from "react-icons/fa";
import { checkIsNullUndefined, getErrorMessage } from "../../commons/Utils";
import { useTranslation } from "react-i18next";
import { controlUserMovement, getReplaceMode } from "../../services/Commons";
import { CorreosButton } from "../CorreosButton/CorreosButton";

export function EnterpriseVerifications(props) {
  const { idEnterprise } = props;
  const commonClasses = CommonStyles();
  const { t, i18n } = useTranslation();

  const [documentNum, setDocumentNum] = useState("");
  const [directions, setDirections] = useState([]);
  const [circularProgress, setCircularProgress] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [openVerifiedPopper, setOpenVerifiedPopper] = useState(false);
  const [openNotVerifiedPopper, setOpenNotVerifiedPopper] = useState(false);
  const [isTitular, setIsTitular] = useState(null);

  useEffect(() => {
    setCircularProgress(true);
    GetEnterpriseDataRole(idEnterprise).then((response) => {
      if (response && !response.Status) {
        setIsTitular(response == 1);
        chargePageData();
      } else {
        finishOperation("error", getErrorMessage(response, t));
        setCircularProgress(false);
      }
    });
  }, [idEnterprise]);

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  const chargePageData = () => {
    setCircularProgress(true);
    GetEnterpriseData(idEnterprise).then((response) => {
      if (response && !response.Status) {
        setDocumentNum([{ value: response.documentNumber }]);
        setDirections(response.enterpriseAddress);
        if (checkIsNullUndefined(response.documentNumber)) {
          finishOperation("error", t("teamPageNoDocumentEnterprise"));
        }
        setCircularProgress(false);
      } else {
        finishOperation("error", getErrorMessage(response, t));
        setCircularProgress(false);
      }
    });
  };

  const getIcon = (name) => {
    switch (name) {
      case Attributes.UserEmail:
        return <MdMail className={commonClasses.iconStyle} />;
      case Attributes.PersonaTelfMovil:
        return <MdPhoneAndroid className={commonClasses.iconStyle} />;
      case Attributes.PersonaNumDocID:
        return <FaIdCard className={commonClasses.iconStyle} />;
      case Attributes.PersonaDireccion:
        return <MdMarkunreadMailbox className={commonClasses.iconStyle} />;
      case Attributes.Documents:
        return <FaFilePdf className={commonClasses.iconStyle} />;
      default:
        break;
    }
  };

  return (
    <div
      onClick={
        openVerifiedPopper
          ? () => setOpenVerifiedPopper(false)
          : openNotVerifiedPopper
            ? () => setOpenNotVerifiedPopper(false)
            : null
      }
    >

      <Grid>
        <Alerts alert={alert} setAlert={setAlert}></Alerts>
        <Typography variant="h4" className={commonClasses.title}>
          {t("UserVerifications")}
        </Typography>
        <Typography
          variant="subtitle2"
          className={commonClasses.title}
          style={{ textAlign: "center", marginBottom: "2em", color: "#666666", fontSize: "120%" }}
        >
          {t("verificationsEnterprisePageSubtitle")}
        </Typography>
        <Container maxWidth={"lg"} className={commonClasses.mainContainer}>
          <Grid container spacing={3}>
            <Grid item sm={6} container spacing={3}>
              {circularProgress ? (
                <CircularProgress />
              ) : (
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <AttributesVerifiedCard
                        documentNum={documentNum}
                        directions={directions}
                        getIcon={getIcon}
                        chargePageData={chargePageData}
                        finishOperation={finishOperation}
                        isTitular={isTitular}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
          {getReplaceMode() == "true" ? (
            <Grid container spacing={1} style={{ marginTop: "1.5em" }}>
              <Grid item xs={12}>
                <CorreosButton
                  onClick={() => { controlUserMovement(false, finishOperation, t) }}
                  variant="contained"
                  color="primary"
                  className={commonClasses.dialogButtonCenter}
                >
                  {t("backToTheApp")}
                </CorreosButton>
              </Grid>
            </Grid>
          ) : null}
        </Container>
      </Grid>
    </div>
  );
}
