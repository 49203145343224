export const GL2 = {

  //User Account Cancel Page

  accountCancelDisableAccountAsk: "¿Seguro que quieres darte de baja?",
  accountCancelDisableAccountTitle:
    "Ao darse de baixa de Correos ID deixará de ter acceso a todos os servizos que se mostran a continuación:",
  accountCancelDisableAccountListTitle: "Teña en conta que:",
  accountCancelDisableAccountList: "Borraremos a súa conta.",
  accountCancelDisableAccountP1:
    "Aconsellámoslle que antes de eliminar a súa conta de Correos ID solicite e finalice a baixa nos servizos nos que está rexistrado. Se aínda así segue preferindo eliminar agora a súa conta de Correos ID, prema en Dar de baixa.",
  accountCancelDisableAccountP2:
    "*Tranquilo, ten {disableAccDays} días para recuperar a súa conta de Correos ID por se se arrepinte. Poderá recuperar a conta de Correos ID, pero o resto de servizos poden non gardar os seus datos durante estes {disableAccDays} días.",
  accountCancelDisableAccountConfirm: "Dar de baixa",
  accountCancelDisableAccountLastWarn: "Si pulsas en aceptar confirmarás que quieres darte de baja",

  //Enterprise Delete Page

  enterpriseDeleteEnterpriseTitle: "Estás seguro de que queres eliminar a empresa {enterpriseName} ?",
  enterpriseDeleteEnterpriseTitleNoName: "Estás seguro de que queres eliminar a empresa?",
  enterpriseDeleteEnterpriseServicesTitle: "Esta empresa ten actualmente acceso aos seguintes servizos:",
  enterpriseDeleteEnterpriseText: "Unha vez eliminado non poderás recuperar os datos referidos a el",
  enterpriseDeleteButtonText: "Eliminar empresa",
  enterpriseDeleteAlertMessageSuccess: "A empresa eliminouse correctamente",
  enterpriseDeleteAlertMessageError: "Erro ao eliminar a empresa",
  enterpriseDeleteAlertLastWarning: "Si pulsas en aceptar confirmarás que quieres borrar la empresa",

  //Privacy page

  privacyPermissionsManage: "Consulta os termos e condicións",
  privacyPrivacyManage: "Xestione a súa privacidade",
  privacyUnsuscribe: "Retirar acceso aos meus datos",
  privacySuscribe: "Permitir acceso aos meus datos",
  privacyDownload: "Descargar o meu histórico de privacidade",
  privacySuccessChange: "Privacidade cambiada con éxito",
  privacyWarning: "Atención!",
  privacyInfo1:
    "Vai retirar o acceso de Mi Buzón á súa conta de Correos ID. Deberá conceder acceso de novo se quere volver acceder a Mi Buzón. Dende o momento que acepte, deixaremos de compartir os seus datos co servizo.",
  privacyInfo1_PARAM:
    "Vai retirar o acceso de {APP} á súa conta de Correos ID. Deberá conceder acceso de novo se quere volver acceder a {APP}. Dende o momento que acepte, deixaremos de compartir os seus datos co servizo.",
  privacyInfo2:
    "Se o que quere é que Mi Buzón elimine a súa conta, podemos axudarlle a xestionar a baixa premendo aquí.",
  noState: "Sen estado",
  privacyTyCLink: "Termos e condicións",
  privacyPrivacyLink: "Políticas de Privacidad",
  privacySignedTyCPrivacy: "Asinado:",
  privacyIntroText: "Revisa los términos y condiciones, así como las poíticas de privacidad firmadas para cada servicio suscrito.",
  privacyIntroText2: "Gestiona la privacidad de Correos ID. Puedes revisar el estado actual de la protección de datos y modificarlo si así lo requieres.",
  privacyDataProtectionTitle: "Protección de Datos",
  privacyEnableAllText: "Activar todos",
  privacyDisableAllText: "Desactivar todos",

  //Team Page

  holderName: "Nome",
  holderLastName: "1º Apelido",
  holderSecondLastName: "2º Apelido",
  holderPrefix: "Prefixo",
  holderMobile: "Móbil",
  holderEmail: "Correo electrónico",
  teamPageHolderTitle: "Titular da conta",
  teamPageAuthorizedTitle: "As miñas persoas autorizadas",
  teamPageAuthorizedName: "Nome",
  teamPageAuthorizedLastName: "Apelidos",
  teamPageAuthorizedState: "Estado",
  teamPageAuthorizedRol: "Rol",
  teamPageButtonAddEmployee: "Engadir Empregado",
  teamPageButtonChangeHolder: "Cambiar Titular",
  teamPageInsertMail: "Correo electrónico",
  teamPageInsertNewMail: "Introduza correo electrónico e rol",
  teamPageSelectHolder: "Seleccione o novo titular",
  teamPageRol: "Rol:",
  teamPageItemAdmin: "Administrador",
  teamPageItemAutorized: "Autorizado",
  teamPageChangeHolder: "Cambiar Titular",
  teamPageInvitationSuccess: "Convite enviado con éxito",
  teamPageTitularChangeSuccess: "Titular cambiado con éxito",
  teamPageTitularEmpty: "A empresa non ten titular",
  revokeAdminPermissions: "Quitar persmisos de administrador",
  revokeAdminPermissionsQuestion:
    "Si continúas, quitarás permisos de administrador a este empleado, pero seguirá siendo empleado y perteneciendo al equipo",
  revokeAdminConfirm: "Permisos quitados correctamente",
  teamPageAddAdminTitle: "Dar permisos de administrador",
  teamPageAddAdminConfirmMsg:
    "Se va a enviar un email para que el usuario acepte el rol de administrador.",
  teamPageSubtitle: "Consulta y gestiona el equipo de la empresa",

  //unifyAccounts Page

  unifyAccountsTitle: "Unificación de cuentas",
  unifyAccountsPreviousInfoTitle: "Información previa",
  unifyAccountsPreviousInfo: "Información que puede ser de ayuda de cada usuario",
  unifyAccountsSubTitle: "Selecciona de entre los datos de usuario que son distintos entre ambas cuentas con cuál de ellos prefieres quedarte:",
  unifyAccountsDataTitle: "Datos de Usuario",
  unifyAccountsData1: "Datos de {user1}",
  unifyAccountsData2: "Datos de {user2}",
  unifyAccountsMultidata: "Ahora selecciona si hay alguno de los emails, teléfonos o direcciones de {user2} que quieres añadir en el perfil de {user1}",
  unifyAccountsMultidataTitle: "Emails, teléfonos y direcciones",
  unifyAccountsEmails1: "Emails de {user1}",
  unifyAccountsEmails2: "Emails de {user2}",
  unifyAccountsPhones1: "Teléfonos de {user1}",
  unifyAccountsPhones2: "Teléfonos de {user2}",
  unifyAccountsAddress1: "Direcciónes de {user1}",
  unifyAccountsAddress2: "Direcciónes de {user2}",
  unifyAccountsInfo1: "Información de {user1}",
  unifyAccountsInfo2: "Información de {user2}",
  unifyAccountsEnterpriseTitle: "Empresas",
  unifyAccountsEnterprise: "Aquí puedes ver las empresas de {user1} y las que se van a unificar del {user2}",
  unifyAccountsEnteprise1: "Empresas de {user1}",
  unifyAccountsEnteprise2: "Empresas de {user2}",
  unifyAccountsServiceTitle: "Servicios",
  unifyAccountsService: "Aquí puedes ver las suscripciones de {user1} y las aplicaciones a las que se va a suscribir de {user2}",
  unifyAccountsService1: "Aplicaciones suscritas de {user1}",
  unifyAccountsService2: "Aplicaciones suscritas de {user2}",
  unifyAccountsNoData: "Este usuario no tiene datos de este tipo",
  unifyAccountsSuccess: "Unificación finalizada",
  unifyAccountsText: "Las dos cuentas se han unificado, ya puedes seguir navegando",
  unifyAccountsRedirectWarn: "Tienes que indicar la pantalla a la que hay que redirigir",
  unifyAccountIncorrectId: "El identificador de usuario proporcionado no corresponde con ninguna cuenta",
  unifyAccountsMobileNoDoc: "Sin documento",
  unifyAccountsMobileuserData: "Escoge el usuario cuyos datos quieras conservar",
  unifyAccountsMobileuserDocument: "Escoge qué documento quieres conservar",
  unifyAccountsMobileuserEmail: "Selecciona los emails de {user2} que quieras incorporar",
  unifyAccountsMobileuserPhone: "Selecciona los teléfonos de {user2} que quieras incorporar",
  unifyAccountsMobileuserAddress: "Selecciona las direcciones de {user2} que quieras incorporar",
  unifyAccountsMobileuserEnterprise: "Estas son todas las empresas que tendrás",
  unifyAccountsMobileuserSubscription: "Estás son todas las aplicaciones a las que estarás suscrito",
  unifyAccountsMobileuserInfo: "Aquí aportamos información útil de cada usuario",
  unifyAccountsMobileUserInfoLogin: "Último login: ",
  unifyAccountsUserInfoLogin: "Último login",

  //Mobile unifyAccouts

  userName: "Nombre",
  userSurname1: "Apellido 1",
  userSurname2: "Apellido 2",
  userbirthDate: "Fecha de nacimiento",
  userNacionality: "Nacionalidad",
  userDocument: "NIF",
  userGender: "Sexo",
  userLanguage: "Idioma",
  stepPage: "Paso {stepPage} de {totalSteps}",
  userInfoMobile: "Información útil",
  userDataMobile: "Datos de usuario",
  emailsMobile: "Emails",
  phonesMobile: "Teléfonos",
  documentsMobile: "Documentos",
  addressMobile: "Direcciones",
  servicesMobile: "Suscripciones",
  enterpriseMobile: "Empresas",
  nextStep: "Siguiente",
  previousStep: "Anterior",
  saveMobile: "Guardar",

  //UploadDocumentModal

  uploadDocumentModalContent:
    "Por favor, teña en conta que, se xa existe un documento da mesma tipoloxía en proceso de verificación, esta se perderá.",
  uploadDocumentModalTitle: "Vai proceder a subir un novo documento",
  uploadDocumentFormatUnsupported: "Formato de documento non admitido.",
  uploadDocumentSuccess: "Documento subido correctamente",
  uploadDocumentError: "Houbo un problema ao subir o arquivo",

  // AtcPage - Stepper

  atcSearchUser: "Buscar usuario",
  atcSelectAccount: "Seleccionar/Crear conta",
  atcUserVerify: "Verificar usuario",
  atcUserData: "Datos de usuario",
  atcIdentifierField:
    "Introduza: DNI, pasaporte, teléfono ou correo electrónico",
  atcOtpText: "Para garantir a súa identidade, enviaremos un código:",
  atcSendEmailButton: "ENVIAR CORREO ELECTRÓNICO",
  atcResendEmailButton: "REENVIAR CORREO ELECTRÓNICO",
  atcSendSmsButton: "ENVIAR SMS",
  atcResendSmsButton: "REENVIAR SMS",
  atcRegisterPagePrefix: "Prefixo",
  atcRegisterPagePhone: "Teléfono",
  atcRegisterPageEmail: "Correo electrónico",
  atcRegisterPageDniNie: "DNI, Pasaporte",
  atcRegisterPageSendOTP: "Introduza o código enviado",
  atcRegisterPageSendConsentSms: "ENVIAR SMS TeC",
  atcRegisterPageResendConsentSms: "REENVIAR SMS TeC",
  atcCheckOtpCode: "COMPROBAR CÓDIGO",
  atcAccept: "ACEPTAR",
  atcClose: "PECHAR",
  atcCancel: "CANCELAR",
  atcCreate: "CREAR",
  atcModify: "MODIFICAR",
  atcVerifyDocumentIdentifier: "VERIFICAR DNI/PASAPORTE",
  atcPhoneError: "O teléfono é obrigatorio, introduza un formato válido",
  atcIdentifierDocumentError:
    "O DNI/pasaporte é obrigatorio, introduza un formato válido",
  atcEmailError:
    "O correo electrónico é obrigatorio, introduza un formato válido",
  atcNewAccount: "Crear nova conta",
  atcRadioSelectAccount: 'Seleccione a súa conta ou a opción "Nova Conta"',
  atcModalTitle: "Datos verificados",
  atcModalSubtitle: "Por favor, seleccione a súa conta",
  atcModalEmailTitle: "Enderezos de correo electrónico:",
  atcModalPhoneTitle: "Números de teléfono:",

  //DialogMissingData

  dialogMissingDataText: "Hay acciones requeridas para el correcto funcionamiento de la aplicación:",
  dialogMissingDataTextTyC: "Para ello antes debes aceptar la privacidad y los términos y condiciones",
  dialogMissingDataTextDocument: "Necesitas verificar tu documento identificativo lo que implica que no puedes continuar a partir de aquí. Para poder verificarlo te recomendamos que te dirijas a tu oficina de correos más cercana.",
  dialogMissingDataTextNoHolder: "La empresa no tiene los datos obligatorios necesarios, ponte en contacto con el titular para poder continuar.",
  dialogMissingDataunsuscribedEnterprise: "Es necesario suscribirse a la aplicación",
  dialogMissingDatapendingEnterprisePrivacy: "Para ello antes debes aceptar la privacidad y los términos y condiciones",
  dialogMissingDataincompleteUserData: "Rellenar datos de usuario obligatorios",
  dialogMissingDataincompleteEnterpriseData: "Rellenar datos de empresa obligatorios",
  dialogMissingDatapendingEnterprise: "Crear una empresa",
  dialogMissingDataverifyEmail: "Añadir un email verificado",
  dialogMissingDataverifyPhone: "Añadir un teléfono verificado",
  dialogMissingDataverifyAddress: "Añadir una dirección verificada",
  dialogMissingUserDataText:
    "Os datos de usuario requiridos para o funcionamento do servizo non son correctos ou están incompletos. Por favor actualice os seus datos.",
  dialogMissingEnterpriseDataText:
    "É necesaria a creación dunha empresa para o correcto funcionamento deste servizo. Por favor, cree a súa empresa.",

  // Redirect Page

  redirectPageMissingParams: "Faltan parámetros",
  redirectPagePageNotFound: "La página no existe",

  // EnterpriseOauthLogin Page

  enterpriseOauthLoginPageError: "Faltan parámetros",
  redirectPageIdEnterpriseNotFound: "Se necesita el Id de empresa para acceder a esa página",
  redirectPageNotUserEnterprise: "La empresa indicada no pertenece al usuario",

  // ChooseEnterprise Page

  chooseEnterpriseNoSubsTitle: "Empresa no suscrita",
  chooseEnterpriseNoSubsText: "La empresa no está suscrita a la aplicación, ponte en contacto con el titular para que la suscriba y poder continuar",
  chooseEnterpriseNoPrivTitle: "Privacidad sin firmar",
  chooseEnterpriseNoPrivText: "La empresa no tiene firmada la privacidad, ponte en contacto con el titular para que la firme y poder continuar",
  chooseEnterpriseNoDataTitle: "Datos requeridos",
  chooseEnterpriseNoDataText: "La empresa no tiene todos los datos necesarios, ponte en contacto con el titular para que los rellene y poder continuar",

  // ResetPass Warn

  expiredPassTitle: "Contraseña caducada",
  expiredPassText: "La contraseña ha caducado, por favor, crea una nueva",

  //UserVerifications2FA
  verifications2FATitle: "Configura tu doble factor de autenticación",
  verifications2FAImageText: "Aquí puedes gestionar tus servicios con doble factor de autenticación. Activa o desactiva el doble factor de autenticación para cada uno de los servicios que tengas contratados teniendo en cuenta que hay servicios que no podrás desactivar porque lo exigen por normativa.",
  verifications2FAConfigText: "Servicios contratados",
  verifications2FASetPhone: "Configura tu teléfono",
  verifications2FASetEmailTitle: "Confirma tu identidad",
  verifications2FASetEmailText: "Mandaremos un código de verificación a tu email",
  verifications2FASetEmailConfText: "Mandaremos un código de verificación a tu email para confirmar tu identidad",
  verifications2FASetEmailOtp: "Código de verificación",
  verifications2FASetPhoneTitle: "Configura tu teléfono",
  verifications2FASetPhoneText: "Mandaremos un código de verificación a tu teléfono",
  verifications2FASetPhoneOtp: "Código de verificación",
  verifications2FASetPhonConfirmation: "Ya tienes configurado un teléfono para el doble factor de autenticación. Si estabas intentando hacer login en un servicio, por favor, vuelve a intentarlo.",
  verifications2FAConfigSuccess: "Tu configuración para el doble factor de autenticación se ha actualizado correctamente",

  // Login2FA

  login2FASetPhoneTitle: "Configura tu teléfono",
  login2FASetPhoneText: "Esta aplicación requiere un doble factor de autenticación y necesitas configurar tu teléfono. Para ello accede a la configuración de tu cuenta pulsando en 'Aceptar'.",
  login2FASendPhoneTitle: "Segundo factor de autenticación",
  login2FASendPhoneText: "Esta aplicación requiere un segundo factor de autenticación, introduce el código de verificación que enviaremos a tu teléfono.",
  login2FASetPhoneOtp: "Código de verificación",
  login2FASetPhoneError: "No se ha podido validar el teléfono. Por favor, inténtalo de nuevo.",

  // Oauth - ACM
  loginACMTitle: "Fallou o inicio de sesión",
  loginACMText: "Houbo un problema cos datos proporcionados, téntao de novo",
  generalACMTitle: "Atención",
  generalACMTextGo: "Para acceder á aplicación debes iniciar sesión de novo",
  generalACMTextNoGo: "Volver á aplicación para iniciar sesión",

  // GeneralWarning
  generalWarningPassText: "Produciuse un erro ao cambiar o teu contrasinal. Se recibiches o código de validación, é posible que o introduciu incorrectamente, ou quizais o {dataType} que introduciu fose incorrecto, nese caso, asegúrate de que o introduciu correctamente.",
  generalWarningRegistryText: "Produciuse un erro ao crear a conta. Se recibiches o código de validación, quizais o introduciches incorrectamente, ou quizais a conta de correo electrónico que indicaches xa estea rexistrada en CorreosID, caso en que deberías recuperar o contrasinal.",
  generalWarningVerifyText: "Produciuse un erro ao verificar o {dataType}. Se recibiu o código de validación, quizais o introduciu incorrectamente, ou quizais o {dataType} que indicaches xa estea verificado en CorreosID.",

  // GoToUnifyAccounts
  goToUnifyAccountsButton: "Unificar contas",
  goToUnifyAccountsTitle1: "Verifica a túa identidade",
  goToUnifyAccountsTitle2: "Verificar a conta",
  goToUnifyAccountsText1: "Para unificar as contas, debes verificar a túa identidade",
  goToUnifyAccountsText2: "Para unificar as contas, debes verificar a conta a unificar",
  goToUnifyAccountsData: "Datos para verificar",
  goToUnifyAccountsOtpError: "Produciuse un erro ao verificar o {dataType}",
};
