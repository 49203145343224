import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Divider,
  Typography,
  CircularProgress,
  ButtonGroup,
  IconButton,
  Button,
  Card,
  CardMedia,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel
} from "@material-ui/core";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import { UserPrivacyStyles } from "./UserPrivacyStyles";
import {
  GetLegalRGPD,
  PostLegalRGPD,
} from "../../services/LegalCallsService";
import {
  GetUtilitiesCorreosIdSubscriptionUserTycPrivacyUrls,
} from "../../services/UtilitiesCallsService";
import {
  GetMasterRgpd,
} from "../../services/MasterCallsService";
import { BiLinkExternal } from "react-icons/bi";
import { CorreosButton } from "../../components/CorreosButton/CorreosButton";
import { Alerts } from "../Alerts/Alerts";
import { Constants } from "../../commons/Constants";
import { checkIsNullUndefined, getErrorMessage } from "../../commons/Utils";
import { useTranslation } from "react-i18next";
import privacyLogo from "../../assets/images/privacidad.svg"

export function UserPrivacy() {
  const classes = UserPrivacyStyles();
  const { t, i18n } = useTranslation();

  const [userSignTyC, setUserSignTyC] = useState([]);
  const [circularPrivacyProgress, setCircularPrivacyProgress] =
    useState(false);
  const [originalUserTyC, setOriginalUserTyC] = useState([]);
  const [userTyC, setUserTyC] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [checkAllRgps, setCheckAllRgps] = useState(false);

  useEffect(() => {
      getServices();
      getUserRgpd();
  }, []);

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  const getServices = () => {

    GetUtilitiesCorreosIdSubscriptionUserTycPrivacyUrls().then((response) => {
      if (response && !response.Status) {
        setUserSignTyC(response);
      } else {
        finishOperation("error", getErrorMessage(response, t));
      }
    });
  };

  const getUserRgpd = () => {
    setCircularPrivacyProgress(true);
    GetMasterRgpd().then((response) => {
      if (response && !response.Status) {
        GetLegalRGPD().then((respon) => {
          if (respon && !respon.Status) {
            let auxiliaryTerms = [];
            response.forEach((elem) => {
              let auxiliarRgpd = respon.filter((rgpd) => {
                return rgpd.idRgpd === elem.id;
              });
              elem.checked = auxiliarRgpd.length > 0 ? 1 : 2;
              auxiliaryTerms.push(elem);
            });
            checkAllChecked(auxiliaryTerms);
            let auxiliaryCloneTerms = JSON.parse(
              JSON.stringify(auxiliaryTerms)
            );
            setUserTyC(auxiliaryTerms);
            setOriginalUserTyC(auxiliaryCloneTerms);
            setCircularPrivacyProgress(false);
          } else {
            finishOperation("error", getErrorMessage(respon, t));
            setCircularPrivacyProgress(false);
          }
        });
      } else {
        finishOperation("error", getErrorMessage(response, t));
        setCircularPrivacyProgress(false);
      }
    });
  };

  const checkAllChecked = (auxiliaryTerms) => {
    let allChecked = auxiliaryTerms.filter((elem) => elem.checked !== 1);
    if (allChecked.length === 0) {
      setCheckAllRgps(true);
    }
  }

  const handleOptions = (value) => {
    let auxiliaryArray = JSON.parse(JSON.stringify(userTyC));
    auxiliaryArray.forEach((elem) => {
      if (elem.code + Constants.Si === value.currentTarget.name)
        elem.checked = 1;
      if (elem.code + Constants.No === value.currentTarget.name)
        elem.checked = 2;
    });
    setUserTyC(auxiliaryArray);
  };

  const handleCancel = () => {
    let auxiliaryArray = JSON.parse(JSON.stringify(originalUserTyC));
    setUserTyC(auxiliaryArray);
  };

  const handleAccept = () => {
    setCircularPrivacyProgress(true);
    let auxiliaryArray = [];
    userTyC.forEach((tyc) => {
      auxiliaryArray.push({
        idRgpd: tyc.id,
        accepted: tyc.checked === 1,
      });
    });
    PostLegalRGPD(auxiliaryArray).then((response) => {
      if (!response || response.Status) {
        finishOperation("error", getErrorMessage(response, t));
        setCircularPrivacyProgress(false);
      } else {
        finishOperation("success", t("privacySuccessChange"));
        getUserRgpd();
      }
    });
  };
  const handlePromotionsSelected = () => {
    let auxiliaryArray = JSON.parse(JSON.stringify(userTyC));
    if (checkAllRgps) {
      auxiliaryArray.forEach((elem) => {
        elem.checked = 2;
      });
      setUserTyC(auxiliaryArray);
      setCheckAllRgps(false);
    } else {
      auxiliaryArray.forEach((elem) => {
        elem.checked = 1;
      });
      setUserTyC(auxiliaryArray);
      setCheckAllRgps(true);
    }
  };

  const formatDate = (timeStamp) => {
    let auxText = "-";
    if (!checkIsNullUndefined(timeStamp)) {
      let date = timeStamp.split("T");
      let year = date[0].split("-")[0];
      let month = date[0].split("-")[1];
      let day = date[0].split("-")[2];
      auxText = (day + "/" + month + "/" + year);
    }
    return auxText;
  }

  const isEvenRow = (index) => {
    return index % 2 == 0;
  }

  const showSignedText = (service, option) => {
    return <Grid item
      xs={4}
      md={4}
      lg={4}
      style={{ display: "flex", textAlign: "right", alignItems: "center" }}
    >
      <Grid container style={{ textAlign: "right", alignItems: "center" }}>
        {option == "tyc" ?
          service.tyc.map((tyc) => {
            if (!checkIsNullUndefined(tyc) && !checkIsNullUndefined(tyc.dateSignTyc)) {
              return (
                <Grid container item xs={12} display="flex" style={{ textAlign: "right", alignItems: "center" }}>
                  <Grid item xs={12} md={11} >
                    <span className={classes.signedText}>{t("privacySignedTyCPrivacy") + " " + formatDate(tyc.dateSignTyc)}</span>
                  </Grid>
                  <Grid item xs={12} md={1} style={{ textAlign: "right", alignItems: "center" }}>
                    <IconButton
                      className={classes.buttonLink}
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      href={tyc.urlTyc}
                      target="_blank"
                      rel={"noopener noreferrer"}>
                      <BiLinkExternal />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            } else {
              return ("")
            };
          }) : option == "privacy" ?
            service.privacy.map((privacy) => {
              if (!checkIsNullUndefined(privacy) && !checkIsNullUndefined(privacy.dateSignPrivacy)) {
                return (
                  <Grid container item xs={12} display="flex" style={{ textAlign: "right", alignItems: "center" }}>
                    <Grid item xs={12} md={11}>
                      <span className={classes.signedText}>{t("privacySignedTyCPrivacy") + " " + formatDate(privacy.dateSignPrivacy)}</span>
                    </Grid>
                    <Grid item xs={12} md={1} style={{ textAlign: "right" }}>
                      <IconButton
                        className={classes.buttonLink}
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        href={privacy.urlPrivacy}
                        target="_blank"
                        rel={"noopener noreferrer"}>
                        <BiLinkExternal />
                      </IconButton>
                    </Grid>
                  </Grid>
                )
              } else {
                return ("")
              };
            }) : ""
        }
      </Grid>
    </Grid>
  }

  return (
    <Grid>
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      <Grid className={classes.container}>
        <Container maxWidth={"lg"} className={classes.mainContainer}>
          <Card className={classes.cardContainer}>
            <Grid container spacing={0} >


              {/* Page Title*/}
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  className={classes.title}
                  noWrap
                >
                  {t("mainPagePrivacity")}
                </Typography>

              </Grid>
              {/* Title Card */}
              <Grid container item xs={12} className={classes.privacyTitleCard} >
                <Grid item xs={12} md={3}>
                  <CardMedia className={classes.media}>
                    <img
                      className={classes.imgCardMedia}
                      src={privacyLogo}
                      alt="Logo"
                    />
                  </CardMedia>
                </Grid>
                <Grid item xs={12} md={9} className={classes.privacyTitleCardTextContainer}>
                  <Typography className={classes.privacyTitleCardText}>
                    {t("privacyIntroText")}
                  </Typography>
                  <Divider className={classes.divider}></Divider>
                  <Typography className={classes.privacyTitleCardText}>
                    {t("privacyIntroText2")}
                  </Typography>
                </Grid>
              </Grid>
              {/*Tycs/Privacies */}
              <Grid container item xs={12} className={classes.containerMargin}>
                <Grid container item xs={12} className={classes.rgpdTitle}>
                  <Grid item xs={12} md={6} style={{ display: "flex" }}>

                    <FindInPageOutlinedIcon className={classes.imgIcon}> </FindInPageOutlinedIcon>
                    <Typography className={classes.rgpdTitleText}>
                      {t("privacyTyCLink")}
                    </Typography>
                  </Grid>

                </Grid>
                <Grid container item md={12} xs={12}>
                  <Grid item md={4} xs={4} style={{ textAlign: "left" }}>
                    <Typography
                      variant="h6"
                      className={classes.titleColumn}
                    >
                      {t("privacyMyServices")}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={4} style={{ textAlign: "right" }}>
                    <Typography
                      variant="h6"
                      className={classes.titleColumn}
                    >
                      {t("privacyTyCLink")}
                    </Typography>
                  </Grid>

                  <Grid item md={4} xs={4} style={{ textAlign: "right" }}>
                    <Typography
                      variant="h6"
                      className={classes.titleColumn}
                    >
                      {t("privacyPrivacyLink")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={12} xs={12}>
                  {userSignTyC.map((service, index) => {

                    return (

                      <Grid
                        container
                        className={!isEvenRow(index) ? classes.evenRow : classes.oddRow}
                      >
                        <Grid item md={4} xs={4} className={classes.agreeConditionsTermsConsent}>

                          <span>{service.application} </span>

                        </Grid>
                        {showSignedText(service, "tyc")}
                        {showSignedText(service, "privacy")}

                      </Grid>

                    );
                  })}

                </Grid>
                <Grid item md={12} xs={12}>
                  <Divider className={classes.categoryDivider}></Divider>
                </Grid>
              </Grid>

              {/* Rgpd title */}
              <Grid container item xs={12} className={classes.containerMargin}>
                <Grid container item xs={12} spacing={1} className={classes.rgpdTitle}>
                  <Grid item xs={12} md={6} style={{ display: "flex" }}>

                    <LockOutlinedIcon className={classes.imgIcon}> </LockOutlinedIcon>
                    <Typography className={classes.rgpdTitleText}>
                      {t("privacyDataProtectionTitle")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}
                    justifyContent="flex-end" style={{ display: "flex" }}>

                    <FormControl>
                      <FormGroup row >
                        <FormControlLabel
                          value="end"
                          control={<Checkbox color="primary" onClick={() => handlePromotionsSelected()} size="small" />}
                          label={<Typography className={classes.checkBoxLabel}>{checkAllRgps ? t("privacyDisableAllText") : t("privacyEnableAllText")}</Typography>}
                          labelPlacement="end"
                          style={{ marginRight: "0em" }}
                        />
                      </FormGroup>
                    </FormControl>

                  </Grid>
                </Grid>

                {/*Rgpds */}

                <Grid item md={12} xs={12}>
                  {circularPrivacyProgress ? (
                    <CircularProgress />
                  ) : (


                    <Grid container className={classes.cardContainer}>
                      <Grid item md={12} xs={12}>
                        <Grid container className={classes.cardFile}>
                        </Grid>
                        <Grid item md={12} xs={12} className={classes.rgpdTitle}>
                          <Grid>
                            {userTyC.map((element, index) => {
                              let nameSi = element.code + Constants.Si;
                              let nameNo = element.code + Constants.No;
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginBottom: "1em" }}
                                >
                                  <Grid
                                    container
                                    alignItems="right"
                                    spacing={0}
                                  >
                                    <Grid
                                      item
                                      xs={9}
                                      md={9}
                                      lg={9}
                                      className={
                                        classes.agreeConditionsTermsConsent
                                      }
                                    >
                                      <span>{element.name}</span>
                                    </Grid>
                                    <Grid item xs={3} style={{ textAlign: "right" }}>
                                      <Typography className={classes.buttonLabel}>{element.checked === 1 ? "Activo" : "Inactivo"}</Typography>
                                      <ButtonGroup disableElevation >
                                        <Button
                                          color={
                                            userTyC[index].checked === 1
                                              ? "primary"
                                              : null
                                          }
                                          variant={
                                            userTyC[index].checked === 1
                                              ? "contained"
                                              : null
                                          }
                                          className={classes.switchButton}
                                          name={nameSi}
                                          onClick={(value) =>
                                            handleOptions(value)
                                          }
                                        >
                                          {t("yes")}
                                        </Button>
                                        <Button
                                          color={
                                            userTyC[index].checked === 2
                                              ? "secondary"
                                              : null
                                          }
                                          variant={
                                            userTyC[index].checked === 2
                                              ? "contained"
                                              : null
                                          }
                                          className={
                                            userTyC[index].checked === 2
                                              ? classes.switchButtonNoActive
                                              : classes.switchButton
                                          }
                                          name={nameNo}
                                          onClick={(value) =>
                                            handleOptions(value)
                                          }
                                        >
                                          {t("no")}
                                        </Button>
                                      </ButtonGroup>

                                    </Grid>

                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          style={{ marginTop: "5em", marginBottom: "5em" }}
                        >
                          <CorreosButton
                            onClick={() => handleCancel()}
                            color="secondary"
                            style={{ float: "left" }}
                          >
                            {t("cancel")}
                          </CorreosButton>
                          <CorreosButton
                            variant="contained"
                            color="primary"
                            onClick={() => handleAccept()}
                            style={{ float: "right" }}
                          >
                            {t("save")}
                          </CorreosButton>
                        </Grid>
                      </Grid>
                    </Grid>


                  )}
                </Grid>

              </Grid>
            </Grid>
          </Card>
        </Container>
      </Grid>
    </Grid>
  );
}
