import { AttributeCodes } from "../../../commons/Attributes";
import { userDocument, userEmail, userPhone } from "../../../commons/ConstantObjects";
import { EnumPersonType } from "../../../commons/EnumsGeneral";
import { obtainAttribute } from "../../../commons/FormManager";
import { checkIsNullUndefined } from "../../../commons/Utils";

export const handleAuxAddEmail = (email, isVerified, auxFormValuesObject, auxUserFormValuesObject, auxUserFormSettersObject, auxBothFormSettersObject) => {
    let auxForm = JSON.parse(JSON.stringify(auxFormValuesObject[EnumPersonType.User].DataForm));
    let auxEditableEmails = JSON.parse(JSON.stringify(auxUserFormValuesObject.EditableEmails));
    let auxEmail = JSON.parse(JSON.stringify(userEmail));
    let auxAttribute = obtainAttribute(auxForm, "EMA");
    auxAttribute.value = email;
    auxEmail.email = email;
    if (isVerified) {
        auxEmail.id = "NEW";
        auxEmail.idConTypeVerification = 2;
    }
    auxEditableEmails.push(auxEmail);
    auxBothFormSettersObject[EnumPersonType.User].SetDataForm(auxForm);
    auxUserFormSettersObject.SetEditableEmails(auxEditableEmails);
};

export const handleAuxVerifyEmail = (email, auxUserFormValuesObject, auxUserFormSettersObject) => {
    let auxEditableEmails = JSON.parse(JSON.stringify(auxUserFormValuesObject.EditableEmails));
    let auxEmail = auxEditableEmails.find(
        (auxEmail) => auxEmail.email == email
    );
    auxEmail.idConTypeVerification = 2;
    if (auxEmail.id == 0) {
        auxEmail.id = "NEW";
    }
    auxUserFormSettersObject.SetEditableEmails(auxEditableEmails);
};

export const handleAuxRemoveEmail = (email, auxFormValuesObject, auxUserFormValuesObject, auxUserFormSettersObject, auxBothFormSettersObject) => {
    let auxForm = JSON.parse(JSON.stringify(auxFormValuesObject[EnumPersonType.User].DataForm));
    let auxEditableEmails = JSON.parse(JSON.stringify(auxUserFormValuesObject.EditableEmails));
    let auxAttribute = obtainAttribute(auxForm, AttributeCodes.Email);
    auxAttribute.value = "";
    let newEditableEmails = auxEditableEmails.filter(
        (auxEmail) => auxEmail.email !== email
    );
    auxBothFormSettersObject[EnumPersonType.User].SetDataForm(auxForm);
    auxUserFormSettersObject.SetEditableEmails(newEditableEmails);
};

export const handleAuxAddPhone = (phone, prefix, isVerified, auxFormValuesObject, auxUserFormValuesObject, auxUserFormSettersObject, auxBothFormSettersObject) => {
    let auxForm = JSON.parse(JSON.stringify(auxFormValuesObject[EnumPersonType.User].DataForm));
    let auxEditablePhones = JSON.parse(JSON.stringify(auxUserFormValuesObject.EditablePhones));
    let auxPhone = JSON.parse(JSON.stringify(userPhone));
    let auxPhoneAttr = obtainAttribute(auxForm, AttributeCodes.Phone);
    let auxPrefixAttr = obtainAttribute(auxForm, AttributeCodes.Prefix);
    auxPhoneAttr.value = phone;
    auxPrefixAttr.value = prefix;
    auxPhone.phone = phone;
    auxPhone.idCountry = prefix;
    if (isVerified) {
        auxPhone.id = "NEW";
        auxPhone.idConTypeVerification = 5;
    }
    auxEditablePhones.push(auxPhone);
    auxBothFormSettersObject[EnumPersonType.User].SetDataForm(auxForm);
    auxUserFormSettersObject.SetEditablePhones(auxEditablePhones);
};

export const handleAuxVerifyPhone = (phone, auxUserFormValuesObject, auxUserFormSettersObject) => {
    let auxEditablePhones = JSON.parse(JSON.stringify(auxUserFormValuesObject.EditablePhones));
    let auxPhone = auxEditablePhones.find(
        (auxPhone) => auxPhone.phone == phone
    );
    auxPhone.idConTypeVerification = 5;
    if (auxPhone.id == 0) {
        auxPhone.id = "NEW";
    }
    auxUserFormSettersObject.SetEditablePhones(auxEditablePhones);
};

export const handleAuxRemovePhone = (phone, prefix, auxFormValuesObject, auxUserFormValuesObject, auxUserFormSettersObject, auxBothFormSettersObject) => {

    let auxForm = JSON.parse(JSON.stringify(auxFormValuesObject[EnumPersonType.User].DataForm));
    let auxEditablePhones = JSON.parse(JSON.stringify(auxUserFormValuesObject.EditablePhones));
    let auxPhoneAttr = obtainAttribute(auxForm, AttributeCodes.Phone);
    let auxPrefixAttr = obtainAttribute(auxForm, AttributeCodes.Prefix);
    auxPhoneAttr.value = "";
    auxPrefixAttr.value = "";
    let newEditablePhones = auxEditablePhones.filter(
        (auxPhone) => auxPhone.phone != phone || auxPhone.idCountry != prefix
    );
    auxBothFormSettersObject[EnumPersonType.User].SetDataForm(auxForm);
    auxUserFormSettersObject.SetEditablePhones(newEditablePhones);
};

export const handleAuxDocumentActions = (
    actionType,
    auxDocInfo,
    auxFormValuesObject,
    auxUserFormValuesObject,
    auxFunctions,
    auxUserFormSettersObject,
    auxBothFormSettersObject,
) => {
    let auxUserDataForm = JSON.parse(JSON.stringify(auxFormValuesObject[EnumPersonType.User].DataForm));
    let auxUserEditableDocuments = JSON.parse(
        JSON.stringify(auxUserFormValuesObject.EditableDocuments)
    );
    let auxDocument = {};
    let auxText = { auxText: "" };
    prepareDocAndText(actionType, auxDocument, auxText, auxUserEditableDocuments, auxDocInfo.auxDocType, auxFunctions.doTraduction);
    let auxTdoAttribute = obtainAttribute(auxUserDataForm, AttributeCodes.DocType);
    auxTdoAttribute.value = auxDocInfo.auxDocType;
    let auxNdoAttribute = obtainAttribute(auxUserDataForm, AttributeCodes.DocNumber);
    auxNdoAttribute.value = actionType === "delete" ? "" : auxDocInfo.auxDocNumber;
    let auxPexAttribute = obtainAttribute(auxUserDataForm, AttributeCodes.ExpedCountry);
    auxPexAttribute.value = actionType === "delete" ? "" : auxDocInfo.auxDocCountry;
    let auxFcaAttribute = obtainAttribute(auxUserDataForm, AttributeCodes.ExpirDate);
    auxFcaAttribute.value = actionType === "delete" ? "" : auxDocInfo.auxDocDate;
    if (actionType !== "delete") {
        auxDocument.id = auxDocInfo.auxDocId;
        auxDocument.idTypeUserDocument = auxDocInfo.auxDocType;
        auxDocument.number = auxDocInfo.auxDocNumber;
        auxDocument.idCountry = auxDocInfo.auxDocCountry;
        auxDocument.expirationDate =
            checkIsNullUndefined(auxDocInfo.auxDocDate) || auxDocInfo.auxDocDate == "yyyy-MM-dd"
                ? null
                : auxDocInfo.auxDocDate;
    }
    if (actionType === "add") {
        auxUserEditableDocuments.push(auxDocument);
    } else if (actionType === "delete") {
        auxUserEditableDocuments = auxUserEditableDocuments.filter((auxDoc) => auxDoc.idTypeUserDocument != auxDocInfo.auxDocType);
    } else {
        let auxDocument = auxUserEditableDocuments.find(
            (document) => document.idTypeUserDocument == auxDocInfo.auxDocType
        );
        auxDocument.number = auxDocInfo.auxDocNumber;
        auxDocument.idCountry = auxDocInfo.auxDocCountry;
        auxDocument.expirationDate = auxDocInfo.auxDocDate;
    };
    auxFunctions.finishOperation("success", auxText.auxText);
    auxBothFormSettersObject[EnumPersonType.User].SetDataForm(auxUserDataForm);
    auxUserFormSettersObject.SetEditableDocuments(auxUserEditableDocuments);
};

const prepareDocAndText = (actionType, auxDocument, auxText, auxUserEditableDocuments, auxDocType, doTraduction) => {
    if (actionType === "add") {
        auxDocument = JSON.parse(JSON.stringify(userDocument));
        auxText.auxText = doTraduction("userDataAddDocSuccess");
    } else if (actionType === "change") {
        auxDocument = auxUserEditableDocuments.find(
            (document) => document.idTypeUserDocument == auxDocType
        );
        auxText.auxText = doTraduction("userDataModifyDocSuccess");
    } else if (actionType === "delete") {
        auxDocument = auxUserEditableDocuments.findIndex(
            (document) => document.idTypeUserDocument == auxDocType
        );
        auxText.auxText = doTraduction("userDataDeleteDocSuccess");
    }
};