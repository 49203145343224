import { makeStyles } from "@material-ui/core/styles";

export const UnifyAccountsStyles = makeStyles((theme) => ({
    sectionTitle: {
        color: "#333333",
        fontWeight: "bold",
        padding: "1em 1em 1em 1.6em",
    },
    noDataText: {
        color: "#666666",
        fontStyle: "italic",
        textAlign: "center",
    },
    formMobileContainer: {
        padding: "1em 1em 1em 1em",
    },
    formMobileRadioGroup:{
        paddingLeft: "1em"
    },
    formTitle: {
        marginBottom:"1em",
        color: "#002453",
    },
    formText: {
        marginTop:"1em",
        color: "#333333",
    },
    divider:{
        width: "100%",
        marginTop: "0.5em",
        marginBottom: "1em"
    },
}));