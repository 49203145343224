import React, { useEffect, useState } from "react";
import {
  GetMasterCountry,
  GetMasterProvince,
  GetMasterCity,
  GetMasterPostalCode,
} from "../../../services/MasterCallsService";
import {
  Typography,
  Container,
  Grid,
  Dialog,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Attributes } from "../../../commons/Attributes";
import { CommonStyles } from "../../../commons/CommonStyles";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { checkIsNullUndefined } from "../../../commons/Utils";
import { useTranslation } from "react-i18next";

export function DialogShowAddress(props) {
  const { address, isUser } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState("-");
  const [province, setProvince] = useState("-");
  const [city, setCity] = useState("-");
  const [postalCode, setPostalCode] = useState("-");
  const [direction, setDirection] = useState("-");
  const [direction1, setDirection1] = useState("-");

  useEffect(() => {
    
      let auxCountry = isUser
        ? address[Attributes.PAI.attributeName]
        : address[Attributes.EPI.attributeName];
      let auxProvince = isUser
        ? address[Attributes.PRO.attributeName]
        : address[Attributes.EPR.attributeName];
      let auxCity = isUser
        ? address[Attributes.POB.attributeName]
        : address[Attributes.EPO.attributeName];
      let auxPostalCode = isUser
        ? address[Attributes.CPO.attributeName]
        : address[Attributes.ECP.attributeName];
      let auxDirection = isUser
        ? address[Attributes.DIR.attributeName]
        : address[Attributes.EDR.attributeName];
      let auxDirection1 = isUser
        ? address[Attributes.AD1.attributeName]
        : address[Attributes.ED1.attributeName];
      if (!checkIsNullUndefined(auxCountry)) {
        GetMasterCountry(null, auxCountry).then((response) => {
          if (response && !response.Status) {
            setCountry(response[0].name);
          }
        });
      }
      if (!checkIsNullUndefined(auxProvince)) {
        GetMasterProvince(null, auxProvince).then((response) => {
          if (response && !response.Status) {
            setProvince(response[0].name);
          }
        });
      }
      if (!checkIsNullUndefined(auxCity)) {
        GetMasterCity(auxCity, true).then((response) => {
          if (response && !response.Status) {
            setCity(response[0].name);
          }
        });
      }
      if (!checkIsNullUndefined(auxPostalCode)) {
        GetMasterPostalCode(auxPostalCode, true).then((response) => {
          if (response && !response.Status) {
            setPostalCode(response[0].postalCode);
          }
        });
      }
      setDirection(checkIsNullUndefined(auxDirection) ? "-" : auxDirection);
      setDirection1(checkIsNullUndefined(auxDirection1) ? "-" : auxDirection1);
  }, []);

  return (
    <Grid>
      <Tooltip title={t("see")}>
        <IconButton
          edge="inherit"
          className={commonClasses.iconButton}
          onClick={() => setOpen(true)}
        >
          <VisibilityIcon className={commonClasses.iconActionStyle} />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ classes: {root: dialogClasses.dialogWindow}}}
      >
        <Container className={commonClasses.dialogTitle}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={commonClasses.dialogTitleText}
              >
                {t("address")}
              </Typography>
            </Grid>            
          </Grid>
        </Container>
        <Container className={commonClasses.dialogContainer}>
          <Grid container spacing={1}>
            <Grid item md={6} xs={6}>
              <Typography>{t("country") + ": " + country}</Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <Typography>{t("province") + ": " + province}</Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <Typography>{t("city") + ": " + city}</Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <Typography>{t("postalCode") + ": " + postalCode}</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography>{t("address") + ": " + direction}</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography>{t("address") + "1: " + direction1}</Typography>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </Grid>
  );
}
