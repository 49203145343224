import { makeStyles } from "@material-ui/core/styles";

export const RequiredDataStyles = makeStyles((theme) => ({
    iconsContainer: {
        display: "flex",
        margin: "auto",
        padding: "0.1em !important",
    },
    iconContainer: {
        display: "grid",
        placeContent: "center",
    },
    actionsComponentContainer: {
        display: "flex",
        margin: "auto",
        height: "100%",
    },
    iconButton: {
        padding: "0.1em",
    },
    cardIcons: {
        display: "inline",
        margin: "auto",
        justifyContent: "left",
        // alignItems: "center",
    },
    iconsStyles: {
        width: "1.6em",
        height: "1.6em",
        color: "#002453",
        padding: "0.2em",
        marginRight: "0.4em",
        [theme.breakpoints.down("sm")]: {
            width: "1.2em",
            height: "1.2em",
            padding: "0.2em",
        },
    }
}));
