import React, { useEffect, useState } from "react";
import {
    Typography,
    Container,
    Dialog,
    Grid,
    TextField,
    Select,
    FormControl,
    InputLabel,
    CircularProgress,
} from "@material-ui/core";
import { CommonStyles } from "../../commons/CommonStyles";
import { DialogStyles } from "../CommonComponents/Dialogs/DialogStyles";
import { UserVerificationsStyles } from "../UserVerifications/UserVerificationsStyles";
import { checkIsNullUndefined, getErrorMessage } from "../../commons/Utils";
import {
    PostAuthorizeAuthorize,
} from "../../services/ReasonService";
import {
    GetUtilitiesCorreosIdGetUrlRedirectOauth,
} from "../../services/UtilitiesCallsService";
import { useTranslation } from "react-i18next";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { setSessionItem } from "../../services/Commons";
import { sessionValues } from "../../commons/EnumsGeneral";
import {
    PostApplicationAuxUserPhone,
    PostApplicationAuxUserResetPassuserSend,
    PostApplicationAuxVerificationTwoFactorAuthentication
} from "../../services/ApplicationAuxCallsService";
import { Constants } from "../../commons/Constants";

export function Dialog2FASetPhoneLogin(props) {
    const { open, setOpen, auxiliarInfo, finishOperation } = props;
    const commonClasses = CommonStyles();
    const dialogClasses = DialogStyles();
    const classes = UserVerificationsStyles();
    const { t, i18n } = useTranslation();

    const [phoneList, setPhoneList] = useState([]);
    const [phone, setPhone] = useState("");
    const [sending, setSending] = useState(false);
    const [sended, setSended] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState(false);
    const [otpErrorText, setOtpErrorText] = useState("");
    const [circularProgress, setCircularProgress] = useState(false);
    const [circularProgress0, setCircularProgress0] = useState(false);

    useEffect(() => {
        if (open && !checkIsNullUndefined(auxiliarInfo.respo)) {
            setCircularProgress0(true);
            let searchParams = new URLSearchParams(auxiliarInfo.respo);
            let code = searchParams.get('code');
            let userName = auxiliarInfo.user;
            PostApplicationAuxUserPhone(code, userName).then((response) => {
                if (response && !response.Status) {
                    setPhoneList(response);
                    let auxPhone = checkIsNullUndefined(response[0]) ? "" : response[0];
                    setPhone(auxPhone);
                    setCircularProgress0(false);
                } else {
                    finishOperation("error", getErrorMessage(response, t));
                    setOpen(false);
                };

            });
        };
    }, [open, auxiliarInfo]);

    useEffect(() => {
        clearAll();
    }, [phone]);

    const clearAll = () => {
        setSended(false);
        setOtp("");
        setOtpError(false);
        setOtpErrorText("");
    };

    const handleSend = () => {
        setSending(true);
        let auxError = { error: false, errorText: "" };
        PostApplicationAuxUserResetPassuserSend(phone).then((response) => {
            if (response && !response.Status) {
                setSended(true);
            } else {
                auxError.error = true;
                auxError.errorText = getErrorMessage(response, t);
                setSended(false);
            };
            setSending(false);
        });
    };

    const handleCancel = () => {
        clearAll();
        setPhone("");
        setPhoneList([]);
        setOpen(false);
    };

    const handleChangeData = (value) => {
        setPhone(value);
    };

    const handleChangeOtp = (value) => {
        const re = /^[0-9\b]{1,12}$/;
        if (value === "" || re.test(value)) {
            setOtp(value);
        };
    };

    const handleVerificatePhone = () => {
        setCircularProgress(true);
        PostApplicationAuxVerificationTwoFactorAuthentication(phone, otp, auxiliarInfo.user).then((response) => {
            if (response && !response.Status) {
                setCircularProgress(false);
                auxiliarInfo.manageGeneralPath(auxiliarInfo.respo, auxiliarInfo.redUrl, auxiliarInfo.user);
                clearAll();
                setOpen(false);
            } else {
                setOtpError(true);
                setOtpErrorText(t("login2FASetPhoneError"));
            };
            setCircularProgress(false);
        });
    };

    const goToVerifications = () => {
        setCircularProgress(true);
        setSessionItem("2FA", true);
        let auxAppOid = process?.env?.REACT_APP_APP_OID;
        let auxAppCode = Constants.cidCode;
        let auxPersonType = 1;
        let auxAppName = t("correosId");
        setSessionItem(sessionValues.appOid, auxAppOid);
        setSessionItem(sessionValues.appCode, auxAppCode);
        setSessionItem(sessionValues.appPersonType, auxPersonType);
        setSessionItem(sessionValues.appName, auxAppName);
        GetUtilitiesCorreosIdGetUrlRedirectOauth(auxAppOid).then((response) => {
            if (response && !response.Status) {
                logIn(response[0]);
            } else {
                setCircularProgress(false);
                finishOperation("error", getErrorMessage(response, t));
            };
        });
    };

    const logIn = (redirectUrl) => {
        PostAuthorizeAuthorize(auxiliarInfo.user, auxiliarInfo.pass, redirectUrl, null, false).then((response) => {
            if (response && !response.Status) {
                auxiliarInfo.manageGeneralPath(response, redirectUrl, auxiliarInfo.user);
            } else {
                setCircularProgress(false);
                finishOperation("error", getErrorMessage(response, t));
            };
        });
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={() => handleCancel()}
            PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
        >
            {circularProgress0 ? <CircularProgress className={commonClasses.circularProgress} /> :
                <>
                    {phoneList.length == 0 ?
                        <>
                            <Container className={commonClasses.dialogTitle}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h4"
                                            className={commonClasses.dialogTitleText}
                                        >
                                            {t("login2FASetPhoneTitle")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Container>
                            <Container className={commonClasses.dialogContainer}>
                                <Grid container item xs={12} className={commonClasses.gridIdentity}>
                                    <Grid item xs={12} style={{ marginBottom: "1em" }}>
                                        <Typography className={commonClasses.dialogContentText}>
                                            {t("login2FASetPhoneText")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    className={dialogClasses.dialogButtonContainer}
                                >
                                    <Grid item sm={6} xs={12}>
                                        <CorreosButton
                                            onClick={() => setOpen(false)}
                                            className={dialogClasses.dialogButtonLeft}
                                        >
                                            {t("cancel")}
                                        </CorreosButton>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CorreosButton
                                            variant="contained"
                                            color="primary"
                                            onClick={goToVerifications}
                                            className={dialogClasses.dialogButtonRight}
                                            circularProgress={circularProgress}
                                        >
                                            {t("accept")}
                                        </CorreosButton>
                                    </Grid>
                                </Grid>
                            </Container>
                        </> : (
                            <>
                                <Container className={dialogClasses.dialogTitle}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="h4" className={dialogClasses.dialogTitleText}>
                                                {t("login2FASendPhoneTitle")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Container className={dialogClasses.dialogContainer}>
                                    <Grid container item xs={12} className={commonClasses.gridIdentity}>
                                        <Grid item xs={12} style={{ marginBottom: "1em" }}>
                                            <Typography className={commonClasses.dialogContentText}>
                                                {t("login2FASendPhoneText")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <Grid item xs={12} sm={9}>
                                            <FormControl variant="filled" fullWidth color="secondary" className={commonClasses.inputsStyle}>
                                                <InputLabel label={t("PersonaTelfMovil")} color="secondary">
                                                    {t("PersonaTelfMovil")}
                                                </InputLabel>
                                                <Select
                                                    native
                                                    color="secondary"
                                                    value={phone}
                                                    onChange={(value) => handleChangeData(value.target.value)}
                                                    label={t("PersonaTelfMovil")}
                                                >
                                                    {phoneList.map((auxPhone) => {
                                                        return (
                                                            <option key={auxPhone} value={auxPhone}>
                                                                {auxPhone}
                                                            </option>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3} className={classes.buttonAdd}>
                                            <CorreosButton
                                                color={sended ? "secondary" : "primary"}
                                                variant={sended ? "outlined" : "contained"}
                                                onClick={() => handleSend()}
                                                disabled={checkIsNullUndefined(phone) || circularProgress}
                                                circularProgress={sending}
                                                fullWidth
                                            >
                                                {sended ? t("reSend") : t("send")}
                                            </CorreosButton>
                                        </Grid>
                                        {sended && (
                                            <Grid item xs={12}>
                                                <TextField
                                                    color="secondary"
                                                    defaultValue={otp}
                                                    value={otp}
                                                    onChange={(value) => handleChangeOtp(value.target.value)}
                                                    variant="filled"
                                                    fullWidth
                                                    name="otp"
                                                    autoFocus
                                                    label={t("login2FASetPhoneOtp")}
                                                    type="text"
                                                    error={otpError}
                                                    helperText={otpErrorText}
                                                    required
                                                    className={commonClasses.inputsStyle}
                                                />
                                            </Grid>
                                        )}
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            className={dialogClasses.dialogButtonContainer}
                                        >
                                            <Grid item sm={6} xs={12}>
                                                <CorreosButton
                                                    onClick={handleCancel}
                                                    className={dialogClasses.dialogButtonLeft}
                                                >
                                                    {t("cancel")}
                                                </CorreosButton>
                                            </Grid>
                                            <Grid item sm={6} xs={12}>
                                                <CorreosButton
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleVerificatePhone}
                                                    className={dialogClasses.dialogButtonRight}
                                                    disabled={!sended || otpError || checkIsNullUndefined(otp)}
                                                    circularProgress={circularProgress}
                                                >
                                                    {t("confirm")}
                                                </CorreosButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </>
                        )}
                </>
            }
        </Dialog>
    );
}
