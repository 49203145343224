import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { UnifyAccounts } from "../../components/UnifyAccounts/UnifyAccounts";
import { Alerts } from "../../components/Alerts/Alerts";
import { CommonStyles } from "../../commons/CommonStyles";
import { Header } from "../../components/Header/Header";

export function UnifyAccountsPage() {
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  return (
    <Grid className={CommonStyles.mainDiv}>
      <Header></Header>
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      <UnifyAccounts finishOperation={finishOperation} />
    </Grid>
  );
}
