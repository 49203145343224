import { fetchMethod } from "../commons/EnumsGeneral";
import { CommonFetch, buildHeader } from "./ReasonService";

const fetchApi = {
    GetApplicationApplicationNameByOid: "Application/ApplicationNameByOid",
    GetApplicationAttributeInfo: "Application/AttributeInfo",
    GetApplicationPersonType: "Application/PersonType",
    PostApplicationEmail: "Application/Email",
    PutApplicationResetPassword: "Application/ResetPassword",
    GetApplicationUserEnterprises: "Application/UserEnterprises/{oidUser}",
    GetApplicationUserInfoByOidOrEmail: "Application/UserInfoByOidOrEmail/{userIdentifier}",
};

export const GetApplicationApplicationNameByOid = async (applicationOid) => {
    let body = "";
    let header = buildHeader("application/json", false, "application/json", applicationOid);
    let apiUrl = fetchApi.GetApplicationApplicationNameByOid;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const GetApplicationAttributeInfo = async (applicationOid) => {
    let body = "";
    let header = buildHeader("*/*", false, false, applicationOid);
    let apiUrl = fetchApi.GetApplicationAttributeInfo;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const GetApplicationPersonType = async (applicationOid) => {
    let body = "";
    let header = buildHeader("*/*", false, false, applicationOid);
    let apiUrl = fetchApi.GetApplicationPersonType;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const PostApplicationEmail = async (data, typePerson, applicationOid) => {
    let param = "";
    if (typePerson) {
        param = "?registryType=" + typePerson;
    };
    let body = data;
    let header = buildHeader("application/json", false, "application/json", applicationOid);
    let apiUrl = fetchApi.PostApplicationEmail + param;
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return response;
};

export const PutApplicationResetPassword = async (
    username,
    newPassword,
    repeatNewPassword,
    otpCode
) => {
    let body = {
        username: username,
        newPassword: newPassword,
        repeatNewPassword: repeatNewPassword,
        otpCode: otpCode,
    };
    let header = buildHeader("application/json", false, "application/json");
    let apiUrl = fetchApi.PutApplicationResetPassword;
    let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl);
    return response;
};

export const GetApplicationUserEnterprises = async (oidUser) => {
    let body = "";
    let header = buildHeader("application/json", false, "application/json", process?.env?.REACT_APP_APP_OID);
    let apiUrl = fetchApi.GetApplicationUserEnterprises.replace(
        "{oidUser}",
        oidUser
    );
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return await response;
};

export const GetApplicationUserInfoByOidOrEmail = async (userIdentifier) => {
    let body = "";
    let header = buildHeader("application/json", false, "application/json", process?.env?.REACT_APP_APP_OID);
    let apiUrl = fetchApi.GetApplicationUserInfoByOidOrEmail.replace(
        "{userIdentifier}",
        userIdentifier
    );
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return await response;
};