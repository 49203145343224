import React from "react";
import {
    Typography,
    Container,
    Grid,
    Popper,
    Paper,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogStyles } from "../CommonComponents/Dialogs/DialogStyles";
import { CorreosButton } from "../CorreosButton/CorreosButton";

export function BasicDualConfirm(props) {
    const { open, title, anchorEl, wrapperRef, action, closeAction } = props;
    const dialogClasses = DialogStyles();
    const { t, i18n } = useTranslation();

    return (
        <Popper
            open={open}
            anchorEl={anchorEl}
            placement={"top"}
            style={{ zIndex: 10000 }}
            ref={wrapperRef}
        >
            <Paper style={{ marginLeft: "10%", marginRight: "10%" }}>
                <Container>
                    <Grid container style={{ paddingTop: "1em", paddingBottom: "1em" }}>
                        <Grid item xs={12} className={dialogClasses.dualPopupTitle}>
                            <Typography variant="h6" >
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <CorreosButton
                                size="small"
                                onClick={closeAction}
                                className={dialogClasses.dialogButtonLeft}
                            >
                                {t("no")}
                            </CorreosButton>
                        </Grid>
                        <Grid item xs={6}>
                            <CorreosButton
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={action}
                                className={dialogClasses.dialogButtonRight}
                            >
                                {t("yes")}
                            </CorreosButton>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </Popper>
    );
};
