import { makeStyles } from "@material-ui/core/styles";

export const EnterpriseTeamStyles = makeStyles((theme) => ({
  listTextStyleShow: {
    fontSize:"large",
    width: "100%",
    color: "#666666",
  },

  card: {
    padding:"8px",
    paddingLeft:"20px",
  },

  dataAuthorizedName: {
    marginTop: "10px",
    fontSize:"large",
    color: "#666666",
  },

  textHolder: {
    marginTop: "10px",
    color: "#002453",
  },

  iconButton: {
   padding: "0px",
   margin:"0px",
   color: "#333333",
  },

}));
