import React, { useState, useRef } from "react";
import {
  Typography,
  Container,
  Grid,
  Paper,
  Popper,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { CommonStyles } from "../../commons/CommonStyles";
import {
  DeleteVerificationUserEmail,
  DeleteVerificationUserPhone,
  DeleteVerificationDataAddress,
  DeleteVerificationDataDocument,
} from "../../services/ReasonService";
import {
  getErrorMessage,
  useOutsideAlerter,
} from "../../commons/Utils";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import { AttributeCodes } from "../../commons/Attributes";

export function DialogDelete(props) {
  const { verificable, tipo, finishOperation, getVerificableName, deleteVerificable } = props;
  const commonClasses = CommonStyles();
  const wrapperRef = useRef(null);
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [circularProgress, setCircularProgress] = useState(false);

  const handleCancel = () => {
    setAnchorEl(null);
    setOpenDelete(false);
  };

  const handleConfirm = () => {
    let auxName = getVerificableName(verificable, tipo);
    let auxCall;
    let auxText = "";
    switch (tipo) {
      case AttributeCodes.Email:
        auxCall = DeleteVerificationUserEmail(auxName);
        auxText = t("verificationsDeleteEmail");
        break;
      case AttributeCodes.Phone:
        auxCall = DeleteVerificationUserPhone(auxName);
        auxText = t("verificationsDeletePhone");
        break;
      case AttributeCodes.Alias:
        auxCall = DeleteVerificationDataAddress(verificable.id);
        auxText = t("verificationsDeleteAddress");
        break;
      case AttributeCodes.DocNumber:
        auxCall = DeleteVerificationDataDocument(verificable.idTypeUserDocument, auxName);
        auxText = t("verificationsDeleteDocument");
        break;
      default:
        break;
    };
    setCircularProgress(true);
    Promise.all([auxCall]).then((results) => {
      let response = results[0];
      if (response && !response.Status) {
        finishOperation("success", auxText);
        deleteVerificable(verificable, tipo);
      } else {
        finishOperation("error", getErrorMessage(response, t));
      };
      handleCancel();
      setCircularProgress(false);
    });
  };

  const handleDelete = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenDelete(true);
  };

  useOutsideAlerter(wrapperRef, handleCancel);

  return (
    <Grid style={{ textAlign: "center" }}>
      <Tooltip title={t("delete")}>
        <IconButton
          className={commonClasses.iconButton}
          aria-label="menu"
          onClick={(event) => handleDelete(event)}
        >
          <DeleteIcon className={commonClasses.iconActionStyle} />
        </IconButton>
      </Tooltip>
      <Popper
        open={openDelete}
        anchorEl={anchorEl}
        placement={"top"}
        style={{ zIndex: 100 }}
        ref={wrapperRef}
      >
        <Paper>
          <Container>
            <Grid container style={{ paddingTop: "1em", paddingBottom: "1em" }}>
              <Grid item xs={12}>
                <Typography className={commonClasses.popperContentText}>
                  {t("verificatinosDeleteElement")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  size="small"
                  onClick={() => handleCancel()}
                  className={commonClasses.leftButton}
                >
                  {t("no")}
                </CorreosButton>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => handleConfirm()}
                  className={commonClasses.rightButton}
                  circularProgress={circularProgress}
                >
                  {t("yes")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Popper>
    </Grid>
  );
}
