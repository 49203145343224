import React from "react";
import { Route } from "react-router-dom";
import history from "../../services/history";

// handle the public routes
function PublicRedirectRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      history={history}
      render={(props) => <Component {...props} />}
    />
  );
}

export default PublicRedirectRoute;