import React, { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";


import correosIdLogoBlue from "../../../assets/images/Cornamusa_Azul.svg";
import { HeaderStyles } from "./HeaderStyles";
import { EnumPages } from "../../../commons/EnumPages";
import { useTranslation } from "react-i18next";
import history from "../../../services/history";
import { getSessionItem } from "../../../services/Commons";
import { sessionValues } from "../../../commons/EnumsGeneral";

export function Header(props) {
  const classes = HeaderStyles();
  const { t, i18n } = useTranslation();

  const [appName, setAppName] = React.useState();

  useEffect(() => {
    setAppName(getSessionItem(sessionValues.appName));
  }, []);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <div className={classes.divBar}>
      <Typography variant="h6" className={classes.title2} noWrap>
          {appName}
        </Typography>
      </div>      
      <Toolbar>       
        <IconButton
          edge="start"
          color="inherit"
          className = {classes.imgIcon}
          aria-label="menu"
          onClick={() => (history.push("/" + EnumPages.Index))}          
        >
          <img
            src={correosIdLogoBlue}
            alt={appName}
            className = {classes.imgLogin}
          />
        </IconButton>
        
        <div className={classes.title}>
          </div>
        {/*Buscador, de momento se queda como comentado
        <Paper component="form" className={classes.searcher}>
          <InputBase className={classes.input} placeholder={t("search")} inputProps={{ 'aria-label': 'search google maps' }} />
          <IconButton>
            <SearchIcon />
          </IconButton>
        </Paper>*/}
        
        
      </Toolbar>
    </AppBar>
  );
}
