import { Grid } from "@material-ui/core";
import { AttributeCodes, Attributes, InputCodes } from "../../../commons/Attributes";
import { TextBoxAttribute } from "../../BuildInputs/TextBoxAttributeNew";
import { DatePickerAttribute } from "../../BuildInputs/DatePicker";
import AutocompleteAttribute from "../../BuildInputs/AutocompleteDropdown";
import { DropdownAttribute } from "../../BuildInputs/DropDownNew";
import { EnumPersonType } from "../../../commons/EnumsGeneral";
import { checkIsNullUndefined } from "../../../commons/Utils";
import { getAddresses, getValue, obtainAttribute } from "../../../commons/FormManager";
import { DialogEmailActions } from "./DialogEmailActions";
import { DialogPhoneActions } from "./DialogPhoneActions";
import { DialogDocumentActions } from "./DialogDocumentActions";
// import { DialogAddressActions } from "./DialogAddressActions";
import { handleAuxAddEmail, handleAuxAddPhone, handleAuxDocumentActions, handleAuxRemoveEmail, handleAuxRemovePhone, handleAuxVerifyEmail, handleAuxVerifyPhone } from "./HandleMultiFormUser";
// import { handleAuxAddAddress, handleAuxChangeAddress, handleAuxIsControlledCountry, handleAuxRemoveAddress, prepareAuxBasicAddressForm, prepareAuxEditableAddressForm } from "./HandleMultiFormAddress";

export const disableAttr = (auxForm, flag) => {
    let newAuxForm = JSON.parse(JSON.stringify(auxForm));
    newAuxForm.forEach((attr) => {
        if (
            attr.codeAttribute !== AttributeCodes.Alias &&
            attr.codeAttribute !== AttributeCodes.EntAlias
        ) {
            attr.disabled = flag;
        } else {
            attr.disabled = false;
        }
    });
    return newAuxForm;
};

export const cleanAttr = (auxForm) => {
    let newAuxForm = JSON.parse(JSON.stringify(auxForm));
    newAuxForm.forEach((attr) => {
        if (attr.codeAttribute !== AttributeCodes.Alias || attr.codeAttribute !== AttributeCodes.EntAlias) {
            attr.value = "";
        }
    });
    return newAuxForm;
};

export const getRequiredInput = (attribute, isForAddress, auxFunctions, auxControlledCountry, userEditableObjects, auxConstObject) => {
    switch (attribute.fieldType) {
        case InputCodes.Text:
        case InputCodes.Numeric:
        case InputCodes.LongText:
            return generateTextbox(attribute, auxFunctions, userEditableObjects, auxConstObject);
        case InputCodes.Selector:
            return generateDropdowns(attribute, isForAddress, auxFunctions, auxControlledCountry, userEditableObjects, auxConstObject);
        case InputCodes.Date:
            return generateDatePicker(attribute, auxFunctions, userEditableObjects, auxConstObject);
        default:
            break;
    }
};

const generateTextbox = (attribute, auxFunctions, editableObjects, auxConstObject) => {
    let dontShow = getDontShow(attribute, editableObjects.EditableDocuments, auxConstObject.auxiliarTab);
    let auxFieldSize = Attributes[attribute.codeAttribute].size;
    let auxKey = attribute.nameGroup + "_" + attribute.codeAttribute;
    return (
        dontShow ? null : (
            <Grid key={auxKey} item md={auxFieldSize} xs={12}>
                <TextBoxAttribute
                    attribute={attribute}
                    saveGroupsChanges={auxFunctions.SaveGroupsChanges}
                />
            </Grid>
        )
    );
};

const getDontShow = (attribute, editableDocuments, auxiliarTab) => {
    let auxDocCodes = [AttributeCodes.DocNumber, AttributeCodes.ExpedCountry, AttributeCodes.ExpirDate];
    let auxDontShow = false;
    if (auxDocCodes.includes(attribute.codeAttribute) && !auxiliarTab) {
        if (editableDocuments.length == 0) {
            auxDontShow = true;
        } else if (checkIsNullUndefined(attribute.value)) {
            auxDontShow = true;
        } else if (attribute.codeAttribute == AttributeCodes.ExpirDate && attribute.value == "yyyy-MM-dd") {
            auxDontShow = true;
        };
    };
    return auxDontShow;
};

const generateDropdowns = (attribute, isForAddress, auxFunctions, auxControlledCountry, userEditableObjects, auxConstObject) => {
    let userEditablePhones = userEditableObjects.EditablePhones;
    let userEditableDocuments = userEditableObjects.EditableDocuments;
    let isUser = attribute.dataType === "user";
    let auxPersonTypeValue = isUser ? EnumPersonType.User : EnumPersonType.Enterprise;
    let auxFieldSize = Attributes[attribute.codeAttribute].size;
    let auxKey = attribute.nameGroup + "_" + attribute.codeAttribute;
    let addressCodes = [
        AttributeCodes.PostalCode,
        AttributeCodes.City,
        AttributeCodes.Province,
        AttributeCodes.EntPostalCode,
        AttributeCodes.EntCity,
        AttributeCodes.EntProvince];
    let isAddressCode = addressCodes.includes(attribute.codeAttribute);
    let multyValues = [
        AttributeCodes.Email,
        AttributeCodes.Phone,
        AttributeCodes.DocType,
        AttributeCodes.Alias,
        AttributeCodes.EntAlias];
    let isMultyValue = multyValues.includes(attribute.codeAttribute);
    if (isAddressCode) {
        let isControlledCountry = auxControlledCountry[auxPersonTypeValue].value;
        if (isControlledCountry) {
            return (
                <Grid key={auxKey} item md={auxFieldSize} xs={12}>
                    <AutocompleteAttribute
                        attribute={attribute}
                        saveGroupsChanges={auxFunctions.SaveGroupsChanges}
                        finishOperation={auxFunctions.FinishOperation}
                        countryList={auxConstObject.countryList}
                    />
                </Grid>
            );
        };
    } else if (isMultyValue) {
        return auxFunctions.ManageMultiValueDropDowns(isUser, attribute, isForAddress, auxKey, auxFieldSize);
    } else {
        let setIsControlledCountry = auxControlledCountry[auxPersonTypeValue].setFunction;
        let optionsList = [];
        let auxForNoUserPhoneList =
            attribute.codeAttribute === AttributeCodes.Prefix && userEditablePhones.length == 0;
        let auxForNoUserDocumentList = getDontShow(attribute, userEditableDocuments, auxConstObject.auxiliarTab);
        return (auxForNoUserPhoneList || auxForNoUserDocumentList) ? null : (
            <Grid key={auxKey} item md={auxFieldSize} xs={12}>
                <DropdownAttribute
                    attribute={attribute}
                    saveGroupsChanges={auxFunctions.SaveGroupsChanges}
                    setIsControlledCountry={setIsControlledCountry}
                    countryList={auxConstObject.countryList}
                    roadType={auxConstObject.roadType}
                    optionsList={optionsList}
                />
            </Grid>
        );
    };
};

const generateDatePicker = (attribute, auxFunctions, userEditableObjects, auxConstObject) => {
    let userEditableDocuments = userEditableObjects.EditableDocuments;
    let auxFieldSize = Attributes[attribute.codeAttribute].size;
    let auxKey = attribute.nameGroup + "_" + attribute.codeAttribute;
    let auxForNoUserDocumentList = getDontShow(attribute, userEditableDocuments, auxConstObject.auxiliarTab);
    return auxForNoUserDocumentList ? null : (
        <Grid item key={auxKey} md={auxFieldSize} xs={12}>
            <DatePickerAttribute
                attribute={attribute}
                saveGroupsChanges={auxFunctions.SaveGroupsChanges}
            />
        </Grid>
    );
};

export const getOptionList = (auxCodeAttribute, userEmailOptionList, userPhoneOptionList, userAddressOptionList, enterpriseAddressOptionList) => {
    let auxObj = { [AttributeCodes.Email]: userEmailOptionList, [AttributeCodes.Phone]: userPhoneOptionList, [AttributeCodes.DocType]: [], [AttributeCodes.Alias]: userAddressOptionList, [AttributeCodes.EntAlias]: enterpriseAddressOptionList }
    let optionsList = auxObj[auxCodeAttribute];
    return checkIsNullUndefined(optionsList) ? [] : optionsList;
}

export const getFinalFieldSize = (codeAttribute, auxFieldSize, auxForNoList, hasNoEmail, hasNoPhone) => {
    let finalFIeldSize = auxFieldSize;
    if (codeAttribute == AttributeCodes.Email || codeAttribute == AttributeCodes.Phone || codeAttribute == AttributeCodes.DocType) {
        if (codeAttribute == AttributeCodes.Email && hasNoPhone) {
            finalFIeldSize = 12;
        } else if (codeAttribute == AttributeCodes.Phone && hasNoEmail) {
            finalFIeldSize = 10;
        } else if ((codeAttribute == AttributeCodes.Email || codeAttribute == AttributeCodes.Phone || codeAttribute == AttributeCodes.DocType) && auxForNoList) {
            finalFIeldSize = 6;
        }
    }
    return finalFIeldSize;
};

export const getFinalAttributeSize = (codeAttribute, hasNoEmail, hasNoPhone) => {
    let finalAttributeSize = 8;
    if ((codeAttribute === AttributeCodes.Email && hasNoPhone) || (codeAttribute === AttributeCodes.Alias || codeAttribute === AttributeCodes.EntAlias)) {
        finalAttributeSize = 10;
    } else if (codeAttribute === AttributeCodes.Email && !hasNoPhone) {
        finalAttributeSize = 8;
    } else if (codeAttribute === AttributeCodes.Phone && hasNoEmail) {
        finalAttributeSize = 9;
    } else if (codeAttribute === AttributeCodes.Phone && !hasNoEmail) {
        finalAttributeSize = 6;
    };
    return finalAttributeSize;
};

export const generateMultiValueDropDown = (
    auxiliar,
    getBothFormValuesObject,
    getBothAddressSettersObject,
    getBothAddressValuesObject,
    getUserFormSetersObject,
    getUserFormValuesObject,
    getBothFormSettersObject,
) => {
    let auxInfo = auxiliar.auxInfo;
    let auxFunctions = auxiliar.auxFunctions;
    let attribute = auxInfo.attribute;
    let auxFormValuesObject = getBothFormValuesObject();
    let auxAddressSettersObject = getBothAddressSettersObject();
    let auxAddressValuesObject = getBothAddressValuesObject();
    let auxUserFormSettersObject = getUserFormSetersObject();
    let auxUserFormValuesObject = getUserFormValuesObject();
    let auxBothFormSettersObject = getBothFormSettersObject();
    let hasNoEmail = checkIsNullUndefined(obtainAttribute(auxFormValuesObject[EnumPersonType.User].DataForm, AttributeCodes.Email));
    let hasNoPhone = checkIsNullUndefined(obtainAttribute(auxFormValuesObject[EnumPersonType.User].DataForm, AttributeCodes.Phone));
    let personType = auxInfo.isUser ? EnumPersonType.User : EnumPersonType.Enterprise;
    let setIsControlledCountry = auxAddressSettersObject[personType].setIsControlledCountry;
    let optionsList = getOptionList(
        attribute.codeAttribute,
        auxUserFormValuesObject.EmailOptionList,
        auxUserFormValuesObject.PhoneOptionList,
        auxAddressValuesObject[EnumPersonType.User].AddressOptionList,
        auxAddressValuesObject[EnumPersonType.Enterprise].AddressOptionList);
    let auxForAddressPopup =
        (attribute.codeAttribute === AttributeCodes.Alias ||
            attribute.codeAttribute === AttributeCodes.EntAlias) &&
        auxInfo.isForAddress;
    let auxNoListCodesValues = { [AttributeCodes.Email]: "EditableEmails", [AttributeCodes.Phone]: "EditablePhones", [AttributeCodes.DocType]: "EditableDocuments" };
    let auxForNoList = auxUserFormValuesObject[auxNoListCodesValues[attribute.codeAttribute]]?.length == 0;
    let finalFieldSize = getFinalFieldSize(attribute.codeAttribute, auxInfo.auxFieldSize, auxForNoList, hasNoEmail, hasNoPhone);
    let finalAttributeSize = getFinalAttributeSize(attribute.codeAttribute, hasNoEmail, hasNoPhone);

    const handleAddEmail = (email, isVerified) => {
        handleAuxAddEmail(email, isVerified, auxFormValuesObject, auxUserFormValuesObject, auxUserFormSettersObject, auxBothFormSettersObject);
    };

    const handleVerifyEmail = (email) => {
        handleAuxVerifyEmail(email, auxUserFormValuesObject, auxUserFormSettersObject);
    };

    const handleRemoveEmail = (email) => {
        handleAuxRemoveEmail(email, auxFormValuesObject, auxUserFormValuesObject, auxUserFormSettersObject, auxBothFormSettersObject);
    };

    const handleAddPhone = (phone, prefix, isVerified) => {
        handleAuxAddPhone(phone, prefix, isVerified, auxFormValuesObject, auxUserFormValuesObject, auxUserFormSettersObject, auxBothFormSettersObject);
    };

    const handleVerifyPhone = (phone) => {
        handleAuxVerifyPhone(phone, auxUserFormValuesObject, auxUserFormSettersObject);
    };

    const handleRemovePhone = (phone, prefix) => {
        handleAuxRemovePhone(phone, prefix, auxFormValuesObject, auxUserFormValuesObject, auxUserFormSettersObject, auxBothFormSettersObject);
    };

    const handleDocumentActions = (
        actionType,
        auxDocType,
        auxDocNumber,
        auxDocCountry,
        auxDocDate,
        auxDocId
    ) => {
        let auxDocInfo = {
            auxDocType: auxDocType,
            auxDocNumber: auxDocNumber,
            auxDocCountry: auxDocCountry,
            auxDocDate: auxDocDate,
            auxDocId: auxDocId
        }
        handleAuxDocumentActions(
            actionType,
            auxDocInfo,
            auxFormValuesObject,
            auxUserFormValuesObject,
            auxFunctions,
            auxUserFormSettersObject,
            auxBothFormSettersObject,
        )
    };

    return (
        <Grid
            key={auxInfo.auxKey}
            container
            item
            md={finalFieldSize}
            xs={12}
            spacing={0}
        >
            {auxForAddressPopup ? (
                <Grid item xs={12}>
                    <TextBoxAttribute
                        attribute={attribute}
                        saveGroupsChanges={auxFunctions.saveGroupsChanges}
                        addressAliasList={
                            attribute.codeAttribute === AttributeCodes.Alias
                                ? getAddresses(auxAddressValuesObject[EnumPersonType.User].EditableAddresses)
                                : getAddresses(auxAddressValuesObject[EnumPersonType.Enterprise].EditableAddresses)
                        }
                    />
                </Grid>
            ) : !auxForNoList ? (
                <Grid item xs={finalAttributeSize}>
                    <DropdownAttribute
                        attribute={attribute}
                        saveGroupsChanges={auxFunctions.saveGroupsChanges}
                        setIsControlledCountry={setIsControlledCountry}
                        countryList={auxInfo.countryList}
                        typeUserDocument={auxUserFormValuesObject.DocumentType}
                        optionsList={optionsList}
                    />
                </Grid>
            ) : null}
            {attribute.codeAttribute === AttributeCodes.Email ? (
                <Grid item xs={auxForNoList ? 12 : hasNoPhone ? 2 : 4}>
                    <DialogEmailActions
                        actualValue={attribute.value}
                        emailsList={auxUserFormValuesObject.EditableEmails}
                        mandatory={attribute.mandatory}
                        mustRegistry={attribute.mustRegistry}
                        handleAddEmail={handleAddEmail}
                        handleVerifyEmail={handleVerifyEmail}
                        handleRemoveEmail={handleRemoveEmail}
                        finishOperation={auxFunctions.finishOperation}
                        isRegistry={auxInfo.isRegistry}
                    />
                </Grid>
            ) : attribute.codeAttribute === AttributeCodes.Phone ? (
                <Grid item xs={auxForNoList ? 12 : hasNoEmail ? 3 : 6}>
                    <DialogPhoneActions
                        actualValue={attribute.value}
                        phonesList={auxUserFormValuesObject.EditablePhones}
                        mandatory={attribute.mandatory}
                        mustRegistry={attribute.mustRegistry}
                        handleAddPhone={handleAddPhone}
                        handleVerifyPhone={handleVerifyPhone}
                        handleRemovePhone={handleRemovePhone}
                        countryList={auxInfo.countryList}
                        finishOperation={auxFunctions.finishOperation}
                        isRegistry={auxInfo.isRegistry}
                    />
                </Grid>
            ) : attribute.codeAttribute === AttributeCodes.DocType ? (
                <Grid item xs={auxForNoList ? 12 : 4} style={{ display: "flex", margin: "auto" }}>
                    <DialogDocumentActions
                        typeUserDocument={auxUserFormValuesObject.DocumentType}
                        countryList={auxInfo.countryList}
                        userModel={auxFormValuesObject[EnumPersonType.User].DataForm}
                        handleDocumentActions={handleDocumentActions}
                        finishOperation={auxFunctions.finishOperation}
                        editableDocuments={auxUserFormValuesObject.EditableDocuments}
                        userNeedsDocument={auxUserFormValuesObject.NeedsDocument}
                        auxiliarTab={auxInfo.auxiliarTab}
                    />
                </Grid>
            ) : null}
        </Grid>
    );
};

export const managePhones = (auxForm, auxValue, getUserFormValuesObject) => {
    let auxUserFormValuesObject = getUserFormValuesObject();
    let auxPhone = auxUserFormValuesObject.EditablePhones.find((phone) => phone.phone == auxValue);
    let auxPrefix = obtainAttribute(auxForm, AttributeCodes.Prefix);
    if (!checkIsNullUndefined(auxPhone)) {
        auxPrefix.value = auxPhone.idCountry;
    } else {
        auxPrefix.value = auxValue;
    }
};

export const manageDocuments = (auxForm, auxValue, getUserFormValuesObject) => {
    let auxUserFormValuesObject = getUserFormValuesObject();
    let auxDocument = auxUserFormValuesObject.EditableDocuments.find(
        (document) => document.idTypeUserDocument == auxValue
    );
    let auxDocNumber = "";
    let auxDocDate = "yyyy-MM-dd";
    let auxDocCountry = "";
    if (!checkIsNullUndefined(auxDocument)) {
        auxDocNumber = auxDocument.number;
        auxDocDate = auxDocument.expirationDate;
        auxDocCountry = auxDocument.idCountry;
    }
    let auxDocNumberAttr = obtainAttribute(auxForm, AttributeCodes.DocNumber);
    let auxDocDateAttr = obtainAttribute(auxForm, AttributeCodes.ExpirDate);
    let auxDocCountryAttr = obtainAttribute(auxForm, AttributeCodes.ExpedCountry);
    auxDocNumberAttr.value = auxDocNumber;
    auxDocDateAttr.value = auxDocDate;
    auxDocCountryAttr.value = auxDocCountry;
};

export const mountUserObject = (getBothFormValuesObject, getUserFormValuesObject) => {
    let auxFormValuesObject = getBothFormValuesObject(EnumPersonType.User);
    let auxUserFormValuesObject = getUserFormValuesObject();
    let userObject = {
        name: getValue(AttributeCodes.Name, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData),
        surname1: getValue(AttributeCodes.Surname1, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData),
        surname2: getValue(AttributeCodes.Surname2, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData),
        birthDate: getValue(AttributeCodes.Birthday, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData),
        nationality: checkIsNullUndefined(getValue(AttributeCodes.Nationality, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData))
            ? null
            : parseInt(getValue(AttributeCodes.Nationality, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData), 10),
        idGender: checkIsNullUndefined(getValue(AttributeCodes.Sex, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData))
            ? null
            : parseInt(getValue(AttributeCodes.Sex, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData), 10),
        idLanguage: checkIsNullUndefined(getValue(AttributeCodes.Languaje, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData))
            ? null
            : parseInt(getValue(AttributeCodes.Languaje, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData), 10),
        userAddress: [],
        userDocument: [],
        userEmail: auxUserFormValuesObject.EditableEmails?.filter((email) => email.id !== "NEW"),
        userPhone: auxUserFormValuesObject.EditablePhones?.filter((phone) => phone.id !== "NEW"),
    };
    return userObject;
};

export const mountEnterpriseObject = (getBothFormValuesObject, getBothAddressValuesObject, idEnterprise) => {
    let auxFormValuesObject = getBothFormValuesObject(EnumPersonType.Enterprise);
    let auxAddressValuesObject = getBothAddressValuesObject(EnumPersonType.Enterprise);
    let enterpriseObject = {
        documentNumber: getValue(AttributeCodes.EntDocNumber, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData),
        expirationDate: getValue(AttributeCodes.EntExpirDate, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData),
        companyName: getValue(AttributeCodes.EntName, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData),
        tradeName: getValue(AttributeCodes.EntComName, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData),
        idProfessionalActivity: checkIsNullUndefined(
            getValue(AttributeCodes.EntProfActivity, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData)
        )
            ? null
            : parseInt(getValue(AttributeCodes.EntProfActivity, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData), 10),
        idCountry: checkIsNullUndefined(getValue(AttributeCodes.EntExpedCountry, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData))
            ? null
            : parseInt(getValue(AttributeCodes.EntExpedCountry, auxFormValuesObject.DataForm, auxFormValuesObject.OriginalData), 10),
        enterpriseAddress: auxAddressValuesObject.EditableAddresses,
    };
    if (!checkIsNullUndefined(idEnterprise)) {
        enterpriseObject.id = parseInt(idEnterprise, 10);
    }
    return enterpriseObject;
};