import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Container,
} from "@material-ui/core";
import { CommonStyles } from "../../commons/CommonStyles";
import { MainStyles } from "./MainStyles";
import PersonIcon from "@material-ui/icons/Person";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import LockIcon from "@material-ui/icons/Lock";
import { EnumPages } from "../../commons/EnumPages";
import { Alerts } from "../Alerts/Alerts";
import { useTranslation } from "react-i18next";
import history from "../../services/history";
import { checkIsNullUndefined } from "../../commons/Utils";

export function Main(props) {
  const {
    isSpecialUser
  } = props;
  const commonClasses = CommonStyles();
  const classes = MainStyles();
  const { t, i18n } = useTranslation();

  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });

  useEffect(() => { }, []);

  return (
    <Grid>
      {/*TODO Descomentar cuando esté el endpoint de noticias y preparar*/}
      {/* <NewsSnack showSideMenu={props.showSideMenu} /> */}
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      <Container maxWidth={"lg"} className={commonClasses.mainContainer}>
        <Grid container spacing={0}>
          <Grid item md={12} xs={12}>
            <Typography
              variant="h3"
              style={{ color: "#002453", margin: "15px" }}
            >
              {t("mainPageTitle")}
            </Typography>
          </Grid>
          <Grid item md={2} xs={12}></Grid>
          <Grid item md={3} xs={12}>
            <Button
              className={classes.cardButton}
              onClick={() => history.push("/Index/" + EnumPages.UserData)}
            >
              <Card className={classes.cardStyle} elevation={4}>
                <CardContent>
                  <PersonIcon className={classes.iconStyle} />
                  <Typography className={classes.cardTextStyle}>
                    {t("mainPageUserProfile")}
                  </Typography>
                </CardContent>
              </Card>
            </Button>
          </Grid>
          <Grid item md={2} xs={12}>
            <Button
              className={classes.cardButton}
              onClick={() =>
                history.push("/Index/" + EnumPages.UserVerifications)
              }
            >
              <Card className={classes.smallCardStyleUp} elevation={4}>
                <CardContent>
                  <DoneAllIcon className={classes.smallIconStyleUp} />
                  <Typography className={classes.cardTextStyle}>
                    {t("mainPageVerifications")}
                  </Typography>
                </CardContent>
              </Card>
            </Button>
            {(checkIsNullUndefined(isSpecialUser) || !isSpecialUser) ? (
              <Button
                className={classes.cardButton}
                onClick={() =>
                  history.push("/Index/" + EnumPages.EnterpriseCreate)
                }
              >
                <Card className={classes.smallCardStyleDown} elevation={4}>
                  <CardContent>
                    <Typography
                      className={classes.cardTextStyle}
                      style={{ marginTop: "15px" }}
                    >
                      {t("mainPageCreateEnterprise")}
                    </Typography>
                  </CardContent>
                </Card>
              </Button>
            ) : null}
          </Grid>
          <Grid item md={3} xs={12}>
            <Button
              className={classes.cardButton}
              onClick={() => history.push("/Index/" + EnumPages.UserPrivacy)}
            >
              <Card className={classes.cardStyle} elevation={4}>
                <CardContent>
                  <LockIcon className={classes.iconStyle} />
                  <Typography className={classes.cardTextStyle}>
                    {t("mainPagePrivacity")}
                  </Typography>
                </CardContent>
              </Card>
            </Button>
          </Grid>
          <Grid item md={2} xs={12}></Grid>
          <Grid item md={12} xs={12} style={{ padding: "1em" }}></Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
