import { makeStyles } from '@material-ui/core/styles';

export const SwitchYesNoButtonStyles = makeStyles((theme) => ({
    switchButton: {
        width: "3em",
    },
    switchButtonNoActive: {
        width: "3em",
        color: "white",
    },
    switchButtonLabel: {
        textAlign: "right",
        color: "#666666",
        fontSize: "80%",
        marginTop: "auto",
        marginBottom: "auto"
    },
    checkBoxLabel: {
        color: "#666666",
        fontSize: "80%",
        marginTop: "0.25em",
        marginBottom: "0.25em"
    },
}));