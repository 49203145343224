import React, { useEffect, useState } from "react";
import { Grid, Typography, Card, CardContent } from "@material-ui/core";
import { getErrorMessage } from "../../../commons/Utils";
import { CommonStyles } from "../../../commons/CommonStyles";
import { EnterpriseTeamStyles } from "../EnterpriseTeamStyles";
import {
  GetEnterpriseDataEnterpriseItems,
} from "../../../services/EnterpriseDataCallsService";
import {
  GetMasterEnterpriseItem,
} from "../../../services/MasterCallsService";
import { useTranslation } from "react-i18next";
import { DialogDeleteItem } from "./DialogDeleteItem";
import { DialogEditItem } from "./DialogEditItem";
import { DialogAddItem } from "./DialogAddItem";

export default function ObjectsCard(props) {
  const { idEnterprise, finishOperation, isTitular } = props;
  const commonClasses = CommonStyles();
  const classes = EnterpriseTeamStyles();
  const { t, i18n } = useTranslation();

  const [items, setItems] = useState([]);
  const [objects, setObjects] = useState([]);

  useEffect(() => {
      loadItems();
  }, []);

  const loadItems = () => {
    GetEnterpriseDataEnterpriseItems(idEnterprise).then((response) => {
      if (response && !response.Status) {
        setItems(response);
        if (objects.length === 0) {
          loadObjects();
        }
      } else {
        // Alerta
        finishOperation("error", getErrorMessage(response, t));
      }
    });
  };

  const loadObjects = () => {
    GetMasterEnterpriseItem().then((response) => {
      if (response && !response.Status) {
        setObjects(response);
      } else {
        //Alerta
        finishOperation("error", getErrorMessage(response, t));
      }
    });
  };

  const actionButtons = (item) => {
    return (
      <Grid container item xs={2}>
        <Grid item xs={6}>
          <DialogEditItem
            finishOperation={finishOperation}
            item={item}
            idEnterprise={idEnterprise}
            loadItems={loadItems}
          />
        </Grid>
        <Grid item xs={6}>
          <DialogDeleteItem
            finishOperation={finishOperation}
            item={item}
            idEnterprise={idEnterprise}
            loadItems={loadItems}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item xs={12} style={{ marginTop: "1.5em" }}>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              {/* <MdDashboard
                className={commonClasses.iconStyle}
                style={{ float: "left" }}
              /> */}
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="h5"
                align="center"
                className={commonClasses.gridTitle}
              >
                {" " + t("teamPageItemsTitle")}
              </Typography>
            </Grid>
            {isTitular ? (
              <Grid item xs={2}>
                <DialogAddItem
                  idEnterprise={idEnterprise}
                  finishOperation={finishOperation}
                  objects={objects}
                  loadItems={loadItems}
                />
              </Grid>
            ) : (
              <Grid item xs={2}></Grid>
            )}
          </Grid>
          {objects.map((objectInfo) => {
            return (
              <Grid container key={objectInfo.id}>
                <Grid item xs={12}>
                  <Typography
                    align="left"
                    variant="h6"
                    className={classes.textHolder}
                  >
                    {objectInfo.name}
                  </Typography>
                </Grid>
                {items.map((item) => {
                  return item.idEnterpriseItem === objectInfo.id ? (
                    <Grid container item xs={12}>
                      <Grid item xs={10}>
                        <Typography
                          align="left"
                          noWrap
                          className={classes.dataAuthorizedName}
                        >
                          {item.value}
                        </Typography>
                      </Grid>
                      {isTitular ? (
                        actionButtons(item)
                      ) : (
                        <Grid container item xs={2}></Grid>
                      )}
                    </Grid>
                  ) : null;
                })}
              </Grid>
            );
          })}
        </CardContent>
      </Card>
    </Grid>
  );
}
