import React from "react";
import { Tooltip, Typography } from "@material-ui/core";
import { CorreosToolTipStyles } from "./CorreosToolTipStyles";

export function CorreosToolTip(props) {
  const classes = CorreosToolTipStyles();
  const { text, children, ...other } = props;
  
  return (
    <Tooltip
      title={<Typography variant="body1">{text}</Typography>}
      classes={{ tooltip: classes.customTooltip }}
      {...other}>
        {children}
    </Tooltip>
  );
}