import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  CircularProgress,
  Typography
} from "@material-ui/core";
import {
  checkIsNullUndefined,
} from "../../commons/Utils";
import {
  ManageAddressFirstIteration,
} from "../../commons/AddressManager";
import {
  checkEditableDirOkNew,
  obtainAttribute,
  getEmails,
  getPhones,
  getAddresses,
  fillAddressEditableModelNew,
} from "../../commons/FormManager";
import { CommonStyles } from "../../commons/CommonStyles";
import { useTranslation } from "react-i18next";
import history from "../../services/history";
import {
  emptyUser,
  errorCodes,
  basicAddress,
} from "../../commons/ConstantObjects";
import {
  backToLogin,
  getSessionItem,
  goToLoginOauth,
  missedInfo,
} from "../../services/Commons";
import { DialogMissingData } from "./Components/DialogMissingData";
import { BasicDialog } from "../CommonComponents/BasicDialog";
import { groupAttributes } from "../../commons/EnumAttributes";
import { EnumPersonType, sessionValues } from "../../commons/EnumsGeneral";
import { disableAttr, generateMultiValueDropDown, getRequiredInput, manageDocuments, managePhones } from "./Components/FormFunctions";
import { manageEntryDialog, manageExitDialog, manageExitDialogTitle, prePreparePage, resetEnterprise } from "./Components/PreparePageFunctions";
import { changeAddressParam, changeAddressSelected, handleAuxAddAddress, handleAuxChangeAddress, handleAuxIsControlledCountry, handleAuxRemoveAddress, prepareAuxBasicAddressForm, prepareAuxEditableAddressForm } from "./Components/HandleMultiFormAddress";
import { AttributeCodes } from "../../commons/Attributes";
import { checkAllOk } from "./Components/CheckFunctions";
import { mountButtons, mountFormBlock } from "./Components/FormBlock";
import { manageSavePath } from "./Components/SaveFunctions";

export function RequiredData(props) {
  const {
    isRegistry,
    setUpdateUser,
    setUpdateEnterprises,
    setIdEnterprise,
    idEnterprise,
    forUser,
    forEnterprise,
    onlyMandatories,
    mainPage,
    registerPage,
    subscriptionPage,
    pendingDataPage,
    setUserMandatoryData,
    setEnterpriseMandatoryData,
    agreeConditions,
    resetAlert,
    isCreate,
    auxiliarTab,
    forceResetPage,
    finishOperation,
    regsitryPersonType
  } = props;
  const commonClasses = CommonStyles();
  const { t, i18n } = useTranslation();
  const isPF = sessionStorage.getItem("isPF");

  const [userGroups, setUserGroups] = useState([]);
  const [enterpriseGroups, setEnterpriseGroups] = useState([]);
  const [userDataForm, setUserDataForm] = useState([]);
  const [userOriginalData, setUserOriginalData] = useState({});
  const [userAddressForm, setUserAddressForm] = useState([]);
  const [userAddressEditableForm, setUserAddressEditableForm] = useState([]);
  const [userAddressEditableFormOk, setUserAddressEditableFormOk] =
    useState(false);
  const [userAddressBasicForm, setUserAddressBasicForm] = useState([]);
  const [enterpriseDataForm, setEnterpriseDataForm] = useState([]);
  const [enterpriseOriginalData, setEnterpriseOriginalData] = useState({});
  const [enterpriseAddressForm, setEnterpriseAddressForm] = useState([]);
  const [enterpriseAddressEditableForm, setEnterpriseAddressEditableForm] =
    useState([]);
  const [enterpriseAddressEditableFormOk, setEnterpriseAddressEditableFormOk] =
    useState(false);
  const [enterpriseAddressBasicForm, setEnterpriseAddressBasicForm] = useState([]);
  const [userEditableEmails, setUserEditableEmails] = useState([]);
  const [userEmailOptionList, setUserEmailOptionList] = useState([]);
  const [userEditablePhones, setUserEditablePhones] = useState([]);
  const [userPhoneOptionList, setUserPhoneOptionList] = useState([]);
  const [userEditableDocuments, setUserEditableDocuments] = useState([]);
  const [userEditableAddresses, setUserEditableAddresses] = useState([]);
  const [userAddressOptionList, setUserAddressOptionList] = useState([]);
  const [enterpriseEditableAddresses, setEnterpriseEditableAddresses] =
    useState([]);
  const [enterpriseAddressOptionList, setEnterpriseAddressOptionList] =
    useState([]);
  const [userActiveAddress, setUserActiveAddress] = useState(null);
  const [enterpriseActiveAddress, setEnterpriseActiveAddress] = useState(null);
  const [userIsControlledCountry, setUserIsControlledCountry] = useState(true);
  const [enterpriseIsControlledCountry, setEnterpriseIsControlledCountry] =
    useState(true);
  const [isTitular, setIsTitular] = useState(false);
  const [userHasFavouriteAddress, setUserHasFavouriteAddress] = useState(false);
  const [enterpriseHasFavouriteAddress, setEnterpriseHasFavouriteAddress] =
    useState(false);
  const [userIsFavouriteAddress, setUserIsFavouriteAddress] = useState(false);
  const [enterpriseIsFavouriteAddress, setEnterpriseIsFavouriteAddress] =
    useState(false);
  const [userTab, setUserTab] = useState(0);
  const [enterpriseTab, setEnterpriseTab] = useState(0);
  const [resetPage, setResetPage] = useState(false);
  const [userNeedsDocument, setUserNeedsDocument] = useState(false);
  const [userNeedsAddress, setUserNeedsAddress] = useState(false);
  const [enterpriseNeedsAddress, setEnterpriseNeedsAddress] = useState(false);
  const [pendingData, setPendingData] = useState([]);
  const [showPendingData, setShowPendingData] = useState(false);
  const [pageCircularProgress, setPageCircularProgress] = useState(false);
  const [userAddressCircularProgress, setUserAddressCircularProgress] =
    useState(false);
  const [
    enterpriseAddressCircularProgress,
    setEnterpriseAddressCircularProgress,
  ] = useState(false);
  const [userActionCircularProgress, setUserActionCircularProgress] =
    useState(false);
  const [
    enterpriseActionCircularProgress,
    setEnterpriseActionCircularProgress,
  ] = useState(false);
  const [
    nextCircularProgress,
    setNextCircularProgress,
  ] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [userDocumentType, setUserDocumentType] = useState([]);
  const [roadType, setRoadType] = useState([]);
  const [userAddressOperationType, setUserAddressOperationType] = useState("");
  const [enterpriseAddressOperationType, setEnterpriseAddressOperationType] =
    useState("");
  const [missingData, setMissingData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openExitDialog, setOpenExitDialog] = useState(false);
  const [openEntryDialog, setOpenEntryDialog] = useState(false);
  const [agreeTyCs, setAgreeTyCs] = useState(false);
  const [needVerifyDocPopup, setNeedVerifyDocPopup] = useState(false);
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [openNoHostDialog, setOpenNoHostDialog] = useState(false);
  const [openACMDialog, setOpenACMDialog] = useState(false);

  useEffect(() => {
    let auxMissingData = missedInfo();
    setMissingData(auxMissingData);
    if (isRegistry) {
      setOpenEntryDialog(true);
    };
  }, []);

  useEffect(() => {
    let auxValue = checkMissingDataVerifyDoc();
    setNeedVerifyDocPopup(auxValue);
    if (!isRegistry && (auxValue || missingData.length > 0)) {
      setOpenDialog(true);
    }
  }, [missingData]);

  useEffect(() => {
    finishOperation("", "", false);
  }, [resetAlert]);

  useEffect(() => {
    if (forEnterprise) {
      resetEnterprise(getBothFormSettersObject, getBothAddressSettersObject);
    };
    let auxConst = {
      forUser,
      forEnterprise,
      isRegistry,
      onlyMandatories,
      registerPage,
      idEnterprise,
      emptyUser,
      auxiliarTab,
    };
    let auxFunctions = {
      setIsTitular,
      setAll,
      finishOperation,
      setCountryList,
      setUserDocumentType,
      setRoadType,
      setPageCircularProgress,
      doTraduction: t,
    };
    prePreparePage(
      auxConst,
      auxFunctions,
      getBothFormSettersObject,
      getUserFormSetersObject,
      getBothAddressSettersObject,
    );
  }, [resetPage, idEnterprise, forUser, forceResetPage]);

  useEffect(() => {
    if (!checkIsNullUndefined(userActiveAddress) && !isNewAddress) {
      let auxUserAddressForm = !checkIsNullUndefined(userActiveAddress) ? JSON.parse(JSON.stringify(userAddressForm)) : JSON.parse(JSON.stringify(userAddressBasicForm));
      let auxUserEditableAddress = !checkIsNullUndefined(userActiveAddress) ? userEditableAddresses.find((add) => add.id == userActiveAddress) : JSON.parse(JSON.stringify(basicAddress));
      setUserAddressCircularProgress(true);
      handleAddressChange(
        auxUserAddressForm,
        auxUserEditableAddress,
        setUserIsFavouriteAddress,
        setUserIsControlledCountry
      );
    } else {
      setUserAddressCircularProgress(false);
      setUserActionCircularProgress(false);
      setIsNewAddress(false);
    }
  }, [userActiveAddress]);

  useEffect(() => {
    if (!checkIsNullUndefined(enterpriseActiveAddress) && !isNewAddress) {
      setEnterpriseAddressCircularProgress(true);
      let auxEnterpriseAddressForm = !checkIsNullUndefined(enterpriseActiveAddress) ? JSON.parse(
        JSON.stringify(enterpriseAddressForm)) : JSON.parse(JSON.stringify(enterpriseAddressBasicForm));
      let auxEnterpriseEditableAddress = !checkIsNullUndefined(enterpriseActiveAddress) ?
        enterpriseEditableAddresses.find((add) => add.id == enterpriseActiveAddress) : JSON.parse(JSON.stringify(basicAddress));
      handleAddressChange(
        auxEnterpriseAddressForm,
        auxEnterpriseEditableAddress,
        setEnterpriseIsFavouriteAddress,
        setEnterpriseIsControlledCountry
      );
    } else {
      setEnterpriseAddressCircularProgress(false);
      setEnterpriseActionCircularProgress(false);
      setIsNewAddress(false);
    }
  }, [enterpriseActiveAddress]);

  useEffect(() => {
    callCheckAllOk();
  }, [
    userDataForm,
    enterpriseDataForm,
    userAddressForm,
    enterpriseAddressForm,
    userEditableAddresses,
    userEditablePhones,
    userEditableEmails,
    userEditableDocuments,
    enterpriseEditableAddresses,
    agreeConditions,
    agreeTyCs,
    regsitryPersonType,
  ]);

  useEffect(() => {
    let auxEmailOptionList = getEmails(userEditableEmails);
    setUserEmailOptionList(auxEmailOptionList);
  }, [userEditableEmails]);

  useEffect(() => {
    let auxPhoneOptionList = getPhones(userEditablePhones);
    setUserPhoneOptionList(auxPhoneOptionList);
  }, [userEditablePhones]);

  useEffect(() => {
    let auxAddressOptionList = getAddresses(userEditableAddresses);
    setUserAddressOptionList(auxAddressOptionList);
  }, [userEditableAddresses]);

  useEffect(() => {
    let auxAddressOptionList = getAddresses(enterpriseEditableAddresses);
    setEnterpriseAddressOptionList(auxAddressOptionList);
  }, [enterpriseEditableAddresses]);

  const checkMissingDataVerifyDoc = () => {
    let auxMissingData = missingData.filter((errorCode) => errorCode != errorCodes[203]);
    return ((auxMissingData.length == 1 && auxMissingData.indexOf(errorCodes[242]) !== -1));
  };

  const getUserFormSetersObject = () => {
    let auxObj = {
      SetEditableEmails: setUserEditableEmails,
      SetEmailOptionList: setUserEmailOptionList,
      SetEditablePhones: setUserEditablePhones,
      SetPhoneOptionList: setUserPhoneOptionList,
      SetEditableDocuments: setUserEditableDocuments,
      SetNeedsDocument: setUserNeedsDocument,
      SetDocumentType: setUserDocumentType,
    };
    return auxObj;
  };

  const getUserFormValuesObject = () => {
    let auxObj = {
      EditableEmails: userEditableEmails,
      EmailOptionList: userEmailOptionList,
      EditablePhones: userEditablePhones,
      PhoneOptionList: userPhoneOptionList,
      EditableDocuments: userEditableDocuments,
      NeedsDocument: userNeedsDocument,
      DocumentType: userDocumentType,
    };
    return auxObj;
  };

  const getBothFormSettersObject = (personType) => {
    let auxObj = {
      [EnumPersonType.User]: {
        SetGroups: setUserGroups,
        SetDataForm: setUserDataForm,
        SetOriginalData: setUserOriginalData,
        SetTab: setUserTab,
      }, [EnumPersonType.Enterprise]: {
        SetGroups: setEnterpriseGroups,
        SetDataForm: setEnterpriseDataForm,
        SetOriginalData: setEnterpriseOriginalData,
        SetTab: setEnterpriseTab,
      }
    };
    return personType ? auxObj[personType] : auxObj;
  };

  const getBothFormValuesObject = (personType) => {
    let auxObj = {
      [EnumPersonType.User]: {
        Groups: userGroups,
        DataForm: userDataForm,
        OriginalData: userOriginalData,
        Tab: userTab,
      }, [EnumPersonType.Enterprise]: {
        Groups: enterpriseGroups,
        DataForm: enterpriseDataForm,
        OriginalData: enterpriseOriginalData,
        Tab: enterpriseTab,
      }
    };
    return personType ? auxObj[personType] : auxObj;
  };

  const getBothAddressSettersObject = (personType) => {
    let auxObj = {
      [EnumPersonType.User]: {
        SetAddressForm: setUserAddressForm,
        SetAddressEditableForm: setUserAddressEditableForm,
        SetAddressEditableFormOk: setUserAddressEditableFormOk,
        SetAddressBasicForm: setUserAddressBasicForm,
        SetEditableAddresses: setUserEditableAddresses,
        SetAddressOptionList: setUserAddressOptionList,
        SetActiveAddress: setUserActiveAddress,
        SetIsControlledCountry: setUserIsControlledCountry,
        SetHasFavouriteAddress: setUserHasFavouriteAddress,
        SetIsFavouriteAddress: setUserIsFavouriteAddress,
        SetNeedsAddress: setUserNeedsAddress,
        SetAddressCircularProgress: setUserAddressCircularProgress,
        SetAddressOperationType: setUserAddressOperationType,
        SetActionCircularProgress: setUserActionCircularProgress,
      }, [EnumPersonType.Enterprise]: {
        SetAddressForm: setEnterpriseAddressForm,
        SetAddressEditableForm: setEnterpriseAddressEditableForm,
        SetAddressEditableFormOk: setEnterpriseAddressEditableFormOk,
        SetAddressBasicForm: setEnterpriseAddressBasicForm,
        SetEditableAddresses: setEnterpriseEditableAddresses,
        SetAddressOptionList: setEnterpriseAddressOptionList,
        SetActiveAddress: setEnterpriseActiveAddress,
        SetIsControlledCountry: setEnterpriseIsControlledCountry,
        SetHasFavouriteAddress: setEnterpriseHasFavouriteAddress,
        SetIsFavouriteAddress: setEnterpriseIsFavouriteAddress,
        SetNeedsAddress: setEnterpriseNeedsAddress,
        SetAddressCircularProgress: setEnterpriseAddressCircularProgress,
        SetAddressOperationType: setEnterpriseAddressOperationType,
        SetActionCircularProgress: setEnterpriseActionCircularProgress,
      }
    };
    return personType ? auxObj[personType] : auxObj;
  };

  const getBothAddressValuesObject = (personType) => {
    let auxObj = {
      [EnumPersonType.User]: {
        AddressForm: userAddressForm,
        AddressEditableForm: userAddressEditableForm,
        AddressEditableFormOk: userAddressEditableFormOk,
        AddressBasicForm: userAddressBasicForm,
        EditableAddresses: userEditableAddresses,
        AddressOptionList: userAddressOptionList,
        ActiveAddress: userActiveAddress,
        IsControlledCountry: userIsControlledCountry,
        HasFavouriteAddress: userHasFavouriteAddress,
        IsFavouriteAddress: userIsFavouriteAddress,
        NeedsAddress: userNeedsAddress,
        AddressCircularProgress: userAddressCircularProgress,
        AddressOperationType: userAddressOperationType,
        ActionCircularProgress: userActionCircularProgress,
      }, [EnumPersonType.Enterprise]: {
        AddressForm: enterpriseAddressForm,
        AddressEditableForm: enterpriseAddressEditableForm,
        AddressEditableFormOk: enterpriseAddressEditableFormOk,
        AddressBasicForm: enterpriseAddressBasicForm,
        EditableAddresses: enterpriseEditableAddresses,
        AddressOptionList: enterpriseAddressOptionList,
        ActiveAddress: enterpriseActiveAddress,
        IsControlledCountry: enterpriseIsControlledCountry,
        HasFavouriteAddress: enterpriseHasFavouriteAddress,
        IsFavouriteAddress: enterpriseIsFavouriteAddress,
        NeedsAddress: enterpriseNeedsAddress,
        AddressCircularProgress: enterpriseAddressCircularProgress,
        AddressOperationType: enterpriseAddressOperationType,
        ActionCircularProgress: enterpriseActionCircularProgress,
      }
    };
    return personType ? auxObj[personType] : auxObj;
  };

  const handleAddressChange = (
    auxAddressForm,
    auxEditableAddress,
    setFavoutireFunction,
    setControlledCountryFunction
  ) => {
    fillAddressEditableModelNew(auxAddressForm, auxEditableAddress);
    setFavoutireFunction(
      auxEditableAddress ? auxEditableAddress.favourite : false
    );
    ManageAddressFirstIteration(
      auxAddressForm,
      setAll,
      null,
      null,
      setControlledCountryFunction,
      finishOperation,
      t
    );
  };

  const selectGenerator = (attribute, isForAddress) => {
    let auxFunctions = {
      SaveGroupsChanges: saveGroupsChanges,
      FinishOperation: finishOperation,
      ManageMultiValueDropDowns: manageMultiValueDropDowns
    };
    let auxControlledCountry = {
      [EnumPersonType.User]: {
        setFunction: setUserIsControlledCountry,
        value: userIsControlledCountry
      },
      [EnumPersonType.Enterprise]: {
        setFunction: setEnterpriseIsControlledCountry,
        value: enterpriseIsControlledCountry
      },
    };
    let auxUserEditableObjects = { EditablePhones: userEditablePhones, EditableDocuments: userEditableDocuments };
    let auxConstObject = {countryList:countryList, roadType:roadType, auxiliarTab:auxiliarTab};
    return getRequiredInput(attribute, isForAddress, auxFunctions, auxControlledCountry, auxUserEditableObjects, auxConstObject);
  };

  const manageMultiValueDropDowns = (isUser, attribute, isForAddress, auxKey, auxFieldSize) => {
    let auxInfo = {
      isUser,
      attribute,
      isForAddress,
      auxKey,
      auxFieldSize,
      countryList,
      roadType,
      idEnterprise,
      isRegistry,
      auxiliarTab,
    };
    let auxFunctions = {
      setResetPage: setResetPage,
      resetPage,
      finishOperation,
      doTraduction: t,
      setAll,
      saveGroupsChanges,
      manageMultiValueDropDowns,
      setIsNewAddress,
    };
    return generateMultiValueDropDown(
      { auxInfo: auxInfo, auxFunctions: auxFunctions },
      getBothFormValuesObject,
      getBothAddressSettersObject,
      getBothAddressValuesObject,
      getUserFormSetersObject,
      getUserFormValuesObject,
      getBothFormSettersObject,
    );
  };

  const saveGroupsChanges = (attribute, idValue, first, isSelect) => {
    let auxValue = checkIsNullUndefined(idValue) ? "" : idValue;
    let isUserData = attribute.dataType === "user";
    if (attribute.codeGroup === groupAttributes.userData || attribute.codeGroup === groupAttributes.enterpriseData) {
      let auxForm = isUserData
        ? JSON.parse(JSON.stringify(userDataForm))
        : JSON.parse(JSON.stringify(enterpriseDataForm));
      let auxAttribute = obtainAttribute(auxForm, attribute.codeAttribute);
      auxAttribute.value = auxValue;
      if (attribute.codeAttribute === AttributeCodes.Phone) {
        managePhones(auxForm, auxValue, getUserFormValuesObject);
      } else if (attribute.codeAttribute === AttributeCodes.DocType) {
        manageDocuments(auxForm, auxValue, getUserFormValuesObject);
      };
      let auxSetDataForm = isUserData ? setUserDataForm : setEnterpriseDataForm;
      auxSetDataForm(auxForm);
    } else if (attribute.codeGroup === groupAttributes.userAddress || attribute.codeGroup === groupAttributes.enterpriseAddress) {
      let auxAddressSettersObject = getBothAddressSettersObject();
      let auxAddressValuesObject = getBothAddressValuesObject();
      // if (isSelect) {
      //   changeAddressSelected(isUserData, auxValue, auxAddressSettersObject, auxAddressValuesObject);
      // } else {
      let auxFunctions = {
        setAll,
        finishOperation,
        doTraduction: t,
      };
      changeAddressParam(isUserData, attribute, auxValue, first, auxAddressSettersObject, auxAddressValuesObject, auxFunctions);
      // };
    };
  };

  const handleAddAddress = (isFavourite, personType) => {
    let auxInfo = {
      idEnterprise,
    };
    let auxFunctions = {
      finishOperation,
      setIsNewAddress,
      doTraduction: t,
    };
    let auxAddressSettersObject = getBothAddressSettersObject();
    let auxAddressValuesObject = getBothAddressValuesObject();
    handleAuxAddAddress(isFavourite, personType, auxAddressSettersObject, auxFunctions, auxAddressValuesObject, auxInfo);
  };

  const handleChangeAddress = (isFavourite, personType) => {
    let auxFunctions = {
      finishOperation,
      doTraduction: t,
    };
    let auxInfo = {
      idEnterprise,
    };
    let auxAddressSettersObject = getBothAddressSettersObject();
    let auxAddressValuesObject = getBothAddressValuesObject();
    handleAuxChangeAddress(isFavourite, personType, auxAddressSettersObject, auxAddressValuesObject, auxFunctions, auxInfo);
  };

  const handleRemoveAddress = (personType) => {
    let auxFunctions = {
      setResetPage,
      resetPage,
      finishOperation,
    };
    let auxAddressSettersObject = getBothAddressSettersObject();
    let auxAddressValuesObject = getBothAddressValuesObject();
    handleAuxRemoveAddress(personType, auxAddressSettersObject, auxAddressValuesObject, auxFunctions);
  };

  const handleSave = () => {
    let auxGetters = {
      getBothFormValuesObject,
      getUserFormValuesObject,
      getBothFormSettersObject,
      getUserFormSetersObject,
      getBothAddressSettersObject,
      getBothAddressValuesObject
    };
    let auxFunctions = {
      setNextCircularProgress,
      setUpdateUser,
      finishOperation,
      doTraduction: t,
      setNeedVerifyDocPopup,
      setOpenDialog,
      setResetPage,
      setIdEnterprise,
      setEnterpriseOriginalData,
      setUpdateEnterprises,
      history,
      setOpenNoHostDialog,
      setOpenACMDialog,
    };
    let auxConst = {
      forUser,
      isRegistry,
      isPF,
      i18n,
      forEnterprise,
      mainPage,
      pendingDataPage,
      resetPage,
      idEnterprise,
      isTitular,
      subscriptionPage,
    };
    let dataControl = {
      pendingData,
      setShowPendingData,
    };
    manageSavePath(auxGetters, auxFunctions, auxConst, dataControl);
  };

  const handleCancel = () => {
    if (!isRegistry) {
      let auxFlag = !resetPage;
      setResetPage(auxFlag);
    } else {
      setOpenExitDialog(true);
    };
  };

  const callCheckAllOk = () => {
    let auxFormVariables = getFormVariables();
    let mandatorySetObject = { setUserMandatoryData, setEnterpriseMandatoryData };
    let forAndFormObject = { forUser, userDataForm, forEnterprise, enterpriseDataForm };
    let gettersObject = { getUserFormValuesObject, getBothAddressValuesObject, getBothFormValuesObject };
    let auxInfo = { pendingDataPage, isTitular, idEnterprise, doTraduction: t };
    checkAllOk(forAndFormObject, mandatorySetObject, gettersObject, auxInfo, setPendingData, auxFormVariables);
  };

  const setAll = (auxAddressForm, isSelect, isEdit) => {
    let isUser = !checkIsNullUndefined(obtainAttribute(auxAddressForm, "ALI"));
    let auxiliarAddressForm = disableAttr(auxAddressForm, true);
    let auxiliarAddressEditableForm = disableAttr(auxAddressForm, false);
    if (isSelect && !isEdit) {
      if (isUser) {
        setUserAddressForm(auxiliarAddressForm);
      } else {
        setEnterpriseAddressForm(auxiliarAddressForm);
      };
    };
    if (isUser) {
      setUserAddressEditableForm(auxiliarAddressEditableForm);
      setUserAddressEditableFormOk(
        checkEditableDirOkNew(
          auxiliarAddressEditableForm,
          getAddresses(userEditableAddresses),
          userAddressOperationType
        )
      );
      setUserAddressCircularProgress(false);
      setUserActionCircularProgress(false);
    } else {
      setEnterpriseAddressEditableForm(auxiliarAddressEditableForm);
      setEnterpriseAddressEditableFormOk(
        checkEditableDirOkNew(
          auxiliarAddressEditableForm,
          getAddresses(enterpriseEditableAddresses),
          enterpriseAddressOperationType
        )
      );
      setEnterpriseAddressCircularProgress(false);
      setEnterpriseActionCircularProgress(false);
    };
  };

  const prepareBasicAddressForm = (isUser) => {
    let personType = isUser ? EnumPersonType.User : EnumPersonType.Enterprise;
    let auxAddressSettersObject = getBothAddressSettersObject();
    let auxAddressValuesObject = getBothAddressValuesObject();
    prepareAuxBasicAddressForm(isUser, auxAddressValuesObject, auxAddressSettersObject, personType);
  };

  const prepareEditableAddressForm = (isUser, addressId) => {
    let auxAddressSettersObject = getBothAddressSettersObject();
    let auxAddressValuesObject = getBothAddressValuesObject();
    changeAddressSelected(isUser, addressId, auxAddressSettersObject, auxAddressValuesObject);
    prepareAuxEditableAddressForm(isUser, auxAddressValuesObject, setAll);
  };

  const handleIsControlledCountry = (isUser) => {
    let personType = isUser ? EnumPersonType.User : EnumPersonType.Enterprise;
    let auxAddressSettersObject = getBothAddressSettersObject();
    let auxAddressValuesObject = getBothAddressValuesObject();
    handleAuxIsControlledCountry(personType, auxAddressValuesObject, auxAddressSettersObject);
  };

  const getButtons = (auxTab, auxText, personType) => {
    let formVariables = getFormVariables(personType);
    let auxCompInfo = {
      auxTab: auxTab,
      auxText: auxText,
      auxPeronType: personType
    };
    return mountButtons(
      auxCompInfo,
      formVariables.auxCompConst,
      formVariables.auxRgpdsInfo,
      formVariables.auxFunctions,
      formVariables.auxCompVariab,
    );
  };

  const getFormBlock = (personType) => {
    let formVariables = getFormVariables(personType);
    let auxAddresses = getBothAddressValuesObject(personType);
    let auxAddressesFunctions = getBothAddressSettersObject(personType);
    return mountFormBlock(
      formVariables.auxInfoGetters,
      formVariables.auxFunctions,
      formVariables.auxCompConst,
      formVariables.auxCompVariab,
      auxAddresses.EditableAddresses,
      auxAddressesFunctions.SetActiveAddress
    );
  };

  const getFormVariables = (personType) => {
    let auxInfoGetters = {
      getBothFormValuesObject,
      getBothAddressValuesObject,
      getBothAddressSettersObject,
      getBothFormSettersObject
    };
    let auxFunctions = {
      finishOperation,
      callCheckAllOk,
      selectGenerator,
      prepareBasicAddressForm,
      prepareEditableAddressForm,
      handleAddAddress,
      handleChangeAddress,
      handleRemoveAddress,
      handleIsControlledCountry,
      handleCancel,
      handleSave,
      doTraduction: t,
      getButtons
    };
    let auxRgpdsInfo = {
      setAgreeTyCs,
      agreeConditions,
      agreeTyCs
    };
    let auxCompConst = {
      commonClasses,
      onlyMandatories,
      subscriptionPage,
      pendingDataPage,
      isRegistry,
      forEnterprise,
      isTitular,
      nextCircularProgress,
    };
    let auxCompVariab = {
      personType,
      auxiliarTab,
      pendingData,
      showPendingData,
    };
    return {
      auxInfoGetters,
      auxFunctions,
      auxRgpdsInfo,
      auxCompConst,
      auxCompVariab
    }
  };

  return (
    <Grid className={
      registerPage ? commonClasses.registerContainer :
        subscriptionPage || pendingDataPage ? commonClasses.subscriptionPaper : commonClasses.mainContainer
    }>
      <Container style={registerPage ? { padding: "inherit" } : {}} className={registerPage ? commonClasses.registerSubContainer : (subscriptionPage || pendingDataPage) ? commonClasses.subscriptionPaper : commonClasses.mainContainer}>
        {mainPage ?
          <Grid>
            <Typography variant="h4" className={commonClasses.title}>
              {!isCreate ? t("UserData") : t("mainPageCreateEnterprise")}
            </Typography>
            <Typography
              variant="subtitle2"
              className={commonClasses.title}
              style={{ textAlign: "center", marginBottom: "2em", color: "#666666", fontSize: "120%" }}
            >
              {forUser ? t("userDataPageSubtitle") : forEnterprise ? isCreate ? t("enterpriseDataCreatePageSubtitle") : t("enterpriseDataPageSubtitle") : null}
            </Typography>
          </Grid>
          : null}
        {pageCircularProgress ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={registerPage ? 1 : 2} style={{ width: "100%", margin: registerPage ? null : "auto" }}>
            <DialogMissingData
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              missingData={missingData}
              finishOperation={finishOperation}
              enterpriseId={idEnterprise}
              needVerifyDocPopup={needVerifyDocPopup}
              isTitular={isTitular}
            />
            <BasicDialog
              open={openEntryDialog}
              setOpen={setOpenEntryDialog}
              title={t("userDataRegister")}
              text={manageEntryDialog(t)}
              action={() => { }}
              justInfo={true}
            />
            <BasicDialog
              open={openExitDialog}
              setOpen={setOpenExitDialog}
              title={manageExitDialogTitle(t)}
              text={manageExitDialog(t)}
              action={backToLogin}
            />
            <BasicDialog
              open={openNoHostDialog}
              setOpen={setOpenNoHostDialog}
              title={t("chooseEnterpriseNoDataTitle")}
              text={t("chooseEnterpriseNoDataText")}
              action={() => { }}
              closeAction={backToLogin}
              justInfo={true}
            />
            <BasicDialog
              open={openACMDialog}
              setOpen={setOpenACMDialog}
              title={t("generalACMTitle")}
              text={getSessionItem(sessionValues.isNoToken) == "true" ? t("generalACMTextGo") : t("generalACMTextNoGo")}
              action={() => goToLoginOauth(finishOperation, t)}
              closeAction={() => goToLoginOauth(finishOperation, t)}
              justInfo={true}
            />
            {registerPage ? (
              userDataForm.map((attr) => {
                return selectGenerator(attr);
              })) : null}
            {forUser && !registerPage ? (
              getFormBlock(EnumPersonType.User)
            ) : null}
            {registerPage && forEnterprise ? (
              enterpriseDataForm.map((attr) => {
                return selectGenerator(attr);
              })) : null}
            {forEnterprise && !registerPage ? (
              getFormBlock(EnumPersonType.Enterprise)
            ) : null}
            {((forUser && forEnterprise) || subscriptionPage || pendingDataPage) && (checkIsNullUndefined(registerPage) || !registerPage) ? (
              getButtons(0, t("save"), 2)
            ) : null}
          </Grid>
        )}
      </Container>
    </Grid>
  );
};