import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { checkIsNullUndefined } from "../../commons/Utils";
import { useTranslation } from "react-i18next";
import { CommonStyles } from "../../commons/CommonStyles";
import { validateName } from "../../commons/Utils";

export function TextBoxAttribute(props) {
  const { t, i18n } = useTranslation();
  const { attribute, saveGroupsChanges, addressAliasList } = props;
  const commonClasses = CommonStyles();

  const [value, setValue] = useState("");
  const [mount, setMount] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    if (!mount) {
      setValue(attribute.value);
      setMount(true);
    } else {
      if (attribute.value !== value) {
        setValue(checkIsNullUndefined(attribute.value) ? "" : attribute.value);
      }
    }
  }, [attribute, value]);

  //guarda los datos.
  const handleInputChange = (event) => {
    let auxValue = event.target.value;
    let error = false;
    let errorText = "";
    if (attribute.mandatory) {
      if (checkIsNullUndefined(auxValue)) {
        error = true;
        errorText = t("required");
      }
    }
    if (
      (!error && attribute.codeAttribute == "ALI") ||
      attribute.codeAttribute == "EAL"
    ) {
      if (addressAliasList.indexOf(auxValue) != -1) {
        error = true;
        errorText = t("userDataAliasWarning");
      }
    }
    if (
      (!error && attribute.codeAttribute == "NOM") ||
      attribute.codeAttribute == "SUR" || attribute.codeAttribute == "SU2"
    ) {
      if (!checkIsNullUndefined(auxValue) && !validateName(auxValue)) {
        error = true;
        errorText = t("userFormatIncorrect");
      }
    }
    setValue(auxValue);
    setError(error);
    setErrorText(errorText);
    saveAttributesChanges(auxValue);
  };

  //guarda los cambios en los attributos de datos personales
  const saveAttributesChanges = (nValue) => {
    saveGroupsChanges(attribute, nValue);
  };

  return (
    <Grid item md={12} xs={12}>
      <TextField
        value={checkIsNullUndefined(value) ? "" : value}
        variant="filled"
        fullWidth
        name="textbox"
        color="secondary"
        disabled={attribute.disabled}
        label={attribute.nameAttritube}
        required={attribute.mandatory}
        onChange={handleInputChange}
        error={error}
        helperText={errorText}
        className={commonClasses.inputsStyle}
      />
    </Grid>
  );
}
