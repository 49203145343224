import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import ico_pass_on from "../../assets/images/ico_pass_on.svg";
import ico_pass_off from "../../assets/images/ico_pass_off.svg";
import { CommonStyles } from "../../commons/CommonStyles";
import { RegisterDataStyles } from "./RegisterDataStyles";
import { CorreosButton } from "../../components/CorreosButton/CorreosButton";
import { OffersAndProm } from "./Components/OffersAndProm";
import { checkIsNullUndefined, finishGlobalOperation, getErrorMessage, getScreenWidth, validatePassword, validatePhone } from "../../commons/Utils";
import {
  PostUtilitiesCorreosIdVerifyRecaptcha,
} from "../../services/UtilitiesCallsService";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
// import { RequiredData } from "../RequiredData/RequiredData";
import { EnumCountries } from "../../commons/EnumCountries";
import { AgreeConditionsLabel } from "../CommonComponents/AgreeConditionsLabel";
import history from "../../services/history";
import { getEntranceUrl, getSessionItem } from "../../services/Commons";
import { EnumPages } from "../../commons/EnumPages";
import { PendingDataWarning } from "../CommonComponents/PendingDataWarning";
import { sessionValues } from "../../commons/EnumsGeneral";
import { GetApplicationAuxCidRegistryData, PostApplicationAuxPreregister } from "../../services/ApplicationAuxCallsService";
import { Constants } from "../../commons/Constants";

export function RegisterData(props) {
  const commonClasses = CommonStyles();
  const classes = RegisterDataStyles();
  const { t, i18n } = useTranslation();
  const {
    changeStep,
    saveData,
    setIsPF,
    setAlert,
    // setEnterpriseMandatoryData,
    // enterpriseMandatoryData,
    personType
  } = props;
  const validator = require("react-email-validator");
  const isInvitation = !checkIsNullUndefined(getSessionItem(sessionValues.idInvitation));

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [checkPassword, setCheckPassword] = useState("");
  const [checkPasswordError, setCheckPasswordError] = useState(false);
  const [showCheckPassword, setShowCheckPassword] = useState(false);
  const [tipoPersona, setTipoPersona] = useState([]);
  const [tipoPersonaValue, setTipoPersonaValue] = useState(null);
  const [agreeConditions, setAgreeConditions] = useState(false);
  const [offersList, setOffersList] = useState([]);
  const [regPendingData, setRegPendingData] = useState([]);
  const [showRegPendingData, setShowRegPendingData] = useState(false);
  const [circularProgress, setCircularProgress] = useState(true);
  const [reCaptchaToken, setReCaptchaToken] = useState(null);
  const [reCaptchaError, setReCaptchaError] = useState("");
  const [reCaptchaRef, setReCaptchaRef] = useState(null);

  useEffect(() => {
    let auxiliarData = {};
    let auxAttributeInfo = getSessionItem(sessionValues.attributeInfo);
    let auxAttributes = getSessionItem(sessionValues.attributes);
    let auxFieldType = getSessionItem(sessionValues.fieldType);
    let auxUserConfig = getSessionItem(sessionValues.userConfiguration);
    let auxEnterpriseConfig = getSessionItem(sessionValues.enterpriseConfiguration);
    if (checkIsNullUndefined(auxAttributeInfo) ||
      checkIsNullUndefined(auxAttributes) ||
      checkIsNullUndefined(auxFieldType) ||
      checkIsNullUndefined(auxUserConfig) ||
      checkIsNullUndefined(auxEnterpriseConfig)) {
      GetApplicationAuxCidRegistryData(Constants.cidCode).then((response) => {
        if (response && !response.Status) {
          let auxAttributeInfo = response.attributeInfo;
          let auxAttributes = response.attribute;
          let auxFieldType = response.fieldType;
          let auxUserConfig = response.userConfig;
          let auxEnterpriseConfig = response.enterpriseConfig;
          sessionStorage.setItem(sessionValues.attributeInfo, JSON.stringify(auxAttributeInfo));
          sessionStorage.setItem(sessionValues.attributes, JSON.stringify(auxAttributes));
          sessionStorage.setItem(sessionValues.fieldType, JSON.stringify(auxFieldType));
          sessionStorage.setItem(sessionValues.userConfiguration, JSON.stringify(auxUserConfig));
          sessionStorage.setItem(sessionValues.enterpriseConfiguration, JSON.stringify(auxEnterpriseConfig));
          preparePage(auxAttributeInfo, auxiliarData);
        } else {
          finishOperation("error", getErrorMessage(response, t));
        }
      });
    } else {
      let auxAttributeInfo = JSON.parse(sessionStorage.getItem(sessionValues.attributeInfo));
      preparePage(auxAttributeInfo, auxiliarData);
    };
  }, []);

  const preparePage = (auxAttributeInfo, auxiliarData) => {
    let auxPersonType = [];
    if (personType == 3) {
      auxPersonType.push({ id: 1, text: t("registerPagePersonType1") });
      auxPersonType.push({ id: 2, text: t("registerPagePersonType2") });
    } else {
      auxPersonType.push({
        id: personType,
        text: t("registerPagePersonType" + personType),
      });
    }
    auxiliarData.tipoPersona = auxPersonType;
    auxiliarData.emailMust = auxAttributeInfo.email?.mustRegistry;
    auxiliarData.phoneMust = auxAttributeInfo.phone?.mustRegistry;
    finishMount(auxiliarData);
  };

  const finishMount = (auxiliarData) => {
    setIsPF(auxiliarData.tipoPersona[0].id == 1);
    setTipoPersona(auxiliarData.tipoPersona);
    setTipoPersonaValue(auxiliarData.tipoPersona[0].id);
    setCircularProgress(false);
  };

  useEffect(() => {
    isAllOk();
  }, [email, password, checkPassword, reCaptchaToken, agreeConditions]);

  const handleNext = () => {
    if (regPendingData.length > 0) {
      setShowRegPendingData(true);
    } else {
      let userRgpd = offersList;
      let data = {
        personType: tipoPersonaValue == "1" ? "PF" : "PJ",
        password: password,
        password2: checkPassword,
        userRgpd,
        userEmail: { email: email, otp: null },
        userPhone: {
          country: {
            code: EnumCountries.ESP,
            id: 67,
            phonePrefix: 34,
          },
          phone: null,
          otp: null,
        },
      };
      saveData(data);
      if (process?.env.REACT_APP_ENVELOP_ACTIVE_PRE_REGISTRY === "true") {
        PostApplicationAuxPreregister(email).then((response) => {
          if (response && !response.Status) {
            changeStep(1);
          } else {
            finishOperation("error", getErrorMessage(response, t));
          };
        });
      } else {
        changeStep(1);
      };
    };
  };

  const handleOffersSelected = (auxOffersList) => {
    let auxiliarOfefersList = auxOffersList.map((elem) => {
      return { idRgpd: parseInt(elem.id, 10), accepted: elem.checked };
    });
    setOffersList(auxiliarOfefersList);
  };

  const handleChangeEmail = (value) => {
    let okEmail = true;
    if (!checkIsNullUndefined(value.target.value)) {
      okEmail = validator.validate(value.target.value);
    };
    setEmailError(!okEmail);
    setEmailErrorText(t("registerPageMailError"));
    setEmail(value.target.value);
  };

  const handleChangePassword = (value) => {
    let okPass = validatePassword(value.target.value);
    let okCheckPass = true;
    if (value.target.value === "") okPass = true;
    if (checkPassword !== "" && checkPassword === value.target.value) {
      okCheckPass = false;
    };
    setPasswordError(!okPass);
    if (checkPassword !== "") {
      setCheckPasswordError(okCheckPass);
    };
    setPassword(value.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeCheckPassword = (value) => {
    let flag = false;
    if (password !== "" && password !== value.target.value) flag = true;
    setCheckPasswordError(flag);
    setCheckPassword(value.target.value);
  };

  const handleClickShowCheckPassword = () => {
    setShowCheckPassword(!showCheckPassword);
  };

  const handleMouseDownCheckPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeAgreeConditions = (flag) => {
    setAgreeConditions(flag);
  };

  const isAllOk = () => {
    // let auxUserPendingData = !checkIsNullUndefined(userMandatoryData) ? userMandatoryData.pendingData : [];
    // let auxEnterprisePendingData = !checkIsNullUndefined(enterpriseMandatoryData) ? enterpriseMandatoryData.pendingData : [];
    // if (tipoPersonaValue === "1") {
    //   auxEnterprisePendingData = [];
    // };
    // let auxPendingData = [...auxEnterprisePendingData];
    // let auxRegPendingData = JSON.parse(JSON.stringify(auxPendingData));
    let auxRegPendingData = [];
    //El registro actual es sólo por email
    if (checkIsNullUndefined(email) || emailError) {
      auxRegPendingData.push(t("UserEmail"));
    };
    if (checkIsNullUndefined(password) || passwordError) {
      auxRegPendingData.push(t("correosPasswordLabel"));
    };
    if (checkIsNullUndefined(checkPassword) || checkPasswordError) {
      auxRegPendingData.push(t("correosCheckPasswordLabel"));
    };
    if (!agreeConditions) {
      auxRegPendingData.push(t("registerPagePoliticsAndConditions"));
    };
    if (process?.env?.REACT_APP_ENVELOP_ACTIVE_CAPTCHA == "true" && checkIsNullUndefined(reCaptchaToken)) {
      auxRegPendingData.push(t("registerPageRecaptcha"));
    };
    setRegPendingData(auxRegPendingData);
  };

  const handleChangePersonType = (value) => {
    setTipoPersonaValue(value.target.value);
    setIsPF(value.target.value === "1");
  };

  const finishOperation = (color, message, auxOpen) => {
    finishGlobalOperation(setAlert, color, message, auxOpen);
    setCircularProgress(false);
  };

  const goToLogin = () => {
    history.push(getEntranceUrl(EnumPages.Login));
  };

  const handleOnChangeReCaptcha = (value) => {
    setReCaptchaToken(null);

    if (!checkIsNullUndefined(value)) {
      setReCaptchaError("");

      PostUtilitiesCorreosIdVerifyRecaptcha(value).then((response) => {
        if (response && !response.Status) {
          setReCaptchaToken(value);
        } else {
          reCaptchaRef.reset();
          setReCaptchaError(getErrorMessage(response, t));
        }
      });
    }
  };

  return (
    <Grid>
      {circularProgress ? (
        <CircularProgress className={commonClasses.circularProgress} />
      ) : (
        <Grid style={{ paddingLeft: "26px" }}>
          <Grid
            container
            item
            spacing={1}
            style={{ marginTop: "20px" }}
            xs={12}
          >
            <Grid item xs={12}>
              <Grid container spacing={1} style={{ width: "100%" }}>
                <Grid item md={12} xs={12}>
                  <TextField
                    color="secondary"
                    value={email}
                    onInput={handleChangeEmail}
                    variant="filled"
                    fullWidth
                    name="email"
                    autoFocus
                    label={t("registerPageMail")}
                    type="text"
                    id="email"
                    error={emailError}
                    helperText={emailError ? emailErrorText : ""}
                    required
                    className={commonClasses.inputsStyle}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    color="secondary"
                    value={password}
                    onInput={handleChangePassword}
                    variant="filled"
                    fullWidth
                    name="password"
                    label={t("registerPagePassword")}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    error={passwordError}
                    style={{ marginRight: "1px" }}
                    required
                    InputProps={{
                      className: classes.underline,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <img src={ico_pass_on} alt="pass_on" />
                            ) : (
                              <img src={ico_pass_off} alt="pass_off" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={commonClasses.inputsStyle}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    color="secondary"
                    value={checkPassword}
                    onInput={handleChangeCheckPassword}
                    variant="filled"
                    fullWidth
                    name="checkPassword"
                    label={t("registerPageCheckPassword")}
                    type={showCheckPassword ? "text" : "password"}
                    id="checkPassword"
                    error={checkPasswordError}
                    required
                    InputProps={{
                      className: classes.underline,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCheckPassword}
                            onMouseDown={handleMouseDownCheckPassword}
                          >
                            {showCheckPassword ? (
                              <img src={ico_pass_on} alt="pass_on" />
                            ) : (
                              <img src={ico_pass_off} alt="pass_off" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={commonClasses.inputsStyle}
                  />
                </Grid>
                {passwordError || checkPasswordError ? (
                  <Grid item sm={12} xs={12} className={commonClasses.gridIdentity}>
                    <Typography className={classes.errorText}>
                      {passwordError
                        ? t("registerPagePasswordError")
                        : t("registerPageCheckPasswordError")}
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} style={{ width: "100%" }}>
                <Grid
                  container
                  justifyContent="center"
                  item
                  xs={12}
                  className={commonClasses.gridIdentity}
                >
                  <FormControl variant="filled" fullWidth color="secondary" className={commonClasses.inputsStyle}>
                    <InputLabel htmlFor="tipoPersona" id="tipoPersona-label">
                      {t("registerPageTipoPersona")}
                    </InputLabel>
                    <Select
                      native
                      labelId="tipoPersona-label"
                      id="tipoPersona"
                      label={t("registerPageTipoPersona")}
                      value={tipoPersonaValue}
                      onChange={handleChangePersonType}
                      disabled={isInvitation}
                    >
                      {tipoPersona.map((tipo) => {
                        if (isInvitation) {
                          return (
                            <option key={1} value={1}>
                              {t("registerPagePersonType1")}
                            </option>
                          )
                        } else {
                          return (
                            <option key={tipo.id} value={tipo.id}>
                              {tipo.text}
                            </option>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {/* {tipoPersonaValue == "2" && !isInvitation ? <Grid item xs={12}>
              <RequiredData
                isRegistry={false}
                forUser={false}
                forEnterprise={true}
                onlyMandatories={true}
                registerPage={true}
                // setEnterpriseMandatoryData={setEnterpriseMandatoryData}
                finishOperation={finishOperation}
              />
            </Grid> : null} */}

            <Divider style={{ width: "100%", marginTop: "1.25em", marginBottom: "0.5em" }}></Divider>
            <Grid container item xs={12} className={commonClasses.gridIdentity} style={{ marginTop: "1em", marginRight: "0.5em" }}>
              <AgreeConditionsLabel
                appName=""
                setOk={handleChangeAgreeConditions}
                finishOperation={finishOperation}
                personType={tipoPersonaValue}
                applicationOid={process?.env?.REACT_APP_APP_OID}
                separatedChecks={true}
                registerPage={true}
              />
            </Grid>
            {showRegPendingData && regPendingData.length > 0 ? (
              <PendingDataWarning pendingData={regPendingData} />
            ) : null}
            <Divider style={{ width: "100%", marginTop: "1em", marginBottom: "0.5em" }}></Divider>
            <Grid item xs={12} className={commonClasses.gridIdentityCenter} style={{ marginTop: "0.5em" }}>
              <OffersAndProm
                launchAlert={finishOperation}
                handleOffersSelected={handleOffersSelected}
              />
            </Grid>
          </Grid>
          {process?.env?.REACT_APP_ENVELOP_ACTIVE_CAPTCHA == "true" ? (
            <Grid
              item
              container
              justifyContent="center"
              xs={12}
              className={commonClasses.gridIdentity}
              style={{ marginBottom: "1em" }}
            >
              <Grid style={{ display: 'inline-block', marginTop: '2em' }}>
                <ReCAPTCHA
                  ref={(r) => setReCaptchaRef(r)}
                  sitekey={process?.env?.REACT_APP_RECAPTCHA_KEY}
                  onChange={handleOnChangeReCaptcha}
                  size={getScreenWidth() > 440 ? "normal" : "compact"} />

                {checkIsNullUndefined(reCaptchaError) ? null : (
                  <p style={{ color: "#f32735", fontSize: "80% !important", textAlign: "start" }}>{reCaptchaError}</p>
                )}
              </Grid>
            </Grid>
          ) : null}

          <Grid
            item
            xs={12}
            className={commonClasses.gridIdentity}
            style={{ marginTop: "2em", marginRight: "1em" }}
          >
            <Grid item sm={6} xs={12}>
              <CorreosButton style={{ float: "left" }} onClick={goToLogin}>
                {t("cancel")}
              </CorreosButton>
            </Grid>
            <Grid item sm={6} xs={12}>
              <CorreosButton
                variant="contained"
                color="primary"
                onClick={handleNext}
                style={{ float: "right" }}
                disabled={regPendingData.length > 3}
              >
                {t("next")}
              </CorreosButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
