import React from "react";

export function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`attributes-tabpanel-${index}`}
            aria-labelledby={`attributes-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}