import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { StylesServiceList } from "./StylesServiceList";
import { ServiceCard } from "../ServiceCard/ServiceCard";
import { useTranslation } from "react-i18next";

export function ServiceList(props) {
  const {
    serviceList,
    idEnterprise,
    subscribedServices,
    isTitular,
    finishOperation,
    resetPageData,
    setUpdateEnterprises,
  } = props;
  const classes = StylesServiceList();
  const { t, i18n } = useTranslation();
  const [auxSubscribedServices, setAuxSubscribedServices] = useState([]);
  const [auxServicesList, setAuxServicesList] = useState([]);

  useEffect(() => {
    if (auxSubscribedServices !== subscribedServices) {
      let auxilServicesList = [];
      let auxSubscribedNamesList = subscribedServices.map((service) => service.application);
      serviceList.forEach((service) => {
        service.subscribed = false;
        service.state = t("unSubscribedService");
        if (auxSubscribedNamesList.includes(service.nameApplication)) {
          let auxService = subscribedServices.find((userService) => userService.application === service.nameApplication);
          if (auxService.idState === 1 || auxService.idState === 3) {
            service.state = auxService.state;
            service.permission = auxService.permissionData;
            service.subscribed = true;
          };
        };
        auxilServicesList.push(service);
      });
      setAuxServicesList(auxilServicesList);
      setAuxSubscribedServices(subscribedServices);
    };
  }, [subscribedServices]);

  const filterList = (subscribed) => {
    let auxList = auxServicesList.filter((service) => service.subscribed === subscribed);
    return auxList;
  };

  return (
    <Grid container>
      <Grid
        container
        spacing={4}
        className={classes.root}
        alignContent="center"
        alignItems="center"
        style={{ marginBottom: "2em" }}
      >
        {filterList(true).map((service, index) => {
          return (
            <ServiceCard
              key={index + "ServiceCardOK"}
              service={service}
              isSubscribed={true}
              idEnterprise={idEnterprise}
              isTitular={isTitular}
              finishOperation={finishOperation}
              resetPageData={resetPageData}
              setUpdateEnterprises={setUpdateEnterprises}
            />
          );
        })}
        {filterList(false).map((service, index) => {
          return (
            <ServiceCard
              key={index + "ServiceCardKO"}
              service={service}
              isSubscribed={false}
              idEnterprise={idEnterprise}
              isTitular={isTitular}
              finishOperation={finishOperation}
              resetPageData={resetPageData}
              setUpdateEnterprises={setUpdateEnterprises}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}
