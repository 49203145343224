import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { SideMenuTyCStyles } from "./SideMenuTyCStyles";
import { EnumTyC } from "./EnumTyC";
import { useTranslation } from "react-i18next";

export function SideMenuTyC(props) {
  const { changeId, setShowSideMenu, idPage, showSideMenu } = props;
  const classes = SideMenuTyCStyles();
  const { t, i18n } = useTranslation();

  const handleGo = (id) => {
    changeId(id);
    setShowSideMenu();
  };
  const renderList = () => {
    return (
      <List>
        <ListItem
          button
          key={"TextoKey"}
          onClick={() => handleGo(EnumTyC.WarninLegal)}
          selected={idPage == EnumTyC.WarninLegal}
        >
          <ListItemText primary={t("warningLegal")} />
        </ListItem>
        <ListItem
          button
          key={"TextoKey2"}
          onClick={() => handleGo(EnumTyC.PrivacyPolicy)}
          selected={idPage == EnumTyC.PrivacyPolicy}
        >
          <ListItemText>
            <Typography>
            {t("privacyPolicy")}
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem
          button
          key={"TextoKey3"}
          onClick={() => handleGo(EnumTyC.Cookies)}
          selected={idPage == EnumTyC.Cookies}
        >
          <ListItemText primary={t("cookiesPolicy")} />
        </ListItem>
      </List>
    );
  };
  return (
    <Drawer
      className={showSideMenu ? classes.drawer : classes.hideDrawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar/>
        <div className={classes.drawerContainer} >{renderList()}</div>
      
    </Drawer>
  );
}
