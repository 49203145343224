import React, { useEffect } from "react";
import { Typography, Grid } from "@material-ui/core";
import { Attributes } from "../../../commons/Attributes";
import { CommonStyles } from "../../../commons/CommonStyles";
import { useTranslation } from "react-i18next";
import { DialogShowAddress } from "../../CommonComponents/Dialogs/DialogShowAddress";
import { DialogDeleteDirection } from "./DialogDeleteDirection";

export function AttributesVerifiedCard(props) {
  const {
    getIcon,
    documentNum,
    directions, 
    chargePageData,
    finishOperation,
    isTitular,
  } = props;
  const commonClasses = CommonStyles();
  const { t, i18n } = useTranslation(); 

  const buildListCard = (list, type) => {
    let isAddress = type === Attributes.PersonaDireccion;
    return (
      <Grid
        item
        container
        spacing={1}
        md={12}
        className={commonClasses.listStyle}
      >
        <Grid item xs={1} className={commonClasses.listItemStyle}>
          {getIcon(type)}
        </Grid>
        <Grid item xs={11} className={commonClasses.listItemStyle}>
          <Typography variant="h6">{t("verifications" + type)}</Typography>
        </Grid>
        {list.length > 0 ? (
          list.map((verificable) => {
            return (
              <Grid key={verificable.value} item container md={12} xs={12}>
                <Grid item md={1} xs={1}></Grid>
                <Grid item md={isAddress ? 9 : 11} xs={isAddress ? 9 : 11}>
                  <Typography
                    className={commonClasses.listTextStyleShow}
                    noWrap
                  >
                    {isAddress ? verificable.alias : verificable.value}
                  </Typography>
                </Grid>
                {isAddress ? (
                  <Grid item container xs={2}>
                    <Grid item xs={6} className={commonClasses.iconGridStyle}>
                      <DialogShowAddress address={verificable} />
                    </Grid>
                    {isTitular ? (
                      <Grid item xs={6}>
                        <DialogDeleteDirection
                          verificable={verificable}
                          chargePageData={chargePageData}
                          finishOperation={finishOperation}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                ) : null}
              </Grid>
            );
          })
        ) : (
          <Grid container item md={12} xs={12}>
            <Grid item md={1} xs={1}></Grid>
            <Grid item md={11} xs={11}>
              <Typography className={commonClasses.listTextStyleShow}>
                {t("verificationsEmpty")}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Grid
      container
      style={{ textAlign: "center" }}
      spacing={1}
      alignItems="flex-end"
    >
      {buildListCard(documentNum, Attributes.PersonaNumDocID)}
      {buildListCard(directions, Attributes.PersonaDireccion)}      
    </Grid>
  );
}
