import React, { useEffect, useState } from "react";
import { CorreosIdStepper } from "../../components/Stepper/Stepper";
import { EnumPages } from "../../commons/EnumPages";
import { BackgroundLogin } from "../../components/Templates/BackgroundLogin";
import { RequiredData } from "../../components/RequiredData/RequiredData";
import { Alerts } from "../../components/Alerts/Alerts";
import { PublicBackGroundPage } from "../../components/Templates/PublicBackGroundPage";
import { Grid, Paper } from "@material-ui/core";
import { CommonStyles } from "../../commons/CommonStyles";
import { finishGlobalOperation } from "../../commons/Utils";

export function SubscriptionPage() {
    const commonClasses = CommonStyles();
    const personType = sessionStorage.getItem("isPF") === "true" ? 1 : 2;
    const enterpriseId = personType == 1 ? null : sessionStorage.getItem("enterpriseId");
    const [alert, setAlert] = useState({
        open: false,
        color: "",
        message: "",
    });
    const [agreeConditions, setAgreeConditions] = useState(false);

    useEffect(() => { }, []);

    const finishOperation = (color, message, auxOpen) => {
        finishGlobalOperation(setAlert, color, message, auxOpen);
    };

    const handleChangeAgreeConditions = (flag) => {
        setAgreeConditions(flag);
    };

    return (
        <PublicBackGroundPage openPage={EnumPages.Registry}>
            <Alerts alert={alert} setAlert={setAlert}></Alerts>
            <CorreosIdStepper
                step={1}
                page={EnumPages.Registry}
                isPF={personType == 1}
            />
            <BackgroundLogin size="lg" subscription={true} finishOperation={finishOperation} handleChangeAgreeConditions={handleChangeAgreeConditions} agreeConditions={agreeConditions} personType={personType} step={2} registry={true}>
                <Grid className={commonClasses.subscriptionContainer}>
                    <Paper style={{ width: "100%" }}>
                        <RequiredData
                            isRegistry={true}
                            forUser={true}
                            forEnterprise={personType == 2}
                            onlyMandatories={true}
                            subscriptionPage={true}
                            idEnterprise={enterpriseId}
                            finishOperation={finishOperation}
                        />
                    </Paper>
                </Grid>
            </BackgroundLogin>
        </PublicBackGroundPage>
    );
}
