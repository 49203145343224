import {
  getAPI,
  getApplicationOid,
  backToLogin,
  getLanguage,
  getOauthApi,
  setIsRefreshing,
  getIsRefreshing,
  sleep,
  closeSessionToLogin,
  getSessionItem,
  setSessionItem,
} from "./Commons";
import { checkIsNullUndefined } from "../commons/Utils";
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import { fetchMethod, sessionValues } from "../commons/EnumsGeneral";
import { Constants } from "../commons/Constants";

const fetchApi = {
  PostCancelToken: "login/CancelToken",
  PostVerificationSendEmail: "Verification/Email/{email}/Send",
  PostVerificationSendPhone: "Verification/Phone/{phone}/Send",
  PostVerificationValidationOtpEmail: "Verification/ValidationOtpEmail",
  PostVerificationValidationOtpPhone: "Verification/ValidationOtpPhone",
  GetProfileUser: "ProfileUser",
  PutProfileUser: "ProfileUser",
  GetEnterpriseUserEnterprises: "Enterprise/UserEnterprises",
  PostVerificationDataEmail: "VerificationData/Email",
  PostVerificationDataPhone: "VerificationData/Phone",
  PostEnterprise: "Enterprise",
  GetSubscriptionUser: "Subscription/User",
  PostSubscriptionUser: "Subscription/User/{applicationOidToSubscribe}",
  PutSubscriptionUserUnsubscribe:
    "Subscription/User/Unsubscribe/{applicationOidToUnsubscribe}",
  PostSubscriptionEnterprise:
    "Subscription/Enterprise/{applicationOidToSubscribe}",
  GetEnterpriseSubscriptions: "Subscription/Enterprise",
  PutSubscriptionEnterpriseUnsubscribe:
    "Subscription/Enterprise/Unsubscribe/{applicationOidToUnSubscribe}/{idEnterprise}",
  PutSubscriptionUserUpdatePermissionData:
    "Subscription/User/UpdatePermissionData/{applicationOidToUpdate}",
  PutSubscriptionEnterpriseUpdatePermissionData:
    "Subscription/Enterprise/UpdatePermissionData/{applicationOidToUpdate}",
  GetUserCheckSingleSubscription: "Subscription/User/CheckSingleSubscription",
  GetEnterpriseCheckSingleSubscription: "Subscription/Enterprise/CheckSingleSubscription",
  DeleteVerificationUserEmail: "VerificationData/Email/{email}",
  DeleteVerificationUserPhone: "VerificationData/Phone/{phone}",
  PostInvitationInvitation: "Invitation/Invitation",
  PostInvitationInvitationAccept: "Invitation/Invitation/Accept/{idInvitation}",
  DeleteInvitationInvitation: "Invitation/Invitation/{idInvitation}",
  GetEnterpriseOwnDocuments: "EnterpriseDocuments",
  GetEnterpriseSelect: "Enterprise/Select/{idEnterprise}",
  PostPasswordCheckPassword: "Password/CheckPassword",
  PostRefreshToken: "Login/RefreshToken",
  PostAuthorizeAuthorize: "Authorize",
  PostAuthorizeToken: "Authorize/token",
  PostEnterpriseRoleCreateSystemUser: "EnterpriseRole/CreateSystemUser",
  DeleteEnterprise: "Enterprise/{idEnterprise}",
  DeleteVerificationDataAddress: "VerificationData/Address/{idUserAddress}",
  DeleteVerificationDataEnterpriseAddress:
    "VerificationData/EnterpriseAddress/{idEnterpriseAddress}",
  PostProfileUserCreateOrUpdateAddress: "ProfileUser/CreateOrUpdateAddress",
  PostProfileUserCreateOrUpdateDocument: "ProfileUser/CreateOrUpdateDocument",
  DeleteVerificationDataDocument:
    "VerificationData/Document/{IdTypeUserDocument}/{documentNumber}",
  GetSSO: "SSO",
  PostSSO: "SSO",
  GetProfileUserExtended: "ProfileUser/Extended",
  PostCallback: "Authorize/callback",
  
};

export const buildHeader = (accept, authorization, content, applicationOid) => {
  let header = new Headers();
  header.append(
    "ApplicationOid",
    checkIsNullUndefined(applicationOid) ? getApplicationOid() : applicationOid
  );
  header.append("Accept-Language", getLanguage());
  header.append("accept", accept);
  if (authorization) {
    header.append("Authorization", "Bearer " + getSessionItem(sessionValues.idToken));
  }
  if (content) {
    header.append("Content-Type", content);
  }
  return header;
};

const PostRefreshToken = async (token, refreshToken) => {
  let body = {
    idToken: token,
    refreshToken: refreshToken,
  };
  let header = buildHeader("*/*", false, "application/json");
  let apiUrl = fetchApi.PostRefreshToken;
  let response = await NewCommonFetch(header, body, fetchMethod.POST, apiUrl);
  return response;
};

const checkToken = async () => {
  let token = getSessionItem(sessionValues.idToken);
  let refreshToken = getSessionItem(sessionValues.refreshToken);
  let tokenDecoded = jwt_decode(token);
  let actualDate = Date.parse(new Date()) / 1000;
  let isOkToken = true;

  if (actualDate > tokenDecoded.exp) {
    setIsRefreshing(true);
    return await PostRefreshToken(token, refreshToken).then((response) => {
      if (!response || response.Status) {
        isOkToken = false;
      } else {
        setSessionItem(sessionValues.idToken, response.idToken);
        setSessionItem(sessionValues.refreshToken, response.refreshToken);
      }
      setIsRefreshing(false);
      return isOkToken;
    });
  } else {
    return isOkToken;
  }
};

//Para API 3.0
export const CommonFetch = async function CommonFetch(
  fetchHeader,
  fetchBody,
  method,
  apiUrl,
  isDoc,
  isOauth
) {
  let isRefreshing = getIsRefreshing();
  if (!checkIsNullUndefined(isRefreshing) && isRefreshing) {
    for (let i = 0; i < 100 && isRefreshing; i++) {
      isRefreshing = getIsRefreshing();
      await sleep(100);
    }
  }

  let okToken = true;

  if (!checkIsNullUndefined(getSessionItem(sessionValues.idToken))) {
    okToken = await checkToken();
  }
  if (okToken) {
    let response = await NewCommonFetch(
      fetchHeader,
      fetchBody,
      method,
      apiUrl,
      isDoc,
      isOauth
    );
    return response;
  } else {
    backToLogin();
  }
};

const NewCommonFetch = async function NewCommonFetch(
  fetchHeader,
  fetchBody,
  method,
  apiUrl,
  isDoc,
  isOauth
) {
  let miInit = {
    method: method,
    headers: fetchHeader,
    mode: "cors",
    cache: "default",
  };
  if (!checkIsNullUndefined(fetchBody)) {
    if (isDoc) {
      miInit.body = fetchBody;
    } else {
      miInit.body = JSON.stringify(fetchBody);
    }
  }
  let api = checkIsNullUndefined(isOauth) ? getAPI() : getOauthApi();
  return await fetch(api + apiUrl, miInit)
    .then((res) => {
      return res
        .json()
        .then((respuesta) => {
          //Status que devolvemos a las llamadas que no devuelven error
          if (
            res.status === 202 ||
            res.status === 203 ||
            res.status === 206 ||
            res.status === 209 ||
            res.status === 400
          ) {
            respuesta.Status = res.status;
          } else if (res.status === 403 && !checkIsNullUndefined(getSessionItem(sessionValues.idToken))) {
            closeSessionToLogin();
          } else if (res.status === 212) {
            respuesta = { response: respuesta, Status: 212 };
          };
          let sessionCookie = res.headers.get(Constants.cookieValue);
          if (!checkIsNullUndefined(sessionCookie)) {
            respuesta.sessionCookie = sessionCookie;
          };
          return respuesta;
        })
        .catch(() => {
          if (res.status === 404) {
            return null;
          } else if (res.status === 202) {
            return { Status: 202 };
          } else {
            return true;
          };
        });
    })
    .catch((error) => console.error("Error:", error))
    .then((response) => {
      return response;
    });
};

//Para API 3.0
export const LoginFetch = async (username, password) => {
  let body = { username: username, password: password };
  let header = buildHeader("*/*", false, "application/json");
  let apiUrl = fetchApi.Login;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return response;
};

//API 3.0 Petición para enviar OTP por email
export const PostVerificationSendEmail = async (email) => {
  let body = "";
  // let auxAuthorization = process.env.REACT_APP_ENVELOP_ACTIVE_PRE_REGISTRY == "true";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.PostVerificationSendEmail.replace("{email}", email);
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return response;
};

//API 3.0 Petición para enviar OTP por sms
export const PostVerificationSendPhone = async (phone, reCaptchaToken) => {
  let body = "";
  // let auxAuthorization = process.env.REACT_APP_ENVELOP_ACTIVE_PRE_REGISTRY == "true";
  let header = buildHeader("*/*", true, false);
  header.append("X-Captcha-Token", reCaptchaToken);
  let apiUrl = fetchApi.PostVerificationSendPhone.replace("{phone}", phone);
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return response;
};

//API 3.0 Petición para verificar OTP email
export const PostVerificationValidationOtpEmail = async (email, otp) => {
  let body = {
    email: email,
    otp: otp,
  };
  let header = buildHeader("*/*", false, "application/json");
  let apiUrl = fetchApi.PostVerificationValidationOtpEmail;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return response;
};

//API 3.0 Petición para verificar OTP phone
export const PostVerificationValidationOtpPhone = async (phone, otp) => {
  let body = {
    phone: phone,
    otp: otp,
  };
  let header = buildHeader("*/*", false, "application/json");
  let apiUrl = fetchApi.PostVerificationValidationOtpPhone;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return response;
};

//API 3.0 Petición para obtener los datos del usuario
export const GetProfileUser = async () => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetProfileUser;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
  return response;
};

//API 3.0 Petición para verificar OTP phone
export const PutProfileUser = async (data) => {
  let body = data;
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PutProfileUser;
  let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl);
  return response;
};

//API 3.0 Petición para obtener las empresas del usuario
export const GetEnterpriseUserEnterprises = async () => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetEnterpriseUserEnterprises;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
  return response;
};

//API 3.0 Petición para añadir un email verificado
export const PostVerificationDataEmail = async (email, otp) => {
  let body = {
    email: email,
    otp: otp,
  };
  let header = buildHeader("application/json", true, "application/json");
  let apiUrl = fetchApi.PostVerificationDataEmail;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return response;
};

//API 3.0 Petición para añadir un email verificado
export const PostVerificationDataPhone = async (phone, otp) => {
  let body = {
    phone: phone,
    otp: otp,
  };
  let header = buildHeader("application/json", true, "application/json");
  let apiUrl = fetchApi.PostVerificationDataPhone;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return response;
};

//API 3.0 Petición para añadir una empresa
export const PostEnterprise = async (data) => {
  let body = data;
  let header = buildHeader("application/json", true, "application/json");
  let apiUrl = fetchApi.PostEnterprise;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return response;
};

//API 3.0 Petición para obtener las suscripciones de un usuario
export const GetSubscriptionUser = async () => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetSubscriptionUser;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
  return response;
};

//API 3.0 Petición para obtener las suscripciones de una empresa
export const GetEnterpriseSubscriptions = async (idEnterprise) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl =
    fetchApi.GetEnterpriseSubscriptions + "?idEnterprise=" + idEnterprise;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
  return response;
};

//API 3.0
export const GetUserCheckSingleSubscription = async () => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetUserCheckSingleSubscription;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
  return response;
};

//API 3.0 
export const GetEnterpriseCheckSingleSubscription = async (idEnterprise) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl =
    fetchApi.GetEnterpriseCheckSingleSubscription + "?idEnterprise=" + idEnterprise;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
  return response;
};

//API 3.0 Petición para obtener los documentos de una empresa
export const DeleteVerificationUserEmail = async (email) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.DeleteVerificationUserEmail.replace("{email}", email);
  let response = await CommonFetch(header, body, fetchMethod.DELETE, apiUrl);
  return response;
};

//API 3.0 Petición para obtener los documentos de una empresa
export const DeleteVerificationUserPhone = async (phone) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.DeleteVerificationUserPhone.replace("{phone}", phone);
  let response = await CommonFetch(header, body, fetchMethod.DELETE, apiUrl);
  return response;
};

//API 3.0 Petición para suscribir servicios a un usuario
export const PostSubscriptionUser = async (oidApplication) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.PostSubscriptionUser.replace(
    "{applicationOidToSubscribe}",
    oidApplication
  );
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return response;
};

//API 3.0 Petición para enviar invitación
export const PostInvitationInvitation = async (email, idEnterprise, roleId) => {
  let body = {
    sendTo: email,
    idEnterprise: idEnterprise,
    roleId: roleId,
  };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostInvitationInvitation;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return response;
};

//API 3.0 Petición para desuscribir servicios a un usuario
export const PutSubscriptionUserUnsubscribe = async (oidApplication) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.PutSubscriptionUserUnsubscribe.replace(
    "{applicationOidToUnsubscribe}",
    oidApplication
  );
  let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl);
  return response;
};

//API 3.0 Petición para desuscribir servicios a una empresa
export const PutSubscriptionEnterpriseUnsubscribe = async (
  oidApplication,
  idEnterprise
) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.PutSubscriptionEnterpriseUnsubscribe.replace(
    "{applicationOidToUnSubscribe}",
    oidApplication
  ).replace("{idEnterprise}", idEnterprise);
  let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl);
  return response;
};

//API 3.0 Petición para suscribir servicios a una empresa
export const PostSubscriptionEnterprise = async (
  oidApplication,
  idEnterprise
) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl =
    fetchApi.PostSubscriptionEnterprise.replace(
      "{applicationOidToSubscribe}",
      oidApplication
    ) +
    "?idEnterprise=" +
    idEnterprise;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return response;
};

//API 3.0 Petición para obtener los documentos de una empresa
export const GetEnterpriseOwnDocuments = async (idEnterprise) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl =
    fetchApi.GetEnterpriseOwnDocuments + "?idEnterprise=" + idEnterprise;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
  return response;
};

//API 3.0 Petición para dar/quitar permisos a las app sobre un usuario
export const PutSubscriptionUserUpdatePermissionData = async (
  applicationOidToUpdate,
  permission
) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl =
    fetchApi.PutSubscriptionUserUpdatePermissionData.replace(
      "{applicationOidToUpdate}",
      applicationOidToUpdate
    ) +
    "?permissionData=" +
    permission;
  let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl);
  return response;
};

//API3.0 Peticion para modificar estado datos personales
export const PutSubscriptionEnterpriseUpdatePermissionData = async (
  oidApplication,
  permission,
  idEnterprise
) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl =
    fetchApi.PutSubscriptionEnterpriseUpdatePermissionData.replace(
      "{applicationOidToUpdate}",
      oidApplication
    ) +
    "?permissionData=" +
    permission +
    "&idEnterprise=" +
    idEnterprise;
  let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl);
  return response;
};

//Para API 3.0
export const GetEnterpriseSelect = async (idEnterprise) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetEnterpriseSelect.replace(
    "{idEnterprise}",
    idEnterprise
  );
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
  return response;
};

// API 3.0 Authorization
export const PostAuthorizeAuthorize = async (
  username,
  password,
  redirectUri,
  applicationOid
) => {
  let auxState = getSessionItem(sessionValues.state);
  let state = checkIsNullUndefined(auxState) ? uuidv4() : auxState;
  let body = {
    username: username,
    password: password,
  };
  let header = buildHeader("application/json", false, "application/json");
  let auxClientId = checkIsNullUndefined(applicationOid) ? getApplicationOid() : applicationOid;
  let apiUrl =
    fetchApi.PostAuthorizeAuthorize +
    "?redirect_uri=" +
    redirectUri +
    "&response_type=code" +
    "&state=" +
    state +
    "&scope=openid" +
    "&client_id=" +
    auxClientId;
  let response = await CommonFetch(
    header,
    body,
    fetchMethod.POST,
    apiUrl,
    false,
    false,
    true
  );
  return response;
};

// API 3.0 Petición para obtener el token
export const PostAuthorizeToken = async (code, redirectUri, applicationOid, personType) => {
  let body = {
    redirect_uri: redirectUri,
    code: code,
    client_id: checkIsNullUndefined(applicationOid) ? getApplicationOid() : applicationOid,
    grant_type: "authorization_code",
  };
  if (!checkIsNullUndefined(personType) && personType == 2) {
    body.scope = "enterprise";
  };
  let formBody = [];
  for (let property in body) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(body[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  };
  formBody = formBody.join("&");
  let header = buildHeader("*/*", false, "application/x-www-form-urlencoded", applicationOid);
  let apiUrl = fetchApi.PostAuthorizeToken;
  let response = await CommonFetch(
    header,
    formBody,
    fetchMethod.POST,
    apiUrl,
    true,
    false,
    true
  );
  return response;
};

//Para API 3.0 Aceptar invitación
export const PostInvitationInvitationAccept = async (idInvitation, applicationOid) => {
  let body = "";
  let header = buildHeader("*/*", true, false, applicationOid);
  let apiUrl = fetchApi.PostInvitationInvitationAccept.replace(
    "{idInvitation}",
    idInvitation
  );
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return await response;
};

//Para API 3.0 Aceptar invitación
export const DeleteInvitationInvitation = async (idInvitation) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.DeleteInvitationInvitation.replace(
    "{idInvitation}",
    idInvitation
  );
  let response = await CommonFetch(header, body, fetchMethod.DELETE, apiUrl);
  return await response;
};

//Para API 3.0 Comprobar password
export const PostPasswordCheckPassword = async (password) => {
  let body = {
    password: password,
  };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostPasswordCheckPassword;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return await response;
};

//Para API 3.0 Crear usuario de systema
export const PostEnterpriseRoleCreateSystemUser = async (
  idEnterprise,
  applicationOid,
  password,
  password2,
  otp
) => {
  let body = {
    idEnterprise: idEnterprise,
    applicationOid: applicationOid,
    password: password,
    password2: password2,
    otp: otp,
  };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostEnterpriseRoleCreateSystemUser;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return await response;
};

//Para API 3.0 Borrar Empresa
export const DeleteEnterprise = async (idEnterprise) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.DeleteEnterprise.replace(
    "{idEnterprise}",
    idEnterprise
  );
  let response = await CommonFetch(header, body, fetchMethod.DELETE, apiUrl);
  return await response;
};

//API 3.0 Petición para Borrar una dirección
export const DeleteVerificationDataAddress = async (idUserAddress) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.DeleteVerificationDataAddress.replace(
    "{idUserAddress}",
    idUserAddress
  );
  let response = await CommonFetch(header, body, fetchMethod.DELETE, apiUrl);
  return response;
};

//Para API 3.0 Añadir o editar dirección de usuario
export const PostProfileUserCreateOrUpdateAddress = async (address, codeConfiguration) => {
  let body = address;
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostProfileUserCreateOrUpdateAddress;

  if (!checkIsNullUndefined(codeConfiguration)) {
    apiUrl = apiUrl + "?codeConfiguration=" + codeConfiguration;
  }
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return await response;
};

//API 3.0 Petición para Borrar una dirección
export const DeleteVerificationDataEnterpriseAddress = async (
  idEnterpriseAddress
) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.DeleteVerificationDataEnterpriseAddress.replace(
    "{idEnterpriseAddress}",
    idEnterpriseAddress
  );
  let response = await CommonFetch(header, body, fetchMethod.DELETE, apiUrl);
  return response;
};

//Para API 3.0 Añadir o editar documentos de usuario
export const PostProfileUserCreateOrUpdateDocument = async (document) => {
  let body = document;
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostProfileUserCreateOrUpdateDocument;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return await response;
};

//API 3.0 Petición para Borrar un documento
export const DeleteVerificationDataDocument = async (
  IdTypeUserDocument,
  documentNumber
) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.DeleteVerificationDataDocument.replace(
    "{IdTypeUserDocument}",
    IdTypeUserDocument
  ).replace("{documentNumber}", documentNumber);
  let response = await CommonFetch(header, body, fetchMethod.DELETE, apiUrl);
  return response;
};

//Para API 3.0
export const GetSSO = async (applicationOid) => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json", applicationOid);
  let apiUrl = fetchApi.GetSSO;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
  return await response;
};

//API 3.0 Petición para obtener los datos del usuario
export const GetProfileUserExtended = async () => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetProfileUserExtended;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
  return response;
};

export const PostCallback = async (
  code, scope
) => {
  let body = {
    code: code,
    scope: scope,
  };
  let formBody = [];
  for (let property in body) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(body[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  let header = buildHeader("*/*", false, "application/x-www-form-urlencoded");
  let apiUrl = fetchApi.PostCallback;
  let response = await CommonFetch(
    header,
    formBody,
    fetchMethod.POST,
    apiUrl,
    true,
    false,
    true
  );
  return response;
};

export const PostSSO = async (cookie) => {
  let body = { cookie: cookie };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostSSO;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return await response;
};

export const PostCancelToken = async (token) => {
  let body = {
    idToken: token
  };
  let header = buildHeader("*/*", false, "application/json");
  let apiUrl = fetchApi.PostCancelToken;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
  return response;
};