import React from "react";
import {
  AppBar,
  Grid
} from "@material-ui/core";
import correosIdLogoBlue from "../../assets/images/Cornamusa_Azul.svg";
import { FooterStyles } from "./FooterStyles";
import { EnumTyC } from "../../pages/TyC/components/EnumTyC";
import { useTranslation } from "react-i18next";
const hrefTyCPage = "/TyC/";
export function Footer(props) {
  const classes = FooterStyles();
  const { t, i18n } = useTranslation();

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Grid container item xs={12} className={classes.footerContainer}>
        <Grid item xs={12} md={2} className={classes.imgContainer}>

          <img
            src={correosIdLogoBlue}
            alt={t("footerCopyrightText")}
            className={classes.imgFooter}
          />

        </Grid>
        <Grid container item xs={12} md={10} spacing={1} className={classes.containerPadding}>
          <Grid item xs={12} className={classes.linkPadding} style={{ color: "#CBCBCB" }}>
            <p className={classes.pLink}>
              <a
                className={classes.legalUrl}
                href={hrefTyCPage + EnumTyC.Cookies}
                target="_blank"
                rel="noreferrer"
              >
                {t("cookiesPolicy")}
              </a>
            </p>

            <p className={classes.pLink}>
              <a
                className={classes.legalUrl}
                href={hrefTyCPage + EnumTyC.WarninLegal}
                target="_blank"
                rel="noreferrer"
              >
                {t("warningLegal")}
              </a>
            </p>

            <p className={classes.pLink}>
              <a
                className={classes.legalUrl}
                href={hrefTyCPage + EnumTyC.PrivacyPolicy}
                target="_blank"
                rel="noreferrer"
              >
                {t("privacyPolicy")}
              </a>
            </p>
          </Grid>
          <Grid item xs={12} className={classes.linkPadding} style={{ color: "#CBCBCB" }}>
            <p className={classes.pFooter}>  {t("footerCopyrightText")}</p>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
}
