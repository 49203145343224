import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Container,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import { CommonStyles } from "../../../commons/CommonStyles";
import { useTranslation } from "react-i18next";
// import { GetMasterRgpd } from "../../../services/MasterCallsService";
import { SelectorSwitch } from "../../CommonComponents/SelectorSwitch";
import { Size, sessionValues } from "../../../commons/EnumsGeneral";
import { GetApplicationAuxRgpd } from "../../../services/ApplicationAuxCallsService";
import { setSessionItem } from "../../../services/Commons";
import { checkIsNullUndefined } from "../../../commons/Utils";

export function OffersAndProm(props) {
  const commonClasses = CommonStyles();
  const { t } = useTranslation();
  const { launchAlert, handleOffersSelected } = props;

  const [offersList, setOffersList] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    let auxOffersAndProms = sessionStorage.getItem(sessionValues.offersAndProms);
    if (checkIsNullUndefined(auxOffersAndProms)) {
      GetApplicationAuxRgpd().then((response) => {
        if (response && !response.Status) {
          response.forEach((elem) => {
            elem.checked = false;
          });
          setSessionItem(sessionValues.offersAndProms, JSON.stringify(response));
          setOffersList(response);
          handleOffersSelected(response);
        } else {
          launchAlert("error", response);
        };
      });
    } else {
      let auxOffersList = JSON.parse(auxOffersAndProms);
      setOffersList(auxOffersList);
      handleOffersSelected(auxOffersList);
    };
  }, []);

  useEffect(() => {
    handleGlobalOfferSelected();
  }, [offersList]);

  const handleSelectAllOffers = (value) => {
    let auxOffersList = JSON.parse(JSON.stringify(offersList));
    auxOffersList.forEach((elem) => {
      elem.checked = value;
    });
    setOffersList(auxOffersList);
    handleAccept(auxOffersList);
    setAllSelected(value);
  };

  const handleCheck = (auxCheckOffer) => {
    let auxOffersList = JSON.parse(JSON.stringify(offersList));
    let auxOffer = auxOffersList.find((offer) => auxCheckOffer.code == offer.code);
    auxOffer.checked = !auxCheckOffer.checked;
    setOffersList(auxOffersList);
    handleAccept(auxOffersList);
  };

  const handleAccept = (list) => {
    handleGlobalOfferSelected();
    handleOffersSelected(list);
  };

  const getFixedLabel = (objectToAccept) => {
    let specialWords = ["Ofertas y promociones", "Cesión de datos", "Perfilado"];
    let text = "";
    let specialWord = "";
    specialWords.forEach((word) => {
      if (objectToAccept.name?.includes(word)) {
        specialWord = word;
        text = objectToAccept.name?.replace(word, "");
      };
    });
    return (
      <Typography style={{ fontSize: "1em" }}>
        <a href={objectToAccept.url} target="_blank" rel={"noopener noreferrer"} className={commonClasses.linkDecoration}>{specialWord}</a>
        <span>{text}</span>
      </Typography>
    );
  };

  const mountControlLabel = (objectToAccept) => {
    return (
      <FormControlLabel
        key={objectToAccept.code}
        control={
          <Checkbox
            checked={objectToAccept.checked}
            onChange={() => handleCheck(objectToAccept)}
            name={objectToAccept.code}
          />
        }
        label={getFixedLabel(objectToAccept)}
      />
    );
  };

  const handleGlobalOfferSelected = () => {
    let hasSeleted = false;
    let hasNotSelected = false;
    offersList.forEach((offer) => {
      if (offer.checked == true) {
        hasSeleted = true;
      } else {
        hasNotSelected = true;
      };
    });
    let auxGlobalSelected = hasSeleted && hasNotSelected ? 2 : !hasSeleted && hasNotSelected ? 1 : 3;
    if (auxGlobalSelected === 3) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    };
  };

  return (
    <Grid container item xs={12}>
      <Typography variant="h6" className={commonClasses.dialogTitleText} style={{ width: "100%" }}>
        <span>{t("registerPageOfersPromotions")}</span>
      </Typography>
      <Container>
        <Grid container>
          <Grid item xs={12} style={{ marginTop: "0.75em", marginBottom: "0.5em" }}>
            <Typography className={commonClasses.dialogContentText} style={{ marginBottom: "0.5em" }}>
              {t("registerPageAgreeConditionsTermsConsent")}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "1em", marginBottom: "1em" }}>
            <SelectorSwitch
              textA={t("registerPageOfersPromotionsSelectedAll")}
              textB={t("registerPageOfersPromotionsSelectedSome")}
              selectedA={allSelected}
              handleSwitch={handleSelectAllOffers}
              size={Size.medium}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid style={{ marginTop: "0.5em" }}>
            <FormControl>
              <FormGroup>
                <Container style={{ textAlign: "left" }}>
                  {offersList.map((offer) => {
                    return mountControlLabel(offer);
                  })}
                </Container>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};
