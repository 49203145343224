import React, { useEffect, useState } from "react";
import {
  Container,
  Dialog,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from "@material-ui/core";
import { CorreosButton } from "../../../components/CorreosButton/CorreosButton";
import { ChooseEnterpriseStyles } from "../ChooseEnterpriseStyles";
import { CommonStyles } from "../../../commons/CommonStyles";
import { useTranslation } from "react-i18next";
import { checkIsNullUndefined } from "../../../commons/Utils";
import { backToLogin } from "../../../services/Commons";

export function DialogEnterprise(props) {
  const { enterprises, manageEnterpriseLogin, openDirectly, create, goToCreateEnterprise } = props;
  const commonClasses = CommonStyles();
  const classes = ChooseEnterpriseStyles();
  const { t, i18n } = useTranslation();

  const [enterprise, setEnterprise] = useState("");
  const [open, setOpen] = useState(openDirectly);
  const [withButtons, setWithButtons] = useState(true);
  useEffect(() => {
    selectView();
  }, [enterprises]);

  const selectView = () => {
    setEnterprise(enterprises[0]?.id);
    if (enterprises.length > 5) {
      setWithButtons(false);
    } else {
      setWithButtons(true);
    }
  };

  const handleCancel = () => {
    backToLogin();
    setOpen(false);
  };

  const handleNext = (auxEnterpriseId) => {
    let auxEnteprise = enterprises.find((empresa) => empresa.id === auxEnterpriseId);
    manageEnterpriseLogin(auxEnteprise.id, auxEnteprise.idRol);
    setOpen(false);
  };

  const handleChange = (event) => {
    setEnterprise(event.target.value);
  };

  const buttonGenerator = (empresa) => {
    return (
      <Grid key={empresa.id} item xs={12}>
        <CorreosButton
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => handleNext(empresa.id)}
          style={{ marginTop: "0.75em" }}
        >
          {checkIsNullUndefined(empresa.companyName) ? empresa.tradeName : empresa.companyName}
        </CorreosButton>
      </Grid>
    );
  };

  const selectGenerator = () => {
    return (
      <FormControl variant="filled" fullWidth color="secondary" className={commonClasses.inputsStyle}>
        <InputLabel label={t("Empresa")} color="secondary">
          {t("chooseEnterprise")}
        </InputLabel>
        <Select
          native
          color="secondary"
          value={enterprise}
          onChange={handleChange}
          label={t("Empresa")}
          // input={<Input />}
        >
          {enterprises.map((empresa) => {
            return (
              <option key={empresa.id} value={empresa.id}>
                {checkIsNullUndefined(empresa.companyName) ? empresa.tradeName : empresa.companyName}
              </option>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  return (
    <Grid>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        className={classes.generalDialog}
      >
        <Container className={commonClasses.dialogTitle}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                className={commonClasses.dialogTitleText}
              >
                {t("chooseDialogTitle")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={commonClasses.dialogContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {withButtons
                ? enterprises.map((empresa) => {
                  return buttonGenerator(empresa);
                })
                : selectGenerator()}
            </Grid>
            {withButtons ? null : (
              <Grid
                container
                item
                xs={12}
                className={commonClasses.dialogButtonContainer}
              >
                <Grid item xs={12}>
                  <CorreosButton
                    variant="contained"
                    color="primary"
                    className={commonClasses.dialogButtonRight}
                    onClick={() => handleNext(enterprise)}
                  >
                    {t("accept")}
                  </CorreosButton>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              className={commonClasses.dialogButtonContainer}
            >
              <Grid item xs={6}>
                <CorreosButton
                  color="secondary"
                  className={commonClasses.dialogButtonLeft}
                  onClick={handleCancel}
                >
                  {t("cancel")}
                </CorreosButton>
              </Grid>
              {create ? (
                <Grid item xs={6}>
                  <CorreosButton
                    variant="contained"
                    color="primary"
                    className={commonClasses.dialogButtonRight}
                    onClick={goToCreateEnterprise}
                  >
                    {t("mainPageCreateEnterprise")}
                  </CorreosButton>
                </Grid>) : null}
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </Grid>
  );
}
