import React, { useEffect, useState, useRef } from "react";
import {
    Typography,
    Container,
    Dialog,
    Grid,
} from "@material-ui/core";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { useTranslation } from "react-i18next";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { BasicDualConfirm } from "../../CommonComponents/BasicDualConfirm";
import { useOutsideAlerter } from "../../../commons/Utils";

export function DialogCancelOrUnsubscribe(props) {
    const { open, setOpen, forEnterprise, appName, cancelAccount, unsubscribe } = props;
    const dialogClasses = DialogStyles();
    const { t, i18n } = useTranslation();
    const wrapperRef1 = useRef(null);

    const [openCancelAccount, setOpenCancelAccount] = useState(false);
    const [text, setText] = useState("");
    const [anchorEl1, setAnchorEl] = useState(null);

    useEffect(() => {
        setText((forEnterprise) ? t("servicePageConfirmCancelEnterprise") : t("servicePageConfirmCancelAccount"));
    }, [forEnterprise]);

    const handleCancel = () => {
        declineCancelAccount();
        setOpen(false);
    };

    const handleCancelAccount = (event) => {
        setAnchorEl(event.target);
        setOpenCancelAccount(true);
    };

    const handleUnsubscribe = () => {
        unsubscribe();
        handleCancel();
    };

    const confirmCancelAccount = () => {
        cancelAccount();
        handleCancel();
    };

    const declineCancelAccount = () => {
        setOpenCancelAccount(false);
        setAnchorEl(null);
    };

    useOutsideAlerter(wrapperRef1, declineCancelAccount);

    return (
        <>
            <BasicDualConfirm
                id={appName + "CancelAccount"}
                open={openCancelAccount}
                title={text}
                anchorEl={anchorEl1}
                wrapperRef={wrapperRef1}
                action={confirmCancelAccount}
                closeAction={declineCancelAccount}
            />
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleCancel}
                PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
            >
                <Container className={dialogClasses.dialogTitle}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                className={dialogClasses.dialogTitleText}
                            >
                                {t("warning")}
                            </Typography>
                        </Grid>

                    </Grid>
                </Container>
                <Container className={dialogClasses.dialogContainer}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography className={dialogClasses.dialogContentText}>
                                {(forEnterprise) ?
                                    t("singleSubscriptionDialogRedirectTextEnterprise").replace("{applicationName}", appName)
                                    :
                                    t("singleSubscriptionDialogRedirectTextUser").replace("{applicationName}", appName)
                                }
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            className={dialogClasses.dialogButtonContainer}
                        >
                            <Grid item xs={4}>
                                <CorreosButton
                                    onClick={handleCancel}
                                    className={dialogClasses.dialogButtonLeft}
                                    style={{ width: "90%" }}
                                >
                                    {t("cancel")}
                                </CorreosButton>
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: "center" }}>
                                <CorreosButton
                                    onClick={(event) => handleCancelAccount(event)}
                                    className={dialogClasses.dialogButtonCenter}
                                    style={{ width: "90%" }}
                                >
                                    <DeleteIcon style={{ marginRight: "0.2em" }} />
                                    {(forEnterprise) ? t("servicePageCancelEnterprise") : t("servicePageCancelAccount")}
                                </CorreosButton>
                            </Grid>
                            <Grid item xs={4}>
                                <CorreosButton
                                    variant="contained"
                                    color="primary"
                                    onClick={handleUnsubscribe}
                                    className={dialogClasses.dialogButtonRight}
                                    style={{ width: "90%" }}
                                >
                                    <DeleteIcon style={{ marginRight: "0.2em" }} />
                                    {t("servicePageUnsubscribe")}
                                </CorreosButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>
        </>
    );
}
