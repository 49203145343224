export const GL1 = {

    //Generic

    saveSuccess: "Gardado con éxito!",
    saveError: "Erro ao gardar!",
    cancel: "Cancelar",
    accept: "Aceptar",
    next: "Seguinte",
    previous: "Anterior",
    required: "Este é un campo obrigatorio",
    exit: "Saír",
    search: "Buscar",
    news: "Novidades",
    send: "Enviar",
    reSend: "Reenviar",
    create: "Crear",
    add: "Engadir",
    delete: "Borrar",
    update: "Actualizar",
    yes: "Si",
    no: "Non",
    save: "Gardar",
    correosId: "Correos ID",
    genericError: "Produciuse un erro",
    change: "Cambiar",
    see: "Ver",
    download: "Descargar",
    email: "Email",
    phone: "Teléfono",
    idDocument: "Documento identificativo",
    address: "Enderezo",
    country: "País",
    province: "Provincia",
    city: "Cidade",
    postalCode: "Código postal",
    reset: "Reiniciar",
    pending: "Pendiente",
    done: "Hecho",
    here: "aquí",
    backToTheApp: "Volver a la aplicación",
    selectOption: "Selecciona una opción",
    noOptionSelected: "Sin seleccionar",

    // PendingData

    valid: "Válido",
    document: "Documento",
    userAddress: "Dirección de usuario",
    enterpriseAddress: "Dirección de empresa",
    privacySign: "Firma de privacidad",
    tycSign: "Firma de Términos y Condiciones",
    pendingData: "Tienes datos pendientes:",

    //login

    logInTitle: "Inicia sesión",
    sublogInTitle: "Accede a {appName} a través de Correos ID. ",
    sublogInTitleCID: "Accede a Correos ID. ",
    sublogInTitleInfo: "Si ya estás registrado, no tendrás que volver a registrarte.",
    sublogInTitleInfoCID: "Desde aquí puedes manejar tu identidad de correos.",
    logInTitleSelectType: "¿Cómo quieres acceder?",
    correosIdentityLabel: "Identidade de correos",
    correosPasswordLabel: "Contrasinal",
    correosCheckPasswordLabel: "Confirmación contraseña",
    loginTooltip: "Correos ID es la puerta de entrada a los servicios digitales de Correos. Para acceder a ellos, solamente debes introducir tus credenciales, no tendrás que volver a registrarte.",
    forgotPasswordButton: "Esqueciches o teu contrasinal?",
    nextButton: "Seguinte",
    logInButton: "Iniciar sesión",
    userPasswordError: "Usuario ou contrasinal incorrectos",
    warningLegal: "Aviso legal",
    privacyPolicy: "Política de Privacidade",
    cookiesPolicy: "Política de Cookies",
    createAccountButton: "Crear Conta",
    rememberme: "Recórdame",
    welcomeBack: "Ola de novo",
    recoverDisabledAccount1:
        "Bloqueouse a súa conta. Se quere activar de novo a súa conta e recuperar a súa información, prema na seguinte ",
    recoverDisabledAccount2: "ligazón",
    recoverDisabledAccount3: " para iniciar o proceso de recuperación de conta.",
    loginPrivacyDialogTitle: "Privacidade",
    loginPrivacyDialogText:
        "Para poder acceder á aplicación, antes debe aceptar a privacidade",
    loginSubscribeDialogTitle: "Suscripción",
    loginSubscribeDialogText: "No estás suscrito a la aplicación, si quieres suscribirte debes leer y aceptar los términos y condiciones y pulsar en aceptar",
    loginSubscribeDialogTextServices: "Para poder suscribirte primero debes leer y aceptar los términos y condiciones y pulsar en aceptar",
    InvitationAcceptSuccess: "Invitación aceptada",
    AcceptChangeEnterpriseRoleSuccess: "Cambio de rol aceptado con éxito",
    CancelOrRejectChangeRoleSuccess: "Se ha rechazado la invitación",
    InvitationAcceptError: "La invitación ha fallado",
    AcceptChangeEnterpriseRoleError: "Ha fallado el cambio de rol",
    CancelOrRejectChangeRoleError: "Ha fallado el rechazo de la invitación",
    loginSystemUserError: "Un usuario de sistema no puede loguear en la web",
    footerCopyrightText: "©Sociedad Estatal Correos y Telegrafos, S.A., S.M.E. Todos los derechos reservados.",
    loginFormatError: "Formato incorrecto",
    loginOauthError: "El método oauth ya no está disponible",

    //Register

    loginPageInfo: "¿Aún no tienes una cuenta? ",
    loginPageRegistryHere: "Regístrate aquí",
    registerPagePutData: "Introduce los datos de registro",
    registerPageConfirmIdentity: "Verifica tu identidad",
    registerPageMail: "Enderezo de correo electrónico",
    registerPageMailError: "O correo electrónico introducido non é correcto",
    registerPagePhoneError: "O teléfono introducido non é correcto",
    registerPageNDOError: "O documento introducido non é correcto",
    registerPageMailErrorExist:
        "O correo electrónico introducido xa está rexistrado",
    registerPagePassword: "Contrasinal",
    registerPagePasswordError:
        '8-16 caracteres, que conteñan 3 dos 4 seguintes: minúsculas, maiúsculas, díxitos (0-9), e un ou máis dos seguintes símbolos: @ # $ % ^ & * - _ + = [ ] { } | \\ : ‘ , ? / ` ~ ( ) ; . "',
    registerPageCheckPassword: "Confirmación",
    registerPageCheckPasswordError: "Os contrasinais non son iguais",
    registerPagePhone: "Teléfono",
    registerPagePhoneErrorShort: "O teléfono é demasiado curto",
    registerPagePhoneErrorLong: "O teléfono é demasiado longo",
    registerPagePhoneErrorExist: "O teléfono introducido xa está rexistrado",
    registerPagePrefixError: "Tiene que introducir un prefijo",
    registerPageTipoPersona: "Tipo de persoa",
    registerPageAgreeConditionsPhrase: "Debes leer y aceptar los términos y condiciones: ",
    registerPageAgreeConditions: "Termos e Condicións",
    registerPageAgreeConditionsDot: "Termos e Condicións: ",
    registerPageAgreeConditionsSee: "Ver Términos y Condiciones",
    registerPageAgreeConditionsTerms1: "Lin e acepto ",
    registerPageAgreeConditionsTerms2: "as condicións de uso ",
    registerPageAgreeConditionsTerms3: " de {appName}.",
    registerPageAgreeConditionsTerms4: "política de protección de datos",
    registerPageAgreeConditionsAccepted: "Aceptados",
    registerPageAgreeConditionsPending: "Pendiente *",
    registerPageAgreeConditionsTermsConsent:
        "Consonte a nosa Política de protección de datos, gustaríanos solicitar o seu consentimento para as seguintes actividades:",
    registerPageOfersPromotions: "Ofertas e Promocións",
    registerPageOfersPromotionsDot: "Ofertas e Promocións: ",
    registerPageOfersPromotionsAll: "ACEPTO TODOS",
    registerPageOfersPromotionsSome: "ACEPTO ALGÚNS",
    registerPageOfersPromotionsNone: "NON ACEPTO",
    registerPageOfersPromotionsPending: "PENDIENTE *",
    registerPagePersonalizeOptions:
        "Personalice a súa privacidade seleccionando as seguintes opcións:",
        registerPageIdentityVerifyTitle: "Confirme a súa identidade",
        registerPageIdentityVerifyText: "Para garantir a túa identidade, enviarémosche un código de verificación ao teu enderezo de correo electrónico. Introduce o código a continuación para completar o proceso de rexistro.",
    registerPageIdentityVerifyMailButton: "ENVIAR CORREO ELECTRÓNICO",
    registerPageIdentityVerifyPhoneButton: "ENVIAR SMS",
    registerPageIdentityVerifyVerificable:
        "Debe realizar unha das dúas verificacións obrigatoriamente, a outra pode omitila ou aproveitar para realizala",
    registerPageIdentityVerifyVerificableEmail:
        "A verificación do correo electrónico é obrigatoria",
    registerPageIdentityVerifyVerificablePhone:
        "A verificación do teléfono é obrigatoria",
    registerPageIdentityVerifyNoVerificableEmail:
        "A verificación do correo electrónico é opcional, pode omitir ese punto se quere",
    registerPageIdentityVerifyNoVerificablePhone:
        "A verificación do teléfono é opcional, pode omitir ese punto se quere",
    registerPageIdentityVerifyCodeSended: "Código enviado",
    registerPageOtp: "Introduza Código",
    registerPageOtpError: "O código introducido non é correcto",
    registerPageRegisterError: "Houbo un problema durante o rexistro",
    registerPageOfersPromotionsSelected: "Ofertas e promocións aceptadas: ",
    registerPageOfersPromotionsSelectedAll: "Todas",
    registerPageOfersPromotionsSelectedSome: "Solo algunas",
    registerPageOfersPromotionsSelectedNone: "Ningunha",
    registerPageOfersPromotionsSelectedPending: "Sin seleccionar",
    registerPageSeeOfersPromotions: "Abrir ofertas/promocións",
    registerPageSendOTP: "Introduza o código que lle acabamos de enviar",
    registerPageSendOTPEmail: "Código para correo electrónico",
    registerPageSendOTPPhone: "Código para teléfono",
    registryTitleData: "Datos Rexistro",
    registerPagePersonType1: "Particular",
    registerPagePersonType2: "Empresa/Autónomo",
    registerPageNoDocuments:
        "Para este tipo de empresa non se requiren documentos",
    registerPageWarnTitle: "Atención",
    registerPageWarnText: "El registro a correos ID ha ido correctamente, pero ha habido un problema en el guardado de datos, tendrás que volver a rellenarlos al acceder a la aplicación",
    registerPagePoliticsAndConditions: "Políticas y condiciones",
    registerPageRecaptcha: "Captcha",

    //ChooseUser

    chooseUserTitle:
        "Por favor, seleccione se quere autorizarse como particular ou como empresa:",
    chooseEnterpriseTitle:
        "Por favor, pulsa en 'Empresa' y escoge con cuál quieres autorizarte:",
    none: "Ninguna",
    chooseUser: "Particular",
    chooseEnterprise: "Empresa",
    chooseDialogTitle: "Seleccione empresa",

    //Stepper

    createUserCid: "Crear cuenta en CorreosID",
    userIdentify: "Verifica tu identidad",
    userIdentity: "Introduce tu identidad",
    sendOTP: "Enviar código",
    reSendOTP: "Reenviar código",
    codeConfirm: "Confirmar código",
    subscriptionToApp: "Suscripción a {appName}",
    ownerData: "Datos del titular",
    enterpriseCreation: "Creación de empresa",
    userData: "Datos de usuario",
    stepChangePass: "Cambiar contrasinal",
    enterpriseData: "Datos de empresa",
    enterpriseDocuments: "Engadir documentos",
    requiredData: "Datos requeridos",
    createUserCidTextLabel: "Introduce tu email de acceso y contraseña, acepta las políticas de privacidad y crea una cuenta en CorreosID para acceder a nuestros servicios de forma segura.",
    userIdentifyTextLabel: "Este paso es esencial para verificar tu identidad y garantizar la seguridad de tus datos.",
    userIdentityTextLabel: "Ingresa los detalles de tu identidad. Estos detalles nos ayudarán a personalizar tu experiencia y a brindarte un mejor servicio.",
    sendOTPTextLabel: "Te enviaremos un código único a tu dirección de correo electrónico o número de teléfono. Utiliza este código para verificar que tienes acceso a los medios de contacto proporcionados.",
    reSendOTPTextLabel: "En caso de no haber recibido el código, tienes la opción de solicitar su reenvío. Verifica tu carpeta de correo no deseado antes de hacerlo.",
    codeConfirmTextLabel: "Ingresa el código que has recibido y confirma tu acceso. Este código garantiza que eres el titular de la cuenta y estás listo para proceder.",
    subscriptionToAppTextLabel: "Opta por suscribirte a nuestra aplicación para acceder a sus funciones exclusivas. Mantente al tanto de las últimas actualizaciones y novedades.",
    ownerDataTextLabel: "Proporciona información detallada sobre ti.",
    enterpriseCreationTextLabel: "Si estás creando una empresa, completa los pasos necesarios para registrarla oficialmente. Proporciona información sobre ella.",
    userDataTextLabel: "Proporciona información adicional sobre ti para enriquecer tu perfil.",
    stepChangePassTextLabel: "Si deseas cambiar tu contraseña actual, este es el momento para hacerlo. Proporciona una nueva contraseña segura y asegúrate de guardarla en un lugar seguro.",
    enterpriseDataTextLabel: "Si estás registrando una empresa, completa los detalles adicionales.",
    enterpriseDocumentsTextLabel: "Sube documentos importantes relacionados con tu empresa.",
    requiredDataTextLabel: "Asegúrate de proporcionar todos los datos necesarios para completar el proceso. Estos pueden variar según el tipo de cuenta que estás creando. Verifica cada sección cuidadosamente.",

    //ResetPassword

    resetPasswordTitle: "¿Has olvidado tu contraseña?",
    resetPasswordText: "No te preocupes, ¡puede pasarle a cualquiera!",
    resetPasswordFormatError: "8-16 carácteres, que contengan 3 de los 4 siguientes: minúsculas, mayúsculas, dígitos (0-9), y uno o más de los siguientes símbolos: @ # $ % ^ & * - _ + = [ ] { } | \\ : ' , ? / ` ~ ( ) ; . \"",
    resetPasswordCheckError: "Las contraseñas no son iguales",
    resetPasswordTitle2: "Cambia tu contraseña",
    resetPasswordText2: "Confirma tu identidad con el código que te hemos enviado a:",
    resetPasswordEmptyError: "La contraseña no puede estar vacía",
    resetPasswordRedirection: "En unos segundos te redirigiremos al Inicio de sesión, o puedes ir pulsando ",
    lAddUser:
        "Introduza a súa identidade de correos e axudarémoslle a crear un contrasinal novo.",
    backButton: "Volver",
    sending: "Enviando...",
    lAddOtp: "Necesitamos saber que é vostede. Confírmanolo?",
    lOtp: "Código de verificación",
    emptyError: "O campo non pode estar baleiro.",
    emptyErrorPass: "Os campos non poden estar baleiros.",
    emptyErrorIdentifier: "Debe engadir un identificador de correos válido.",
    lChangePass: "Escriba o seu novo contrasinal.",
    lPass: "Contrasinal",
    lRPass: "Repita o seu contrasinal",
    errorEqualPass: "Os contrasinais deben ser iguais",
    errorCheckPass: "O contrasinal introducido non é válido.",
    lCFinalStep: "O contrasinal modificouse con éxito.",
    lEFinalStep: "Erro ao cambiar o contrasinal, volva intentalo nuns minutos.",
    lStart: "Iniciar",
    tLinkHelper: "Necesita axuda?",
    hAddUser:
        "Para poder realizar o cambio de contrasinal necesitamos saber o identificador co que se deu de alta na nosa plataforma.",
    hAddOtp:
        "Dende Correos ID necesitamos verificar que é vostede quen está a intentar cambiar o contrasinal, por ese motivo mandarémoslle ao seu identificador de conta (teléfono ou correo electrónico) un código de 5 díxitos. Simplemente ten que dicirnos ese código e poderemos continuar co proceso.",
    hAddPass:
        'Debe indicar un novo contrasinal que teña entre 8-16 caracteres, que conteñan 3 dos 4 seguintes valores: minúsculas, maiúsculas, díxitos (0-9), e un ou máis dos seguintes símbolos: @ # $ % ^ & * - _ + = [ ] { } |  : ‘ , ? / ` ~ " ( ) ; .os mínimos necesarios para poder continuar.',
    dialogClose: "Pechar",
    otpRpError: "O código indicado non é correcto.",
    otpInvalidError: "OTP non válido",
    otpEmptyError: "O código non pode estar baleiro.",

    //PrefixPhone

    prefixSelector: "Prefixo",

    //UserData

    userDataInfo:
        "Xa tes unha conta de CorreosId, agora é o momento de subscribirte a {appName}",
    userDataInfoCid:
        "Rexistrácheste correctamente en CorreosId, agora é necesario completar os datos requiridos",
    userDataVerifyEmailOk: "O correo electrónico foi verificado correctamente",
    userDataVerifyEmailKo:
        "Houbo un problema coa verificación do correo electrónico",
    userDataVerifyPhoneOk: "O teléfono foi verificado correctamente",
    userDataVerifyPhoneKo: "Houbo un problema coa verificación do teléfono",
    userDataInfoQuestion: "¿Seguro que deseas cancelar la suscripción a {appName}?",
    userDataInfoQuestionCid: "¿Seguro que deseas cancelar la creación de la empresa?",
    userDataExitInfo:
        "Ya estás registrado en correosID, ten en cuenta que si sales ahora cuando hagas login en {appName} tendrás que terminar de rellenar los datos para suscribirte.",
    userDataExitInfoCid:
        "Ya estás registrado en correosID, ten en cuenta que si sales ahora tu usuario no tendrá empresa",
    userDataCopyDirection: "Copiar enderezo",
    userDataSuccessUpdate: "Datos de usuario actualizados con éxito",
    userDataAddEmail: "Engadir correo electrónico",
    userDataModifyEmail: "Verificar email",
    userDataDeleteEmail: "Eliminar email",
    userDataDeleteEmailSuccess: "Email eliminado",
    userDataAddPhone: "Engadir teléfono",
    userDataModifyPhone: "Verificar teléfono",
    userDataDeletePhone: "Eliminar teléfono",
    userDataDeletePhoneSuccess: "Teléfono eliminado",
    userDataAddDoc: "Añadir documento",
    userDataModifyDoc: "Modificar documento identificativo",
    userDataDeleteDoc: "Eliminar documento identificativo",
    userDataAddDocSuccess: "Documento identificativo añadido",
    userDataModifyDocSuccess: "Documento identificativo modificado",
    userDataDeleteDocSuccess: "Documento identificativo eliminado",
    userDataAddAddress: "Añadir dirección",
    userDataModifyAddress: "Modificar Dirección",
    userDataDeleteAddress: "Eliminar Dirección",
    userDataAddAddressSuccess: "Dirección añadida",
    userDataModifyAddressSuccess: "Dirección modificada",
    userDataDeleteAddressSuccess: "Dirección eliminada",
    userDataSaveWarning:
        "Recorde gardar os datos para que este cambio teña efecto",
    userDataAliasWarning: "Ya tienes una dirección con ese nombre",
    userDataAddDirection: "Quere engadir un enderezo cos datos actuais?",
    userDataAddDirectionMandatorie:
        "Para poder engadir un enderezo debe encher todos os campos obrigatorios",
    userDataRequiredDirection: "O Alcume e o Enderezo son campos obrigatorios",
    userDataAddDirectionSuccess: "Enderezo engadido con éxito",
    userDataChangeDirectionSuccess: "Dirección modificada con éxito",
    userDataFavouriteDirection: "Dirección favorita",
    userDataFavouriteDirectionWarning: "Ya tienes una dirección favorita y sólo puedes tener una, si seleccionas esta la otra dejará de serlo",
    userDataWarningMessage:
        "Recorde que para gardar un novo enderezo, antes debe engadilo á lista, se non o fixo aínda, prema Cancelar e engada o novo enderezo antes de continuar",
    userDataRegister: "Registro completado",
    userDataIncomplete:
        "Faltan datos obrigatorios. Por favor, encha os datos obrigatorios que faltan.",
    userDataFNAError: "A data de nacemento non pode ser posterior a hoxe.",
    userDataSaveProfile: "Guardar perfil",
    enterpriseDataSaveProfile: "Guardar empresa",
    userDataAddressUpdateWarning: "É necesario actualizar este enderezo porque os seus datos non son correctos",
    userDataPageSubtitle: "Edita tus datos personales y gestiona las direcciones",
    userFormatIncorrect: "Formato no soportado. Solo se permiten letras",

    //EnterpriseData

    enterpriseDataInfo:
        "Realizouse o rexistro correctamente, agora é necesario rematar de encher os datos da empresa",
    enterpriseDataInfoQuestion:
        "Seguro que desexa cancelar a inserción de datos?",
    enterpriseDataExitInfo:
        "A empresa xa foi rexistrada, se sae sen informar os datos, volveránselle pedir cando volva acceder",
    enterpriseDataCopyDirection: "Copiar enderezo",
    enterpriseDataSuccessUpdate: "Datos de empresa actualizados con éxito",
    enterpriseDataAddressWarning:
        "La dirección se guardará junto con la creación de la empresa",
    enterpriseDataCreated: "Empresa creada con éxito",
    enterpriseDataSaveData: "Guardar datos",
    enterpriseDataPageSubtitle: "Modifica los datos de tu empresa así como la dirección fiscal",
    enterpriseDataCreatePageSubtitle: "Añade los datos de tu empresa así como la dirección fiscal",
    enterpriseDataSave: "Guardar empresa",
    enterpriseDataCreate: "Crear empresa",

    //DialogAddChange
    dialogAddChangeAddTitle: "Engadir enderezo",
    dialogAddChangeChangeTitle: "Modificar enderezo",
    dialogAddChangeAddText: "Engadir datos de enderezo",
    dialogAddChangeChangeText: "Modificar os datos do enderezo",

    //Main

    userNameUnregistered: "Usuario sen nome",
    enterpriseNameUnregistered: "Empresa sen nome",
    mainPageTitle: "Benvido a Correos ID",
    mainPageUserProfile: "Perfil de usuario",
    mainPagePrivacity: "Privacidade",
    mainPageVerifications: "Verificacións",
    mainPageCreateEnterprise: "Crear empresa",
    mainPageAvailableServices: "Servizos subscritos",

    //SideBar

    UserData: "Datos",
    UserVerifications: "Verificacións",
    UserPrivacy: "Privacidade",
    EnterpriseCreate: "Crear",
    EnterpriseTeam: "Equipo",
    EnterpriseDelete: "Solicitar Baixa",
    UserServices: "Particular",
    EnterpriseData: "Datos",
    EnterpriseVerifications: "Verificacións",
    EnterprisePrivacy: "Privacidade",
    EnterpriseServices: "Empresa",
    User: "Usuario",
    Enterprise: "Empresa",
    Services: "Servizos",
    Service: "Servicio",
    profileComp: "Progreso do perfil",

    //Service Page

    subscribeService: "Subscribirse",
    unsubscribeService: "Baixa",
    revokeData: "Retirar Acceso a Os Meus Datos",
    acceptData: "Permitir Acceso a Os Meus Datos",
    goToService: "Entrar",
    goToUrl: "Ir a",
    subscribedService: "Subscrito",
    unSubscribedService: "Non subscrito",
    askUnsubscribeService: "Solicitar Baixa",
    serviceSubscribed: "Subscrición realizada con éxito",
    serviceUnsubscribed: "Cancelouse a subscrición con éxito",
    revokeEnterpriseData: "Retirar permisos",
    acceptEnterpriseData: "Permitir permisos",
    servicePageDualWarning: "¿Seguro que quieres darte de baja de {applicationName}?",
    servicePageCancelAccount: "Usuario",
    servicePageCancelEnterprise: "Empresa",
    servicePageUnsubscribe: "Servicio",
    singleSubscriptionDialogRedirectTextUser: "Estás a punto de darte de baja de {applicationName}, que es tu único servicio. Si deseas continuar con esta acción, pulsa en 'Servicio'. Por otro lado, si lo que quieres es dar de baja tu usuario de Correos ID, pulsa en 'Usuario'.",
    singleSubscriptionDialogRedirectTextEnterprise: "Estás a punto de dar de baja la empresa de {applicationName}, que es su único servicio. Si deseas continuar con esta acción, pulsa en 'Servicio'. Por otro lado, si lo que quieres es dar de baja la empresa, pulsa en 'Empresa'.",
    servicePageLinkText: "ligazón",
    subscribeMeText: "Subscríbete",
    subscriptionOkTitle: "Feito!",
    subscriptionOkText: "Suscribícheste correctamente",
    servicePageSubtitle: "Co teu identidade de Correos (Correos ID) podes subscribirte aos servizos dixitais de Correos",
    servicePageAppGoTo: "Suscríbete desde el servicio",
    servicePageAppAccess: "Accede desde el servicio",
    servicePageConfirmCancelAccount: "¿Seguro que quieres cancelar tu cuenta?",
    servicePageConfirmCancelEnterprise: "¿Seguro que quieres eliminar la empresa?",

    //Verifitacions Page

    verificationsVerify: "Verificar",
    verificated: "Verificado",
    notVerificated: "Non verificado",
    pendingVerificated: "Pendente",
    UserEmail: "Correo electrónico",
    PersonaTelfMovil: "Teléfono",
    PersonaNumDocID: "N.º documento",
    PersonaDireccion: "Enderezo",
    verificationsSendCode: "Enviaremos un código de verificación a: ",
    verificationsSendCodeEmail: "Verifica tu email",
    verificationsSendCodePhone: "Verifica tu teléfono",
    verificationsAddVerifEmail: "Añade y verifica un email",
    verificationsAddVerifPhone: "Añade y verifica un teléfono",
    verificationsConfirmCode: "Introduza o código de verificación",
    verificationsVerificationType: "Tipo de verificación",
    verificationsConfirmPostCard:
        "Imos enviarlle o código por correo postal, seguro que quere realizar este tipo de verificación?",
    verificationsCreate: "Introduza os datos para engadir ",
    verificationsAlias: "Alcume",
    verificationsCountry: "País ou Rexión",
    verificationsDirection: "Enderezo",
    verificationsPostalCode: "Código Postal",
    verificationsRegion: "Provincia/Rexión",
    verificationsTown: "Localidade",
    verificatinosDeleteElement: "Eliminar elemento?",
    verificationsVerifiedData: "Datos verificados",
    verificationsEMA: "Correos electrónicos verificados",
    verificationsMOV: "Teléfonos verificados",
    verificationsNDO: "Documentos verificados",
    verificationsALI: "Enderezos verificados",
    verificationsEmpty: "Ningún",
    verificationsUpgrade: "Aumentar nivel de verificación",
    verificationsAddEmail: "Correo electrónico engadido correctamente",
    verificationsVerifyEmail: "Correo electrónico verificado correctamente",
    verificationsDeleteEmail: "Correo electrónico eliminado correctamente",
    verificationsAddPhone: "Teléfono engadido correctamente",
    verificationsVerifyPhone: "Teléfono verificado correctamente",
    verificationsDeletePhone: "Teléfono eliminado correctamente",
    verificationsAddAddress: "Dirección añadida correctamente",
    verificationsVerifyAddress: "Dirección verificada correctamente",
    verificationsDeleteAddress: "Dirección eliminada correctamente",
    verificationsDeleteDocument: "Documento eliminado correctamente",
    verificationsDeleteAddressTooltip: "Eliminar dirección",
    verificationsDocuments: "Documentación",
    verificationsemail: "Correo electrónico",
    verificationsphone: "Teléfono",
    verificationsaddress: "Enderezo",
    verificationsnumber: "Documento identificativo",
    verificationsByOtp: "Verificado por código",
    verificationsByForm: "Verificado por formato",
    verificationsReSendOtp: "Código reenviado",
    verificationDisableAccountTitle:
        "Ao darse de baixa de Correos ID deixará de ter acceso a todos os servizos que se mostran a continuación:",
    verificationDisableAccountList: "Borraremos a súa conta*.",
    verificationDisableAccountP1:
        "Aconsellámoslle que antes de eliminar a súa conta de Correos ID solicite e finalice a baixa nos servizos nos que está rexistrado. Para iso, seleccione a opción Cancelar. Se aínda así segue preferindo eliminar agora a súa conta de Correos ID, prema en Dar de baixa.",
    verificationDisableAccountP2:
        "*Tranquilo, ten {disableAccDays} días para recuperar a súa conta de Correos ID por se se arrepinte. Poderá recuperar a conta de Correos ID, pero o resto de servizos poden non gardar os seus datos durante estes {disableAccDays} días.",
    verificationDisableAccountP3:
        "Correos ID comunicará a eliminación da súa conta aos servizos asociados, pero a eliminación da súa conta en cada servizo non é responsabilidade de Correos ID",
    verificationDisableAccountConfirm: "Dar de baixa",
    verificationsWrongTipeDocument: "O tipo de documento non é válido",
    verificationsUploadSuccess: "Documento subido con éxito",
    verificationsDeleteSuccess: "Documento eliminado con éxito",
    verificationsNoUploaded: "Non subido",
    verificationsEmailExists: "O correo electrónico introducido xa existe",
    verificationsPhoneExists: "O teléfono introducido xa existe",
    verificationsUnifyUserTitle: "Atención",
    verificationsUnifyUserText: "Hemos detectado que el {dataType} que estás intentando verificar ya existe en otra cuenta. ¿Es este {dataType} ({data}) realmente tuyo?. Si es así pulsa en 'Aceptar' para unificar ambas cuentas",
    verificationsPageSubtitle: "Puedes gestionar y verificar tus datos, así como cambiar tu contraseña de acceso",
    verificationsEnterprisePageSubtitle: "Puedes gestionar y verificar los datos de la empresa",
};
