import React, { useEffect, useState } from "react";
import {
    Typography,
    Container,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
} from "@material-ui/core";
import { CommonStyles } from "../../../commons/CommonStyles";
import { UserVerificationsStyles } from "../../UserVerifications/UserVerificationsStyles";
import { checkIsNullUndefined, getErrorMessage, validatePhoneNumber, getScreenWidth } from "../../../commons/Utils";
import {
    PostVerificationSendPhone,
    PostVerificationSendEmail,
    PostVerificationValidationOtpEmail,
    PostVerificationValidationOtpPhone,
} from "../../../services/ReasonService";
import { PrefixPhone } from "../../PrefixPhone/PrefixPhone";
import { useTranslation } from "react-i18next";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { secureValues, Size, verificatedValues } from "../../../commons/EnumsGeneral";
import { SelectorSwitch } from "../../CommonComponents/SelectorSwitch";
import history from "../../../services/history.js";
import { EnumPages } from "../../../commons/EnumPages.js";
import { setSecureItem } from "../../../services/Commons.js";
import ReCAPTCHA from "react-google-recaptcha";

export function DataVerification(props) {

    const { verificableData, countryList, finishOperation, setOpenUnification, firstInfo } = props;

    const commonClasses = CommonStyles();
    const classes = UserVerificationsStyles();
    const { t, i18n } = useTranslation();
    const validator = require("react-email-validator");

    const [showPhoneSelector, setShowPhoneSelector] = useState(false);
    const [country, setCountry] = useState({});
    const [verificableDataList, setVerificableDataList] = useState([]);
    const [verificable01, setVerificable01] = useState("");
    const [sended01, setSended01] = useState(false);
    const [otp01, setOtp01] = useState("");
    const [otp01Error, setOtp01Error] = useState(false);
    const [otp01ErrorText, setOtp01ErrorText] = useState("");
    const [verificable02, setVerificable02] = useState("");
    const [sended02, setSended02] = useState(false);
    const [verificable02error, setVerificable02Error] = useState(false);
    const [verificable02ErrorText, setVerificable02ErrorText] = useState("");
    const [confirmed01, setConfirmed01] = useState(false);
    const [confirmed02, setConfirmed02] = useState(false);
    const [circularProgress, setCircularProgress] = useState(false);
    const [circularProgressSending, setCircularProgressSending] = useState(false);
    const [isEmail, setIsEmail] = useState(false);
    const [reCaptchaToken, setReCaptchaToken] = useState(null);
    const [reCaptchaError, setReCaptchaError] = useState("");
    const [reCaptchaRef, setReCaptchaRef] = useState(null);

    useEffect(() => {
        if (firstInfo) {
            let auxVerificableData = [];
            let auxEmails = verificableData.userEmail.filter((email) => email.idConTypeVerification >= verificatedValues.email);
            let auxPhones = verificableData.userPhone.filter((phone) => phone.idConTypeVerification >= verificatedValues.phone);
            auxEmails.forEach((email) => {
                auxVerificableData.push(email.email);
            });
            auxPhones.forEach((phone) => {
                let auxCountry = countryList.find((country) => country.id === phone.idCountry);
                let auxPhone = auxCountry.phonePrefix + "-" + phone.phone;
                auxVerificableData.push(auxPhone);
            });
            setVerificableDataList(auxVerificableData);
            let auxVerificable = auxVerificableData[0];
            let isEmail = checkIsEmail(auxVerificable);
            setIsEmail(isEmail);
            setVerificable01(auxVerificable);
        };
    }, [firstInfo]);

    useEffect(() => {
        if (!checkIsNullUndefined(verificable02)) {
            checkValidity(verificable02);
        };
    }, [verificable02]);

    useEffect(() => {
        if (confirmed02) {
            let auxVerificable = verificable02;
            let isEmail = checkIsEmail(auxVerificable);
            if (!isEmail) {
                auxVerificable = country.phonePrefix + "-" + auxVerificable;
            };
            setSecureItem(secureValues.copyUser, auxVerificable);
            setIsEmail(false);
            setOpenUnification(true);
        };
    }, [confirmed02]);

    useEffect(() => {
        if (!confirmed01 && verificableDataList.length > 0) {
            let auxVerificable = verificableDataList[0];
            let isEmail = checkIsEmail(auxVerificable);
            setIsEmail(isEmail);
            setVerificable01(auxVerificable);
        };
    }, [confirmed01]);

    const checkIsEmail = (value) => {
        return value.includes("@");
    }

    const handleChangeVerificable01 = (value) => {
        let auxIsEmail = checkIsEmail(value);
        setIsEmail(auxIsEmail);
        setVerificable01(value);
    };

    const handleChangeVerificable02 = (value) => {
        let auxIsEmail = checkIsEmail(value);
        setIsEmail(auxIsEmail);
        setVerificable02(value);
    };

    const handleSend = (step) => {
        let auxVerificable = confirmed01 ? verificable02 : verificable01;
        let auxIsEmail = checkIsEmail(auxVerificable);
        if (!auxIsEmail || showPhoneSelector) {
            if (step === 1 && sended01) {
                setSended01(false);
            } else if (step === 2 && sended02) {
                setSended02(false);
            } else {
                handleSend2();
            };
        } else {
            handleSend2();
        };
    };

    const handleSend2 = () => {
        setCircularProgressSending(true);
        let auxVerificable = confirmed01 ? verificable02 : verificable01;
        let auxIsEmail = checkIsEmail(auxVerificable);
        if (!auxIsEmail && confirmed01) {
            auxVerificable = country.phonePrefix + "-" + auxVerificable;
        };
        let auxCall = auxIsEmail ? PostVerificationSendEmail : PostVerificationSendPhone;
        let auxSetSended = confirmed01 ? setSended02 : setSended01;
        auxCall(auxVerificable, reCaptchaToken).then((response) => {
            if (response && !response.Status) {
                auxSetSended(true);
            } else {
                //Alerta
                finishOperation("error", getErrorMessage(response, t));
                auxSetSended(false);
            };
            setCircularProgressSending(false);
            reCaptchaRef?.reset();
            setReCaptchaToken(null);
        });
    };

    const handleCancel = () => {
        history.push("Index/" + EnumPages.UserVerifications);
    };

    const handleValidate = () => {
        setCircularProgress(true);
        let auxVerificable = confirmed01 ? verificable02 : verificable01;
        let auxIsEmail = checkIsEmail(auxVerificable);
        if (!auxIsEmail && confirmed01) {
            auxVerificable = country.phonePrefix + "-" + auxVerificable;
        };
        let auxCall = auxIsEmail ? PostVerificationValidationOtpEmail : PostVerificationValidationOtpPhone;
        let auxConfirmed = confirmed01 ? setConfirmed02 : setConfirmed01;
        auxCall(auxVerificable, otp01).then((response) => {
            if (response && !response.Status) {
                auxConfirmed(true);
                setOtp01("");
            } else {
                //Alerta
                let auxType = auxIsEmail ? t("email") : t("phone");
                auxType = auxType.toLowerCase();
                let auxText = t("goToUnifyAccountsOtpError").replace("{dataType}", auxType);
                setOtp01Error(true);
                setOtp01ErrorText(auxText);
                finishOperation("error", getErrorMessage(response, t));
            };
            setCircularProgress(false);
        });
    };

    // Hacer que valga para las 2 otps
    const handleChangeOtp = (value) => {
        const re = /^[0-9\b]{1,12}$/;
        if (value === "" || re.test(value)) {
            setOtp01(value);
            setOtp01Error(false);
            setOtp01ErrorText("");
        };
    };

    const handleSwitchType = (emailSelected) => {
        handleClear();
        setShowPhoneSelector(!emailSelected);
    };

    const handleSelectorChange = (country) => {
        let auxText = t("loginFormatError");
        setCountry(country);
        if (verificable02 === "" || validatePhoneNumber(verificable02, country, t)) {
            auxText = "";
        };
        setVerificable02Error(!checkIsNullUndefined(auxText));
        setVerificable02ErrorText(auxText);
    };

    const checkValidity = (value) => {
        let auxText = t("loginFormatError");
        if (showPhoneSelector) {
            if (value === "" || validatePhoneNumber(value, country, t)) {
                auxText = "";
            };
        } else {
            if (value === "" || checkIsValidEmail(value)) {
                auxText = "";
            };
        };
        setVerificable02Error(!checkIsNullUndefined(auxText));
        setVerificable02ErrorText(auxText);
    };

    const checkIsValidEmail = (value) => {
        return validator.validate(value);
    };

    const handleDisabled = () => {
        let auxVerificable = confirmed01 ? verificable02 : verificable01;
        return checkIsNullUndefined(auxVerificable) || checkIsNullUndefined(otp01);
    };

    const handleClear = () => {
        setOtp01("");
        setOtp01Error(false);
        setOtp01ErrorText("");
        setVerificable02("");
        setSended02(false);
        setVerificable02Error(false);
        setVerificable02ErrorText("");
    };

    const handleOnChangeReCaptcha = (value) => {
        let auxRecaptchaToken = checkIsNullUndefined(value) ? null : value;
        if (!checkIsNullUndefined(value)) {
            setReCaptchaError("");
        };
        setReCaptchaToken(auxRecaptchaToken);
    };

    const checkDisabled = (step) => {
        let auxVerificable = step === 2 ? verificable02 : verificable01;
        let auxSended = step === 2 ? sended02 : sended01;
        let auxIsEmail = checkIsEmail(auxVerificable);
        let auxDisabled = false;
        if (checkIsNullUndefined(auxVerificable)) {
            auxDisabled = true;
        } else if ((!auxIsEmail || showPhoneSelector) && (checkIsNullUndefined(reCaptchaToken) && !auxSended)) {
            auxDisabled = true;
        };
        return auxDisabled;
    };

    const checkShowReCaptcha = (step) => {
        let auxShow = false;
        if (!isEmail && step === 1 || showPhoneSelector && step === 2) {
            if (!confirmed01 && !sended01) {
                auxShow = true;
            } else if (confirmed01 && !sended02) {
                auxShow = true;
            };
        };
        return auxShow;
    };

    const recaptchaBlock = () => {
        return (
            <Grid
                item
                container
                justifyContent="center"
                xs={12}
                className={commonClasses.gridIdentity}
                style={{ marginBottom: "1em" }}
            >
                <Grid style={{ display: 'inline-block', marginTop: '2em' }}>
                    <ReCAPTCHA
                        ref={(r) => setReCaptchaRef(r)}
                        sitekey={process?.env?.REACT_APP_RECAPTCHA_KEY}
                        onChange={handleOnChangeReCaptcha}
                        size={getScreenWidth() > 440 ? "normal" : "compact"} />

                    {checkIsNullUndefined(reCaptchaError) ? null : (
                        <p style={{ color: "#f32735", fontSize: "80% !important", textAlign: "start" }}>{reCaptchaError}</p>
                    )}
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            <Container className={commonClasses.dialogTitle}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4" className={commonClasses.dialogTitleText}>
                            {confirmed01 ? t("goToUnifyAccountsTitle2") : t("goToUnifyAccountsTitle1")}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Container className={commonClasses.dialogContainer}>
                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12} >
                        <Typography variant="h6" className={commonClasses.dialogTitleText} >
                            {confirmed01 ? t("goToUnifyAccountsText2") : t("goToUnifyAccountsText1")}
                        </Typography>
                    </Grid>
                    {confirmed01 ? (
                        <>
                            <Grid item xs={12} style={{ margin: "1em" }}>
                                <SelectorSwitch textA={t("UserEmail")} textB={t("registerPagePhone")} selectedA={!showPhoneSelector} handleSwitch={handleSwitchType} size={Size.medium} />
                            </Grid>
                            {showPhoneSelector ? (
                                <Grid item sm={4} xs={12} className={commonClasses.gridIdentity}>
                                    <PrefixPhone handleSelectorChange={handleSelectorChange} isLogin={true} masterCountryList={countryList} />
                                </Grid>
                            ) : null}
                            <Grid item sm={showPhoneSelector ? 5 : 9} xs={9}>
                                <TextField
                                    color="secondary"
                                    defaultValue={verificable02}
                                    value={verificable02}
                                    onInput={(e) => handleChangeVerificable02(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    name="otpEmail"
                                    autoFocus
                                    label={showPhoneSelector ? t("phone") : t("email")}
                                    type="text"
                                    error={verificable02error}
                                    helperText={verificable02ErrorText}
                                    required
                                    className={commonClasses.inputsStyle}
                                />
                            </Grid>
                            <Grid item xs={3} className={classes.buttonAdd}>
                                <CorreosButton
                                    variant={sended02 ? "outlined" : "contained"}
                                    color={sended02 ? "secondary" : "primary"}
                                    onClick={() => handleSend(2)}
                                    disabled={checkDisabled(2)}
                                    fullWidth
                                    circularProgress={circularProgressSending}
                                >
                                    {sended02 ? t("reSend") : t("send")}
                                </CorreosButton>
                            </Grid>
                            {checkShowReCaptcha(2) && (
                                recaptchaBlock()
                            )}
                            {sended02 && (
                                <Grid item xs={12}>
                                    <TextField
                                        color="secondary"
                                        defaultValue={otp01}
                                        value={otp01}
                                        onChange={(e) => handleChangeOtp(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        name="otpEmail"
                                        autoFocus
                                        label={t("lOtp")}
                                        type="text"
                                        error={otp01Error}
                                        helperText={otp01ErrorText}
                                        required
                                        className={commonClasses.inputsStyle}
                                    />
                                </Grid>
                            )}
                        </>
                    ) : (
                        <>
                            <Grid item xs={9}>
                                <FormControl variant="filled" fullWidth color="secondary" className={commonClasses.inputsStyle}>
                                    <InputLabel label={t("goToUnifyAccountsData")} color="secondary">
                                        {t("goToUnifyAccountsData")}
                                    </InputLabel>
                                    <Select
                                        native
                                        color="secondary"
                                        value={verificable01}
                                        onChange={(e) => handleChangeVerificable01(e.target.value)}
                                        label={t("goToUnifyAccountsData")}
                                    >
                                        {verificableDataList.map((auxData) => {
                                            return (
                                                <option key={auxData} value={auxData}>
                                                    {auxData}
                                                </option>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} className={classes.buttonAdd}>
                                <CorreosButton
                                    variant={sended01 ? "outlined" : "contained"}
                                    color={sended01 ? "secondary" : "primary"}
                                    onClick={() => handleSend(1)}
                                    disabled={checkDisabled(1)}
                                    fullWidth
                                    circularProgress={circularProgressSending}
                                >
                                    {sended01 ? t("reSend") : t("send")}
                                </CorreosButton>
                            </Grid>
                            {checkShowReCaptcha(1) && (
                                recaptchaBlock()
                            )}
                            {sended01 && (
                                <Grid item xs={12}>
                                    <TextField
                                        color="secondary"
                                        defaultValue={otp01}
                                        value={otp01}
                                        onChange={(e) => handleChangeOtp(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        name="otpEmail"
                                        autoFocus
                                        label={t("lOtp")}
                                        type="text"
                                        error={otp01Error}
                                        helperText={otp01ErrorText}
                                        required
                                        className={commonClasses.inputsStyle}
                                    />
                                </Grid>
                            )}
                        </>
                    )}
                    <Grid
                        item
                        container
                        xs={12}
                        className={commonClasses.dialogButtonContainer}
                    >
                        <Grid item xs={6}>
                            <CorreosButton
                                onClick={handleCancel}
                                className={commonClasses.dialogButtonLeft}
                            >
                                {t("cancel")}
                            </CorreosButton>
                        </Grid>
                        <Grid item xs={6}>
                            <CorreosButton
                                variant="contained"
                                color="primary"
                                onClick={handleValidate}
                                className={commonClasses.dialogButtonRight}
                                disabled={handleDisabled()}
                                circularProgress={circularProgress}
                            >
                                {t("confirm")}
                            </CorreosButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};