import {createTheme, responsiveFontSizes} from "@material-ui/core/styles";
import Cartero from "../assets/fonts/Cartero-Regular.ttf";

const cartero = {
  fontFamily: 'Cartero-Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Cartero'),
    local('Cartero-Regular'),
    url(${Cartero}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

let theme = createTheme({
  typography: {
    fontFamily: "Cartero-Regular",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [cartero],
      },
    },
  },
  palette: {
    primary: {
      light: "#ffcd00",
      main: "#ffcd00",
      dark: "#ffcd00",
      contrastText: "#002453",
      error: "#F32735",
    },
    secondary: {
      light: "#002453",
      main: "#002453",
      dark: "#002453",
      contrastText: "#002453",
      error: "#F32735",
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
