import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { MainPageStyles } from "./MainPageStyles";
import { Header } from "../../components/Header/Header";
import { SideMenu } from "../../components/SideMenu/SideMenu";
import { Main } from "../../components/Main/Main";
import { useParams } from "react-router-dom";
import { EnumPages } from "../../commons/EnumPages";
import { UserServices } from "../../components/UserServices/UserServices";
import { EnterpriseServices } from "../../components/EnterpriseServices/EnterpriseServices";
import { EnterprisePrivacy } from "../../components/EnterprisePrivacy/EnterprisePrivacy";
import { UserVerifications } from "../../components/UserVerifications/UserVerifications";
import { UserPrivacy } from "../../components/UserPrivacy/UserPrivacy";
import { UserCancelAccount } from "../../components/UserCancelAccount/UserCancelAccount";
import { EnterpriseVerifications } from "../../components/EnterpriseVerifications/EnterpriseVerifications";
import { EnterpriseDelete } from "../../components/EnterpriseDelete/EnterpriseDelete";
import { EnterpriseTeam } from "../../components/EnterpriseTeam/EnterpriseTeam";
import { Alerts } from "../../components/Alerts/Alerts";
import {
  getReplaceMode,
  setSessionItem,
} from "../../services/Commons";
import { RequiredData } from "../../components/RequiredData/RequiredData";
import { CommonStyles } from "../../commons/CommonStyles";
import { finishGlobalOperation } from "../../commons/Utils";

export function MainPage(props) {
  const commonClasses = CommonStyles();
  const classes = MainPageStyles();
  const { idPage, idEnterprise } = useParams();
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [updateEnterprises, setUpdateEnterprises] = useState(false);
  const [updateUser, setUpdateUser] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [resetPage, setResetPage] = useState(false);
  const [resetAlert, setResetAlert] = useState(false);
  const [isSpecialUser, setIsSpecialUser] = useState(false);

  useEffect(() => {
    setResetAlert(!resetAlert);
  }, [idPage, resetPage]);

  const finishOperation = (color, message, auxOpen) => {
    finishGlobalOperation(setAlert, color, message, auxOpen);
  };

  const handleShowSideMenu = () => {
    const flag = showSideMenu;
    setShowSideMenu(!flag);
  };

  const renderPage = () => {

    setSessionItem("2FA", "false");

    switch (idPage) {
      case EnumPages.UserServices:
        return <UserServices showSideMenu={showSideMenu}></UserServices>;
      case EnumPages.EnterpriseServices:
        return (
          <EnterpriseServices
            showSideMenu={showSideMenu}
            idEnterprise={idEnterprise}
            setUpdateEnterprises={setUpdateEnterprises}
          ></EnterpriseServices>
        );
      case EnumPages.EnterprisePrivacy:
        return (
          <EnterprisePrivacy
            showSideMenu={showSideMenu}
            idEnterprise={idEnterprise}
          ></EnterprisePrivacy>
        );
      case EnumPages.EnterpriseDelete:
        return (
          <EnterpriseDelete
            idEnterprise={idEnterprise}
            setUpdateEnterprises={setUpdateEnterprises}
            showSideMenu={showSideMenu}
          ></EnterpriseDelete>
        );
      case EnumPages.UserVerifications:
        return (
          <UserVerifications showSideMenu={showSideMenu}></UserVerifications>
        );
      case EnumPages.UserData:
        return (
          <RequiredData
            isRegistry={false}
            showSideMenu={showSideMenu}
            setUpdateUser={setUpdateUser}
            forUser={true}
            forEnterprise={false}
            onlyMandatories={false}
            mainPage={true}
            resetAlert={resetAlert}
            forceResetPage={resetPage}
            finishOperation={finishOperation}
          />
        );
      case EnumPages.UserPrivacy:
        return <UserPrivacy showSideMenu={showSideMenu}></UserPrivacy>;
      case EnumPages.UserCancelAccount:
        return (
          <UserCancelAccount showSideMenu={showSideMenu}></UserCancelAccount>
        );
      case EnumPages.EnterpriseData:
        return (
          <RequiredData
            isRegistry={false}
            showSideMenu={showSideMenu}
            setUpdateEnterprises={setUpdateEnterprises}
            idEnterprise={idEnterprise}
            forUser={false}
            forEnterprise={true}
            onlyMandatories={false}
            mainPage={true}
            resetAlert={resetAlert}
            forceResetPage={resetPage}
            finishOperation={finishOperation}
          />
        );
      case EnumPages.EnterpriseCreate:
        return (
          <RequiredData
            isRegistry={false}
            showSideMenu={showSideMenu}
            setUpdateEnterprises={setUpdateEnterprises}
            forUser={false}
            forEnterprise={true}
            onlyMandatories={false}
            mainPage={true}
            resetAlert={resetAlert}
            isCreate={true}
            forceResetPage={resetPage}
            finishOperation={finishOperation}
          />
        );
      case EnumPages.EnterpriseTeam:
        return (
          <EnterpriseTeam
            showSideMenu={showSideMenu}
            idEnterprise={idEnterprise}
          ></EnterpriseTeam>
        );
      case EnumPages.EnterpriseVerifications:
        return (
          <EnterpriseVerifications
            showSideMenu={showSideMenu}
            idEnterprise={idEnterprise}
            setUpdateEnterprises={setUpdateEnterprises}
          ></EnterpriseVerifications>
        );
      case EnumPages.AuxUserData:
        return (
          <RequiredData
            isRegistry={false}
            showSideMenu={showSideMenu}
            setUpdateUser={setUpdateUser}
            forUser={true}
            forEnterprise={false}
            onlyMandatories={false}
            mainPage={true}
            resetAlert={resetAlert}
            auxiliarTab={true}
            forceResetPage={resetPage}
            finishOperation={finishOperation}
          />
        );
      case EnumPages.AuxEnterpriseData:
        return (
          <RequiredData
            isRegistry={false}
            showSideMenu={showSideMenu}
            setUpdateEnterprises={setUpdateEnterprises}
            idEnterprise={idEnterprise}
            forUser={false}
            forEnterprise={true}
            onlyMandatories={false}
            mainPage={true}
            resetAlert={resetAlert}
            auxiliarTab={true}
            forceResetPage={resetPage}
            finishOperation={finishOperation}
          />
        );
      default:
        return <Main showSideMenu={showSideMenu} isSpecialUser={isSpecialUser}></Main>;
    }
  };

  return (
    <Grid className={classes.mainDiv}>
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      <Header setShowSideMenu={handleShowSideMenu} />
      {getReplaceMode() != "true" ?
        <SideMenu
          showSideMenu={showSideMenu}
          setShowSideMenu={handleShowSideMenu}
          updateEnterprises={updateEnterprises}
          setUpdateEnterprises={setUpdateEnterprises}
          updateUser={updateUser}
          setUpdateUser={setUpdateUser}
          resetPage={resetPage}
          setResetPage={setResetPage}
          setIsSpecialUser={setIsSpecialUser}
          isSpecialUser={isSpecialUser}
        /> : null}
      <Grid className={
        !showSideMenu ? commonClasses.container : commonClasses.hideContainer
      }>
        {renderPage()}
      </Grid>
    </Grid>
  );
}
