import { makeStyles } from "@material-ui/core/styles";

export const RegisterDataStyles = makeStyles((theme) => ({
  underline: {
    "& .MuiInputBase-input": {
      "&:before": {
        border: "1px solid #FFCD00",
      },
      "&:hover:before": {
        border: "2px solid #FFCD00",
      },
      "&:after": {
        border: "2px solid #002453",
      },
    },
  },
  gridIdentity: {
    display: "flex",
  },
  errorText: {
    fontSize: "80% !important",
    color: "#f32735",
  },
  linkDecoration: {
    textDecoration: "none",
    fontWeight: "bold",
    color: "#333333",
    "&:hover": { textDecoration: "none", fontWeight: "bold", color: "#333333" },
  },
  root: {
    "& .MuiFilledInput-root": {
      backgroundColor: "#F2F2F2",
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
      "&:hover": { backgroundColor: "#cbcbcb" },
    }
  }
}));
