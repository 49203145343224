import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getSessionItem } from "../../services/Commons";
import history from "../../services/history";
import { sessionValues } from "../../commons/EnumsGeneral";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      history={history}
      render={(props) =>
        getSessionItem(sessionValues.logged) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/Login" />
        )
      }
    />
  );
};

export default PrivateRoute;
