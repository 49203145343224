import React from "react";
import {
  Grid,
  Container,
  Card,
  Typography,
} from "@material-ui/core";
import { TyCStyles } from "./TyCStyles";
import { useTranslation } from "react-i18next";

export function PrivacyPolicyTyC(props) {
  const classes = TyCStyles();
  const { t, i18n } = useTranslation();

  return (
    <Grid
      className={
        !props.showSideMenu ? classes.container : classes.hideContainer
      }
    >
      <Container maxWidth={"lg"} className={classes.mainContainer}>
        <Card className={classes.cardContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography align="justify" className={classes.text}>
                <div id="Top" />
                <p>{t("tycPrivacyPoliticyTyCText1")}</p>
                <p>
                  {t("tycPrivacyPoliticyTyCText2_1")}

                  <b>
                    <a href="id.correos.post" className={classes.links}>
                      {t("tycPrivacyPoliticyTyCText2_B1")}
                    </a>
                  </b>
                  {t("tycPrivacyPoliticyTyCText2_2")}

                  <b>
                    <a
                      href="emiidentidad.correos.post"
                      className={classes.links}
                    >
                      {t("tycPrivacyPoliticyTyCText2_B2")}
                    </a>
                  </b>
                  {t("tycPrivacyPoliticyTyCText2_3")}
                </p>
                <p>{t("tycPrivacyPoliticyTyCText3")}</p>
                <a id="Id1" />
                <h4 className={classes.subtitles}>
                  {t("tycPrivacyPoliticyTyCSubtittle1")}
                </h4>
                <p>{t("tycPrivacyPoliticyTyCText4")}</p>
                <p>
                  {t("tycPrivacyPoliticyTyCText5")}
                  <b>
                    <a
                      href="mailto:dpdgrupocorreos@correos.com"
                      className={classes.links}
                    >
                      dpdgrupocorreos@correos.com.
                    </a>
                  </b>
                </p>
                <h4 className={classes.subtitles}>
                  {t("tycPrivacyPoliticyTyCSubtittle2")}
                </h4>
                <p>{t("tycPrivacyPoliticyTyCText6")}</p>
                <ol type="A">
                  <b>
                    <li>{t("tycPrivacyPoliticyTyCListTittle1")}</li>
                  </b>
                  <p> {t("tycPrivacyPoliticyTyCText7")}</p>
                  <ol type="I">
                    <li>
                      <p>{t("tycPrivacyPoliticyTyCText8")}</p>
                      <p>{t("tycPrivacyPoliticyTyCText9")}</p>
                    </li>
                    <li>
                      <p>{t("tycPrivacyPoliticyTyCText10")}</p>
                      <p>{t("tycPrivacyPoliticyTyCText11")}</p>
                      <p>{t("tycPrivacyPoliticyTyCText12")}</p>
                    </li>
                    <li>
                      <p>{t("tycPrivacyPoliticyTyCText13")} </p>
                    </li>
                    <li>
                      <p>{t("tycPrivacyPoliticyTyCText14")}</p>
                      <p>{t("tycPrivacyPoliticyTyCText15")}</p>
                    </li>
                  </ol>
                  <b>
                    <li>{t("tycPrivacyPoliticyTyCListTittle2")}</li>
                  </b>
                  <p>{t("tycPrivacyPoliticyTyCText16")}</p>
                  <p>{t("tycPrivacyPoliticyTyCText17")}</p>
                  <b>
                    <li>{t("tycPrivacyPoliticyTyCListTittle3")}</li>
                  </b>
                  <p>{t("tycPrivacyPoliticyTyCText18")}</p>
                  <ul>
                    <li>{t("tycPrivacyPoliticyTyCText19")}</li>
                    <li>{t("tycPrivacyPoliticyTyCText20")}</li>
                  </ul>
                  <p>{t("tycPrivacyPoliticyTyCText21")}</p>
                  <b>
                    <li>{t("tycPrivacyPoliticyTyCListTittle4")}</li>
                  </b>
                  <p>{t("tycPrivacyPoliticyTyCText22")}</p>
                  <b>
                    <li>{t("tycPrivacyPoliticyTyCListTittle5")}</li>
                  </b>
                  <p>{t("tycPrivacyPoliticyTyCText23")}</p>
                  <p>{t("tycPrivacyPoliticyTyCText24")}</p>
                  <b>
                    <li>{t("tycPrivacyPoliticyTyCListTittle6")}</li>
                  </b>
                  <p>{t("tycPrivacyPoliticyTyCText25")}</p>
                  <p>{t("tycPrivacyPoliticyTyCText26")}</p>
                  <ul>
                    <li>
                      <p>{t("tycPrivacyPoliticyTyCText27")}</p>
                      <p>{t("tycPrivacyPoliticyTyCText28")}</p>
                    </li>
                    <li>
                      <p>{t("tycPrivacyPoliticyTyCText29")}</p>
                    </li>
                  </ul>
                  <b>
                    <li>{t("tycPrivacyPoliticyTyCListTittle7")}</li>
                  </b>
                  <p>{t("tycPrivacyPoliticyTyCText30")}</p>
                </ol>

                <h4 className={classes.subtitles}>
                  {t("tycPrivacyPoliticyTyCSubtittle3")}
                </h4>
                <p>{t("tycPrivacyPoliticyTyCText31")}</p>
                <ol type="A">
                  <b>
                    <li>
                      <p>{t("tycPrivacyPoliticyTyCListTittle8")}</p>
                    </li>
                  </b>
                  <p>{t("tycPrivacyPoliticyTyCText32")}</p>
                  <p>{t("tycPrivacyPoliticyTyCText33")}</p>
                  <p>{t("tycPrivacyPoliticyTyCText34")}</p>
                  <b>
                    <li> {t("tycPrivacyPoliticyTyCListTittle9")}</li>
                  </b>
                  <p>
                    {t("tycPrivacyPoliticyTyCText35_1")}
                    <b>
                      <a href="#Id1" className={classes.links}>
                        {t("tycPrivacyPoliticyTyCText35_B")}
                      </a>
                    </b>
                  </p>
                  <p>{t("tycPrivacyPoliticyTyCText36")}</p>
                  <b>
                    <li>{t("tycPrivacyPoliticyTyCListTittle10")}</li>
                  </b>
                  <p>{t("tycPrivacyPoliticyTyCText37")}</p>
                </ol>

                <h4 className={classes.subtitles}>
                  {t("tycPrivacyPoliticyTyCSubtittle4")}
                </h4>

                <p>{t("tycPrivacyPoliticyTyCText38")}</p>
                <p>{t("tycPrivacyPoliticyTyCText39")}</p>
                <p>{t("tycPrivacyPoliticyTyCText40")}</p>

                <h4 className={classes.subtitles}>
                  {t("tycPrivacyPoliticyTyCSubtittle5")}
                </h4>
                <p>{t("tycPrivacyPoliticyTyCText41")}</p>
                <ul type="none">
                  <li>{t("tycPrivacyPoliticyTyCText42")}</li>
                  <li>{t("tycPrivacyPoliticyTyCText43")}</li>
                  <li>{t("tycPrivacyPoliticyTyCText44")}</li>
                  <li>{t("tycPrivacyPoliticyTyCText45")}</li>
                  <li>{t("tycPrivacyPoliticyTyCText46")}</li>
                  <li>{t("tycPrivacyPoliticyTyCText47")}</li>
                  <li>{t("tycPrivacyPoliticyTyCText48")}</li>
                </ul>
                <p>{t("tycPrivacyPoliticyTyCText49")}</p>
                <ul type="none">
                  <li>{t("tycPrivacyPoliticyTyCText50")}</li>
                  <li>
                    {t("tycPrivacyPoliticyTyCText51")}
                    <b>
                      <a
                        href="mailto:derechos.protecciondatos.correos@correos.com"
                        className={classes.links}
                      >
                        derechos.protecciondatos.correos@correos.com.
                      </a>
                    </b>
                  </li>
                </ul>
                <p>{t("tycPrivacyPoliticyTyCText52")}</p>
                <p>{t("tycPrivacyPoliticyTyCText53")}</p>

                <h4 className={classes.subtitles}>
                  {t("tycPrivacyPoliticyTyCSubtittle6")}
                </h4>
                <p>{t("tycPrivacyPoliticyTyCText54")}</p>
                <p>{t("tycPrivacyPoliticyTyCText55")}</p>
                <p>{t("tycPrivacyPoliticyTyCText56")}</p>
                <p>{t("tycPrivacyPoliticyTyCText57")}</p>
                <ul>
                  <li>{t("tycPrivacyPoliticyTyCText58")}</li>
                  <li>{t("tycPrivacyPoliticyTyCText59")}</li>
                  <li>{t("tycPrivacyPoliticyTyCText60")}</li>
                  <li>{t("tycPrivacyPoliticyTyCText61")}</li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Grid>
  );
}
