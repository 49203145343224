import React, { useEffect, useState } from "react";
import {
    Typography,
    Container,
    Dialog,
    Grid,
    TextField,
    CardMedia,
    FormControl,
    InputLabel,
    Select,
} from "@material-ui/core";
import { CommonStyles } from "../../commons/CommonStyles";
import { DialogStyles } from "../CommonComponents/Dialogs/DialogStyles";
import { UserVerificationsStyles } from "../UserVerifications/UserVerificationsStyles";
import { checkIsNullUndefined, getErrorMessage, validatePhone, getScreenWidth } from "../../commons/Utils";
import {
    PostVerificationSendPhone,
    PostVerificationDataPhone,
    PostVerificationSendEmail,
    PostVerificationValidationOtpEmail,
} from "../../services/ReasonService";
import { PrefixPhone } from "../PrefixPhone/PrefixPhone";
import { useTranslation } from "react-i18next";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { EnumCountries } from "../../commons/EnumCountries";
import subscriptionOkImage from "../../assets/images/subok.svg";
import { userPhone } from "../../commons/ConstantObjects";
import { AttributeCodes, Attributes } from "../../commons/Attributes";
import { GeneralWarning } from "../CommonComponents/GeneralWarning/GeneralWarning";
import { generalWarning } from "../../commons/EnumsGeneral";
import ReCAPTCHA from "react-google-recaptcha";

export function Dialog2FASetPhone(props) {

    const { emails, addVerificable } = props;

    const commonClasses = CommonStyles();
    const dialogClasses = DialogStyles();
    const classes = UserVerificationsStyles();
    const { t, i18n } = useTranslation();

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorEmailText, setErrorEmailText] = useState("");
    const [sendedEmail, setSendedEmail] = useState(false);
    const [phone, setPhone] = useState("");
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorPhoneText, setErrorPhoneText] = useState("");
    const [sendedPhone, setSendedPhone] = useState(false);
    const [otpEmail, setOtpEmail] = useState("");
    const [otpEmailError, setOtpEmailError] = useState(false);
    const [otpEmailErrorText, setOtpEmailErrorText] = useState("");
    const [otpPhone, setOtpPhone] = useState("");
    const [otpPhoneError, setOtpPhoneError] = useState(false);
    const [otpPhoneErrorText, setOtpPhoneErrorText] = useState("");
    const [country, setCountry] = useState({
        code: EnumCountries.ESP,
        id: 67,
        phonePrefix: 34,
    });
    const [phoneExists, setPhoneExists] = useState(false);
    const [circularProgress, setCircularProgress] = useState(false);
    const [circularProgressSending, setCircularProgressSending] = useState(false);
    const [isEmailFinished, setIsEmailFinished] = useState(false);
    const [isPhoneFinished, setIsPhoneFinished] = useState(false);
    const [openWarnDialog, setOpenWarnDialog] = useState(false);
    const [reCaptchaToken, setReCaptchaToken] = useState(null);
    const [reCaptchaError, setReCaptchaError] = useState("");
    const [reCaptchaRef, setReCaptchaRef] = useState(null);

    useEffect(() => {
        setEmail(emails[0]);
    }, []);

    useEffect(() => {
        clearAll();
    }, [phone, country]);

    useEffect(() => {
        if (isPhoneFinished) {
            setTimeout(closePopup, 5000);
        };
    }, [isPhoneFinished]);

    const closePopup = () => {
        setOpen(false);
    };

    const clearAll = () => {
        setErrorPhone(false);
        setErrorPhoneText("");
        setSendedPhone(false);
        setOtpPhone("");
        setOtpPhoneError(false);
        setOtpPhoneErrorText("");
        setPhoneExists(false);
    };

    const handleSend = () => {
        setCircularProgressSending(true);
        let auxError = validatePhoneNumber();
        if (!auxError.error) {
            sendVerificationPhone(auxError);
        } else {
            setErrorPhone(auxError.error);
            setErrorPhoneText(auxError.errorText);
            setCircularProgressSending(false);
        };
    };

    const sendVerificationEmail = () => {
        let auxError = { error: false, errorText: "" };
        setCircularProgressSending(true);
        PostVerificationSendEmail(email).then((response) => {
            if (response && !response.Status) {
                auxError.error = false;
                auxError.errorText = "";
                setSendedEmail(true);
            } else {
                auxError.error = true;
                auxError.errorText = getErrorMessage(response, t);
                setSendedEmail(false);
            };
            setErrorEmail(auxError.error);
            setErrorEmailText(auxError.errorText);
            setCircularProgressSending(false);
        });
    };

    const sendVerificationPhone = (auxError) => {
        if (sendedPhone) {
            setSendedPhone(false);
            setCircularProgressSending(false);
        } else {
            PostVerificationSendPhone(country.phonePrefix + "-" + phone, reCaptchaToken).then((response) => {
                if (response && !response.Status) {
                    auxError.error = false;
                    auxError.errorText = "";
                    setSendedPhone(true);
                } else {
                    auxError.error = true;
                    auxError.errorText = getErrorMessage(response, t);
                    setSendedPhone(false);
                };
                setErrorPhone(auxError.error);
                setErrorPhoneText(auxError.errorText);
                setCircularProgressSending(false);
                reCaptchaRef?.reset();
                setReCaptchaToken(null);
            });
        };
    };

    const handleSelectorChange = (value) => {
        setCountry(value);
    };

    const validatePhoneNumber = () => {
        let auxError = { error: false, errorText: "" };

        if (checkIsNullUndefined(phone)) {
            auxError.error = false;
            auxError.errorText = "";
        } else if (phone.toString().length === 1) {
            auxError.error = true;
            auxError.errorText = t("registerPagePhoneErrorShort");
        } else if (country.id === 67 && phone.toString().length > 9) {
            auxError.error = true;
            auxError.errorText = t("registerPagePhoneErrorLong");
        } else if (phone.toString().length > 1) {
            if (validatePhone(phone, country)) {
                auxError.error = false;
                auxError.errorText = "";
            } else {
                auxError.error = true;
                auxError.errorText = t("registerPagePhoneError");
            };
        };
        return auxError;
    };

    const handleCancel = () => {
        setPhone("");
        setCountry({ code: EnumCountries.ESP, id: 67, phonePrefix: 34 });
        setErrorPhone(false);
        setErrorPhoneText("");
        setOpen(false);
    };

    const handleChangeEmail = (value) => {
        setEmail(value.target.value);
    };

    const handleChangePhone = (value) => {
        const re = /^[0-9\b]{1,12}$/;
        if (value.target.value === "" || re.test(value.target.value)) {
            setPhone(value.target.value);
        };
    };

    const handleChangeEmailOtp = (value) => {
        const re = /^[0-9\b]{1,12}$/;
        if (value.target.value === "" || re.test(value.target.value)) {
            setOtpEmail(value.target.value);
            setOtpEmailError(false);
        };
    };

    const handleChangePhoneOtp = (value) => {
        const re = /^[0-9\b]{1,12}$/;
        if (value.target.value === "" || re.test(value.target.value)) {
            setOtpPhone(value.target.value);
            setOtpPhoneError(false);
        };
    };

    const handleValidateEmail = () => {
        setCircularProgress(true);
        PostVerificationValidationOtpEmail(email, otpEmail).then((response) => {
            if (response && !response.Status) {
                setCircularProgress(false);
                setIsEmailFinished(true);
            } else {
                setOtpEmailError(true);
                setOtpEmailErrorText(getErrorMessage(response, t));
            };
            setCircularProgress(false);
        });
    };

    const handleVerificatePhone = () => {
        setCircularProgress(true);
        PostVerificationDataPhone(country.phonePrefix + "-" + phone, otpPhone).then((response) => {
            if (response && !response.Status) {
                let auxVerificable = userPhone;
                let auxType = AttributeCodes.Phone;
                auxVerificable[Attributes[auxType].attributeName] = phone;
                auxVerificable.idCountry = country.id;
                addVerificable(auxVerificable, auxType, "add");
                setCircularProgress(false);
                setIsPhoneFinished(true);
            } else {
                setOpenWarnDialog(true);
            };
            setCircularProgress(false);
        });
    };

    const handleOnChangeReCaptcha = (value) => {
        let auxRecaptchaToken = checkIsNullUndefined(value) ? null : value;
        if (!checkIsNullUndefined(value)) {
            setReCaptchaError("");
        };
        setReCaptchaToken(auxRecaptchaToken);
    };

    const mountEmailVerification = () => {
        return <>
            <Container className={commonClasses.dialogTitle}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4" className={commonClasses.dialogTitleText}>
                            {t("verifications2FASetEmailTitle")}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Container className={commonClasses.dialogContainer}>
                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12} >
                        <Typography variant="h6" className={commonClasses.dialogTitleText} >
                            {t("verifications2FASetEmailConfText")}
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <FormControl variant="filled" fullWidth color="secondary" className={commonClasses.inputsStyle}>
                            <InputLabel label={t("PersonaEmail")} color="secondary">
                                {t("PersonaEmail")}
                            </InputLabel>
                            <Select
                                native
                                color="secondary"
                                value={email}
                                onChange={(value) => handleChangeEmail(value)}
                                label={t("PersonaEmail")}
                                error={errorEmail}
                                helperText={errorEmailText}
                            >
                                {emails.map((auxEmail) => {
                                    return (
                                        <option key={auxEmail} value={auxEmail}>
                                            {auxEmail}
                                        </option>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} className={classes.buttonAdd}>
                        <CorreosButton
                            variant={sendedEmail ? "outlined" : "contained"}
                            color={sendedEmail ? "secondary" : "primary"}
                            onClick={() => sendVerificationEmail()}
                            disabled={checkIsNullUndefined(email) || circularProgressSending}
                            fullWidth
                            circularProgress={circularProgressSending}
                        >
                            {sendedEmail ? t("reSend") : t("send")}
                        </CorreosButton>
                    </Grid>
                    {sendedEmail && (
                        <Grid item xs={12}>
                            <TextField
                                color="secondary"
                                defaultValue={otpEmail}
                                value={otpEmail}
                                onChange={(value) => handleChangeEmailOtp(value)}
                                variant="outlined"
                                fullWidth
                                name="otpEmail"
                                autoFocus
                                label={t("verifications2FASetEmailOtp")}
                                type="text"
                                error={otpEmailError}
                                helperText={otpEmailErrorText}
                                required
                                className={commonClasses.inputsStyle}
                            />
                        </Grid>
                    )}
                    <Grid
                        item
                        container
                        xs={12}
                        className={commonClasses.dialogButtonContainer}
                    >
                        <Grid item md={6} xs={12}>
                            <CorreosButton
                                onClick={handleCancel}
                                className={commonClasses.dialogButtonLeft}
                            >
                                {t("cancel")}
                            </CorreosButton>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CorreosButton
                                variant="contained"
                                color="primary"
                                onClick={handleValidateEmail}
                                className={commonClasses.dialogButtonRight}
                                disabled={!sendedEmail || otpEmailError || checkIsNullUndefined(otpEmail)}
                                circularProgress={circularProgress}
                            >
                                {t("confirm")}
                            </CorreosButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    };

    const mountPhoneVerification = () => {
        return <>
            <Container className={commonClasses.dialogTitle}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4" className={commonClasses.dialogTitleText}>
                            {t("verifications2FASetPhoneTitle")}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Container className={commonClasses.dialogContainer}>
                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12} >
                        <Typography variant="h6" className={commonClasses.dialogTitleText} >
                            {t("verifications2FASetPhoneText")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <PrefixPhone handleSelectorChange={handleSelectorChange} />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            color="secondary"
                            defaultValue={phone}
                            value={phone}
                            onChange={(value) => handleChangePhone(value)}
                            variant="outlined"
                            fullWidth
                            name="phone"
                            autoFocus
                            label={t("PersonaTelfMovil")}
                            type="text"
                            error={errorPhone}
                            helperText={errorPhone ? errorPhoneText : ""}
                            required
                            className={commonClasses.inputsStyle}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.buttonAdd}>
                        <CorreosButton
                            variant={sendedPhone ? "outlined" : "contained"}
                            color={sendedPhone ? "secondary" : "primary"}
                            onClick={() => handleSend()}
                            disabled={checkIsNullUndefined(phone) || errorPhone || phoneExists || circularProgressSending || (checkIsNullUndefined(reCaptchaToken) && !sendedPhone)}
                            circularProgress={circularProgressSending}
                            fullWidth
                        >
                            {sendedPhone ? t("reSend") : t("send")}
                        </CorreosButton>
                    </Grid>

                    {sendedPhone ? (
                        <Grid item xs={12}>
                            <TextField
                                color="secondary"
                                defaultValue={otpPhone}
                                value={otpPhone}
                                onChange={(value) => handleChangePhoneOtp(value)}
                                variant="outlined"
                                fullWidth
                                name="otpPhone"
                                autoFocus
                                label={t("verifications2FASetPhoneOtp")}
                                type="text"
                                error={otpPhoneError}
                                helperText={otpPhoneErrorText}
                                required
                                className={commonClasses.inputsStyle}
                            />
                        </Grid>
                    ) : (
                        <Grid
                            item
                            container
                            justifyContent="center"
                            xs={12}
                            className={commonClasses.gridIdentity}
                            style={{ marginBottom: "1em" }}
                        >
                            <Grid style={{ display: 'inline-block', marginTop: '2em' }}>
                                <ReCAPTCHA
                                    ref={(r) => setReCaptchaRef(r)}
                                    sitekey={process?.env?.REACT_APP_RECAPTCHA_KEY}
                                    onChange={handleOnChangeReCaptcha}
                                    size={getScreenWidth() > 440 ? "normal" : "compact"} />

                                {checkIsNullUndefined(reCaptchaError) ? null : (
                                    <p style={{ color: "#f32735", fontSize: "80% !important", textAlign: "start" }}>{reCaptchaError}</p>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    className={commonClasses.dialogButtonContainer}
                    spacing={1}
                >
                    <Grid item md={6} xs={12}>
                        <CorreosButton
                            onClick={handleCancel}
                            className={commonClasses.dialogButtonLeft}
                        >
                            {t("cancel")}
                        </CorreosButton>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <CorreosButton
                            variant="contained"
                            color="primary"
                            onClick={handleVerificatePhone}
                            className={commonClasses.dialogButtonRight}
                            disabled={!sendedPhone || otpPhoneError || checkIsNullUndefined(otpPhone)}
                            circularProgress={circularProgress}
                        >
                            {t("confirm")}
                        </CorreosButton>
                    </Grid>
                </Grid>
            </Container>
        </>
    };

    return (
        <>
            <GeneralWarning open={openWarnDialog} setOpen={setOpenWarnDialog} isEmail={false} warnType={generalWarning.verify} />
            <CorreosButton
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
                className={commonClasses.buttonLeftCenter}
            >
                {t("verifications2FASetPhone")}
            </CorreosButton>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={() => handleCancel()}
                PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
            >
                {!isEmailFinished ? mountEmailVerification() : null}
                {isEmailFinished && !isPhoneFinished ? mountPhoneVerification() : null}
                {isEmailFinished && isPhoneFinished ?
                    <Container className={commonClasses.dialogContainer}>
                        <Grid container item xs={12}>
                            <Grid item xs={12} className={commonClasses.textDiv}>
                                <CardMedia className={dialogClasses.mediaSubOk}>
                                    <img
                                        className={dialogClasses.imgCardMediaSubOk}
                                        src={subscriptionOkImage}
                                        alt="Logo"
                                    />
                                </CardMedia>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} className={commonClasses.mainButtonContainer}>
                            <Grid item xs={12} className={classes.textDivLeft}>
                                <Typography variant="h6">
                                    <span>{t("verifications2FASetPhonConfirmation")}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container> : null}
            </Dialog>
        </>
    );
};