import React, { useState, useRef } from "react";
import {
  Typography,
  Container,
  Grid,
  Paper,
  Popper,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { CommonStyles } from "../../../commons/CommonStyles";
import {
    DeleteVerificationDataEnterpriseAddress,
} from "../../../services/ReasonService";
import {
  getErrorMessage,
  useOutsideAlerter,
} from "../../../commons/Utils";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";

export function DialogDeleteDirection(props) {
  const { verificable, chargePageData, finishOperation } = props;
  const commonClasses = CommonStyles();
  const wrapperRef = useRef(null);
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(null);

  const handleCancel = () => {
    setAnchorEl(null);
    setOpenDelete(false);
  };

  const handleConfirm = () => {
    DeleteVerificationDataEnterpriseAddress(verificable.id).then((response) => {
      if (response && !response.status) {
        finishOperation("success", t("verificationsDeleteAddress"));
        chargePageData();
      } else {
        finishOperation("error", getErrorMessage(response, t));
        handleCancel();
      }
    });
  };

  const handleDelete = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenDelete(true);
  };

  useOutsideAlerter(wrapperRef, handleCancel);

  return (
    <Grid>
      <Tooltip title={t("delete")}>
        <IconButton
          edge="inherit"
          className={commonClasses.iconButton}
          aria-label="menu"
          onClick={(event) => handleDelete(event)}
        >
          <DeleteIcon className={commonClasses.iconActionStyle} />
        </IconButton>
      </Tooltip>
      <Popper
        open={openDelete}
        anchorEl={anchorEl}
        // onClose={() => setOpenDelete(false)}
        placement={"top"}
        style={{ zIndex: 100 }}
        ref={wrapperRef}
      >
        <Paper>
          <Container>
            <Grid container style={{ paddingTop: "1em", paddingBottom: "1em" }}>
              <Grid item xs={12}>
                <Typography className={commonClasses.popperContentText}>
                  {t("verificatinosDeleteElement")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  size="small"
                  onClick={() => handleCancel()}
                  style={{ float: "left" }}
                >
                  {t("no")}
                </CorreosButton>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => handleConfirm()}
                  style={{ float: "right" }}
                >
                  {t("yes")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Popper>
    </Grid>
  );
}
