export const ES1 = {
  //Generic
  saveSuccess: "¡Guardado realizado con éxito!",
  saveError: "¡Error al guardar!",
  cancel: "Cancelar",
  accept: "Aceptar",
  next: "Siguiente",
  previous: "Anterior",
  required: "Este es un campo obligatorio",
  exit: "Salir",
  search: "Buscar",
  news: "Novedades",
  send: "Enviar",
  reSend: "Reenviar",
  create: "Crear",
  add: "Añadir",
  delete: "Borrar",
  update: "Actualizar",
  yes: "Sí",
  no: "No",
  save: "Guardar",
  correosId: "Correos ID",
  genericError: "Se ha producido un error",
  change: "Cambiar",
  see: "Ver",
  download: "Descargar",
  email: "Email",
  phone: "Teléfono",
  idDocument: "Documento identificativo",
  address: "Dirección",
  country: "País",
  province: "Provincia",
  city: "Ciudad",
  postalCode: "Código postal",
  reset: "Resetear",
  confirm: "Confirmar",
  warning: "Atención",
  aliasFilterText: "Introduzca texto para filtrar/añadir Alias",
  addDirection: "Añadir dirección",
  pending: "Pendiente",
  done: "Hecho",
  here: "aquí",
  backToTheApp: "Volver a la aplicación",
  selectOption: "Selecciona una opción",
  noOptionSelected: "Sin seleccionar",

  // PendingData

  valid: "Válido",
  document: "Documento",
  userAddress: "Dirección de usuario",
  enterpriseAddress: "Dirección de empresa",
  privacySign: "Firma de privacidad",
  tycSign: "Firma de Términos y Condiciones",
  pendingData: "Tienes datos pendientes:",

  //login
  logInTitle: "Inicia sesión",
  sublogInTitle: "Accede a {appName} a través de Correos ID. ",
  sublogInTitleCID: "Accede a Correos ID. ",
  sublogInTitleInfo: "Si ya estás registrado, no tendrás que volver a registrarte.",
  sublogInTitleInfoCID: "Desde aquí puedes manejar tu identidad de correos.",
  logInTitleSelectType: "¿Cómo quieres acceder?",
  correosIdentityLabel: "Identidad de Correos",
  correosPasswordLabel: "Contraseña",
  correosCheckPasswordLabel: "Confirmación contraseña",
  loginTooltip: "Correos ID es la puerta de entrada a los servicios digitales de Correos. Para acceder a ellos, solamente debes introducir tus credenciales, no tendrás que volver a registrarte.",
  forgotPasswordButton: "¿Has olvidado tu contraseña?",
  nextButton: "Siguiente",
  logInButton: "Iniciar Sesión",
  userPasswordError: "Usuario o contraseña incorrectos",
  warningLegal: "Aviso Legal",
  privacyPolicy: "Política de Privacidad",
  cookiesPolicy: "Política de Cookies",
  createAccountButton: "Crear Cuenta",
  rememberme: "Recuérdame",
  welcomeBack: "Hola de nuevo",
  recoverDisabledAccount1: "Se bloqueó su cuenta. Si quiere activar de nuevo su cuenta y recuperar su información, pinche en el siguiente ",
  recoverDisabledAccount2: "link",
  recoverDisabledAccount3: " para iniciar el proceso de recuperación de cuenta.",
  loginPrivacyDialogTitle: "Privacidad",
  loginPrivacyDialogText: "Para poder acceder a la aplicación antes debes aceptar la privacidad",
  loginSubscribeDialogTitle: "Suscripción",
  loginSubscribeDialogText: "No estás suscrito a la aplicación, si quieres suscribirte debes leer y aceptar los términos y condiciones y pulsar en aceptar",
  loginSubscribeDialogTextServices: "Para poder suscribirte primero debes leer y aceptar los términos y condiciones y pulsar en aceptar",
  InvitationAcceptSuccess: "Invitación aceptada",
  AcceptChangeEnterpriseRoleSuccess: "Cambio de rol aceptado con éxito",
  CancelOrRejectChangeRoleSuccess: "Se ha rechazado la invitación",
  InvitationAcceptError: "La invitación ha fallado",
  AcceptChangeEnterpriseRoleError: "Ha fallado el cambio de rol",
  CancelOrRejectChangeRoleError: "Ha fallado el rechazo de la invitación",
  loginSystemUserError: "Un usuario de sistema no puede loguear en la web",

  footerCopyrightText: "©Sociedad Estatal Correos y Telegrafos, S.A., S.M.E. Todos los derechos reservados.",
  loginFormatError: "Formato incorrecto",
  loginOauthError: "El método oauth ya no está disponible",

  //Register
  loginPageInfo: "¿Aún no tienes una cuenta? ",
  loginPageRegistryHere: "Regístrate aquí",
  registerPagePutData: "Introduce los datos de registro",
  registerPageConfirmIdentity: "Verifica tu identidad",
  registerPageMail: "Dirección de correo electrónico",
  registerPageMailError: "El email introducido no es correcto",
  registerPagePhoneError: "El teléfono introducido no es correcto",
  registerPageNDOError: "El documento introducido no es correcto",
  registerPageMailErrorExist: "El email introducido ya está registrado",
  registerPagePassword: "Contraseña",
  registerPagePasswordError: "8-16 carácteres, que contengan 3 de los 4 siguientes: minúsculas, mayúsculas, dígitos (0-9), y uno o más de los siguientes símbolos: @ # $ % ^ & * - _ + = [ ] { } | \\ : ' , ? / ` ~ ( ) ; . \"",
  registerPageCheckPassword: "Confirmación",
  registerPageCheckPasswordError: "Las contraseñas no son iguales",
  registerPagePhone: "Teléfono",
  registerPagePhoneErrorShort: "El teléfono es demasiado corto",
  registerPagePhoneErrorLong: "El teléfono es demasiado largo",
  registerPagePhoneErrorExist: "El teléfono introducido ya está registrado",
  registerPagePrefixError: "Tiene que introducir un prefijo",
  registerPageTipoPersona: "Tipo de Persona",
  registerPageAgreeConditionsPhrase: "Debes leer y aceptar los términos y condiciones: ",
  registerPageAgreeConditions: "Términos y Condiciones",
  registerPageAgreeConditionsDot: "Términos y Condiciones: ",
  registerPageAgreeConditionsSee: "Ver Términos y Condiciones",
  registerPageAgreeConditionsTerms1: "He leído y acepto ",
  registerPageAgreeConditionsTerms2: "las condiciones de uso ",
  registerPageAgreeConditionsTerms3: " de {appName}.",
  registerPageAgreeConditionsTerms4: "política de protección de datos",
  registerPageAgreeConditionsAccepted: "Aceptados",
  registerPageAgreeConditionsPending: "Pendiente *",
  registerPageAgreeConditionsTermsConsent: "De acuerdo con nuestra Política de Protección de datos nos gustaría solicitar su consentimiento para las siguientes actividades:",
  registerPageOfersPromotions: "Ofertas y promociones",
  registerPageOfersPromotionsDot: "Ofertas y promociones: ",
  registerPageOfersPromotionsAll: "ACEPTO TODOS",
  registerPageOfersPromotionsSome: "ACEPTO ALGUNOS",
  registerPageOfersPromotionsNone: "NO ACEPTO",
  registerPageOfersPromotionsPending: "PENDIENTE *",
  registerPagePersonalizeOptions: "Personalice su privacidad seleccionando las siguientes opciones:",
  registerPageIdentityVerifyTitle: "Confirma tu identidad",
  registerPageIdentityVerifyText: "Para garantizar tu identidad, enviaremos un código de verificación a tu dirección de correo electrónico. Introduce el código a continuación para completar el proceso de registro.",
  registerPageIdentityVerifyMailButton: "ENVIAR EMAIL",
  registerPageIdentityVerifyPhoneButton: "ENVIAR SMS",
  registerPageIdentityVerifyVerificable: "Debe realizar una de las dos verificaciones obligatoriamente, la otra puede omitirla o aprovechar para realizarla",
  registerPageIdentityVerifyVerificableEmail: "La verificación del email es obligatoria",
  registerPageIdentityVerifyVerificablePhone: "La verificación del teléfono es obligatoria",
  registerPageIdentityVerifyNoVerificableEmail: "La verificación del email es opcional, puede omitir ese punto si quiere",
  registerPageIdentityVerifyNoVerificablePhone: "La verificación del teléfono es opcional, puede omitir ese punto si quiere",
  registerPageIdentityVerifyCodeSended: "Código enviado",
  registerPageOtp: "Introduzca Código",
  registerPageOtpError: "El código introducido no es correcto",
  registerPageRegisterError: "Ha habido un problema durante el registro",
  registerPageOfersPromotionsSelected: "Ofertas y promociones aceptadas: ",
  registerPageOfersPromotionsSelectedAll: "Todas",
  registerPageOfersPromotionsSelectedSome: "Solo algunas",
  registerPageOfersPromotionsSelectedNone: "Ninguna",
  registerPageOfersPromotionsSelectedPending: "Sin seleccionar",
  registerPageSeeOfersPromotions: "Abrir ofertas/promociones",
  registerPageSendOTP: "Introduzca el código que le acabamos de enviar",
  registerPageSendOTPEmail: "Código para email",
  registerPageSendOTPPhone: "Código para teléfono",
  registryTitleData: "Datos Registro",
  registerPagePersonType1: "Particular",
  registerPagePersonType2: "Empresa/Autónomo",
  registerPageNoDocuments: "Para este tipo de empresa no se requieren documentos",
  registerPageWarnTitle: "Atención",
  registerPageWarnText: "El registro a correos ID ha ido correctamente, pero ha habido un problema en el guardado de datos, tendrás que volver a rellenarlos al acceder a la aplicación",
  registerPagePoliticsAndConditions: "Políticas y condiciones",
  registerPageRecaptcha: "Captcha",

  //ChooseUser
  chooseUserTitle: "Por favor, seleccione si quiere autorizarse como particular o como empresa:",
  chooseEnterpriseTitle:
    "Por favor, pulsa en 'Empresa' y escoge con cuál quieres autorizarte:",
  none: "Ninguna",
  chooseUser: "Particular",
  chooseEnterprise: "Empresa",
  chooseDialogTitle: "Seleccione empresa",

  //Stepper
  createUserCid: "Crear cuenta en CorreosID",
  userIdentify: "Verifica tu identidad",
  userIdentity: "Introduce tu identidad",
  sendOTP: "Enviar código",
  reSendOTP: "Reenviar código",
  codeConfirm: "Confirmar código",
  subscriptionToApp: "Suscripción a {appName}",
  ownerData: "Datos del titular",
  enterpriseCreation: "Creación de empresa",
  userData: "Datos de usuario",
  stepChangePass: "Cambiar contraseña",
  enterpriseData: "Datos de empresa",
  enterpriseDocuments: "Añadir documentos",
  requiredData: "Datos requeridos",
  createUserCidTextLabel: "Introduce tu email de acceso y contraseña, acepta las políticas de privacidad y crea una cuenta en CorreosID para acceder a nuestros servicios de forma segura.",
  userIdentifyTextLabel: "Este paso es esencial para verificar tu identidad y garantizar la seguridad de tus datos.",
  userIdentityTextLabel: "Ingresa los detalles de tu identidad. Estos detalles nos ayudarán a personalizar tu experiencia y a brindarte un mejor servicio.",
  sendOTPTextLabel: "Te enviaremos un código único a tu dirección de correo electrónico o número de teléfono. Utiliza este código para verificar que tienes acceso a los medios de contacto proporcionados.",
  reSendOTPTextLabel: "En caso de no haber recibido el código, tienes la opción de solicitar su reenvío. Verifica tu carpeta de correo no deseado antes de hacerlo.",
  codeConfirmTextLabel: "Ingresa el código que has recibido y confirma tu acceso. Este código garantiza que eres el titular de la cuenta y estás listo para proceder.",
  subscriptionToAppTextLabel: "Opta por suscribirte a nuestra aplicación para acceder a sus funciones exclusivas. Mantente al tanto de las últimas actualizaciones y novedades.",
  ownerDataTextLabel: "Proporciona información detallada sobre ti.",
  enterpriseCreationTextLabel: "Si estás creando una empresa, completa los pasos necesarios para registrarla oficialmente. Proporciona información sobre ella.",
  userDataTextLabel: "Proporciona información adicional sobre ti para enriquecer tu perfil.",
  stepChangePassTextLabel: "Si deseas cambiar tu contraseña actual, este es el momento para hacerlo. Proporciona una nueva contraseña segura y asegúrate de guardarla en un lugar seguro.",
  enterpriseDataTextLabel: "Si estás registrando una empresa, completa los detalles adicionales.",
  enterpriseDocumentsTextLabel: "Sube documentos importantes relacionados con tu empresa.",
  requiredDataTextLabel: "Asegúrate de proporcionar todos los datos necesarios para completar el proceso. Estos pueden variar según el tipo de cuenta que estás creando. Verifica cada sección cuidadosamente.",

  //ResetPassword
  resetPasswordTitle: "¿Has olvidado tu contraseña?",
  resetPasswordText: "No te preocupes, ¡puede pasarle a cualquiera!",
  resetPasswordFormatError: "8-16 carácteres, que contengan 3 de los 4 siguientes: minúsculas, mayúsculas, dígitos (0-9), y uno o más de los siguientes símbolos: @ # $ % ^ & * - _ + = [ ] { } | \\ : ' , ? / ` ~ ( ) ; . \"",
  resetPasswordCheckError: "Las contraseñas no son iguales",
  resetPasswordTitle2: "Cambia tu contraseña",
  resetPasswordText2: "Confirma tu identidad con el código que te hemos enviado a:",
  resetPasswordEmptyError: "La contraseña no puede estar vacía",
  resetPasswordRedirection: "En unos segundos te redirigiremos al Inicio de sesión, o puedes ir pulsando ",
  lAddUser: "Introduce tu identidad de Correos y te ayudaremos a crear una contraseña nueva.",
  backButton: "Volver",
  sending: "Enviando...",
  lAddOtp: "Necesitamos saber que eres tú. ¿Nos lo confirmas?",
  lOtp: "Código de verificación",
  emptyError: "El campo no puede estar vacío.",
  emptyErrorPass: "Los campos no pueden estar vacíos.",
  emptyErrorIdentifier: "Debes añadir un identificador de Correos válido.",
  lChangePass: "Escribe tu nueva contraseña.",
  lPass: "Contraseña",
  lRPass: "Repite tu contraseña",
  errorEqualPass: "Las contraseñas deben ser iguales",
  errorCheckPass: "La contraseña indicada no es válida.",
  lCFinalStep: "La contraseña se ha modificado con éxito.",
  lEFinalStep: "Error al cambiar la contraseña, vuelva a intentarlo en unos minutos.",
  lStart: "Iniciar",
  tLinkHelper: "¿Necesitas ayuda?",
  hAddUser: "Para poder realizar el cambio de contraseña necesitamos saber el identificador con el que te diste de alta en nuestra plataforma.",
  hAddOtp: "Desde CorreosID necesitamos verificar que eres tú quién está intentando cambiar la contraseña, por ese motivo te mandaremos a tu identificador de cuenta (teléfono o mail) un código de 5 dígitos. Simplemente tienes que decirnos ese código y podremos continuar con el proceso.",
  hAddPass: "Debes indicar una nueva contraseña que tenga entre 8-16 carácteres, que contengan 3 de los 4 siguientes valores: minúsculas, mayúsculas, dígitos (0-9), y uno o más de los siguientes símbolos: @ # $ % ^ & * - _ + = [ ] { } |  : ' , ? / ` ~ \" ( ) ; .los mínimos necesarios para poder continuar.",
  dialogClose: "Cerrar",
  otpRpError: "El código indicado no es correcto.",
  otpInvalidError: "OTP no válido",
  otpEmptyError: "El código no puede estar vacío.",
  resetPassword: "Cambiar contraseña",
  resetPasswordEmail: "Email",
  resetPasswordPhone: "Teléfono",
  resetPasswordSend: "Enviar código de verificación",
  resetPasswordSended: "Código enviado",
  resetPasswordSendEmail: "Seleccione un email para enviar el código",
  resetPasswordSendPhone: "Seleccione un teléfono para enviar el código",
  resetPasswordChange: "Introduzca la nueva contraseña",
  resetPasswordChanged: "Contraseña modificada con éxito",
  //PrefixPhone
  prefixSelector: "Prefijo",

  //UserData
  userDataInfo:
    "Ya tienes cuenta en CorreosId, ahora es el momento de suscribirte a {appName}",
  userDataInfoCid:
    "Te has registrado en CorreosId correctamente, ahora es necesario completar los datos requeridos",
  userDataVerifyEmailOk: "El email ha sido verificado correctamente",
  userDataVerifyEmailKo: "Ha habido un problema con la verificación del email",
  userDataVerifyPhoneOk: "El teléfono ha sido verificado correctamente",
  userDataVerifyPhoneKo: "Ha habido un problema con la verificación del teléfono",
  userDataInfoQuestion: "¿Seguro que deseas cancelar la suscripción a {appName}?",
  userDataInfoQuestionCid: "¿Seguro que deseas cancelar la creación de la empresa?",
  userDataExitInfo:
    "Ya estás registrado en correosID, ten en cuenta que si sales ahora cuando hagas login en {appName} tendrás que terminar de rellenar los datos para suscribirte.",
  userDataExitInfoCid:
    "Ya estás registrado en correosID, ten en cuenta que si sales ahora tu usuario no tendrá empresa",
  usersaveData: "Antes de guardar",
  userDataCopyDirection: "Copiar dirección",
  userDataSuccessUpdate: "Datos de usuario actualizados con éxito",
  userDataAddEmail: "Añadir email",
  userDataModifyEmail: "Verificar email",
  userDataDeleteEmail: "Eliminar email",
  userDataDeleteEmailSuccess: "Email eliminado",
  userDataAddPhone: "Añadir teléfono",
  userDataModifyPhone: "Verificar teléfono",
  userDataDeletePhone: "Eliminar teléfono",
  userDataDeletePhoneSuccess: "Teléfono eliminado",
  userDataAddDoc: "Añadir documento",
  userDataModifyDoc: "Modificar documento identificativo",
  userDataDeleteDoc: "Eliminar documento identificativo",
  userDataAddDocSuccess: "Documento identificativo añadido",
  userDataModifyDocSuccess: "Documento identificativo modificado",
  userDataDeleteDocSuccess: "Documento identificativo eliminado",
  userDataAddAddress: "Añadir dirección",
  userDataModifyAddress: "Modificar Dirección",
  userDataDeleteAddress: "Eliminar Dirección",
  userDataAddAddressSuccess: "Dirección añadida",
  userDataModifyAddressSuccess: "Dirección modificada",
  userDataDeleteAddressSuccess: "Dirección eliminada",
  userDataSaveWarning: "Recuerde guardar los datos para que este cambio tenga efecto",
  userDataAliasWarning: "Ya tienes una dirección con ese nombre",
  userDataAddDirection: "¿Quiere añadir una dirección con los datos actuales?",
  userDataAddDirectionMandatorie: "Para poder añadir una dirección debe rellenar todos los campos obligatorios",
  userDataRequiredDirection: "El Alias y la Dirección son campos obligatorios",
  userDataAddDirectionSuccess: "Dirección añadida con éxito",
  userDataChangeDirectionSuccess: "Dirección modificada con éxito",
  userDataFavouriteDirection: "Dirección favorita",
  userDataFavouriteDirectionWarning: "Ya tienes una dirección favorita y sólo puedes tener una, si seleccionas esta la otra dejará de serlo",
  userDataWarningMessage: "Recuerde que para guardar una nueva dirección antes debe añadirla a la lista, si no lo ha hecho todavía pulse en Cancelar y añada la nueva dirección antes de continuar",
  userDataRegister: "Registro completado",
  userDataIncomplete: "Faltan datos obligatorios. Por favor, rellene los datos obligatorios que faltan.",
  userDataFNAError: "La fecha de nacimiento no puede ser posterior al día de hoy.",
  userDataSelectEmail: "Selecciona email",
  userDataSelectPhone: "Selecciona teléfono",
  userDataSelectAddress: "Selecciona dirección",
  userDataDateError: "La fecha no es correcta",
  userDataSaveProfile: "Guardar perfil",
  enterpriseDataSaveProfile: "Guardar empresa",
  userDataAddressUpdateWarning: "Es necesario actualizar esta dirección porque sus datos no son correctos",
  userDataPageSubtitle: "Edita tus datos personales y gestiona las direcciones",
  userFormatIncorrect: "Formato no soportado. Solo se permiten letras",

  //EnterpriseData
  enterpriseDataInfo: "Se ha realizado el registro correctamente, ahora es necesario terminar de rellenar los datos de la empresa",
  enterpriseDataInfoQuestion: "¿Seguro que desea cancelar la inserción de datos?",
  enterpriseDataExitInfo: "La empresa ya ha sido registrada, si sale sin informar los datos se le volverán a pedir cuando vuelva a acceder",
  enterpriseDataCopyDirection: "Copiar dirección",
  enterpriseDataSuccessUpdate: "Datos de empresa actualizados con éxito",
  enterpriseDataAddressWarning: "La dirección se guardará junto con la creación de la empresa",
  enterpriseDataCreated: "Empresa creada con éxito",
  enterpriseDataSaveData: "Guardar datos",
  enterpriseDataPageSubtitle: "Modifica los datos de tu empresa así como la dirección fiscal",
  enterpriseDataCreatePageSubtitle: "Añade los datos de tu empresa así como la dirección fiscal",
  enterpriseDataSave: "Guardar empresa",
  enterpriseDataCreate: "Crear empresa",
  
  //DialogAddChange
  dialogAddChangeAddTitle: "Añadir dirección",
  dialogAddChangeChangeTitle: "Modificar dirección",
  dialogAddChangeAddText: "Añade los datos de la dirección",
  dialogAddChangeChangeText: "Modifica los datos de la dirección",

  //Main
  userNameUnregistered: "Usuario sin nombre",
  enterpriseNameUnregistered: "Empresa sin nombre",
  mainPageTitle: "Bienvenido a CorreosID",
  mainPageUserProfile: "Perfil de usuario",
  mainPagePrivacity: "Privacidad",
  mainPageVerifications: "Verificaciones",
  mainPageCreateEnterprise: "Crear empresa",
  mainPageAvailableServices: "Servicios suscritos",

  //SideBar
  UserData: "Datos",
  UserVerifications: "Verificaciones",
  UserPrivacy: "Privacidad",
  EnterpriseCreate: "Crear",
  EnterpriseTeam: "Equipo",
  EnterpriseDelete: "Solicitar Baja",
  UserServices: "Servicios",
  EnterpriseData: "Datos",
  EnterpriseVerifications: "Verificaciones",
  EnterprisePrivacy: "Privacidad",
  EnterpriseServices: "Servicios",
  User: "Usuario",
  Enterprise: "Empresa",
  Services: "Servicios",
  Service: "Servicio",
  profileComp: "Progreso del perfil",

  //Service Page
  subscribeService: "Suscribirse",
  unsubscribeService: "Baja",
  revokeData: "Retirar Acceso a Mis Datos",
  acceptData: "Permitir Acceso a Mis Datos",
  goToService: "Entrar",
  goToUrl: "Ir a",
  subscribedService: "Suscrito",
  unSubscribedService: "No Suscrito",
  askUnsubscribeService: "Solicitar Baja",
  serviceSubscribed: "Suscripción realizada con éxito",
  serviceUnsubscribed: "Desuscripción realizada con éxito",
  revokeEnterpriseData: "Retirar permisos",
  acceptEnterpriseData: "Permitir permisos",
  servicePageDualWarning: "¿Seguro que quieres darte de baja de {applicationName}?",
  servicePageCancelAccount: "Usuario",
  servicePageCancelEnterprise: "Empresa",
  servicePageUnsubscribe: "Servicio",
  singleSubscriptionDialogRedirectTextUser: "Estás a punto de darte de baja de {applicationName}, que es tu único servicio. Si deseas continuar con esta acción, pulsa en 'Servicio'. Por otro lado, si lo que quieres es dar de baja tu usuario de Correos ID, pulsa en 'Usuario'.",
  singleSubscriptionDialogRedirectTextEnterprise: "Estás a punto de dar de baja la empresa de {applicationName}, que es su único servicio. Si deseas continuar con esta acción, pulsa en 'Servicio'. Por otro lado, si lo que quieres es dar de baja la empresa, pulsa en 'Empresa'.",
  servicePageLinkText: "enlace",
  subscribeMeText: "Suscribirme",
  subscriptionOkTitle: "¡Listo!",
  subscriptionOkText: "Te has suscrito con éxito",
  servicePageSubtitle: "Con tu identidad de Correos (Correos ID) puedes suscribirte a los servicios digitales de Correos",
  servicePageAppGoTo: "Suscríbete desde el servicio",
  servicePageAppAccess: "Accede desde el servicio",
  servicePageConfirmCancelAccount: "¿Seguro que quieres cancelar tu cuenta?",
  servicePageConfirmCancelEnterprise: "¿Seguro que quieres eliminar la empresa?",

  //Verifitacions Page
  verificationsVerify: "Verificar",
  verificated: "Verificado",
  notVerificated: "No Verificado",
  pendingVerificated: "Pendiente",
  UserEmail: "Email",
  PersonaTelfMovil: "Teléfono",
  PersonaNumDocID: "Nº Documento",
  PersonaDireccion: "Dirección",
  verificationsSendCode: "Enviaremos un código de verificación a: ",
  verificationsSendCodeEmail: "Verifica tu email",
  verificationsSendCodePhone: "Verifica tu teléfono",
  verificationsAddVerifEmail: "Añade y verifica un email",
  verificationsAddVerifPhone: "Añade y verifica un teléfono",
  verificationsConfirmCode: "Introduzca el código de verificación",
  verificationsVerificationType: "Tipo de verificación",
  verificationsConfirmPostCard: "Vamos a enviarle el código por correo postal, ¿seguro que quiere realizar este tipo de verificación?",
  verificationsCreate: "Introduzca los datos para añadir teléfono",
  verificationsCreateEmail: "Introduzca los datos para añadir email",
  verificationsAlias: "Alias",
  verificationsCountry: "País o Región",
  verificationsDirection: "Dirección",
  verificationsPostalCode: "Código Postal",
  verificationsRegion: "Provincia/Región",
  verificationsTown: "Población",
  verificatinosDeleteElement: "¿Eliminar elemento?",
  verificationsVerifiedData: "Datos verificados",
  verificationsEMA: "Emails verificados",
  verificationsMOV: "Teléfonos verificados",
  verificationsNDO: "Documentos verificados",
  verificationsALI: "Direcciones verificadas",
  verificationsEmpty: "Ninguno",
  verificationsUpgrade: "Aumentar nivel de verificación",
  verificationsAddEmail: "Email añadido correctamente",
  verificationsVerifyEmail: "Email verificado correctamente",
  verificationsDeleteEmail: "Email eliminado correctamente",
  verificationsAddPhone: "Teléfono añadido correctamente",
  verificationsVerifyPhone: "Teléfono verificado correctamente",
  verificationsDeletePhone: "Teléfono eliminado correctamente",
  verificationsAddAddress: "Dirección añadida correctamente",
  verificationsVerifyAddress: "Dirección verificada correctamente",
  verificationsDeleteAddress: "Dirección eliminada correctamente",
  verificationsDeleteDocument: "Documento eliminado correctamente",
  verificationsDeleteAddressTooltip: "Eliminar dirección",
  verificationsDocuments: "Documentación",
  verificationsemail: "Email",
  verificationsphone: "Teléfono",
  verificationsaddress: "Dirección",
  verificationsnumber: "Documento identificativo",
  verificationsByOtp: "Verificado por código",
  verificationsByForm: "Verificado por formato",
  verificationsReSendOtp: "Código reenviado",
  verificationDisableAccountTitle: "Al darse de baja de Correos ID dejará de tener acceso a todos los servicios que se muestran a continuación:",
  verificationDisableAccountList: "Borraremos tu cuenta*.",
  verificationDisableAccountListEnterprise: "Borraremos las empresas de las que eres titular.",
  verificationDisableAccountP1: "Te aconsejamos que antes de eliminar tu cuenta de Correos ID solicites y finalices la baja en los servicios en los que estás registrado. Para ello, selecciona la opción Cancelar. Si aun así sigues prefiriendo eliminar ahora tu cuenta de Correos ID ahora, pincha en Dar de baja.",
  verificationDisableAccountP2: "*Tranquilo, tienes {disableAccDays} días para recuperar tu cuenta de Correos ID por si te arrepientes. Podrás recuperar la cuenta de Correos ID, pero el resto de servicios pueden no guardar tus datos durante estos {disableAccDays} días.",
  verificationDisableAccountP3: "Correos ID comunicará la eliminación de tu cuenta a los servicios asociados pero la eliminación de tu cuenta en cada servicio no es responsabilidad de Correos ID",
  verificationDisableAccountConfirm: "Dar de baja",
  verificationsWrongTipeDocument: "El tipo de documento no es válido",
  verificationsUploadSuccess: "Documento subido con éxito",
  verificationsDeleteSuccess: "Documento eliminado con éxito",
  verificationsNoUploaded: "No subido",
  verificationsEmailExists: "El email introducido ya existe",
  verificationsPhoneExists: "El teléfono introducido ya existe",
  verificationsDeleteEnterpriseTitle: "¿Seguro que quiere borrar esta empresa?",
  verificationsDeleteEnterpriseText: "Una vez borrada no podrá recuperar los datos referentes a ella",
  verificationsConfirmDeleteAddress: "¿Esta seguro que desea eliminar la dirección? Si acepta se eliminará de forma permanente",
  verificationsUnifyUserTitle: "Atención",
  verificationsUnifyUserText: "Hemos detectado que el {dataType} que estás intentando verificar ya existe en otra cuenta. ¿Es este {dataType} ({data}) realmente tuyo?. Si es así pulsa en 'Aceptar' para unificar ambas cuentas",
  verificationsPageSubtitle: "Puedes gestionar y verificar tus datos, así como cambiar tu contraseña de acceso",
  verificationsEnterprisePageSubtitle: "Puedes gestionar y verificar los datos de la empresa",


};
