import React, { useEffect } from "react";
import {
    Divider,
    Grid,
    Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

export function PendingDataWarning(props) {
    const { pendingData } = props;
    const { t, i18n } = useTranslation();

    useEffect(() => { }, []);

    return (
        <Grid item xs={12} style={{ marginBottom: "2em", color: "#F32735", textAlign: "left" }}>
            <Divider fullWith style={{ backgroundColor: "#F32735", marginBottom: "0.5em" }} />
            <Typography style={{ marginBottom: "0.5em" }}>{t("pendingData")}</Typography>
            <ul>
                {pendingData.map((data) => {
                    return <li><Typography variant="h7">{data}</Typography></li>
                })
                }
            </ul>
            <Divider fullWith style={{ backgroundColor: "#F32735" }} />
        </Grid>
    );
};