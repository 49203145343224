import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { FaUser } from "react-icons/fa";
import { FaUserCog } from "react-icons/fa";
import { CommonStyles } from "../../../commons/CommonStyles";
import { EnterpriseTeamStyles } from "../EnterpriseTeamStyles";
import DialogRevokeAdmin from "./DialogRevokeAdmin";
import { DialogSendInvitation } from "./DialogSendInvitation";
import { DialogCancelInvitation } from "./DialogCancelInvitation";
import DialogAddAdmin from "./DialogAddAdmin";
import DialogCreateSystemUser from "./DialogCreateSystemUser";
import { checkIsNullUndefined } from "../../../commons/Utils";
import { useTranslation } from "react-i18next";
import { DialogDeleteUser } from "./DialogDeleteUser";

export function UsersCard(props) {
  const {
    idEnterprise,
    authorized,
    finishOperation,
    getInfo,
    isTitular,
    roles,
    titular,
  } = props;
  const commonClasses = CommonStyles();
  const classes = EnterpriseTeamStyles();
  const { t, i18n } = useTranslation();

  const loadAutorized = () => {
    return authorized.map((user, i) => {
      if (user.idRole != 1) {
        return (
          <Grid item xs={12} key={i + "a"}>
            <Card className={classes.card}>
              <Grid container>
                <Grid container item xs={12}>
                  <Grid item xs={2}>
                    {!checkIsNullUndefined(user.userEmail) ||
                    !checkIsNullUndefined(user.userPhone) ? (
                      <FaUser
                        className={commonClasses.iconStyle}
                        style={{ float: "left" }}
                      />
                    ) : !checkIsNullUndefined(user.userName) ? (
                      <FaUserCog
                        className={commonClasses.iconStyle}
                        style={{ float: "left" }}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      align="left"
                      className={classes.listTextStyleShow}
                    >
                      {!checkIsNullUndefined(user.userEmail)
                        ? user.userEmail
                        : !checkIsNullUndefined(user.userPhone)
                        ? user.userPhone
                        : !checkIsNullUndefined(user.userName)
                        ? user.userName
                        : null}
                    </Typography>
                  </Grid>
                  {isTitular && (user.idRole === 2 || user.idRole === 3) ? (
                    <Grid
                      container
                      item
                      xs={2}
                      style={{ float: "right", display: "flex" }}
                    >
                      <Grid item xs={6}></Grid>
                      <Grid item xs={6}>
                        <DialogDeleteUser
                          finishOperation={finishOperation}
                          idEnterprise={idEnterprise}
                          user={user}
                          getInfo={getInfo}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container item xs={2}></Grid>
                  )}
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={2} key={i + "b"} style={{ display: "flex" }}>
                    <Typography variant="h6" align="left">
                      {t("teamPageRol")}
                    </Typography>
                  </Grid>
                  <Grid item container xs={8} style={{ display: "flex" }}>
                    <Typography
                      className={classes.listTextStyleShow}
                      align="left"
                    >
                      {checkIsNullUndefined(user.rolName) ? "-" : user.rolName}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={2}
                    style={{ float: "right", display: "flex" }}
                  >
                    {isTitular && !checkIsNullUndefined(user.idInvitation) ? (
                      <Grid item xs={6}>
                        <DialogCancelInvitation
                          finishOperation={finishOperation}
                          idInvitation={user.idInvitation}
                          getInfo={getInfo}
                          idRole={user.idRole}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={6}></Grid>
                    )}
                    {isTitular && user.rolName == t("teamPageItemAdmin") ? (
                      <Grid item xs={6}>
                        <DialogRevokeAdmin
                          userOid={user.userOid}
                          finishOperation={finishOperation}
                          idEnterprise={idEnterprise}
                          getInfo={getInfo}
                        />
                      </Grid>
                    ) : null}
                    {isTitular && user.rolName == t("teamPageItemAutorized") ? (
                      <Grid item xs={6}>
                        <DialogAddAdmin
                          userOid={user.userOid}
                          finishOperation={finishOperation}
                          idEnterprise={idEnterprise}
                          getInfo={getInfo}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <Typography
                  align="center"
                  variant="h5"
                  className={commonClasses.gridTitle}
                >
                  {" " + t("teamPageAuthorizedTitle")}
                </Typography>
              </Grid>
              {isTitular ? (
                <Grid container item xs={2}>
                  <Grid item xs={6}>
                    <DialogSendInvitation
                      roles={roles}
                      finishOperation={finishOperation}
                      idEnterprise={idEnterprise}
                      getInfo={getInfo}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DialogCreateSystemUser
                      finishOperation={finishOperation}
                      datosTitular={titular}
                      idEnterprise={idEnterprise}
                      getInfo={getInfo}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={2}></Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {loadAutorized()}
    </Grid>
  );
}
