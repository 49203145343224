import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Grid,
} from "@material-ui/core";
import DehazeIcon from "@material-ui/icons/Dehaze";
import correosIdLogoBlue from "../../assets/images/Cornamusa_Azul.svg";
import { checkIsNullUndefined } from "../../commons/Utils"
import { FiLogOut } from 'react-icons/fi';
import { HeaderStyles } from "./HeaderStyles";
import { backToLogin, getApplicationOid, getEntranceUrl, getReplaceMode, getSessionItem } from "../../services/Commons";
import { EnumPages } from "../../commons/EnumPages";
import { useTranslation } from "react-i18next";
import history from "../../services/history";
import { SelectorSwitch } from "../CommonComponents/SelectorSwitch";
import { Size, sessionValues } from "../../commons/EnumsGeneral";

export function Header(props) {
  const { setShowSideMenu, removeAppNameLogout, showName, personType, setPersonTypeSelected, openPage } = props;
  const classes = HeaderStyles();
  const { t, i18n } = useTranslation();

  const [appName, setAppName] = useState();
  const [isUser, setIsUser] = useState();

  useEffect(() => {
    setAppName(getSessionItem(sessionValues.appName));
    if (!checkIsNullUndefined(personType)) {
      let auxPersonTypeSelected = personType == 1 || personType == 3 ? 1 : 2;
      manageUserSelected(auxPersonTypeSelected);
    }
  }, [personType]);

  const handleLogout = () => {
    backToLogin();
  };

  const handleList = () => {
    setShowSideMenu();
  };

  const handleGoToMain = () => {
    let auxToken = getSessionItem(sessionValues.idToken);
    if (checkIsNullUndefined(auxToken)) {
      history.push(getEntranceUrl(EnumPages.Login));
    } else if (getReplaceMode() != "true") {
      history.push("/" + EnumPages.Index);
    }
  }

  const handleSwitch = () => {
    let auxPersonTypeSelected = isUser ? 2 : 1;
    manageUserSelected(auxPersonTypeSelected);
  }

  const manageUserSelected = (auxPersonTypeSelected) => {
    setPersonTypeSelected(auxPersonTypeSelected);
    setIsUser(auxPersonTypeSelected != 2);
    sessionStorage.setItem("personTypeSelected", auxPersonTypeSelected);
  }

  const managePersonTypeSwitch = () => {
    return (
      <Grid>
        {personType == 1 || process.env.REACT_APP_APP_OID == getApplicationOid() ? (
          <Grid container className={classes.selectorHeaderContainer}>
            <Grid item xs={12}
              className={classes.selectorButtonSelected}>
              {t("User")}
            </Grid>
          </Grid>
        ) : personType == 2 ? (
          <Grid container className={classes.selectorHeaderContainer}>
            <Grid item xs={12}
              className={classes.selectorButtonSelected}>
              {t("Enterprise")}
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <SelectorSwitch textA={t("User")} textB={t("Enterprise")} selectedA={isUser} handleSwitch={handleSwitch} forPopup={true} size={Size.medium} />
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <div className={
        checkIsNullUndefined(removeAppNameLogout)
          ? classes.divBar
          : classes.divBarNoName
      }>

        {checkIsNullUndefined(removeAppNameLogout) ?
          <Typography variant="h6" className={classes.title2} noWrap>
            {appName}
          </Typography>
          : null}
      </div>

      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          className={classes.imgIcon}
          aria-label="menu"
          onClick={handleGoToMain}
        >
          <img
            src={correosIdLogoBlue}
            alt={appName}
            className={classes.imgLogin}
          />
        </IconButton>
        {checkIsNullUndefined(removeAppNameLogout) ?
          <div className={classes.verticalLine}>
            <IconButton
              edge="start"
              className={classes.buttonClass}
              aria-label="menu"
              onClick={handleList}
            >
              <DehazeIcon />
            </IconButton>
          </div>
          : null}
        <div className={classes.title}>
        </div>
        {openPage != EnumPages.Login || checkIsNullUndefined(personType) ? null : managePersonTypeSwitch()}
        {showName ?
          <Typography variant="h6" className={classes.title2} noWrap>
            {appName}
          </Typography> : null}
        {checkIsNullUndefined(removeAppNameLogout) ?
          <Tooltip title={t("exit")}>
            <IconButton
              edge="start"
              aria-label="menu"
              className={classes.logoutButton}
              onClick={handleLogout}
            >
              <FiLogOut />
            </IconButton>
          </Tooltip>
          : null}
      </Toolbar>
    </AppBar>
  );
}
