import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import {
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { BackgroundLogin } from "../../components/Templates/BackgroundLogin";
import { LogIn } from "../../components/LogIn/LogIn";
import { ChooseEnterprise } from "../../components/ChooseEnterprise/ChooseEnterprise";
import { checkIsNullUndefined, finishGlobalOperation } from "../../commons/Utils";
import { getSecureItem, getSessionItem } from "../../services/Commons";
import { Alerts } from "../../components/Alerts/Alerts";
import { secureValues, sessionValues } from "../../commons/EnumsGeneral";
import { Constants } from "../../commons/Constants";

export function LoginPage(props) {
  const { personType, personTypeSelected, isSSOPath, masterCountryList } = props;
  const cookies = new Cookies();

  const [enterprisePath, setEnterprisePath] = useState(false);
  const [authorizationUrl, setAuthorizationUrl] = useState("");
  const [code, setCode] = useState("");
  const [userValue, setUserValue] = useState("");
  const [SSOStatus, setSSOStatus] = useState(null);
  const [SSOResp, setSSOResp] = useState(null);
  const [cookieInfo, setCookieInfo] = useState(null);
  const [pagePrepared, setPagePrepared] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });

  useEffect(() => {
    manageCookies();
  }, [isSSOPath]);

  const finishOperation = (color, message, auxOpen) => {
    finishGlobalOperation(setAlert, color, message, auxOpen);
  };

  const manageCookies = () => {
    if (isSSOPath) {
      manageSSOPath();
    } else {
      let userCorreosIdSignIn = cookies.get("userCorreosIdSignIn");
      if (!checkIsNullUndefined(userCorreosIdSignIn)) {
        setCookieInfo(userCorreosIdSignIn);
      };
      setPagePrepared(true);
    };
  };

  const manageSSOPath = () => {
    let auxIdToken = jwt_decode(getSessionItem(sessionValues.idToken));
    let auxAppCode = getSessionItem(sessionValues.appCode);
    let auxToken = getSecureItem(secureValues.token);
    if ((!checkIsNullUndefined(auxIdToken.eid) || personType == 2) && auxAppCode != Constants.cidCode) {
      let auxStatus = getStatus(auxIdToken, auxToken);
      setSSOStatus(auxStatus);
      setEnterprisePath(true);
    } else {
      setSSOResp(auxToken);
    };
    setPagePrepared(true);
  };

  const getStatus = (auxIdToken, auxToken) => {
    let status = null;
    if (!checkIsNullUndefined(auxIdToken.eid)) {
      status = checkIsNullUndefined(auxToken.Status) ? 200 : auxToken.Status;
    };
    return status;
  };

  return (
    <Grid>
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      {enterprisePath ? (
        <BackgroundLogin>
          <ChooseEnterprise userValue={userValue} code={code} authorizationUrl={authorizationUrl} SSOStatus={SSOStatus} />
        </BackgroundLogin>
      ) : (
        pagePrepared ? (
          <BackgroundLogin login={true} mainLogin={true} >
            <LogIn
              setEnterprisePath={setEnterprisePath}
              setAuthorizationUrl={setAuthorizationUrl}
              setCode={setCode}
              setUserValue={setUserValue}
              personTypeSelected={personTypeSelected}
              SSOResp={SSOResp}
              cookieInfo={cookieInfo}
              finishOperation={finishOperation}
              masterCountryList={masterCountryList}
            />
          </BackgroundLogin>
        ) : <CircularProgress />
      )}
    </Grid>
  );
}
