export const ENTYC1 = {
    
    //TyCs and Privacy Page

    tycUrlsText: "Please read the terms and conditions of use carefully by clicking on the following links:",
    privacyUrlsText: "Please read the privacy policies carefully by clicking on the following links:",
    privacyNoUrlsText: "Para la aplicación y el tipo de persona indicados no existe privacidad que firmar.",
    tycNoUrlsText: "Para la aplicación y el tipo de persona indicados no existen TyC's que firmar.",
    atcText: "Once the verification code has been communicated to the ATC operator, the terms and conditions of use will be considered accepted.",
  
    //Terms and Conditions

    tycHeaderTitle: "Terms and Conditions",
  
    //WarningLegalTyC Subtittles

    tycWarningLegalSubtittle1: "INTELLECTUAL AND INDUSTRIAL PROPERTY RIGHTS",
    tycWarningLegalSubtittle2: "PERSONAL DATA PROTECTION",
    tycWarningLegalSubtittle3: "SECURITY MEASURES",
    tycWarningLegalSubtittle4: "RESPONSIBILITIES OF CORREOS",
    tycWarningLegalSubtittle5: "RESPONSIBILITY OF THE USER",
    tycWarningLegalSubtittle6: "APPLICABLE LAW AND JURISDICTION",
  
    //WarningLegalTyC texts
    tycWarningLegalText1:
      "In compliance with Article 10 of Act 34/2002 of 11 July, on Services in the Information Society and e-Commerce (LSSI-CE), you are informed that this website is the property of Correos.",
    tycWarningLegalText2:
      "This document contains the Legal Notice of “Correos ID” provided by Sociedad Estatal Correos y Telégrafos, S.A (hereafter, Correos), with registered office at Vía Dublín nº 7 (Campo de las Naciones) 28070 Madrid (Spain), CIF (tax ID) A-83052407.",
    tycWarningLegalText3:
      "Customers can contact the service by telephone on the Correos Customer Services number: 902 197 197 or the Correos website.",
    tycWarningLegalText4:
      "The design and source code of the Portal and all logos, brands, and other distinctive signs appearing thereon belong to Correos and are protected by the corresponding intellectual and industrial property rights.",
    tycWarningLegalText5:
      "Their use, reproduction, distribution, public communication, transformation, or any similar or analogous activity is strictly prohibited without express written authorisation by Correos.",
    tycWarningLegalText6:
      "The content of the Portal is also protected by the intellectual property rights of Correos. The use of the content created by Correos or any of the entities in its Group will be permitted only within the scope of a contractual relationship with Correos or said entities.",
    tycWarningLegalText7:
      "Correos states its respect for third-party intellectual and industrial property rights; therefore, if you consider this site to be in breach of your rights, please contact Correos on the following phone line: 902 197 197.",
    tycWarningLegalText8_1:
      "Browsing our website does not require prior registration. However, when you visit our website, it is standard for our web servers to store information such as the IP address and domain from which you are accessing it, the date and time of the visit, etc. On the other hand, certain features of our website require you to provide additional information through forms (e.g., your first name and surname, postal or email address, telephone number, etc.). Correos will process your personal data according to the conditions published in the ",
    tycWarningLegalText8_B: "Website Privacy Policy ",
    tycWarningLegalText8_2:
      "and where applicable, the Data Protection Policy of the different requested services.",
    tycWarningLegalText9:
      "Please note that we have adopted all the necessary technical, organisational and computer security measures for the protection of personal data, in order to avoid their alteration, loss, or unauthorised processing and/or access, in accordance with the applicable legislation.",
    tycWarningLegalText10:
      "Misuse of the Portal: Correos has created the Portal to promote its products and provide access to its services, but it cannot control its use in ways not provided for in this Legal Notice; therefore, access to the Portal and the correct use of the information it contains are the responsibility of the person taking these actions. Correos accepts no liability for any incorrect, illicit or negligent use that Users may make of the Portal.",
    tycWarningLegalText11:
      "Use of the contents: Correos provides all the content of its Portal (Correos ID) in good faith and makes its best effort to ensure it is always updated and current; however, Correos cannot accept any liability for the use of or access to such content by Users outside the scope for which the Portal is intended, nor for the consequences which may arise from the practical application of reviews, recommendations or studies which they may access through the Portal, the ultimate responsibility for which will always rest upon the User. Similarly, Correos cannot monitor content which it has not created or commissioned, and therefore will accept no liability for any loss or damage arising from such third-party content.",
    tycWarningLegalText12:
      "Advertising: Correos will include its own and third-party advertising on the Portal in order to offer products or services which may be of interest to you. However, Correos cannot control the appearance of such advertising, nor the quality or suitability of such products or services, and therefore, Correos will accept no liability for any loss or damage to the user caused by them.",
    tycWarningLegalText13:
      "Viruses: Correos undertakes to apply all necessary measures to ensure there are no computer viruses or similar elements on its Portal. However, these measures are not infallible, and therefore Correos cannot guarantee the total absence of such harmful elements. Consequently, Correos will accept no liability for any loss or damage arising from such elements.",
    tycWarningLegalText14:
      "Technological failures: Correos has secured all the contracts needed for the continuity of its Portal and will make its best efforts to ensure its service is uninterrupted, but it cannot guarantee the absence of technological failures, or the permanent availability of the Portal and the products or services it contains. Consequently, it will accept no liability for any loss or damage arising from a lack of availability or for failure to access due to disconnections, breakdowns, overloads, or network failures not attributable to Correos.",
    tycWarningLegalText15:
      "Information exchange: the Portal may contain certain areas designed for different Users to share their experiences and to encourage the exchange of information. However, in these forums Correos will act merely as a gathering place and will not control their content, and therefore will accept no responsibility for the truthfulness or relevance of any statements made thereon by Users.",
    tycWarningLegalText16:
      "Users undertake to use the services of the Portal in accordance with the terms set forth in this Legal Notice, which they accept by the act of using the Portal.",
    tycWarningLegalText18:
      "Users who act against the image, good name or reputation of Correos, or who make illicit or fraudulent use of the designs, logos or content of the Portal, will be liable to Correos for such actions.",
    tycWarningLegalText19:
      "The applicable law in cases of dispute or conflict over the interpretation of the terms set forth in this Legal Notice, and any issue relating to the products or services of this Portal, will be the law of Spain, unless otherwise established by regulations protecting consumers and users.",
    tycWarningLegalText20:
      "For the resolution of conflicts, the parties accept the jurisdiction of the Courts and Tribunals of the corresponding Spanish provincial capital.",
  
    //PrivacyPoliticyTyC Subtittles

    tycPrivacyPoliticyTyCSubtittle1: "Data controller",
    tycPrivacyPoliticyTyCSubtittle2: "Purpose and legitimacy of processing",
    tycPrivacyPoliticyTyCSubtittle3: "Type of data processed",
    tycPrivacyPoliticyTyCSubtittle4: "Data storage time",
    tycPrivacyPoliticyTyCSubtittle5: "Your rights",
    tycPrivacyPoliticyTyCSubtittle6: "Third-party access",
  
    //PrivacyPoliticyTyC list tittles

    tycPrivacyPoliticyTyCListTittle1: "Provision of the Correos ID service",
    tycPrivacyPoliticyTyCListTittle2:
      "Sending offers and promotions about Correos products and services",
    tycPrivacyPoliticyTyCListTittle3:
      "Sending offers and promotions from Correos Group companies and third-party companies",
    tycPrivacyPoliticyTyCListTittle4:
      "Sharing data with other Correos Group companies and third-party companies",
    tycPrivacyPoliticyTyCListTittle5: "Compliance with legal obligations",
    tycPrivacyPoliticyTyCListTittle6:
      "Statistical information and customer satisfaction surveys",
    tycPrivacyPoliticyTyCListTittle7: "Enriching your information",
    tycPrivacyPoliticyTyCListTittle8: "Data provided directly:",
    tycPrivacyPoliticyTyCListTittle9: "Data collected indirectly:",
    tycPrivacyPoliticyTyCListTittle10:
      "Data stemming from provision of the service:",
  
    //PrivacyPoliticyTyC texts

    tycPrivacyPoliticyTyCText1:
      "Respecting your privacy and complying with data protection regulations are vitally important to all the companies in Correos Group.",
    tycPrivacyPoliticyTyCText2_1:
      "We want you to know why we are using, or want to use, your data, and what your rights are, at all times. With this goal, we have drafted the following Data Protection Policy governing the processing of data arising from the Correos ID service or browsing the website ",
    tycPrivacyPoliticyTyCText2_B1: "id.correos.post",
    tycPrivacyPoliticyTyCText2_2: " (previously ",
    tycPrivacyPoliticyTyCText2_B2: "emiidentidad.correos.post",
    tycPrivacyPoliticyTyCText2_3:
      " ) and any of its subdomains, or other Correos websites where this Privacy Policy is shown in the footer.",
    tycPrivacyPoliticyTyCText3:
      "This following may be subject to change to adapt it to future changes in the law or industry practices. In all cases, the user will be notified of such changes by any valid means of communication.",
    tycPrivacyPoliticyTyCText4:
      "The data controller is “Sociedad Estatal Correos y Telégrafos, S.A., S.M.E” (“Correos”), with Spanish Tax ID No. A-83052407 and registered address at Vía Dublín no. 7 (Campo de las Naciones) 28070 Madrid (Spain).",
    tycPrivacyPoliticyTyCText5:
      "For the purpose of guaranteeing the proper management of said data processing activity, Correos has appointed a Data Protection Officer (DPO or DPD) who can be contacted at the following email address regarding any matters related to this issue: ",
    tycPrivacyPoliticyTyCText6:
      "Your data is processed for the following purposes:",
    tycPrivacyPoliticyTyCText7: "Your data will be processed for",
    tycPrivacyPoliticyTyCText8:
      "Registering you as a user via the form provided for that purpose.",
    tycPrivacyPoliticyTyCText9:
      "When registering for any of the digital services available through Correos ID, you may be asked for additional information via an online form provided for that purpose.",
    tycPrivacyPoliticyTyCText10:
      "Providing the services of “Correos ID”, in accordance with the Specific Conditions of the Service.",
    tycPrivacyPoliticyTyCText11:
      "In this case Correos ID is a centralised digital identity manager, enabling users to identity themselves to the different digital services of Correos and/or any third-party services accepting this method as an identification system; i.e., briefly, users may access different services through a single digital identification process, avoiding the need for a password for each service.",
    tycPrivacyPoliticyTyCText12:
      "If the Correos ID service is used as a digital identification platform for services provided by other companies in the Correos Group or third-party partners, user data must be communicated to these companies in order to verify the user’s identity.",
    tycPrivacyPoliticyTyCText13: "Responding to your questions or complaints.",
    tycPrivacyPoliticyTyCText14:
      "Verifying your identity, arising from a partnership and/or service provision agreement between Correos and a third party.",
    tycPrivacyPoliticyTyCText15:
      "Also, the centralised digital identity manager may process the verification of your identity, arising from a partnership and/or service provision agreement between Correos and a third party.",
    tycPrivacyPoliticyTyCText16:
      "If you provide your consent, Correos may send you commercial information related to the provision, improvement and updating of our products and services, new rates and discounts via any electronic or non-electronic channel.",
    tycPrivacyPoliticyTyCText17:
      "This commercial information may be generic or personalised. In the latter case it will imply the prior processing of your data in order to profile and characterise you and determine your tastes, preferences and needs, and, based on them, identify the offers that may be of most interest to you.",
    tycPrivacyPoliticyTyCText18:
      "If you provide your consent, Correos may send offers and promotions about the following to you via any electronic or non-electronic channel:",
    tycPrivacyPoliticyTyCText19:
      "Other Correos Group companies that provide services related to the sectors of package delivery, added-value postal services, logistics, marketing and telecommunications.",
    tycPrivacyPoliticyTyCText20:
      "Other companies with which Correos has entered into partnership agreements and that may belong to sectors such as telecommunications, finance, wholesale, healthcare or NGOs.",
    tycPrivacyPoliticyTyCText21:
      "This commercial information may be generic or personalised. In the latter case it will imply the prior processing of your data in order to profile and characterise you and determine your tastes, preferences and needs, and, based on them, identify the offers that may be of most interest to you.",
    tycPrivacyPoliticyTyCText22:
      "If you provide your consent, Correos may share your data with Correos Group companies and other companies whose sectors are detailed above so that these companies can send you offers and promotions related to their products and services directly without Correos intervening.",
    tycPrivacyPoliticyTyCText23:
      "Some of the various legal obligations assumed by Correos include those derived from applying the postal regulations (Law 43/2010 on the universal postal service, the rights of users and the postal market), the obligation of secrecy of communications (Article 18 of the Constitution), and tax law.",
    tycPrivacyPoliticyTyCText24:
      "In compliance with these obligations, Correos may communicate your data to Public Administrations and courts whenever said information is required for established legal proceedings.",
    tycPrivacyPoliticyTyCText25:
      "Correos has a legitimate interest in knowing the opinion of its customers regarding its products and services, as well as their consumption habits (e.g. the frequency/recurrence of contracting different services), in order to be able to offer products and services that are better in line with their needs, and to improve its current products and services and contracting process.",
    tycPrivacyPoliticyTyCText26:
      "To achieve the purpose detailed above, Correos may process your data and:",
    tycPrivacyPoliticyTyCText27:
      "Conduct satisfaction surveys, either by telephone or electronic means, even after you are no longer a customer, in the latter case to find out why you left.",
    tycPrivacyPoliticyTyCText28:
      "If, during the satisfaction survey, a customer states their opinion of the products and services of any of the Correos Group companies, the result of the survey and the identifying information of the participant may be communicated to the Correos Group company in question.",
    tycPrivacyPoliticyTyCText29:
      "Anonymise it, so that the information concerning your consumer habits cannot subsequently be linked to your person, and then carry out statistical and econometric studies (e.g. to know the customer’s life cycle and/or identify models of propensity to abandonment).",
    tycPrivacyPoliticyTyCText30:
      "If you provide your consent, Correos may obtain more information about your characteristics, tastes and needs through the information provided by different external sources such as public records, land registry, social networks, geomarketing tools, economic information databases such as INFORMA, credit histories such as ASNEF, BADEXCUG, etc. in order to make improvements to our products and services and/or adapt or choose the offers for these products and services that best suit your specific profile.",
    tycPrivacyPoliticyTyCText31:
      "For the purposes detailed above, a set of personal data is processed that can be divided into the following sources and categories:",
    tycPrivacyPoliticyTyCText32:
      "At the time of registration, users must provide a minimum of information needed for their verification and registration, so unless otherwise indicated, if this information is not provided the service cannot be provided. Certain functions (e.g., access to certain services) require you to provide additional information through the service itself.",
    tycPrivacyPoliticyTyCText33:
      "Also, together with the information provided through the service, other information gathered by the server will be processed, such as the IP address from which you access it, the domain leading to it, the data and time of your visit, session cookies (which are deleted when you close the browser), etc.",
    tycPrivacyPoliticyTyCText34:
      "The customer ensures that the data given to Correos for the provision of the requested services are accurate and shall communicate any change that may affect them. As a result, the customer is liable to Correos and third parties for any damage or harm caused as a result of not complying with the obligations assumed in this clause.",
    tycPrivacyPoliticyTyCText35_1:
      "Todos aquellos datos que, en caso de haber prestado su consentimiento, Correos recaba de la tipología de fuentes " +
      'externas enumerada en la finalidad "H" del apartado ',
    tycPrivacyPoliticyTyCText35_B: '"Purpose and legitimacy of processing".',
    tycPrivacyPoliticyTyCText36:
      "As well as in the previous case, third parties such as Public Administrations, the company where you work, or other institutions with which Correos has a partnership and/or service provision agreement, and which are interested in contracting this method as an identification system, may send us or give us access to your data so that we can verify your identity. The entities with which Correos has a partnership and/or service provision agreement may, if applicable, disable the identification system or apply different or additional conditions, in accordance with such partnership and/or service provision agreements.",
    tycPrivacyPoliticyTyCText37:
      "This information may include: (i) A record of products and services contracted from Correos, the type of service and its characteristics (e.g. Correos digital services for which you are registered, use of other Correos services such as sending mail or parcels, Citypaq, etc.); (ii) Complaints or requests for information, etc. (iii) Browsing information based on the cookies installed on your device with your previous consent, if required, as established in our Cookies Policy.",
    tycPrivacyPoliticyTyCText38:
      "Your personal data will be stored as long as necessary for the provision of the Correos ID service, unless you request their deletion. The Identity deletion process involves blocking or restricting the use of your Identity and data for thirty (30) days, after which they will be deleted. During the period of restriction on the use of your Identity, you can recover your Identity following the process provided by Correos ID.",
    tycPrivacyPoliticyTyCText39:
      "Correos will block inactive users - i.e., those who do not access any of the digital services of Correos or of other companies using Correos ID as an identification platform - for a period of 5 years.",
    tycPrivacyPoliticyTyCText40:
      "Once the processing of your data is no longer necessary, the data will be erased in accordance with data protection regulations, which involves blocking them; they will only be available at the request of judges and courts of law, the Public Prosecutor and competent Public Administrations during the limitation period for the actions that could arise. They will subsequently be deleted. In general, they will be erased after a period of 3 years.",
    tycPrivacyPoliticyTyCText41:
      "Our data protection regulations provide you with a series of rights relating to the data processing involved in our services, and they can be summarised as follows:",
    tycPrivacyPoliticyTyCText42:
      "- Right of access: Knowing what type of data we are processing and the type of processing we are carrying out.",
    tycPrivacyPoliticyTyCText43:
      "- Right to rectification: Being able to request the modification of your data if they are inaccurate or false.",
    tycPrivacyPoliticyTyCText44:
      "- Right to data portability: Being able to obtain a copy of the data being processed in an interoperable format.",
    tycPrivacyPoliticyTyCText45:
      "- Right to restrict processing, in those cases provided for by law.",
    tycPrivacyPoliticyTyCText46:
      "- Right to erasure: Requesting the deletion of your data when the processing is no longer necessary.",
    tycPrivacyPoliticyTyCText47:
      "- Right to object: This right allows you, for example, to request for us to stop sending you commercial communications.",
    tycPrivacyPoliticyTyCText48: "- Right to withdraw consent.",
    tycPrivacyPoliticyTyCText49:
      "You can exercise your rights through any of the following channels:",
    tycPrivacyPoliticyTyCText50:
      "- Postal address: Vía Dublín no. 7 (Campo de las Naciones) 28070 Madrid (Spain)",
    tycPrivacyPoliticyTyCText51: "- Email address: ",
    tycPrivacyPoliticyTyCText52:
      "All commercial electronic communications will include an unsubscribe mechanism or similar method enabling you to opt out of future communications.",
    tycPrivacyPoliticyTyCText53:
      "You can find a set of forms that will help you exercise your rights on the website of the Spanish Data Protection Agency (AEPD). Moreover, we inform you that you have the right to file a claim with the control authority (in Spain, the AEPD) if you feel that your rights have been breached.",
    tycPrivacyPoliticyTyCText54:
      "In the provision of certain services, it may be necessary for you to provide us with the personal and contact information of third parties, like, for instance, the name, surnames, postal address, email address, telephone number, and other data or equivalent means of contact for the recipient of a communication or parcel.",
    tycPrivacyPoliticyTyCText55:
      "Additionally, in order to better provide the service, the telephone number or email address may be used to inform the recipient of the provision of the service and handle any incident that may affect them.",
    tycPrivacyPoliticyTyCText56:
      "In this sense, the customer ensures the truthfulness and accuracy of the recipient’s information and, where applicable, confirms having complied with all data protection obligations that may be attributable to them, having obtained the recipient’s consent for Correos to carry out the processing described above, should said consent be necessary.",
    tycPrivacyPoliticyTyCText57:
      "If Correos should access the personal data necessary for the provision of the identity verification service, under the responsibility of a company or professional, Correos undertakes to comply with all the obligations inherent in its position as data processor, and therefore:",
    tycPrivacyPoliticyTyCText58:
      "It will access and process the data to which it has access subject to the instructions thereof, not using said data for purposes other than service provision. To do so, it may use the services of other third parties (sub-processors), if it becomes necessary for the service provision and contracting operations and/or storage, provided that (i) the new processor is subject to the same terms and conditions (instructions, obligations, safety measures, etc.) and the same formal requirements in terms of the correct processing of personal data and guaranteeing the rights of the individuals involved; (ii) Correos, upon explicit request, makes available a list identifying the sub-processed services and the identity of the sub-processors; and (iii) If the sub-processor is not compliant, Correos will continue to be fully liable.",
    tycPrivacyPoliticyTyCText59:
      "It will implement the proper legal, technical and organisational measures to guarantee a level of security that is proportionate to the risk, in accordance with the provisions of Article 32 of the GDPR and, upon your request, will inform you of the risk assessment methodology used.",
    tycPrivacyPoliticyTyCText60:
      "When the provision of service subject to the contract comes to an end, it will destroy or return the processed data as well as any media or documents containing the data that have been processed, without prejudice to the option to store these data, under due protection, pursuant to the terms of our data protection regulations.",
    tycPrivacyPoliticyTyCText61:
      "It will ensure compliance with all other obligations contained in the data protection regulations.",
  };
  