import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { checkIsNullUndefined } from "../../../commons/Utils";
import { EnumPages } from "../../../commons/EnumPages";
import { useTranslation } from "react-i18next";
import history from "../../../services/history";

export function SubMenu(props) {
  const {
    setSubMenuKey,
    setShowSideMenu,
    code,
    enterprise,
    subMenuKey,
    options,
    setSelected,
    setResetPage,
    resetPage,
    isSpecialUser
  } = props;
  const { t, i18n } = useTranslation();

  const [pageSelected, setPageSelected] = useState();
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    
    let filteredArray = options.filter(item => {
      if (item === "UserServices" || item === "EnterpriseServices") {
        return !isSpecialUser;
      } else if (item === "EnterpriseTeam" || item === "EnterpriseDelete") {
        return enterprise.idRol == 1 && !isSpecialUser;
      }
      return true;
    });
    if (process.env.REACT_APP_ENVELOP_ACTIVE_AUXILIAR_TAB == "true") {
      if (!checkIsNullUndefined(enterprise)){
        filteredArray.push(EnumPages.AuxEnterpriseData);
      }else{
        filteredArray.push(EnumPages.AuxUserData);
      }
      
    }
    setFilteredOptions(filteredArray);

  }, [isSpecialUser]);

  const handleSelectPage = (elem, index) => {
    setSelected(false);
    setPageSelected(elem);
    setSubMenuKey(code);
    setShowSideMenu();
    if (!checkIsNullUndefined(enterprise)) {
      history.push("/Index/" + enterprise.oid + "/" + EnumPages[elem]);
      if (EnumPages[elem] == EnumPages.AuxEnterpriseData || EnumPages[elem] == EnumPages.EnterpriseData) {
        setResetPage(!resetPage);
      }
    } else {
      history.push("/Index/" + EnumPages[elem]);
      if (EnumPages[elem] == EnumPages.AuxUserData || EnumPages[elem] == EnumPages.UserData) {
        setResetPage(!resetPage);
      }
    }
  };

  return (
    <List key={code} style={{ padding: "0px", color: "#002453" }}>
      {filteredOptions.map((elem, index) => {
        return (
          <ListItem
            key={index}
            style={{ paddingLeft: "40px" }}
            button
            onClick={() => handleSelectPage(elem, index)}
            selected={pageSelected === elem && code === subMenuKey}
          >
            {enterprise ? (
              <ListItemText
                primary={t(elem)}
                primaryTypographyProps={{ noWrap: true }}
              />
            ) : (
              <ListItemText
                primary={t(elem)}
                primaryTypographyProps={{ noWrap: true }}
              />
            )}
          </ListItem>
        );
      }

      )}
    </List>
  );
}
