export const CA2 = {
    
    //User Account Cancel Page
  
    accountCancelDisableAccountAsk: "¿Seguro que quieres darte de baja?",
    accountCancelDisableAccountTitle:
      "Si us doneu de baixa de Correos ID deixareu de tenir accés a tots els serveis que es mostren a continuació:",
    accountCancelDisableAccountListTitle: "Tingues en compte que:",
    accountCancelDisableAccountList: "Esborrarem el vostre compte.",
    accountCancelDisableAccountP1:
      "Us aconsellem que abans d’eliminar el compte de Correos ID sol·liciteu i finalitzeu la baixa en els serveis en què esteu registrat. Si tot i així continueu preferint eliminar ara el vostre compte de Correos ID, cliqueu “Dona de baixa”.",
    accountCancelDisableAccountP2:
      "*Tranquil, teniu {disableAccDays} dies per recuperar el compte de Correos ID, per si us en penediu. Podreu recuperar el compte de Correos ID, però la resta de serveis poden no desar les vostres dades durant aquests {disableAccDays} dies.",
    accountCancelDisableAccountConfirm: "Dona de baixa",
    accountCancelDisableAccountLastWarn: "Si pulsas en aceptar confirmarás que quieres darte de baja",
  
    //Enterprise Delete Page
  
    enterpriseDeleteEnterpriseTitle: "Segur que voleu esborrar l'empresa {enterpriseName} ?",
    enterpriseDeleteEnterpriseTitleNoName: "Segur que voleu esborrar l'empresa?",
    enterpriseDeleteEnterpriseServicesTitle: "Actualment aquesta empresa té accés als serveis que es mostren a continuació:",
    enterpriseDeleteEnterpriseText: "Un cop esborrada no podràs recuperar les dades referents a ella",
    enterpriseDeleteButtonText: "Eliminar Empresa",
    enterpriseDeleteAlertMessageSuccess: "Empresa eliminada amb èxit",
    enterpriseDeleteAlertMessageError: "Error en eliminar l'empresa",
    enterpriseDeleteAlertLastWarning: "Si pulsas en aceptar confirmarás que quieres borrar la empresa",
  
    //Privacy page

    privacyPermissionsManage: "Revisa els termes i condicions",
    privacyPrivacyManage: "Gestioneu la vostra privacitat",
    privacyUnsuscribe: "Retira l’accés a les meves dades",
    privacySuscribe: "Permet l’accés a les meves dades",
    privacyDownload: "Baixa el meu historial de privacitat",
    privacySuccessChange: "Privacitat canviada correctament",
    privacyWarning: "Atenció!",
    privacyInfo1:
      "Esteu a punt de retirar l’accés de Mi Buzón al vostre compte de Correos ID. Haureu de concedir accés de nou si voleu tornar a accedir a Mi Buzón. Des del moment en què accepteu, deixarem de compartir les vostres dades amb el servei.",
    privacyInfo1_PARAM:
      "Esteu a punt de retirar l’accés de {APP} al vostre compte de Correos ID. Haureu de concedir accés de nou si voleu tornar a accedir a {APP}. Des del moment en què accepteu, deixarem de compartir les vostres dades amb el servei.",
  
    privacyInfo2:
      "Si el que voleu és que Mi Buzón elimini el vostre compte, us podem ajudar a gestionar la baixa si cliqueu aquí.",
    noState: "Sense estat",
    privacyTyCLink: "Termes i Condicions",
    privacyPrivacyLink: "Políticas de Privacidad",
    privacySignedTyCPrivacy: "Signat:",
    privacyIntroText: "Revisa los términos y condiciones, así como las poíticas de privacidad firmadas para cada servicio suscrito.",
    privacyIntroText2: "Gestiona la privacidad de Correos ID. Puedes revisar el estado actual de la protección de datos y modificarlo si así lo requieres.",
    privacyDataProtectionTitle: "Protección de Datos",
    privacyEnableAllText: "Activar todos",
    privacyDisableAllText: "Desactivar todos",
    //Team Page
    holderName: "Nom",
    holderLastName: "1r cognom",
    holderSecondLastName: "2n cognom",
    holderPrefix: "Prefix",
    holderMobile: "Mòbil",
    holderEmail: "Adreça electrònica",
    teamPageHolderTitle: "Titular del compte",
    teamPageAuthorizedTitle: "Les meves persones autoritzades",
    teamPageAuthorizedName: "Nom",
    teamPageAuthorizedLastName: "Cognoms",
    teamPageAuthorizedState: "Estat",
    teamPageAuthorizedRol: "Rol",
    teamPageButtonAddEmployee: "Afegeix empleat",
    teamPageButtonChangeHolder: "Canvia titular",
    teamPageInsertMail: "Adreça electrònica",
    teamPageInsertNewMail: "Introduïu l’adreça electrònica i el rol",
    teamPageSelectHolder: "Seleccioneu el nou titular",
    teamPageRol: "Rol:",
    teamPageItemAdmin: "Administrador",
    teamPageItemAutorized: "Autoritzat",
    teamPageChangeHolder: "Canvia titular",
    teamPageInvitationSuccess: "Invitació enviada correctament",
    teamPageTitularChangeSuccess: "Titular canviat correctament",
    teamPageTitularEmpty: "L’empresa no té titular",
    revokeAdminPermissions: "Quitar persmisos de administrador",
    revokeAdminPermissionsQuestion:
      "Si continúas, quitarás permisos de administrador a este empleado, pero seguirá siendo empleado y perteneciendo al equipo",
    revokeAdminConfirm: "Permisos quitados correctamente",
    teamPageAddAdminTitle: "Dar permisos de administrador",
    teamPageAddAdminConfirmMsg:
      "Se va a enviar un email para que el usuario acepte el rol de administrador.",
    teamPageSubtitle: "Consulta y gestiona el equipo de la empresa",
  
    //unifyAccounts Page

    unifyAccountsTitle: "Unificación de cuentas",
    unifyAccountsPreviousInfoTitle: "Información previa",
    unifyAccountsPreviousInfo: "Información que puede ser de ayuda de cada usuario",
    unifyAccountsSubTitle: "Selecciona de entre los datos de usuario que son distintos entre ambas cuentas con cuál de ellos prefieres quedarte:",
    unifyAccountsDataTitle: "Datos de Usuario",
    unifyAccountsData1: "Datos de {user1}",
    unifyAccountsData2: "Datos de {user2}",
    unifyAccountsMultidata: "Ahora selecciona si hay alguno de los emails, teléfonos o direcciones de {user2} que quieres añadir en el perfil de {user1}",
    unifyAccountsMultidataTitle: "Emails, teléfonos y direcciones",
    unifyAccountsEmails1: "Emails de {user1}",
    unifyAccountsEmails2: "Emails de {user2}",
    unifyAccountsPhones1: "Teléfonos de {user1}",
    unifyAccountsPhones2: "Teléfonos de {user2}",
    unifyAccountsAddress1: "Direcciónes de {user1}",
    unifyAccountsAddress2: "Direcciónes de {user2}",
    unifyAccountsInfo1: "Información de {user1}",
    unifyAccountsInfo2: "Información de {user2}",
    unifyAccountsEnterpriseTitle: "Empresas",
    unifyAccountsEnterprise: "Aquí puedes ver las empresas de {user1} y las que se van a unificar del {user2}",
    unifyAccountsEnteprise1: "Empresas de {user1}",
    unifyAccountsEnteprise2: "Empresas de {user2}",
    unifyAccountsServiceTitle: "Servicios",
    unifyAccountsService: "Aquí puedes ver las suscripciones de {user1} y las aplicaciones a las que se va a suscribir de {user2}",
    unifyAccountsService1: "Aplicaciones suscritas de {user1}",
    unifyAccountsService2: "Aplicaciones suscritas de {user2}",
    unifyAccountsNoData: "Este usuario no tiene datos de este tipo",
    unifyAccountsSuccess: "Unificación finalizada",
    unifyAccountsText: "Las dos cuentas se han unificado, ya puedes seguir navegando",
    unifyAccountsRedirectWarn: "Tienes que indicar la pantalla a la que hay que redirigir",
    unifyAccountIncorrectId: "El identificador de usuario proporcionado no corresponde con ninguna cuenta",
    unifyAccountsMobileNoDoc: "Sin documento",
    unifyAccountsMobileuserData: "Escoge el usuario cuyos datos quieras conservar",
    unifyAccountsMobileuserDocument: "Escoge qué documento quieres conservar",
    unifyAccountsMobileuserEmail: "Selecciona los emails de {user2} que quieras incorporar",
    unifyAccountsMobileuserPhone: "Selecciona los teléfonos de {user2} que quieras incorporar",
    unifyAccountsMobileuserAddress: "Selecciona las direcciones de {user2} que quieras incorporar",
    unifyAccountsMobileuserEnterprise: "Estas son todas las empresas que tendrás",
    unifyAccountsMobileuserSubscription: "Estás son todas las aplicaciones a las que estarás suscrito",
    unifyAccountsMobileuserInfo: "Aquí aportamos información útil de cada usuario",
    unifyAccountsMobileUserInfoLogin: "Último login: ",
    unifyAccountsUserInfoLogin: "Último login",
  
    //Mobile unifyAccouts

    userName: "Nombre",
    userSurname1: "Apellido 1",
    userSurname2: "Apellido 2",
    userbirthDate: "Fecha de nacimiento",
    userNacionality: "Nacionalidad",
    userDocument: "NIF",
    userGender: "Sexo",
    userLanguage: "Idioma",
    stepPage: "Paso {stepPage} de {totalSteps}",
    userInfoMobile: "Información útil",
    userDataMobile: "Datos de usuario",
    emailsMobile: "Emails",
    phonesMobile: "Teléfonos",
    documentsMobile: "Documentos",
    addressMobile: "Direcciones",
    servicesMobile: "Suscripciones",
    enterpriseMobile: "Empresas",
    nextStep: "Siguiente",
    previousStep: "Anterior",
    saveMobile: "Guardar",
  
    //UploadDocumentModal

    uploadDocumentModalContent:
      "Si us plau, tingues en compte que si ja existeix un document de la mateixa tipologia en procés de verificació, aquesta es perdrà.",
    uploadDocumentModalTitle: "Estàs a punt de pujar un document nou",
    uploadDocumentFormatUnsupported: "Format de document no admès.",
    uploadDocumentSuccess: "Document pujat correctament",
    uploadDocumentError: "Hi ha hagut un problema en pujar l’arxiu",
  
    // AtcPage - Stepper

    atcSearchUser: "Cerca usuari",
    atcSelectAccount: "Selecciona/Crea compte",
    atcUserVerify: "Verifica usuari",
    atcUserData: "Dades d’usuari",
    atcIdentifierField: "Introduïu: DNI, passaport, telèfon o adreça electrònica",
    atcOtpText: "Per garantir la vostra identitat, enviarem un codi:",
    atcSendEmailButton: "ENVIA CORREU ELECTRÒNIC",
    atcResendEmailButton: "REENVIA CORREU ELECTRÒNIC",
    atcSendSmsButton: "ENVIA SMS",
    atcResendSmsButton: "REENVIA SMS",
    atcRegisterPagePrefix: "Prefix",
    atcRegisterPagePhone: "Telèfon",
    atcRegisterPageEmail: "Adreça electrònica",
    atcRegisterPageDniNie: "DNI, passaport",
    atcRegisterPageSendOTP: "Introduïu el codi enviat",
    atcRegisterPageSendConsentSms: "ENVIA SMS TIC",
    atcRegisterPageResendConsentSms: "REENVIA SMS TIC",
    atcCheckOtpCode: "COMPROVA EL CODI",
    atcAccept: "ACCEPTA",
    atcClose: "TANCA",
    atcCancel: "CANCEL·LA",
    atcCreate: "CREA",
    atcModify: "MODIFICA",
    atcVerifyDocumentIdentifier: "VERIFICA DNI/PASSAPORT",
    atcPhoneError: "El telèfon és obligatori, introduïu un format vàlid",
    atcIdentifierDocumentError:
      "El DNI/passaport és obligatori, introduïu un format vàlid",
    atcEmailError:
      "L’adreça electrònica és obligatòria, introduïu un format vàlid",
    atcNewAccount: "Creeu un compte nou",
    atcRadioSelectAccount: 'Seleccioneu el vostre compte o l’opció "Compte nou"',
    atcModalTitle: "Dades verificades",
    atcModalSubtitle: "Si us plau, seleccioneu el vostre compte",
    atcModalEmailTitle: "Adreces electròniques:",
    atcModalPhoneTitle: "Números de telèfon:",
  
    //DialogMissingData

    dialogMissingDataText: "Hay acciones requeridas para el correcto funcionamiento de la aplicación:",
    dialogMissingDataTextTyC: "Para ello antes debes aceptar la privacidad y los términos y condiciones",
    dialogMissingDataTextDocument: "Necesitas verificar tu documento identificativo lo que implica que no puedes continuar a partir de aquí. Para poder verificarlo te recomendamos que te dirijas a tu oficina de correos más cercana.",
    dialogMissingDataTextNoHolder: "La empresa no tiene los datos obligatorios necesarios, ponte en contacto con el titular para poder continuar.",
    dialogMissingDataunsuscribedEnterprise: "Es necesario suscribirse a la aplicación",
    dialogMissingDatapendingEnterprisePrivacy: "Para ello antes debes aceptar la privacidad y los términos y condiciones",
    dialogMissingDataincompleteUserData: "Rellenar datos de usuario obligatorios",
    dialogMissingDataincompleteEnterpriseData: "Rellenar datos de empresa obligatorios",
    dialogMissingDatapendingEnterprise: "Crear una empresa",
    dialogMissingDataverifyEmail: "Añadir un email verificado",
    dialogMissingDataverifyPhone: "Añadir un teléfono verificado",
    dialogMissingDataverifyAddress: "Añadir una dirección verificada",
    dialogMissingUserDataText:
      "Les dades d’usuari requerides per al funcionament del servei no són correctes o són incompletes. Si us plau, actualitzeu les vostres dades.",
    dialogMissingEnterpriseDataText:
      "És necessària la creació d’una empresa per al funcionament correcte d’aquest servei. Si us plau, creeu la vostra empresa.",
  
    // Redirect Page
  
    redirectPageMissingParams: "Faltan parámetros",
    redirectPagePageNotFound: "La página no existe",
  
    // EnterpriseOauthLogin Page
  
    enterpriseOauthLoginPageError: "Faltan parámetros",
    redirectPageIdEnterpriseNotFound: "Se necesita el Id de empresa para acceder a esa página",
    redirectPageNotUserEnterprise: "La empresa indicada no pertenece al usuario",
  
    // ChooseEnterprise Page
  
    chooseEnterpriseNoSubsTitle: "Empresa no suscrita",
    chooseEnterpriseNoSubsText: "La empresa no está suscrita a la aplicación, ponte en contacto con el titular para que la suscriba y poder continuar",
    chooseEnterpriseNoPrivTitle: "Privacidad sin firmar",
    chooseEnterpriseNoPrivText: "La empresa no tiene firmada la privacidad, ponte en contacto con el titular para que la firme y poder continuar",
    chooseEnterpriseNoDataTitle: "Datos requeridos",
    chooseEnterpriseNoDataText: "La empresa no tiene todos los datos necesarios, ponte en contacto con el titular para que los rellene y poder continuar",

    // ResetPass Warn

    expiredPassTitle: "Contraseña caducada",
    expiredPassText: "La contraseña ha caducado, por favor, crea una nueva",

    //UserVerifications2FA
  verifications2FATitle: "Configura tu doble factor de autenticación",
  verifications2FAImageText: "Aquí puedes gestionar tus servicios con doble factor de autenticación. Activa o desactiva el doble factor de autenticación para cada uno de los servicios que tengas contratados teniendo en cuenta que hay servicios que no podrás desactivar porque lo exigen por normativa.",
  verifications2FAConfigText: "Servicios contratados",
  verifications2FASetPhone: "Configura tu teléfono",
  verifications2FASetEmailTitle: "Confirma tu identidad",
  verifications2FASetEmailText: "Mandaremos un código de verificación a tu email",
  verifications2FASetEmailConfText: "Mandaremos un código de verificación a tu email para confirmar tu identidad",
  verifications2FASetEmailOtp: "Código de verificación",
  verifications2FASetPhoneTitle: "Configura tu teléfono",
  verifications2FASetPhoneText: "Mandaremos un código de verificación a tu teléfono",
  verifications2FASetPhoneOtp: "Código de verificación",
  verifications2FASetPhonConfirmation: "Ya tienes configurado un teléfono para el doble factor de autenticación. Si estabas intentando hacer login en un servicio, por favor, vuelve a intentarlo.",
  verifications2FAConfigSuccess: "Tu configuración para el doble factor de autenticación se ha actualizado correctamente",

  // Login2FA

  login2FASetPhoneTitle: "Configura tu teléfono",
  login2FASetPhoneText: "Esta aplicación requiere un doble factor de autenticación y necesitas configurar tu teléfono. Para ello accede a la configuración de tu cuenta pulsando en 'Aceptar'.",
  login2FASendPhoneTitle: "Segundo factor de autenticación",
  login2FASendPhoneText: "Esta aplicación requiere un segundo factor de autenticación, introduce el código de verificación que enviaremos a tu teléfono.",
  login2FASetPhoneOtp: "Código de verificación",
  login2FASetPhoneError: "No se ha podido validar el teléfono. Por favor, inténtalo de nuevo.",

  // Oauth - ACM
  loginACMTitle: "Inici de sessió erroni",
  loginACMText: "Hi ha hagut un problema amb les dades aportades, si us plau torna a intentar-ho",
  generalACMTitle: "Atenció",
  generalACMTextGo: "Per poder accedir a l'aplicació heu de tornar a iniciar sessió",
  generalACMTextNoGo: "Torna a l'aplicació per iniciar sessió",

  // GeneralWarning
  generalWarningPassText: "S'ha produït un error en canviar la contrasenya. Si heu rebut el codi de validació potser l'heu introduït malament, o potser podria ser que el {dataType} que heu indicat sigui incorrecte, en aquest cas assegureu-vos d'haver-ho escrit bé.",
  generalWarningRegistryText: "S'ha produït un error en crear el compte. Si has rebut el codi de validació potser ho has introduït malament, o potser podria ser que el compte de correu electrònic que has indicat ja estigui registrat a CorreosID, en aquest cas hauries de recuperar el compte contrasenya.",
  generalWarningVerifyText: "S'ha produït un error en verificar el {dataType}. Si heu rebut el codi de validació potser l'heu introduït malament, o potser podria ser que el {dataType} que heu indicat ja estigui verificat a CorreosID.",

  // GoToUnifyAccounts
  goToUnifyAccountsButton: "Unificació de comptes",
  goToUnifyAccountsTitle1: "Verifica la teva identitat",
  goToUnifyAccountsTitle2: "Verifica el compte",
  goToUnifyAccountsText1: "Per poder unificar els comptes, has de verificar la teva identitat",
  goToUnifyAccountsText2: "Per poder unificar els comptes, has de verificar el compte a unificar",
  goToUnifyAccountsData: "Dada a verificar",
  goToUnifyAccountsOtpError: "S'ha produït un error en verificar {dataType}",
  };
  