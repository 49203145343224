export const Attributes = {
  UserEmail: "email",
  PersonaNumDocID: "number",
  PersonaTelfMovil: "phone",
  PersonaDireccion: "address",
  Documents: "Documents",
  EMA: { position: 10, size: 6, attributeName: "email" }, //Email
  PRE: { position: 20, size: 2, attributeName: "idCountry" }, //Prefijo
  MOV: { position: 30, size: 4, attributeName: "phone" }, //Teléfono
  NOM: { position: 40, size: 12, attributeName: "name" }, //Nombre
  SUR: { position: 50, size: 6, attributeName: "surname1" }, //Apellido1
  SU2: { position: 60, size: 6, attributeName: "surname2" }, //Apellido2
  FNA: { position: 70, size: 6, attributeName: "birthDate" }, //fecha nacimiento
  NAC: { position: 80, size: 6, attributeName: "nationality" }, //Nacionalidad
  TDO: { position: 90, size: 6, attributeName: "idTypeUserDocument" }, //Tipo de documento
  NDO: { position: 100, size: 6, attributeName: "number" }, //Nro documento
  PEX: { position: 110, size: 6, attributeName: "idCountry" }, //País de expedición
  FCA: { position: 120, size: 6, attributeName: "expirationDate" }, //Fecha de caducidad
  GEN: { position: 130, size: 6, attributeName: "idGender" }, //Sexo
  ALI: { position: 140, size: 12, attributeName: "alias" , attributeValue: "alias" }, //Alias
  PAI: { position: 150, size: 12, attributeName: "countryCode", attributeValue: "countryName" }, //País o región
  VIA: { position: 160, size: 4, attributeName: "roadTypeCode", attributeValue: "roadTypeName" }, //Via
  DIR: { position: 170, size: 8, attributeName: "address", attributeValue: "address" }, //Dirección
  AD1: { position: 180, size: 12, attributeName: "address1", attributeValue: "address1" }, //Dirección 1
  PRO: { position: 190, size: 4, attributeName: "provinceCode", attributeValue: "provinceName" }, //Provincia/Región
  POB: { position: 200, size: 4, attributeName: "cityCode", attributeValue: "cityName" }, //Población
  CPO: { position: 210, size: 4, attributeName: "postalCode", attributeValue: "postalCode" }, //Código postal  
  CMM: { position: 220, size: 12, attributeName: "comment", attributeValue: "comment" }, //Observaciones
  LAN: { position: 230, size: 6, attributeName: "idLanguage" }, //Observaciones

  EDN: { position: 520, size: 6, attributeName: "documentNumber" }, //Empresa Nro de documento
  ENM: { position: 500, size: 6, attributeName: "companyName" }, //Empresa Nombre
  ECN: { position: 510, size: 6, attributeName: "tradeName" }, //Empresa Nombre comercial
  ECY: { position: 540, size: 6, attributeName: "idCountry" }, //Empresa País
  EDE: { position: 530, size: 6, attributeName: "expirationDate" }, //Empresa Fecha de caducidad
  EPA: { position: 550, size: 6, attributeName: "idProfessionalActivity" }, //Empresa Actividad profesional
  ECI: { position: 560, size: 6, attributeName: null }, //Empresa Comercial
  ECM: { position: 650, size: 12, attributeName: "comment", attributeValue: "comment" }, //Empresa Observaciones
  EAL: { position: 570, size: 12, attributeName: "alias" ,attributeValue: "alias" }, //Empresa Alias
  EPI: { position: 580, size: 12, attributeName: "countryCode", attributeValue: "countryName" }, //Empresa País o región
  EVI: { position: 590, size: 4, attributeName: "roadTypeCode", attributeValue: "roadTypeName" }, //Empresa Tipo de dirección
  EDR: { position: 600, size: 8, attributeName: "address", attributeValue: "address" }, //Empresa Dirección
  ED1: { position: 610, size: 12, attributeName: "address1", attributeValue: "address1" }, //Empresa Dirección 1
  EPR: { position: 620, size: 4, attributeName: "provinceCode", attributeValue: "provinceName" }, //Empresa Provincia/Región
  EPO: { position: 630, size: 4, attributeName: "cityCode", attributeValue: "cityName" }, //Empresa Población
  ECP: { position: 640, size: 4, attributeName: "postalCode", attributeValue: "postalCode" }, //Empresa Código postal
};

export const AttributeCodes = {
  Email: "EMA", //Email
  Prefix: "PRE", //Prefijo
  Phone: "MOV", //Teléfono
  Name: "NOM", //Nombre
  Surname1: "SUR", //Apellido1
  Surname2: "SU2", //Apellido2
  Birthday: "FNA", //fecha nacimiento
  Nationality: "NAC", //Nacionalidad
  DocType: "TDO", //Tipo de documento
  DocNumber: "NDO", //Nro documento
  ExpedCountry: "PEX", //País de expedición
  ExpirDate: "FCA", //Fecha de caducidad
  Sex: "GEN", //Sexo
  Alias: "ALI", //Alias
  Country: "PAI", //País o región
  Via: "VIA", //Via
  Address: "DIR", //Dirección
  Address1: "AD1", //Dirección 1
  Province: "PRO", //Provincia/Región
  City: "POB", //Población
  PostalCode: "CPO", //Código postal  
  Observations: "CMM", //Observaciones
  Languaje: "LAN", //Lenguaje

  EntDocNumber: "EDN", //Empresa Nro de documento
  EntName: "ENM", //Empresa Nombre
  EntComName: "ECN", //Empresa Nombre comercial
  EntExpedCountry: "ECY", //Empresa País
  EntExpirDate: "EDE", //Empresa Fecha de caducidad
  EntProfActivity: "EPA", //Empresa Actividad profesional
  ECI: "ECI", //Empresa Comercial
  ECM: "ECM", //Empresa Observaciones
  EntAlias: "EAL", //Empresa Alias
  EntCountry: "EPI", //Empresa País o región
  EntVia: "EVI", //Empresa Tipo de dirección
  EntAddress: "EDR", //Empresa Dirección
  EntAddress1: "ED1", //Empresa Dirección 1
  EntProvince: "EPR", //Empresa Provincia/Región
  EntCity: "EPO", //Empresa Población
  EntPostalCode: "ECP", //Empresa Código postal
}

export const InputCodes = {
  Text: "TXB",
  Numeric: "NUM",
  LongText: "TXA",
  Selector: "DRO",
  Date: "DAT",
}