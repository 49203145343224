import { fetchMethod } from "../commons/EnumsGeneral";
import { CommonFetch, buildHeader } from "./ReasonService";

const fetchApi = {
    PostLegalRGPD: "Legal/RGPD",
    GetLegalRGPD: "Legal/RGPD",
    PutLegalTyCs: "Legal/TyCs",
    PutLegalEnterpriseTyCs: "Legal/Enterprise/TyCs",
    GetLegalUserPendingTycCid: "Legal/User/PendingTycCid",
    PostLegalUserPendingTycCid: "Legal/User/PendingTycCid",
    GetLegalEnterprisePendingTyc: "Legal/Enterprise/PendingTyc",
    PostLegalEnterprisePendingTyc: "Legal/Enterprise/PendingTyc",
    GetLegalUserTwoFactorAuthentication: "Legal/User/TwoFactorAuthentication",
    PostLegalUserTwoFactorAuthentication: "Legal/User/TwoFactorAuthentication",
};

export const PostLegalRGPD = async (data) => {
    let body = data;
    let header = buildHeader("application/json", true, "application/json");
    let apiUrl = fetchApi.PostLegalRGPD;
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return response;
};

export const GetLegalRGPD = async () => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.GetLegalRGPD;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const PutLegalTyCs = async () => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.PutLegalTyCs;
    let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl);
    return response;
};

export const PutLegalEnterpriseTyCs = async (idEnterprise) => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl =
        fetchApi.PutLegalEnterpriseTyCs + "?idEnterprise=" + idEnterprise;
    let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl);
    return response;
};

export const GetLegalUserPendingTycCid = async () => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.GetLegalUserPendingTycCid;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const PostLegalUserPendingTycCid = async (tycObj) => {
    let body = tycObj;
    let header = buildHeader("*/*", true, "application/json");
    let apiUrl = fetchApi.PostLegalUserPendingTycCid;
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return response;
};

export const GetLegalEnterprisePendingTyc = async () => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.GetLegalEnterprisePendingTyc;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const PostLegalEnterprisePendingTyc = async (tycObj) => {
    let body = tycObj;
    let header = buildHeader("*/*", true, "application/json");
    let apiUrl = fetchApi.PostLegalEnterprisePendingTyc;
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return response;
};

export const GetLegalUserTwoFactorAuthentication = async () => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.GetLegalUserTwoFactorAuthentication;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const PostLegalUserTwoFactorAuthentication = async (data) => {
    let body = data;
    let header = buildHeader("*/*", true, "application/json");
    let apiUrl = fetchApi.PostLegalUserTwoFactorAuthentication;
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return response;
};