export const Constants = {
  DrawerWidth: 300,
  MarginTop: "2.8em",
  MarginTopMobile: "8.8em",
  MarginTopApp: "12em",
  Si: "SI",
  No: "NO",
  lvlVerification: {
    low: 1,
    medium: 2,
    high: 3,
  },
  DaysForCancelAccount: 30,
  CorreosIdLogoUrl:
    "https://azamdcorreosidsta001.blob.core.windows.net/b2cdev/correosid/images/correosid.jpg",
  minDate: "1800-01-01",
  maxDate: "8000-01-01",
  cookieValue: "Vg1wq29va2ll",
  cidCode: "CID",
};
