import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Fade,
  Tooltip,
} from "@material-ui/core";
import { StylesServiceCard } from "./StylesServiceCard";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { checkIsNullUndefined, getErrorMessage, useOutsideAlerter, } from "../../commons/Utils";
import { useTranslation } from "react-i18next";
import { DialogSubscribe } from "../CommonComponents/Dialogs/DialogSubscribe";
import { GetUserCheckSingleSubscription, GetEnterpriseCheckSingleSubscription, DeleteEnterprise } from "../../services/ReasonService";
import { BasicDualConfirm } from "../CommonComponents/BasicDualConfirm";
import { PostUtilitiesCorreosIdCancelAccount } from "../../services/UtilitiesCallsService";
import { backToLogin } from "../../services/Commons";
import { DialogCancelOrUnsubscribe } from "./components/DialogCancelOrUnsubscribe";
import history from "../../services/history.js";

export function ServiceCard(props) {
  const {
    service,
    isSubscribed,
    idEnterprise,
    isTitular,
    finishOperation,
    resetPageData,
    setUpdateEnterprises
  } = props;
  const classes = StylesServiceCard();
  const { t, i18n } = useTranslation();
  const wrapperRef = useRef(null);

  const [openSubscribe, setOpenSubscribe] = useState(false);
  const [doSubscription, setDoSubscription] = useState(false);
  const [doUnsubscription, setDoUnsubscription] = useState(false);
  const [circularProgressSubs, setCircularProgressSubs] = useState(false);
  const [openUnsubscribe, setOpenUnsubscribe] = useState(false);
  const [openCancelAccount, setOpenCancelAccount] = useState(false);
  const [showApplicationDescription, setShowApplicationDescription] = useState(false);
  const [shortName, setShortName] = useState("");
  const [
    circularProgressCancelingAccount,
    setCircularProgressCancelingAccount,
  ] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setCircularProgressSubs(false);
    let auxName = service.nameApplication;
    if (auxName.length > 20) {
      auxName = auxName.substring(0, 17) + "...";
    }
    setShortName(auxName);
  }, []);

  const CheckSingleSub = (event) => {
    let auxCall = checkIsNullUndefined(idEnterprise) ? GetUserCheckSingleSubscription : GetEnterpriseCheckSingleSubscription;
    auxCall(idEnterprise).then((response) => {
      if (!checkIsNullUndefined(response) && !response.Status) {
        if (response) {
          setOpenCancelAccount(true);
        } else {
          setAnchorEl(event.target);
          setOpenUnsubscribe(true);
        };
      } else {
        finishOperation("error", getErrorMessage(response, t));
      };
    });
  };

  //suscribe o des-suscribe al usuario en la aplicación seleccionada
  const changeSubscription = () => {
    setOpenUnsubscribe(false);
    setDoUnsubscription(true);
  };

  const handleChangeSubscription = () => {
    if (service.tycPrivacyManagement) {
      setOpenSubscribe(true);
    } else {
      setDoSubscription(true);
    };
  };

  const goToService = () => {
    if (!checkIsNullUndefined(service.url)) {
      window.location.href = service.url;
    };
  };

  // Manage show application description in Cards
  const handleCardMouseOver = () => {
    if (!isSubscribed) {
      setShowApplicationDescription(true);
    };
  };

  const handleCardMouseOut = () => {
    setShowApplicationDescription(false);
  };

  const getOnClickBehaviour = () => {
    if (!isSubscribed && (checkIsNullUndefined(idEnterprise) || isTitular)) {
      if (service.idApplicationType !== 1) {
        return handleChangeSubscription;
      } else {
        return goToService;
      };
    } else {
      return null;
    };
  };

  const handleCancelPopups = () => {
    setOpenCancelAccount(false);
    setOpenUnsubscribe(false);
    setAnchorEl(null);
  };

  const handleCancelAccount = () => {
    setCircularProgressCancelingAccount(true);
    if (checkIsNullUndefined(idEnterprise)) {
      handleCancelUser();
    } else {
      handleCancelEnterprise();
    }
  };

  const handleCancelUser = () => {
    PostUtilitiesCorreosIdCancelAccount().then((response) => {
      if (response && !response.Status) {
        backToLogin();
      } else {
        finishOperation("error", getErrorMessage(response, t));
        setCircularProgressCancelingAccount(false);
      }
    });
  };

  const handleCancelEnterprise = () => {
    DeleteEnterprise(idEnterprise).then((response) => {
      if (response && !response.Status) {
        finishOperation("success", t("enterpriseDeleteAlertMessageSuccess"));
        setCircularProgressCancelingAccount(false);
        setUpdateEnterprises(true);
        let redirect = "/Index";
        history.push(redirect);
      } else {
        finishOperation("error", getErrorMessage(response, t));
        setCircularProgressCancelingAccount(false);
      };
    });
  };

  const manageGoToButton = () => {
    let auxButton = null;
    if (service.url != null) {
      auxButton = (
        <CorreosButton
          variant= {checkIsNullUndefined(service.url) ? "outlined" : "contained"}
          color= {checkIsNullUndefined(service.url) ? "secondary" : "primary"}
          className={classes.ButtonRight}
          onClick={goToService}
        >
          {t("goToService")}
        </CorreosButton>
      );
    };
    return auxButton;
  };

  const manageSubscribeButton = () => {
    let auxButton = null;
    if ((checkIsNullUndefined(idEnterprise) || isTitular)) {
      let auxCall = service.idApplicationType !== 1 ? handleChangeSubscription : goToService;
      let hasUrl = service.url != null;
      let text = service.idApplicationType !== 1 ? t("subscribeMeText") : hasUrl ? t("goToUrl") : t("servicePageAppGoTo");
      auxButton = (
        <CorreosButton
          className={classes.ButtonCenter}
          onClick={auxCall}
          style={{ border: "none" }}
          disabled={service.idApplicationType === 1 && !hasUrl}
          circularProgress={circularProgressSubs}
        >
          {text}
        </CorreosButton>
      );
      if (service.idApplicationType === 1 && !hasUrl) {
        auxButton = (
          <Typography style={{ marginLeft: "1em", marginRight: "1em", fontSize: "95%", color: "#666666" }}>
            {text}
          </Typography>
        );
      }
    };
    return auxButton;
  };

  const manageUnsubscribeButton = () => {
    let auxButton = null;
    if ((checkIsNullUndefined(idEnterprise) || isTitular)) {
      auxButton = (
        <CorreosButton
          className={classes.ButtonLeft}
          onClick={(event) => CheckSingleSub(event)}
          style={{ border: "none" }}
          circularProgress={circularProgressSubs || circularProgressCancelingAccount}
        >
          {t("unsubscribeService")}
        </CorreosButton>
      );
    };
    return auxButton;
  };

  useOutsideAlerter(wrapperRef, handleCancelPopups);

  return (
    <Grid key={service.applicationOid} item xl={4} lg={4} md={12} xs={12} className={classes.roundedCardGrid}>
      <DialogSubscribe
        open={openSubscribe}
        setOpen={setOpenSubscribe}
        enterpriseId={idEnterprise}
        appName={service.nameApplication}
        isServices={true}
        resetPageData={resetPageData}
        applicationOid={service.applicationOid}
        doSubscription={doSubscription}
        doUnsubscription={doUnsubscription}
        setCircularProgressSubs={setCircularProgressSubs}
      />
      <DialogCancelOrUnsubscribe
        open={openCancelAccount}
        setOpen={setOpenCancelAccount}
        forEnterprise={isTitular}
        appName={service.nameApplication}
        cancelAccount={handleCancelAccount}
        unsubscribe={changeSubscription}
      />
      <BasicDualConfirm
        id={service.nameApplication + "CancelService"}
        open={openUnsubscribe}
        title={t("servicePageDualWarning").replace("{applicationName}", service.nameApplication)}
        anchorEl={anchorEl}
        wrapperRef={wrapperRef}
        action={changeSubscription}
        closeAction={handleCancelPopups}
      />
      <Card
        key={service.nameApplication}
        onMouseEnter={!isSubscribed ? handleCardMouseOver : null}
        onMouseLeave={handleCardMouseOut}
        onClick={getOnClickBehaviour()}
        className={service.state == t("unSubscribedService") ? classes.roundedCardHover : classes.roundedCard}
      >
        <Grid
          container
          item
          xs={12}
          className={!isSubscribed ? classes.backgroundChangeHover : classes.backgroundChange}
        >
          <Grid item md={12} xs={12} className={classes.serviceNameContainer} >
            <CardContent className={showApplicationDescription && !isSubscribed ? classes.serviceNameHover : classes.serviceName}>
              <Tooltip title={service.nameApplication} placement="top">
                <Typography component="h1" variant="h5" noWrap width="80%">
                  {shortName}
                </Typography>
              </Tooltip>
            </CardContent>
          </Grid>
          {!isSubscribed && showApplicationDescription && !checkIsNullUndefined(service.description) && service.description.length > 0 ?
            <Fade in={showApplicationDescription} timeout={800}>
              <Grid
                item md={12}
                xs={12}
                className={classes.CardDescription}
              >
                <Typography style={{ paddingTop: "1em", marginLeft: "1em", marginRight: "1em", fontSize: "95%" }}>
                  {service.description}
                </Typography>
              </Grid>
            </Fade>
            : null}
          {!showApplicationDescription ?
            <Grid item xs={12} className={classes.GridContent}>
              {isSubscribed ? (
                <Grid item container xs={12} style={{ paddingTop: "0.75em" }}>
                  <Grid item xs={12} style={{ paddingTop: checkIsNullUndefined(service.url) ? "0.5em" : "2em" }}>
                    <Typography style={{ marginLeft: "1em", marginRight: "1em", fontSize: "95%", color: "#666666" }}>
                       {checkIsNullUndefined(service.url) ? t("servicePageAppAccess") : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.GridButtons} style={{ paddingLeft: "2em", paddingRight: "2em" }}>
                    {manageUnsubscribeButton()}
                    {manageGoToButton()}
                  </Grid>
                </Grid>
              ) : (
                <Grid item container xs={12} style={{ paddingTop: "0.25em" }}>
                  <Grid item xs={12} className={classes.GridButtons} style={{ paddingLeft: "2em", paddingRight: "2em" }}>
                    {manageSubscribeButton()}
                  </Grid>
                </Grid>
              )}
            </Grid>
            : null}
        </Grid>
      </Card>
    </Grid>
  );
}
