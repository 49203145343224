export const userEmail = {
  id: 0,
  email: null,
  communication: false,
};

export const userPhone = {
  id: 0,
  idCountry: null,
  phone: null,
  communication: false,
};

export const userAddress = {
  id: 0,
  address: null,
  address1: null,
  alias: null,
  idCountry: null,
  countryCode: null,
  idProvince: null,
  provinceCode: null,
  idCity: null,
  cityCode: null,
  idPostalCode: null,
  postalCode: null,
  idRoadType: null,
  roadTypeCode: null,
  favourite: false,
  comment: null,
};

export const userDocument = {
  id: 0,
  idTypeUserDocument: null,
  idCountry: null,
  number: null,
  expirationDate: null,
};

export const userMultipleValues = {
  userEmail: "userEmail",
  userPhone: "userPhone",
  userAddress: "userAddress",
  userDocument: "userDocument",
};

export const emptyUser = {
  name: null,
  surname1: null,
  surname2: null,
  birthDate: null,
  nationality: null,
  idGender: null,
  userAddress: [],
  userDocument: [],
  userEmail: [],
  userPhone: [],
  idLanguage: null
}

export const emptyEnterprise = {
  id: 0,
  address: null,
  address1: null,
  alias: null,
  idCountry: null,
  idProvince: null,
  idCity: null,
  idPostalCode: null,
  idRoadType: null,
  favourite: false,
  comment: null,
  enterpriseAddress: [
    {
      id: 0,
      address: null,
      address1: null,
      alias: null,
      idCountry: null,
      countryCode: null,
      idProvince: null,
      provinceCode: null,
      idCity: null,
      cityCode: null,
      idPostalCode: null,
      postalCode: null,
      idRoadType: null,
      roadTypeCode: null,
      favourite: false,
      comment: null,
    },
  ],
};

export const emptyEnterpriseNew = {
  id: 0,
  address: null,
  address1: null,
  alias: null,
  idCountry: null,
  idProvince: null,
  idCity: null,
  idPostalCode: null,
  idRoadType: null,
  favourite: false,
  comment: null,
  enterpriseAddress: []
};

export const emptyAddress = {
  id: 0,
  address: null,
  address1: null,
  alias: null,
  idCountry: null,
  idProvince: null,
  idCity: null,
  idPostalCode: null,
  idRoadType: null,
  favourite: false,
  comment: null,
}

export const enterpriseMultipleValues = {
  enterpriseAddress: "enterpriseAddress",
};

export const errorCodes = {
  999: "pendingEnterprise",
  202: "incompleteUserData",
  2020: "incompleteEnterpriseData",
  203: "unsuscribedUser",
  2030: "unsuscribedEnterprise",
  206: "pendingUserPrivacy",
  2060: "pendingEnterprisePrivacy",
  207: "tooLongAttribute",
  209: "canceledAccount",
  212: "verifyEmail",
  222: "verifyPhone",
  232: "verifyAddress",
  242: "verifyDocument",
};

export const basicAddress = {
  id: 0,
  address: null,
  address1: null,
  alias: null,
  idCountry: null,
  countryCode: null,
  idProvince: null,
  provinceCode: null,
  idCity: null,
  cityCode: null,
  idPostalCode: null,
  postalCode: null,
  idRoadType: null,
  roadTypeCode: null,
  favourite: false,
  comment: null,
};