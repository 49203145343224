import React, { useEffect } from "react";
import {
    Grid,
} from "@material-ui/core";
import { SelectorSwitchStyles } from "./SelectorSwitchStyles";
import { Size } from "../../commons/EnumsGeneral";

export function SelectorSwitch(props) {
    const { textA, textB, selectedA, handleSwitch, size } = props;
    const classes = SelectorSwitchStyles();

    useEffect(() => {}, []);

    const handleSwitchButton = (switchToA) => {
        if (switchToA != selectedA) {
            handleSwitch(switchToA);
        }
    }

    const handleClass = () => {
        let auxClass = size == Size.large ? classes.selectorContainerBig : size == Size.medium ? classes.selectorContainerMedium : classes.selectorContainerSmall;
        return auxClass;
    }

    return (
        <Grid container className={handleClass()}>
            <Grid item xs={6}
                className={selectedA ? classes.selectorButtonSelected : classes.selectorButtonNotSelected}
                onClick={() => handleSwitchButton(true)}>
                {textA}
            </Grid>
            <Grid item xs={6}
                className={!selectedA ? classes.selectorButtonSelected : classes.selectorButtonNotSelected}
                onClick={() => handleSwitchButton(false)} >
                {textB}
            </Grid>
        </Grid>
    );
}