import React, { useEffect, useState } from "react";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import {
  Grid,
  Dialog,
  Container,
  TextField,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { checkIsNullUndefined, getErrorMessage } from "../../../commons/Utils";
import { CommonStyles } from "../../../commons/CommonStyles";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { EnterpriseTeamStyles } from "../EnterpriseTeamStyles";
// import EditIcon from "@material-ui/icons/Edit";
import { AiOutlineEdit } from "react-icons/ai";
import { PutEnterpriseDataEnterpriseItems } from "../../../services/EnterpriseDataCallsService";
import { useTranslation } from "react-i18next";

export function DialogEditItem(props) {
  const { finishOperation, item, idEnterprise, loadItems } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const classes = EnterpriseTeamStyles();
  const { t, i18n } = useTranslation();

  const [openEdit, setOpenEdit] = useState(false);
  const [value, setValue] = useState(item.value);
  const [error, setError] = useState(false);

  useEffect(() => { }, []);

  const handleEditObject = () => {
    PutEnterpriseDataEnterpriseItems(idEnterprise, item.id, value).then(
      (response) => {
        if (response && !response.Status) {
          loadItems();
          finishOperation("success", t("teamPageItemEditSuccess"));
        } else {
          // Alerta
          finishOperation("error", getErrorMessage(response, t));
        }
        setOpenEdit(false);
      }
    );
  };

  const handleChange = (event) => {
    let koItem = checkIsNullUndefined(event.target.value);
    setValue(event.target.value);
    setError(koItem);
  };

  const handleCancelEdit = () => {
    setOpenEdit(false);
  };

  const handleEdit = () => {
    setOpenEdit(true);
  };

  return (
    <Grid>
      <Tooltip title={t("teamPageItemEdit")}>
        <IconButton
          className={classes.iconButton}
          onClick={() => handleEdit()}
          style={{ float: "right" }}
        >
          <AiOutlineEdit className={commonClasses.iconActionStyle}/>
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        open={openEdit}
        onClose={() => handleCancelEdit()}
        PaperProps={{ classes: {root: dialogClasses.dialogWindow }}}
      >
        <Container className={commonClasses.dialogTitle}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={commonClasses.dialogTitleText}
              >
                {t("teamPageEditItem")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={commonClasses.dialogContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                value={value}
                color="secondary"
                variant="outlined"
                label={t("teamPageItemValue")}
                fullWidth
                id="titem"
                autoFocus
                required
                onChange={handleChange}
                error={error}
                helperText={error ? t("teamPageItemError") : ""}
                className={commonClasses.inputsStyle}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={commonClasses.dialogButtonContainer}
            >
              <Grid item xs={6}>
                <CorreosButton
                  color="secondary"
                  className={commonClasses.dialogButtonLeft}
                  onClick={handleCancelEdit}
                >
                  {t("cancel")}
                </CorreosButton>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  variant="contained"
                  color="primary"
                  className={commonClasses.dialogButtonRight}
                  onClick={handleEditObject}
                >
                  {t("save")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </Grid>
  );
}
