import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Container,
  Dialog,
  Grid,
  TextField,
  IconButton,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Popper,
  Paper,
} from "@material-ui/core";
import { CorreosButton } from "../../../components/CorreosButton/CorreosButton";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import { CheckCircle, CheckCircleOutline } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { CommonStyles } from "../../../commons/CommonStyles";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { useTranslation } from "react-i18next";
import {
  DeleteVerificationUserEmail,
  PostVerificationDataEmail,
  PostVerificationSendEmail,
} from "../../../services/ReasonService";
import {
  checkIsNullUndefined,
  getErrorMessage,
  useOutsideAlerter,
} from "../../../commons/Utils";
import { RequiredDataStyles } from "../RequiredDataStyles";
import { EnumFirstSelectOption, generalWarning } from "../../../commons/EnumsGeneral";
import { GeneralWarning } from "../../CommonComponents/GeneralWarning/GeneralWarning";

export function DialogEmailActions(props) {
  const {
    actualValue,
    emailsList,
    mandatory,
    mustRegistry,
    handleAddEmail,
    handleVerifyEmail,
    handleRemoveEmail,
    finishOperation
  } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const classes = RequiredDataStyles();
  const { t, i18n } = useTranslation();
  const wrapperRef = useRef(null);
  const validator = require("react-email-validator");

  const [open, setOpen] = useState(false);
  const [operationType, setOperationType] = useState("");
  const [hasValue, setHasValue] = useState(true);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [sendedOtp, setSendedOtp] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otpErrorText, setOtpErrorText] = useState("");
  const [toVerificate, setToVerificate] = useState(false);
  const [verificable, setVerificable] = useState(false);
  const [deletable, setDeletable] = useState(false);
  const [sendCircularProgress, setSendCircularProgress] = useState(false);
  const [actionCircularProgress, setActionCircularProgress] = useState(false);
  const [allOk, setAllOk] = useState(false);
  const [openWarnDialog, setOpenWarnDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    let auxHasValue = true;
    let auxVerificable = true;
    let auxDeletable = true;
    let auxEmail = actualValue;
    if (checkIsNullUndefined(actualValue) || actualValue == EnumFirstSelectOption) {
      auxHasValue = false;
      auxVerificable = false;
      auxEmail = "";
    } else {
      let auxEmailObj = emailsList.find((email) => email.email == actualValue);
      if (!checkIsNullUndefined(auxEmailObj)) {
        auxDeletable = auxEmailObj.id != 0;
        auxVerificable = checkIsNullUndefined(auxEmailObj.idConTypeVerification) || auxEmailObj.idConTypeVerification < 2;
      };
    };
    setHasValue(auxHasValue);
    setVerificable(auxVerificable);
    setDeletable(auxDeletable);
    setEmail(auxEmail);
  }, [actualValue, emailsList]);

  useEffect(() => {
    let allOk = true;
    if (operationType == "add" && (checkIsNullUndefined(email) || emailError)) {
      allOk = false;
    } else if (
      (operationType == "change" || (operationType == "add" && toVerificate)) &&
      (checkIsNullUndefined(otp) || !sendedOtp || otpError)
    ) {
      allOk = false;
    };
    setAllOk(allOk);
  }, [email, otp, operationType, toVerificate]);

  const handleOpen = (event, operationType) => {
    let auxEmail = checkIsNullUndefined(actualValue) ? "" : actualValue;
    if (operationType === "delete") {
      setAnchorEl(event.currentTarget);
    };
    if (operationType === "add") {
      auxEmail = "";
    };
    setOperationType(operationType);
    setEmail(auxEmail);
    setOpen(true);
  };

  const handleCancel = () => {
    setEmail("");
    setEmailError(false);
    setEmailErrorText("");
    setToVerificate(false);
    setOperationType("");
    setAnchorEl(null);
    setAllOk(false);
    setActionCircularProgress(false);
    clearOtp();
    setOpen(false);
  };

  const clearOtp = () => {
    setOtp("");
    setOtpError(false);
    setOtpErrorText("");
    setSendedOtp(false);
    setSendCircularProgress(false);
  };

  const handleChangeEmail = (value) => {
    let auxError = false;
    let auxErrorText = "";
    if (checkIsNullUndefined(value.target.value)) {
      auxError = true;
      auxErrorText = t("required");
    } else if (!validator.validate(value.target.value)) {
      auxError = true;
      auxErrorText = t("registerPageMailError");
    };
    setEmailError(auxError);
    setEmailErrorText(auxErrorText);
    clearOtp();
    setEmail(value.target.value);
  };

  const handleChangeOtp = (value) => {
    let auxError = false;
    let auxErrorText = "";
    if (checkIsNullUndefined(value.target.value)) {
      auxError = true;
      auxErrorText = t("required");
    };
    setOtpError(auxError);
    setOtpErrorText(auxErrorText);
    setOtp(value.target.value);
  };

  const handleSendOtp = () => {
    setSendCircularProgress(true);
    PostVerificationSendEmail(email).then((response) => {
      if (response && !response.Status) {
        setSendedOtp(true);
      } else {
        finishOperation("error", getErrorMessage(response, t));
      };
      setSendCircularProgress(false);
    });
  };

  const handleConfirmAction = () => {
    setActionCircularProgress(true);
    if (operationType == "add" || operationType == "change") {
      handleRepeatedEmail();
    } else {
      handleDeleteable();
    };
  };

  const handleRepeatedEmail = () => {
    let auxRepeatedEmail = checkRepeatedEmail();
    if (!auxRepeatedEmail) {
      if (operationType == "add" && !toVerificate) {
        handleAddEmail(email);
        finishOperation("warning", t("userDataSaveWarning"));
        handleCancel();
      } else {
        handleVerificationDataEmail();
      };
    };
  };

  const handleDeleteable = () => {
    if (deletable) {
      handleDelete();
    } else {
      finishOperation("success", t("userDataDeleteEmailSuccess"));
      handleRemoveEmail(email);
      handleCancel();
    };
  };

  const handleDelete = () => {
    DeleteVerificationUserEmail(email).then((response) => {
      if (response && !response.Status) {
        finishOperation("success", t("userDataDeleteEmailSuccess"));
        handleRemoveEmail(email);
        handleCancel();
      } else {
        finishOperation("error", getErrorMessage(response, t));
        setActionCircularProgress(false);
      };
    });
  };

  const handleVerificationDataEmail = () => {
    PostVerificationDataEmail(email, otp).then((response1) => {
      if (response1 && !response1.Status) {
        manageVerificationAction();
      } else {
        setOpenWarnDialog(true);
        setActionCircularProgress(false);
      };
    });
  };

  const manageVerificationAction = () => {
    if (operationType == "add" && toVerificate) {
      handleAddEmail(email, true);
    } else {
      handleVerifyEmail(email);
    };
    finishOperation("success", t("userDataVerifyEmailOk"));
    handleCancel();
  };

  const checkRepeatedEmail = () => {
    let repeated = false;
    let auxEmailList = emailsList.map((auxEmail) => auxEmail.email);
    let auxRepeatedEmailsList = auxEmailList.filter(
      (auxEmail) => auxEmail == email
    );
    if (operationType === "change") {
      repeated = auxRepeatedEmailsList.length > 1;
    } else {
      repeated = auxRepeatedEmailsList.length > 0;
    }
    if (repeated) {
      setEmailError(true);
      setEmailErrorText(t("verificationsEmailExists"));
      setActionCircularProgress(false);
    };
    return repeated;
  };

  const handleToVerificate = () => {
    let auxToVerificate = !toVerificate;
    if (!auxToVerificate) {
      clearOtp();
    }
    setToVerificate(auxToVerificate);
  };

  const getShowSend = () => {
    return operationType === "change" || (operationType === "add" && toVerificate);
  };

  useOutsideAlerter(wrapperRef, handleCancel);

  return (
    <Grid
      container
      item
      xs={12}
      spacing={1}
      className={classes.actionsComponentContainer}
    >
      {emailsList.length == 0 ? (
        <Grid item xs={12}>
          <Tooltip title={t("add")}>
            <span>
              <CorreosButton
                color="primary"
                variant="contained"
                className={commonClasses.dialogButtonCenter}
                onClick={(event) => handleOpen(event, "add")}
              >
                <AddToPhotosIcon style={{ marginRight: "0.2em" }} />
                {t("email")}
                {mandatory ? " *" : ""}
              </CorreosButton>
            </span>
          </Tooltip>
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          spacing={1}
          className={classes.iconsContainer}
        >
          <Grid item xs={4} className={classes.iconContainer}>
            <Tooltip title={t("add")}>
              <IconButton
                classes={{ root: classes.iconButton }}
                onClick={(event) => handleOpen(event, "add")}
              >
                <AddToPhotosIcon className={commonClasses.iconActionStyle} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={4} className={classes.iconContainer}>
            <Tooltip title={t("verificationsVerify") + (mustRegistry ? " *" : "")}>
              <IconButton
                classes={{ root: classes.iconButton }}
                onClick={(event) => handleOpen(event, "change")}
                disabled={!hasValue || !verificable}
              >
                {(!verificable || !hasValue) ?
                  <CheckCircle
                    className={
                      !hasValue || !verificable
                        ? commonClasses.iconDisabledStyle
                        : commonClasses.iconActionStyle
                    }
                  /> :
                  <CheckCircleOutline
                    className={
                      !hasValue || !verificable
                        ? commonClasses.iconDisabledStyle
                        : commonClasses.iconActionStyle
                    }
                  />

                }
                {mustRegistry ? " *" : ""}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={4} className={classes.iconContainer}>
            <Tooltip title={t("delete")}>
              <IconButton
                classes={{ root: classes.iconButton }}
                onClick={(event) => handleOpen(event, "delete")}
                disabled={!hasValue}
              >
                <DeleteIcon
                  className={
                    !hasValue
                      ? commonClasses.iconDisabledStyle
                      : commonClasses.iconActionStyle
                  }
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open && operationType !== "delete"}
        onClose={handleCancel}
        PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
      >
        <Container className={commonClasses.dialogTitle}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={commonClasses.dialogTitleText}
              >
                {operationType === "add"
                  ? t("userDataAddEmail")
                  : operationType === "change"
                    ? t("userDataModifyEmail")
                    : ""}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={commonClasses.dialogContainer}>
          <Grid container spacing={1}>
            <Grid item xs={getShowSend() ? 10 : 12}>
              <TextField
                className={commonClasses.inputsStyle}
                color="secondary"
                variant="filled"
                value={email}
                onChange={(value) => handleChangeEmail(value)}
                fullWidth
                name="data"
                autoFocus
                label={t("UserEmail")}
                type="text"
                error={emailError}
                helperText={emailError ? emailErrorText : ""}
                disabled={operationType === "change"}
                required
              />
            </Grid>
            {getShowSend() ? (
              <Grid item xs={2} className={commonClasses.gridIdentity}>
                <CorreosButton
                  variant={sendedOtp ? "text" : "contained"}
                  color={sendedOtp ? "secondary" : "primary"}
                  onClick={handleSendOtp}
                  className={commonClasses.dialogButtonRight}
                  disabled={emailError || checkIsNullUndefined(email)}
                  circularProgress={sendCircularProgress}
                >
                  {sendedOtp ? t("reSend") : t("send")}
                </CorreosButton>
              </Grid>
            ) : null}
            {operationType == "add" ? (
              <Grid item xs={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={toVerificate}
                        name="check"
                        onChange={handleToVerificate}
                        className={commonClasses.leftButton}
                      />
                    }
                    label={t("userDataModifyEmail")}
                    style={{ marginBottom: 0 }}
                  />
                </FormGroup>
              </Grid>
            ) : null}
            {sendedOtp ? (
              <Grid item xs={12}>
                <TextField
                  color="secondary"
                  value={otp}
                  onChange={(value) => handleChangeOtp(value)}
                  variant="filled"
                  fullWidth
                  name="top"
                  label={t("lOtp")}
                  type="text"
                  error={otpError}
                  helperText={otpError ? otpErrorText : ""}
                  required
                  className={commonClasses.inputsStyle}
                />
              </Grid>
            ) : null}
            <Grid
              item
              container
              xs={12}
              className={commonClasses.dialogButtonContainer}
            >
              <Grid item xs={6}>
                <CorreosButton
                  onClick={handleCancel}
                  className={commonClasses.dialogButtonLeft}
                >
                  {t("cancel")}
                </CorreosButton>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  variant="contained"
                  color="primary"
                  onClick={handleConfirmAction}
                  className={commonClasses.dialogButtonRight}
                  disabled={!allOk}
                  circularProgress={actionCircularProgress}
                >
                  {operationType == "add" ? t("add") : t("verificationsVerify")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
      <Popper
        open={open && operationType === "delete"}
        anchorEl={anchorEl}
        placement={"top"}
        style={{ zIndex: 100 }}
        ref={wrapperRef}
      >
        <Paper>
          <Container>
            <Grid container style={{ paddingTop: "1em", paddingBottom: "1em" }}>
              <Grid item xs={12}>
                <Typography className={commonClasses.popperContentText}>
                  {t("userDataDeleteEmail")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  size="small"
                  onClick={handleCancel}
                  className={commonClasses.leftButton}
                >
                  {t("no")}
                </CorreosButton>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleConfirmAction}
                  className={commonClasses.rightButton}
                  circularProgress={actionCircularProgress}
                >
                  {t("yes")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Popper>
      <GeneralWarning open={openWarnDialog} setOpen={setOpenWarnDialog} isEmail={true} warnType={generalWarning.verify} />
    </Grid>
  );
}
