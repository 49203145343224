import React, { useEffect, useState } from "react";
import { Grid, CircularProgress, Typography, Container, Card } from "@material-ui/core";
import { Alerts } from "../../components/Alerts/Alerts";
import { useTranslation } from "react-i18next";
import { checkIsNullUndefined, getErrorMessage } from "../../commons/Utils";
import history from "../../services/history";
import { Header } from "../../components/Header/Header";
import { getEidFromToken, hasPagePermission, setReplaceMode, setSessionItem, setUserSession } from "../../services/Commons";
import {
  PostUtilitiesCorreosGetTokenFromRedirectCode,
} from "../../services/UtilitiesCallsService";
import { RedirectPageStyles } from "./RedirectPageStyles";
import { EnumAllowedRedirectUserPages, EnumEnterprisePages, sessionValues } from "../../commons/EnumsGeneral";

export function RedirectPage() {
  const { t, i18n } = useTranslation();
  const classes = RedirectPageStyles();

  const [circularProgress, setCircularProgress] = useState(true);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("false");
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });

  const finishOperation = (color, message) => {
    setError(true);
    setErrorText(message);
    setAlert({
      open: true,
      color,
      message,
    });
  };

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let code = urlParams.get('code');
    let page = urlParams.get('page');
    let redirectURI = urlParams.get('redirectURI');
    let isSSO = checkIsNullUndefined(redirectURI);
    setSessionItem(sessionValues.redirectURI, redirectURI);
    if (!isSSO && page != EnumAllowedRedirectUserPages.UnifyAccounts) {
      setReplaceMode(true);
    }
    setCircularProgress(false);
    if (!checkIsNullUndefined(code) && !checkIsNullUndefined(page)) {
      PostUtilitiesCorreosGetTokenFromRedirectCode(code).then((response) => {
        setCircularProgress(true);
        if (response && !response.Status) {
          setUserSession(response, i18n, process?.env?.REACT_APP_APP_OID).then(() => {
            let idEnterprise = getEidFromToken(response);
            handleUserRedirection(code, page, idEnterprise, isSSO);
          });
        } else {
          finishOperation("error", getErrorMessage(response, t));
        };
        setCircularProgress(false);
      });
    } else {
      finishOperation("error", t("redirectPageMissingParams"));
    };
  }, []);

  const handleUserRedirection = (code, page, idEnterprise, isSSO) => {
    setCircularProgress(false);
    if (!hasPagePermission(page, idEnterprise, isSSO)) {
      finishOperation("error", t("redirectPagePageNotFound"));
    } else {
      let redirect = "Index/";
      if (page === EnumAllowedRedirectUserPages.UnifyAccounts) {
        history.push(redirect);
      } else if (EnumEnterprisePages.includes(page)) {
        redirect += idEnterprise + "/" + page;
        prepareEnterpriseData(idEnterprise, redirect);
      } else {
        redirect += page;
        history.push(redirect);
      };
    };
  };

  const prepareEnterpriseData = (idEnterprise, redirect) => {
    if (checkIsNullUndefined(idEnterprise)) {
      finishOperation("error", t("redirectPageIdEnterpriseNotFound"));
    } else {
      history.push(redirect);
    };
  };

  return (
    <div>
      <Header setShowSideMenu="false" />
      <Grid className={classes.container}>
        <Alerts alert={alert} setAlert={setAlert}></Alerts>
        {!circularProgress ?
          <Container maxWidth={"lg"} className={classes.mainContainer}>
            <Card className={classes.cardContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {error ?
                    (
                      <Typography variant="h6" className={classes.dialogTitleText}>
                        {errorText}
                      </Typography>
                    ) : ""}
                </Grid>
              </Grid>
            </Card>
          </Container>
          : <CircularProgress style={{ marginTop: "20%", marginLeft: "auto", marginRight: "auto" }} />}
      </Grid>
    </div>
  );
}
