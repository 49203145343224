export const EN1 = {

    //Generic

    saveSuccess: "Successfully saved!",
    saveError: "Error saving!",
    cancel: "Cancel",
    accept: "Accept",
    next: "Next",
    previous: "Previous",
    required: "This is a required field",
    exit: "Exit",
    search: "Search",
    news: "Changes",
    send: "Send",
    reSend: "Resend",
    create: "Create",
    add: "Add",
    delete: "Delete",
    update: "Update",
    yes: "Yes",
    no: "No",
    save: "Save",
    correosId: "Correos ID",
    genericError: "An error has occurred",
    change: "Change",
    see: "See",
    download: "Download",
    address: "Address",
    email: "Email",
    phone: "Teléfono",
    idDocument: "Documento identificativo",
    country: "Country",
    province: "Province",
    city: "City",
    postalCode: "Post code",
    reset: "Reset",
    pending: "Pendiente",
    done: "Hecho",
    here: "aquí",
    backToTheApp: "Volver a la aplicación",
    selectOption: "Selecciona una opción",
    noOptionSelected: "Sin seleccionar",

    // PendingData

    valid: "Válido",
    document: "Documento",
    userAddress: "Dirección de usuario",
    enterpriseAddress: "Dirección de empresa",
    privacySign: "Firma de privacidad",
    tycSign: "Firma de Términos y Condiciones",
    pendingData: "Tienes datos pendientes:",

    //login

    logInTitle: "Inicia sesión",
    sublogInTitle: "Accede a {appName} a través de Correos ID. ",
    sublogInTitleCID: "Accede a Correos ID. ",
    sublogInTitleInfo: "Si ya estás registrado, no tendrás que volver a registrarte.",
    sublogInTitleInfoCID: "Desde aquí puedes manejar tu identidad de correos.",
    logInTitleSelectType: "¿Cómo quieres acceder?",
    correosIdentityLabel: "Correos identity",
    correosPasswordLabel: "Password",
    correosCheckPasswordLabel: "Confirmación contraseña",
    loginTooltip: "Correos ID es la puerta de entrada a los servicios digitales de Correos. Para acceder a ellos, solamente debes introducir tus credenciales, no tendrás que volver a registrarte.",
    forgotPasswordButton: "Forgotten your password?",
    nextButton: "Next",
    logInButton: "Log in",
    userPasswordError: "Your username or password is incorrect",
    warningLegal: "Legal Notice",
    privacyPolicy: "Privacy Policy",
    cookiesPolicy: "Cookies Policy",
    createAccountButton: "Create Account",
    rememberme: "Remember me",
    welcomeBack: "Hello again",
    recoverDisabledAccount1:
        "Your account was blocked. If you want to reactivate it and recover your information, click the following  ",
    recoverDisabledAccount2: "link",
    recoverDisabledAccount3: " to start the account recovery process.",
    loginPrivacyDialogTitle: "Privacy",
    loginPrivacyDialogText:
        "To be able to log into the app, you must first accept the privacy policy",
    loginSubscribeDialogTitle: "Suscripción",
    loginSubscribeDialogText: "No estás suscrito a la aplicación, si quieres suscribirte debes leer y aceptar los términos y condiciones y pulsar en aceptar",
    loginSubscribeDialogTextServices: "Para poder suscribirte primero debes leer y aceptar los términos y condiciones y pulsar en aceptar",
    InvitationAcceptSuccess: "Invitación aceptada",
    AcceptChangeEnterpriseRoleSuccess: "Cambio de rol aceptado con éxito",
    CancelOrRejectChangeRoleSuccess: "Se ha rechazado la invitación",
    InvitationAcceptError: "La invitación ha fallado",
    AcceptChangeEnterpriseRoleError: "Ha fallado el cambio de rol",
    CancelOrRejectChangeRoleError: "Ha fallado el rechazo de la invitación",
    loginSystemUserError: "Un usuario de sistema no puede loguear en la web",
    footerCopyrightText: "©Sociedad Estatal Correos y Telegrafos, S.A., S.M.E. Todos los derechos reservados.",
    loginFormatError: "Formato incorrecto",
    loginOauthError: "El método oauth ya no está disponible",

    //Register

    loginPageInfo: "¿Aún no tienes una cuenta? ",
    loginPageRegistryHere: "Regístrate aquí",
    registerPagePutData: "Introduce los datos de registro",
    registerPageConfirmIdentity: "Verifica tu identidad",
    registerPageMail: "Email address",
    registerPageMailError: "The email address is not correct",
    registerPagePhoneError: "The phone number entered is not correct",
    registerPageNDOError: "The document entered is not correct",
    registerPageMailErrorExist: "The email address entered is already being used",
    registerPagePassword: "Password",
    registerPagePasswordError:
        "8-16 characters with 3 of the following 4: lower case, upper case, numbers (0-9) and one or more of the following symbols: @ # $ % ^ & * - _ + = [ ] { } | \\ : ' , ? / ` ~ ( ) ; . \"",
    registerPageCheckPassword: "Confirmation",
    registerPageCheckPasswordError: "The passwords are not the same",
    registerPagePhone: "Telephone number",
    registerPagePhoneErrorShort: "The phone number is too short",
    registerPagePhoneErrorLong: "The phone number is too long",
    registerPagePhoneErrorExist: "The phone number entered is already being used",
    registerPagePrefixError: "Tiene que introducir un prefijo",
    registerPageTipoPersona: "Person type",
    registerPageAgreeConditionsPhrase: "Debes leer y aceptar los términos y condiciones: ",
    registerPageAgreeConditions: "Terms and Conditions",
    registerPageAgreeConditionsDot: "Términos y Condiciones: ",
    registerPageAgreeConditionsSee: "Ver Términos y Condiciones",
    registerPageAgreeConditionsTerms1: "I have read and accept ",
    registerPageAgreeConditionsTerms2: "the conditions for use ",
    registerPageAgreeConditionsTerms3: " of {appName}.",
    registerPageAgreeConditionsTerms4: "data protection policy",
    registerPageAgreeConditionsAccepted: "Aceptados",
    registerPageAgreeConditionsPending: "Pendiente *",
    registerPageAgreeConditionsTermsConsent:
        "In accordance with our Data Protection Policy, we would like to update your consent for the following activities:",
    registerPageOfersPromotions: "Offers and Promotions",
    registerPageOfersPromotionsDot: "Offers and Promotions: ",
    registerPageOfersPromotionsAll: "I ACCEPT ALL",
    registerPageOfersPromotionsSome: "I ACCEPT SOME",
    registerPageOfersPromotionsNone: "I DO NOT ACCEPT",
    registerPageOfersPromotionsPending: "PENDIENTE *",
    registerPagePersonalizeOptions:
        "Personalise your privacy selecting the following options:",
        registerPageIdentityVerifyTitle: "Confirm your identity",
        registerPageIdentityVerifyText: "To guarantee your identity, we will send a verification code to your email address. Enter the code below to complete the registration process.",
    registerPageIdentityVerifyMailButton: "SEND EMAIL",
    registerPageIdentityVerifyPhoneButton: "SEND SMS",
    registerPageIdentityVerifyVerificable:
        "You must perform at least one verification. You can omit the other one or perform it ",
    registerPageIdentityVerifyVerificableEmail:
        "Verification of the email address is required",
    registerPageIdentityVerifyVerificablePhone:
        "Verification of the phone number is required",
    registerPageIdentityVerifyNoVerificableEmail:
        "Verification of the email address is optional, you can omit it if you prefer",
    registerPageIdentityVerifyNoVerificablePhone:
        "Verification of the phone number is optional, you can omit it if you prefer",
    registerPageIdentityVerifyCodeSended: "Code sent",
    registerPageOtp: "Enter code",
    registerPageOtpError: "The code you have entered is not correct",
    registerPageRegisterError:
        "There was a problem during the registration process",
    registerPageOfersPromotionsSelected: "Offers and promotions accepted: ",
    registerPageOfersPromotionsSelectedAll: "All",
    registerPageOfersPromotionsSelectedSome: "Solo algunas",
    registerPageOfersPromotionsSelectedNone: "None",
    registerPageOfersPromotionsSelectedPending: "Sin seleccionar",
    registerPageSeeOfersPromotions: "Open offers/promotions",
    registerPageSendOTP: "Enter the code we just sent you",
    registerPageSendOTPEmail: "Email code",
    registerPageSendOTPPhone: "Phone number code",
    registryTitleData: "Login details",
    registerPagePersonType1: "Individual",
    registerPagePersonType2: "Company/Self-employed",
    registerPageNoDocuments: "Documents are not required for this company type",
    registerPageWarnTitle: "Atención",
    registerPageWarnText: "El registro a correos ID ha ido correctamente, pero ha habido un problema en el guardado de datos, tendrás que volver a rellenarlos al acceder a la aplicación",
    registerPagePoliticsAndConditions: "Políticas y condiciones",
    registerPageRecaptcha: "Captcha",

    //ChooseUser

    chooseUserTitle:
        "Please select if you want to be authorised as an individual or as a company:",
    chooseEnterpriseTitle:
        "Por favor, pulsa en 'Empresa' y escoge con cuál quieres autorizarte:",
    none: "Ninguna",
    chooseUser: "Individual",
    chooseEnterprise: "Company",
    chooseDialogTitle: "Seleccione empresa",

    //Stepper

    createUserCid: "Crear cuenta en CorreosID",
    userIdentify: "Verifica tu identidad",
    userIdentity: "Introduce tu identidad",
    sendOTP: "Send code",
    reSendOTP: "Resend code",
    codeConfirm: "Confirm code",
    subscriptionToApp: "Suscripción a {appName}",
    ownerData: "Datos del titular",
    enterpriseCreation: "Creación de empresa",
    userData: "Datos de usuario",
    stepChangePass: "Change password",
    enterpriseData: "Company details",
    enterpriseDocuments: "Add documents",
    requiredData: "Datos requeridos",
    createUserCidTextLabel: "Introduce tu email de acceso y contraseña, acepta las políticas de privacidad y crea una cuenta en CorreosID para acceder a nuestros servicios de forma segura.",
    userIdentifyTextLabel: "Este paso es esencial para verificar tu identidad y garantizar la seguridad de tus datos.",
    userIdentityTextLabel: "Ingresa los detalles de tu identidad. Estos detalles nos ayudarán a personalizar tu experiencia y a brindarte un mejor servicio.",
    sendOTPTextLabel: "Te enviaremos un código único a tu dirección de correo electrónico o número de teléfono. Utiliza este código para verificar que tienes acceso a los medios de contacto proporcionados.",
    reSendOTPTextLabel: "En caso de no haber recibido el código, tienes la opción de solicitar su reenvío. Verifica tu carpeta de correo no deseado antes de hacerlo.",
    codeConfirmTextLabel: "Ingresa el código que has recibido y confirma tu acceso. Este código garantiza que eres el titular de la cuenta y estás listo para proceder.",
    subscriptionToAppTextLabel: "Opta por suscribirte a nuestra aplicación para acceder a sus funciones exclusivas. Mantente al tanto de las últimas actualizaciones y novedades.",
    ownerDataTextLabel: "Proporciona información detallada sobre ti.",
    enterpriseCreationTextLabel: "Si estás creando una empresa, completa los pasos necesarios para registrarla oficialmente. Proporciona información sobre ella.",
    userDataTextLabel: "Proporciona información adicional sobre ti para enriquecer tu perfil.",
    stepChangePassTextLabel: "Si deseas cambiar tu contraseña actual, este es el momento para hacerlo. Proporciona una nueva contraseña segura y asegúrate de guardarla en un lugar seguro.",
    enterpriseDataTextLabel: "Si estás registrando una empresa, completa los detalles adicionales.",
    enterpriseDocumentsTextLabel: "Sube documentos importantes relacionados con tu empresa.",
    requiredDataTextLabel: "Asegúrate de proporcionar todos los datos necesarios para completar el proceso. Estos pueden variar según el tipo de cuenta que estás creando. Verifica cada sección cuidadosamente.",

    //ResetPassword

    resetPasswordTitle: "¿Has olvidado tu contraseña?",
    resetPasswordText: "No te preocupes, ¡puede pasarle a cualquiera!",
    resetPasswordFormatError: "8-16 carácteres, que contengan 3 de los 4 siguientes: minúsculas, mayúsculas, dígitos (0-9), y uno o más de los siguientes símbolos: @ # $ % ^ & * - _ + = [ ] { } | \\ : ' , ? / ` ~ ( ) ; . \"",
    resetPasswordCheckError: "Las contraseñas no son iguales",
    resetPasswordTitle2: "Cambia tu contraseña",
    resetPasswordText2: "Confirma tu identidad con el código que te hemos enviado a:",
    resetPasswordEmptyError: "La contraseña no puede estar vacía",
    resetPasswordRedirection: "En unos segundos te redirigiremos al Inicio de sesión, o puedes ir pulsando ",
    lAddUser:
        "Enter your Correos identity and we will help you create a new password.",
    backButton: "Back",
    sending: "Sending...",
    lAddOtp: "We need to know it’s really you. Please confirm",
    lOtp: "Verification code",
    emptyError: "This field cannot be left blank.",
    emptyErrorPass: "The fields cannot be left blank.",
    emptyErrorIdentifier: "You must add a valid Correos identifier.",
    lChangePass: "Write your new password.",
    lPass: "Password",
    lRPass: "Repeat your password",
    errorEqualPass: "The passwords must be the same",
    errorCheckPass: "The indicated password is not valid.",
    lCFinalStep: "The password was successfully changed.",
    lEFinalStep:
        "There was an error changing the password. Please try again in a few minutes",
    lStart: "Start",
    tLinkHelper: "Do you need any help?",
    hAddUser:
        "To change your password, we need to know the identifier you used when registering on our platform.",
    hAddOtp:
        "correosID needs to verify that it is you who is trying to change the password, so we will send your account identifier (phone number or email) a 5-digit code. Just tell us the code and we can continue the process. ",
    hAddPass:
        "You must indicate a new password with 8-16 characters, with 3 of the following 4: lower case, upper case, numbers (0-9) and one or more of the following symbols: @ # $ % ^ & * - _ + = [ ] { } |  : ' , ? / ` ~ \" ( ) ; . The minimum needed to continue.",
    dialogClose: "Close",
    otpRpError: "The indicated code is not correct.",
    otpInvalidError: "Invalid OTP",
    otpEmptyError: "The code cannot be left blank.",

    //PrefixPhone

    prefixSelector: "Prefix",

    //UserData

    userDataInfo:
        "You have registered in CorreosId, now is the time to subscribe to {appName}",
    userDataInfoCid:
        "You have registered correctly in CorreosId, now it is necessary to complete the required data",
    userDataVerifyEmailOk: "The email address was successfully verified",
    userDataVerifyEmailKo:
        "There was a problem with the email address verification",
    userDataVerifyPhoneOk: "The phone number was successfully verified",
    userDataVerifyPhoneKo:
        "There was a problem with the phone number verification",
    userDataInfoQuestion: "¿Seguro que deseas cancelar la suscripción a {appName}?",
    userDataInfoQuestionCid: "¿Seguro que deseas cancelar la creación de la empresa?",
    userDataExitInfo:
        "Ya estás registrado en correosID, ten en cuenta que si sales ahora cuando hagas login en {appName} tendrás que terminar de rellenar los datos para suscribirte.",
    userDataExitInfoCid:
        "Ya estás registrado en correosID, ten en cuenta que si sales ahora tu usuario no tendrá empresa",
    userDataCopyDirection: "Copy address",
    userDataSuccessUpdate: "User details were updated successfully",
    userDataAddEmail: "Add email address",
    userDataModifyEmail: "Verificar email",
    userDataDeleteEmail: "Eliminar email",
    userDataDeleteEmailSuccess: "Email eliminado",
    userDataAddPhone: "Add phone number",
    userDataModifyPhone: "Verificar teléfono",
    userDataDeletePhone: "Eliminar teléfono",
    userDataDeletePhoneSuccess: "Teléfono eliminado",
    userDataAddDoc: "Añadir documento",
    userDataModifyDoc: "Modificar documento identificativo",
    userDataDeleteDoc: "Eliminar documento identificativo",
    userDataAddDocSuccess: "Documento identificativo añadido",
    userDataModifyDocSuccess: "Documento identificativo modificado",
    userDataDeleteDocSuccess: "Documento identificativo eliminado",
    userDataAddAddress: "Añadir dirección",
    userDataModifyAddress: "Modificar Dirección",
    userDataDeleteAddress: "Eliminar Dirección",
    userDataAddAddressSuccess: "Dirección añadida",
    userDataModifyAddressSuccess: "Dirección modificada",
    userDataDeleteAddressSuccess: "Dirección eliminada",
    userDataSaveWarning:
        "Remember to save the information so this change takes effect",
    userDataAliasWarning: "Ya tienes una dirección con ese nombre",
    userDataAddDirection:
        "Do you want to add an address with the current information?",
    userDataAddDirectionMandatorie:
        "To add an address, please fill in all the required fields",
    userDataRequiredDirection: "The Username and Address are required fields",
    userDataAddDirectionSuccess:
        "Address added successfully.",
    userDataChangeDirectionSuccess: "Dirección modificada con éxito",
    userDataFavouriteDirection: "Dirección favorita",
    userDataFavouriteDirectionWarning: "Ya tienes una dirección favorita y sólo puedes tener una, si seleccionas esta la otra dejará de serlo",
    userDataWarningMessage:
        "Remember that to save a new address you must first add it to the list. If you have not yet done so, click Cancel and add the new address before continuing",
    userDataRegister: "Registro completado",
    userDataIncomplete:
        "Required information is missing. Please fill in the remaining required information.",
    userDataFNAError: "The date of birth cannot be later than today.",
    userDataDateError: "The date is not correct",
    userDataSaveProfile: "Guardar perfil",
    enterpriseDataSaveProfile: "Guardar empresa",
    userDataAddressUpdateWarning: "It is necessary to update this address because its data is not correct",
    userDataPageSubtitle: "Edita tus datos personales y gestiona las direcciones",
    userFormatIncorrect: "Formato no soportado. Solo se permiten letras",

    //EnterpriseData

    enterpriseDataInfo:
        "The registration was completed correctly. Please finish filling in your company information",
    enterpriseDataInfoQuestion:
        "Are you sure you want to cancel entering the details?",
    enterpriseDataExitInfo:
        "Your company is now registered. If you log out without filling in the information, you will be asked for it next time you log in",
    enterpriseDataCopyDirection: "Copy address",
    enterpriseDataSuccessUpdate: "Company details were updated successfully",
    enterpriseDataAddressWarning: "La dirección se guardará junto con la creación de la empresa",
    enterpriseDataCreated: "Company created successfully",
    enterpriseDataSaveData: "Guardar datos",
    enterpriseDataPageSubtitle: "Modifica los datos de tu empresa así como la dirección fiscal",
    enterpriseDataCreatePageSubtitle: "Añade los datos de tu empresa así como la dirección fiscal",
    enterpriseDataSave: "Guardar empresa",
    enterpriseDataCreate: "Crear empresa",

    //DialogAddChange
    dialogAddChangeAddTitle: "Add address",
    dialogAddChangeChangeTitle: "Modify address",
    dialogAddChangeAddText: "Add address data",
    dialogAddChangeChangeText: "Modify the address data",

    //Main

    userNameUnregistered: "User without name",
    enterpriseNameUnregistered: "Company without name",
    mainPageTitle: "Welcome to CorreosID",
    mainPageUserProfile: "User profile",
    mainPagePrivacity: "Privacy",
    mainPageVerifications: "Verifications",
    mainPageCreateEnterprise: "Create company",
    mainPageAvailableServices: "Subscribed services",

    //SideBar

    UserData: "Details",
    UserVerifications: "Verifications",
    UserPrivacy: "Privacy",
    EnterpriseCreate: "Create",
    EnterpriseTeam: "Equipment",
    EnterpriseDelete: "Unsubscribe",
    UserServices: "Individual",
    EnterpriseData: "Details",
    EnterpriseVerifications: "Verifications",
    EnterprisePrivacy: "Privacy",
    EnterpriseServices: "Company",
    User: "User",
    Enterprise: "Company",
    Services: "Services",
    Service: "Servicio",
    profileComp: "Profile progress",

    //Service Page

    subscribeService: "Subscribe",
    unsubscribeService: "Unsubscribe",
    revokeData: "Remove access to My Details",
    acceptData: "Permit access to My Details",
    goToService: "Enter",
    goToUrl: "Go to",
    subscribedService: "Subscribed ",
    unSubscribedService: "Not Subscribed",
    askUnsubscribeService: "Unsubscribe",
    serviceSubscribed: "Successfully subscribed",
    serviceUnsubscribed: "Successfully unsubscribed",
    revokeEnterpriseData: "Withdraw permissions",
    servicePageDualWarning: "¿Seguro que quieres darte de baja de {applicationName}?",
    servicePageCancelAccount: "Usuario",
    servicePageCancelEnterprise: "Empresa",
    servicePageUnsubscribe: "Servicio",
    singleSubscriptionDialogRedirectTextUser: "Estás a punto de darte de baja de {applicationName}, que es tu único servicio. Si deseas continuar con esta acción, pulsa en 'Servicio'. Por otro lado, si lo que quieres es dar de baja tu usuario de Correos ID, pulsa en 'Usuario'.",
    singleSubscriptionDialogRedirectTextEnterprise: "Estás a punto de dar de baja la empresa de {applicationName}, que es su único servicio. Si deseas continuar con esta acción, pulsa en 'Servicio'. Por otro lado, si lo que quieres es dar de baja la empresa, pulsa en 'Empresa'.",
    servicePageLinkText: "link",
    subscribeMeText: "Subscribe Me",
    subscriptionOkTitle: "Done!",
    subscriptionOkText: "You have subscribed successfully",
    servicePageSubtitle: "With your Correos ID (Correos ID) you can subscribe to the digital services of Correos",
    servicePageAppGoTo: "Suscríbete desde el servicio",
    servicePageAppAccess: "Accede desde el servicio",
    servicePageConfirmCancelAccount: "¿Seguro que quieres cancelar tu cuenta?",
    servicePageConfirmCancelEnterprise: "¿Seguro que quieres eliminar la empresa?",

    //Verifitacions Page

    verificationsVerify: "Verify",
    verificated: "Verified",
    notVerificated: "Not Verified",
    pendingVerificated: "Pending",
    UserEmail: "Email",
    PersonaTelfMovil: "Telephone number",
    PersonaNumDocID: "Document no.",
    PersonaDireccion: "Address",
    verificationsSendCode: "We will send a verification code to: ",
    verificationsSendCodeEmail: "Verifica tu email",
    verificationsSendCodePhone: "Verifica tu teléfono",
    verificationsAddVerifEmail: "Añade y verifica un email",
    verificationsAddVerifPhone: "Añade y verifica un teléfono",
    verificationsConfirmCode: "Enter the verification code",
    verificationsVerificationType: "Verification type",
    verificationsConfirmPostCard:
        "We will email you the code. Are you sure you want to do this type of verification?",
    verificationsCreate: "Enter the information to be added ",
    verificationsAlias: "Username",
    verificationsCountry: "Country or region",
    verificationsDirection: "Address",
    verificationsPostalCode: "Postcode",
    verificationsRegion: "Province/Region",
    verificationsTown: "Town or City",
    verificatinosDeleteElement: "Delete the element?",
    verificationsVerifiedData: "Verified details",
    verificationsEMA: "Verified emails",
    verificationsMOV: "Verified phone numbers",
    verificationsNDO: "Verified documents",
    verificationsALI: "Verified addresses",
    verificationsEmpty: "None",
    verificationsUpgrade: "Aumentar nivel de verificación",
    verificationsAddEmail: "Email address added correctly",
    verificationsVerifyEmail: "Email address verified correctly",
    verificationsDeleteEmail: "Email address deleted correctly",
    verificationsAddPhone: "Phone number added correctly",
    verificationsVerifyPhone: "Phone number verified correctly",
    verificationsDeletePhone: "Phone number deleted correctly",
    verificationsAddAddress: "Dirección añadida correctamente",
    verificationsVerifyAddress: "Dirección verificada correctamente",
    verificationsDeleteAddress: "Dirección eliminada correctamente",
    verificationsDeleteDocument: "Documento eliminado correctamente",
    verificationsDeleteAddressTooltip: "Eliminar dirección",
    verificationsDocuments: "Documents",
    verificationsemail: "Email",
    verificationsphone: "Telephone number",
    verificationsaddress: "Address",
    verificationsnumber: "Identity document",
    verificationsByOtp: "Verified by code",
    verificationsByForm: "Verified by format",
    verificationsReSendOtp: "Code re-sent",
    verificationDisableAccountTitle:
        "If you cancel your Correos ID account you will no longer have access to the following services:",
    verificationDisableAccountList: "We will delete your account*.",
    verificationDisableAccountP1:
        "Before you delete your Correos ID account we advise you to request and finalise the cancellation of the services where you are registered. To do so, choose the option Cancel. If you would prefer to delete your Correos ID account now, click on Delete Account.",
    verificationDisableAccountP2:
        "*Don’t worry, you have {disableAccDays} days to recover your Correos ID account if you change your mind. You can recover the Correos ID account, but the other services may not save your data during these {disableAccDays} days.",
    verificationDisableAccountP3:
        "Correos ID will inform the associated services that your account has been deleted, but the cancellation of your accouint with each of these services is not the responsibility of Correos ID",
    verificationDisableAccountConfirm: "Delete Account",
    verificationsWrongTipeDocument: "The document type is not valid",
    verificationsUploadSuccess: "Document successfully uploaded",
    verificationsDeleteSuccess: "Document successfully deleted",
    verificationsNoUploaded: "Not uploaded",
    verificationsEmailExists: "The email address entered already exists",
    verificationsPhoneExists: "The phone number entered already exists",
    verificationsUnifyUserTitle: "Atención",
    verificationsUnifyUserText: "Hemos detectado que el {dataType} que estás intentando verificar ya existe en otra cuenta. ¿Es este {dataType} ({data}) realmente tuyo?. Si es así pulsa en 'Aceptar' para unificar ambas cuentas",
    verificationsPageSubtitle: "Puedes gestionar y verificar tus datos, así como cambiar tu contraseña de acceso",
    verificationsEnterprisePageSubtitle: "Puedes gestionar y verificar los datos de la empresa",
};
