import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { CommonStyles } from "../../commons/CommonStyles";
import { UserVerificationsStyles } from "../UserVerifications/UserVerificationsStyles";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { useTranslation } from "react-i18next";
import { DialogVerifications } from "./DialogVerifications";

export function ActionsCard(props) {
  const {
    tipo,
    verificables,
    getIcon,
    getVerificableName,
    buildDeleteVerificable,
    finishOperation,
    masterCountryList,
    addVerificable,
  } = props;
  const commonClasses = CommonStyles();
  const classes = UserVerificationsStyles();
  const { t, i18n } = useTranslation();

  const [selected, setSelected] = useState("");
  const [openVerifications, setOpenVerifications] = useState(false);
  const [operationType, setOperationType] = useState(null);

  useEffect(() => {
    setSelected(verificables[0].id);
  }, [verificables]);

  const handleSelect = (value) => {
    setSelected(value);
  };

  const getSelected = () => {
    let auxVerificable = verificables.find(
      (verificable) => verificable.id == selected
    );
    return auxVerificable;
  };

  const handleVerification = () => {
    if (selected) {
      setOperationType("ver");
      setOpenVerifications(true);
    };
  };

  const handleAddition = () => {
    setOperationType("add");
    setOpenVerifications(true);
  };

  return (
    <Card className={commonClasses.generalCardContainer}>
      <CardContent>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={12}>
            <Typography variant="h6" className={commonClasses.title} noWrap>
              {t("verificationsUpgrade")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            className={classes.iconGridStyle}
            style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            {getIcon(tipo)}
          </Grid>
          <Grid item md={10} xs={10}>
            <FormControl fullWidth variant="filled" color="secondary" className={commonClasses.inputsStyle}>
              <InputLabel>{t("verifications" + tipo)}</InputLabel>
              <Select
                native
                label={t("verifications" + tipo)}
                fullWidth
                value={selected}
                onChange={(value) => {
                  handleSelect(value.target.value);
                }}
              >
                {verificables.map((verificable) => {
                  return (
                    <option key={verificable.id} value={verificable.id}>
                      {getVerificableName(verificable, tipo)}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} className={commonClasses.iconGridStyle}>
            {buildDeleteVerificable(selected, false, tipo)}
          </Grid>
          <Grid item md={1} xs={1} className={classes.iconGridStyle}></Grid>
          <Grid item md={3} xs={3} className={classes.buttonGridStyle}>
            <CorreosButton
              variant="contained"
              color="primary"
              onClick={() =>
                handleVerification(selected, tipo)
              }
              size="small"
              fullWidth
            >
              {t("verificationsVerify")}
            </CorreosButton>
          </Grid>
          <Grid item md={7} xs={7} className={classes.textGridStyle}>
          </Grid>
          <Grid item md={1} xs={1} className={classes.iconGridStyle}>
            <Tooltip title={t("add")}>
              <IconButton
                className={commonClasses.iconButton}
                aria-label="menu"
                onClick={() => handleAddition()}
              >
                <AddToPhotosIcon className={commonClasses.iconActionStyle} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </CardContent>
      <DialogVerifications
        open={openVerifications}
        setOpen={setOpenVerifications}
        verificable={getSelected()}
        type={tipo}
        finishOperation={finishOperation}
        getVerificableName={getVerificableName}
        operationType={operationType}
        masterCountryList={masterCountryList}
        addVerificable={addVerificable}
      />
    </Card>
  );
}
