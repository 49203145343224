import { makeStyles } from "@material-ui/core/styles";

export const SelectorSwitchStyles = makeStyles((theme) => ({
  selectorButtonSelected: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    color: "white",
    backgroundColor: "#002453",
    borderRadius: "3em",
    cursor: "pointer",
    paddingLeft: "2em",
    paddingRight: "2em",
  },
  selectorButtonNotSelected: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    paddingLeft: "2em",
    paddingRight: "2em",
  },
  selectorContainer: {
    border: "1px solid #CBCBCB",
    borderRadius: "3em",
    margin: "1em",
    height: "3em",
    fontSize: "1.2rem",
  },
  selectorPopupContainer: {
    border: "1px solid #CBCBCB",
    borderRadius: "3em",
    marginTop: "0.5em",
    marginBotton: "0.5em",
    height: "3em",
    fontSize: "1rem",
  },
  selectorContainerBig: {
    border: "1px solid #CBCBCB",
    borderRadius: "3em",
    height: "3em",
    fontSize: "1.2rem",
    width: "100%",
    margin: "auto",
  },
  selectorContainerMedium: {
    border: "1px solid #CBCBCB",
    borderRadius: "3em",
    height: "2.75em",
    fontSize: "1rem",
    width: "80%",
    margin: "auto",
  },
  selectorContainerSmall: {
    border: "1px solid #CBCBCB",
    borderRadius: "3em",
    height: "2.5em",
    fontSize: "0.8rem",
    width: "60%",
    margin: "auto",
  },
}));
