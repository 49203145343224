import { makeStyles } from "@material-ui/core/styles";
export const LogInStyles = makeStyles((theme) => ({

  gridIdentity: {
    display: "flex",
    margin: "auto",
  },
  gridPassword: {
    display: "block",
    margin: "auto",
  },
  loginTitle: {
    marginBottom: "1rem",
    marginTop: "1.5rem",
  },
  logInSubtitle: {
    marginTop: "0.5rem",
    textAlign: "left",
  },
  logInUserCheck: {
    marginTop: "1rem",
    textAlign: "right",
  },
  loginUserCompleteName: {
    marginTop: "2.5em",
    marginBottom: "1em",
  },
  loginUserNameTypography: {
    color: "#002453",
    cursor: "pointer",
    fontSize: "1rem",
    lineHeight: "1.5",
  },
  loginUnderline: {
    "& .MuiInputBase-input": {
      "&:before": {
        border: "1px solid #FFCD00",
      },
      "&:hover:before": {
        border: "2px solid #FFCD00",
      },
      "&:after": {
        border: "2px solid #002453",
      },
    },
  },
  loginRememberMeLabel: {
    fontSize: "1rem",
    marginTop: "auto",
    marginBottom: "auto",
  },
  loginForgotPasswordLink: {
    marginLeft: "auto",
    fontSize: "1rem",
    marginTop: "auto",
    marginBottom: "auto",
    color: "#002453",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": { color: "#002453", textDecoration: "underline" },
  },
  loginSelectorButtonSelected: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    color: "white",
    backgroundColor: "#002453",
    borderRadius: "3em",
    cursor: "pointer",
  },
  loginSelectorButton: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    // fontSize: "1.2rem",
  },
  loginSelectorContainer: {
    border: "1px solid #CBCBCB",
    borderRadius: "3em",
    margin: "1em",
    height: "3em",
    fontSize: "1.2rem",
  },
}));
