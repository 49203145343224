import { makeStyles } from "@material-ui/core/styles";
import {Constants} from "../../commons/Constants";

export const MainStyles = makeStyles((theme) => ({
  container: {
    marginTop: "8.8em",
    [theme.breakpoints.up("sm")]: {
      marginTop: Constants.MarginTopApp,
      marginLeft: Constants.DrawerWidth,
    },
  },
  hideContainer: {
    marginTop: Constants.MarginTopApp,
    marginLeft: Constants.DrawerWidth,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mainContainer: {
    margin: "auto",
  },
  cardButton: {
    width: "100%",
  },
  cardStyle: {
    width: "100%",
    height: "17em",
    backgroundColor: "#ffffff",
  },
  iconStyle: {
    width: "6em",
    height: "6em",
    margin: "auto",
    color: "#002453",
  },
  cardTextStyle: {
    color: "#002453"
  },
  smallCardStyleUp: {
    width: "100%",
    height: "10em",
    backgroundColor: "#ffffff",
  },
  smallCardStyleDown: {
    width: "100%",
    height: "6em",
    backgroundColor: "#ffffff",
  },
  smallIconStyleUp: {
    width: "3em",
    height: "3em",
    margin: "auto",
    color: "#002453",
  },
  smallIconStyleDown: {
    width: "1.5em",
    height: "1.5em",
    margin: "auto",
  },
  leftButtonCard: {
    [theme.breakpoints.up("sm")]: {
      float: "right",
    },
  },
  rightButtonCard: {
    [theme.breakpoints.up("sm")]: {
      float: "left",
    },
  },
  icon: {
    fill: "currentColor",
    width: "0.9em",
    height: "0.9em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    flexShrink: "0",
    userSelect: "none",
    margin: "auto",
  },
  snackBar: {
    paddingTop: "4em",
    backgroundColor: "#ffcd00",
  },
  title: {
    color: "#002453",
  },
  carouselContainer: {
    marginTop: "15px",
    padding: "8px",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  carouselButton: {
    margin: "1em 1.2em",
  },
  carouselIcon: {
    margin: "auto",
    width: "7.6em",
    height: "3em",
  },
  carouselCard: {
    width: "8em",
    height: "8em",
    margin: "auto",
    padding: "3px",
  },
  carouselText: {
    fontSize: "0.75em",
    color: "#002453",
  },
  carouselControlsPrev: {
    left: 0,
    position: "absolute",
    top: "0",
    bottom: "0",
    zIndex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "15%",
  },
  carouselControlsNext: {
    right: 0,
    position: "absolute",
    top: "0",
    bottom: "0",
    zIndex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "15%",
  },
  media: {
    maxWidth: "8rem",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  circularProgres: {
    marginTop: "30px",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));
