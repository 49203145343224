import { EU1 } from "./translations1";
import { EU2 } from "./translations2";
import { EUTYC1 } from "./TycsTranslation1";
import { EUTYC2 } from "./TycsTranslation2";

const EU = { ...EU1, ...EU2, ...EUTYC1, ...EUTYC2 };
export { EU };



