import { Checkbox, Divider, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Typography } from "@material-ui/core"
import { CheckBoxInput } from "./CheckBoxInput";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { obtainAttribute } from "../../../commons/FormManager";
import { checkIsNullUndefined, getDate } from "../../../commons/Utils";
import { AttributeCodes } from "../../../commons/Attributes";

export const mountWebTitlesAux = (auxTitle, auxSubtitle, classesObject) => {
    let commonClasses = classesObject.commonClasses;
    return <Grid
        container
        item
        xs={12}
        className={commonClasses.formContainer}
    >
        <Grid item xs={12}>
            <Typography variant="h5" className={commonClasses.gridTitle}>
                {auxTitle}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h6" className={commonClasses.greyColor}>
                {auxSubtitle}
            </Typography>
        </Grid>
    </Grid>
};

export const mountWebFormsAux = (title, auxArray, inputType, classesObject, handlers, doTraduction) => {
    let commonClasses = classesObject.commonClasses;
    let classes = classesObject.classes;
    return <Grid
        container
        item
        xs={12}
        md={6}
        spacing={0}
        className={commonClasses.formContainer}
        style={{ display: "block" }}
    >
        <Grid item xs={12}>
            <Typography variant="h6" className={classes.sectionTitle} style={{ marginBottom: "1em" }}>
                {title}
            </Typography>
        </Grid>
        {auxArray.length > 0 ?
            <Grid container item xs={12} spacing={1}>
                {auxArray.map((attr) => {
                    return generateCheckBoxInput(attr, inputType, handlers);
                })}
            </Grid> :
            <Grid item xs={12} spacing={1}>
                <Typography variant="h6" className={classes.noDataText}>
                    {doTraduction("unifyAccountsNoData")}
                </Typography>
            </Grid>}
    </Grid>
};

const generateCheckBoxInput = (attribute, auxType, handlers) => {
    let auxKey = attribute.codeAttribute + "_" + attribute.isCopy + "_" + attribute.id;
    let auxHandleCall = handlers.handleInputSelected;
    if (auxType == "multi") {
        auxHandleCall = handlers.handleInfoSelected;
    } else if (auxType == "enter" || auxType == "service" || auxType == "info") {
        auxHandleCall = () => { };
    }
    return (
        <Grid key={auxKey} item xs={12} >
            <CheckBoxInput
                attribute={attribute}
                handleInputSelected={auxHandleCall}
            />
        </Grid>
    );
};

export const mountUserDataTextAux = (code, userToViewData) => {
    let auxAttribute = obtainAttribute(userToViewData, code);
    if (!checkIsNullUndefined(auxAttribute)) {
        let auxAttributeValue = checkIsNullUndefined(auxAttribute.value) ? "-" : auxAttribute.value;
        if (code == AttributeCodes.Birthday && auxAttributeValue != "-") {
            auxAttributeValue = getDate(auxAttributeValue, true);
        }
        return (
            <Grid item xs={12}><Typography variant="subtitle2"><strong>{auxAttribute.nameAttritube + ": "}</strong>{auxAttributeValue}</Typography></Grid>
        );
    };
};

const mountMobileFormsUserData = (getUserInfo, nameSelectedMobile, classesObject, settersObject, getDocCodes) => {
    let usersInfo = getUserInfo();
    let classes = classesObject.classes;
    return (
        <Grid item xs={12}>
            <RadioGroup
                defaultValue={usersInfo.user1}
                value={nameSelectedMobile}
                onChange={(e) => handleChangeName(e.target.value, usersInfo, settersObject, getDocCodes)}
                className={classes.formMobileRadioGroup}>
                <FormControlLabel value={usersInfo.user1} control={<Radio />}
                    label={handleAddIcon(usersInfo.user1, usersInfo.auxUser1, classesObject, settersObject)} />
                <FormControlLabel value={usersInfo.user2} control={<Radio />}
                    label={handleAddIcon(usersInfo.user2, usersInfo.auxUser2, classesObject, settersObject)} />
            </RadioGroup>
        </Grid>
    );
};

const handleChangeName = (value, usersInfo, settersObject, getDocCodes) => {
    settersObject.setNameSelectedMobile(value);
    let docCodes = getDocCodes();
    usersInfo.userDataForm?.forEach((attr) => {
        if (!docCodes.includes(attr.code))
            attr.checked = value == usersInfo.user1;
    });
    usersInfo.copyDataForm?.forEach((attr) => {
        if (!docCodes.includes(attr.code))
            attr.checked = value == usersInfo.user2;
    });
};

const handleAddIcon = (name, user, classesObject, settersObject) => {
    let commonClasses = classesObject.commonClasses;
    return (
        <Grid container item xs={12} style={{ display: "inline-flex", alignItems: "center" }}>
            <Grid item xs={10}>
                <Typography className={commonClasses.listTextStyleShow} noWrap>
                    {name}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <IconButton
                    onClick={() => openDataUserDialog(user, name, settersObject)}
                >
                    <VisibilityIcon className={commonClasses.iconActionStyle} />
                </IconButton>
            </Grid>
        </Grid>
    );
};

const openDataUserDialog = (user, name, settersObject) => {
    settersObject.setSelectedUser(name);
    settersObject.setUserToViewData(user);
    settersObject.setOpenUserDataDialog(true);
};

const mountMobileFormsGeneric = (getStepsInfo, getUserInfo, doTraduction, handleInfoSelected) => {
    let stepsInfo = getStepsInfo();
    let phoneSteps = stepsInfo.phoneSteps;
    let stepPage = stepsInfo.stepPage;
    let userInfo = getUserInfo();
    let auxUserValues = phoneSteps[stepPage] == "userEmail" ? userInfo.userEmails : phoneSteps[stepPage] == "userPhone" ? userInfo.userPhones : userInfo.userAddresses;
    let auxCopyValues = phoneSteps[stepPage] == "userEmail" ? userInfo.copyEmails : phoneSteps[stepPage] == "userPhone" ? userInfo.copyPhones : userInfo.copyAddresses;
    return (
        <Grid item xs={12} key={phoneSteps[stepPage]}>
            <RadioGroup defaultValue={userInfo.user1} >
                <Typography>
                    <strong>{userInfo.user1}</strong>
                </Typography>
                {auxUserValues.length == 0 ? (
                    <Typography>
                        {doTraduction("unifyAccountsNoData")}
                    </Typography>
                ) : (
                    auxUserValues.map((element1) => {
                        return <FormControlLabel key={element1.value} checked={element1.checked} value={element1.value} control={<Checkbox defaultChecked />} label={element1.value} />
                    })
                )}
                <Divider style={{ width: "100%", marginTop: "0.5em", marginBottom: "1em" }} />
                <Typography>
                    <strong>{userInfo.user2}</strong>
                </Typography>
                {auxCopyValues.length == 0 ? (
                    <Typography>
                        {doTraduction("unifyAccountsNoData")}
                    </Typography>
                ) : (
                    auxCopyValues.map((element2) => {
                        return <FormControlLabel key={element2.value} checked={element2.checked} value={element2.value} control={<Checkbox />} label={element2.value} onChange={(e) => handleInfoSelected(e, element2)} />
                    })
                )}
            </RadioGroup>
        </Grid>
    );
};

const mountMobileFormsDocuments = (docTypes, getUserInfo, docSelectedObject, doTraduction, handleInputSelected) => {
    let userInfo = getUserInfo();
    return (
        <Grid
            container
            item
            xs={12}>
            {docTypes.map((auxDocType) => {
                return handleDocPage(auxDocType, userInfo, docSelectedObject, doTraduction, handleInputSelected);
            })}
        </Grid>
    );
};

const handleDocPage = (auxDocType, userInfo, docSelectedObject, doTraduction, handleInputSelected) => {
    let title = auxDocType.name;
    let user1Doc = obtainAttribute(userInfo.userDataForm, auxDocType.code);
    let user2Doc = obtainAttribute(userInfo.copyDataForm, auxDocType.code);
    if (checkIsNullUndefined(user1Doc) && checkIsNullUndefined(user2Doc)) {
        return null;
    } else {
        let auxValue1 = checkIsNullUndefined(user1Doc.value) ? doTraduction("unifyAccountsMobileNoDoc") : user1Doc.value;
        let auxLabel1 = auxValue1 + " " + userInfo.user1;
        let auxValue2 = checkIsNullUndefined(user2Doc.value) ? doTraduction("unifyAccountsMobileNoDoc") : user2Doc.value;
        let auxLabel2 = auxValue2 + " " + userInfo.user2;
        return (
            <Grid item xs={12}>
                <Divider style={{ width: "100%", marginTop: "0.5em", marginBottom: "1em" }} />
                <Typography>
                    <strong>{title}</strong>
                </Typography>
                <RadioGroup
                    defaultValue={user1Doc.value}
                    value={docSelectedObject.docSelectedValue[auxDocType.code]}
                    onChange={(e) => handleChangeDocument(e, auxDocType.code, docSelectedObject, userInfo, handleInputSelected)} >
                    <FormControlLabel value={user1Doc.value} control={<Radio />} label={auxLabel1} />
                    <FormControlLabel value={user2Doc.value} control={<Radio />} label={auxLabel2} />
                </RadioGroup>
            </Grid>
        );
    };
};

const handleChangeDocument = (e, auxCode, docSelectedObject, userInfo, handleInputSelected) => {
    let value = e.target.value;
    let auxDocSelectedValue = JSON.parse(JSON.stringify(docSelectedObject.docSelectedValue));
    auxDocSelectedValue[auxCode] = value;
    docSelectedObject.setDocSelectedValue(auxDocSelectedValue);
    let userDocAttr = obtainAttribute(userInfo.userDataForm, auxCode);
    let copyDocAttr = obtainAttribute(userInfo.copyDataForm, auxCode);
    let actualAttr = userDocAttr.id == value ? userDocAttr : copyDocAttr;
    handleInputSelected(e, actualAttr);
};

const mountMobileFormsServicesAndEnterprises = (getStepsInfo, getUserInfo, doTraduction) => {
    let stepsInfo = getStepsInfo();
    let phoneSteps = stepsInfo.phoneSteps;
    let stepPage = stepsInfo.stepPage;
    let userInfo = getUserInfo();
    let auxUserValues = phoneSteps[stepPage] == "userEnterprise" ? userInfo.userEnterprises : userInfo.userServices;
    let auxCopyValues = phoneSteps[stepPage] == "userEnterprise" ? userInfo.copyEnterprises : userInfo.copyServices;
    return (
        <Grid item xs={12} key={phoneSteps[stepPage]}>
            <RadioGroup defaultValue={userInfo.user1} >
                <Typography>
                    <strong>{userInfo.user1}</strong>
                </Typography>
                {auxUserValues.length == 0 ? (
                    <Typography>
                        {doTraduction("unifyAccountsNoData")}
                    </Typography>
                ) : (
                    auxUserValues.map((element1) => {
                        return <FormControlLabel key={element1.value} checked={element1.checked} value={element1.value} control={<Checkbox />} label={element1.value} />
                    })
                )}
                <Divider style={{ marginBottom: "1em" }} />
                <Typography>
                    <strong>{userInfo.user2}</strong>
                </Typography>
                {auxCopyValues.length == 0 ? (
                    <Typography>
                        {doTraduction("unifyAccountsNoData")}
                    </Typography>
                ) : (
                    auxCopyValues.map((element2) => {
                        return <FormControlLabel key={element2.value} checked={element2.checked} value={element2.value} control={<Checkbox />} label={element2.value} />
                    })
                )}
            </RadioGroup>
        </Grid>
    );
};

export const handleStepAux = (getStepsInfo, getUserInfo, classesObject, settersObject, handlers, generalInfo) => {
    let stepsInfo = getStepsInfo();
    let phoneSteps = stepsInfo.phoneSteps;
    let stepPage = stepsInfo.stepPage;
    let classes = classesObject.classes;
    let auxFunction = () => { };
    switch (phoneSteps[stepPage]) {
        case "userData":
            auxFunction = mountMobileFormsUserData(getUserInfo, generalInfo.nameSelectedMobile, classesObject, settersObject, generalInfo.getDocCodes);
            break;
        case "userDocument":
            auxFunction = mountMobileFormsDocuments(generalInfo.docTypes, getUserInfo, generalInfo.docSelectedObject, generalInfo.doTraduction, handlers.handleInputSelected);
            break;
        case "userEmail":
        case "userPhone":
        case "userAddress":
            auxFunction = mountMobileFormsGeneric(getStepsInfo, getUserInfo, generalInfo.doTraduction, handlers.handleInfoSelected);
            break;
        case "userEnterprise":
        case "userSubscription":
            auxFunction = mountMobileFormsServicesAndEnterprises(getStepsInfo, getUserInfo, generalInfo.doTraduction);
            break;
        default:
            auxFunction = mountMobileFormsUserData(getUserInfo, generalInfo.nameSelectedMobile, classesObject, settersObject, generalInfo.getDocCodes);
            break;
    };
    return <Grid
        container
        item
        xs={12}
        spacing={0}
        className={classes.formMobileContainer}
    >
        {handleStepTitle(getStepsInfo, generalInfo.doTraduction, classesObject, getUserInfo)}
        {phoneSteps[stepPage] == "userDocument" ? null : <Divider style={{ width: "100%", marginTop: "0.5em", marginBottom: "1em" }} />}
        {auxFunction}
    </Grid>
};

const handleStepTitle = (getStepsInfo, doTraduction, classesObject, getUserInfo) => {
    let stepsInfo = getStepsInfo();
    let phoneSteps = stepsInfo.phoneSteps;
    let stepPage = stepsInfo.stepPage;
    let commonClasses = classesObject.commonClasses;
    let classes = classesObject.classes;
    let userInfo = getUserInfo();
    const stepTitle = { userInfo: doTraduction("userInfoMobile"), userData: doTraduction("userDataMobile"), userDocument: doTraduction("documentsMobile"), userEmail: doTraduction("emailsMobile"), userPhone: doTraduction("phonesMobile"), userAddress: doTraduction("addressMobile"), userEnterprise: doTraduction("enterpriseMobile"), userSubscription: doTraduction("servicesMobile") }
    let auxStep = phoneSteps[stepPage];
    let auxShowStep = stepPage + 1;
    let auxText = doTraduction("unifyAccountsMobile" + auxStep);
    if (auxStep == "userEmail" || auxStep == "userPhone" || auxStep == "userAddress") {
        auxText = auxText.replace("{user2}", userInfo.user2);
    };
    return (
        <Grid item xs={12} className={classes.formTitle}>
            <Typography variant="h6" className={commonClasses.dialogTitleText}>
                {auxShowStep + "/" + phoneSteps.length}
            </Typography>
            <Typography variant="h4" className={commonClasses.dialogTitleText}>
                <u>{stepTitle[auxStep]}</u>
            </Typography>
            <Typography variant="h5" className={classes.formText}>
                {auxText}
            </Typography>
        </Grid>
    );
};