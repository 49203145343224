import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { StylesCorreosButton } from "./StylesCorreosButton";

export function CorreosButton(props) {
  const classes = StylesCorreosButton();
  const { circularProgress, children, className, ...other } = props;
  return (
    <div>
      {circularProgress ? (
        <CircularProgress className={className ? className : ""} style={{ margin: "auto" }} />
      ) : (
        <Button
          className={classes.correosButton + " " + (className ? className : "")}
          {...other}
        >
          {children}
        </Button>
      )}
    </div>
  );
}
