export const EU1 = {

  //Generic

  saveSuccess: "Behar bezala gorde da!",
  saveError: "Errorea gordetzean!",
  cancel: "Baliogabetu",
  accept: "Onartu",
  next: "Hurrengoa",
  previous: "Aurrekoa",
  required: "Bete beharreko eremua da",
  exit: "Irten",
  search: "Bilatu",
  news: "Berriak",
  send: "Bidali",
  reSend: "Birbidali",
  create: "Sortu",
  add: "Gehitu",
  delete: "Ezabatu",
  update: "Eguneratu",
  yes: "Bai",
  no: "Ez",
  save: "Gorde",
  correosId: "Correos ID",
  genericError: "Errore bat jazo da",
  change: "Aldatu",
  see: "Ikusi",
  download: "Deskargatu",
  email: "Email",
  phone: "Teléfono",
  idDocument: "Documento identificativo",
  address: "Helbidea",
  country: "Herrialdea",
  province: "Probintzia",
  city: "Udalerria",
  postalCode: "Posta-kodea",
  reset: "Berrasieratu",
  pending: "Pendiente",
  done: "Hecho",
  here: "aquí",
  backToTheApp: "Volver a la aplicación",
  selectOption: "Selecciona una opción",
  noOptionSelected: "Sin seleccionar",

  // PendingData

  valid: "Válido",
  document: "Documento",
  userAddress: "Dirección de usuario",
  enterpriseAddress: "Dirección de empresa",
  privacySign: "Firma de privacidad",
  tycSign: "Firma de Términos y Condiciones",
  pendingData: "Tienes datos pendientes:",

  //login

  logInTitle: "Inicia sesión",
  sublogInTitle: "Accede a {appName} a través de Correos ID. ",
  sublogInTitleCID: "Accede a Correos ID. ",
  sublogInTitleInfo: "Si ya estás registrado, no tendrás que volver a registrarte.",
  sublogInTitleInfoCID: "Desde aquí puedes manejar tu identidad de correos.",
  logInTitleSelectType: "¿Cómo quieres acceder?",
  correosIdentityLabel: "Correos-eko identitatea",
  correosPasswordLabel: "Pasahitza",
  correosCheckPasswordLabel: "Confirmación contraseña",
  loginTooltip: "Correos ID es la puerta de entrada a los servicios digitales de Correos. Para acceder a ellos, solamente debes introducir tus credenciales, no tendrás que volver a registrarte.",
  forgotPasswordButton: "Zure pasahitza ahaztu duzu?",
  nextButton: "Hurrengoa",
  logInButton: "Hasi Saioa",
  userPasswordError: "Erabiltzailea edo pasahitza ez da zuzena",
  warningLegal: "Lege-oharra",
  privacyPolicy: "Pribatutasun-politika",
  cookiesPolicy: "Cookie-en politika",
  createAccountButton: "Sortu Kontua",
  rememberme: "Gogorarazi",
  welcomeBack: "Kaixo berriz ere",
  recoverDisabledAccount1:
    "Zure kontua blokeatu egin da. Zure kontua berriz aktibatu eta informazioa berreskuratu nahi baduzu, egin klik ondoko ",
  recoverDisabledAccount2: "estekan",
  recoverDisabledAccount3: " kontua berreskuratzeko prozesuarekin hasteko.",
  loginPrivacyDialogTitle: "Pribatutasuna",
  loginPrivacyDialogText:
    "Aplikazioan sartzeko, lehenengo pribatutasuna onartu behar da",
  loginSubscribeDialogTitle: "Suscripción",
  loginSubscribeDialogText: "No estás suscrito a la aplicación, si quieres suscribirte debes leer y aceptar los términos y condiciones y pulsar en aceptar",
  loginSubscribeDialogTextServices: "Para poder suscribirte primero debes leer y aceptar los términos y condiciones y pulsar en aceptar",
  InvitationAcceptSuccess: "Invitación aceptada",
  AcceptChangeEnterpriseRoleSuccess: "Cambio de rol aceptado con éxito",
  CancelOrRejectChangeRoleSuccess: "Se ha rechazado la invitación",
  InvitationAcceptError: "La invitación ha fallado",
  AcceptChangeEnterpriseRoleError: "Ha fallado el cambio de rol",
  CancelOrRejectChangeRoleError: "Ha fallado el rechazo de la invitación",
  loginSystemUserError: "Un usuario de sistema no puede loguear en la web",
  footerCopyrightText: "©Sociedad Estatal Correos y Telegrafos, S.A., S.M.E. Todos los derechos reservados.",
  loginFormatError: "Formato incorrecto",
  loginOauthError: "El método oauth ya no está disponible",

  //Register

  loginPageInfo: "¿Aún no tienes una cuenta? ",
  loginPageRegistryHere: "Regístrate aquí",
  registerPagePutData: "Introduce los datos de registro",
  registerPageConfirmIdentity: "Verifica tu identidad",
  registerPageMail: "Helbide elektronikoa",
  registerPageMailError: "Idatzitako helbide elektronikoa ez da zuzena",
  registerPagePhoneError: "Idatzitako telefono-zenbakia ez da zuzena",
  registerPageNDOError: "Idatzitako agiria ez da zuzena",
  registerPageMailErrorExist:
    "Idatzitako helbide elektronikoa erregistratuta dago",
  registerPagePassword: "Pasahitza",
  registerPagePasswordError:
    "8-16 karaktere, 4 elementu hauetatik 3 dituena, gutxienez: letra xeheak, letra larriak, zenbakiak (0-9) eta hauetako sinbolo bat edo gehiago: @ # $ % ^ & * - _ + = [ ] { } | \\ : ' , ? / ` ~ ( ) ; . \"",
  registerPageCheckPassword: "Berrespena",
  registerPageCheckPasswordError: "Pasahitzak ez dira berdinak",
  registerPagePhone: "Telefonoa",
  registerPagePhoneErrorShort: "Telefono-zenbakia motzegia da",
  registerPagePhoneErrorLong: "Telefono-zenbakia luzeegia da",
  registerPagePhoneErrorExist:
    "Idatzitako telefono-zenbakia erregistratuta dago",
  registerPagePrefixError: "Tiene que introducir un prefijo",
  registerPageTipoPersona: "Pertsona Mota",
  registerPageAgreeConditionsPhrase: "Debes leer y aceptar los términos y condiciones: ",
  registerPageAgreeConditions: "Baldintzak",
  registerPageAgreeConditionsDot: "Baldintzak: ",
  registerPageAgreeConditionsSee: "Ver Términos y Condiciones",
  registerPageAgreeConditionsTerms1: "Irakurri ditut eta onartzen ditut ",
  registerPageAgreeConditionsTerms2: "Correos Id-ren erabilera-baldintzak ",
  registerPageAgreeConditionsTerms3: "eta ",
  registerPageAgreeConditionsTerms4: "datuak babesteko duen politika",
  registerPageAgreeConditionsAccepted: "Aceptados",
  registerPageAgreeConditionsPending: "Pendiente *",
  registerPageAgreeConditionsTermsConsent:
    "Datuak Babesteko Politikaren arabera, zure baimena eskatu nahiko genuke jarduera hauetarako:",
  registerPageOfersPromotions: "Eskaintzak eta Promozioak",
  registerPageOfersPromotionsDot: "Eskaintzak eta Promozioak: ",
  registerPageOfersPromotionsAll: "GUZTIAK ONARTZEN DITUT",
  registerPageOfersPromotionsSome: "BATZUK ONARTZEN DITUT",
  registerPageOfersPromotionsNone: "EZ DITUT ONARTZEN",
  registerPageOfersPromotionsPending: "PENDIENTE *",
  registerPagePersonalizeOptions:
    "Pertsonalizatu zure pribatutasuna aukera hauek hautatuz:",
    registerPageIdentityVerifyTitle: "Berretsi zure identitatea",
    registerPageIdentityVerifyText: "Zure identitatea bermatzeko, egiaztapen-kode bat bidaliko dizugu zure helbide elektronikora. Idatzi beheko kodea erregistratzeko prozesua osatzeko.",
  registerPageIdentityVerifyMailButton: "BIDALI MEZU ELEKTRONIKOA",
  registerPageIdentityVerifyPhoneButton: "BIDALI SMS-A",
  registerPageIdentityVerifyVerificable:
    "Bi egiaztapenetatik gutxienez bat derrigor egin behar da; bestea, ez egiteko aukera duzu, edo aprobetxatu eta egin",
  registerPageIdentityVerifyVerificableEmail:
    "Helbide elektronikoa derrigor egiaztatu behar duzu",
  registerPageIdentityVerifyVerificablePhone:
    "Telefonoa derrigor egiaztatu behar duzu",
  registerPageIdentityVerifyNoVerificableEmail:
    "Helbide elektroniko hau egiaztatzea aukerakoa da, puntu hori hutsik utzi dezakezu",
  registerPageIdentityVerifyNoVerificablePhone:
    "Telefono hau egiaztatzea aukerakoa da, puntu hori hutsik utzi dezakezu",
  registerPageIdentityVerifyCodeSended: "Bidali da kodea",
  registerPageOtp: "Idatzi Kodea",
  registerPageOtpError: "Idatzitako kodea ez da zuzena",
  registerPageRegisterError: "Arazo bat egon da erregistratzerakoan",
  registerPageOfersPromotionsSelected:
    "Onartu diren eskaintzak eta promozioak: ",
  registerPageOfersPromotionsSelectedAll: "Guztiak",
  registerPageOfersPromotionsSelectedSome: "Solo algunas",
  registerPageOfersPromotionsSelectedNone: "Bat ere ez",
  registerPageOfersPromotionsSelectedPending: "Sin seleccionar",
  registerPageSeeOfersPromotions: "Ireki eskaintzak/promozioak",
  registerPageSendOTP: "Idatzi bidali berri dizugun kodea",
  registerPageSendOTPEmail: "Kodea posta elektronikorako",
  registerPageSendOTPPhone: "Kodea telefonorako",
  registryTitleData: "Erregistroaren Datuak",
  registerPagePersonType1: "Partikularra",
  registerPagePersonType2: "Enpresa/Autonomoa",
  registerPageNoDocuments: "Enpresa-mota honetarako, ez da dokumenturik behar",
  registerPageWarnTitle: "Atención",
  registerPageWarnText: "El registro a correos ID ha ido correctamente, pero ha habido un problema en el guardado de datos, tendrás que volver a rellenarlos al acceder a la aplicación",
  registerPagePoliticsAndConditions: "Políticas y condiciones",
  registerPageRecaptcha: "Captcha",

  //ChooseUser

  chooseUserTitle:
    "Mesedez, hautatu partikular edo enpresa gisa izan nahi duzun baimendun:",
  chooseEnterpriseTitle:
    "Por favor, pulsa en 'Empresa' y escoge con cuál quieres autorizarte:",
  none: "Ninguna",
  chooseUser: "Partikularra",
  chooseEnterprise: "Enpresa",
  chooseDialogTitle: "Seleccione empresa",

  //Stepper

  createUserCid: "Crear cuenta en CorreosID",
  userIdentify: "Verifica tu identidad",
  userIdentity: "Introduce tu identidad",
  sendOTP: "Bidali kodea",
  reSendOTP: "Birbidali kodea",
  codeConfirm: "Berretsi kodea",
  subscriptionToApp: "Suscripción a {appName}",
  ownerData: "Datos del titular",
  enterpriseCreation: "Creación de empresa",
  userData: "Datos de usuario",
  stepChangePass: "Aldatu pasahitza",
  enterpriseData: "Enpresa-datuak",
  enterpriseDocuments: "Gehitu dokumentuak",
  requiredData: "Datos requeridos",
  createUserCidTextLabel: "Introduce tu email de acceso y contraseña, acepta las políticas de privacidad y crea una cuenta en CorreosID para acceder a nuestros servicios de forma segura.",
  userIdentifyTextLabel: "Este paso es esencial para verificar tu identidad y garantizar la seguridad de tus datos.",
  userIdentityTextLabel: "Ingresa los detalles de tu identidad. Estos detalles nos ayudarán a personalizar tu experiencia y a brindarte un mejor servicio.",
  sendOTPTextLabel: "Te enviaremos un código único a tu dirección de correo electrónico o número de teléfono. Utiliza este código para verificar que tienes acceso a los medios de contacto proporcionados.",
  reSendOTPTextLabel: "En caso de no haber recibido el código, tienes la opción de solicitar su reenvío. Verifica tu carpeta de correo no deseado antes de hacerlo.",
  codeConfirmTextLabel: "Ingresa el código que has recibido y confirma tu acceso. Este código garantiza que eres el titular de la cuenta y estás listo para proceder.",
  subscriptionToAppTextLabel: "Opta por suscribirte a nuestra aplicación para acceder a sus funciones exclusivas. Mantente al tanto de las últimas actualizaciones y novedades.",
  ownerDataTextLabel: "Proporciona información detallada sobre ti.",
  enterpriseCreationTextLabel: "Si estás creando una empresa, completa los pasos necesarios para registrarla oficialmente. Proporciona información sobre ella.",
  userDataTextLabel: "Proporciona información adicional sobre ti para enriquecer tu perfil.",
  stepChangePassTextLabel: "Si deseas cambiar tu contraseña actual, este es el momento para hacerlo. Proporciona una nueva contraseña segura y asegúrate de guardarla en un lugar seguro.",
  enterpriseDataTextLabel: "Si estás registrando una empresa, completa los detalles adicionales.",
  enterpriseDocumentsTextLabel: "Sube documentos importantes relacionados con tu empresa.",
  requiredDataTextLabel: "Asegúrate de proporcionar todos los datos necesarios para completar el proceso. Estos pueden variar según el tipo de cuenta que estás creando. Verifica cada sección cuidadosamente.",

  //ResetPassword

  resetPasswordTitle: "¿Has olvidado tu contraseña?",
  resetPasswordText: "No te preocupes, ¡puede pasarle a cualquiera!",
  resetPasswordFormatError: "8-16 carácteres, que contengan 3 de los 4 siguientes: minúsculas, mayúsculas, dígitos (0-9), y uno o más de los siguientes símbolos: @ # $ % ^ & * - _ + = [ ] { } | \\ : ' , ? / ` ~ ( ) ; . \"",
  resetPasswordCheckError: "Las contraseñas no son iguales",
  resetPasswordTitle2: "Cambia tu contraseña",
  resetPasswordText2: "Confirma tu identidad con el código que te hemos enviado a:",
  resetPasswordEmptyError: "La contraseña no puede estar vacía",
  resetPasswordRedirection: "En unos segundos te redirigiremos al Inicio de sesión, o puedes ir pulsando ",
  lAddUser:
    "Idatzi zure Correos-eko identitatea eta pasahitz berria sortzen lagunduko dizugu.",
  backButton: "Itzuli",
  sending: "Bidaltzen...",
  lAddOtp: "Zu zarela jakin behar dugu. Egiaztatuko al diguzu?",
  lOtp: "Egiaztatze-kodea",
  emptyError: "Eremua ezin da hutsik egon.",
  emptyErrorPass: "Eremuak ezin dira hutsik egon.",
  emptyErrorIdentifier: "Correos identifikatzaile zuzen bat gehitu behar duzu.",
  lChangePass: "Idatzi zure pasahitz berria.",
  lPass: "Pasahitza",
  lRPass: "Errepikatu zure pasahitza",
  errorEqualPass: "Pasahitzak berdinak izan behar dira",
  errorCheckPass: "Idatzitako pasahitza ez da zuzena.",
  lCFinalStep: "Pasahitza behar bezala aldatu da.",
  lEFinalStep:
    "Errorea pasahitza aldatzean. Saiatu berriro minutu batzuk barru.",
  lStart: "Hasi",
  tLinkHelper: "Laguntzarik behar?",
  hAddUser:
    "Pasahitza aldatu ahal izateko, gure plataforman alta egiteko zein identifikatzaile erabili zenuen jakin behar dugu.",
  hAddOtp:
    "CorreosID-en pasahitza zu aldatzen ari zarela egiaztatu behar dugu. Horregatik, zure kontu-identifikatzailera (telefonoa edo helbide elektronikoa) 5 digituko kodea bidaliko dugu. Kode hori zein den esan eta prozesuarekin jarraitu ahal izango dugu.",
  hAddPass:
    "Zure kode berriak 8-16 karaktere izango ditu, 4 elementu hauetatik 3 dituena, gutxienez: letra xeheak, letra larriak, zenbakiak (0-9) eta hauetako sinbolo bat edo gehiago: @ # $ % ^ & * - _ + = [ ] { } |  : ' , ? / ` ~ \" ( ) ; .gutxienez behar direnak jarraitu ahal izateko.",
  dialogClose: "Itxi",
  otpRpError: "Idatzitako kodea ez da zuzena.",
  otpInvalidError: "OTPa ez da zuzena",
  otpEmptyError: "Kodea ezin da hutsik egon.",

  //PrefixPhone

  prefixSelector: "Aurrezenbakia",

  //UserData

  userDataInfo:
    "Dagoeneko CorreosId kontu bat duzu, orain da {appName}-ra harpidetzeko unea",
  userDataInfoCid:
    "CorreosId-en behar bezala erregistratu zara, orain beharrezkoa da eskatutako datuak betetzea",
  userDataVerifyEmailOk: "Helbide elektronikoa behar bezala egiaztatu da",
  userDataVerifyEmailKo:
    "Arazo bat egon da helbide elektronikoa egiaztatzerakoan",
  userDataVerifyPhoneOk: "Telefono-zenbakia behar bezala egiaztatu da",
  userDataVerifyPhoneKo: "Arazo bat egon da telefono-zenbakia egiaztatzerakoan",
  userDataInfoQuestion: "¿Seguro que deseas cancelar la suscripción a {appName}?",
  userDataInfoQuestionCid: "¿Seguro que deseas cancelar la creación de la empresa?",
  userDataExitInfo:
    "Ya estás registrado en correosID, ten en cuenta que si sales ahora cuando hagas login en {appName} tendrás que terminar de rellenar los datos para suscribirte.",
  userDataExitInfoCid:
    "Ya estás registrado en correosID, ten en cuenta que si sales ahora tu usuario no tendrá empresa",
  userDataCopyDirection: "Kopiatu helbidea",
  userDataSuccessUpdate: "Erabiltzailearen datuak behar bezala eguneratu dira",
  userDataAddEmail: "Gehitu helbide elektronikoa",
  userDataModifyEmail: "Verificar email",
  userDataDeleteEmail: "Eliminar email",
  userDataDeleteEmailSuccess: "Email eliminado",
  userDataAddPhone: "Gehitu telefono-zenbakia",
  userDataModifyPhone: "Verificar teléfono",
  userDataDeletePhone: "Eliminar teléfono",
  userDataDeletePhoneSuccess: "Teléfono eliminado",
  userDataAddDoc: "Añadir documento",
  userDataModifyDoc: "Modificar documento identificativo",
  userDataDeleteDoc: "Eliminar documento identificativo",
  userDataAddDocSuccess: "Documento identificativo añadido",
  userDataModifyDocSuccess: "Documento identificativo modificado",
  userDataDeleteDocSuccess: "Documento identificativo eliminado",
  userDataAddAddress: "Añadir dirección",
  userDataModifyAddress: "Modificar Dirección",
  userDataDeleteAddress: "Eliminar Dirección",
  userDataAddAddressSuccess: "Dirección añadida",
  userDataModifyAddressSuccess: "Dirección modificada",
  userDataDeleteAddressSuccess: "Dirección eliminada",
  userDataSaveWarning:
    "Gogoratu datuak gorde behar dituzula aldaketa eraginkorra izan dadin",
  userDataAliasWarning: "Ya tienes una dirección con ese nombre",
  userDataAddDirection: "Helbide bat gehitu nahi duzu datu hauekin?",
  userDataAddDirectionMandatorie:
    "Helbide bat gehitu ahal izateko, derrigorrezko eremu guztiak bete behar dira",
  userDataRequiredDirection:
    "Izenordea eta Helbidea derrigorrezko eremuak dira",
  userDataAddDirectionSuccess:
    "Helbidea behar bezala gehitu da; ez ahaztu aldaketak gorde behar dituzula",
  userDataChangeDirectionSuccess: "Dirección modificada con éxito",
  userDataFavouriteDirection: "Dirección favorita",
  userDataFavouriteDirectionWarning: "Ya tienes una dirección favorita y sólo puedes tener una, si seleccionas esta la otra dejará de serlo",
  userDataWarningMessage:
    "Helbide berri bat gordetzeko, gogoratu zerrendara gehitu behar duzula. Oraindik egin ez baduzu, sakatu Ezeztatu eta gehitu helbide berria jarraitu baino lehen",
  userDataRegister: "Registro completado",
  userDataIncomplete:
    "Derrigorrezko datuak falta dira. Mesedez, bete falta diren derrigorrezko datuak.",
  userDataFNAError: "Jaiotze-data ezin da izan gaurko eguna baino geroagokoa.",
  userDataSaveProfile: "Guardar perfil",
  enterpriseDataSaveProfile: "Guardar empresa",
  userDataAddressUpdateWarning: "Helbide hau eguneratzea beharrezkoa da bere datuak zuzenak ez direlako",
  userDataPageSubtitle: "Edita tus datos personales y gestiona las direcciones",
  userFormatIncorrect: "Formato no soportado. Solo se permiten letras",

  //EnterpriseData

  enterpriseDataInfo:
    "Behar bezala erregistratu da. Orain, enpresaren datuak betetzen amaitu behar da",
  enterpriseDataInfoQuestion:
    "Ziur al zaude datuak txertatzeari utzi nahi diozula?",
  enterpriseDataExitInfo:
    "Enpresa erregistratu egin da. Datuak adierazi gabe joaten bazara, berriz eskatuko zaizkizu sartzen zaren hurrengo aldian",
  enterpriseDataCopyDirection: "Kopiatu helbidea",
  enterpriseDataSuccessUpdate:
    "Erabiltzailearen datuak behar bezala eguneratu dira",
  enterpriseDataAddressWarning:
    "La dirección se guardará junto con la creación de la empresa",
  enterpriseDataCreated: "Enpresa behar bezala sortu da",
  enterpriseDataSaveData: "Guardar datos",
  enterpriseDataPageSubtitle: "Modifica los datos de tu empresa así como la dirección fiscal",
  enterpriseDataCreatePageSubtitle: "Añade los datos de tu empresa así como la dirección fiscal",
  enterpriseDataSave: "Guardar empresa",
  enterpriseDataCreate: "Crear empresa",

  //DialogAddChange
  dialogAddChangeAddTitle: "Gehitu helbidea",
  dialogAddChangeChangeTitle: "Aldatu helbidea",
  dialogAddChangeAddText: "Gehitu helbidearen datuak",
  dialogAddChangeChangeText: "Aldatu helbidearen datuak",

  //Main

  userNameUnregistered: "Erabiltzailea izenik gabe",
  enterpriseNameUnregistered: "Enpresa izenik gabe",
  mainPageTitle: "Ongi etorri CorreosID-ra",
  mainPageUserProfile: "Erabiltzailearen profila",
  mainPagePrivacity: "Pribatutasuna",
  mainPageVerifications: "Egiaztapenak",
  mainPageCreateEnterprise: "Sortu enpresa",
  mainPageAvailableServices: "Harpidetutako zerbitzuak",

  //SideBar

  UserData: "Datuak",
  UserVerifications: "Egiaztapenak",
  UserPrivacy: "Pribatutasuna",
  EnterpriseCreate: "Sortu",
  EnterpriseTeam: "Taldea",
  EnterpriseDelete: "Eskatu Baja",
  UserServices: "Partikularra",
  EnterpriseData: "Datuak",
  EnterpriseVerifications: "Egiaztapenak",
  EnterprisePrivacy: "Pribatutasuna",
  EnterpriseServices: "Enpresa",
  User: "Erabiltzailea",
  Enterprise: "Enpresa",
  Services: "Zerbitzuak",
  Service: "Servicio",
  profileComp: "Profilaren aurrerabidea",

  //Service Page

  subscribeService: "Harpidetu",
  unsubscribeService: "Baja",
  revokeData: "Kendu Sarbidea Nire Datuetara",
  acceptData: "Onartu Sarbidea Nire Datuetara",
  goToService: "Sartu",
  subscribedService: "Harpidetua",
  unSubscribedService: "Ez Harpidetua",
  askUnsubscribeService: "Eskatu Baja",
  serviceSubscribed: "Behar bezala harpidetu da",
  serviceUnsubscribed: "Harpidetza behar bezala indargabetu da",
  revokeEnterpriseData: "Kendu baimenak",
  acceptEnterpriseData: "Onartu baimenak",
  servicePageDualWarning: "¿Seguro que quieres darte de baja de {applicationName}?",
  servicePageCancelAccount: "Usuario",
  servicePageCancelEnterprise: "Empresa",
  servicePageUnsubscribe: "Servicio",
  singleSubscriptionDialogRedirectTextUser: "Estás a punto de darte de baja de {applicationName}, que es tu único servicio. Si deseas continuar con esta acción, pulsa en 'Servicio'. Por otro lado, si lo que quieres es dar de baja tu usuario de Correos ID, pulsa en 'Usuario'.",
  singleSubscriptionDialogRedirectTextEnterprise: "Estás a punto de dar de baja la empresa de {applicationName}, que es su único servicio. Si deseas continuar con esta acción, pulsa en 'Servicio'. Por otro lado, si lo que quieres es dar de baja la empresa, pulsa en 'Empresa'.",
  servicePageLinkText: "estekan",
  subscribeMeText: "Harpidetu nazazu",
  subscriptionOkTitle: "Bukatu!",
  subscriptionOkText: "Ondo harpidetu zara",
  servicePageSubtitle: "Zure Correos IDarekin Correoseko zerbitzu digitaletara harpidetu zaitezke",
  servicePageAppGoTo: "Suscríbete desde el servicio",
  servicePageAppAccess: "Accede desde el servicio",
  servicePageConfirmCancelAccount: "¿Seguro que quieres cancelar tu cuenta?",
  servicePageConfirmCancelEnterprise: "¿Seguro que quieres eliminar la empresa?",

  //Verifitacions Page

  verificationsVerify: "Egiaztatu",
  verificated: "Egiaztatua",
  notVerificated: "Ez Egiaztatua",
  pendingVerificated: "Zain",
  UserEmail: "Helbide elektronikoa",
  PersonaTelfMovil: "Telefonoa",
  PersonaNumDocID: "Dokumentu zk.",
  PersonaDireccion: "Helbidea",
  verificationsSendCode: "Egiaztatze-kode bat bidaliko dugu hona: ",
  verificationsSendCodeEmail: "Verifica tu email",
  verificationsSendCodePhone: "Verifica tu teléfono",
  verificationsAddVerifEmail: "Añade y verifica un email",
  verificationsAddVerifPhone: "Añade y verifica un teléfono",
  verificationsConfirmCode: "Idatzi egiaztatze-kodea",
  verificationsVerificationType: "Egiaztapen-mota",
  verificationsConfirmPostCard:
    "Kodea posta arrunt bidez bidaliko dizugu, ziur al zaude egiaztapen-mota hau erabili nahi duzula?",
  verificationsCreate: "Idatzi datuak gehitzeko ",
  verificationsAlias: "Ezizena",
  verificationsCountry: "Herrialdea edo eskualdea",
  verificationsDirection: "Helbidea",
  verificationsPostalCode: "Posta-kodea",
  verificationsRegion: "Probintzia/Eskualdea",
  verificationsTown: "Herria",
  verificatinosDeleteElement: "Ezabatu elementua?",
  verificationsVerifiedData: "Egiaztatutako datuak",
  verificationsEMA: "Egiaztatutako helbide elektronikoak",
  verificationsMOV: "Egiaztatutako telefono-zenbakiak",
  verificationsNDO: "Egiaztatutako dokumentuak",
  verificationsALI: "Egiaztatutako helbideak",
  verificationsEmpty: "Batere ez",
  verificationsUpgrade: "Aumentar nivel de verificación",
  verificationsAddEmail: "Helbide elektronikoa behar bezala gehitu da",
  verificationsVerifyEmail: "Helbide elektronikoa behar bezala egiaztatu da",
  verificationsDeleteEmail: "Helbide elektronikoa behar bezala ezabatu da",
  verificationsAddPhone: "Telefono-zenbakia behar bezala gehitu da",
  verificationsVerifyPhone: "Telefono-zenbakia behar bezala egiaztatu da",
  verificationsDeletePhone: "Telefono-zenbakia behar bezala ezabatu da",
  verificationsAddAddress: "Dirección añadida correctamente",
  verificationsVerifyAddress: "Dirección verificada correctamente",
  verificationsDeleteAddress: "Dirección eliminada correctamente",
  verificationsDeleteDocument: "Documento eliminado correctamente",
  verificationsDeleteAddressTooltip: "Eliminar dirección",
  verificationsDocuments: "Dokumentazioa",
  verificationsemail: "Helbide elektronikoa",
  verificationsphone: "Telefonoa",
  verificationsaddress: "Helbidea",
  verificationsnumber: "Identifikazio-dokumentua",
  verificationsByOtp: "Egiaztatua kode bidez",
  verificationsByForm: "Egiaztatua formatu bidez",
  verificationsReSendOtp: "Kodea birbidali da",
  verificationDisableAccountTitle:
    "Correos ID-n baja eman ondoren, ezin izango dituzu ondoko zerbitzu hauek guztiak erabili:",
  verificationDisableAccountList: "Zure kontua ezabatuko dugu*.",
  verificationDisableAccountP1:
    "Zure Correos ID kontua ezabatu baino lehen, erregistratuta zauden zerbitzuetan baja eskatu eta prozesua amaitzea gomendatzen dizugu. Horretarako, hautatu Ezeztatu aukera. Hala ere, zure Correos ID kontua orain ezabatzea nahiago baduzu, egin klik Eman Baja aukeran.",
  verificationDisableAccountP2:
    "*Lasai, {disableAccDays} egun dituzu, damutuz gero, zure Correos ID kontua berreskuratzeko. Zure Correos ID kontua berreskuratu ahal izango duzu, baina baliteke beste zerbitzuek ez gordetzea zure datuak {disableAccDays} egun horien bitartean.",
  verificationDisableAccountP3:
    "Correos ID-k zure kontua ezabatu dela jakinaraziko die lotutako zerbitzuei, baina zerbitzu bakoitzean duzun kontua ezabatzea ez da Correos ID-ren ardura",
  verificationDisableAccountConfirm: "Eman baja",
  verificationsWrongTipeDocument: "Dokumentu-mota ez da zuzena",
  verificationsUploadSuccess: "Dokumentua behar bezala igo da",
  verificationsDeleteSuccess: "Dokumentua behar bezala ezabatu da",
  verificationsNoUploaded: "Ez da igo",
  verificationsEmailExists: "Idatzitako helbide elektronikoa existitzen da",
  verificationsPhoneExists: "Idatzitako telefono-zenbakia existitzen da",
  verificationsUnifyUserTitle: "Atención",
  verificationsUnifyUserText: "Hemos detectado que el {dataType} que estás intentando verificar ya existe en otra cuenta. ¿Es este {dataType} ({data}) realmente tuyo?. Si es así pulsa en 'Aceptar' para unificar ambas cuentas",
  verificationsPageSubtitle: "Puedes gestionar y verificar tus datos, así como cambiar tu contraseña de acceso",
  verificationsEnterprisePageSubtitle: "Puedes gestionar y verificar los datos de la empresa",
};
