import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { checkIsNullUndefined } from "../../commons/Utils";
import { useTranslation } from "react-i18next";
import { compareDates, getDate } from "../../commons/Utils";
import { Constants } from "../../commons/Constants";
import { CommonStyles } from "../../commons/CommonStyles";

export function DatePickerAttribute(props) {
  const { t, i18n } = useTranslation();
  const { attribute, saveGroupsChanges } = props;
  const commonClasses = CommonStyles();

  const [value, setValue] = useState("");
  const [current, setCurrent] = useState(getDate(Date().toLocaleString()));
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    let fecha = checkIsNullUndefined(attribute.value)
      ? "yyyy-MM-dd"
      : getDate(attribute.value);
    handleDates(fecha);
  }, [attribute, value]);

  const isDateOk = (fec) =>{
    return attribute.codeAttribute === "FNA"
    ? compareDates(fec, Constants.minDate, current)
    : compareDates(fec, Constants.minDate, Constants.maxDate);
  }

  const handleInputChange = (event) => {
    handleDates(event.target.value)
    saveGroupsChanges(attribute, event.target.value);
  };

  const handleDates = (value) =>{
    let errorDate = false;
    let errorDateText = "";
    errorDate = isDateOk(value);

    if (errorDate) {
      errorDateText = t("userDataDateError");
    } else {
      errorDateText = "";
    }

    setValue(value);
    setCurrent(getDate(Date().toLocaleString()));
    setError(errorDate);
    setErrorText(errorDateText);
  }

  return (
    <Grid item md={12} xs={12}>
      {attribute.codeAttribute === "FNA" ? (
        <TextField
          id="date"
          variant="filled"
          color="secondary"
          label={attribute.nameAttritube}
          disabled={attribute.disabled}
          required={attribute.mandatory}
          type="date"
          value={value}
          error={error}
          helperText={errorText}
          fullWidth
          inputProps={{ max: current, min: Constants.minDate }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleInputChange}
          inputFormat="DD/MM/YYYY"
          className={commonClasses.inputsStyle}
        />
      ) : (
        <TextField
          id="date"
          variant="filled"
          color="secondary"
          label={attribute.nameAttritube}
          disabled={attribute.disabled}
          required={attribute.mandatory}
          type="date"
          value={value}
          error={error}
          helperText={errorText}
          fullWidth
          inputProps={{ min: Constants.minDate, max: Constants.maxDate }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleInputChange}
          inputFormat="DD/MM/YYYY"
          className={commonClasses.inputsStyle}
        />
      )}
    </Grid>
  );
}
