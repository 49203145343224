import { makeStyles } from "@material-ui/core/styles";
import { Constants } from "../../commons/Constants"

export const RedirectPageStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10.5em",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "10.5em",
    },
  },
  cardContainer:{
    padding:"2em"
  },


  hideContainer: {
    marginLeft: Constants.DrawerWidth,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  links: {
    color: "#002453",
    "&:hover": {
      textDecoration: "underline",
      color: "#002453",
    },
  },
  table: {
    border: "1px solid black",
    borderCollapse: "collapse",
  },
  text: {
    color: "#666666",
  },
  subtitles: {
    color: "#002453",
  },
  tableHead:{
    backgroundColor:"#666666",
  },
  tableHeadText:{
    color:"#FFFFFF"
  },
}));
