import React, { useEffect, useState, useRef } from "react";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import {
  Grid,
  Dialog,
  Container,
  IconButton,
  Tooltip,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Popper,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { CommonStyles } from "../../../commons/CommonStyles";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import { AiOutlineEdit } from "react-icons/ai";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import {
  checkIsNullUndefined,
  useOutsideAlerter,
} from "../../../commons/Utils";
import { DialogFavouriteWarning } from "./DialogFavouriteWarning";
import { RequiredDataStyles } from "../RequiredDataStyles";
import { EnumPersonType } from "../../../commons/EnumsGeneral";
import { Attributes } from "../../../commons/Attributes";

export function DialogAddressActions(props) {
  const {
    isCard,
    objectAddressActions,
    address,
    circularProgress
  } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const classes = RequiredDataStyles();
  const { t, i18n } = useTranslation();
  const wrapperRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [checkedFavourite, setCheckedFavourite] = useState(false);
  const [openWarnPopup, setOpenWarnPopup] = useState(false);
  const [operationType, setOperationType] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (!checkIsNullUndefined(operationType) && open) {
      if (operationType == "add") {
        objectAddressActions.prepareBasicAddressForm(objectAddressActions.isUser);
        setCheckedFavourite(false);
      } else {
        objectAddressActions.prepareEditableAddressForm(objectAddressActions.isUser, address.id);
      };
      objectAddressActions.setAddressOperationType(operationType);
    };
  }, [operationType, open]);

  const handleOpen = (event, operationType) => {
    if (operationType === "delete") {
      setAnchorEl(event.currentTarget);
    };
    setCheckedFavourite(address?.favourite);
    setOperationType(operationType);
    objectAddressActions.setActiveAddress(address?.id);
    setOpen(true);
  };

  const handleAction = () => {
    if (checkedFavourite && (!objectAddressActions.isFavourite || operationType == "add") && objectAddressActions.hasFavourite && operationType != "delete") {
      setOpenWarnPopup(true);
    } else {
      if (operationType == "add") {
        confirmAdd();
      } else if (operationType == "change") {
        confirmEdit();
      } else if (operationType == "delete") {
        confirmDelete();
      };
    };
  };
  const confirmAdd = () => {
    objectAddressActions.handleAddAddress(checkedFavourite, objectAddressActions.isUser ? EnumPersonType.User : EnumPersonType.Enterprise);
    handleCancel();
  };

  const confirmEdit = () => {
    objectAddressActions.handleEditAddress(checkedFavourite, objectAddressActions.isUser ? EnumPersonType.User : EnumPersonType.Enterprise);
    handleCancel();
  };

  const confirmDelete = () => {
    objectAddressActions.handleRemoveAddress(objectAddressActions.isUser ? EnumPersonType.User : EnumPersonType.Enterprise);
    handleCancel();
  };

  const handleCancel = () => {
    objectAddressActions.handleIsControlledCountry(objectAddressActions.isUser ? EnumPersonType.User : EnumPersonType.Enterprise);
    setAnchorEl(null);
    setOperationType("");
    setCheckedFavourite(false);
    setOpenWarnPopup(false);
    setOpen(false);
  };

  const handleFavourite = () => {
    let auxIsFavourite = !checkedFavourite;
    setCheckedFavourite(auxIsFavourite);
  };

  useOutsideAlerter(wrapperRef, handleCancel);

  return (
    <Grid
      container
      item
      xs={12}
      spacing={1}
      className={classes.actionsComponentContainer}
    >
      <Grid
        container
        item
        xs={12}
        spacing={1}
        className={classes.iconsContainer}
      >
        {isCard ?
          <Grid
            container
            item
            xs={12}
            spacing={1}
          >
            <Grid item xs={6} className={classes.iconContainer}>
              <Tooltip title={t("update")}>
                <IconButton
                  classes={{ root: classes.iconButton }}
                  onClick={(event) => handleOpen(event, "change")}
                >
                  <AiOutlineEdit
                    className={commonClasses.iconActionStyle}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6} className={classes.iconContainer}>
              <Tooltip title={t("delete")}>
                <IconButton
                  classes={{ root: classes.iconButton }}
                  onClick={(event) => handleOpen(event, "delete")}
                >
                  <DeleteIcon
                    className={commonClasses.iconActionStyle}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          :
          <Grid item xs={12} style={{ textAlign: "left" }}>
            <Tooltip title={t("add")}>
              <span>
                <CorreosButton
                  color="primary"
                  variant="contained"
                  className={commonClasses.dialogButtonLeft}
                  onClick={(event) => handleOpen(event, "add")}
                  circularProgress={objectAddressActions.actionCircularProgress}
                  disabled={objectAddressActions.isNotTitular}
                >
                  <AddToPhotosIcon style={{ marginRight: "0.2em" }} />
                  {t("address")}
                  {objectAddressActions.needsAddress ? " *" : ""}
                </CorreosButton>
              </span>
            </Tooltip>
          </Grid>
        }
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open && operationType !== "delete"}
        onClose={() => handleCancel()}
        PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
      >
        <Grid>
          <Container className={commonClasses.dialogTitle}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  className={commonClasses.dialogTitleText}
                >
                  {operationType == "add"
                    ? t("dialogAddChangeAddTitle")
                    : t("dialogAddChangeChangeTitle")}
                </Typography>
              </Grid>
            </Grid>
          </Container>
          {circularProgress ?
            <Grid item xs={12} className={commonClasses.gridIdentityCenter}>
              <CircularProgress style={{ margin: "auto", marginTop: "1em", marginBottom: "1em" }}></CircularProgress>
            </Grid> : (
              <Container className={commonClasses.dialogContainer}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography>
                      {operationType == "add"
                        ? t("dialogAddChangeAddText")
                        : t("dialogAddChangeChangeText")}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    spacing={1}
                    xs={12}
                    style={{ width: "100%" }}
                  >
                    {objectAddressActions.addressEditableForm.map((attr) => {
                      return objectAddressActions.selectGenerator(attr, true);
                    })}
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkedFavourite}
                              onChange={handleFavourite}
                              name="check"
                            />
                          }
                          label={t("userDataFavouriteDirection")}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    className={commonClasses.dialogButtonContainer}
                  >
                    <Grid item xs={6}>
                      <CorreosButton
                        color="secondary"
                        className={commonClasses.dialogButtonLeft}
                        onClick={handleCancel}
                      >
                        {t("cancel")}
                      </CorreosButton>
                    </Grid>
                    <Grid item xs={6}>
                      <CorreosButton
                        variant="contained"
                        color="primary"
                        className={commonClasses.dialogButtonRight}
                        disabled={!objectAddressActions.editableAddressOk}
                        onClick={handleAction}
                        circularProgress={objectAddressActions.actionCircularProgress}
                      >
                        {t("save")}
                      </CorreosButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            )}
        </Grid>
      </Dialog>
      <DialogFavouriteWarning
        openPopUp={openWarnPopup}
        setOpenPopup={setOpenWarnPopup}
        doAction={operationType == "add" ? confirmAdd : confirmEdit}
      />
      <Popper
        open={open && operationType === "delete"}
        anchorEl={anchorEl}
        placement={"top"}
        style={{ zIndex: 100 }}
        ref={wrapperRef}
      >
        <Paper>
          <Container>
            <Grid container style={{ paddingTop: "1em", paddingBottom: "1em" }}>
              <Grid item xs={12}>
                <Typography className={commonClasses.popperContentText}>
                  {t("userDataDeleteAddress")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  size="small"
                  onClick={handleCancel}
                  className={commonClasses.leftButton}
                >
                  {t("no")}
                </CorreosButton>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleAction}
                  className={commonClasses.rightButton}
                  circularProgress={objectAddressActions.actionCircularProgress}
                >
                  {t("yes")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Popper>
    </Grid>
  );
}
