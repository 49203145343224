import React, { useEffect, useState } from "react";
import { Grid, Container, Typography } from "@material-ui/core";
import { CommonStyles } from "../../commons/CommonStyles";
import {
  GetSubscriptionUser,
} from "../../services/ReasonService";
import {
  GetUtilitiesCorreosIdUserServices,
} from "../../services/UtilitiesCallsService";
import { CircularProgress } from "@material-ui/core";
import { ServiceList } from "..//ServiceList/ServiceList";
import { checkIsNullUndefined, getErrorMessage } from "../../commons/Utils";
import { Alerts } from "../Alerts/Alerts";
import { useTranslation } from "react-i18next";
import { DialogSubscribeOk } from "../CommonComponents/Dialogs/DialogSubscribeOk";

export function UserServices() {
  const commonClasses = CommonStyles();
  const { t, i18n } = useTranslation();

  const [circularProgress, setCircularProgress] = useState(true);
  const [serviceList, setServiceList] = useState([]);
  const [userServices, setUserServices] = useState([]);
  const [openSubOk, setOpenSubOk] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });

  useEffect(() => {
    getServices();
  }, []);

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  const getServices = () => {
    setCircularProgress(true);
    GetUtilitiesCorreosIdUserServices().then((response) => {
      if (response && !response.Status) {
        setServiceList(response);
        getSubscriptions();
      } else {
        //Alerta
        finishOperation("error", getErrorMessage(response, t));
        setCircularProgress(false);
      }
    });
  };

  const getSubscriptions = (subscription) => {
    setCircularProgress(true);
    GetSubscriptionUser().then((response) => {
      if (response && !response.Status) {
        setUserServices(response);
        if(!checkIsNullUndefined(subscription)){
          if(subscription){
            setOpenSubOk(true);
          }else{
            finishOperation("success", t("serviceUnsubscribed"));
          };
        };
        setCircularProgress(false);
      } else {
        //Alerta
        finishOperation("error", getErrorMessage(response, t));
        setCircularProgress(false);
      }
    });
  };

  const resetPageData = (subscribed) => {
    getSubscriptions(subscribed);
  };

  return (
    <Grid>
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      <Container
        maxWidth="lg"
        className={commonClasses.mainContainer}
      >
        <Typography variant="h4" className={commonClasses.title}>
          {t("Services")}
        </Typography>
        <Typography
          variant="subtitle2"
          className={commonClasses.title}
          style={{ textAlign: "center", marginBottom: "2em", color: "#666666", fontSize: "120%" }}
        >
          {t("servicePageSubtitle")}
        </Typography>
        <DialogSubscribeOk
          open={openSubOk}
          setOpen={setOpenSubOk}
        />
        {circularProgress ? (
          <CircularProgress />
        ) : (
          <ServiceList
            serviceList={serviceList}
            subscribedServices={userServices}
            finishOperation={finishOperation}
            resetPageData={resetPageData}
          />
        )}
      </Container>
    </Grid>
  );
}
