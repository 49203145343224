import React, { useEffect, useState } from "react";
import {
  Grid,
  Dialog,
  Typography,
  Container,
} from "@material-ui/core";
import { CorreosButton } from "../../../components/CorreosButton/CorreosButton";
import { AgreeConditionsLabel } from "../AgreeConditionsLabel";
import { CommonStyles } from "../../../commons/CommonStyles";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import {
  PutLegalTyCs,
  PostLegalUserPendingTycCid,
  PostLegalEnterprisePendingTyc,
} from "../../../services/LegalCallsService";
import { checkIsNullUndefined, getErrorMessage } from "../../../commons/Utils";
import {
  backToLogin,
  checkUserPath,
  checkEnterprisePath,
} from "../../../services/Commons";
import { useTranslation } from "react-i18next";

export function DialogPrivacy(props) {
  const { open, setOpen, enterpriseId, finishOperation, isLogin, personTypeSelected, setEnterprisePath, setOpenACM } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const { t, i18n } = useTranslation();

  const [agreeConditions, setAgreeConditions] = useState(false);
  const [reset, setReset] = useState(false);
  const [personType, setPersonType] = useState(1);
  const [tycsObject, setTycsObjects] = useState({});

  useEffect(() => {
    if (!checkIsNullUndefined(personTypeSelected)) {
      setPersonType(personTypeSelected);
    } else {
      setPersonType(checkIsNullUndefined(enterpriseId) ? 1 : 2);
    };
  }, [enterpriseId, personTypeSelected]);

  const handleCancel = () => {
    setOpen(false);
    setReset(true);
    backToLogin();
  };

  const handleConfirm = () => {
    if (isLogin && checkIsNullUndefined(enterpriseId)) {
      manageSignUserTycs();
    } else if (personType == 2 || !checkIsNullUndefined(enterpriseId)) {
      manageSignEnterpriseTycs();
    } else {
      manageSignGeneralTycs();
    };
  };

  const manageSignUserTycs = () => {
    PostLegalUserPendingTycCid(tycsObject).then((response) => {
      if (response && !response.Status) {
        manageUserPath();
      } else {
        finishOperation("error", getErrorMessage(response, t));
      };
      setOpen(false);
    });
  };

  const manageSignEnterpriseTycs = () => {
    PostLegalEnterprisePendingTyc(tycsObject).then((response) => {
      if (response && !response.Status) {
        removeSync("pendingEnterprisePrivacy").then(() => {
          checkEnterprisePath(enterpriseId, finishOperation, t);
        });
      } else {
        finishOperation("error", getErrorMessage(response, t));
      };
      setOpen(false);
    });
  };

  const manageUserPath = () => {
    if (personType == 1) {
      removeSync("pendingUserPrivacy").then(() => {
        checkUserPath(false, finishOperation, t, setOpenACM);
      });
    } else {
      setEnterprisePath(true);
    };
  };

  const manageSignGeneralTycs = () => {
    PutLegalTyCs().then((response) => {
      if ((response && !response.Status) || response.Status == 202) {
        removeSync("pendingUserPrivacy").then(() => {
          checkUserPath(false, finishOperation, t, setOpenACM);
        });
      } else {
        finishOperation("error", getErrorMessage(response, t));
      };
      setOpen(false);
    });
  };

  const removeSync = async (value) => {
    return new Promise((resolve) => {
      sessionStorage.removeItem(value);
      resolve();
    });
  };

  return (
    <Grid>
      <Dialog
        fullWidth
        PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
        onClose={() => { }}
        open={open}
      >
        <Container className={commonClasses.dialogTitle}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={commonClasses.dialogTitleText}
              >
                {t("loginPrivacyDialogTitle")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={commonClasses.dialogContainer}>
          <Grid container item xs={12} className={commonClasses.gridIdentity}>
            <Grid item xs={12} style={{ marginBottom: "1em" }}>
              <Typography className={commonClasses.dialogContentText}>
                {t("loginPrivacyDialogText")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <AgreeConditionsLabel
              setOk={setAgreeConditions}
              finishOperation={finishOperation}
              reset={reset}
              personType={personType}
              setTycsObjects={setTycsObjects}
              isLogin={isLogin}
              personTypeSelected={personTypeSelected}
              enterpriseId={enterpriseId}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            className={commonClasses.dialogButtonContainer}
          >
            <Grid item xs={6}>
              <CorreosButton
                color="secondary"
                className={commonClasses.dialogButtonLeft}
                onClick={handleCancel}
              >
                {t("cancel")}
              </CorreosButton>
            </Grid>
            <Grid item xs={6}>
              <CorreosButton
                variant="contained"
                color="primary"
                className={commonClasses.dialogButtonRight}
                onClick={handleConfirm}
                disabled={!agreeConditions}
              >
                {t("accept")}
              </CorreosButton>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </Grid>
  );
}
