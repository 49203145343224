import { fetchMethod } from "../commons/EnumsGeneral";
import { checkIsNullUndefined } from "../commons/Utils";
import { CommonFetch, buildHeader } from "./ReasonService";

const fetchApi = {
    GetEnterpriseData: "EnterpriseData",
    PutEnterpriseData: "EnterpriseData",
    GetEnterpriseDataEnterpriseUsers: "EnterpriseData/EnterpriseUsers",
    GetEnterpriseDataRole: "EnterpriseData/Role",
    GetEnterpriseDataEnterpriseItems: "EnterpriseData/EnterpriseItems/{idEnterprise}",
    PostEnterpriseDataEnterpriseItems: "EnterpriseData/EnterpriseItems/{idEnterprise}",
    PutEnterpriseDataEnterpriseItems: "EnterpriseData/EnterpriseItems/{idEnterprise}",
    DeleteEnterpriseDataEnterpriseItems: "EnterpriseData/EnterpriseItems/{id}/{idEnterprise}",
    PostEnterpriseDataChangeEnterpriseUserRoleAdminToAuthorized: "EnterpriseData/ChangeEnterpriseUserRoleAdminToAuthorized/{idEnterprise}",
    PostEnterpriseDataSendChangeEnterpriseHolderProposal: "EnterpriseData/SendChangeEnterpriseHolderProposal/{idEnterprise}",
    PostEnterpriseDataAcceptChangeEnterpriseRoleProposal: "EnterpriseData/AcceptChangeEnterpriseRoleProposal/{idProposal}",
    PostEnterpriseDataSendChangeEnterpriseUserRoleAuthorizedToAdminProposal: "EnterpriseData/SendChangeEnterpriseUserRoleAuthorizedToAdminProposal/{idEnterprise}",
    PostEnterpriseDataCancelOrRejectChangeRoleProposal: "EnterpriseData/CancelOrRejectChangeRoleProposal/{idProposal}",
    GetEnterpriseDataEnterprisePendingInvitations: "EnterpriseData/EnterprisePendingInvitations/{idEnterprise}",
    DeteleEnterpriseDataRemoveUserFromEnterprise: "EnterpriseData/RemoveUserFromEnterprise/{userOid}",
    PostEnterpriseDataCreateOrUpdateAddress: "EnterpriseData/CreateOrUpdateAddress",
};

export const GetEnterpriseData = async (idEnterprise) => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.GetEnterpriseData + "?idEnterprise=" + idEnterprise;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const PutEnterpriseData = async (data, idEnterprise, codeConfiguration, applicationOid) => {
    let body = data;
    let header = buildHeader("application/json", true, "application/json", applicationOid);
    let apiUrl = fetchApi.PutEnterpriseData + "?idEnterprise=" + idEnterprise;
    if (!checkIsNullUndefined(codeConfiguration)) {
        apiUrl = apiUrl + "&codeConfiguration=" + codeConfiguration;
    };
    let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl);
    return response;
};

export const GetEnterpriseDataEnterpriseUsers = async (idEnterprise) => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.GetEnterpriseDataEnterpriseUsers + "?idEnterprise=" + idEnterprise;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const GetEnterpriseDataRole = async (idEnterprise) => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.GetEnterpriseDataRole + "?idEnterprise=" + idEnterprise;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const GetEnterpriseDataEnterpriseItems = async (idEnterprise) => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.GetEnterpriseDataEnterpriseItems.replace("{idEnterprise}", idEnterprise);
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return await response;
};

export const PostEnterpriseDataEnterpriseItems = async (
    idEnterprise,
    idEnterpriseItem,
    value
) => {
    let body = { idEnterpriseItem: idEnterpriseItem, value: value };
    let header = buildHeader("*/*", true, "application/json");
    let apiUrl = fetchApi.PostEnterpriseDataEnterpriseItems.replace("{idEnterprise}", idEnterprise);
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return await response;
};

export const PutEnterpriseDataEnterpriseItems = async (
    idEnterprise,
    id,
    value
) => {
    let body = { id: id, value: value };
    let header = buildHeader("*/*", true, "application/json");
    let apiUrl = fetchApi.PutEnterpriseDataEnterpriseItems.replace("{idEnterprise}", idEnterprise);
    let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl);
    return await response;
};

export const DeleteEnterpriseDataEnterpriseItems = async (idEnterprise, id) => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.DeleteEnterpriseDataEnterpriseItems.replace("{idEnterprise}", idEnterprise).replace("{id}", id);
    let response = await CommonFetch(header, body, fetchMethod.DELETE, apiUrl);
    return await response;
};

export const PostEnterpriseDataChangeEnterpriseUserRoleAdminToAuthorized = async (
    idEnterprise,
    userOid
) => {
    let body = {
        userOid: userOid,
    };
    let header = buildHeader("*/*", true, "application/json");
    let apiUrl = fetchApi.PostEnterpriseDataChangeEnterpriseUserRoleAdminToAuthorized.replace("{userOid}", userOid).replace("{idEnterprise}", idEnterprise);
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return await response;
};

export const PostEnterpriseDataSendChangeEnterpriseHolderProposal = async (
    idEnterprise,
    invitationUserOid
) => {
    let body = {
        invitationUserOid: invitationUserOid,
    };
    let header = buildHeader("*/*", true, "application/json");
    let apiUrl = fetchApi.PostEnterpriseDataSendChangeEnterpriseHolderProposal.replace("{idEnterprise}", idEnterprise);
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return await response;
};

export const PostEnterpriseDataAcceptChangeEnterpriseRoleProposal = async (
    idProposal, applicationOid
) => {
    let body = "";
    let header = buildHeader("*/*", true, false, applicationOid);
    let apiUrl = fetchApi.PostEnterpriseDataAcceptChangeEnterpriseRoleProposal.replace("{idProposal}", idProposal);
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return await response;
};

export const PostEnterpriseDataSendChangeEnterpriseUserRoleAuthorizedToAdminProposal =
    async (idEnterprise, invitationUserOid) => {
        let body = {
            invitationUserOid: invitationUserOid,
        };
        let header = buildHeader("*/*", true, "application/json");
        let apiUrl = fetchApi.PostEnterpriseDataSendChangeEnterpriseUserRoleAuthorizedToAdminProposal.replace("{idEnterprise}", idEnterprise);
        let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
        return await response;
    };

export const PostEnterpriseDataCancelOrRejectChangeRoleProposal = async (
    idProposal, applicationOid
) => {
    let body = "";
    let header = buildHeader("*/*", true, false, applicationOid);
    let apiUrl =
        fetchApi.PostEnterpriseDataCancelOrRejectChangeRoleProposal.replace("{idProposal}", idProposal);
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return await response;
};

export const GetEnterpriseDataEnterprisePendingInvitations = async (
    idEnterprise
) => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.GetEnterpriseDataEnterprisePendingInvitations.replace("{idEnterprise}", idEnterprise);
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const DeteleEnterpriseDataRemoveUserFromEnterprise = async (
    idEnterprise,
    userOid
) => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.DeteleEnterpriseDataRemoveUserFromEnterprise.replace("{userOid}", userOid) +
        "?idEnterprise=" +
        idEnterprise;
    let response = await CommonFetch(header, body, fetchMethod.DELETE, apiUrl);
    return await response;
};

export const PostEnterpriseDataCreateOrUpdateAddress = async (
    address,
    idEnterprise,
    codeConfiguration
) => {
    let body = address;
    let header = buildHeader("*/*", true, "application/json");
    let apiUrl = fetchApi.PostEnterpriseDataCreateOrUpdateAddress + "?idEnterprise=" + idEnterprise;
    if (!checkIsNullUndefined(codeConfiguration)) {
        apiUrl = apiUrl + "&codeConfiguration=" + codeConfiguration;
    };
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return await response;
};