import { makeStyles } from "@material-ui/core/styles";

export const StylesServiceCard = makeStyles((theme) => ({

    roundedCard: {
        borderRadius: "2em",
        backgroundColor: "#F3F2F2",
        height: "13em",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto"


    },
    roundedCardHover: {
        borderRadius: "2em",
        backgroundColor: "#FFFFFF",
        height: "13em",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        "&:hover": { backgroundColor: "#002e6a", cursor: "pointer" },

    },
    roundedCardGrid: {
        width: "80%"
    },
    roundedCardSubscribed: {
        borderRadius: "2em",
        backgroundColor: "#FFCD00",

    },
    backgroundChange: {
        padding: "16px 0px 0px 0px",


    },
    backgroundChangeHover: {
        padding: "16px 0px 0px 0px",
        "&:hover": { backgroundColor: "#002e6a", cursor: "pointer" },
    },
    serviceNameContainer: {
        textAlign: "center",
        color: "#002e6a",

    },
    serviceName: {
        textAlign: "center",
        color: "#002e6a",
        "&:last-child": {
            paddingBottom: "0"
        }

    },
    serviceNameHover: {
        textAlign: "center",
        color: "white",
        "&:last-child": {
            paddingBottom: "0"
        }
    },

    details: {
        display: "flex",
        flexDirection: "column",
    },
    media: {
        maxWidth: "10rem",
    },
    imgCardMedia: {
        width: "auto",
        height: "4em",
        float: "left",
    },
    ButtonRight: {
        textAlign: "center",
        float: "right",
    },
    ButtonLeft: {
        textAlign: "center",
        float: "left",
    },
    ButtonLeftPermission: {
        textAlign: "center",
        float: "left",
        marginTop: "1em",
        marginLeft: "auto",
        marginRight: "auto"

    },
    ButtonCenter: {
        textAlign: "center",
        float: "center",
        color: "#666666",
        border: "none"
    },
    GridContent: {
        marginLeft: "0.25em",
        marginRight: "0.25em",
    },
    GridContentWithText: {
        marginLeft: "0.25em",
        marginRight: "0.25em",
        paddingTop: "0.75em"
    },
    GridButtons: {
        marginLeft: "0.25em",
        marginRight: "0.25em",
        paddingTop: "1.25em"
    },
    GridButtonsEnterprise: {
        justifyContent: "center",
    },
    CardDescription: {
        textAlign: "center",
        color: "white",
        paddingTop: "0px",
        padding: "12px 12px 1rem 12px",
    },

}));
