import { makeStyles } from "@material-ui/core/styles";
import { Constants } from "../../commons/Constants";

export const SideMenuStyles = makeStyles((theme) => ({
  drawer: {
    maxWidth: "100%",
    // flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      maxWidth: Constants.DrawerWidth,
    },
  },
  hideDrawer: {
    maxWidth: Constants.DrawerWidth,
    // flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  drawerPaper: {
    backgroundColor: "#FFFFFF",
    width: Constants.DrawerWidth,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  drawerContainer: {
    paddingTop: "3.0em",
    overflow: "auto",
  },
  drawerText: {
    color: "#002453",
  },
  icon: {
    fill: "currentColor",
    width: "0.9em",
    height: "0.9em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    flexShrink: "0",
    userSelect: "none",
    margin: "auto",
  },
  userGrid: {
    backgroundColor: "#FFCD00",
    paddingTop: "1em",
    paddingBottom: "1em",
    padding: "0em 1.5em",
    borderBottom: "1px solid #002453",
  },

  profileBar: {
    marginTop: '0.5em',
  },
  menuList:{
    color: "#002453",
    padding: "0em"
  },
  menuListItem:{
    padding: "0.5em"
  },
  collapseStyle:{
    backgroundColor: "#F3F3F3"
  }
}));
