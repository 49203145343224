import React, { useEffect, useState } from "react";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Tooltip,
    CircularProgress
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { AttributeCodes, Attributes } from "../../../commons/Attributes";
import { addressEnterpriseCodeAttributes, addressUserCodeAttributes } from "../../../commons/EnumAttributes";
import { DialogAddressActions } from "./DialogAddressActions";
import { CommonStyles } from "../../../commons/CommonStyles";
import { RequiredDataStyles } from "../RequiredDataStyles";
import HomeIcon from '@material-ui/icons/Home';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PublicIcon from '@material-ui/icons/Public';
import LocalPostOfficeIcon from '@material-ui/icons/LocalPostOffice';
import { checkIsNullUndefined } from "../../../commons/Utils";

export function AddressCard(props) {
    const { address, isUser, objectAddressActions } = props;
    const commonClasses = CommonStyles();
    const classes = RequiredDataStyles();
    const { t, i18n } = useTranslation();

    const [codes, setCodes] = useState([]);

    useEffect(() => {
        if (isUser) {
            setCodes(addressUserCodeAttributes);
        } else {
            setCodes(addressEnterpriseCodeAttributes);
        };
    }, [isUser]);

    const mountAddress = () => {
        let via = address[Attributes[AttributeCodes.Via].attributeValue];
        let addr = address[Attributes[AttributeCodes.Address].attributeValue];
        let addr1 = address[Attributes[AttributeCodes.Address1].attributeValue];
        addr1 = checkIsNullUndefined(addr1) ? "" : addr1;
        let text = via + " " + addr + " " + addr1;
        return mountTextLine("HomeIcon", text);
    };

    const mountCity = () => {
        let city = address[Attributes[AttributeCodes.City].attributeValue];
        let region = address[Attributes[AttributeCodes.Province].attributeValue];
        let text = (checkIsNullUndefined(city) || checkIsNullUndefined(region)) ? "-" : city + ", " + region;
        return mountTextLine("LocationCityIcon", text);
    };

    const mountCountry = () => {
        let country = address[Attributes[AttributeCodes.Country].attributeValue];
        return mountTextLine("PublicIcon", country);
    };

    const mountPostalCode = () => {
        let postalCode = address[Attributes[AttributeCodes.PostalCode].attributeValue];
        let text = (checkIsNullUndefined(postalCode)) ? "-" : postalCode;
        return mountTextLine("LocalPostOfficeIcon", text);
    };

    const mountTextLine = (icon, text) => {
        let auxIcon;
        switch (icon) {
            case "HomeIcon":
                auxIcon = <HomeIcon
                    className={classes.iconsStyles}
                    style={{ float: "left" }}
                />;
                break;
            case "LocationCityIcon":
                auxIcon = <LocationCityIcon
                    className={classes.iconsStyles}
                    style={{ float: "left" }}
                />;
                break;
            case "PublicIcon":
                auxIcon = <PublicIcon
                    className={classes.iconsStyles}
                    style={{ float: "left" }}
                />;
                break;
            case "LocalPostOfficeIcon":
                auxIcon = <LocalPostOfficeIcon
                    className={classes.iconsStyles}
                    style={{ float: "left" }}
                />;
                break;
            default:
                break;
        };

        return (
            <Grid item xs={12} className={classes.cardIcons}>
                {auxIcon}
                <Tooltip title={text}>
                    <Typography component="h1" variant="h6" noWrap align="left" style={{ paddingTop: "0.2em" }}>
                        {text}
                    </Typography>
                </Tooltip>
            </Grid>);
    };

    return (
        <Grid item xs={12} md={6}>
            <Card style={{ backgroundColor: "#fafafa" }}>
                <Grid
                    container
                    item
                    xs={12}
                >
                    {objectAddressActions.activeAddress == address.id && objectAddressActions.actionCircularProgress ?
                        <Grid item xs={12} className={commonClasses.gridIdentityCenter}>
                            <CircularProgress style={{ margin: "auto", marginTop:"1em", marginBottom:"1em" }}></CircularProgress>
                        </Grid> : (
                            <CardContent style={{ paddingTop: "1em", paddingRight: "0.5em", paddingBottom: "0.5em", paddingLeft: "1em", maxWidth: "100%" }}>
                                <Grid container item md={12} xs={12}>
                                    <Grid item xs={9}>
                                        <Typography component="h1" variant="h4" noWrap align="left" style={{ marginLeft: "0.25em" }}>
                                            {address[Attributes["ALI"].attributeValue]}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <DialogAddressActions
                                            objectAddressActions={objectAddressActions}
                                            isCard={true}
                                            address={address}
                                            circularProgress={objectAddressActions.addressCircularProgress}
                                        />
                                    </Grid>
                                    <Grid container item xs={12} >
                                        {mountAddress()}
                                        {mountCity()}
                                        {mountCountry()}
                                        {mountPostalCode()}
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        style={{ marginTop: "0.5em" }}
                                    >
                                        <Grid item xs={12}>
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={address?.favourite}
                                                            name="check"
                                                            disabled={true}
                                                            className={commonClasses.leftButton}
                                                        />
                                                    }
                                                    label={t("userDataFavouriteDirection")}
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        )
                    }
                </Grid>
            </Card>
        </Grid>
    );
}
