import React, { useEffect } from "react";
import { Snackbar, Slide } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { AlertColors } from "../../commons/AlertColors";

function Transition(props) {
  return <Slide {...props} direction="left" />;
}

export function Alerts(props) {
  const { alert, setAlert } = props;

  const [hideTimeout, setHideTimeout] = React.useState(6000);

  useEffect(() => {
    setHideTimeout(6000);
  }, [alert]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    } else {
      setAlert({
        open: false,
        color: "",
        message: "",
      });
    }
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{ marginTop: "4em" }}
      open={alert.open}
      autoHideDuration={hideTimeout}
      onClose={handleClose}
    >
      {alert.open ? (
        <Alert
          onClose={handleClose}
          variant="filled"
          style={{ backgroundColor: AlertColors[alert.color], color: "white" }}
        >
          {alert.message}
        </Alert>
      ) : null}
    </Snackbar>
  );
}
