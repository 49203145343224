import { AttributeCodes, Attributes, InputCodes } from "../../../commons/Attributes";
import { Constants } from "../../../commons/Constants";
import { EnumPersonType } from "../../../commons/EnumsGeneral";
import { checkIsNullUndefined, compareDates, getDate, validateName } from "../../../commons/Utils";
import { getReplaceApplicationOid } from "../../../services/Commons";
import { mountEnterpriseObject, mountUserObject } from "./FormFunctions";

export const checkAllOk = (forAndFormObject, mandatorySetObject, gettersObject, auxInfo, setPendingData, auxFormVariables) => {
    let setUserMandatoryData = mandatorySetObject.setUserMandatoryData;
    let setEnterpriseMandatoryData = mandatorySetObject.setEnterpriseMandatoryData;
    let forUser = forAndFormObject.forUser;
    let forEnterprise = forAndFormObject.forEnterprise;
    let userDataForm = forAndFormObject.userDataForm;
    let enterpriseDataForm = forAndFormObject.enterpriseDataForm;
    let getUserFormValuesObject = gettersObject.getUserFormValuesObject;
    let getBothAddressValuesObject = gettersObject.getBothAddressValuesObject;
    let getBothFormValuesObject = gettersObject.getBothFormValuesObject;
    let pendingData = [];
    if (forUser) {
        userCheck(pendingData, userDataForm, getUserFormValuesObject, getBothAddressValuesObject, auxInfo.doTraduction);
    };
    if (forEnterprise && (!auxInfo.pendingDataPage || (auxInfo.pendingDataPage && auxInfo.isTitular))) {
        enterpriseCheck(pendingData, enterpriseDataForm, getBothAddressValuesObject, auxInfo.doTraduction);
    };
    let isCid = getReplaceApplicationOid() == process.env.REACT_APP_APP_OID;
    let auxCond = !checkIsNullUndefined(auxFormVariables.auxRgpdsInfo.agreeConditions) && !auxFormVariables.auxRgpdsInfo.agreeConditions;
    let auxTyCs = (!isCid && auxFormVariables.auxCompConst.isRegistry) && !auxFormVariables.auxRgpdsInfo.agreeTyCs;
    if (auxCond) {
        pendingData.push(auxInfo.doTraduction("privacySign"));
    };
    if (auxTyCs) {
        pendingData.push(auxInfo.doTraduction("tycSign"));
    };
    setPendingData(pendingData);
    if (!checkIsNullUndefined(setUserMandatoryData)) {
        setUserMandatoryData({ pendingData, userMandatoryData: mountUserObject(getBothFormValuesObject, getBothAddressValuesObject) });
    };
    if (!checkIsNullUndefined(setEnterpriseMandatoryData)) {
        setEnterpriseMandatoryData({ pendingData, enterpriseMandatoryData: mountEnterpriseObject(getBothFormValuesObject, getBothAddressValuesObject, auxInfo.idEnterprise) });
    };
};

export const userCheck = (pendingData, userDataForm, getUserFormValuesObject, getBothAddressValuesObject, doTraduction) => {
    let userFormValuesObject = getUserFormValuesObject();
    let userAddresValuesObject = getBothAddressValuesObject(EnumPersonType.User);
    userDataForm.forEach((attribute) => {
        if (attribute.mandatory) {
            userMandatoryChecks(attribute, pendingData, userFormValuesObject, doTraduction);
        };
        if ((attribute.codeAttribute === AttributeCodes.Name
            || attribute.codeAttribute === AttributeCodes.Surname1
            || attribute.codeAttribute === AttributeCodes.Surname2)
            && (!checkIsNullUndefined(attribute.value) && !validateName(attribute.value))) {
            let text = attribute.nameAttritube + doTraduction("valid").toLowerCase();
            pendingData.push(text);
        };
    });
    if (userFormValuesObject.EditableDocuments?.length === 0 && userFormValuesObject.NeedsDocument) {
        let text = doTraduction("document");
        pendingData.push(text);
    };
    if (userAddresValuesObject.EditableAddresses?.length === 0 && userAddresValuesObject.NeedsAddress) {
        let text = doTraduction("userAddress");
        pendingData.push(text);
    };
};

export const enterpriseCheck = (pendingData, enterpriseDataForm, getBothAddressValuesObject, doTraduction) => {
    let enterpriseAddresValuesObject = getBothAddressValuesObject(EnumPersonType.Enterprise);
    enterpriseDataForm.forEach((attribute) => {
        if (attribute.mandatory) {
            if (checkIsNullUndefined(attribute.value)) {
                let text = attribute.nameAttritube;
                pendingData.push(text);
            };
        };
        if (attribute.fieldType === InputCodes.Date) {
            let auxflag = !compareDates(
                attribute.value,
                Constants.minDate,
                Constants.maxDate
            );
            if (!auxflag) {
                let text = attribute.nameAttritube + " " + doTraduction("valid").toLowerCase();
                pendingData.push(text);
            };
        };
    });
    if (
        enterpriseAddresValuesObject.EditableAddresses?.length === 0 &&
        enterpriseAddresValuesObject.NeedsAddress
    ) {
        let text = doTraduction("enterpriseAddress");
        pendingData.push(text);
    };
};

const userMandatoryChecks = (attribute, pendingData, userFormValuesObject, doTraduction) => {
    let auxDocCodes = [AttributeCodes.DocType, AttributeCodes.DocNumber, AttributeCodes.ExpedCountry, AttributeCodes.ExpirDate];
    let auxMovCodes = [AttributeCodes.Phone, AttributeCodes.Prefix];
    let isEmail = attribute.codeAttribute === AttributeCodes.Email;
    let isMov = auxMovCodes.includes(attribute.codeAttribute);
    let isDoc = auxDocCodes.includes(attribute.codeAttribute);
    if (isEmail) {
        checkEmail(pendingData, attribute, userFormValuesObject.EditableEmails, doTraduction);
    } else if (isMov) {
        checkPhone(pendingData, attribute, userFormValuesObject.EditablePhones, doTraduction);
    } else if (isDoc) {
        checkDoc(pendingData, attribute, userFormValuesObject.EditableDocuments);
    } else {
        if (checkIsNullUndefined(attribute.value)) {
            let text = attribute.nameAttritube;
            pendingData.push(text);
        };
    };
    if (attribute.fieldType === InputCodes.Date) {
        let auxFlag =
            attribute.codeAttribute === AttributeCodes.Birthday
                ? !compareDates(
                    attribute.value,
                    Constants.minDate,
                    getDate(Date().toLocaleString())
                )
                : !compareDates(
                    attribute.value,
                    Constants.minDate,
                    Constants.maxDate
                );
        if (!auxFlag) {
            let text = attribute.nameAttritube + " " + doTraduction("valid").toLowerCase();
            pendingData.push(text);
        };
    };
};

const checkEmail = (pendingData, attribute, userEditableEmails, doTraduction) => {
    let auxFlag = true;
    let text = attribute.nameAttritube;
    if (userEditableEmails.length === 0) {
        auxFlag = false;
    } else if (attribute.mustRegistry) {
        auxFlag = false;
        text += " " + doTraduction("verificated").toLowerCase();
        userEditableEmails.forEach((email) => {
            if (email.idConTypeVerification == 2) {
                auxFlag = true;
            };
        });
    };
    if (!auxFlag) {
        pendingData.push(text);
    };
};

const checkPhone = (pendingData, attribute, userEditablePhones, doTraduction) => {
    let auxFlag = true;
    let text = attribute.nameAttritube;
    if (userEditablePhones.length === 0) {
        auxFlag = false;
    } else if (attribute.codeAttribute === AttributeCodes.Phone && attribute.mustRegistry) {
        auxFlag = false;
        text += " " + doTraduction("verificated").toLowerCase();
        userEditablePhones.forEach((phone) => {
            if (phone.idConTypeVerification == 5) {
                auxFlag = true;
            };
        });
    };
    if (!auxFlag && attribute.codeAttribute !== AttributeCodes.Prefix) {
        pendingData.push(text);
    };
};

const checkDoc = (pendingData, attribute, userEditableDocuments) => {
    let auxFlag = true;
    let text = attribute.nameAttritube;
    if (userEditableDocuments.length !== 0) {
        userEditableDocuments.forEach((document) => {
            let auxValue = document[Attributes[attribute.codeAttribute]?.attributeName];
            if (checkIsNullUndefined(auxValue)) {
                auxFlag = false;
            };
        });
    };
    if (!auxFlag) {
        pendingData.push(text);
    };
};