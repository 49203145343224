import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { checkIsNullUndefined, getDate } from "../../../commons/Utils";
import { CommonStyles } from "../../../commons/CommonStyles";

export function CheckBoxInput(props) {
  const commonClasses = CommonStyles();
  const { attribute, handleInputSelected } = props;
  const [value, setValue] = useState("");

  useEffect(() => {
    if (attribute?.codeAttribute === "FNA") {
      let fecha = checkIsNullUndefined(attribute?.value)
        ? "dd/mm/aaaa"
        : getDate(attribute.value, true);
      setValue(fecha);
    } else {
      setValue(attribute?.value);
    }
  }, [attribute]);

  const handleChange = () => { }

  return (
    // <Grid item xs={12}>
    <TextField
      value={checkIsNullUndefined(attribute?.value) ? " " : attribute.codeAttribute === "FNA" ? getDate(attribute.value, true) :  attribute.value}
      variant="outlined"
      fullWidth
      name="textbox"
      color="secondary"
      label={attribute?.nameAttribute}
      onClick={(event) => handleInputSelected(event, attribute)}
      onChange={handleChange}
      focused={attribute?.checked}
      type={"text"}
      inputProps={{ style: { cursor: "pointer" } }}
      className={commonClasses.inputsStyle}
    />
    // </Grid>
  );
}
