export const EUTYC2 = {

    //Cookies Politicy TyC Tittles

    tycCookiesPolicityTittle1: "Zer dira cookieak?",
    tycCookiesPolicityTittle2: "Zer nolako cookie-ak daude?",
    tycCookiesPolicityTittle3: "Zein cookie erabiltzen ditu webgune honek?",
    tycCookiesPolicityTittle4: "Nola aldatu cookie-en konfigurazioa?",

    //Cookies Politicy TyC Texts

    tycCookiesPolicityText1:
        "Correos y Telégrafos, S.A. Estatu Sozietateak (hemendik aurrera, “Correos”) bere webgunean erabiltzen dituen cookie-en berri ematen dizu. Cookie-politika hau alda daiteke; beraz, sartzen zaren aldioro kontsultatzea gomendatzen dizugu.",
    tycCookiesPolicityText2:
        "Webgune jakinetara sartzen zarenean zure ordenagailuan/smartphonean/tabletan deskargatzen diren fitxategiak edo gailuak dira cookie-ak, terminalari buruzko informazioa bildu eta berreskuratzeko. Besteak beste, nabigatzeko dituzun ohiturei buruzko informazioa bildu eta berreskuratzeko aukera ematen dute. Hala, eskaintzen zaion zerbitzua hobe daiteke. Cookie bat webgune batek zure PCan, telefonoan edo bestelako gailuren batean ipintzen duen testu-fitxategi txiki bat da, aipatu webgunean egin duzun nabigazioari buruzko informazioarekin. Cookie-ak beharrezkoak dira errazago nabigatzeko eta adiskidetsuagoa izateko, eta ez dute zure ordenagailua kaltetzen.",
    tycCookiesPolicityText3:
        "Hainbat cookie mota daude, eta honela sailka ditzakegu:",
    tycCookiesPolicityText4:
        "Cookie-ak bidali eta datuak tratatzen diren domeinua kudeatzen duen entitatea",
    tycCookiesPolicityText5:
        "Cookie propioak: zure ekipo terminalera bidaltzen dira gure ekipo edo domeinu propioetatik.",
    tycCookiesPolicityText6:
        "Hirugarrenen cookie-ak: erakunde kolaboratzaileen ekipo edo domeinutik zure gailura bidaltzen dira.",
    tycCookiesPolicityText7: "Nabigatzailean gordeta geratzen diren aldia",
    tycCookiesPolicityText8:
        "Saioko cookie-ak: webgunean sartzen zarenean edo eskatutako zerbitzua emateko aktibatzen dira.",
    tycCookiesPolicityText9:
        "Cookie iraunkorrak: zure ekipo terminalean gordetzen dira aldi jakin baten bitartean. Arduradunak cookie hauek berreskura ditzake zu bere webgunera konektatzen zarenean.",
    tycCookiesPolicityText10: "Berreskuratutako datuak tratatzearen helburua",
    tycCookiesPolicityText11:
        "Cookie teknikoak: webgunea erabiltzeko eta zerbitzuetarako sarbidea izateko beharrezkoak dira.",
    tycCookiesPolicityText12:
        "Pertsonalizatzeko cookie-ak: izaera orokorreko zenbait ezaugarri zure terminalean konfiguratu bezala sartzeko aukera ematen dute (hizkuntza, nabigatzailea…).",
    tycCookiesPolicityText13:
        "Analisirako cookie-ak: lotuta dituzten webguneetako erabiltzaile guztien ohituren segimendua eta analisi estatistikoa egiteko aukera ematen dute.",
    tycCookiesPolicityText14:
        "Cookie publizitarioak: eskatutako zerbitzua eskaintzeko erabiliko den webgunean, aplikazioan edo plataforman publizitaterako dauden atalak kudeatzen laguntzen dute.",
    tycCookiesPolicityText15:
        "Portaeraren araberako publizitateko cookie-ak: zuk dituzun nabigatzeko ohituren gaineko informazioa gordetzen dute, publizitatea erakusteko profil zehatza sortzeko aukera emanez.",
    tycCookiesPolicityText16:
        "Hona hemen gure webgunean erabiltzen diren cookie-ak, haien deskribapena, zenbat denboraz gordetzen diren eta titulartasuna.",
    tycCookiesPolicityText17:
        "Cookie-ak erabiltzeko baimena eman dezakezu edo edozein unetan baliogabetu esteka honen bidez ",
    tycCookiesPolicityText18_B: "Cookie-en konfigurazioa.",
    tycCookiesPolicityText18:
        "Nabigatzailea erabiliz, gure Webgunean erabiltzen diren cookie-ak onartu, murriztu, blokeatu edo ezabatu ahalko dituzu.",
    tycCookiesPolicityText19:
        "Erabiltzen duzun nabigatzailearen arabera, era batera edo bestera egingo da. Hurrengo esteketan aurkituko duzu nabigatzaile bakoitzean cookie-en konfigurazioa aldatzeko edo horiek desgaitzeko jarraitu beharreko pausoak.",
    tycCookiesPolicityText20:
        "Internet Explorer: microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=»ie-10″",
    tycCookiesPolicityText21: "Firefox: mozilla.org/es/kb/Borrar%20cookies",
    tycCookiesPolicityText22: "Chrome: google.com/chrome/answer/95647?hl=»es»",
    tycCookiesPolicityText23: "Safari: apple.com/es/privacy/use-of-cookies/",

    //Cookies Politicy TyC Tables Texts

    tycCookiesPolicityTableHead1: "Izendapena",
    tycCookiesPolicityTableHead2: "Helburua",
    tycCookiesPolicityTableHead3: "Iraupena",
    tycCookiesPolicityTableHead4: "Jabea",

    //Table Tittles

    tycCookiesPolicityTableTittle1:
        "Cookie teknikoak eta pertsonalizazio-cookie-ak",
    tycCookiesPolicityTableTittle2: "Analisirako cookie-ak",
    tycCookiesPolicityTableTittle3: "Portaeraren araberako publizitate-cookie-ak",

    //Table Text

    session: "Saioa",
    persistent: "Iraunkorra",

    //Tale 1

    tycCookiesPolicityTable1_Text1:
        "Erabiltzailea identifikatu eta baimentzeko erabiltzen dira. Gainera, erabiltzailearen saioaren datu teknikoak barne hartzen ditu, adibidez, konexio-denbora, saio-identifikatzailea, etab.",
    tycCookiesPolicityTable1_Text2:
        "Erabiltzailearen http saioa identifikatzen du. Web aplikazio guztietan ohikoak dira, eta erabiltzaileak saio batean egiten dituen eskaerak identifikatzen ditu",
    tycCookiesPolicityTable1_Text3:
        "Erabiltzaileak zein nabigazio-egoeratan dagoen identifikatzen du (saio-hasiera, lehenengo orria, lehenengo sarbidea, scroll baten egoera, bozketa baten egoera, etab.).",
    tycCookiesPolicityTable1_Text4:
        "Erabiltzaileak hautatu dituen saio-balioak gordetzen ditu, adibidez, denda, hizkuntza, moneta, produktuak, neurriak, etab.",

    //Table 2 and 3

    tycCookiesPolicityDoesntExist: "Ez daude Correos ID-n",

    // Cookie Banner
    
    cookieBannerAcceptAll: "Aceptar",
    cookieBannerConfigure: "Configurar",
    cookieBannerWeUseCookies1: "Utilizamos cookies para personalizar el contenido, adaptar los anuncios, medir su eficacia y ofrecer una experiencia más segura. Al navegar por el sitio web, aceptas el uso de cookies para recopilar información dentro y fuera de Correos.",
    cookieBannerWeUseCookies2: "Lee nuestra ",
    cookieBannerWeUseCookies3: "Política de Cookies",
    cookieBannerWeUseCookies4: " para obtener más información o accede a las preferencias de cookies para gestionar tu configuración",
    cookieBannerLearnMore: "Puedes obtener más información en nuestra",
    cookieDialogDescriptionTPE: "Permiten la navegación a través de nuestra web y ejecutar la acción solicitada por el usuario. Por ejemplo, permitir la reproducción de contenido multimedia, registrar sus pedidos en el carrito de compra, reconocer inicios de sesión y la selección de idioma en función del lugar de acceso, etc.",
    cookieDialogDescriptionANA: "Ofrecen distintas métricas de uso de la web que nos permiten mejorar la navegación del usuario, conocer qué apartados de la web resultan más atractivos, desde dónde acceden los usuarios, etc. Se trata información de carácter estadístico y que es proporcionada a nivel agregado, salvo por el registro de la dirección IP desde la que accede.",
    cookieDialogDescriptionPCO: "Son aquéllas que almacenan información del comportamiento de los usuarios obtenida a través de los hábitos de navegación del usuario, lo que permite desarrollar un perfil específico para mostrar publicidad.",
    cookieDialogMandatoryCookie: "(Cookies estrictamente necesarias)",
    cookieDialogMandatory: "Obligatorio",
    cookieDialogAllow: "Permitir",
    cookieDialogDisallow: "No permitir",
    cookieDialogSave: "Guardar Preferencias",
    cookieDialogAllowAll: "Permitir Todo",
    cookieDialogDisallowAll: "No permitir nada",
    cookieDialogCookiesPolicyText: "Puedes obtener más información en nuestra",
};
