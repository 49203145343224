import { VA1 } from "./translations1";
import { VA2 } from "./translations2";
import { VATYC1 } from "./TycsTranslation1";
import { VATYC2 } from "./TycsTranslation2";

const VA = { ...VA1, ...VA2, ...VATYC1, ...VATYC2 };
export { VA };



