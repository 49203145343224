import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { checkIsNullUndefined, } from "../../commons/Utils";
import { CommonStyles } from "../../commons/CommonStyles";

export default function AutocompleteAttribute(props) {
  const { attribute, saveGroupsChanges } = props;
  const commonClasses = CommonStyles();

  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");

  useEffect(() => {
    //El attribute.value es el code
    let auxCodeOrValue = getCodeOrValue(attribute.value, null);
    setInputValue(auxCodeOrValue.value);
  }, [attribute]);

  const _handleDropdownChange = (event, nValue) => {
    if (!checkIsNullUndefined(event) && event.type !== "blur") {
      saveAttributesChanges(true, nValue);
    }
  };

  const _handleChange = (nValue) => { };

  const saveAttributesChanges = (first, nValue) => {
    let auxCodeAndValue = getCodeOrValue(null, nValue);
    let code = auxCodeAndValue.code?.toUpperCase();
    setInputValue(nValue?.toUpperCase());
    setValue(code);
    if (!checkIsNullUndefined(code)) {
      saveGroupsChanges(props.attribute, code, first);
    } else {
      if (checkIsNullUndefined(nValue)) {
        saveGroupsChanges(props.attribute, nValue, first);
      } else if (
        (attribute.codeAttribute === "CPO" ||
          attribute.codeAttribute === "ECP") &&
        nValue.length === 5
      ) {
        saveGroupsChanges(props.attribute, nValue, first);
      }
    }
  };

  const getCodeOrValue = (auxCode, auxValue) => {
    let name =
      attribute.codeAttribute !== "CPO" && attribute.codeAttribute !== "ECP"
        ? "name"
        : "postalCode";
    let auxAttrCodeName =
      (attribute.codeAttribute == "CPO" || attribute.codeAttribute == "ECP")
        ? "postalCode"
        : (attribute.codeAttribute == "POB" || attribute.codeAttribute == "EPO")
          ? "codeLocalidad"
          : "code";
    let code;
    let value = "";
    if (!checkIsNullUndefined(attribute.originalValues)) {
      attribute.originalValues.forEach((dropValue) => {
        if(!checkIsNullUndefined(auxValue) && auxValue === dropValue[name]){
          code = dropValue[auxAttrCodeName];
        }
        if (!checkIsNullUndefined(auxCode) && auxCode === dropValue[auxAttrCodeName]) {
          value = dropValue[name];
        }
      });
    }
    return {code: code, value: value}
  }

  return (
    <Grid item md={12} xs={12}>
      <Autocomplete
        autoHighlight
        required={attribute.mandatory}
        id={attribute.code}
        options={checkIsNullUndefined(attribute.dropdownValues) ? [] : attribute.dropdownValues}
        disabled={attribute.disabled}
        getOptionLabel={(option) =>
          attribute.codeAttribute !== "CPO" && attribute.codeAttribute !== "ECP"
            ? checkIsNullUndefined(option.name)
              ? ""
              : option.name
            : checkIsNullUndefined(option.postalCode)
              ? ""
              : option.postalCode
        }
        value={value}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) =>
          _handleDropdownChange(event, newInputValue)
        }
        onChange={(event, newValue) => _handleChange(newValue)}
        renderOption={(option) => (
          <React.Fragment>
            {attribute.codeAttribute !== "CPO" &&
              attribute.codeAttribute !== "ECP"
              ? checkIsNullUndefined(option.name)
                ? ""
                : option.name
              : checkIsNullUndefined(option.postalCode)
                ? ""
                : option.postalCode}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={(attribute.nameAttritube) + (attribute.mandatory ? " *" : "")}
            variant="filled"
            color="secondary"
            InputProps={{ ...params.InputProps, type: "search" }}
            className={commonClasses.inputsStyle}
          />
        )}
      />
    </Grid>
  );
}
