import React, { useState } from "react";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import {
  Grid,
  Dialog,
  Container,
  TextField,
  IconButton,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import ico_pass_on from "../../../assets/images/ico_pass_on.svg";
import ico_pass_off from "../../../assets/images/ico_pass_off.svg";
import { PostPasswordCheckPassword } from "../../../services/ReasonService";
import { checkIsNullUndefined } from "../../../commons/Utils";
import { CommonStyles } from "../../../commons/CommonStyles";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { useTranslation } from "react-i18next";

export function DialogPutPassword(props) {
  const { openPutPass, handleCancel, switchDialogs } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const { t, i18n } = useTranslation();

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmCircular, setConfirmCircular] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    let koPass = checkIsNullUndefined(event.target.value);
    setPassword(event.target.value);
    setError(koPass);
  };

  const handleCancelPutPass = () => {
    handleCancel(false);
  };

  const handleConfirmPutPass = () => {
    setError(false);
    setConfirmCircular(true);
    PostPasswordCheckPassword(password).then((response) => {
      if (response && !response.Status) {
        setConfirmCircular(false);
        setPassword("");
        switchDialogs();
      } else {
        setConfirmCircular(false);
        setError(true);
      }
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid>
      <Dialog
        fullWidth
        open={openPutPass}
        onClose={() => handleCancelPutPass()}
        PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
      >
        <Container className={commonClasses.dialogTitle}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={commonClasses.dialogTitleText}
              >
                {t("teamPagePutPassword")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={commonClasses.dialogContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                value={password}
                color="secondary"
                variant="outlined"
                label={t("correosPasswordLabel")}
                fullWidth
                id="titem"
                autoFocus
                required
                type={showPassword ? "text" : "password"}
                onChange={handleChange}
                error={error}
                helperText={
                  error
                    ? checkIsNullUndefined(password)
                      ? t("teamPagePutPasswordEmptyError")
                      : t("teamPagePutPasswordError")
                    : ""
                }
                InputProps={{
                  // className: classes.loginUnderline,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <img src={ico_pass_on} alt="pass_on" />
                        ) : (
                          <img src={ico_pass_off} alt="pass_off" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                className={commonClasses.inputsStyle}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={commonClasses.dialogButtonContainer}
            >
              <Grid item xs={6}>
                <CorreosButton
                  color="secondary"
                  className={commonClasses.dialogButtonLeft}
                  onClick={handleCancelPutPass}
                >
                  {t("cancel")}
                </CorreosButton>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  variant="contained"
                  color="primary"
                  className={commonClasses.dialogButtonRight}
                  onClick={handleConfirmPutPass}
                  disabled={error || checkIsNullUndefined(password)}
                  circularProgress={confirmCircular}
                >
                  {t("confirm")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </Grid>
  );
}
