// import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getLanguage,
  setLanguage,
  setAppEntranceUrl,
  getSessionItem,
  removeSessionItem,
} from "./services/Commons";
import { SetSecretKey } from "./commons/SecureStorage";
import { CircularProgress, Grid } from "@material-ui/core";
import CookieBanner, { Cookies } from 'react-cookie-banner';
import { CustomCookieBanner } from "./components/CustomCookieBanner/CustomCookieBanner";
import { checkIsNullUndefined } from "./commons/Utils";
import PublicRedirectRoute from "./config/routes/PublicRedirectRoute";
import { sessionValues } from "./commons/EnumsGeneral";
import { Routes } from "./config/routes/routes";

function App() {
  const { t, i18n } = useTranslation();
  const [circularProgress, setCircularProgress] = useState(true);
  const [acceptedCookies, setAcceptedCookies] = useState(true);

  useEffect(() => {
    let language = getLanguage();
    setLanguage(language, i18n);
    removeSessionItem(sessionValues.redirectURI);
    let auxToken = getSessionItem(sessionValues.idToken);
    SetSecretKey(auxToken).then(() => {
      if (checkIsNullUndefined(auxToken)) {
        setAppEntranceUrl(setCircularProgress, t);
      } else {
        setCircularProgress(false);
      }
    });
  }, []);

  useEffect(() => {
    var cookies = new Cookies();
    cookies.get('CookiesAceptadas');
    setAcceptedCookies(cookies.cookies.CookiesAceptadas);
  }, []);

  const _handleFlag = () => {
    setAcceptedCookies(true);
  };

  return (
    <Grid className="App">
      {circularProgress ? (
        <CircularProgress style={{ marginLeft: "auto", marginRight: "auto" }} />
      ) : (
        <Routes />
      )}
      {acceptedCookies ? null : (
        <CookieBanner dismissOnScroll={false} >
          <CustomCookieBanner handleFlag={_handleFlag} />
        </CookieBanner>
      )}
    </Grid>
  );
}

export default App;
