export const ESTYC2 = {
    //Cookies Politicy TyC Tittles
  
    tycCookiesPolicityTittle1: "¿Qué son las cookies?",
    tycCookiesPolicityTittle2: "¿Qué tipo de cookies existen?",
    tycCookiesPolicityTittle3: "¿Qué cookies concretas utiliza este sitio web?",
    tycCookiesPolicityTittle4: "¿Cómo modifico la configuración de las cookies?",
  
    //Cookies Politicy TyC Texts
    tycCookiesPolicityText1: "La Sociedad Estatal Correos y Telégrafos, S.A. (en adelante “Correos”) te informa como usuario acerca de las cookies en ésta página web. La presente Política de Cookies podrá ser objeto de modificaciones, por lo que te recomendamos consultarla cada vez que accedes.",
    tycCookiesPolicityText2: "Una cookie es un archivo o dispositivo que se descarga en tu ordenador/smartphone/tablet al acceder a determinadas páginas web para almacenar y recuperar información del equipo terminal. Entre otras funciones, permite almacenar y recuperar información sobre tus hábitos de navegación con el fin de mejorar el servicio ofrecido. Una cookie es un pequeño fichero de texto que un sitio web coloca en su PC, teléfono o cualquier otro dispositivo, con información sobre su navegación en dicho sitio. Las cookies son necesarias para facilitar la navegación y hacerla más amigable, y no dañan su ordenador.",
    tycCookiesPolicityText3: "Existen distintos tipos de cookies que se pueden clasificar según:",
    tycCookiesPolicityText4: "La entidad que gestiona el dominio desde donde se envían las cookies y tratan los datos",
    tycCookiesPolicityText5: "Cookies propias: se envían a tu equipo terminal desde nuestros equipos o dominios propios.",
    tycCookiesPolicityText6: "Cookies de terceros: se envían a tu equipo terminal desde un equipo o dominio de otra entidad colaboradora.",
    tycCookiesPolicityText7: "El plazo de tiempo que permanecen almacenadas en el navegador",
    tycCookiesPolicityText8: "Cookies de sesión: se activan mientras accedes a la página web o para la prestación del servicio solicitado.",
    tycCookiesPolicityText9: "Cookies persistentes: se almacenan por un tiempo determinado en tu equipo terminal. El responsable tiene acceso cuando te conectas a su página web.",
    tycCookiesPolicityText10: "La finalidad del tratamiento de los datos obtenidos",
    tycCookiesPolicityText11: "Cookies técnicas: son necesarias para el uso del sitio web y para la prestación de servicios.",
    tycCookiesPolicityText12: "Cookies de personalización: permiten acceder al servicio con unas algunas características de carácter general predefinidas en función de una serie de criterios en tu terminal (idioma, tipo de navegador…).",
    tycCookiesPolicityText13: "Cookies de análisis: permiten el seguimiento y análisis estadístico del comportamiento del conjunto de los usuarios de los sitios web a los que están vinculadas.",
    tycCookiesPolicityText14: "Cookies publicitarias: permiten la gestión de los espacios publicitarios en la página web, aplicación o plataforma desde la que se presta el servicio solicitado.",
    tycCookiesPolicityText15: "Cookies de publicidad comportamental: almacenan información de tu comportamiento obtenida a través de los hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad.",
    tycCookiesPolicityText16: "A continuación se identifican las cookies utilizadas en nuestro sitio web así como su descripción, la duración de almacenamiento y su titularidad.",
    tycCookiesPolicityText17: "Puedes prestar tu consentimiento al uso de cookies o revocarlo en cualquier momento a través del enlace ",
    tycCookiesPolicityText18_B: "Configuración de Cookies.",
    tycCookiesPolicityText18: "También puedes hacerlo utilizando tu navegador, por el que puedes permitir, restringir, bloquear o borrar las cookies utilizadas desde nuestro Sitio Web.",
    tycCookiesPolicityText19: "La forma de hacerlo será diferente en función del tipo de navegador utilizado durante la navegación. En los siguientes enlaces tienes a tu disposición toda la información para configurar o deshabilitar las cookies en cada navegador.",
    tycCookiesPolicityText20: "Internet Explorer: microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=»ie-10″",
    tycCookiesPolicityText21: "Firefox: mozilla.org/es/kb/Borrar%20cookies",
    tycCookiesPolicityText22: "Chrome: google.com/chrome/answer/95647?hl=»es»",
    tycCookiesPolicityText23: "Safari: apple.com/es/privacy/use-of-cookies/",
  
    //Cookies Politicy TyC Tables Texts
    tycCookiesPolicityTableHead1: "Denominación",
    tycCookiesPolicityTableHead2: "Finalidad",
    tycCookiesPolicityTableHead3: "Duración",
    tycCookiesPolicityTableHead4: "Titular",
    //Table Tittles
    tycCookiesPolicityTableTittle1: "Cookies técnicas y de personalización",
    tycCookiesPolicityTableTittle2: "Cookies analíticas",
    tycCookiesPolicityTableTittle3: "Cookies de publicidad comportamental",
    //Table Text
    session: "Sesión",
    persistent: "Persistente",
    //Tale 1
    tycCookiesPolicityTable1_Text1: "Se utilizan para identificar y autenticar al usuario. Contiene además datos técnicos de la sesión de usuario como, por ejemplo, tiempo de espera de onexión, identificador de sesión, etc.",
    tycCookiesPolicityTable1_Text2: "Identifica la sesión http del usuario. Es común en todas las aplicaciones web para identificar peticiones de un usuario en una sesión",
    tycCookiesPolicityTable1_Text3: "Permite identificar en qué estado de navegación se encuentra el usuario (inicio de la sesión, primera página, primer acceso, estado de un scroll, estado de una votación, etc.).",
    tycCookiesPolicityTable1_Text4: "Almacenan los valores de sesión seleccionados por el usuario tales como la tienda, el idioma, la moneda, los productos, la talla, etc.",
    //Table 2 and 3
    tycCookiesPolicityDoesntExist: "No existen en Correos ID",
  
    // Cookie Banner
    cookieBannerAcceptAll: "Aceptar",
    cookieBannerConfigure: "Configurar",
    cookieBannerWeUseCookies1: "Utilizamos cookies para personalizar el contenido, adaptar los anuncios, medir su eficacia y ofrecer una experiencia más segura. Al navegar por el sitio web, aceptas el uso de cookies para recopilar información dentro y fuera de Correos.",
    cookieBannerWeUseCookies2: "Lee nuestra ",
    cookieBannerWeUseCookies3: "Política de Cookies",
    cookieBannerWeUseCookies4: " para obtener más información o accede a las preferencias de cookies para gestionar tu configuración",
    cookieBannerLearnMore: "Puedes obtener más información en nuestra",
    cookieDialogDescriptionTPE: "Permiten la navegación a través de nuestra web y ejecutar la acción solicitada por el usuario. Por ejemplo, permitir la reproducción de contenido multimedia, registrar sus pedidos en el carrito de compra, reconocer inicios de sesión y la selección de idioma en función del lugar de acceso, etc.",
    cookieDialogDescriptionANA: "Ofrecen distintas métricas de uso de la web que nos permiten mejorar la navegación del usuario, conocer qué apartados de la web resultan más atractivos, desde dónde acceden los usuarios, etc. Se trata información de carácter estadístico y que es proporcionada a nivel agregado, salvo por el registro de la dirección IP desde la que accede.",
    cookieDialogDescriptionPCO: "Son aquéllas que almacenan información del comportamiento de los usuarios obtenida a través de los hábitos de navegación del usuario, lo que permite desarrollar un perfil específico para mostrar publicidad.",
    cookieDialogMandatoryCookie: "(Cookies estrictamente necesarias)",
    cookieDialogMandatory: "Obligatorio",
    cookieDialogAllow: "Permitir",
    cookieDialogDisallow: "No permitir",
    cookieDialogSave: "Guardar Preferencias",
    cookieDialogAllowAll: "Permitir Todo",
    cookieDialogDisallowAll: "No permitir nada",
    cookieDialogCookiesPolicyText: "Puedes obtener más información en nuestra",
};
  