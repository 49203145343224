import { makeStyles, withStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";

export const RegisterDataStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    }
}));

export const BorderLinearProgressStyles = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor:
            theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#002453"
    }
}))(LinearProgress);