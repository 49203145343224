import { makeStyles } from "@material-ui/core/styles";
import { Constants } from "../../../commons/Constants";

export const SideMenuTyCStyles = makeStyles((theme) => ({
  drawer: {
    maxWidth: "100%",
    // flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      maxWidth: Constants.DrawerWidth,
    },
  },
  hideDrawer: {
    maxWidth: Constants.DrawerWidth,
    // flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  drawerPaper: {
    width: "255px",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "inline-flex"
    },
  },
  drawerContainer: {
    paddingTop:"1em",
    overflow: "auto",
  },
  drawerText: {
    color: "#002453",
  },
  mainDiv:{
    paddingTop:"6em",
  }
}));
