import React, { useEffect } from "react";
import {
    Typography,
    Container,
    Dialog,
    Grid,
} from "@material-ui/core";

import { CommonStyles } from "../../../commons/CommonStyles";
import { useTranslation } from "react-i18next";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { generalWarning } from "../../../commons/EnumsGeneral";

export function GeneralWarning(props) {
    const { open, setOpen, isEmail, warnType } = props;
    const commonClasses = CommonStyles();
    const dialogClasses = DialogStyles();
    const { t, i18n } = useTranslation();

    useEffect(() => { }, []);

    const handleCancel = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        handleCancel();
    };

    const getAuxText = () => {
        let auxTextKey = warnType == generalWarning.pass ? "generalWarningPassText" : warnType == generalWarning.registry ? "generalWarningRegistryText" : "generalWarningVerifyText";
        let auxDataType = isEmail ? t("email").toLowerCase() : t("phone").toLowerCase();
        let auxText = t(auxTextKey).replace(/{dataType}/g, auxDataType);
        return auxText;
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={handleCancel}
            PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
        >
            <Container className={commonClasses.dialogTitle}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            className={commonClasses.dialogTitleText}
                        >
                            {t("warning")}
                        </Typography>
                    </Grid>

                </Grid>
            </Container>
            <Container className={commonClasses.dialogContainer}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography className={commonClasses.dialogContentText}>
                            {getAuxText()}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        className={commonClasses.dialogButtonContainer}
                    >
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <CorreosButton
                                variant="contained"
                                color="primary"
                                onClick={handleConfirm}
                                className={commonClasses.dialogButtonCenter}
                            >
                                {t("accept")}
                            </CorreosButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    );
}
