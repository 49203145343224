import { Attributes } from "../../../commons/Attributes";
import { multipleAttributes } from "../../../commons/EnumAttributes";
import { EnumPages } from "../../../commons/EnumPages";
import { secureValues } from "../../../commons/EnumsGeneral";
import { getFinalModel, getGroups, obtainAttribute, sortAttributes } from "../../../commons/FormManager";
import { checkIsNullUndefined, getDate, getErrorMessage, getCountryByAnyIdValue } from "../../../commons/Utils";
import { getSecureItem } from "../../../services/Commons";
import { GetUtilitiesCorreosIdUserExtendedInfoByDataVerified, PostUtilitiesCorreosIdLastSignInDate } from "../../../services/UtilitiesCallsService";
import history from "../../../services/history";

export const preparePage = (
    masterData,
    userConfig,
    settersObject,
    auxInfo,
    userInitialCallData,
) => {
    //Obtención de la información de los usuarios
    let auxCopyUser = getSecureItem(secureValues.copyUser);
    GetUtilitiesCorreosIdUserExtendedInfoByDataVerified(auxCopyUser).then((response) => {
        if (response && !response.Status) {
            let userInfo = userInitialCallData;
            let copyInfo = response;
            prepareUsers(userConfig, userInfo, false, masterData, settersObject, auxInfo);
            prepareUsers(userConfig, copyInfo, true, masterData, settersObject, auxInfo);
            if (process.env.REACT_APP_ENVELOP_ACTIVE_LAST_LOGIN == "true") {
                obtainLastLogin(copyInfo, settersObject, auxInfo);
            };
        } else {
            auxInfo.finishOperation("error", getErrorMessage(response, auxInfo.doTraduction));
            setTimeout(() => {
                history.push("Index/" + EnumPages.UserVerifications);
            }, 2000);
        };
    });
};

const prepareUsers = (userConfig, userInfo, isCopy, masterData, settersObject, auxInfo) => {
    let userAuxForm = JSON.parse(JSON.stringify(userConfig));
    let userGroups = getGroups(userAuxForm);
    let userCompleteForm = getFinalModel(userAuxForm, userGroups);
    let auxUserDataForm = { value: [] };
    userPreparation(userCompleteForm, auxUserDataForm);
    let auxUserData = userInfo;
    let auxUserEnterprises = auxUserData.userEnterprise;
    let auxUserServices = auxUserData.userSubscription;
    auxUserData.userEnterprise = auxUserEnterprises;
    auxUserData.userSubscription = auxUserServices.filter((service) => service.application != "Correos ID");
    prepareMultipleData(userAuxForm, auxUserData, isCopy, masterData.masterCountry, settersObject, auxInfo);
    let auxUserDataBasicForm = auxUserDataForm.value.filter((attr) => !multipleAttributes.includes(attr.codeAttribute));
    auxUserDataBasicForm.forEach((attr) => {
        attr.checked = false;
        attr.isCopy = isCopy;
    });
    prepareData(auxUserDataBasicForm, auxUserData, isCopy, masterData, settersObject, auxInfo);
    prepareEnterprises(auxUserData.userEnterprise, isCopy, settersObject, auxInfo);
    prepareServices(auxUserData.userSubscription, isCopy, settersObject, auxInfo);
    let auxOriginSetFunction = isCopy ? settersObject.setCopyOriginalData : settersObject.setUserOriginalData;
    auxOriginSetFunction(auxUserData);
};

const userPreparation = (userCompleteForm, auxUserDataForm) => {
    auxUserDataForm.value = sortAttributes(userCompleteForm[0]);
};

const prepareMultipleData = (auxAttrlist, auxData, isCopy, countryList, settersObject, auxInfo) => {
    let auxEmails = [];
    let auxPhones = [];
    let auxAddresses = [];
    let auxEmailAttr = obtainAttribute(auxAttrlist, "EMA");
    let auxPhoneAttr = obtainAttribute(auxAttrlist, "MOV");
    let auxAddressAttr = obtainAttribute(auxAttrlist, "DIR");
    auxData.userEmail.forEach((email) => {
        let checked = !isCopy || email.email == auxInfo.user2;
        auxEmails.push({ id: email.id, value: email.email, nameAttribute: auxEmailAttr.nameAttritube, checked: checked, codeAttribute: "EMA", isCopy: isCopy })
    });
    auxData.userPhone.forEach((phone) => {
        let auxCountry = getCountryByAnyIdValue(countryList, phone.idCountry, "id");
        let auxUser = auxCountry.phonePrefix + "-" + phone.phone;
        let checked = !isCopy || auxUser == auxInfo.user2;
        auxPhones.push({ id: phone.id, value: auxUser, nameAttribute: auxPhoneAttr.nameAttritube, checked: checked, codeAttribute: "MOV", isCopy: isCopy })
    });
    auxData.userAddress.forEach((address) => {
        auxAddresses.push({ id: address.id, value: address.alias + ": " + address.address, nameAttribute: auxAddressAttr.nameAttritube, checked: !isCopy, codeAttribute: "DIR", isCopy: isCopy })
    });
    if (isCopy) {
        settersObject.setCopyEmails(auxEmails);
        settersObject.setCopyPhones(auxPhones);
        settersObject.setCopyAddresses(auxAddresses);
    } else {
        settersObject.setUserEmails(auxEmails);
        settersObject.setUserPhones(auxPhones);
        settersObject.setUserAddresses(auxAddresses);
    };
};

const prepareData = (
    attrList,
    auxData,
    isCopy,
    masterData,
    settersObject,
    auxInfo
) => {
    const selectCodeList = ["NAC", "LAN", "GEN"];
    let auxAttrlist = JSON.parse(JSON.stringify(attrList));
    let auxSetCall = isCopy ? settersObject.setCopyDataForm : settersObject.setUserDataForm;
    auxAttrlist.forEach((attr) => {
        let auxText = checkIsNullUndefined(auxData[Attributes[attr.codeAttribute].attributeName]) ? " " : auxData[Attributes[attr.codeAttribute].attributeName];
        if (!checkIsNullUndefined(auxText) && selectCodeList.includes(attr.codeAttribute)) {
            prepareSelectValues(attr, masterData, auxText);
        } else {
            attr.value = auxText;
        };
        attr.nameAttribute = attr.nameAttritube;
    });
    masterData.masterTypeUserDocument.forEach((docType) => {
        prepareDocuments(auxData, auxAttrlist, docType, isCopy, auxInfo);
    });
    auxSetCall(auxAttrlist);
};

const prepareEnterprises = (enterpriseList, isCopy, settersObject, auxInfo) => {
    let auxSetCall = isCopy ? settersObject.setCopyEnterprises : settersObject.setUserEnterprises;
    let auxEnterprises = [];
    enterpriseList.forEach((enterprise) => {
        let auxName = enterprise.companyName ? enterprise.companyName : enterprise.tradeName ? enterprise.tradeName : auxInfo.doTraduction("enterpriseNameUnregistered");
        auxName = auxName + " - " + enterprise.documentNumber;
        auxEnterprises.push({ id: enterprise.id, value: auxName, nameAttribute: auxInfo.doTraduction("Enterprise"), checked: true, codeAttribute: "ENT", isCopy: isCopy });
    });
    auxSetCall(auxEnterprises);
};

const prepareServices = (serviceList, isCopy, settersObject, auxInfo) => {
    let auxSetCall = isCopy ? settersObject.setCopyServices : settersObject.setUserServices;
    let auxServices = [];
    serviceList.forEach((service) => {
        auxServices.push({ id: service.id, value: service.application, nameAttribute: auxInfo.doTraduction("Services"), checked: true, codeAttribute: "SER", isCopy: isCopy });
    });
    auxSetCall(auxServices);
};

const obtainLastLogin = (copyInfo, settersObject, auxInfo) => {
    //Llamada al back para el lastLogin
    let copyOid = copyInfo.secondaryUserOid;
    PostUtilitiesCorreosIdLastSignInDate([copyOid]).then((response) => {
        if (response && !response.Status) {
            let copyLoginInfo = response.find((auxCopyLoginInfo) => copyOid == auxCopyLoginInfo.userOid)?.lastSignInDate;
            let auxLasDate = checkIsNullUndefined(copyLoginInfo) ? "-" : getDate(copyLoginInfo, true);
            settersObject.setUserInfo([{ id: "prev1", value: getDate(null, true), nameAttribute: auxInfo.doTraduction("unifyAccountsUserInfoLogin"), checked: true, codeAttribute: "INF", isCopy: false }])
            settersObject.setCopyInfo([{ id: "prev2", value: auxLasDate, nameAttribute: auxInfo.doTraduction("unifyAccountsUserInfoLogin"), checked: true, codeAttribute: "INF", isCopy: true }])
        } else {
            auxInfo.finishOperation("error", getErrorMessage(response, auxInfo.doTraduction));
            settersObject.setLastLoginCircularProgress(false);
        };
    });
};

const prepareSelectValues = (attr, masterData, auxText) => {
    let auxList = attr.codeAttribute == "NAC" ? masterData.masterCountry : attr.codeAttribute == "LAN" ? masterData.masterLanguage : masterData.masterGenders;
    let auxObj = auxList.find((auxObje) => auxObje.id == auxText);
    attr.value = auxObj.name;
    attr.id = auxText;
};

const prepareDocuments = (auxData, auxAttrlist, docType, isCopy, auxInfo) => {
    let auxDoc = auxData.userDocument.find((document) => document.idTypeUserDocument == docType.id);
    if (checkIsNullUndefined(auxDoc)) {
        auxDoc = {};
    };
    auxAttrlist.push({ id: checkIsNullUndefined(auxDoc.id) ? 0 : auxDoc.id, value: checkIsNullUndefined(auxDoc.number) ? " " : auxDoc.number, nameAttribute: docType.name, checked: (!isCopy && auxInfo.isMovilTypeScreen), codeAttribute: docType.code, isCopy: isCopy });
};