import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { ES } from "./es/translations";
import { EN } from "./en/translations";
import { GL } from "./gl/translations";
import { CA } from "./ca/translations";
import { EU } from "./eu/translations";
import { VA } from "./va/translations";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      es: {
        translation: ES,
      },
      en: {
        translation: EN,
      },
      gl: {
        translation: GL,
      },
      ca: {
        translation: CA,
      },
      eu: {
        translation: EU,
      },
      va: {
        translation: VA,
      },
    },
  });

i18n.changeLanguage("es");

export default i18n;