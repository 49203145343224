import React, { useEffect, useState } from "react";
import { CorreosButton } from "../../../components/CorreosButton/CorreosButton";
import {
  Grid,
  Dialog,
  Container,
  Typography,
} from "@material-ui/core";
import { CommonStyles } from "../../../commons/CommonStyles";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { useTranslation } from "react-i18next";
import { checkIsNullUndefined, getErrorMessage } from "../../../commons/Utils";
import { AgreeConditionsLabel } from "../../../components/CommonComponents/AgreeConditionsLabel";
import {
  PostSubscriptionEnterprise,
} from "../../../services/ReasonService";
import {
  PostLegalEnterprisePendingTyc,
} from "../../../services/LegalCallsService";
import {
  backToLogin,
  controlUserMovement,
  getAndSetErrorCodes,
  getSessionItem,
  missedInfo
} from "../../../services/Commons";
import { EnumPages } from "../../../commons/EnumPages";
import history from "../../../services/history";
import { errorCodes } from "../../../commons/ConstantObjects";
import { sessionValues } from "../../../commons/EnumsGeneral";


export function DialogMissingData(props) {
  const { setOpenDialog, openDialog, missingData, finishOperation, enterpriseId, needVerifyDocPopup, isTitular } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const { t, i18n } = useTranslation();

  const [needTyCs, setNeedTyCs] = useState(false);
  const [agreeConditions, setAgreeConditions] = useState(false);
  const [reset, setReset] = useState(false);
  const [needSubscription, setNeedSubscription] = useState(false);
  const [needSignTycs, setNeedSignTycs] = useState(false);
  const [needsTitular, setNeedsTitular] = useState(false);
  const [tycsObject, setTycsObjects] = useState({});

  useEffect(() => {
    if (!checkIsNullUndefined(missingData)) {
      if ((missingData.indexOf(errorCodes[999]) !== -1 || missingData.indexOf(errorCodes[2030]) !== -1 || missingData.indexOf(errorCodes[2060]) !== -1)
      ) {
        setReset(false);
        setNeedTyCs(true);
        if (missingData.indexOf(errorCodes[2030]) !== -1) setNeedSubscription(true);
        if (missingData.indexOf(errorCodes[2060]) !== -1) setNeedSignTycs(true);
      };
      if (missingData.length == 1 && missingData[0] == errorCodes[2020] && !isTitular) {
        setNeedsTitular(true);
      };
    };
  }, [missingData]);

  const handleSubscription = () => {
    let appOid = getSessionItem(sessionValues.appOid);
    //Llamada para suscribirse
    if (!checkIsNullUndefined(enterpriseId)) {
      PostSubscriptionEnterprise(appOid, enterpriseId).then(
        (response) => {
          if (response && (!response.Status || response.Status == 202)) {
            sessionStorage.removeItem(errorCodes[2030]);
            sessionStorage.removeItem(errorCodes[2060]);
          } else {
            finishOperation("error", getErrorMessage(response, t));
          }
          setOpenDialog(false);
        }
      );
    };
  };

  const handleSignTycs = () => {
    PostLegalEnterprisePendingTyc(tycsObject).then((response) => {
      if ((response && !response.Status) || response.Status == 202) {
        sessionStorage.removeItem(errorCodes[2060]);
      } else {
        finishOperation("error", getErrorMessage(response, t));
      };
      setOpenDialog(false);
    });
  };

  const handleUserOk = () => {
    getAndSetErrorCodes().then(() => {
      let missData = missedInfo();
      if (missData.length > 0) {
        history.push("/" + EnumPages.PendingData);
      } else {
        controlUserMovement(false, finishOperation, t);
      };
    });
  };

  const aceptDialog = () => {
    if (needTyCs) {
      if (needSubscription) {
        handleSubscription();
        handleUserOk();
      } else if (needSignTycs) {
        handleSignTycs();
        handleUserOk();
      };
      setOpenDialog(false);
      setReset(true);
      setNeedTyCs(false);
    } else if (needVerifyDocPopup || needsTitular) {
      backToLogin();
    } else {
      setOpenDialog(false);
    };
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openDialog}
      PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
    >
      <Container className={commonClasses.dialogTitle}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" className={commonClasses.dialogTitleText}>
              {t("warning")}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container className={commonClasses.dialogContainer}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={commonClasses.dialogContentText}>
            <Typography
              className={commonClasses.textDiv}
              style={{ textAlign: "left" }}
            >
              <Typography
                variant="h6"
                className={commonClasses.title}
                style={{ textAlign: "left" }}
              >
                {t("dialogMissingDataText")}
              </Typography>
              {needVerifyDocPopup ? (
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", marginTop: "1em" }}
                >
                  {t("dialogMissingDataTextDocument")}
                </Typography>
              ) : needsTitular ? (
                <Typography
                  variant="h6"
                  style={{ textAlign: "left", marginTop: "1em" }}
                >
                  {t("dialogMissingDataTextNoHolder")}
                </Typography>
              ) : (
                <ul>
                  {missingData.map((data) => {
                    if (data != errorCodes[203] && data != errorCodes[242]) { return (<li>{t("dialogMissingData" + data)}</li>) }
                  })}
                </ul>
              )}
            </Typography>
          </Grid>
          {needTyCs ? (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                className={commonClasses.title}
                style={{ textAlign: "left" }}
              >
                {t("dialogMissingDataTextTyC")}
              </Typography>
              <AgreeConditionsLabel
                setOk={setAgreeConditions}
                finishOperation={finishOperation}
                reset={reset}
                personType={2}
                setTycsObjects={setTycsObjects}
                enterpriseId={enterpriseId}
              />
            </Grid>
          ) : null}
          <Grid
            container
            item
            xs={12}
            className={commonClasses.dialogButtonContainer}
          >
            <Grid item xs={12} className={commonClasses.popperContentText}>
              <CorreosButton
                variant="contained"
                color="primary"
                onClick={() => aceptDialog()}
                className={commonClasses.dialogButtonCenter}
                disabled={needTyCs && !agreeConditions}
              >
                <Typography>{t("accept")}</Typography>
              </CorreosButton>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}
