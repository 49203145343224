export const VA1 = {

    //Generic

    saveSuccess: "Desament realitzat correctament!",
    saveError: "Error en guardar!",
    cancel: "Cancel·lar",
    accept: "Acceptar",
    next: "Següent",
    previous: "Anterior",
    required: "Este és un camp obligatori",
    exit: "Eixir",
    search: "Buscar",
    news: "Novetats",
    send: "Enviar",
    reSend: "Reenviar",
    create: "Crear",
    add: "Afegir",
    delete: "Esborrar",
    update: "Actualitzar",
    yes: "Sí",
    no: "No",
    save: "Guardar",
    correosId: "Correos ID",
    genericError: "S’ha produït un error",
    change: "Canviar",
    see: "Mostrar",
    download: "Baixar",
    email: "Email",
    phone: "Teléfono",
    idDocument: "Documento identificativo",
    address: "Adreça",
    country: "País",
    province: "Província",
    city: "Ciutat",
    postalCode: "Codi postal",
    reset: "Inicialitzar",
    pending: "Pendiente",
    done: "Hecho",
    here: "aquí",
    backToTheApp: "Volver a la aplicación",
    selectOption: "Selecciona una opción",
    noOptionSelected: "Sin seleccionar",

    // PendingData

    valid: "Válido",
    document: "Documento",
    userAddress: "Dirección de usuario",
    enterpriseAddress: "Dirección de empresa",
    privacySign: "Firma de privacidad",
    tycSign: "Firma de Términos y Condiciones",
    pendingData: "Tienes datos pendientes:",

    //login

    logInTitle: "Inicia sesión",
    sublogInTitle: "Accede a {appName} a través de Correos ID. ",
    sublogInTitleCID: "Accede a Correos ID. ",
    sublogInTitleInfo: "Si ya estás registrado, no tendrás que volver a registrarte.",
    sublogInTitleInfoCID: "Desde aquí puedes manejar tu identidad de correos.",
    logInTitleSelectType: "¿Cómo quieres acceder?",
    correosIdentityLabel: "Identitat de Correos",
    correosPasswordLabel: "Contrasenya",
    correosCheckPasswordLabel: "Confirmación contraseña",
    loginTooltip: "Correos ID es la puerta de entrada a los servicios digitales de Correos. Para acceder a ellos, solamente debes introducir tus credenciales, no tendrás que volver a registrarte.",
    forgotPasswordButton: "Has oblidat la contrasenya?",
    nextButton: "Següent",
    logInButton: "Iniciar sessió",
    userPasswordError: "Usuari o contrasenya incorrectes",
    warningLegal: "Avís legal",
    privacyPolicy: "Política de privacitat",
    cookiesPolicy: "Política de galletes",
    createAccountButton: "Crear compte",
    rememberme: "Recorda’m",
    welcomeBack: "Hola de nou",
    recoverDisabledAccount1:
        "S’ha bloquejat el seu compte. Si vol tornar a activar el compte i recuperar la seua informació, clique ",
    recoverDisabledAccount2: "l’enllaç següent",
    recoverDisabledAccount3: " per a iniciar el procés de recuperació de compte.",
    loginPrivacyDialogTitle: "Privacitat",
    loginPrivacyDialogText:
        "Per a poder accedir a l’aplicació, abans ha d’acceptar la privacitat",
    loginSubscribeDialogTitle: "Suscripción",
    loginSubscribeDialogText: "No estás suscrito a la aplicación, si quieres suscribirte debes leer y aceptar los términos y condiciones y pulsar en aceptar",
    loginSubscribeDialogTextServices: "Para poder suscribirte primero debes leer y aceptar los términos y condiciones y pulsar en aceptar",
    InvitationAcceptSuccess: "Invitación aceptada",
    AcceptChangeEnterpriseRoleSuccess: "Cambio de rol aceptado con éxito",
    CancelOrRejectChangeRoleSuccess: "Se ha rechazado la invitación",
    InvitationAcceptError: "La invitación ha fallado",
    AcceptChangeEnterpriseRoleError: "Ha fallado el cambio de rol",
    CancelOrRejectChangeRoleError: "Ha fallado el rechazo de la invitación",
    loginSystemUserError: "Un usuario de sistema no puede loguear en la web",
    footerCopyrightText: "©Sociedad Estatal Correos y Telegrafos, S.A., S.M.E. Todos los derechos reservados.",
    loginFormatError: "Formato incorrecto",
    loginOauthError: "El método oauth ya no está disponible",

    //Register

    loginPageInfo: "¿Aún no tienes una cuenta? ",
    loginPageRegistryHere: "Regístrate aquí",
    registerPagePutData: "Introduce los datos de registro",
    registerPageConfirmIdentity: "Verifica tu identidad",
    registerPageMail: "Adreça electrònica",
    registerPageMailError: "L’adreça electrònica introduïda no és correcta",
    registerPagePhoneError: "El telèfon introduït no és correcte",
    registerPageNDOError: "El document introduït no és correcte",
    registerPageMailErrorExist:
        "L’adreça electrònica introduïda ja està registrada",
    registerPagePassword: "Contrasenya",
    registerPagePasswordError:
        "8-16 caràcters, que continguen tres dels quatre següents: minúscules, majúscules, dígits (0-9) i un o més dels símbols següents: @ # $ % ^ & * - _ + = [ ] { } | \\ : ' , ? / ` ~ ( ) ; . \"",
    registerPageCheckPassword: "Confirmació",
    registerPageCheckPasswordError: "Les contrasenyes no són iguals",
    registerPagePhone: "Telèfon",
    registerPagePhoneErrorShort: "El telèfon és massa curt",
    registerPagePhoneErrorLong: "El telèfon és massa llarg",
    registerPagePhoneErrorExist: "El telèfon introduït ja està registrat",
    registerPagePrefixError: "Tiene que introducir un prefijo",
    registerPageTipoPersona: "Tipus de persona",
    registerPageAgreeConditionsPhrase: "Debes leer y aceptar los términos y condiciones: ",
    registerPageAgreeConditions: "Termes i condicions",
    registerPageAgreeConditionsDot: "Termes i condicions: ",
    registerPageAgreeConditionsSee: "Ver Términos y Condiciones",
    registerPageAgreeConditionsTerms1: "He llegit i accepte ",
    registerPageAgreeConditionsTerms2: "les condicions d’ús ",
    registerPageAgreeConditionsTerms3: " de {appName}.",
    registerPageAgreeConditionsTerms4: "Política de protecció de dades",
    registerPageAgreeConditionsAccepted: "Aceptados",
    registerPageAgreeConditionsPending: "Pendiente *",
    registerPageAgreeConditionsTermsConsent:
        "D’acord amb la nostra Política de protecció de dades, ens agradaria actualitzar el seu consentiment per a les activitats següents:",
    registerPageOfersPromotions: "Ofertes i promocions",
    registerPageOfersPromotionsDot: "Ofertes i promocions: ",
    registerPageOfersPromotionsAll: "ELS ACCEPTE TOTS",
    registerPageOfersPromotionsSome: "N’ACCEPTE ALGUNS",
    registerPageOfersPromotionsNone: "NO ELS ACCEPTE",
    registerPageOfersPromotionsPending: "PENDIENTE *",
    registerPagePersonalizeOptions:
        "Personalitze la seua privacitat seleccionant les opcions següents:",
        registerPageIdentityVerifyTitle: "Confirma la teua identitat",
        registerPageIdentityVerifyText: "Per a garantir la teua identitat, enviarem un codi de verificació a la teua adreça de correu electrònic. Introduïx el codi a continuació per a completar el procés de registre.",
    registerPageIdentityVerifyMailButton: "ENVIAR CORREU ELECTRÒNIC",
    registerPageIdentityVerifyPhoneButton: "ENVIAR SMS",
    registerPageIdentityVerifyVerificable:
        "Ha de realitzar una de les dos verificacions obligatòriament, l’altra la pot ometre o aprofitar per a realitzar-la",
    registerPageIdentityVerifyVerificableEmail:
        "La verificació de l’adreça electrònica és obligatòria",
    registerPageIdentityVerifyVerificablePhone:
        "La verificació del telèfon és obligatòria",
    registerPageIdentityVerifyNoVerificableEmail:
        "La verificació de l’adreça electrònica és opcional, pot ometre este punt si vol",
    registerPageIdentityVerifyNoVerificablePhone:
        "La verificació del telèfon és opcional, pot ometre este punt si vol",
    registerPageIdentityVerifyCodeSended: "Codi enviat",
    registerPageOtp: "Introduïsca el codi",
    registerPageOtpError: "El codi introduït no és correcte",
    registerPageRegisterError: "Hi ha hagut un problema durant el registre",
    registerPageOfersPromotionsSelected: "Ofertes i promocions acceptades: ",
    registerPageOfersPromotionsSelectedAll: "Totes",
    registerPageOfersPromotionsSelectedSome: "Solo algunas",
    registerPageOfersPromotionsSelectedNone: "Cap",
    registerPageOfersPromotionsSelectedPending: "Sin seleccionar",
    registerPageSeeOfersPromotions: "Obrir ofertes/promocions",
    registerPageSendOTP: "Introduïsca el codi que li acabem d’enviar",
    registerPageSendOTPEmail: "Codi per a correu electrònic",
    registerPageSendOTPPhone: "Codi per a telèfon",
    registryTitleData: "Dades de registre",
    registerPagePersonType1: "Particular",
    registerPagePersonType2: "Empresa/Autònom",
    registerPageNoDocuments:
        "Per a este tipus d’empresa no es requerixen documents",
    registerPageWarnTitle: "Atención",
    registerPageWarnText: "El registro a correos ID ha ido correctamente, pero ha habido un problema en el guardado de datos, tendrás que volver a rellenarlos al acceder a la aplicación",
    registerPagePoliticsAndConditions: "Políticas y condiciones",
    registerPageRecaptcha: "Captcha",

    //ChooseUser

    chooseUserTitle:
        "Per favor, seleccione si es vol autoritzar com a particular o com a empresa:",
    chooseEnterpriseTitle:
        "Por favor, pulsa en 'Empresa' y escoge con cuál quieres autorizarte:",
    none: "Ninguna",
    chooseUser: "Particular",
    chooseEnterprise: "Empresa",
    chooseDialogTitle: "Seleccione empresa",

    //Stepper

    createUserCid: "Crear cuenta en CorreosID",
    userIdentify: "Verifica tu identidad",
    userIdentity: "Introduce tu identidad",
    sendOTP: "Enviar codi",
    reSendOTP: "Reenviar codi",
    codeConfirm: "Confirmar codi",
    subscriptionToApp: "Suscripción a {appName}",
    ownerData: "Datos del titular",
    enterpriseCreation: "Creación de empresa",
    userData: "Datos de usuario",
    stepChangePass: "Canviar contrasenya",
    enterpriseData: "Dades d’empresa",
    enterpriseDocuments: "Afegir documents",
    requiredData: "Datos requeridos",
    createUserCidTextLabel: "Introduce tu email de acceso y contraseña, acepta las políticas de privacidad y crea una cuenta en CorreosID para acceder a nuestros servicios de forma segura.",
    userIdentifyTextLabel: "Este paso es esencial para verificar tu identidad y garantizar la seguridad de tus datos.",
    userIdentityTextLabel: "Ingresa los detalles de tu identidad. Estos detalles nos ayudarán a personalizar tu experiencia y a brindarte un mejor servicio.",
    sendOTPTextLabel: "Te enviaremos un código único a tu dirección de correo electrónico o número de teléfono. Utiliza este código para verificar que tienes acceso a los medios de contacto proporcionados.",
    reSendOTPTextLabel: "En caso de no haber recibido el código, tienes la opción de solicitar su reenvío. Verifica tu carpeta de correo no deseado antes de hacerlo.",
    codeConfirmTextLabel: "Ingresa el código que has recibido y confirma tu acceso. Este código garantiza que eres el titular de la cuenta y estás listo para proceder.",
    subscriptionToAppTextLabel: "Opta por suscribirte a nuestra aplicación para acceder a sus funciones exclusivas. Mantente al tanto de las últimas actualizaciones y novedades.",
    ownerDataTextLabel: "Proporciona información detallada sobre ti.",
    enterpriseCreationTextLabel: "Si estás creando una empresa, completa los pasos necesarios para registrarla oficialmente. Proporciona información sobre ella.",
    userDataTextLabel: "Proporciona información adicional sobre ti para enriquecer tu perfil.",
    stepChangePassTextLabel: "Si deseas cambiar tu contraseña actual, este es el momento para hacerlo. Proporciona una nueva contraseña segura y asegúrate de guardarla en un lugar seguro.",
    enterpriseDataTextLabel: "Si estás registrando una empresa, completa los detalles adicionales.",
    enterpriseDocumentsTextLabel: "Sube documentos importantes relacionados con tu empresa.",
    requiredDataTextLabel: "Asegúrate de proporcionar todos los datos necesarios para completar el proceso. Estos pueden variar según el tipo de cuenta que estás creando. Verifica cada sección cuidadosamente.",

    //ResetPassword

    resetPasswordTitle: "¿Has olvidado tu contraseña?",
    resetPasswordText: "No te preocupes, ¡puede pasarle a cualquiera!",
    resetPasswordFormatError: "8-16 carácteres, que contengan 3 de los 4 siguientes: minúsculas, mayúsculas, dígitos (0-9), y uno o más de los siguientes símbolos: @ # $ % ^ & * - _ + = [ ] { } | \\ : ' , ? / ` ~ ( ) ; . \"",
    resetPasswordCheckError: "Las contraseñas no son iguales",
    resetPasswordTitle2: "Cambia tu contraseña",
    resetPasswordText2: "Confirma tu identidad con el código que te hemos enviado a:",
    resetPasswordEmptyError: "La contraseña no puede estar vacía",
    resetPasswordRedirection: "En unos segundos te redirigiremos al Inicio de sesión, o puedes ir pulsando ",
    lAddUser:
        "Introduïsca la seua identitat de Correos i l’ajudarem a crear una contrasenya nova.",
    backButton: "Tornar",
    sending: "S’està enviant...",
    lAddOtp: "Necessitem saber que és vosté. Ens ho confirma?",
    lOtp: "Codi de verificació",
    emptyError: "El camp no pot estar buit.",
    emptyErrorPass: "Els camps no poden estar buits.",
    emptyErrorIdentifier: "Ha d’afegir un identificador de Correos vàlid.",
    lChangePass: "Escriga la nova contrasenya.",
    lPass: "Contrasenya",
    lRPass: "Repetisca la contrasenya",
    errorEqualPass: "Les contrasenyes han de ser iguals",
    errorCheckPass: "La contrasenya indicada no és vàlida.",
    lCFinalStep: "La contrasenya s’ha modificat correctament.",
    lEFinalStep:
        "Error en canviar la contrasenya, torne a intentar-ho d’ací a uns minuts.",
    lStart: "Iniciar",
    tLinkHelper: "Necessita ajuda?",
    hAddUser:
        "Per a poder canviar la contrasenya necessitem saber l’identificador amb què es va donar d’alta a la nostra plataforma.",
    hAddOtp:
        "Des de Correos ID necessitem verificar que és vosté qui està intentant canviar la contrasenya, per eixe motiu, li enviarem al seu identificador de compte (telèfon o correu electrònic) un codi de 5 dígits. Simplement ens ha de dir eixe codi i podrem continuar amb el procés.",
    hAddPass:
        "Ha d’indicar una contrasenya nova que tinga entre 8-16 caràcters, que continguen tres dels quatre valors següents: minúscules, majúscules, dígits (0-9) i un o més dels símbols següents: @ # $ % ^ & * - _ + = [ ] { } |  : ' , ? / ` ~ \" ( ) ; .els mínims necessaris per a poder continuar.",
    dialogClose: "Tancar",
    otpRpError: "El codi indicat no és correcte.",
    otpInvalidError: "OTP no vàlid",
    otpEmptyError: "El codi no pot estar buit.",

    //PrefixPhone

    prefixSelector: "Prefix",

    //UserData

    userDataInfo:
        "Ja tens compte en CorreosId, ara és el moment de subscriure't a {appName}",
    userDataInfoCid:
        "T'has registrat en CorreosId correctament, ara és necessari completar les dades requerides",
    userDataVerifyEmailOk: "L’adreça electrònica s’ha verificat correctament",
    userDataVerifyEmailKo:
        "Hi ha hagut un problema amb la verificació de l’adreça electrònica",
    userDataVerifyPhoneOk: "El telèfon s’ha verificat correctament",
    userDataVerifyPhoneKo:
        "Hi ha hagut un problema amb la verificació del telèfon",
    userDataInfoQuestion: "¿Seguro que deseas cancelar la suscripción a {appName}?",
    userDataInfoQuestionCid: "¿Seguro que deseas cancelar la creación de la empresa?",
    userDataExitInfo:
        "Ya estás registrado en correosID, ten en cuenta que si sales ahora cuando hagas login en {appName} tendrás que terminar de rellenar los datos para suscribirte.",
    userDataExitInfoCid:
        "Ya estás registrado en correosID, ten en cuenta que si sales ahora tu usuario no tendrá empresa",
    userDataCopyDirection: "Copiar adreça",
    userDataSuccessUpdate: "Dades d’usuari actualitzades correctament",
    userDataAddEmail: "Afegir adreça electrònica",
    userDataModifyEmail: "Verificar email",
    userDataDeleteEmail: "Eliminar email",
    userDataDeleteEmailSuccess: "Email eliminado",
    userDataAddPhone: "Afegir telèfon",
    userDataModifyPhone: "Verificar teléfono",
    userDataDeletePhone: "Eliminar teléfono",
    userDataDeletePhoneSuccess: "Teléfono eliminado",
    userDataAddDoc: "Añadir documento",
    userDataModifyDoc: "Modificar documento identificativo",
    userDataDeleteDoc: "Eliminar documento identificativo",
    userDataAddDocSuccess: "Documento identificativo añadido",
    userDataModifyDocSuccess: "Documento identificativo modificado",
    userDataDeleteDocSuccess: "Documento identificativo eliminado",
    userDataAddAddress: "Añadir dirección",
    userDataModifyAddress: "Modificar Dirección",
    userDataDeleteAddress: "Eliminar Dirección",
    userDataAddAddressSuccess: "Dirección añadida",
    userDataModifyAddressSuccess: "Dirección modificada",
    userDataDeleteAddressSuccess: "Dirección eliminada",
    userDataSaveWarning:
        "Recorde guardar les dades perquè este canvi tinga efecte",
    userDataAliasWarning: "Ya tienes una dirección con ese nombre",
    userDataAddDirection: "Vol afegir una adreça amb les dades actuals?",
    userDataAddDirectionMandatorie:
        "Per a poder afegir una adreça, ha d’omplir tots els camps obligatoris",
    userDataRequiredDirection: "L’àlies i l’adreça són camps obligatoris",
    userDataAddDirectionSuccess: "Adreça afegida correctament",
    userDataChangeDirectionSuccess: "Dirección modificada con éxito",
    userDataFavouriteDirection: "Dirección favorita",
    userDataFavouriteDirectionWarning: "Ya tienes una dirección favorita y sólo puedes tener una, si seleccionas esta la otra dejará de serlo",
    userDataWarningMessage:
        "Recorde que per a guardar una adreça nova, abans l’ha d’afegir a la llista; si no ho ha fet encara, polse Cancel·lar i afegisca la nova adreça abans de continuar",
    userDataRegister: "Registro completado",
    userDataIncomplete:
        "Hi falten dades obligatòries. Per favor, òmpliga les dades obligatòries que hi falten.",
    userDataFNAError: "La data de naixement no pot ser posterior al dia de hui.",
    userDataAddressUpdateWarning: "Cal actualitzar aquesta adreça perquè les vostres dades no són correctes",
    userDataPageSubtitle: "Edita tus datos personales y gestiona las direcciones",

    //EnterpriseData

    enterpriseDataInfo:
        "S’ha efectuat el registre correctament, ara és necessari acabar d’omplir les dades de l’empresa",
    enterpriseDataInfoQuestion: "Segur que vol cancel·lar la inserció de dades?",
    enterpriseDataExitInfo:
        "L’empresa ja ha sigut registrada, si ix sense proporcionar les dades se li tornaran a demanar quan torne a accedir-hi",
    enterpriseDataCopyDirection: "Copiar adreça",
    enterpriseDataSuccessUpdate: "Dades d’empresa actualitzades correctament",
    enterpriseDataAddressWarning:
        "La dirección se guardará junto con la creación de la empresa",
    enterpriseDataCreated: "Empresa creada correctament",
    enterpriseDataPageSubtitle: "Modifica los datos de tu empresa así como la dirección fiscal",
    enterpriseDataCreatePageSubtitle: "Añade los datos de tu empresa así como la dirección fiscal",
    enterpriseDataSave: "Guardar empresa",
    enterpriseDataCreate: "Crear empresa",

    //DialogAddChange
    dialogAddChangeAddTitle: "Afegir adreça",
    dialogAddChangeChangeTitle: "Modificar adreça",
    dialogAddChangeAddText: "Afegeix les dades de l'adreça",
    dialogAddChangeChangeText: "Modifica les dades de l'adreça",

    //Main

    userNameUnregistered: "Usuari sense nom",
    enterpriseNameUnregistered: "Empresa sense nom",
    mainPageTitle: "Benvingut a Correos ID",
    mainPageUserProfile: "Perfil d’usuari",
    mainPagePrivacity: "Privacitat",
    mainPageVerifications: "Verificacions",
    mainPageCreateEnterprise: "Crear empresa",
    mainPageAvailableServices: "Servicis subscrits",

    //SideBar

    UserData: "Dades",
    UserVerifications: "Verificacions",
    UserPrivacy: "Privacitat",
    EnterpriseCreate: "Crear",
    EnterpriseTeam: "Equip",
    EnterpriseDelete: "Sol·licitar la baixa",
    UserServices: "Particular",
    EnterpriseData: "Dades",
    EnterpriseVerifications: "Verificacions",
    EnterprisePrivacy: "Privacitat",
    EnterpriseServices: "Empresa",
    User: "Usuari",
    Enterprise: "Empresa",
    Services: "Servicis",
    Service: "Servicio",
    profileComp: "Progrés del perfil",

    //Service Page

    subscribeService: "Subscriure-s’hi",
    unsubscribeService: "Baixa",
    revokeData: "Retirar l’accés a les meues dades",
    acceptData: "Permetre l’accés a les meues dades",
    goToService: "Entrar",
    subscribedService: "Subscrit",
    unSubscribedService: "No subscrit",
    askUnsubscribeService: "Sol·licitar la baixa",
    serviceSubscribed: "Subscripció realitzada correctament",
    serviceUnsubscribed: "Cancel·lació de la subscripció realitzada correctament",
    revokeEnterpriseData: "Retirar permisos",
    acceptEnterpriseData: "Permetre permisos",
    servicePageDualWarning: "¿Seguro que quieres darte de baja de {applicationName}?",
    servicePageCancelAccount: "Usuario",
    servicePageCancelEnterprise: "Empresa",
    servicePageUnsubscribe: "Servicio",
    singleSubscriptionDialogRedirectTextUser: "Estás a punto de darte de baja de {applicationName}, que es tu único servicio. Si deseas continuar con esta acción, pulsa en 'Servicio'. Por otro lado, si lo que quieres es dar de baja tu usuario de Correos ID, pulsa en 'Usuario'.",
    singleSubscriptionDialogRedirectTextEnterprise: "Estás a punto de dar de baja la empresa de {applicationName}, que es su único servicio. Si deseas continuar con esta acción, pulsa en 'Servicio'. Por otro lado, si lo que quieres es dar de baja la empresa, pulsa en 'Empresa'.",
    servicePageLinkText: "enllaç",
    subscribeMeText: "Subscriure'm",
    subscriptionOkTitle: "A punt!",
    subscriptionOkText: "T'has subscrit amb èxit",
    servicePageSubtitle: "Amb la teva identitat de Correus (Correus ID) pots subscriure't als serveis digitals de Correus",
    servicePageAppGoTo: "Suscríbete desde el servicio",
    servicePageAppAccess: "Accede desde el servicio",
    servicePageConfirmCancelAccount: "¿Seguro que quieres cancelar tu cuenta?",
    servicePageConfirmCancelEnterprise: "¿Seguro que quieres eliminar la empresa?",

    //Verifitacions Page

    verificationsVerify: "Verificar",
    verificated: "Verificat",
    notVerificated: "No verificat",
    pendingVerificated: "Pendent",
    UserEmail: "Adreça electrònica",
    PersonaTelfMovil: "Telèfon",
    PersonaNumDocID: "Núm. document",
    PersonaDireccion: "Adreça",
    verificationsSendCode: "Enviarem un codi de verificació a: ",
    verificationsSendCodeEmail: "Verifica tu email",
    verificationsSendCodePhone: "Verifica tu teléfono",
    verificationsAddVerifEmail: "Añade y verifica un email",
    verificationsAddVerifPhone: "Añade y verifica un teléfono",
    verificationsConfirmCode: "Introduïsca el codi de verificació",
    verificationsVerificationType: "Tipus de verificació",
    verificationsConfirmPostCard:
        "Li enviarem el codi per correu postal, segur que vol fer este tipus de verificació?",
    verificationsCreate: "Introduïsca les dades per a afegir ",
    verificationsAlias: "Àlies",
    verificationsCountry: "País o regió",
    verificationsDirection: "Adreça",
    verificationsPostalCode: "Codi postal",
    verificationsRegion: "Província/Regió",
    verificationsTown: "Població",
    verificatinosDeleteElement: "Vol eliminar l’element?",
    verificationsVerifiedData: "Dades verificades",
    verificationsEMA: "Adreces electròniques verificades",
    verificationsMOV: "Telèfons verificats",
    verificationsNDO: "Documents verificats",
    verificationsALI: "Adreces verificades",
    verificationsEmpty: "Cap",
    verificationsUpgrade: "Aumentar nivel de verificación",
    verificationsAddEmail: "Adreça electrònica afegida correctament",
    verificationsVerifyEmail: "Adreça electrònica verificada correctament",
    verificationsDeleteEmail: "Adreça electrònica eliminada correctament",
    verificationsAddPhone: "Telèfon afegit correctament",
    verificationsVerifyPhone: "Telèfon verificat correctament",
    verificationsDeletePhone: "Telèfon eliminat correctament",
    verificationsAddAddress: "Dirección añadida correctamente",
    verificationsVerifyAddress: "Dirección verificada correctamente",
    verificationsDeleteAddress: "Dirección eliminada correctamente",
    verificationsDeleteDocument: "Documento eliminado correctamente",
    verificationsDeleteAddressTooltip: "Eliminar dirección",
    verificationsDocuments: "Documentació",
    verificationsemail: "Adreça electrònica",
    verificationsphone: "Telèfon",
    verificationsaddress: "Adreça",
    verificationsnumber: "Document identificatiu",
    verificationsByOtp: "Verificat per codi",
    verificationsByForm: "Verificat per format",
    verificationsReSendOtp: "Codi reenviat",
    verificationDisableAccountTitle:
        "Si us dona de baixa de Correos ID deixarà de tindre accés a tots els servicis que es mostren a continuació:",
    verificationDisableAccountList: "Esborrarem el seu compte*.",
    verificationDisableAccountP1:
        "Li aconsellem que abans d’eliminar el compte de Correos ID sol·licite i finalitze la baixa en els servicis en què està registrat. Per a això, seleccione l’opció Cancel·lar. Si tot i això continua preferint eliminar ara el seu compte de Correos ID, clique “Donar de baixa”.",
    verificationDisableAccountP2:
        "*Tranquil, té {disableAccDays} dies per a recuperar el compte de Correos ID, per si se’n penedix. Podrà recuperar el compte de Correos ID, però la resta de servicis poden no guardar les seues dades durant estos {disableAccDays} dies.",
    verificationDisableAccountP3:
        "Correos ID comunicarà l’eliminació del compte als servicis associats, però l’eliminació del compte en cada servici no és responsabilitat de Correos ID",
    verificationDisableAccountConfirm: "Donar de baixa",
    verificationsWrongTipeDocument: "El tipus de document no és vàlid",
    verificationsUploadSuccess: "Document pujat correctament",
    verificationsDeleteSuccess: "Document eliminat correctament",
    verificationsNoUploaded: "No pujat",
    verificationsEmailExists: "L’adreça electrònica introduïda ja existix",
    verificationsPhoneExists: "El telèfon introduït ja existix",
    verificationsUnifyUserTitle: "Atención",
    verificationsUnifyUserText: "Hemos detectado que el {dataType} que estás intentando verificar ya existe en otra cuenta. ¿Es este {dataType} ({data}) realmente tuyo?. Si es así pulsa en 'Aceptar' para unificar ambas cuentas",
    verificationsPageSubtitle: "Puedes gestionar y verificar tus datos, así como cambiar tu contraseña de acceso",
    verificationsEnterprisePageSubtitle: "Puedes gestionar y verificar los datos de la empresa",
};
