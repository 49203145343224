import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getApplicationOid, getSessionItem } from "../../services/Commons";
import history from "../../services/history";
import { sessionValues } from "../../commons/EnumsGeneral";

// handle the public routes
function PublicRoute({ component: Component, ...rest }) {
  let auxLogged = getSessionItem(sessionValues.logged);
  const controlUserMovement = () => {
    return (!auxLogged || (auxLogged && getApplicationOid() != process?.env?.REACT_APP_APP_OID));
  }
  return (
    <Route
      {...rest}
      history={history}
      render={(props) => controlUserMovement() ? <Component {...props} /> : <Redirect to='/Index' />}
    />
  );
}

export default PublicRoute;
