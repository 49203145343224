import React from "react";
import { Grid, Button, ButtonGroup, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { SwitchYesNoButtonStyles } from "./SwitchYesNoButtonStyles";

export function SwitchYesNoButton(props) {
    const classes = SwitchYesNoButtonStyles();
    const { t, i18n } = useTranslation();

    const { checked, code, handleSwitchButtons, disabled } = props;

    return (
        <>
            <Typography className={classes.switchButtonLabel}>{checked ? t("Activo") : t("Inactivo")}</Typography>
            <ButtonGroup disableElevation >
                <Button
                    color={checked ? "primary" : null}
                    variant={checked ? "contained" : null}
                    className={classes.switchButton}
                    name={code + "yes"}
                    onClick={() => handleSwitchButtons(code, true)}
                    disabled={disabled}
                >
                    {t("yes")}
                </Button>
                <Button
                    color={!checked ? "secondary" : null}
                    variant={!checked ? "contained" : null}
                    className={!checked ? classes.switchButtonNoActive : classes.switchButton}
                    name={code + "no"}
                    onClick={() => handleSwitchButtons(code, false)}
                    disabled={disabled}
                >
                    {t("no")}
                </Button>
            </ButtonGroup>
        </>

    );
}
