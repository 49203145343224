import { makeStyles } from "@material-ui/core/styles";

export const UserPrivacyStyles = makeStyles((theme) => ({
  mainContainer: {    
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0em !important",
      paddingRight: "0em !important",
    },
  },
  cardContainer: {
    paddingBottom: "1em",
  },
  cardFile: {
    alignItems: "center",
  },
  agreeButtonsLeft: {
    margin: "2px",
    border: "2px solid #FFCD00",
    "&:hover": {
      border: "2px solid #FFCD00",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "75%",
    },
  },
  buttonLink: {
   
    [theme.breakpoints.up("sm")]: {
      marginRight:"0em"
    },
  },
  title: {
    
    marginTop: "1em",
    marginBottom: "1em",
    color: "#002453",
  },

  titleColumn: {
    color: "#002453",
    fontSize:"120%",
    [theme.breakpoints.down("sm")]: {
      fontSize:"80%",
      
    }

  },
  permissionsText: {
    color: "#666666",
    textAlign: "center",
    marginBottom: "1em",
  },
  privacyText: {
    color: "#666666",
    marginBottom: "1em",
  },
  signedText: {
    fontSize: "90%",
    textAlign: "left",
    color: "#666666",
    marginRight:"1em",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em !important",
      marginRight:"0em !important"
    }
  },
  agreeConditionsTermsConsent: {
    marginTop:"auto",
    marginBottom:"auto",
    fontSize: "110%",
    textAlign: "left",
    color: "#666666",
    paddingLeft:"1em",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em !important",
    },
  },
  switchButton: {
    width: "3em",
  },
  switchButtonNoActive: {
    width: "3em",
    color: "white",
  },

  media: {
   height:"100%",
   width:"auto"
    
  },
  imgCardMedia: {
    width: "auto",
    height: "12em"
  },
  privacyTitleCard:{
    backgroundColor:"#f3f2f2",
    borderRadius: "2em",
    marginLeft: "5em",
    marginRight: "5em",
    marginBottom: "3em",
  },
  privacyTitleCardTextContainer:{
    marginTop:"auto",
    marginBottom: "auto",
    paddingRight: "5em",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0em !important",
      marginBottom: "1em"
      
    }
  },
  privacyTitleCardText:{
    
    fontSize: "120%",
    textAlign: "left",
    color: "#666666",
    marginRight:"2em",
    [theme.breakpoints.down("sm")]: {
      marginLeft:"1em",
      marginRight:"1em !important",
      paddingRight: "0em !important",
      
    }

  },
  rgpdTitle:{
    marginBottom: "2em"
  },
  introText: {   
    fontSize: "90%",
    textAlign: "left",
    color: "#666666",
  },
  rgpdTitleText: { 
    color: "#333333",
    marginTop:"auto",
    marginBottom:"auto",
    marginLeft:"1em",
    fontSize:"150%"
  },
  rgpdTitleTextContainer:{
    textAlign:"left", 
    marginTop:"auto",
    marginBottom: "auto",
    marginLeft: "1em"
  },
  rgpdTitleCheckBoxContainer:{
    alignItems:"right", 
    marginTop:"auto",
    marginBottom: "auto"
  }, 
  rgpdTitleCheckBox:{
    verticalAlign: "middle",
    float: "right"
  },
  rgpdTitleCheckBoxLabel:{
    display: "block",
    overflow: "hidden",
    width: "120px"
  },
  divider:{   
    backgroundColor: "#FDCD00", 
    marginRight:"1em",
    marginTop:"1em", 
    marginBottom: "1em",
    [theme.breakpoints.down("sm")]: {
      marginLeft:"1em",
      
    }
  },
  imgBackground: {
    borderRadius:"5em",
    backgroundColor: "#f3f3f3",   
    
  },
  imgIcon: {
    marginTop:"auto",
    marginBottom:"auto",
    height:"1.5em",
    width: "auto",
    overflow:"auto",
    color: "#002453",   
    borderRadius: "50%",
    backgroundColor: "#f3f2f2",
    border: "7px solid #f3f2f2"
  },
  imgLogin: {
    maxWidth: "2.0em",
    height: "auto",
  },
  containerMargin:{
    marginLeft: "5em",
    marginRight: "5em",
    [theme.breakpoints.down("sm")]: {
      marginLeft:"1em !important",
      marginRight:"1em !important",
      
    }
  },
  checkBoxLabel:{
    color: "#666666",
    fontSize:"80%"
  },
  buttonLabel:{
    textAlign:"right",
    color: "#666666",
    fontSize:"80%",
    marginTop:"auto",
    marginBottom:"auto"
  },
  
  evenRow:{
    backgroundColor:"#f3f2f2",
    borderRadius: "0.5em",
    paddingRight:"1em" 
    
  },
  oddRow:{       
    paddingRight:"1em" 
  },
  categoryDivider:{
    width:"100%",
    backgroundColor: "#666666", 
    marginTop:"3em",
    marginBottom:"3em"
  },

}));
