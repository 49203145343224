import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Typography,
  IconButton,
  Card,
  CardMedia,
  CircularProgress
} from "@material-ui/core";
import { EnterprisePrivacyStyles } from "./EnterprisePrivacyStyles";
import {
  GetUtilitiesCorreosIdSubscriptionEnterpriseTycPrivacyUrls,
} from "../../services/UtilitiesCallsService";
import { BiLinkExternal } from "react-icons/bi";
import { Alerts } from "../Alerts/Alerts";
import { checkIsNullUndefined, getErrorMessage } from "../../commons/Utils";
import { useTranslation } from "react-i18next";
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import privacyLogo from "../../assets/images/privacidad.svg";

export function EnterprisePrivacy(props) {
  const {
    idEnterprise
  } = props;
  const classes = EnterprisePrivacyStyles();
  const { t, i18n } = useTranslation();

  const [enterpriseSignTyC, setEnterpriseSignTyC] = useState([]);
  const [circularPermissionsProgress, setCircularPermissionsProgress] =
    useState(false);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });

  useEffect(() => {
    getServices();
  }, [idEnterprise]);

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  const getServices = () => {

    setCircularPermissionsProgress(true);

    GetUtilitiesCorreosIdSubscriptionEnterpriseTycPrivacyUrls(idEnterprise).then((response) => {
      if (response && !response.Status) {
        setEnterpriseSignTyC(response);
      } else {
        finishOperation("error", getErrorMessage(response, t));
      }
      setCircularPermissionsProgress(false);
    });
  };

  const formatDate = (timeStamp) => {

    var date = timeStamp.split("T");
    var year = date[0].split("-")[0];
    var month = date[0].split("-")[1];
    var day = date[0].split("-")[2];
    return (day + "/" + month + "/" + year);
  };


  const isEvenRow = (index) => {

    return index % 2 == 0;
  };

  const showSignedText = (service, option) => {
    return <Grid item
      xs={4}
      md={4}
      lg={4}
      style={{ display: "flex", textAlign: "right", alignItems: "center" }}
    >
      <Grid container style={{ textAlign: "right", alignItems: "center" }}>


        {option == "tyc" ?
          service.tyc.map((tyc) => {
            if (!checkIsNullUndefined(tyc)) {
              return (
                <Grid container item xs={12} display="flex" style={{ textAlign: "right", alignItems: "center" }}>

                  <Grid item xs={12} md={11} >
                    <span className={classes.signedText}>{t("privacySignedTyCPrivacy") + " " + formatDate(tyc.dateSignTyc)}</span>
                  </Grid>

                  <Grid item xs={12} md={1} style={{ textAlign: "right", alignItems: "center" }}>
                    <IconButton
                      className={classes.buttonLink}
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      href={tyc.urlTyc}
                      target="_blank"
                      rel={"noopener noreferrer"}>
                      <BiLinkExternal />
                    </IconButton>

                  </Grid>
                </Grid>
              )
            } else {
              return ("")
            };
          }) : option == "privacy" ?
            service.privacy.map((privacy) => {
              if (!checkIsNullUndefined(privacy)) {
                return (
                  <Grid container item xs={12} display="flex" style={{ textAlign: "right", alignItems: "center" }}>

                    <Grid item xs={12} md={11}>
                      <span className={classes.signedText}>{t("privacySignedTyCPrivacy") + " " + formatDate(privacy.dateSignPrivacy)}</span>
                    </Grid>
                    <Grid item xs={12} md={1} style={{ textAlign: "right" }}>
                      <IconButton
                        className={classes.buttonLink}
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        href={privacy.urlPrivacy}
                        target="_blank"
                        rel={"noopener noreferrer"}>
                        <BiLinkExternal />
                      </IconButton>

                    </Grid>
                  </Grid>
                )
              } else {
                return ("")
              };
            }) : ""
        }
      </Grid>
    </Grid>
  };

  return (
    <Grid>
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      <Grid className={classes.container}>
        <Container maxWidth={"lg"} className={classes.mainContainer}>
          <Card className={classes.cardContainer}>
            <Grid container spacing={0} >


              {/* Page Title*/}
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  className={classes.title}
                  noWrap
                >
                  {t("mainPagePrivacity")}
                </Typography>

              </Grid>
              {/* Title Card */}
              <Grid container item xs={12} className={classes.privacyTitleCard} >
                <Grid item xs={12} md={3}>
                  <CardMedia className={classes.media}>
                    <img
                      className={classes.imgCardMedia}
                      src={privacyLogo}
                      alt="Logo"
                    />
                  </CardMedia>
                </Grid>
                <Grid item xs={12} md={9} className={classes.privacyTitleCardTextContainer}>
                  <Typography className={classes.privacyTitleCardText}>
                    {t("privacyIntroText")}
                  </Typography>

                </Grid>
              </Grid>
              {/*Tycs/Privacies */}
              <Grid container item xs={12} className={classes.containerMargin}>
                <Grid container item xs={12} className={classes.rgpdTitle}>
                  <Grid item xs={12} md={6} style={{ display: "flex" }}>

                    <FindInPageOutlinedIcon className={classes.imgIcon}> </FindInPageOutlinedIcon>
                    <Typography className={classes.rgpdTitleText}>
                      {t("privacyTyCLink")}
                    </Typography>
                  </Grid>

                </Grid>
                <Grid container item md={12} xs={12}>
                  <Grid item md={4} xs={4} style={{ textAlign: "left" }}>
                    <Typography
                      variant="h6"
                      className={classes.titleColumn}
                    >
                      {t("privacyMyServices")}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={4} style={{ textAlign: "right" }}>
                    <Typography
                      variant="h6"
                      className={classes.titleColumn}
                    >
                      {t("privacyTyCLink")}
                    </Typography>
                  </Grid>

                  <Grid item md={4} xs={4} style={{ textAlign: "right" }}>
                    <Typography
                      variant="h6"
                      className={classes.titleColumn}
                    >
                      {t("privacyPrivacyLink")}
                    </Typography>
                  </Grid>
                </Grid>
                {circularPermissionsProgress ? <CircularProgress></CircularProgress> : (<Grid item md={12} xs={12} style={{ marginBottom: "5em" }}>
                  {enterpriseSignTyC.map((service, index) => {

                    return (

                      <Grid
                        container
                        className={!isEvenRow(index) ? classes.evenRow : classes.oddRow}
                      >
                        <Grid item md={4} xs={4} className={classes.agreeConditionsTermsConsent}>

                          <span>{service.application} </span>

                        </Grid>
                        {showSignedText(service, "tyc")}
                        {showSignedText(service, "privacy")}

                      </Grid>

                    );
                  })}

                </Grid>)}


              </Grid>
            </Grid>
          </Card>
        </Container>
      </Grid>
    </Grid>
  );

}