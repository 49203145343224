export const EUTYC1 = {

    //TyCs eta Pribatutasun Orria

    tycUrlsText: "Mesedez irakurri arretaz erabilera-baldintzak ondoko esteketan klik eginez:",
    privacyUrlsText: "Mesedez, irakurri arretaz pribatutasun-politikak esteka hauetan klik eginez:",
    privacyNoUrlsText: "Para la aplicación y el tipo de persona indicados no existe privacidad que firmar.",
    tycNoUrlsText: "Para la aplicación y el tipo de persona indicados no existen TyC's que firmar.",
    atcText: "ATC operadoreari egiaztapen-kodea jakinarazi ondoren, erabilera-baldintzak onartutzat joko dira.",

    //Terms and Conditions

    tycHeaderTitle: "Baldintzak",

    //WarningLegalTyC Subtittles

    tycWarningLegalSubtittle1:
        "JABETZA INTELEKTUALEKO ETA INDUSTRIALEKO ESKUBIDEAK",
    tycWarningLegalSubtittle2: "IZAERA PERTSONALEKO DATUEN BABESA",
    tycWarningLegalSubtittle3: "SEGURTASUN-NEURRIAK",
    tycWarningLegalSubtittle4: "CORREOS-EN ERANTZUKIZUNAK",
    tycWarningLegalSubtittle5: "ERABILTZAILEAREN ERANTZUKIZUNAK",
    tycWarningLegalSubtittle6: "LEGE APLIKAGARRIA ETA JURISDIKZIOA",

    //WarningLegalTyC texts

    tycWarningLegalText1:
        "Informazio Gizartearen eta Merkataritza Elektronikoaren Zerbitzuei buruzko uztailaren 11ko 34/2002 Legearen 10 artikuluak ezartzen duenari jarraiki, jakinarazten da webgune honen titularra Correos dela.",
    tycWarningLegalText2:
        "Dokumentu honetan aurkituko duzu “Correos ID”-ren Lege Oharra. Informazio hori Correos y Telégrafos Estatuko Sozietatea, S.A.ak eman du (aurrerantzean, Correos), egoitza soziala Vía Dublín nº 7 (Campo de las Naciones) 28070 Madril (Espainia) duenak eta IFK A83052407 duenak.",
    tycWarningLegalText3:
        "Zerbitzu honen bezeroarentzako arreta-zerbitzuarekin harremanetan jar zaitezke Correos-en Bezeroarentzako Arreta Zerbitzuaren bidez, zenbaki honetan: 902.197.197 edo Correos-en webgunearen bidez.",
    tycWarningLegalText4:
        "Atariaren diseinua eta bertako iturri-kodeak, hala nola, logoak, markak eta bertan ageri diren zeinu bereizgarriak, Correos-enak dira eta berari dagozkion jabetza intelektualeko eta industrialeko eskubideek babesten dituzte.",
    tycWarningLegalText5:
        "Horiek erabiltzea, kopiatzea, zabaltzea, publikoki jakinaraztea, helaraztea edo antzeko ekintzak, guztiz debekatuta daude, Correos-ek horretarako idatziz baimenik eman ez badu.",
    tycWarningLegalText6:
        "Atariko edukiak, era berean, Correos-en jabetza intelektualeko eskubideek babesten dituzte. Correos-ek edo bere Taldeko edozein entitatek egindako edukiak erabiltzeko aukera bakarra, Correos-ekin edo aipatu entitateekin kontratu bat sinatuta izatea da.",
    tycWarningLegalText7:
        "Correos-ek hirugarrenen jabetza intelektualeko eta industrialeko eskubideak errespetatzen dituela aitortzen du. Horregatik, gune honetan zure eskubideak urratzen direla uste baduzu, mesedez, jarri harremanetan Correos-ekin hurrengo telefono-zenbakian: 902.197.197.",
    tycWarningLegalText8_1:
        "Gure webgunean nabigatzeko ez da beharrezkoa aldez aurretik erregistratzea. Nolanahi ere, webgunean sartzen zarenean, informazioa gordetzen dute gure web-zerbitzariek, modu estandarrean, adibidez IP helbidea, zein domeinutik sartu den eta bisitaren data eta ordua. Beste alde batetik, webguneko zenbait funtzionaltasun erabili ahal izateko, informazioa eman beharko diguzu kasuan kasuko formularioaren bitartez (esaterako, izen-abizenak, harremanetarako posta-helbidea edo helbide elektronikoa, telefonoa...). Correos-ek izaera pertsonaleko datuak ",
    tycWarningLegalText8_B: "Webgunearen Pribatutasun-politikari ",
    tycWarningLegalText8_2:
        "jarraiki tratatuko ditu eta, hala badagokio, baita eskatu diren zerbitzuei aplikagarri zaizkien Datuak Babesteko Politikari jarraiki ere.",
    tycWarningLegalText9:
        "Honen bidez, jakinarazten dizugu izaera pertsonaleko datuak babesteko beharrezko segurtasun-neurri guztiak hartu direla, teknikoak, antolakuntzakoak eta segurtasun informatikokoak, datu horiek aldatu, galdu, tratatu eta/edo eskuratu ez daitezen baimenik gabe, eta guzti hori aplikagarri den araudian ezarritakoari jarraiki.",
    tycWarningLegalText10:
        "Atariaren erabilera desegokia: Correos-ek Atari hau sortu du bere produktuak promozionatzeko eta bere zerbitzuak errazago erabili ahal izateko, baina ezin du kontrolatu erabilera Lege Ohar honetan aurreikusitakoa ez den beste modu batean. Beraz, Atarian sartzea eta bertan dagoen informazioa behar bezala erabiltzea halakoak egiten dituenaren erantzukizuna da eta, ondorioz, Correos ez da erantzule Erabiltzaileak gaizki, legez kanpo edo arduragabe erabiltzen badu.",
    tycWarningLegalText11:
        "Edukien erabilera: Correos-ek bere Atariko (Correos ID) eduki guztiak fede onez jartzen ditu guztien eskura eta posible zaizkion ahalegin guztiak egingo ditu eduki horiek eguneratuta eta indarrean egon daitezen beti; hala ere, Correos-ek ez du inolako erantzukizunik Atariaz kanpo Erabiltzaileek egiten duten erabilera edo eskuratzeen inguruan, ezta Atariaren bidez ikus daitezkeen iritzi, gomendio edo azterketen aplikazio praktikoak eragin ditzakeen ondorioen gainean ere, azken erantzukizuna Erabiltzailearena izango da eta. Halaber, Correos-ek ezingo ditu kontrolatu berak edo Correos-en aginduz hirugarrenek egin ez dituzten edukiak eta, beraz, ez da hirugarrenen edukiek eragin ditzaketen inolako kalteren erantzule izango.",
    tycWarningLegalText12:
        "Publizitatea: Correos-ek publizitate propioa edo hirugarrenena barne hartuko du Atarian zuretzako interesgarriak izan daitezkeen produktuak edo zerbitzuak eskaintzeko. Hala ere, Correos-ek ezingo du kontrolatu aipatu publizitatearen itxura, ezta aipatu produktu edo zerbitzuen kalitatea edo egokitasuna ere eta, ondorioz, Correos ez da horren ondorioz erabiltzaileari eragin diezaiokeen inolako kalteren erantzule izango.",
    tycWarningLegalText13:
        "Birusak: Correos-ek konpromisoa hartu du Erabiltzaileari Atarian birus informatikorik edo antzeko elementurik ez dagoela bermatu ahal izateko beharrezko neurri guztiak aplikatzeko. Hala ere, neurri horietan hutsuneak egon litezke eta, horregatik, Correos-ek ezin du guztiz bermatu aipatu elementu kaltegarririk ez dagoenik. Beraz, Correos ez da Erabiltzaileari eragin diezaioketen aipatu kalteen erantzule izango.",
    tycWarningLegalText14:
        "Akats teknologikoak: Correos-ek Atariak aurrera jarraitzeko behar diren kontratu guztiak gauzatu ditu eta ahalegin guztiak egingo ditu etenik ez egoteko, baina ezin du bermatu akats teknologikorik ez denik egongo, ezta Ataria eta bertako produktu eta zerbitzuak etengabe erabilgarri egongo direnik ere eta, horren ondorioz, ez du inolako erantzukizunik erabilgarritasun ezak eta Correos-i egotzi ezin zaizkion deskonexio, matxura, gainkarga edo aldizkako sarearen ez-funtzionamenduak eragin ditzaketen kalte-galeran gainean.",
    tycWarningLegalText15:
        "Informazio-trukea: Erabiltzaileek esperientziak argitaratzeko eta informazio-trukea sustatzeko pentsatu diren hainbat gune jakin egon litezke Atarian. Hala ere, Correos-ek topagune gisa hartzen ditu soilik foro horiek eta ez ditu kontrolatzen bertan argitaratzen diren adierazpenak. Beraz, ez du inolako erantzukizunik Erabiltzaileek bertan esandakoen egiatasun edo egokitasunaren gainean.",
    tycWarningLegalText16:
        "Erabiltzaileak konpromisoa hartzen du Atariaren zerbitzuak Lege Ohar honetan adierazitako baldintzen baitan erabiltzeko, eta erabiltze hutsak hori onartzea esan nahiko du.",
    tycWarningLegalText18:
        "Correos-en irudia, izen ona edo ospearen kontra jarduten duen Erabiltzailea, baita Atariko diseinu, logo edo edukiak legez kanpo edo iruzur egiteko erabiltzen dituen oro, jarduera horien guztien erantzule izango da Correos-en aurrean.",
    tycWarningLegalText19:
        "Lege Ohar honetan ezartzen diren baldintzak interpretatzerakoan egon litezkeen eztabaida edo gatazken aurrean, edo Atari honetako produktu edo zerbitzuekin zerikusia duen edozein gairen aurrean Espainiako legea aplikatuko da, baldin eta kontsumitzaile eta erabiltzaileen babeserako arauek beste legedi bat aplikatzea ezartzen ez badute.",
    tycWarningLegalText20:
        "Alderdien ebazpenak, dagokien Espainiako probintziaren hiriburuko epaitegi eta auzitegien pean egongo dira.",

    //PrivacyPoliticyTyC Subtittles

    tycPrivacyPoliticyTyCSubtittle1: "Tratamenduaren arduraduna",
    tycPrivacyPoliticyTyCSubtittle2: "Tratamenduaren helburua eta legitimazioa",
    tycPrivacyPoliticyTyCSubtittle3: "Tratatuko diren datu-motak",
    tycPrivacyPoliticyTyCSubtittle4: "Datuak gordetzeko epea",
    tycPrivacyPoliticyTyCSubtittle5: "Zure eskubideak",
    tycPrivacyPoliticyTyCSubtittle6: "Hirugarrenentzako sarbidea",

    //PrivacyPoliticyTyC list tittles

    tycPrivacyPoliticyTyCListTittle1: "Correos ID zerbitzuak",
    tycPrivacyPoliticyTyCListTittle2:
        "Correos-en produktu eta zerbitzuen eskaintzen eta promozioen bidalketa",
    tycPrivacyPoliticyTyCListTittle3:
        "Correos Taldeko beste enpresa batzuen eta hirugarrenen eskaintzen eta promozioen bidalketa",
    tycPrivacyPoliticyTyCListTittle4:
        "Correos Taldeko beste enpresa batzuei eta hirugarrenei datuak jakinaraztea",
    tycPrivacyPoliticyTyCListTittle5: "Lege-betebeharren betetzea",
    tycPrivacyPoliticyTyCListTittle6:
        "Informazio estatistikoa eta gogobetetze-inkestak",
    tycPrivacyPoliticyTyCListTittle7: "Informazioa aberastea",
    tycPrivacyPoliticyTyCListTittle8: "Zuzenean emandako datuak:",
    tycPrivacyPoliticyTyCListTittle9: "Zeharka bildutako datuak:",
    tycPrivacyPoliticyTyCListTittle10:
        "Zerbitzua ematearen ondorioz biltzen diren datuak:",

    //PrivacyPoliticyTyC texts

    tycPrivacyPoliticyTyCText1:
        "Correos Taldearen enpresa guztientzat, berebiziko garrantzia du, batetik, zure pribatutasuna errespetatzeak eta, bestetik, datuak babesteko araudia betetzeak.",
    tycPrivacyPoliticyTyCText2_1:
        "Une oro datuak zertarako erabiltzen ditugun edo zertarako erabili nahi ditugun jakin dezazun nahi dugu, baita zure eskubideak ezagut ditzazun ere. Helburu honekin, Datuak Babesteko Politika hau idatzi dugu, Correos ID zerbitzuetatik datozen datuen tratamendua arautzen duena, baita id.correos.post webgunearen bidezko nabigazioa ",
    tycPrivacyPoliticyTyCText2_B1: "",
    tycPrivacyPoliticyTyCText2_2: " (lehen ",
    tycPrivacyPoliticyTyCText2_B2: "emiidentidad.correos.post",
    tycPrivacyPoliticyTyCText2_3:
        " ) eta Pribatutasun Politika hau ezartzen den footer-a duen Correos-en beste azpi-domeinu edo webguneetan egiten dena ere",
    tycPrivacyPoliticyTyCText3:
        "Hau guztia aldatu ahal izango da, etorkizunean izan litezkeen legegintza- edo jurisprudentzia-berrikuntzetara zein industriaren jardunbideetara egokitzeko. Edonola ere, aldaketarik izanez gero, Erabiltzaileari jakinaraziko zaio onartu duen jakinarazpen-bidea erabiliz.",
    tycPrivacyPoliticyTyCText4:
        "Hau da tratamenduaren arduraduna: “Sociedad Estatal Correos y Telégrafos SA SME” (aurrerantzean, “Correos”); IFK: A-83052407; egoitza soziala: Vía Dublín 7 (Campo de las Naciones) 28070 Madril (Espainia).",
    tycPrivacyPoliticyTyCText5:
        "Correos-ek Datuak Babesteko Ordezkari bat du (DBO edo DPO) tratamendua ondo kudeatzen dela bermatzeko, eta harengana zuzen zaitezke gai horri buruzko edozein auzitarako, helbide elektroniko honetara zuzenduta: ",
    tycPrivacyPoliticyTyCText6:
        "Zure datuak honako helburu hauetarako tratatuko dira:",
    tycPrivacyPoliticyTyCText7: "Zure datuak tratatuko dira",
    tycPrivacyPoliticyTyCText8:
        "erabiltzaile gisa erregistroa izapidetzeko eta alta horretarako ezarri den inprimakiaren bidez burutzeko.",
    tycPrivacyPoliticyTyCText9:
        "Hainbat zerbitzu digitaletan Correos ID-ren bidez alta emateko, baliteke informazio gehigarria eskatzea horretarako den web inprimakiaren bidez.",
    tycPrivacyPoliticyTyCText10:
        "“Correos ID” zerbitzuak emateko, Zerbitzuaren Baldintza Partikularrei jarraiki.",
    tycPrivacyPoliticyTyCText11:
        "Ildo horretan, Correos ID identitate digitaleko kudeatzaile zentralizatua da, eta horrek aukera ematen dio erabiltzaileari Correos-en zerbitzu digitaletan eta/edo identifikazio-sistemaren metodo hau onartzen duten hirugarrengoen zerbitzuetan autentifikatzeko; hau da, laburtze aldera, erabiltzaileak identifikazio digital bakarra eginez sartu ahal izango da zerbitzuetara eta, horrela, ez du zertan eduki pasahitz bat zerbitzu bakoitzerako. ",
    tycPrivacyPoliticyTyCText12:
        "Correos ID zerbitzua identifikazio-plataforma digital bezala erabiliz gero Correos Taldearen beste enpresa batzuek edo hirugarren partaideek ematen dituzten zerbitzuen aurrean, erabiltzailearen datuak enpresa horiei jakinarazi behar zaizkie eskatu den identitate-egiaztapena egin ahal izateko.",
    tycPrivacyPoliticyTyCText13:
        "Zure zalantza, kontsulta edo kexei erantzuteko.",
    tycPrivacyPoliticyTyCText14:
        "Zure identitatearen egiaztapena izapidetzeko, hirugarren batekin sinatutako lankidetzarako eta/edo Correos-en zerbitzuak emateko akordio baten ondorioz.",
    tycPrivacyPoliticyTyCText15:
        "Era berean, nortasunaren kudeatzaile digital zentralizatuaren bidez zure identitatearen egiaztapena izapidetu ahal izango duzu, hirugarren batekin sinatutako lankidetzarako eta/edo Correos-en zerbitzuak emateko akordio baten ondorioz.",
    tycPrivacyPoliticyTyCText16:
        "Baimena emanez gero, informazio komertziala helarazi ahal izango dizu Correos-ek gure produktuak eta zerbitzuak emateari, hobetzeari eta eguneratzeari buruz, eta tarifa berriei eta deskontuei buruz, edozein bide elektroniko nahiz ez-elektronikoren bitartez.",
    tycPrivacyPoliticyTyCText17:
        "Generikoa zein pertsonalizatua izan daiteke aipatu informazio komertziala. Azken kasu horretan, aldez aurretik tratatu beharko ditu Correos-ek zure datuak, honako xede hauetarako: zure profila egin eta zorroan sartzeko eta, zure zaletasunak, lehentasunak eta premiak oinarri, interesgarrien suerta dakizkizukeen eskaintzak identifikatzeko.",
    tycPrivacyPoliticyTyCText18:
        "Baimena emanez gero, Correos-ek eskaintzak eta promozioak helarazi ahal izango dizkizu edozein bide elektroniko nahiz ez-elektronikoren bitartez. Adibidez,",
    tycPrivacyPoliticyTyCText19:
        "Correos Taldeko beste enpresa batzuenak, sektore hauetan dihardutenak, besteak beste: paketeria-zerbitzuak, posta-zerbitzuaren balio erantsia, logistika, marketina eta telekomunikazioa.",
    tycPrivacyPoliticyTyCText20:
        "Correos-ekin lankidetza-hitzarmenak sinatu dituzten beste enpresa batzuenak, sektore hauetan dihardutenean, besteak beste: telekomunikazioan, finantzetan, komenentziako produktuetan, osasunean eta GKE-etan.",
    tycPrivacyPoliticyTyCText21:
        "Generikoa zein pertsonalizatua izan daiteke aipatu informazio komertziala. Azken kasu horretan, aldez aurretik tratatu beharko ditu Correos-ek zure datuak, honako xede hauetarako: zure profila egin eta zorroan sartzeko eta, zure zaletasunak, lehentasunak eta premiak oinarri, interesgarrien suerta dakizkizukeen eskaintzak identifikatzeko.",
    tycPrivacyPoliticyTyCText22:
        "Baimena emanez gero, Correos-ek aukera izango du bere Taldeko enpresei eta arestian zehaztutako sektoreetako hirugarren enpresei zure datuak jakinarazteko, enpresa horiek beren produktu eta zerbitzuei buruzko eskaintzak eta promozioak helarazi ahal diezazkizuten, zuzenean eta Correos bitarteko izan gabe.",
    tycPrivacyPoliticyTyCText23:
        "Correos-ek hainbat lege-betebehar hartzen ditu bere gain. Horien artean, posta-araudia (43/2010 Legea, Posta Zerbitzu Unibertsalari, erabiltzaileen eskubideei eta posta-merkatuari buruzkoa), jakinarazpenak isilpean gordetzearen betebeharra (Konstituzioaren 18. artikulua) eta zerga-araudia aplikatzetik eratorritakoak daude.",
    tycPrivacyPoliticyTyCText24:
        "Betebeharrok betetze aldera, Correos-ek aukera izango du zure datuak Administrazio Publikoei eta auzitegiei jakinarazteko, baldin eta ezarritako lege-prozesuen arabera eskatzen bada aipatu informazioa.",
    tycPrivacyPoliticyTyCText25:
        "Correos-ek interes legitimoa du bezeroek produktuei eta zerbitzuei buruz zer uste duten jakiteko eta beren kontsumo-ohiturak ezagutzeko (esate baterako, zerbitzuak zenbatetik behin kontratatzen diren), haien beharretara hobeto egokitzen diren produktuak eta zerbitzuak eskaini eta egungo produktuak eta zerbitzuak nahiz horiek kontratatzeko prozesua hobetze aldera.",
    tycPrivacyPoliticyTyCText26:
        "Aurreko paragrafoan azaldutako helburua lortzeko, Correos-ek zure datuak tratatu ahal izango ditu, eta:",
    tycPrivacyPoliticyTyCText27:
        "Gogobetetze-inkestak egin, bai telefono bidez nahiz bitarteko elektronikoak erabilita, baita bezero izateari uzten diozunean ere, horren arrazoiak ezagutze aldera.",
    tycPrivacyPoliticyTyCText28:
        "Gogobetetze-inkesta egitean bezeroak Correos Taldeko enpresaren baten produktu eta zerbitzuei buruzko iritzia ematen badu, enpresa horri jakinarazi ahal izango zaizkio inkestaren emaitza eta parte-hartzailearen identifikazio-datuak.",
    tycPrivacyPoliticyTyCText29:
        "Datuak anonimo bihurtuko ditu eta kontsumo-ohituren gaineko informazioa ez da berriz lotuko pertsonarekin; ondoren, azterketa estatistikoak eta ekonometrikoak egin ahal izango ditu (bezeroaren bizi-zikloa ezagutzeko edota bertan behera uzteko joera-ereduak identifikatzeko, kasu).",
    tycPrivacyPoliticyTyCText30:
        "Zuk baimena emanez gero, Correos-ek zure ezaugarriei, zaletasunei eta premiei buruzko informazio xeheagoa eskuratu ahal izango du, zenbait kanpo-iturrik (hala nola erregistro publikoak, katastroak, sare sozialak, geomarketin-tresnak eta kreditu-informazioko fitxategiak, besteak beste, ASNEF eta BADEXCUG) emandako informazioaz baliatuta, betiere, gure produktu eta zerbitzuak hobetzeko eta/edo zure profilarekin hobekien egokitu eta bat datozen produktu eta zerbitzuen eskaintzak hautatzeko.",
    tycPrivacyPoliticyTyCText31:
        "Lehengo atalean zehaztutako helburuak betetze aldera, hainbat datu pertsonal tratatzen dira, zeinak honako iturri eta kategoria hauetan bana baitaitezke:",
    tycPrivacyPoliticyTyCText32:
        "Erregistratzerakoan, erabiltzaileak egiaztapena eta alta emateko behar den gutxieneko informazioa eman beharko du, eta beraz, kontrakoa adierazi ezean, ez bada behar bezala betetzen, ezingo da zerbitzua eman. Funtzionalitate jakin batzuk (adb. Zerbitzu batzuetarako sarbidea) informazio gehigarria ematea eskatzen dute zerbitzuaren bidez.",
    tycPrivacyPoliticyTyCText33:
        "Halaber, zerbitzuaren bidez emandako informazioarekin batera zerbitzariak bildutako datuak tratatuko dira, adibidez, sartzeko erabili den IP helbidea, sarbidea lortu den domeinua, bisitaldiaren data eta ordua, saioaren cookie-a (azken hau nabigatzailea ixtean ezabatuko da), etab.",
    tycPrivacyPoliticyTyCText34:
        "Bezeroak hitzematen du eskatutako zerbitzuetarako Correos-i emandako datuak benetakoak direla, eta horiei eragiten dien edozein aldaketaren berri emango duela. Bezeroak, hortaz, Correos-en eta hirugarrenen aurrean erantzun beharko du klausula honetan adierazitakoa ez betetzeagatik edozein kalte edo galera eragingo balu.",
    tycPrivacyPoliticyTyCText35_1:
        "Todos aquellos datos que, en caso de haber prestado su consentimiento, Correos recaba de la tipología de fuentes " +
        'externas enumerada en la finalidad "H" del apartado ',
    tycPrivacyPoliticyTyCText35_B: "“Tratamenduaren helburua eta legitimazioa”",
    tycPrivacyPoliticyTyCText36:
        "Arestiko kasuan, hirugarren entitateek, adibidez, Administrazio Publikoek, zuk lan egiten duzun enpresak edo Correos-ekin lankidetzarako eta/edo zerbitzuak emateko akordio bat sinatu duen beste instituzioek, baldin eta identifikazio-sistema hau kontratatu nahi badute, zure datuak jakinarazi edo eman ahal izango dizkigute, zure identitatea egiaztatze aldera. Correos-ekin lankidetzarako eta/edo zerbitzuak emateko akordio bat sinatu duten entitateek identifikazio-sistema desgaitu ahal izango dute, edo sinatutako lankidetzarako eta/edo zerbitzuak emateko akordioetan adierazitako baldintza desberdinak edo gehigarriak aplikatu.",
    tycPrivacyPoliticyTyCText37:
        "Aipatu informazioak hau barne hartuko du: (I) Correos-ekin kontratatutako produktu eta zerbitzuen historikoa, zerbitzu-motak eta ezaugarriak (adb. altan zauden Correos-en Zerbitzu digitalak, zerbitzu horiek erabiltzearen maiztasuna, Correos-en beste zerbitzu batzuen erabilera, hala nola, posta-bidalketak, paketeria, Citypaq...); (ii) Kexak edo informazio-eskaerak, etab. (Iii) Zure nabigazioari buruzko informazioa zure gailuan, baimena eman eta beharrezkoa izanez gero, instalatu daitezkeen cookie-en bidez, gure Cookie-en Politikan ezarritakoari jarraiki.",
    tycPrivacyPoliticyTyCText38:
        "Zure datu pertsonalak Correos ID zerbitzuak emateko beharrezkoak diren bitartean gordeko dira, ezabatzeko eskaera jaso ezean. Identitatea ezabatzeko prozesuak zure Identitatea eta datuak hogeita hamar (30) egunen bitartean blokeatzea edo horren erabilera mugatua dakar, eta aldi horren ostean behin betiko ezabatuko dira. Identitatearen erabilera mugatua den aldian, zure Identitatea berreskuratu ahal izango duzu horretarako den Correos ID prozesuari jarraituz.",
    tycPrivacyPoliticyTyCText39:
        "Correos-ek aktibo ez dauden erabiltzaileak blokeatuko ditu -hau da, Correos-en edo Correos ID identifikazio-plataforma erabiltzen duten beste enpresetako zerbitzu digitaletara sartzen ez direnean- 5 urtez.",
    tycPrivacyPoliticyTyCText40:
        "Zure datuak tratatzea beharrezkoa ez denean, ezabatu egingo dira, datuak babesteko araudian xedatutakoari jarraituta. Hortaz, blokeatu egingo dira, eta epaileek, auzitegiek, Fiskaltzak edo Administrazio Publiko eskudunek eskatuz gero baino ez dira erabilgarri egongo, sor litezkeen ekintzen preskripzio-epeak iraun bitartean. Ondoren, ezabatu egingo dira datuak. Oro har, 3 urte igaro ondoren ezabatuko dira.",
    tycPrivacyPoliticyTyCText41:
        "Datuak babesteko gure araudiak eskubide jakin batzuk ematen dizkizu zerbitzuek berekin dituzten datuen tratamenduari dagokionez. Honela laburbildu litezke eskubideok:",
    tycPrivacyPoliticyTyCText42:
        "- Atzitzeko eskubidea: Zein datu mota tratatzen ari garen eta burutzen ari garen tratamenduak zein ezaugarri dituen jakiteko.",
    tycPrivacyPoliticyTyCText43:
        "- Zuzentzeko eskubidea: Datuak aldatu daitezen eskatzeko, zehatzak edo egiazkoak ez izateagatik.",
    tycPrivacyPoliticyTyCText44:
        "- Eramateko eskubidea: Tratatzen ari diren datuen kopia bat eskuratzeko, formatu elkarreragile batean.",
    tycPrivacyPoliticyTyCText45:
        "- Datuen tratamendua mugatzeko eskubidea, legean jasotzen diren kasuetan.",
    tycPrivacyPoliticyTyCText46:
        "- Ezabatzeko eskubidea: Datuak ezaba daitezen eskatzeko, tratamenduak beharrezkoa izateari uzten dionean.",
    tycPrivacyPoliticyTyCText47:
        "- Aurka egiteko eskubidea: Eskubide honi esker, merkataritza-komunikazioak etetea eska dezakezu, esaterako.",
    tycPrivacyPoliticyTyCText48: "- Emandako baimena baliogabetzeko eskubidea.",
    tycPrivacyPoliticyTyCText49:
        "Zure eskubideak erabil ditzakezu kanal hauetako baten bidez:",
    tycPrivacyPoliticyTyCText50:
        "- Posta Helbidea: Vía Dublín 7 (Campo de las Naciones) 28070 Madrid (España)",
    tycPrivacyPoliticyTyCText51: "- Helbide Elektronikoa: ",
    tycPrivacyPoliticyTyCText52:
        "Halaber, jakinarazpen komertzial elektroniko guztiek baja edo antzekoak egiteko mekanismo bat izango dute eta horrela, aukera izango duzu, nahi izanez gero, jakinarazpen gehiago ez jasotzeko.",
    tycPrivacyPoliticyTyCText53:
        "Datuak Babesteko Espainiako Bulegoaren (DBEB) webgunean eskubideak erabiltzen lagunduko dizuten eredu batzuk aurkituko dituzu. Bestalde, jakinarazten dizugu eskubidea duzula kontrol-agintaritzan (Espainian, DBEB) erreklamazioa jartzeko zure eskubideak urratu direla uste baduzu.",
    tycPrivacyPoliticyTyCText54:
        "Zerbitzu jakin batzuk emateko, baliteke hirugarrenen datu pertsonalak eta harremanetarako datuak eman behar izatea, hala nola izen-abizenak, posta-helbidea, helbide elektronikoa, harremanetarako telefonoa eta mezu nahiz bidalketa baten hartzailearekin harremanetan jartzeko antzeko beste datu edo bitarteko batzuk.",
    tycPrivacyPoliticyTyCText55:
        "Halaber, zerbitzua hobetzeko helburuz, hartzaileari gutxi gorabehera ematen diren zerbitzuen berri emateko eta horiekin loturiko edozein arazo kudeatzeko ere erabili ahal izango dira telefono-zenbakia edo helbide elektronikoa.",
    tycPrivacyPoliticyTyCText56:
        "Horren harira, bezeroak hitzematen du hartzailearen datuak egiazkoak direla eta eguneratuta daudela; hala badagokio, datuak babestearen alorrean egotz dakizkiokeen betebehar guztiak bete dituela ziurtatuko du, hau da, hartzailearen baimena lortu duela Correos-ek aurreko paragrafoetan azaldutako tratamendua burutu dezan, baldin eta aipatu baimena beharrezkoa balitz.",
    tycPrivacyPoliticyTyCText57:
        "Bestalde, Correos-ek identitatea egiaztatzeko zerbitzua emateko beharrezkoak diren datu pertsonalak eskuratuz gero, enpresa edo profesional baten ardurapean egingo da. Correos-ek konpromisoa hartzen du tratamenduaren arduradun izaki lotzen zaizkion betebehar guztiak betetzeko eta, ildo horretan:",
    tycPrivacyPoliticyTyCText58:
        "Argibideei jarraituz atzi ditzakeen datuak atzitu eta tratatuko ditu, eta ez ditu erabiliko zerbitzua ematea ez den beste helburu batzuetarako. Horretarako, hirugarrenen zerbitzuez (azpiarduradunak) baliatu ahal izango da Correos, zerbitzua emateko edo kontratuaren eragiketak burutzeko edota gordetzeko beharrezkoa bada, baldin eta: (i) arduradun berria baldintza berberei lotuta badago (argibideak, betebeharrak, segurtasun-neurriak, etab.) eta Correos-ek dituen betekizun formal berberak baditu, eraginpeko pertsonen datu pertsonalak behar bezala tratatzeari eta beren eskubideak bermatzeari dagokienez; (ii) Correos-ek, berariaz eskatuta, eskuordetutako zerbitzuak eta azpiarduradunen identitatea zehazten diren zerrenda bat jartzen badu zure eskura; eta, (iii) azpiarduradunek adostutakoa bete ezean, Correos-ek guztiz erantzule izaten jarraitzen badu.",
    tycPrivacyPoliticyTyCText59:
        "Arriskuarekin bat datorren segurtasun-maila bermatzeko lege-neurriak, neurri teknikoak eta antolamendu-neurriak ezarriko ditu Correos-ek, DBEOren 32. artikuluan xedatutakoarekin bat. Eskatuz gero, arriskua aztertzeko erabilitako metodologiaren berri emango dizu.",
    tycPrivacyPoliticyTyCText60:
        "Kontratu-prestazioa betetakoan, tratatutako datuak suntsitu edo itzuliko ditu, bai eta tratamenduaren xede diren datuak biltzen dituen euskarri edo dokumentu oro ere; hala ere, ez da bazter utziko datuok behar bezala blokeaturik gordetzeko aukera, datuak babesteko gure araudian ezarritakoa betez.",
    tycPrivacyPoliticyTyCText61:
        "Datuak babesteko araudian jasotako gainerako betebeharrak betetzen direla ziurtatuko du.",
};
