import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, IconButton, Divider, Button, ButtonGroup } from "@material-ui/core";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { GetMasterCookies } from "../../services/MasterCallsService";
import { Cookies } from "react-cookie-banner";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { CustomCookieBannerStyles } from "./CustomCookieBannerStyles";
import { EnumTyC } from "../../pages/TyC/components/EnumTyC";
import { DialogCookiesStyles } from "./Dialogs/DialogCookiesStyles";
import { CommonStyles } from "../../commons/CommonStyles"
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import CloseIcon from "@material-ui/icons/Close";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Constants } from "../../commons/Constants";
import { Alerts } from "../Alerts/Alerts";
import { getErrorMessage } from "../../commons/Utils";


export function CustomCookieBanner(props) {
  const cookies = new Cookies();
  const { t, i18n } = useTranslation();
  const hrefTyCPage = "/TyC/";
  const classes = CustomCookieBannerStyles();
  const dialogClasses = DialogCookiesStyles();
  const commonClasses = CommonStyles();

  const [listaCookies, setListaCookies] = useState([]);
  const [listaPersonalizada, setListaPersonalizada] = useState([]);
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });

  useEffect(() => {
    loadCookies();
  }, []);

  const loadCookies = () => {
    GetMasterCookies().then((response) => {
      if (response && !response.Status) {
        let auxCookiesList = [];
        response.forEach((cookie) => {
          if (cookie.mandatory) {
            auxCookiesList.push({
              name: cookie.name,
              code: cookie.code,
              checked: cookie.mandatory,
              mandatory: cookie.mandatory
            });
          };
        });
        setListaCookies(auxCookiesList);
      } else {
        finishOperation("error", getErrorMessage(response, t));
      };
    })
  };

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const _handleAll = () => {
    let expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 545);
    var values = [];
    listaCookies.forEach((value) => {
      values = values.concat(value.code);
    })
    cookies.set('CookiesAceptadas', values, { path: '/', expires: expireDate });
    props.handleFlag();
  }

  const _handleConfigure = () => {
    setOpen(true);
  }

  const _handleSaveConfigure = () => {
    let expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 545);
    var values = [];
    listaPersonalizada.forEach((value) => {
      if (value.checked) {
        values = values.concat(value.code);
      }
    })
    cookies.set('CookiesAceptadas', values, { path: '/', expires: expireDate });
    props.handleFlag();
  }


  const handleOptions = (value) => {
    let auxLista = listaCookies;
    auxLista.forEach((elem) => {
      if (elem.code + Constants.Si === value.currentTarget.name)
        elem.checked = true;
      if (elem.code + Constants.No === value.currentTarget.name)
        elem.checked = false;
    })
    setListaPersonalizada(auxLista);
    setListaCookies(auxLista);
    setFlag(!flag);
  };

  return (
    <Grid>
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      {open ? (
        <Grid container>
          <Dialog fullWidth
            maxWidth="md"
            open={open}
            className={dialogClasses.dialog}
            onClose={() => setOpen(false)}
          >
            <DialogContent>
              <Grid container className={dialogClasses.cookiesTitle}>
                <Grid item xs={11} style={{ display: "flex" }}>

                  <SettingsOutlinedIcon className={dialogClasses.imgIcon}> </SettingsOutlinedIcon>
                  <Typography className={dialogClasses.cookiesTitleText}>
                    {t("tycCookiesPolicityText18_B").replace(".", "")}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    edge="inherit"
                    className={commonClasses.dialogClosebutton}
                    color="inherit"
                    aria-label="menu"
                    onClick={handleCancel}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Divider className={dialogClasses.divider}></Divider>
                <Grid item md={12} xs={12} className={dialogClasses.cookiesTitle}>
                  <Grid>
                    {listaCookies.map((element, index) => {
                      let nameSi = element.code + Constants.Si;
                      let nameNo = element.code + Constants.No;
                      return (
                        <Grid
                          item
                          xs={12}
                          style={{ marginBottom: "1em" }}
                        >
                          <Grid
                            container
                            alignItems="right"
                          >
                            <Grid
                              item
                              xs={9}
                              md={9}
                              lg={9}
                              className={
                                dialogClasses.agreeConditionsTermsConsent
                              }
                            >
                              <span className={dialogClasses.cookieName}>{element.name} {element.mandatory ? " " + t("cookieDialogMandatoryCookie") : null}</span>
                              <p className={dialogClasses.cookieDescription}>
                                {element.code == "TPE" ? t("cookieDialogDescriptionTPE") : null}
                                {element.code == "ANA" ? t("cookieDialogDescriptionANA") : null}
                                {element.code == "PCO" ? t("cookieDialogDescriptionPCO") : null}</p>
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: "right" }}>
                              <Typography className={dialogClasses.buttonLabel}>{element.mandatory ? t("cookieDialogMandatory") : element.checked === 1 ? t("cookieDialogAllow") : t("cookieDialogDisallow")}</Typography>

                              {!element.mandatory ?
                                <ButtonGroup disableElevation >
                                  <Button
                                    color={
                                      listaCookies[index].checked === true
                                        ? "primary"
                                        : null
                                    }
                                    variant={
                                      listaCookies[index].checked === true
                                        ? "contained"
                                        : null
                                    }
                                    className={dialogClasses.switchButton}
                                    name={nameSi}
                                    disabled={element.mandatory ? true : false}
                                    onClick={(value) =>
                                      handleOptions(value)
                                    }
                                  >
                                    {t("yes")}
                                  </Button>
                                  <Button
                                    color={
                                      listaCookies[index].checked === false
                                        ? "secondary"
                                        : null
                                    }
                                    variant={
                                      listaCookies[index].checked === false
                                        ? "contained"
                                        : null
                                    }
                                    className={
                                      listaCookies[index].checked === false
                                        ? dialogClasses.switchButtonNoActive
                                        : dialogClasses.switchButton
                                    }
                                    name={nameNo}
                                    disabled={element.mandatory ? true : false}
                                    onClick={(value) =>
                                      handleOptions(value)
                                    }
                                  >
                                    {t("no")}
                                  </Button>
                                </ButtonGroup>
                                : <ButtonGroup disableElevation >
                                  <IconButton
                                    edge="inherit"
                                    className={commonClasses.dialogLockIcon}
                                    color="inherit"
                                    aria-label="menu"
                                    disabled="true"
                                  >
                                    <LockOutlinedIcon />
                                  </IconButton>
                                </ButtonGroup>}


                            </Grid>

                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={dialogClasses.cookiesActions} >
                <Grid item xs={12} md={6} className={dialogClasses.urlText}>
                  {t("cookieDialogCookiesPolicyText") + " "}
                  <a href={hrefTyCPage + EnumTyC.Cookies} target="_blank" rel={"noopener noreferrer"} className={dialogClasses.policyCookiesUrl}>{t("cookieBannerWeUseCookies3")}</a>
                </Grid>
                <Grid item xs={12} md={6} className={dialogClasses.buttonContainer}>
                  <CorreosButton
                    variant="contained"
                    color="primary"
                    onClick={() => _handleSaveConfigure()}
                    className={dialogClasses.dialogButton}
                  >
                    {t("cookieDialogSave")}
                  </CorreosButton>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>
      ) : null}

      <Grid container className={classes.messageGlobalCookie}>
        <Grid item xs={12} md={10} className={classes.cookiesText}>
          {t("cookieBannerWeUseCookies1") + " "}
          {t("cookieBannerWeUseCookies2")}
          <a href={hrefTyCPage + EnumTyC.Cookies} target="_blank" rel={"noopener noreferrer"} className={classes.policyCookiesUrl}>{t("cookieBannerWeUseCookies3")}</a>
          {t("cookieBannerWeUseCookies4") + "."}

        </Grid>

        <Grid item xs={12} md={2} className={classes.buttonContainerConfigure}>
          <CorreosButton
            variant="contained"
            color="default"
            className={classes.buttonConfigure}
            onClick={() => _handleConfigure()}>

            {t("cookieBannerConfigure")}
          </CorreosButton>

          <CorreosButton
            variant="contained"
            color="primary"
            className={classes.buttonConfigure}
            onClick={() => _handleAll()}
          >
            {t("cookieBannerAcceptAll")}
          </CorreosButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
