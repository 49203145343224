import { checkIsNullUndefined } from "../commons/Utils";
import { getLanguage } from "./Commons";
import { CommonFetch, buildHeader } from "./ReasonService";
import { fetchMethod } from "../commons/EnumsGeneral";

const fetchApi = {
    GetMasterRgpd: "Master/Rgpd",
    GetMasterCountry: "Master/Country",
    GetMasterProvince: "Master/Province",
    GetMasterCity: "Master/City/{id}",
    GetMasterPostalCode: "Master/PostalCode/{idPostalCode}",
    GetMasterProvincePostalCode: "Master/Province/PostalCode/{postalCode}",
    GetMasterCityPostalCode: "Master/City/PostalCode/{postalCode}",
    GetMasterCityProvince: "Master/City/Province/{idProvince}",
    GetMasterPostalCodeProvince: "Master/PostalCode/Province/{idProvince}",
    GetMasterTypeUserDocument: "Master/TypeUserDocument",
    GetMasterGenders: "Master/Gender",
    GetMasterProfessionalActivity: "Master/ProfessionalActivity",
    GetMasterTypeVerification: "Master/TypeVerification",
    GetMasterTypeVerificationDescription: "Master/TypeVerificationDescription",
    GetMasterLanguage: "Master/Language",
    GetMasterAttribute: "Master/Attribute",
    GetMasterFieldType: "Master/FieldType",
    GetMasterRoadType: "Master/RoadType",
    GetMasterEnterpriseRol: "Master/EnterpriseRol",
    GetMasterPrivacyApplication: "Master/Privacy/Application",
    GetMasterTycApplication: "Master/TyC/Application",
    GetMasterCookies: "Master/Cookies",
    GetMasterEnterpriseItem: "Master/EnterpriseItem",
};

//API 3.0
export const GetMasterRgpd = async (value) => {
    let body = "";
    let header = buildHeader("text/plain", false, false);
    let apiUrl = value
        ? fetchApi.GetMasterRgpd + "?id=" + value
        : fetchApi.GetMasterRgpd;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterCountry = async (id, code) => {
    let body = "";
    let header = buildHeader("text/plain", false, false);
    let apiUrl = fetchApi.GetMasterCountry;
    if (!checkIsNullUndefined(code)) {
        apiUrl = apiUrl + "?code=" + code;
    } else if (!checkIsNullUndefined(id)) {
        apiUrl = apiUrl + "?id=" + id;
    };
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterProvince = async (id, code) => {
    let body = "";
    let header = buildHeader("text/plain", true, false);
    let apiUrl = fetchApi.GetMasterProvince;
    if (!checkIsNullUndefined(code)) {
        apiUrl = apiUrl + "?code=" + code;
    } else if (!checkIsNullUndefined(id)) {
        apiUrl = apiUrl + "?id=" + id;
    };
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterCity = async (idCity, isCode) => {
    let body = "";
    let header = buildHeader("text/plain", true, false);
    let apiUrl = fetchApi.GetMasterCity.replace("{id}", idCity);
    if (!checkIsNullUndefined(isCode)) {
        apiUrl = apiUrl + "?isCode=" + true;
    };
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterPostalCode = async (idPostalCode, isCode = null) => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.GetMasterPostalCode.replace(
        "{idPostalCode}",
        idPostalCode
    );
    if (!checkIsNullUndefined(isCode) && isCode == true) {
        apiUrl = apiUrl + "?isCode=true";
    };
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0 Petición fetch para obtener la provincia segun codigo postal
export const GetMasterProvincePostalCode = async (postalCode, isCode = null) => {
    let body = "";
    let header = buildHeader("*/*", false, false);
    let apiUrl = fetchApi.GetMasterProvincePostalCode.replace(
        "{postalCode}",
        postalCode
    );
    if (!checkIsNullUndefined(isCode) && isCode == true) {
        apiUrl = apiUrl + "?isCode=true";
    };
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0 Petición fetch para obtener la ciudad segun codigo postal
export const GetMasterCityPostalCode = async (postalCode, isCode = null) => {
    let body = "";
    let header = buildHeader("*/*", false, false);
    let apiUrl = fetchApi.GetMasterCityPostalCode.replace(
        "{postalCode}",
        postalCode
    );
    if (!checkIsNullUndefined(isCode) && isCode == true) {
        apiUrl = apiUrl + "?isCode=true";
    };
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0 Petición fetch para obtener la ciudad segun provincia
export const GetMasterCityProvince = async (idProvince, isCode = null) => {
    let body = "";
    let header = buildHeader("*/*", false, false);
    let apiUrl = fetchApi.GetMasterCityProvince.replace(
        "{idProvince}",
        idProvince
    );
    if (!checkIsNullUndefined(isCode) && isCode == true) {
        apiUrl = apiUrl + "?isCode=true";
    };
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0 Petición fetch para obtener los codigos postales
export const GetMasterPostalCodeProvince = async (idProvince, isCode = null) => {
    let body = "";
    let header = buildHeader("*/*", false, false);
    let apiUrl = fetchApi.GetMasterPostalCodeProvince.replace(
        "{idProvince}",
        idProvince
    );
    if (!checkIsNullUndefined(isCode) && isCode == true) {
        apiUrl = apiUrl + "?isCode=true";
    };
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterTypeUserDocument = async (value) => {
    let body = "";
    let header = buildHeader("text/plain", false, false);
    let apiUrl = value
        ? fetchApi.GetMasterTypeUserDocument + "?id=" + value
        : fetchApi.GetMasterTypeUserDocument;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterGenders = async (value) => {
    let body = "";
    let header = buildHeader("text/plain", false, false);
    let apiUrl = value
        ? fetchApi.GetMasterGenders + "?id=" + value
        : fetchApi.GetMasterGenders;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterProfessionalActivity = async (value) => {
    let body = "";
    let header = buildHeader("text/plain", false, false);
    let apiUrl = value
        ? fetchApi.GetMasterProfessionalActivity + "?id=" + value
        : fetchApi.GetMasterProfessionalActivity;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0 
export const GetMasterTypeVerification = async () => {
    let body = "";
    let header = buildHeader("text/plain", false, false);
    let apiUrl = fetchApi.GetMasterTypeVerification;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterTypeVerificationDescription = async (value) => {
    let body = "";
    let header = buildHeader("text/plain", true, false);
    let apiUrl = value
        ? fetchApi.GetMasterTypeVerificationDescription + "?id=" + value
        : fetchApi.GetMasterTypeVerificationDescription;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterLanguage = async (value) => {
    let body = "";
    let header = buildHeader("text/plain", true, false);
    let apiUrl = value
        ? fetchApi.GetMasterLanguage + "?id=" + value
        : fetchApi.GetMasterLanguage;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterAttribute = async (value) => {
    let body = "";
    let header = buildHeader("text/plain", false, false);
    let apiUrl = value
        ? fetchApi.GetMasterAttribute + "?id=" + value
        : fetchApi.GetMasterAttribute;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterFieldType = async (value) => {
    let body = "";
    let header = buildHeader("text/plain", false, false);
    let apiUrl = value
        ? fetchApi.GetMasterFieldType + "?id=" + value
        : fetchApi.GetMasterFieldType;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterRoadType = async (id, code) => {
    let body = "";
    let header = buildHeader("text/plain", false, false);
    let apiUrl = fetchApi.GetMasterRoadType;
    if (!checkIsNullUndefined(code)) {
        apiUrl = apiUrl + "?code=" + code;
    } else if (!checkIsNullUndefined(id)) {
        apiUrl = apiUrl + "?id=" + id;
    };
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterEnterpriseRol = async (value) => {
    let body = "";
    let header = buildHeader("text/plain", true, false);
    let apiUrl = value
        ? fetchApi.GetMasterEnterpriseRol + "?id=" + value
        : fetchApi.GetMasterEnterpriseRol;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterPrivacyApplication = async (applicationOid) => {
    let body = "";
    let header = buildHeader("text/plain", false, false, applicationOid);
    let apiUrl = fetchApi.GetMasterPrivacyApplication;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterTycApplication = async (applicationOid) => {
    let body = "";
    let header = buildHeader("text/plain", false, false, applicationOid);
    let apiUrl = fetchApi.GetMasterTycApplication;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterCookies = async () => {
    let body = "";
    let header = new Headers();
    header.append("ApplicationOid", process?.env?.REACT_APP_APP_OID);
    header.append("Accept-Language", getLanguage());
    header.append("accept", "text/plain");
    let apiUrl = fetchApi.GetMasterCookies;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

//API 3.0
export const GetMasterEnterpriseItem = async (value) => {
    let body = "";
    let header = buildHeader("text/plain", true, false);
    let apiUrl = value
        ? fetchApi.GetMasterEnterpriseItem + "?id=" + value
        : fetchApi.GetMasterEnterpriseItem;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return await response;
};