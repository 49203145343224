import { makeStyles } from "@material-ui/core/styles";

export const FooterStyles = makeStyles((theme) => ({

  appBar: {
    position: "sticky",
    backgroundColor: "#333333",
    top: "auto",
    bottom: "0",
    // zIndex: 0,
    height: "auto",
    width: "100%",
    padding: "2em",
    [theme.breakpoints.down("md")]: {
      position: "static",
      height: "auto",
      padding: "1em",
    },
  },

  footerContainer: {
    alignItems: "center",
    justifyContent: "left",
  },

  imgContainer: {
    textAlign: "right",
    paddingRight: "2em",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      display: "flex",
      textAlign: "left",
      marginLeft: "1em",
      paddingTop: "1em",
    },
  },
  containerPadding: {
    [theme.breakpoints.down("md")]: {
      paddingTop: "1.5em",
    },
  },
  imgFooter: {
    filter: "brightness(0) invert(1)",
    maxWidth: "3.75em",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      maxWidth: "3em",
    },
  },
  legalUrl: {
    color: "#f2f2f2",
    "&:visited": { color: "#f2f2f2" },
    "&:hover": { color: "#f2f2f2" },
  },
  linkPadding: {
    textAlign: "left",
    marginTop: "auto",
    marginBottom: "auto",
    [theme.breakpoints.down("md")]: {
      display: "block",
      marginLeft: "1em",
      fontSize: "0.8em",
    },
  },
  pLink: {
    display: "inline",
    color: "#f2f2f2",
    textDecoration: "none",
    paddingRight: "5em",
    [theme.breakpoints.down("md")]: {
      display: "block",
      // marginLeft:"1em"
    },
  },
  pFooter: {
    display: "inline",
    [theme.breakpoints.down("md")]: {
      display: "block",
      // marginLeft:"1em"
    },
  }
}));
