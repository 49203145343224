import { makeStyles } from "@material-ui/core/styles";

export const UserVerificationsStyles = makeStyles((theme) => ({
  cardStyle: {
    width: "100%",
    marginBottom: "0.5em",
    paddingLeft: "5em",
    paddingRight: "5em",
  },
  listStyle: {
    width: "100%",
    textAlign: "left",
  },
  listItemStyle: {
    textAlign: "left",
    margin: "10px 0px",
  },
  listTextStyleShow: {
    width: "100%",
    color: "#666666",
  },
  listTextStyleGreen: {
    color: "#348f41",
    marginLeft: "5px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  iconGridStyle: {
    margin: "auto",
    color: "#333333",
  },
  buttonGridStyle: {
    margin: "auto",
    textAlign: "left",
  },
  iconStyle: {
    width: "30px",
    height: "30px",
    color: "#002453",
    [theme.breakpoints.down("sm")]: {
      width: "20px",
      height: "20px",
    },
  },
  textGridStyle: {
    height: "100%",
  },
  textVerificationStyle: {
    padding: "8px",
    float: "left",
    color: "#348f41",
  },
  textNoVerificationStyle: {
    padding: "8px",
    float: "left",
    color: "#f32735",
  },
  textPendingVerificationStyle: {
    padding: "8px",
    float: "left",
    color: "#dbb000",
  },
  formSelectorControl: {
    minWidth: "100%",
    "& .MuiFilledInput-underline": {
      "&:before": {
        border: "1px solid #FFCD00",
      },
      "&:hover:before": {
        border: "2px solid #FFCD00",
      },
      "&:after": {
        border: "2px solid #002453",
      },
    },
  },
  // dialogSendIconContainer: {
  //   display: "flex",
  //   alignItems: "center",
  //   height: "4.5em"
  // },
  divider: {
    marginTop: "-2.25em", marginBottom: "1em"
  },
  confirmOTP: {
    marginTop: "3em",
  },
  iconButtonStyle: {
    color: "#666666",
  },
  popperContainer: {
    width: "220px",
    height: "90px",
    padding: "10px",
  },
  popperContentText: {
    textAlign: "center",
  },
  cardFile: {
    alignItems: "center",
  },
  actionCardsContainer: {
    height: "fit-content",
  },
  gridText: {
    textAlign: "center",
  },
  buttonContainer: {
    margin: "1.5em 0em -0.5em 0em",
  },
  errorText: {
    fontSize: "80% !important",
    color: "#f32735",
  },

  buttonAdd: {
    margin: "auto",
  },
  media: {
    height: "100%",
    width: "auto"

  },
  imgCardMedia: {
    width: "auto",
    height: "12em"
  },
  privacyTitleCard: {
    backgroundColor: "#f3f2f2",
    borderRadius: "2em",
    marginBottom: "3em",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1em !important",
      marginRight: "1em !important",
    }
  },
  privacyTitleCardTextContainer: {
    marginTop: "auto",
    marginBottom: "auto",
    paddingRight: "5em",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0em !important",
      marginBottom: "1em"

    }
  },
  privacyTitleCardText: {

    fontSize: "120%",
    textAlign: "left",
    color: "#666666",
    marginRight: "2em",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1em",
      marginRight: "1em !important",
      paddingRight: "0em !important",

    }

  },
  rgpdTitle: {
    marginBottom: "2em"
  },
  imgIcon: {
    marginTop: "auto",
    marginBottom: "auto",
    height: "1.5em",
    width: "auto",
    overflow: "auto",
    color: "#002453",
    borderRadius: "50%",
    backgroundColor: "#f3f2f2",
    border: "7px solid #f3f2f2"
  },
}));
