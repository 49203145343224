import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { CommonStyles } from "../../commons/CommonStyles";
import { EnterpriseDeleteStyles } from "./EnterpriseDeleteStyles";
import { DeleteEnterprise, } from "../../services/ReasonService";
import { GetEnterpriseData, GetEnterpriseDataRole } from "../../services/EnterpriseDataCallsService.js";
import {
  GetUtilitiesCorreosIdSubscriptionEnterpriseTycPrivacyUrls,
} from "../../services/UtilitiesCallsService";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { checkIsNullUndefined, getErrorMessage } from "../../commons/Utils";
import { useTranslation } from "react-i18next";
import { Alerts } from "../Alerts/Alerts";
import history from "../../services/history.js";
import { BasicDialog } from "../CommonComponents/BasicDialog";
import { isResultOk, showAlert } from "../../commons/FormManager";

export function EnterpriseDelete(props) {
  const { setUpdateEnterprises, idEnterprise } = props;
  const commonClasses = CommonStyles();
  const classes = EnterpriseDeleteStyles();
  const { t, i18n } = useTranslation();
  const [isEnterpriseTitular, setIsEnterpriseTitular] = useState(false);
  const [circularProgress, setCircularProgress] = useState(false);
  const [circularProgressDeleting, setCircularProgressDeleting] =
    useState(false);
  const [enterpriseSubscriptions, setEnterpriseSubcriptions] = useState(
    []
  );
  const [enterpriseName, setEnterpriseName] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    if (idEnterprise) {
      preparePage();
    }
  }, [idEnterprise]);

  useEffect(() => { }, [circularProgressDeleting]);

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  const preparePage = () => {
    setCircularProgress(true);
    let getEnterpriseIdEnterprise = GetEnterpriseData(idEnterprise);
    let getEnterpriseDataRole = GetEnterpriseDataRole(idEnterprise);
    let getUtilitiesCorreosIdSubscriptionEnterpriseTycPrivacyUrls = GetUtilitiesCorreosIdSubscriptionEnterpriseTycPrivacyUrls(idEnterprise);
    Promise.all([getEnterpriseIdEnterprise, getEnterpriseDataRole, getUtilitiesCorreosIdSubscriptionEnterpriseTycPrivacyUrls]).then((results) => {
      let enterpriseData = results[0];
      let userRole = results[1];
      let enterpriseSubscriptions = results[2];
      if (isResultOk([enterpriseData, userRole, enterpriseSubscriptions])) {
        setEnterpriseName(enterpriseData.companyName);
        setIsEnterpriseTitular(userRole == 1);
        setEnterpriseSubcriptions(enterpriseSubscriptions);
        setCircularProgress(false);
      } else {
        showAlert([enterpriseData, userRole, enterpriseSubscriptions], finishOperation, t);
        setCircularProgress(false);
      }
    });
  };

  const handleSend = () => {
    setOpenPopup(true);
  };

  const confirmDeleteEnterprise = () => {
    setCircularProgressDeleting(true);
    DeleteEnterprise(idEnterprise).then((response) => {
      if (response && !response.Status) {
        finishOperation("success", t("enterpriseDeleteAlertMessageSuccess"));
        setCircularProgressDeleting(false);
        setUpdateEnterprises(true);
        let redirect = "/Index";
        history.push(redirect);
      } else {
        finishOperation("error", getErrorMessage(response, t));
        setCircularProgressDeleting(false);
      }
    });
  };

  return isEnterpriseTitular ? (
    <Grid>
      <Typography variant="h4" className={commonClasses.title}>
        {t("askUnsubscribeService")}
      </Typography>
      <Typography
        variant="subtitle2"
        className={commonClasses.title}
        style={{ textAlign: "center", marginBottom: "2em", color: "#666666", fontSize: "120%" }}
      >
        {circularProgress ? (
          <CircularProgress />
        ) : checkIsNullUndefined(enterpriseName) ? (
          t("enterpriseDeleteEnterpriseTitleNoName")
        ) : (
          t("enterpriseDeleteEnterpriseTitle").replace(
            /{enterpriseName}/,
            '"' + enterpriseName + '"'
          )
        )}
      </Typography>
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      <BasicDialog
        open={openPopup}
        setOpen={setOpenPopup}
        title={t("warning")}
        text={t("enterpriseDeleteAlertLastWarning")}
        action={confirmDeleteEnterprise}
      />
      <Container className={commonClasses.mainContainer}>
        <Paper>
          <Container>
            <Grid container className={classes.cardContainer}>
              <Container style={{ marginTop: "1.5em" }}>
                <Typography
                  variant="h6"
                  className={commonClasses.title}
                  style={{ textAlign: "left", marginTop: "1em" }}
                >
                  {t("enterpriseDeleteEnterpriseServicesTitle")}
                </Typography>
                <Typography
                  className={commonClasses.textDiv}
                  style={{ textAlign: "left" }}
                >
                  {circularProgress ? (
                    <CircularProgress />
                  ) : (
                    <ul>
                      {enterpriseSubscriptions.map((app) => (
                        <li>{app.application}</li>
                      ))}{" "}
                    </ul>
                  )}
                  <Typography
                    variant="h6"
                    className={commonClasses.title}
                    style={{ textAlign: "left" }}
                  >
                    {t("accountCancelDisableAccountListTitle")}
                  </Typography>
                  <ul>
                    <li>{t("enterpriseDeleteEnterpriseText")}</li>
                  </ul>
                </Typography>
              </Container>
              <Grid
                container
                item
                spacing={1}
                xs={12}
                className={commonClasses.dialogButtonContainer}
              >
                <Grid item xs={12}>
                  <CorreosButton
                    color="secondary"
                    onClick={handleSend}
                    circularProgress={circularProgressDeleting}
                  >
                    {t("enterpriseDeleteButtonText")}
                  </CorreosButton>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Container>
    </Grid>
  ) : (
    ""
  );
}
