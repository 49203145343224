import { useEffect } from "react";

export const checkIsNullUndefined = (value) => {
  let isNullUndefined = value === undefined || value === null || value === "" || value === "null";
  if (!isNullUndefined && (typeof value === "string")) {
    isNullUndefined = value.trim() === "";
  }
  return isNullUndefined;
};

export const getErrorMessage = (error, t) => {
  var errorMessage =
    !checkIsNullUndefined(error) &&
      !checkIsNullUndefined(error.Error) &&
      !checkIsNullUndefined(error.Error.resultError) &&
      !checkIsNullUndefined(error.Error.resultError[0])
      ? error.Error.resultError[0]
      : t("genericError");
  return errorMessage;
};

export const useOutsideAlerter = (ref, action) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export const compareDates = (fec, min, max) => {
  let flag = false;
  var fechaMax = new Date(max);
  var fechaValor = new Date(fec);
  var fechaMin = new Date(min);
  fechaMax.setHours(0, 0, 0, 0);
  fechaValor.setHours(0, 0, 0, 0);
  fechaMin.setHours(0, 0, 0, 0);
  if (
    fechaValor.getTime() > fechaMax.getTime() ||
    fechaValor < fechaMin.getTime()
  ) {
    flag = true;
  }
  return flag;
};

export const getDate = (fec, esp) => {
  let date = checkIsNullUndefined(fec) ? new Date() : new Date(fec);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  if (day < 10) {
    day = "0" + day.toString();
  }
  if (month < 10) {
    month = "0" + month.toString();
  }
  let year = date.getFullYear();
  let auxDate = esp ? day + "-" + month + "-" + year : year + "-" + month + "-" + day
  return auxDate;
};

export const getScreenWidth = () => {
  return window.screen.width;
};

export const validatePhoneNumber = (data, country, t) => {
  let auxError = { error: false, errorText: "" };
  let dataLength = data?.toString().length;

  if (!checkIsNullUndefined(data) && dataLength > 0) {
    if (dataLength === 1) {
      auxError.error = true;
      auxError.errorText = t("registerPagePhoneErrorEmpty");
    } else if (country.id === 67 && dataLength > 9) {
      auxError.error = true;
      auxError.errorText = t("registerPagePhoneErrorLong");
    } else if (!validatePhone(data, country)) {
      auxError.error = true;
      auxError.errorText = t("registerPagePhoneError");
    };
  };
  return auxError;
};

export const validatePhone = (phone, country) => {
  const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
  if (process?.env?.REACT_APP_ENVELOP_ACTIVE_3CODE_COUNTRY == "true") {
    let auxCode = phoneUtil.getRegionCodeForCountryCode(country.phonePrefix);
    return phoneUtil.isValidNumberForRegion(phoneUtil.parseAndKeepRawInput(phone.toString(), auxCode), auxCode);
  } else {
    return phoneUtil.isValidNumberForRegion(phoneUtil.parseAndKeepRawInput(phone.toString(), country.code), country.code);
  }
}

export const validatePhoneByPrefix = (phone, prefix) => {
  const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
  let auxCode = phoneUtil.getRegionCodeForCountryCode(prefix);
  return phoneUtil.isValidNumberForRegion(phoneUtil.parseAndKeepRawInput(phone.toString(), auxCode), auxCode);

}

export const getCountryByAnyIdValue = (countryList, value, enterType) => {
  let auxResponse = {};
  let auxCountry = countryList.find((country) => country[enterType] == value);
  if (!checkIsNullUndefined(auxCountry)) {
    auxResponse = auxCountry;
  }
  return auxResponse;
};

export const validatePassword = (auxPass) => {
  const hasNumber = new RegExp("[0-9]");
  const hasUpperChar = new RegExp("[A-Z]");
  const hasLowerChar = new RegExp("[a-z]");
  const hasNumberCharacters = new RegExp(".{8,16}");
  const hasSimbols = new RegExp("[@#$%^&*_+=[{}|\\:',?/`~\"();.-]");
  const hasSimbol = !checkIsNullUndefined(auxPass)
    ? auxPass.indexOf("]")
    : -1;
  let okPass = hasNumberCharacters.test(auxPass);
  if (okPass) {
    let flag = 0;
    if (hasNumber.test(auxPass)) flag++;
    if (hasUpperChar.test(auxPass)) flag++;
    if (hasLowerChar.test(auxPass)) flag++;
    if (hasSimbols.test(auxPass) || hasSimbol >= 0) flag++;
    if (flag < 3) okPass = false;
  }
  return okPass;
}

export const validateName = (value) => {
  const ExpRegNomApe = new RegExp('((^[a-zA-ZÀ-ÿ- \'ºª·]{1,50})$)', 'gm');
  return ExpRegNomApe.test(value);
}

export const finishGlobalOperation = (setAlert, color, message, open) => {
  let auxOpen = checkIsNullUndefined(open) ? true : open;
  setAlert({
    open: auxOpen,
    color,
    message,
  });
};

export const decode64 = (codedString) => {
  let auxValue = window.atob(codedString);
  return JSON.parse(auxValue);
};

export const encode64 = (stringToCode) => {
  return window.btoa(stringToCode);
};

export const getCountryForCode = (countryList, code) => {
  let auxCountry = countryList.find((country) => country.code == code);
  return auxCountry;
};