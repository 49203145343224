import React from "react";
import {
  Grid,
  Container,
  Card,
  Typography,
} from "@material-ui/core";
import { TyCStyles } from "./TyCStyles";
import { useTranslation } from "react-i18next";

export function WarninLegalTyC(props) {
  const { showSideMenu } = props;
  const classes = TyCStyles();
  const { t, i18n } = useTranslation();

  return (
    <Grid className={!showSideMenu ? classes.container : classes.hideContainer}>
      <Container maxWidth={"lg"} className={classes.mainContainer}>
        <Card className={classes.cardContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography align="justify" className={classes.text}>
                <p>{t("tycWarningLegalText1")}</p>
                <p>{t("tycWarningLegalText2")}</p>
                <p>{t("tycWarningLegalText3")}</p>

                <p className={classes.subtitles}>
                  {t("tycWarningLegalSubtittle1")}
                </p>
                <p>{t("tycWarningLegalText4")}</p>
                <p>{t("tycWarningLegalText5")}</p>
                <p>{t("tycWarningLegalText6")}</p>
                <p>{t("tycWarningLegalText7")}</p>
                <p className={classes.subtitles}>
                  {t("tycWarningLegalSubtittle2")}
                </p>
                <p>
                  {t("tycWarningLegalText8_1")}
                  <b>{t("tycWarningLegalText8_B")}</b>
                  {t("tycWarningLegalText8_2")}
                </p>
                <p className={classes.subtitles}>
                  {t("tycWarningLegalSubtittle3")}
                </p>
                <p>{t("tycWarningLegalText9")}</p>
                <p className={classes.subtitles}>
                  {t("tycWarningLegalSubtittle4")}
                </p>
                <p>{t("tycWarningLegalText10")}</p>
                <p>{t("tycWarningLegalText11")}</p>
                <p>{t("tycWarningLegalText12")}</p>
                <p>{t("tycWarningLegalText13")}</p>
                <p>{t("tycWarningLegalText14")}</p>
                <p>{t("tycWarningLegalText15")}</p>
                <p className={classes.subtitles}>
                  {t("tycWarningLegalSubtittle5")}
                </p>
                <p>{t("tycWarningLegalText16")}</p>
                <p>{t("tycWarningLegalText18")}</p>
                <p className={classes.subtitles}>
                  {t("tycWarningLegalSubtittle6")}
                </p>
                <p>{t("tycWarningLegalText19")}</p>
                <p>{t("tycWarningLegalText20")}</p>
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Grid>
  );
}
