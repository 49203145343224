import { makeStyles } from "@material-ui/core/styles";

export const StylesServiceList = makeStyles((theme) => ({
    root: {
        paddingTop: "1rem",
        paddingBottom: "1rem",
        marginLeft:"12%", 
        marginRight:"12%",
        [theme.breakpoints.down("lg")]: {
            marginLeft:"4%", marginRight:"4%"
          },
    }
}));
