import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import { SideMenuStyles } from "../SideMenuStyles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { FaBuilding } from "react-icons/fa";
import { LateralBarPages } from "../../../commons/LateralBarOptions";
import { checkIsNullUndefined } from "../../../commons/Utils";
import { SubMenu } from "./SubMenu";
import { useTranslation } from "react-i18next";

export function EnterpriseMenu(props) {
  const { enterprise, setShowSideMenu, setSubMenuKey, subMenuKey, setSelected, setResetPage, resetPage, isSpecialUser } = props;
  const classes = SideMenuStyles();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);

  useEffect(() => { }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      key={"Enterprise" + enterprise.oid?.toString()}
      className={classes.menuList}
    >
      <ListItem
        button
        onClick={() => handleClick()}
        key={"EnterpriseSub" + enterprise.oid?.toString()}
        className={classes.menuListItem}
      >
        <ListItemIcon className={classes.drawerText}>
          <FaBuilding className={classes.icon} />
        </ListItemIcon>
        <ListItemText
          primary={
            !checkIsNullUndefined(enterprise) &&
              !checkIsNullUndefined(enterprise.name)
              ? enterprise.name
              : t("Enterprise")
          }
          primaryTypographyProps={{ noWrap: true }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {open ? (
        <Collapse
          in={open}
          className={classes.collapseStyle}
          timeout="auto"
          unmountOnExit
          key={t("EnterpriseColl") + enterprise.oid?.toString()}
        >
          <SubMenu
            code={t("Enterprise") + enterprise.oid?.toString()}
            options={LateralBarPages.Enterprise}
            enterprise={enterprise}
            setShowSideMenu={setShowSideMenu}
            setSubMenuKey={setSubMenuKey}
            subMenuKey={subMenuKey}
            setSelected={setSelected}
            setResetPage={setResetPage}
            resetPage={resetPage}
            isSpecialUser={isSpecialUser}
          ></SubMenu>
        </Collapse>
      ) : null}
    </List>
  );
}