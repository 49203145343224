import { fetchMethod, sessionValues } from "../commons/EnumsGeneral";
import { encode64 } from "../commons/Utils";
import { getSessionItem } from "./Commons";
import { CommonFetch } from "./ReasonService";

const fetchApi = {
    GetApplicationAuxApplicationData: "ApplicationAux/ApplicationData",
    GetApplicationAuxCountry: "ApplicationAux/Country",
    PostApplicationAuxSSOLogin: "ApplicationAux/SSOLogin",
    PostApplicationAuxPreregister: "ApplicationAux/Preregister",
    PutApplicationAuxResetPassword: "ApplicationAux/ResetPassword",
    GetApplicationAuxRgpd: "ApplicationAux/Rgpd",
    GetApplicationAuxPrivacyAndTycApplication: "ApplicationAux/PrivacyAndTyc/Application",
    GetApplicationAuxCidRegistryData: "ApplicationAux/CidRegistryData",
    PostApplicationAuxUserPhone: "ApplicationAux/UserPhone",
    PostApplicationAuxVerificationTwoFactorAuthentication: "ApplicationAux/VerificationTwoFactorAuthentication",
    PostApplicationAuxUserResetPassuserSend: "ApplicationAux/User/ResetPass/{user}/Send",
    PostApplicationAuxUserRegisteruserSend: "ApplicationAux/User/Register/{user}/Send",
    PostApplicationAuxUserSend: "ApplicationAux/User/{user}/Send",
};

const buildHeaderAux = (accept, content, appCode) => {
    let appCodeAux = appCode ? appCode : getSessionItem(sessionValues.appCode);
    let header = new Headers();
    header.append("accept", accept);
    header.append("value", encode64(appCodeAux));
    if (content) {
        header.append("Content-Type", content);
    };
    return header;
};

export const GetApplicationAuxApplicationData = async () => {
    let body = "";
    let header = buildHeaderAux("application/json", "application/json");
    let apiUrl = fetchApi.GetApplicationAuxApplicationData;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const GetApplicationAuxCountry = async () => {
    let body = "";
    let header = buildHeaderAux("application/json", "application/json");
    let apiUrl = fetchApi.GetApplicationAuxCountry;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const PostApplicationAuxSSOLogin = async (cookie) => {
    let body = { cookie: cookie };
    let header = buildHeaderAux("*/*", "application/json");
    let apiUrl = fetchApi.PostApplicationAuxSSOLogin;
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return await response;
};

export const PostApplicationAuxPreregister = async (user) => {
    let body = { username: user };
    let header = buildHeaderAux("*/*", "application/json");
    let apiUrl = fetchApi.PostApplicationAuxPreregister;
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return response;
};

export const PutApplicationAuxResetPassword = async (
    username,
    newPassword,
    repeatNewPassword,
    otpCode
) => {
    let body = {
        username: username,
        newPassword: newPassword,
        repeatNewPassword: repeatNewPassword,
        otpCode: otpCode,
    };
    let header = buildHeaderAux("application/json", "application/json");
    let apiUrl = fetchApi.PutApplicationAuxResetPassword;
    let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl);
    return response;
};

export const GetApplicationAuxRgpd = async () => {
    let body = "";
    let header = buildHeaderAux("*/*", "application/json");
    let apiUrl = fetchApi.GetApplicationAuxRgpd;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const GetApplicationAuxPrivacyAndTycApplication = async (auxCode) => {
    let body = "";
    let header = buildHeaderAux("*/*", "application/json", auxCode);
    let apiUrl = fetchApi.GetApplicationAuxPrivacyAndTycApplication;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const GetApplicationAuxCidRegistryData = async (auxCode) => {
    let body = "";
    let header = buildHeaderAux("*/*", "application/json", auxCode);
    let apiUrl = fetchApi.GetApplicationAuxCidRegistryData;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const PostApplicationAuxUserPhone = async (code, username) => {
    let body = { code: code, username: username };
    let header = buildHeaderAux("*/*", "application/json");
    let apiUrl = fetchApi.PostApplicationAuxUserPhone;
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return await response;
};

export const PostApplicationAuxVerificationTwoFactorAuthentication = async (phone, otp, username) => {
    let body = {
        username: username,
        phone: phone,
        otp: otp,
    };
    let header = buildHeaderAux("*/*", "application/json");
    let apiUrl = fetchApi.PostApplicationAuxVerificationTwoFactorAuthentication;
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return response;
};

export const PostApplicationAuxUserResetPassuserSend = async (user) => {
    let body = "";
    let header = buildHeaderAux("*/*", null);
    let apiUrl = fetchApi.PostApplicationAuxUserResetPassuserSend.replace("{user}", user);
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return response;
};

export const PostApplicationAuxUserRegisteruserSend = async (user) => {
    let body = "";
    let header = buildHeaderAux("*/*", null);
    let apiUrl = fetchApi.PostApplicationAuxUserRegisteruserSend.replace("{user}", user);
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return response;
};

export const PostApplicationAuxUserSend = async (user) => {
    let body = "";
    let header = buildHeaderAux("*/*", null);
    let apiUrl = fetchApi.PostApplicationAuxUserSend.replace("{user}", user);
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return response;
};