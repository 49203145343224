import React, { useRef, useState } from "react";
import {
  Typography,
  Container,
  Grid,
  Paper,
  Button,
  Popper,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { CommonStyles } from "../../../commons/CommonStyles";
import { EnterpriseTeamStyles } from "../EnterpriseTeamStyles";
import {
  DeleteInvitationInvitation,
} from "../../../services/ReasonService";
import {
  PostEnterpriseDataCancelOrRejectChangeRoleProposal,
} from "../../../services/EnterpriseDataCallsService";
import { getErrorMessage, useOutsideAlerter } from "../../../commons/Utils";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

export function DialogCancelInvitation(props) {
  const { finishOperation, idInvitation, idRole, getInfo } = props;
  const commonClasses = CommonStyles();
  const classes = EnterpriseTeamStyles();
  const wrapperRef = useRef(null);
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(null);

  const handleCancel = () => {
    setAnchorEl(null);
    setOpenDelete(false);
  };

  const handleConfirm = () => {
    if (idRole === "-") {
      DeleteInvitationInvitation(idInvitation).then((response) => {
        if (response && !response.Status) {
          getInfo();
          finishOperation("success", t("teamPageInvitationCancelSuccess"));
        } else {
          finishOperation("error", getErrorMessage(response, t));
        }
        setOpenDelete(false);
      });
    } else {
      PostEnterpriseDataCancelOrRejectChangeRoleProposal(idInvitation).then(
        (response) => {
          if (response && !response.Status) {
            getInfo();
            finishOperation("success", t("teamPageInvitationCancelSuccess"));
          } else {
            finishOperation("error", getErrorMessage(response, t));
          }
          setOpenDelete(false);
        }
      );
    }
  };

  const handleDelete = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenDelete(true);
  };

  useOutsideAlerter(wrapperRef, handleCancel);

  return (
    <Grid>
      <Popper
        open={openDelete}
        anchorEl={anchorEl}
        placement={"top"}
        style={{ zIndex: 100 }}
        ref={wrapperRef}
      >
        <Paper>
          <Container>
            <Grid container style={{ paddingTop: "1em", paddingBottom: "1em" }}>
              <Grid item xs={12}>
                <Typography className={commonClasses.popperContentText}>
                  {t("teamPageInvitationConfirmCancel")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="small"
                  onClick={() => handleCancel()}
                  className={commonClasses.leftButton}
                >
                  {t("no")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => handleConfirm()}
                  className={commonClasses.rightButton}
                >
                  {t("yes")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Popper>
      <Tooltip title={t("teamPageInvitationCancel")}>
        <IconButton
          edge="inherit"
          className={classes.iconButton}
          color="inherit"
          aria-label="menu"
          onClick={(event) => handleDelete(event)}
        >
          <AiOutlineCloseCircle className={commonClasses.iconActionStyle} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}
