import { ES1 } from "./translations1";
import { ES2 } from "./translations2";
import { ESTYC1 } from "./TycsTranslation1";
import { ESTYC2 } from "./TycsTranslation2";

const ES = { ...ES1, ...ES2, ...ESTYC1, ...ESTYC2 };
export { ES };



