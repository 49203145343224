import React, { useEffect, useState } from "react";
import {
  Grid,
  Dialog,
  Typography,
  Container,
  CardMedia,
} from "@material-ui/core";
import correosIdLogoBlue from "../../../assets/images/Cornamusa_Azul.svg"
import { CorreosButton } from "../../../components/CorreosButton/CorreosButton";
import { Alerts } from "../../../components/Alerts/Alerts";
import { AgreeConditionsLabel } from "../AgreeConditionsLabel";
import { CommonStyles } from "../../../commons/CommonStyles";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import {
  PostSubscriptionUser,
  PostSubscriptionEnterprise,
  PutSubscriptionUserUnsubscribe,
  PutSubscriptionEnterpriseUnsubscribe,
} from "../../../services/ReasonService";
import {
  GetLegalUserPendingTycCid,
} from "../../../services/LegalCallsService";
import { checkIsNullUndefined, getErrorMessage } from "../../../commons/Utils";
import {
  backToLogin,
  checkEnterprisePath,
  checkUserPath,
  getApplicationOid,
} from "../../../services/Commons";
import { useTranslation } from "react-i18next";

export function DialogSubscribe(props) {
  const {
    open,
    setOpen,
    enterpriseId,
    appName,
    isServices,
    resetPageData,
    applicationOid,
    doSubscription,
    doUnsubscription,
    setCircularProgressSubs,
    isLogin,
    setOpenPrivacy,
    setOpenACM,
  } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const { t, i18n } = useTranslation();
  const [circularProgress, setCircularProgress] = useState(false);
  const [agreeConditions, setAgreeConditions] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (!checkIsNullUndefined(doSubscription) && doSubscription) {
      handleSubscribe(true);
    };
  }, [doSubscription]);

  useEffect(() => {
    if (!checkIsNullUndefined(doUnsubscription) && doUnsubscription) {
      handleSubscribe(false);
    };
  }, [doUnsubscription]);

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  const handleCancel = () => {
    setOpen(false);
    setReset(true);
    if (checkIsNullUndefined(isServices) || !isServices) {
      backToLogin();
    };
  };

  const handleConfirm = () => {
    if (isServices) {
      handleSubscribe(true);
    } else {
      handlePendingSubscribe();
    };
  };

  const handlePendingSubscribe = () => {
    setCircularProgress(true);
    let auxApplicationOid = getApplicationOid();
    let auxCall = checkIsNullUndefined(enterpriseId) ? PostSubscriptionUser : PostSubscriptionEnterprise;
    let text1 = checkIsNullUndefined(enterpriseId) ? "unsuscribedUser" : "unsuscribedEnterprise";
    let text2 = checkIsNullUndefined(enterpriseId) ? "pendingUserPrivacy" : "pendingEnterprisePrivacy";
    auxCall(auxApplicationOid, enterpriseId).then((response) => {
      if (response && (!response.Status || response.Status == 202)) {
        manageResponseFunction(text1, text2);
      } else {
        finishOperation("error", getErrorMessage(response, t));
      };
      setOpen(false);
      setCircularProgress(false);
    });
  };

  const manageResponseFunction = (auxText1, auxText2) => {
    sessionStorage.removeItem(auxText1);
    sessionStorage.removeItem(auxText2);
    if (isLogin) {
      checkCidTyCs();
    } else if (checkIsNullUndefined(enterpriseId)) {
      checkUserPath(false, finishOperation, t, setOpenACM);
    } else {
      checkEnterprisePath(enterpriseId, finishOperation, t);
    };
  };

  const checkCidTyCs = () => {
    GetLegalUserPendingTycCid().then((response) => {
      if (response && !response.Status) {
        if (response.length == 0) {
          checkUserPath(false, finishOperation, t, setOpenACM);
        } else {
          setOpenPrivacy(true);
        };
      } else {
        finishOperation("error", getErrorMessage(response, t));
      };
    });
  };

  const handleSubscribe = (subscribe) => {
    setCircularProgress(true);
    let auxCircularProgressSubs = checkIsNullUndefined(setCircularProgressSubs) ? () => { } : setCircularProgressSubs;
    auxCircularProgressSubs(true);
    let auxCall = checkIsNullUndefined(enterpriseId) ? PostSubscriptionUser : PostSubscriptionEnterprise;
    if (!subscribe) {
      auxCall = checkIsNullUndefined(enterpriseId) ? PutSubscriptionUserUnsubscribe : PutSubscriptionEnterpriseUnsubscribe;
    };
    auxCall(applicationOid, enterpriseId).then((response) => {
      if (response && (!response.Status || response.Status == 202)) {
        resetPageData(subscribe);
      } else {
        finishOperation("error", getErrorMessage(response, t));
      };
      setOpen(false);
      setCircularProgress(false);
      auxCircularProgressSubs(false);
    });
  };

  return (
    <Grid>
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      <Dialog
        fullWidth
        PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
        onClose={isServices ? handleCancel : () => { }}
        open={open}
      >
        <Container className={dialogClasses.dialogTitle}>
          <Grid container>
            <Grid item xs={12} style={{ margin: "auto" }}>
              <CardMedia className={dialogClasses.media}>
                <img
                  className={dialogClasses.imgCardMedia}
                  src={correosIdLogoBlue}
                  alt="Logo"
                />
              </CardMedia>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="h4">
                {appName}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={dialogClasses.dialogContainer}>
          <Grid container item xs={12} className={dialogClasses.gridIdentity}>
            {isFinished ? (
              <Grid item xs={12} style={{ marginBottom: "1em" }}>
                {isServices ? (
                  <Typography className={dialogClasses.dialogContentText}>
                    {t("loginSubscribeDialogTextServices")}
                  </Typography>
                ) : (
                  <Typography className={dialogClasses.dialogContentText}>
                    {t("loginSubscribeDialogText")}
                  </Typography>
                )}
              </Grid>
            ) : null}
            {isFinished ? (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography className={dialogClasses.dialogContentText}>
                  {t("registerPageAgreeConditions")}
                </Typography>
              </Grid>
            ) : null}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <AgreeConditionsLabel
                setOk={setAgreeConditions}
                finishOperation={finishOperation}
                reset={reset}
                personType={checkIsNullUndefined(enterpriseId) ? 1 : 2}
                applicationOid={applicationOid}
                setIsFinished={setIsFinished}
              />
            </Grid>
            {isFinished ? (
              <Grid
                container
                item
                xs={12}
                className={commonClasses.dialogButtonContainer}
              >
                <Grid item xs={6}>
                  <CorreosButton
                    color="secondary"
                    className={commonClasses.dialogButtonLeft}
                    onClick={handleCancel}
                  >
                    {t("cancel")}
                  </CorreosButton>
                </Grid>
                <Grid item xs={6}>
                  <CorreosButton
                    variant="contained"
                    color="primary"
                    className={commonClasses.dialogButtonRight}
                    onClick={handleConfirm}
                    disabled={!agreeConditions}
                    circularProgress={circularProgress}
                  >
                    {t("accept")}
                  </CorreosButton>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Container>
      </Dialog>
    </Grid>
  );
};
