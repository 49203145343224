import React, { useEffect, useState } from "react";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import {
  Grid,
  Dialog,
  Container,
  Tooltip,
  IconButton,
  Typography,
} from "@material-ui/core";
import { CommonStyles } from "../../../commons/CommonStyles";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { EnterpriseTeamStyles } from "../EnterpriseTeamStyles";
import { AiOutlineUserDelete } from "react-icons/ai";
import { PostEnterpriseDataChangeEnterpriseUserRoleAdminToAuthorized } from "../../../services/EnterpriseDataCallsService";
import { getErrorMessage } from "../../../commons/Utils";
import { useTranslation } from "react-i18next";

export default function DialogRevokeAdmin(props) {
  const { userOid, finishOperation, idEnterprise, getInfo } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const classes = EnterpriseTeamStyles();
  const { t, i18n } = useTranslation();
  const [openChange, setOpenChange] = useState(false);
  const [circularButtonProgress, setCircularButtonProgress] = useState(false);

  useEffect(() => {}, []);

  const handleCancel = () => {
    setOpenChange(false);
  };

  const handleConfirm = () => {
    setCircularButtonProgress(true);
    PostEnterpriseDataChangeEnterpriseUserRoleAdminToAuthorized(idEnterprise, userOid).then(
      (response) => {
        if (response && !response.Status) {
          //Alerta
          getInfo();
          finishOperation("success", t("revokeAdminConfirm"));
        } else {
          //Alerta
          finishOperation("error", getErrorMessage(response, t));
        }
        setCircularButtonProgress(false);
        setOpenChange(false);
      }
    );
  };

  const handleOpen = () => {
    setOpenChange(true);
  };

  return (
    <Grid>
      <Tooltip title={t("revokeAdminPermissions")}>
        <IconButton
          aria-label="menu"
          className={classes.iconButton}
          onClick={() => handleOpen()}
        >
          <AiOutlineUserDelete className={commonClasses.iconActionStyle} />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        open={openChange}
        onClose={() => handleCancel()}
        PaperProps={{ classes: {root: dialogClasses.dialogWindow }}}
      >
        <Container className={commonClasses.dialogTitle}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={commonClasses.dialogTitleText}
              >
                {t("revokeAdminPermissions")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={commonClasses.dialogContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                className={commonClasses.dialogTitleText}
              >
                {t("revokeAdminPermissionsQuestion")}
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              className={commonClasses.dialogButtonContainer}
            >
              <Grid item xs={6}>
                <CorreosButton
                  color="secondary"
                  className={commonClasses.dialogButtonLeft}
                  onClick={handleCancel}
                >
                  {t("cancel")}
                </CorreosButton>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  circularProgress={circularButtonProgress}
                  variant="contained"
                  color="primary"
                  className={commonClasses.dialogButtonRight}
                  onClick={handleConfirm}
                >
                  {t("accept")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </Grid>
  );
}
