import React, { useEffect, useState } from "react";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import {
  Grid,
  Dialog,
  Container,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Typography,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import {
  PostVerificationSendEmail,
  PostVerificationSendPhone,
  GetEnterpriseSubscriptions,
  PostEnterpriseRoleCreateSystemUser,
} from "../../../services/ReasonService";
import {
  GetUtilitiesCorreosIdEnterpriseServices,
} from "../../../services/UtilitiesCallsService";
import ico_pass_on from "../../../assets/images/ico_pass_on.svg";
import ico_pass_off from "../../../assets/images/ico_pass_off.svg";
import { checkIsNullUndefined, getErrorMessage } from "../../../commons/Utils";
import { CommonStyles } from "../../../commons/CommonStyles";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";

import { EnterpriseTeamStyles } from "../EnterpriseTeamStyles";
import { AiOutlineSetting } from "react-icons/ai";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";

export default function DialogCreateSystemUser(props) {
  const { datosTitular, finishOperation, idEnterprise, getInfo } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const classes = EnterpriseTeamStyles();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [checkPassword, setCheckPassword] = useState("");
  const [checkPasswordError, setCheckPasswordError] = useState(false);
  const [showCheckPassword, setShowCheckPassword] = useState(false);
  const [sended, setSended] = useState(false);
  const [applicationList, setApplicationList] = useState([]);
  const [oidApplication, setOidApplication] = useState("");
  const [createCircularProgress, setCreateCircularProgress] = useState(false);

  useEffect(() => {
    if (applicationList.length === 0) {
      GetUtilitiesCorreosIdEnterpriseServices().then((response) => {
        if (response && !response.Status) {
          GetEnterpriseSubscriptions(idEnterprise).then((response1) => {
            if (response1 && !response1.Status) {
              let auxApplicationList = [];
              response.forEach((service) => {
                response1.forEach((enterService) => {
                  if (
                    enterService.application === service.nameApplication &&
                    (enterService.idState === 1 || enterService.idState === 3)
                  ) {
                    auxApplicationList.push(service);
                  }
                });
              });
              setApplicationList(auxApplicationList);
              if (auxApplicationList.length > 0) {
                setOidApplication(auxApplicationList[0].applicationOid);
              }
            } else {
              //Alerta
              finishOperation("error", getErrorMessage(response1, t));
            }
          });
        } else {
          //Alerta
          finishOperation("error", getErrorMessage(response, t));
        }
      });
    }
    if (open === true) {
      handleSendOtp();
    }
  }, [open]);

  const handleSendOtp = () => {
    if (!checkIsNullUndefined(datosTitular)) {
      if (!checkIsNullUndefined(datosTitular.userEmail)) {
        PostVerificationSendEmail(datosTitular.userEmail).then((response) => {
          handleFinishOperation(response);
        });
      } else if (
        !checkIsNullUndefined(datosTitular.userPhone) &&
        !checkIsNullUndefined(datosTitular.userPhonePrefix)
      ) {
        PostVerificationSendPhone(
          datosTitular.userPhonePrefix + "-" + datosTitular.userPhone
        ).then((response) => {
          handleFinishOperation(response);
        });
      }
    }
  };

  const handleFinishOperation = (response) => {
    if (response && !response.Status) {
      setSended(true);
    } else {
      //Alerta
      finishOperation("error", getErrorMessage(response, t));
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancelCreate = () => {
    setOpen(false);
    setOtp("");
    setPassword("");
    setPasswordError(false);
    setShowPassword(false);
    setCheckPassword("");
    setCheckPasswordError(false);
    setShowCheckPassword(false);
    if (applicationList.length === 0) {
      setOidApplication("");
    } else {
      setOidApplication(applicationList[0].applicationOid);
    }
  };

  const handleChangePassword = (value) => {
    const hasNumber = new RegExp("[0-9]");
    const hasUpperChar = new RegExp("[A-Z]");
    const hasLowerChar = new RegExp("[a-z]");
    const hasNumberCharacters = new RegExp(".{8,16}");
    const hasSimbols = new RegExp("[@#$%^&*_+=[{}|\\:',?/`~\"();.-]");
    const hasSimbol = !checkIsNullUndefined(value.target.value)
      ? value.target.value.indexOf("]")
      : -1;
    let okPass = hasNumberCharacters.test(value.target.value);
    let okCheckPass = true;
    if (okPass) {
      let flag = 0;
      if (hasNumber.test(value.target.value)) flag++;
      if (hasUpperChar.test(value.target.value)) flag++;
      if (hasLowerChar.test(value.target.value)) flag++;
      if (hasSimbols.test(value.target.value) || hasSimbol >= 0) flag++;
      if (flag < 3) okPass = false;
    }
    if (value.target.value === "") okPass = true;

    if (checkPassword !== "" && checkPassword === value.target.value) {
      okCheckPass = false;
    }
    setPassword(value.target.value);
    setPasswordError(!okPass);
    if (checkPassword !== "") {
      setCheckPasswordError(okCheckPass);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeCheckPassword = (value) => {
    let flag = false;
    if (
      !checkIsNullUndefined(password) &&
      !checkIsNullUndefined(value.target.value) &&
      password !== value.target.value
    )
      flag = true;
    setCheckPassword(value.target.value);
    setCheckPasswordError(flag);
  };

  const handleClickShowCheckPassword = () => {
    setShowCheckPassword(!showCheckPassword);
  };

  const handleMouseDownCheckPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeOtp = (value) => {
    let flag = false;
    if (checkIsNullUndefined(value.target.value)) {
      flag = true;
    }
    setOtp(value.target.value);
    setOtpError(flag);
    setOtpErrorMessage("");
  };

  const handleCreateSystemUser = () => {
    //Llamada al back
    setCreateCircularProgress(true);
    PostEnterpriseRoleCreateSystemUser(
      parseInt(idEnterprise, 10),
      oidApplication,
      password,
      checkPassword,
      otp
    ).then((response) => {
      if (response && !response.Status) {
        finishOperation("success", t("teamPageCreateSystemUserSuccess"));
        handleCancelCreate();
        getInfo();
      } else {
        //Alerta
        if (getErrorMessage(response, t).toLowerCase().indexOf("otp") !== -1) {
          setOtpError(true);
          setOtpErrorMessage(getErrorMessage(response, t));
        } else {
          finishOperation("error", getErrorMessage(response, t));
        }
      }
      setCreateCircularProgress(false);
    });
  };

  const handleApplication = (value) => {
    setOidApplication(value.target.value);
  };

  return (
    <Grid>
      <Tooltip title={t("teamPageCreateSystemUser")}>
        <IconButton
          onClick={() => handleClickOpen()}
          className={commonClasses.iconButton}
        >
          <AiOutlineSetting className={commonClasses.iconActionStyle} />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        open={open}
        onClose={() => handleCancelCreate()}
        PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
      >
        {applicationList.length > 0 ? (
          <Grid>
            <Container className={commonClasses.dialogTitle}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    className={commonClasses.dialogTitleText}
                  >
                    {t("teamPageCreateSystemUserTitle")}
                  </Typography>
                </Grid>
              </Grid>
            </Container>
            <Container className={commonClasses.dialogContainer}>
              <Grid container spacing={1}>
                <Grid container item xs={12}>
                  <Grid item xs={12} style={{ color: "#333333" }}>
                    <Typography>
                      {t("teamPageCreateSystemUserText1")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    style={{ marginTop: "0.70em", marginBottom: "0.2em" }}
                  >
                    <Typography style={{ color: "#666666" }}>
                      {!checkIsNullUndefined(datosTitular.userEmail)
                        ? datosTitular.userEmail
                        : datosTitular.userPhonePrefix +
                        "-" +
                        datosTitular.userPhone}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <CorreosButton
                      color="secondary"
                      className={commonClasses.dialogButtonRight}
                      onClick={handleSendOtp}
                      style={{ marginTop: "0.3em" }}
                    >
                      {sended ? t("reSend") : t("send")}
                    </CorreosButton>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{ color: "#333333", marginBottom: "0.3em" }}
                  >
                    {t("teamPageCreateSystemUserText2")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    required
                    fullWidth
                    color="secondary"
                    className={commonClasses.inputsStyle}
                  >
                    <InputLabel>
                      {t("teamPageCreateSystemUserApplication")}
                    </InputLabel>
                    <Select
                      native
                      id="rol"
                      label={t("teamPageCreateSystemUserApplication")}
                      value={oidApplication}
                      onChange={handleApplication}
                      displayEmpty
                    >
                      {applicationList.map((element, i) => {
                        return (
                          <option value={element.applicationOid}>
                            {element.nameApplication}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    color="secondary"
                    value={otp}
                    onChange={handleChangeOtp}
                    variant="outlined"
                    fullWidth
                    name="Otp"
                    autoFocus
                    label={t("teamPageCreateSystemUserOtp")}
                    type="text"
                    id="Otp"
                    error={otpError}
                    helperText={otpErrorMessage}
                    className={commonClasses.inputsStyle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color="secondary"
                    value={password}
                    onInput={handleChangePassword}
                    variant="outlined"
                    fullWidth
                    name="password"
                    label={t("teamPageCreateSystemUserPassword")}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    error={passwordError}
                    helperText={
                      passwordError ? t("registerPagePasswordError") : ""
                    }
                    style={{ marginRight: "1px" }}
                    required
                    InputProps={{
                      className: classes.underline,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <img src={ico_pass_on} alt="pass_on" />
                            ) : (
                              <img src={ico_pass_off} alt="pass_off" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={commonClasses.inputsStyle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color="secondary"
                    value={checkPassword}
                    onInput={handleChangeCheckPassword}
                    variant="outlined"
                    fullWidth
                    name="checkPassword"
                    label={t("teamPageCreateSystemUserCheckPassword")}
                    type={showCheckPassword ? "text" : "password"}
                    id="checkPassword"
                    error={checkPasswordError}
                    helperText={
                      checkPasswordError
                        ? t("registerPageCheckPasswordError")
                        : ""
                    }
                    required
                    InputProps={{
                      className: classes.underline,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCheckPassword}
                            onMouseDown={handleMouseDownCheckPassword}
                          >
                            {showCheckPassword ? (
                              <img src={ico_pass_on} alt="pass_on" />
                            ) : (
                              <img src={ico_pass_off} alt="pass_off" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={commonClasses.inputsStyle}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  className={commonClasses.dialogButtonContainer}
                >
                  <Grid item xs={6}>
                    <CorreosButton
                      color="secondary"
                      className={commonClasses.dialogButtonLeft}
                      onClick={handleCancelCreate}
                    >
                      {t("cancel")}
                    </CorreosButton>
                  </Grid>
                  <Grid item xs={6}>
                    <CorreosButton
                      variant="contained"
                      color="primary"
                      className={commonClasses.dialogButtonRight}
                      onClick={handleCreateSystemUser}
                      disabled={
                        checkIsNullUndefined(password) ||
                        checkIsNullUndefined(checkPassword) ||
                        checkIsNullUndefined(otp) ||
                        passwordError ||
                        checkPasswordError
                      }
                      circularProgress={createCircularProgress}
                    >
                      {t("create")}
                    </CorreosButton>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        ) : (
          <Grid>
            <Container className={commonClasses.dialogTitle} style={{ paddingBottom: "1em" }}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography
                    variant="h6"
                    className={commonClasses.dialogTitleText}
                  >
                    {t("teamPageCreateSystemUserWarn")}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    edge="inherit"
                    className={commonClasses.dialogClosebutton}
                    color="inherit"
                    aria-label="menu"
                    onClick={handleCancelCreate}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        )}
      </Dialog>
    </Grid>
  );
}
