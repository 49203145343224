import React, { useEffect } from "react";
import {
  Typography,
  Container,
  Dialog,
  Grid,
} from "@material-ui/core";

import { CommonStyles } from "../../commons/CommonStyles";
import { useTranslation } from "react-i18next";
import { DialogStyles } from "../CommonComponents/Dialogs/DialogStyles";
import { checkIsNullUndefined } from "../../commons/Utils";
import { CorreosButton } from "../CorreosButton/CorreosButton";

export function BasicDialog(props) {
  const { open, setOpen, title, text, action, closeAction, justInfo } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const { t, i18n } = useTranslation();

  useEffect(() => { }, []);

  const handleCancel = () => {
    if (!checkIsNullUndefined(closeAction)) {
      closeAction();
    };
    setOpen(false);
  };

  const handleConfirm = () => {
    action();
    handleCancel();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleCancel}
      PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
    >
      <Container className={commonClasses.dialogTitle}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              className={commonClasses.dialogTitleText}
            >
              {title}
            </Typography>
          </Grid>

        </Grid>
      </Container>
      <Container className={commonClasses.dialogContainer}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography className={commonClasses.dialogContentText}>
              {text}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            className={commonClasses.dialogButtonContainer}
          >
            {!checkIsNullUndefined(justInfo) ? null : (
              <Grid item xs={6}>
                <CorreosButton
                  onClick={handleCancel}
                  className={commonClasses.dialogButtonLeft}
                >
                  {t("cancel")}
                </CorreosButton>
              </Grid>
            )}
            <Grid item xs={!checkIsNullUndefined(justInfo) ? 12 : 6} style={{ textAlign: checkIsNullUndefined(justInfo) ? null : "center" }}>
              <CorreosButton
                variant="contained"
                color="primary"
                onClick={handleConfirm}
                className={checkIsNullUndefined(justInfo) ? commonClasses.dialogButtonRight : commonClasses.dialogButtonCenter}
              >
                {t("accept")}
              </CorreosButton>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}
