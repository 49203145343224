export const ENTYC2 = {
  
    //Cookies Politicy TyC Tittles
  
    tycCookiesPolicityTittle1: "What are cookies?",
    tycCookiesPolicityTittle2: "What types of cookies are there?",
    tycCookiesPolicityTittle3: "What specific cookies are used on this website?",
    tycCookiesPolicityTittle4: "How can I change my cookie settings?",
  
    //Cookies Politicy TyC Texts
    
    tycCookiesPolicityText1:
      "Sociedad Estatal Correos y Telégrafos, S.A. (Hereafter, “Correos”) informs you as a user on the cookies on this website. This Cookies Policy may be subject to amendments, so we recommend users consult it every time they visit the website.",
    tycCookiesPolicityText2:
      "A cookie is a file or device that is downloaded to your computer/smartphone/tablet when certain web pages are accessed in order to store and retrieve information from the terminal device. Among other functions, cookies make it possible to store and retrieve information about your browsing habits in order to improve the service offered. A cookie is a small text file which a website uploads to your PC, phone or any other device, with information on your browsing of that website. Cookies are necessary to make website browing easier and more user-friendly, and do not harm your computer.",
    tycCookiesPolicityText3:
      "There are different types of cookies, depending on:",
    tycCookiesPolicityText4:
      "The entity managing the domain sending the cookies and processing the data",
    tycCookiesPolicityText5:
      "Own cookies: sent to your device from our own equipment or domains.",
    tycCookiesPolicityText6:
      "Third-party cookies: sent to your device from a computer or domain of a collaborating entity.",
    tycCookiesPolicityText7: "How long they remain stored in the browser",
    tycCookiesPolicityText8:
      "Session cookies: those that are activated while the user accesses the website or for the provision of the requested service.",
    tycCookiesPolicityText9:
      "Persistent cookies: stored for a given period on your device. The owner can access them when you visit their website.",
    tycCookiesPolicityText10: "The purpose of processing the data obtained",
    tycCookiesPolicityText11:
      "Technical cookies: those that are necessary for the use of the website and the provision of services.",
    tycCookiesPolicityText12:
      "Customisation cookies: those that allow the user to access the service with certain predefined general characteristics based on a series of criteria on your device (language, type of browser, etc.).",
    tycCookiesPolicityText13:
      "Analysis cookies: those that allow monitoring and statistical analysis of the behaviour of all users of the websites to which they are linked.",
    tycCookiesPolicityText14:
      "Advertising cookies: those that allow the management of advertising spaces on the website, application or platform from which the requested service is provided.",
    tycCookiesPolicityText15:
      "Behavioural advertising cookies: those that store information on your behaviour obtained through browsing habits, which allows the creation of a specific profile to show advertising.",
    tycCookiesPolicityText16:
      "The cookies used on our website, their description, duration of storage and ownership are identified below:",
    tycCookiesPolicityText17:
      "You can consent to the use of cookies or withdraw your consent at any time via the link ",
    tycCookiesPolicityText18_B: "Configuration of cookies.",
    tycCookiesPolicityText18:
      "You can also do this using your browser, enabling you to allow, restrict, block or delete the cookies used from our Website.",
    tycCookiesPolicityText19:
      "The way to do so differs depending on the type of browser used. The following links provide you with all the information you need to configure or disable cookies in each browser.",
    tycCookiesPolicityText20:
      "Internet Explorer: microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=»ie-10″",
    tycCookiesPolicityText21: "Firefox: mozilla.org/es/kb/Borrar%20cookies",
    tycCookiesPolicityText22: "Chrome: google.com/chrome/answer/95647?hl=»es»",
    tycCookiesPolicityText23: "Safari: apple.com/es/privacy/use-of-cookies/",
  
    //Cookies Politicy TyC Tables Texts

    tycCookiesPolicityTableHead1: "Name",
    tycCookiesPolicityTableHead2: "Purpose",
    tycCookiesPolicityTableHead3: "Duration",
    tycCookiesPolicityTableHead4: "Owner",

    //Table Tittles

    tycCookiesPolicityTableTittle1: "Personalisation and technical cookies",
    tycCookiesPolicityTableTittle2: "Analytics cookies",
    tycCookiesPolicityTableTittle3: "Behavioural advertising cookies",

    //Table Text

    session: "Session",
    persistent: "Persistent",

    //Tale 1

    tycCookiesPolicityTable1_Text1:
      "Used to identify and authenticate the user. They also contain technical data from the user session, such as time waiting to connect, session identifier, etc.",
    tycCookiesPolicityTable1_Text2:
      "Identifies the user’s http session. Shared by all web applications to identify user requests in a session",
    tycCookiesPolicityTable1_Text3:
      "Identifies the user’s browsing state (starting the session, first page, first access, scrolling state, status of a vote, etc.).",
    tycCookiesPolicityTable1_Text4:
      "They store the session values selected by the user, such as the shop, language, currency, products, size, etc.",

    //Table 2 and 3

    tycCookiesPolicityDoesntExist: "They do not exist on Correos ID",
  
    // Cookie Banner

    cookieBannerAcceptAll: "Aceptar",
    cookieBannerConfigure: "Configurar",
    cookieBannerWeUseCookies1: "Utilizamos cookies para personalizar el contenido, adaptar los anuncios, medir su eficacia y ofrecer una experiencia más segura. Al navegar por el sitio web, aceptas el uso de cookies para recopilar información dentro y fuera de Correos.",
    cookieBannerWeUseCookies2: "Lee nuestra ",
    cookieBannerWeUseCookies3: "Política de Cookies",
    cookieBannerWeUseCookies4: " para obtener más información o accede a las preferencias de cookies para gestionar tu configuración",
    cookieBannerLearnMore: "Puedes obtener más información en nuestra",
    cookieDialogDescriptionTPE: "Permiten la navegación a través de nuestra web y ejecutar la acción solicitada por el usuario. Por ejemplo, permitir la reproducción de contenido multimedia, registrar sus pedidos en el carrito de compra, reconocer inicios de sesión y la selección de idioma en función del lugar de acceso, etc.",
    cookieDialogDescriptionANA: "Ofrecen distintas métricas de uso de la web que nos permiten mejorar la navegación del usuario, conocer qué apartados de la web resultan más atractivos, desde dónde acceden los usuarios, etc. Se trata información de carácter estadístico y que es proporcionada a nivel agregado, salvo por el registro de la dirección IP desde la que accede.",
    cookieDialogDescriptionPCO: "Son aquéllas que almacenan información del comportamiento de los usuarios obtenida a través de los hábitos de navegación del usuario, lo que permite desarrollar un perfil específico para mostrar publicidad.",
    cookieDialogMandatoryCookie: "(Cookies estrictamente necesarias)",
    cookieDialogMandatory: "Obligatorio",
    cookieDialogAllow: "Permitir",
    cookieDialogDisallow: "No permitir",
    cookieDialogSave: "Guardar Preferencias",
    cookieDialogAllowAll: "Permitir Todo",
    cookieDialogDisallowAll: "No permitir nada",
    cookieDialogCookiesPolicyText: "Puedes obtener más información en nuestra",
  };
  