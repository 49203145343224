export const EnumPages = {
    Login: "Login",
    Registry: "Registry",
    RegistryEnterprise: "RegistryEnterprise",
    ResetPassword: "ResetPassword",
    Index: "Index",
    UserData: "UserData",
    UserVerifications: "UserVerifications",
    UserPrivacy: "UserPrivacy",
    EnterpriseData: "EnterpriseData",    
    EnterpriseVerifications: "EnterpriseVerifications",
    EnterprisePrivacy: "EnterprisePrivacy",
    EnterpriseTeam: "EnterpriseTeam",
    EnterpriseCreate: "EnterpriseCreate",
    UserServices: "UserServices",
    EnterpriseServices: "EnterpriseServices",
    ChooseUser: "ChooseUser", 
    UserCancelAccount: "UserCancelAccount",
    EnterpriseDelete: "EnterpriseDelete",
    PendingData: "PendingData",
    RequiredData: "RequiredData",
    UnifyAccounts: "UnifyAccounts",
    AuxUserData: "AuxUserData",
    AuxEnterpriseData: "AuxEnterpriseData",
};