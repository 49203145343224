import React from "react";
import {
  Grid,
  Container,
  Card,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Link,
} from "@material-ui/core";
import { TyCStyles } from "./TyCStyles";
import { EnumTyC } from "./EnumTyC";
import { useTranslation } from "react-i18next";

export function CookiesPolicityTyC(props) {
  const classes = TyCStyles();
  const { t, i18n } = useTranslation();

  return (
    <Grid
      className={
        !props.showSideMenu ? classes.container : classes.hideContainer
      }
    >
      <Container maxWidth={"lg"} className={classes.mainContainer}>
        <Card className={classes.cardContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography align="justify" className={classes.text}>
                <p>{t("tycCookiesPolicityText1")}</p>

                <h5 className={classes.subtitles}>
                  {t("tycCookiesPolicityTittle1")}🍪
                </h5>

                <p>{t("tycCookiesPolicityText2")}</p>

                <h5 className={classes.subtitles}>
                  {t("tycCookiesPolicityTittle2")} 📑
                </h5>

                <p>{t("tycCookiesPolicityText3")}</p>
                <p>{t("tycCookiesPolicityText4")}</p>
                <ul>
                  <li>{t("tycCookiesPolicityText5")}</li>
                  <li>{t("tycCookiesPolicityText6")}</li>
                </ul>
                <p>{t("tycCookiesPolicityText7")}</p>
                <ul>
                  <li>{t("tycCookiesPolicityText8")}</li>
                  <li>{t("tycCookiesPolicityText9")}</li>
                </ul>
                <p> {t("tycCookiesPolicityText10")}</p>
                <ul>
                  <li>{t("tycCookiesPolicityText11")}</li>
                  <li>{t("tycCookiesPolicityText12")}</li>
                  <li>{t("tycCookiesPolicityText13")}</li>
                  <li>{t("tycCookiesPolicityText14")}</li>
                  <li>{t("tycCookiesPolicityText15")}</li>
                </ul>

                <h5 className={classes.subtitles}>
                  {t("tycCookiesPolicityTittle3")} 🆔
                </h5>

                <p>{t("tycCookiesPolicityText16")}</p>
                <p align="center">
                  <b>{t("tycCookiesPolicityTableTittle1")}</b>
                </p>

                <Table>
                  <TableBody>
                    <TableRow className={classes.tableHead}>
                      <TableCell className={classes.tableHeadText}>
                        {t("tycCookiesPolicityTableHead1")}
                      </TableCell>
                      <TableCell className={classes.tableHeadText}>
                        {t("tycCookiesPolicityTableHead2")}
                      </TableCell>
                      <TableCell className={classes.tableHeadText}>
                        {t("tycCookiesPolicityTableHead3")}
                      </TableCell>
                      <TableCell className={classes.tableHeadText}>
                        {t("tycCookiesPolicityTableHead4")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>ARRAffinity</TableCell>
                      <TableCell rowSpan={8}>
                        <b>{t("tycCookiesPolicityTable1_Text1")}</b>
                      </TableCell>
                      <TableCell>{t("session")}</TableCell>
                      <TableCell>Correos</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>x-ms-cpim-csrf</TableCell>
                      <TableCell>{t("session")}</TableCell>
                      <TableCell>Microsoft</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>x-ms-cpim-cache</TableCell>
                      <TableCell>{t("session")}</TableCell>
                      <TableCell>Microsoft</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>x-ms-cpim-csrf</TableCell>
                      <TableCell>{t("session")}</TableCell>
                      <TableCell>Microsoft</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>x-ms-cpim-dc</TableCell>
                      <TableCell>{t("session")}</TableCell>
                      <TableCell>Microsoft</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>x-ms-cpim-slice</TableCell>
                      <TableCell>{t("session")}</TableCell>
                      <TableCell>Microsoft</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>x-ms-cpim-sso</TableCell>
                      <TableCell>{t("session")}</TableCell>
                      <TableCell>Microsoft</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>x-ms-cpim-trans</TableCell>
                      <TableCell>{t("session")}</TableCell>
                      <TableCell>Microsoft</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>.AspNet.Cookies</TableCell>
                      <TableCell rowSpan={2}>
                        <b>{t("tycCookiesPolicityTable1_Text2")}</b>
                      </TableCell>
                      <TableCell>{t("session")}</TableCell>
                      <TableCell>Correos</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>OpenIdConnect. nonce</TableCell>
                      <TableCell>{t("session")}</TableCell>
                      <TableCell>Correos</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>lastTab</TableCell>
                      <TableCell>
                        <b>{t("tycCookiesPolicityTable1_Text3")}</b>
                      </TableCell>
                      <TableCell>{t("session")}</TableCell>
                      <TableCell>Correos</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>lastTab</TableCell>
                      <TableCell>
                        <b>{t("tycCookiesPolicityTable1_Text4")}</b>
                      </TableCell>
                      <TableCell>{t("persistent")}</TableCell>
                      <TableCell>Correos</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <br />
                <p align="center">
                  <b>{t("tycCookiesPolicityTableTittle2")}</b>
                </p>
                <Table>
                  <TableBody>
                    <TableRow className={classes.tableHead}>
                      <TableCell className={classes.tableHeadText}>
                        {t("tycCookiesPolicityTableHead1")}
                      </TableCell>
                      <TableCell className={classes.tableHeadText}>
                        {t("tycCookiesPolicityTableHead2")}
                      </TableCell>
                      <TableCell className={classes.tableHeadText}>
                        {t("tycCookiesPolicityTableHead3")}
                      </TableCell>
                      <TableCell className={classes.tableHeadText}>
                        {t("tycCookiesPolicityTableHead4")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>{t("tycCookiesPolicityDoesntExist")}</b>
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableBody>
                </Table>
                <br />
                <p align="center">
                  <b>{t("tycCookiesPolicityTableTittle3")}</b>
                </p>

                <Table>
                  <TableBody>
                    <TableRow className={classes.tableHead}>
                      <TableCell className={classes.tableHeadText}>
                        {t("tycCookiesPolicityTableHead1")}
                      </TableCell>
                      <TableCell className={classes.tableHeadText}>
                        {t("tycCookiesPolicityTableHead2")}
                      </TableCell>
                      <TableCell className={classes.tableHeadText}>
                        {t("tycCookiesPolicityTableHead3")}
                      </TableCell>
                      <TableCell className={classes.tableHeadText}>
                        {t("tycCookiesPolicityTableHead4")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>{t("tycCookiesPolicityDoesntExist")}</b>
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableBody>
                </Table>

                <br />
                <h5 className={classes.subtitles}>
                  {t("tycCookiesPolicityTittle4")} ⚙
                </h5>
                <p>
                  {t("tycCookiesPolicityText17")}
                  <b>
                    <Link
                      className={classes.links}
                      onClick={() => props.changeId(EnumTyC.WarninLegal)}
                    >
                      {t("tycCookiesPolicityText18_B")}
                    </Link>
                  </b>
                  {t("tycCookiesPolicityText18")}
                </p>
                <p>{t("tycCookiesPolicityText19")}</p>
                <ul>
                  <li>{t("tycCookiesPolicityText20")}</li>
                  <li>{t("tycCookiesPolicityText21")}</li>
                  <li>{t("tycCookiesPolicityText22")}»</li>
                  <li>{t("tycCookiesPolicityText23")}</li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Grid>
  );
}
