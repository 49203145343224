import {
  GetMasterProvince,
  GetMasterCityProvince,
  GetMasterPostalCodeProvince,
  GetMasterProvincePostalCode,
  GetMasterCityPostalCode,
} from "../services/MasterCallsService";
import { checkIsNullUndefined, getErrorMessage } from "./Utils";
import { EnumCountries } from "../commons/EnumCountries";
import { isResultOk, showAlert } from "./FormManager.js";

//Funcionamiento conjunto al introducir algún valor
export const ManageAddressRelation = (usableModel, trigger, setAll, value, finishOperation, doTraduction) => {
  let auxCountryCodes = ["PAI", "EPI"];
  let auxProvinceCodes = ["PRO", "EPR"];
  let auxCityCodes = ["POB", "EPO"];
  let auxPostalCodes = ["CPO", "ECP"];
  let auxCountry = {
    value: usableModel.find(
      (attr) => auxCountryCodes.includes(attr.codeAttribute)).value,
  };
  let auxProv = {
    value: usableModel.find(
      (attr) => auxProvinceCodes.includes(attr.codeAttribute)).value,
  };
  let auxCity = {
    value: usableModel.find(
      (attr) => auxCityCodes.includes(attr.codeAttribute)).value,
  };
  let auxPoCo = {
    value: usableModel.find(
      (attr) => auxPostalCodes.includes(attr.codeAttribute)).value,
  };
  manageUsableModel(usableModel, auxProvinceCodes, auxProv, auxCityCodes, auxCity, auxPostalCodes, auxPoCo);
  if (checkIsControlledCountry(value, trigger, auxCountry, auxCountryCodes)) {
    let auxiliarFunctionValues = {
      first: false,
      setAll: setAll,
      select: null,
      isEdit: null,
      finishOperation: finishOperation,
      doTraduction: doTraduction
    }
    if (auxCountryCodes.includes(trigger)) {
      countryPath(value, auxCountry, auxProv, auxCity, auxPoCo, usableModel, auxiliarFunctionValues);
    } else if (auxProvinceCodes.includes(trigger)) {
      provincePath(value, auxCountry, auxProv, auxCity, auxPoCo, usableModel, auxiliarFunctionValues);
    } else if (auxCityCodes.includes(trigger)) {
      cityPath(value, auxCountry, auxProv, auxCity, auxPoCo, usableModel, setAll);
    } else if (auxPostalCodes.includes(trigger)) {
      auxPoCo.value = value;
      mandatoryPostalCode(
        auxCountry,
        auxProv,
        auxCity,
        auxPoCo,
        usableModel,
        null,
        auxiliarFunctionValues,
      );
    }
  } else {
    if (auxCountryCodes.includes(trigger)) {
      auxCountry.value = value;
      auxProv.value = "";
      auxProv.dropdownValues = [];
      auxProv.originalValues = [];
      auxCity.value = "";
      auxCity.dropdownValues = [];
      auxCity.originalValues = [];
      auxPoCo.value = "";
      auxPoCo.dropdownValues = [];
      auxPoCo.originalValues = [];
    } else if (auxProvinceCodes.includes(trigger)) {
      auxProv.value = value;
      auxCity.value = "";
      auxCity.dropdownValues = [];
      auxCity.originalValues = [];
      auxPoCo.value = "";
      auxPoCo.dropdownValues = [];
      auxPoCo.originalValues = [];
    } else if (auxCityCodes.includes(trigger)) {
      auxCity.value = value;
      auxPoCo.dropdownValues = auxPoCo.originalValues;
    } else if (auxPostalCodes.includes(trigger)) {
      auxPoCo.value = value;
      auxCity.dropdownValues = auxCity.originalValues;
    }
    let auxiliarModel = setAllAddressData(
      auxCountry,
      auxProv,
      auxCity,
      auxPoCo,
      usableModel
    );
    setAll(auxiliarModel);
  }
};

const manageUsableModel = (usableModel, auxProvinceCodes, auxProv, auxCityCodes, auxCity, auxPostalCodes, auxPoCo) => {
  usableModel.forEach((attribute) => {
    if (auxProvinceCodes.includes(attribute.codeAttribute)) {
      auxProv.dropdownValues = attribute.dropdownValues;
      auxProv.originalValues = attribute.originalValues;
    }
    if (auxCityCodes.includes(attribute.codeAttribute)) {
      auxCity.dropdownValues = attribute.dropdownValues;
      auxCity.originalValues = attribute.originalValues;
    }
    if (auxPostalCodes.includes(attribute.codeAttribute)) {
      auxPoCo.dropdownValues = attribute.dropdownValues;
      auxPoCo.originalValues = attribute.originalValues;
    }
  });
}

const checkIsControlledCountry = (value, trigger, auxCountry, auxCountryCodes) => {
  return (!checkIsNullUndefined(value) &&
    ((!auxCountryCodes.includes(trigger) &&
      (auxCountry.value == EnumCountries.ESP || auxCountry.value == EnumCountries.AND)) ||
      ((auxCountryCodes.includes(trigger)) &&
        (value == EnumCountries.ESP || value == EnumCountries.AND))));
}

const countryPath = (value, auxCountry, auxProv, auxCity, auxPoCo, usableModel, auxiliarFunctionValues) => {
  auxCountry.value = value;
  auxProv.value = "";
  auxProv.dropdownValues = [];
  auxProv.originalValues = [];
  auxCity.value = "";
  auxCity.dropdownValues = [];
  auxCity.originalValues = [];
  auxPoCo.value = "";
  auxPoCo.dropdownValues = [];
  auxPoCo.originalValues = [];
  GetMasterProvince().then((provinces) => {
    if (provinces && !provinces.Status) {
      auxProv.dropdownValues = provinces;
      auxProv.originalValues = provinces;
      if (auxCountry.value == EnumCountries.AND) {
        let auxprovince = provinces.filter((province) => province.code == "00");
        auxProv.dropdownValues = auxprovince;
        auxProv.originalValues = auxprovince;
      }
      let auxiliarModel = setAllAddressData(
        auxCountry,
        auxProv,
        auxCity,
        auxPoCo,
        usableModel
      );
      auxiliarFunctionValues.setAll(auxiliarModel);
    } else {
      auxiliarFunctionValues.finishOperation("error", getErrorMessage(provinces, auxiliarFunctionValues.doTraduction));
    };
  });
}

const provincePath = (value, auxCountry, auxProv, auxCity, auxPoCo, usableModel, auxiliarFunctionValues) => {
  auxProv.value = value;
  let getMasterCityProvince = GetMasterCityProvince(value, true);
  let getMasterPostalCodeProvince = GetMasterPostalCodeProvince(value, true);
  Promise.all([getMasterCityProvince, getMasterPostalCodeProvince]).then((results) => {
    let cities = results[0]
    let postalCodes = results[1];
    if (isResultOk([cities, postalCodes])) {
      auxCity.value = "";
      auxCity.dropdownValues = cities;
      auxCity.originalValues = cities;
      let auxDropdownValues = postalCodes.filter(
        (postalCode, index, self) =>
          index ===
          self.findIndex(
            (item) => item.postalCode === postalCode.postalCode
          )
      );
      auxPoCo.value = "";
      auxPoCo.dropdownValues = auxDropdownValues;
      auxPoCo.originalValues = postalCodes;
      let auxiliarModel = setAllAddressData(
        auxCountry,
        auxProv,
        auxCity,
        auxPoCo,
        usableModel
      );
      auxiliarFunctionValues.setAll(auxiliarModel);
    }else{
      showAlert([cities, postalCodes], auxiliarFunctionValues.finishOperation, auxiliarFunctionValues.doTraduction);
    }
  });
}

const cityPath = (value, auxCountry, auxProv, auxCity, auxPoCo, usableModel, setAll) => {
  auxCity.value = value;
  let auxCityPostalCodes = auxPoCo.originalValues.filter((postalCode) => {
    return postalCode.codeLocalidad == value;
  });
  auxPoCo.dropdownValues = auxCityPostalCodes;
  if (auxCityPostalCodes.length === 1) {
    auxPoCo.value = auxCityPostalCodes[0].postalCode;
  } else {
    if (
      checkIsNullUndefined(
        auxCityPostalCodes.find(
          (postCodObj) => postCodObj.postalCode === auxPoCo.value
        )
      )
    ) {
      auxPoCo.value = "";
    }
  }
  let auxiliarModel = setAllAddressData(
    auxCountry,
    auxProv,
    auxCity,
    auxPoCo,
    usableModel
  );
  setAll(auxiliarModel);
}

//Para cargar por primera vez los valores que vienen prerrellenados o vacios
export const ManageAddressFirstIteration = (
  usableModel,
  setAll,
  auxIsEdit,
  auxEditDirList,
  setIsControlledCountry,
  finishOperation,
  t
) => {
  let auxCountryCodes = ["PAI", "EPI"];
  let auxProvinceCodes = ["PRO", "EPR"];
  let auxCityCodes = ["POB", "EPO"];
  let auxPostalCodes = ["CPO", "ECP"];
  let auxCountry = {};
  let auxProv = {};
  let auxCity = {};
  let auxPoCo = {};
  // let auxIsEdit = !checkIsNullUndefined(auxauxIsEdit);
  usableModel.forEach((attribute) => {
    if (auxCountryCodes.includes(attribute.codeAttribute)) {
      auxCountry.value = attribute.value;
    }
    if (auxProvinceCodes.includes(attribute.codeAttribute)) {
      auxProv.value = attribute.value;
      auxProv.dropdownValues = [];
      auxProv.originalValues = [];
    }
    if (auxCityCodes.includes(attribute.codeAttribute)) {
      auxCity.value = attribute.value;
      auxCity.dropdownValues = [];
      auxCity.originalValues = [];
    }
    if (auxPostalCodes.includes(attribute.codeAttribute)) {
      auxPoCo.value = attribute.value;
      auxPoCo.dropdownValues = [];
      auxPoCo.originalValues = [];
    }
  });
  if (auxCountry.value == EnumCountries.ESP || auxCountry.value == EnumCountries.AND) {
    if (!checkIsNullUndefined(setIsControlledCountry)) {
      setIsControlledCountry(true);
    }
    let auxFunctionValues = { setAll: setAll, auxIsEdit: auxIsEdit, finishOperation: finishOperation, t: t }
    firstIterationControlledCountryPath(auxCountry, auxProv, auxCity, auxPoCo, usableModel, auxEditDirList, auxFunctionValues);
  } else {
    if (!checkIsNullUndefined(setIsControlledCountry)) {
      setIsControlledCountry(false);
    }
    let auxiliarModel = setAllAddressData(
      auxCountry,
      auxProv,
      auxCity,
      auxPoCo,
      usableModel
    );
    setAll(auxiliarModel, true, auxIsEdit, auxEditDirList);
  }
};

const firstIterationControlledCountryPath = (auxCountry, auxProv, auxCity, auxPoCo, usableModel, auxEditDirList, auxFunctionValues) => {
  GetMasterProvince().then((provinces) => {
    if (provinces && !provinces.Status) {
      auxProv.dropdownValues = provinces;
      auxProv.originalValues = provinces;
      if (auxCountry.value == EnumCountries.AND) {
        let auxprovince = provinces.filter((province) => province.code == "00");
        auxProv.dropdownValues = auxprovince;
        auxProv.originalValues = auxprovince;
      }
      if (!checkIsNullUndefined(auxPoCo.value)) {
        let auxiliarFunctionValues = {
          first: true,
          setAll: auxFunctionValues.setAll,
          select: true,
          isEdit: auxFunctionValues.auxIsEdit,
          finishOperation: auxFunctionValues.finishOperation,
        }
        mandatoryPostalCode(
          auxCountry,
          auxProv,
          auxCity,
          auxPoCo,
          usableModel,
          auxEditDirList,
          auxiliarFunctionValues
        );
      } else if (!checkIsNullUndefined(auxCity.value)) {
        if (!checkIsNullUndefined(auxProv.value)) {
          getCityAndCpValues(auxCountry, auxProv, auxCity, auxPoCo, usableModel, auxEditDirList, auxFunctionValues);
        } else {
          let auxiliarModel = setAllAddressData(
            auxCountry,
            auxProv,
            auxCity,
            auxPoCo,
            usableModel
          );
          auxFunctionValues.setAll(auxiliarModel, true, auxFunctionValues.auxIsEdit, auxEditDirList);
        }
      } else {
        let auxiliarModel = setAllAddressData(
          auxCountry,
          auxProv,
          auxCity,
          auxPoCo,
          usableModel
        );
        auxFunctionValues.setAll(auxiliarModel, true, auxFunctionValues.auxIsEdit, auxEditDirList);
      }
    } else {
      auxFunctionValues.finishOperation("error", getErrorMessage(provinces, auxFunctionValues.t));
    }
  });
};

const getCityAndCpValues = (auxCountry, auxProv, auxCity, auxPoCo, usableModel, auxEditDirList, auxFunctionValues) => {
  let getMasterCityProvince = GetMasterCityProvince(auxProv.value, true);
  let getMasterPostalCodeProvince = GetMasterPostalCodeProvince(auxProv.value, true);
  Promise.all([getMasterCityProvince, getMasterPostalCodeProvince]).then((results) => {
    auxCity.dropdownValues = results[0];
    auxCity.originalValues = results[0];
    auxPoCo.originalValues = results[1];
    if (isResultOk([auxCity.dropdownValues, auxCity.originalValues, auxPoCo.originalValues])) {
      let auxCityPostalCodes = auxPoCo.originalValues.filter(
        (postalCode) => {
          return postalCode.codeLocalidad == auxCity.value;
        }
      );
      auxPoCo.dropdownValues = auxCityPostalCodes;
      if (auxPoCo.dropdownValues.length === 1) {
        auxPoCo.value = auxPoCo.dropdownValues[0].code;
      }
      let auxiliarModel = setAllAddressData(
        auxCountry,
        auxProv,
        auxCity,
        auxPoCo,
        usableModel
      );
      auxFunctionValues.setAll(auxiliarModel, true, auxFunctionValues.auxIsEdit, auxEditDirList);
    } else {
      showAlert([auxCity.dropdownValues, auxCity.originalValues, auxPoCo.originalValues], auxFunctionValues.finishOperation, auxFunctionValues.doTraduction);
    }
  });
}

//Cuando el campo introducido es el código psotal
const mandatoryPostalCode = (
  auxCountry,
  auxProv,
  auxCity,
  auxPoCo,
  usableModel,
  auxEditDirList,
  auxiliarFunctionValues
) => {
  let auxPostalCode = auxPoCo.value;
  GetMasterProvincePostalCode(auxPostalCode, true).then((province) => {
    if(province && !province.Status){
      let auxProvince = province[0].code;
      let auxFunctionValues = {
        setAll: auxiliarFunctionValues.setAll,
        select: auxiliarFunctionValues.select,
        isEdit: auxiliarFunctionValues.isEdit
      }
      if (auxProv.value !== auxProvince || auxiliarFunctionValues.first) {
        auxProv.value = auxProvince;
        let getMasterPostalCodeProvince = GetMasterPostalCodeProvince(auxProvince, true);
        let getMasterCityProvince = GetMasterCityProvince(auxProvince, true);
        let getMasterCityPostalCode = GetMasterCityPostalCode(auxPostalCode, true);
        Promise.all([getMasterPostalCodeProvince, getMasterCityProvince, getMasterCityPostalCode]).then((results) => {
          let postalCodes = results[0];
          let allProvinceCities = results[1];
          let allCPCities = results[2];
          if(isResultOk([postalCodes, allProvinceCities, allCPCities])){
            let auxDropdownValues = postalCodes.filter(
              (postalCode, index, self) =>
                index ===
                self.findIndex(
                  (item) => item.postalCode === postalCode.postalCode
                )
            );
            auxPoCo.dropdownValues = auxDropdownValues;
            auxPoCo.originalValues = postalCodes;
            auxCity.originalValues = allProvinceCities;
            prepareCities(allCPCities, auxProv, auxCity, auxPoCo, auxiliarFunctionValues.finishOperation, auxiliarFunctionValues.doTraduction);
            managePostalCodePath(auxCity, auxCountry, auxPoCo, auxProv, usableModel, auxEditDirList, auxFunctionValues);
          }else{
            showAlert([postalCodes, allProvinceCities, allCPCities], auxiliarFunctionValues.finishOperation, auxiliarFunctionValues.doTraduction);
          }
        });
      } else {
        GetMasterCityPostalCode(auxPostalCode, true).then((allCPCities) => {
          if(allCPCities && !allCPCities.Status){
            prepareCities(allCPCities, auxProv, auxCity, auxPoCo, auxiliarFunctionValues.finishOperation, auxiliarFunctionValues.doTraduction);
            managePostalCodePath(auxCity, auxCountry, auxPoCo, auxProv, usableModel, auxEditDirList, auxFunctionValues);
          }else{
            auxiliarFunctionValues.finishOperation("error", getErrorMessage(allCPCities, auxiliarFunctionValues.doTraduction));
          }
        });
      }
    }else{
      auxiliarFunctionValues.finishOperation("error", getErrorMessage(province, auxiliarFunctionValues.doTraduction));
    }
  });
};

const prepareCities = (allCPCities, auxProv, auxCity, auxPoCo, finishOperation, doTraduction) => {
  auxCity.dropdownValues = allCPCities;
  if (allCPCities.length === 1) {
    auxCity.value = allCPCities[0].codeLocalidad;
  } else if (
    checkIsNullUndefined(
      allCPCities.find((city) => city.codeLocalidad === auxCity.value)
    )
  ) {
    if (!checkIsNullUndefined(auxCity.value)) {
      auxPoCo.value = "";
      auxProv.value = "";
      finishOperation("warning", doTraduction("userDataAddressUpdateWarning"));
    }
    auxCity.value = "";
  }
  let auxCityPostalCodes = [];
  auxPoCo.originalValues.forEach((postalCode) => {
    allCPCities.forEach((city) => {
      if (city.code === postalCode.codeLocalidad) {
        auxCityPostalCodes.push(postalCode);
      }
    });
  });
  auxPoCo.dropdownValues = auxCityPostalCodes;
}

const managePostalCodePath = (auxCity, auxCountry, auxPoCo, auxProv, usableModel, auxEditDirList, auxFunctionValues) => {
  let auxiliarModel = setAllAddressData(
    auxCountry,
    auxProv,
    auxCity,
    auxPoCo,
    usableModel
  );
  auxFunctionValues.setAll(auxiliarModel, auxFunctionValues.select, auxFunctionValues.isEdit, auxEditDirList);
}

const setAllAddressData = (
  auxCountry,
  auxProv,
  auxCity,
  auxPoCo,
  auxUsableMod
) => {
  let auxiliarModel = JSON.parse(JSON.stringify(auxUsableMod));
  auxiliarModel.forEach((element) => {
    if (element.codeAttribute === "PAI" || element.codeAttribute === "EPI") {
      element.value = auxCountry.value;
    }
    if (element.codeAttribute === "PRO" || element.codeAttribute === "EPR") {
      element.value = checkIsNullUndefined(auxProv.value) ? "" : auxProv.value;
      element.dropdownValues = auxProv.dropdownValues;
      element.originalValues = auxProv.originalValues;
    }
    if (element.codeAttribute === "POB" || element.codeAttribute === "EPO") {
      element.value = checkIsNullUndefined(auxCity.value) ? "" : auxCity.value;
      element.dropdownValues = auxCity.dropdownValues;
      element.originalValues = auxCity.originalValues;
    }
    if (element.codeAttribute === "CPO" || element.codeAttribute === "ECP") {
      element.value = checkIsNullUndefined(auxPoCo.value) ? "" : auxPoCo.value;
      element.dropdownValues = auxPoCo.dropdownValues;
      element.originalValues = auxPoCo.originalValues;
    }
  });
  return auxiliarModel;
};
