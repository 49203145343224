import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { BackgroundLogin } from "../../components/Templates/BackgroundLogin";
import { ResetPassword } from "../../components/ResetPassword/ResetPassword";
import { Constants } from "../../commons/Constants";
import { GeneralWarning } from "../../components/CommonComponents/GeneralWarning/GeneralWarning";
import { generalWarning } from "../../commons/EnumsGeneral";

export function ResetPasswordPage(props) {
  const { masterCountryList } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [isEmail, setIsEmail] = useState(true);

  useEffect(() => { }, []);

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const previousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const reStart = () => {
    setActiveStep(0);
  };

  return (
    <Grid style={{ marginTop: Constants.MarginTop, display: "flow-root" }}>
      <GeneralWarning open={open} setOpen={setOpen} isEmail={isEmail} warnType={generalWarning.pass}/>
      <BackgroundLogin resetPass={true} step={activeStep}>
        <ResetPassword
          step={activeStep}
          nextStep={nextStep}
          previousStep={previousStep}
          initialStep={reStart}
          masterCountryList={masterCountryList}
          setOpen={setOpen}
          setIsEmail={setIsEmail}
        />
      </BackgroundLogin>
    </Grid>
  );
}
