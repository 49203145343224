import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Container,
  Dialog,
  Grid,
  TextField,
  IconButton,
  Tooltip,
  FormControl,
  Select,
  InputLabel,
  Popper,
  Paper,
} from "@material-ui/core";
import { CorreosButton } from "../../../components/CorreosButton/CorreosButton";
import { CommonStyles } from "../../../commons/CommonStyles";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import {
  checkIsNullUndefined,
  getErrorMessage,
  useOutsideAlerter,
} from "../../../commons/Utils";
import {
  PostProfileUserCreateOrUpdateDocument,
  DeleteVerificationDataDocument,
} from "../../../services/ReasonService";
import { useTranslation } from "react-i18next";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import { AiOutlineEdit } from "react-icons/ai";
import DeleteIcon from "@material-ui/icons/Delete";
import { Constants } from "../../../commons/Constants";
import { userDocument } from "../../../commons/ConstantObjects";
import { RequiredDataStyles } from "../RequiredDataStyles";
import { EnumFirstSelectOption } from "../../../commons/EnumsGeneral";
import { AttributeCodes } from "../../../commons/Attributes";

export function DialogDocumentActions(props) {
  const {
    countryList,
    typeUserDocument,
    userModel,
    handleDocumentActions,
    finishOperation,
    editableDocuments,
    userNeedsDocument,
    auxiliarTab,
  } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const classes = RequiredDataStyles();
  const { t, i18n } = useTranslation();
  const wrapperRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [operationType, setOperationType] = useState("");
  const [docData, setDocData] = useState({
    [AttributeCodes.DocType]: { value: "", required: "", label: "" },
    [AttributeCodes.DocNumber]: { value: "", required: "", label: "" },
    [AttributeCodes.ExpedCountry]: { value: 67, required: "", label: "" },
    [AttributeCodes.ExpirDate]: { value: "", required: "", label: "" },
  });
  const [docTypeSelected, setDocTypeSelected] = useState();
  const [hasValue, setHasValue] = useState("");
  const [docCountryOptions, setDocCountryOptions] = useState([]);
  const [circularProgress, setCircularProgress] = useState(false);
  const [error, setError] = useState(false);
  const [isAllOk, setIsAllOk] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [docSizes, setDocSizes] = useState(3);

  useEffect(() => {
    let auxNewDocTypeSelected = userModel.find(
      (attribute) => attribute.codeAttribute === AttributeCodes.DocType
    );
    if (
      !checkIsNullUndefined(operationType) ||
      auxNewDocTypeSelected != docTypeSelected
    ) {
      if (docCountryOptions.length === 0) {
        setDocCountryOptions(countryList);
      };
      let auxDocData = JSON.parse(JSON.stringify(docData));
      const auxDocValues = [AttributeCodes.DocType, AttributeCodes.DocNumber, AttributeCodes.ExpedCountry, AttributeCodes.ExpirDate];
      auxDocValues.forEach((docValue) => {
        let auxDocValue = userModel.find(
          (attribute) => attribute.codeAttribute === docValue
        );
        if (!checkIsNullUndefined(auxDocValue)) {
          manageDocValues(auxDocValue, auxDocData, docValue);
        };
      });
      // 1 DNI - 2 EUR - 3 NIE
      let auxHasValue = false;
      let auxDocTypeSelected = auxDocData[AttributeCodes.DocType].value;
      if (editableDocuments.length === 0) {
        auxDocTypeSelected = handleDocumentTypeList()[0].id;
      } else {
        let auxDocSelected = editableDocuments.find((document) => document.idTypeUserDocument == auxDocTypeSelected);
        auxHasValue = !checkIsNullUndefined(auxDocSelected) && !checkIsNullUndefined(auxDocSelected.number);
      };
      setDocTypeSelected(auxDocTypeSelected);
      setHasValue(auxHasValue);
      auxDocData[AttributeCodes.DocType].value = auxDocTypeSelected;
      setDocData(auxDocData);
    }
  }, [operationType, userModel]);

  const manageDocValues = (auxDocValue, auxDocData, docValue) => {
    if (docValue === AttributeCodes.ExpirDate) {
      auxDocValue.value = auxDocValue.value?.split("T")[0];
    }
    auxDocData[docValue].value = checkIsNullUndefined(auxDocValue.value)
      ? ""
      : auxDocValue.value;
    auxDocData[docValue].required =
      docValue === AttributeCodes.DocNumber ? true : auxDocValue.mandatory;
    auxDocData[docValue].label = auxDocValue.nameAttritube;
  };

  useEffect(() => {
    checkAllOk();
  }, [docData]);

  useEffect(() => {
    if (!open) {
      setOperationType("");
    };
  }, [open]);

  useEffect(() => {
    if(open){
      let auxFieldsNumber = 0;
      let auxFieldsEquivalSize = { 0: 3, 1: 12, 2: 6, 3: 4, 4: 3 };
      let auxDocAttributes = [AttributeCodes.DocType, AttributeCodes.DocNumber, AttributeCodes.ExpedCountry, AttributeCodes.ExpirDate];
      auxDocAttributes.forEach((docAttribute) => {
        if (docData[docAttribute]?.required || (!checkIsNullUndefined(docData[docAttribute]?.value) && (docAttribute != AttributeCodes.ExpirDate || docData[docAttribute]?.value != "yyyy-MM-dd"))) {
          auxFieldsNumber++;
        };
      });
      setDocSizes(auxFieldsEquivalSize[auxFieldsNumber]);
    };
  }, [open]);

  const handleOpen = (event, operationType) => {
    if (operationType === "delete") {
      setAnchorEl(event.currentTarget);
    }
    setOperationType(operationType);
    setOpen(true);
  };

  const handleCancel = () => {
    setDocData({
      [AttributeCodes.DocType]: { value: "", required: "", label: "" },
      [AttributeCodes.DocNumber]: { value: "", required: "", label: "" },
      [AttributeCodes.ExpedCountry]: { value: 67, required: "", label: "" },
      [AttributeCodes.ExpirDate]: { value: "", required: "", label: "" },
    });
    setOperationType("");
    setAnchorEl(null);
    setOpen(false);
  };

  const handleChange = (event, docKey) => {
    let auxDocData = JSON.parse(JSON.stringify(docData));
    auxDocData[docKey].value = event.target.value;
    setDocData(auxDocData);
    if (docKey === AttributeCodes.DocType) {
      setDocTypeSelected(event.target.value);
    };
  };

  const handleAction = () => {
    setCircularProgress(true);
    //llamadas al back;
    let auxTdo = parseInt(docData[AttributeCodes.DocType].value, 10);
    let auxNdo = docData[AttributeCodes.DocNumber].value;
    let auxPex = docData[AttributeCodes.ExpedCountry].value;
    let auxFca = docData[AttributeCodes.ExpirDate].value;
    let auxDocument = {};
    if (operationType === "add") {
      auxDocument = JSON.parse(JSON.stringify(userDocument));
    } else {
      let auxEditableDocuments = JSON.parse(JSON.stringify(editableDocuments));
      auxDocument = auxEditableDocuments.find(
        (document) => document.idTypeUserDocument == docTypeSelected
      );
    }
    if (operationType !== "delete") {
      auxDocument.idTypeUserDocument = auxTdo;
      auxDocument.number = auxNdo;
      auxDocument.idCountry = checkIsNullUndefined(auxPex)
        ? null
        : parseInt(auxPex, 10);
      auxDocument.expirationDate =
        auxFca === "yyyy-MM-dd" || auxFca === "" ? null : auxFca;
      PostProfileUserCreateOrUpdateDocument(auxDocument).then((response) => {
        if (response && !response.Status) {
          handleDocumentActions(
            operationType,
            auxTdo,
            auxNdo,
            auxPex,
            auxFca,
            response
          );
          handleCancel();
        } else {
          finishOperation("error", getErrorMessage(response, t));
        }
        setCircularProgress(false);
      });
    } else {
      DeleteVerificationDataDocument(docTypeSelected, auxDocument.number).then(
        (response) => {
          if (response && !response.Status) {
            handleDocumentActions(
              operationType,
              auxTdo,
              auxNdo,
              auxPex,
              auxFca,
              auxDocument.id
            );
            handleCancel();
          } else {
            finishOperation("error", getErrorMessage(response, t));
          }
          setCircularProgress(false);
        }
      );
    }
  };

  const checkAllOk = () => {
    let isAllOk = true;
    let auxHasDocError = docTypeSelected != 2 ? validateDoc() : false;
    const auxDocValues = [AttributeCodes.DocNumber, AttributeCodes.ExpedCountry, AttributeCodes.ExpirDate];
    if (!auxHasDocError) {
      auxDocValues.forEach((docValue) => {
        if (
          docData[docValue].required &&
          (checkIsNullUndefined(docData[docValue].value) || (docValue === AttributeCodes.ExpirDate && docData[docValue].value == "yyyy-MM-dd"))
        ) {
          isAllOk = false;
        }
      });
    } else {
      isAllOk = false;
    }
    setError(auxHasDocError);
    setIsAllOk(isAllOk);
  };

  const validateDoc = () => {
    let number = docData[AttributeCodes.DocNumber].value.toUpperCase();
    if (!checkIsNullUndefined(number) && docTypeSelected == 1 && number.length < 9 && /[A-Z]$/.test(number)) {
      while (number.length < 9) {
        number = "0" + number;
      }
      docData[AttributeCodes.DocNumber].value = number
    }
    let hasError = false;
    let regularExp =
      docTypeSelected == 1
        ? /^\d{8}[A-Z]$/
        : docTypeSelected == 3
          ? /^[XYZ]?\d{7}[A-Z]$/
          : "";

    if (!checkIsNullUndefined(regularExp) && regularExp.test(number) === true) {
      let charList = "TRWAGMYFPDXBNJZSQVHLCKET";
      let auxNumber = number.substring(0, number.length - 1);
      if (docTypeSelected == 3) {
        auxNumber = auxNumber.replace("X", 0);
        auxNumber = auxNumber.replace("Y", 1);
        auxNumber = auxNumber.replace("Z", 2);
      }
      let char = number.slice(-1);
      auxNumber = auxNumber % 23;
      charList = "TRWAGMYFPDXBNJZSQVHLCKET";
      let auxChar = charList.substring(auxNumber, auxNumber + 1);
      if (auxChar != char) {
        hasError = true;
      }
    } else {
      hasError = true;
    }
    return hasError;
  };

  useOutsideAlerter(wrapperRef, handleCancel);

  const handleDisabled = () => {
    return (hasValue || checkIsNullUndefined(docTypeSelected) || (docTypeSelected == EnumFirstSelectOption))
  };

  const handleDocumentTypeList = () => {
    let auxList = typeUserDocument;
    if (operationType === "add") {
      let auxOwnedDocTypes = editableDocuments.map((document) => document.idTypeUserDocument);
      auxList = typeUserDocument.filter((document) => !auxOwnedDocTypes.includes(document.id));
    };
    return auxList;
  };

  return (
    <Grid
      container
      item
      xs={12}
      spacing={1}
      className={classes.iconsContainer}
    >
      {editableDocuments.length == 0 ? (
        <Grid item xs={12}>
          <Tooltip title={t("add")}>
            <span>
              <CorreosButton
                color="primary"
                variant="contained"
                className={commonClasses.dialogButtonCenter}
                onClick={(event) => handleOpen(event, "add")}
              >
                <AddToPhotosIcon style={{ marginRight: "0.2em" }} />
                {t("idDocument")}
                {userNeedsDocument ? " *" : ""}
              </CorreosButton>
            </span>
          </Tooltip>
        </Grid>
      ) : (
        <>
          <Grid item xs={4} className={classes.iconContainer}>
            <Tooltip title={t("add")}>
              <IconButton
                // edge="inherit"
                classes={{ root: classes.iconButton }}
                onClick={(event) => handleOpen(event, "add")}
                disabled={handleDisabled()}
              >
                <AddToPhotosIcon
                  className={
                    handleDisabled()
                      ? commonClasses.iconDisabledStyle
                      : commonClasses.iconActionStyle
                  }
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={4} className={classes.iconContainer}>
            <Tooltip title={t("update")}>
              <IconButton
                // edge="inherit"
                classes={{ root: classes.iconButton }}
                onClick={(event) => handleOpen(event, "change")}
                disabled={!hasValue || checkIsNullUndefined(docTypeSelected)}
              >
                <AiOutlineEdit
                  className={
                    !hasValue || checkIsNullUndefined(docTypeSelected)
                      ? commonClasses.iconDisabledStyle
                      : commonClasses.iconActionStyle
                  }
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={4} className={classes.iconContainer}>
            <Tooltip title={t("delete")}>
              <IconButton
                // edge="inherit"
                classes={{ root: classes.iconButton }}
                onClick={(event) => handleOpen(event, "delete")}
                disabled={!hasValue || checkIsNullUndefined(docTypeSelected)}
              >
                <DeleteIcon
                  className={
                    !hasValue || checkIsNullUndefined(docTypeSelected)
                      ? commonClasses.iconDisabledStyle
                      : commonClasses.iconActionStyle
                  }
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </>
      )}
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open && operationType != "delete"}
        onClose={handleCancel}
        PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
      >
        <Container className={commonClasses.dialogTitle}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={commonClasses.dialogTitleText}
              >
                {operationType === "add"
                  ? t("userDataAddDoc")
                  : operationType === "change"
                    ? t("userDataModifyDoc")
                    : null}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={commonClasses.dialogContainer}>
          <Grid container spacing={1}>
            {auxiliarTab || (docData[AttributeCodes.DocType]?.required || !checkIsNullUndefined(docData[AttributeCodes.DocType]?.value)) ? (
              <Grid item md={docSizes} xs={12}>
                <FormControl
                  name="docCountry"
                  variant="outlined"
                  fullWidth
                  required={docData[AttributeCodes.DocType].required}
                >
                  <InputLabel label={docData[AttributeCodes.DocType].label} color="secondary">
                    {docData[AttributeCodes.DocType].label}
                  </InputLabel>
                  <Select
                    value={docData[AttributeCodes.DocType].value}
                    label={docData[AttributeCodes.DocType].label}
                    onChange={(event) => handleChange(event, AttributeCodes.DocType)}
                    nulleable={false}
                    color="secondary"
                    native
                    disabled={operationType !== "add"}
                  >
                    {handleDocumentTypeList().map((dvalue) => {
                      return (
                        <option key={dvalue.code} value={dvalue.id}>
                          {dvalue.name}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            {auxiliarTab || (docData[AttributeCodes.DocNumber]?.required || !checkIsNullUndefined(docData[AttributeCodes.DocNumber]?.value)) ? (
              <Grid item md={docSizes} xs={12}>
                <TextField
                  name="docNumber"
                  value={docData[AttributeCodes.DocNumber].value}
                  label={docData[AttributeCodes.DocNumber].label}
                  onChange={(event) => handleChange(event, AttributeCodes.DocNumber)}
                  variant="outlined"
                  fullWidth
                  color="secondary"
                  required={docData[AttributeCodes.DocNumber].required}
                  error={error && !checkIsNullUndefined(docData[AttributeCodes.DocNumber].value)}
                  helperText={
                    error && !checkIsNullUndefined(docData[AttributeCodes.DocNumber].value)
                      ? t("registerPageNDOError")
                      : ""
                  }
                  className={commonClasses.inputsStyle}
                />
              </Grid>
            ) : null}
            {auxiliarTab || (docData[AttributeCodes.ExpedCountry]?.required || !checkIsNullUndefined(docData[AttributeCodes.ExpedCountry]?.value)) ? (
              <Grid item md={docSizes} xs={12}>
                <FormControl
                  name="docCountry"
                  variant="outlined"
                  fullWidth
                  required={docData[AttributeCodes.ExpedCountry].required}
                >
                  <InputLabel label={docData[AttributeCodes.ExpedCountry].label} color="secondary">
                    {docData[AttributeCodes.ExpedCountry].label}
                  </InputLabel>
                  <Select
                    value={docData[AttributeCodes.ExpedCountry].value}
                    label={docData[AttributeCodes.ExpedCountry].label}
                    onChange={(event) => handleChange(event, AttributeCodes.ExpedCountry)}
                    nulleable={false}
                    color="secondary"
                    native
                  >
                    {docCountryOptions.map((dvalue) => {
                      return (
                        <option key={dvalue.code} value={dvalue.id}>
                          {dvalue.name}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            {auxiliarTab || (docData[AttributeCodes.ExpirDate]?.required || (!checkIsNullUndefined(docData[AttributeCodes.ExpirDate]?.value) && docData[AttributeCodes.ExpirDate]?.value != "yyyy-MM-dd")) ? (
              <Grid item md={docSizes} xs={12}>
                <TextField
                  type="date"
                  name="docDate"
                  value={docData[AttributeCodes.ExpirDate].value}
                  label={docData[AttributeCodes.ExpirDate].label}
                  onInput={(event) => handleChange(event, AttributeCodes.ExpirDate)}
                  variant="outlined"
                  fullWidth
                  color="secondary"
                  required={docData[AttributeCodes.ExpirDate].required}
                  inputProps={{ min: Constants.minDate, max: Constants.maxDate }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputFormat="DD/MM/YYYY"
                  className={commonClasses.inputsStyle}
                />
              </Grid>
            ) : null}
            <Grid
              item
              container
              xs={12}
              className={commonClasses.dialogButtonContainer}
              style={{ marginTop: "20px", paddingRight: "6px" }}
            >
              <Grid item xs={6}>
                <CorreosButton
                  onClick={() => handleCancel()}
                  className={commonClasses.dialogButtonLeft}
                >
                  {t("cancel")}
                </CorreosButton>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  variant="contained"
                  color="primary"
                  onClick={handleAction}
                  className={commonClasses.dialogButtonRight}
                  disabled={!isAllOk}
                  circularProgress={circularProgress}
                >
                  {operationType === "change" ? t("update") : t("add")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
      <Popper
        open={open && operationType === "delete"}
        anchorEl={anchorEl}
        placement={"top"}
        style={{ zIndex: 100 }}
        ref={wrapperRef}
      >
        <Paper>
          <Container>
            <Grid container style={{ paddingTop: "1em", paddingBottom: "1em" }}>
              <Grid item xs={12}>
                <Typography className={commonClasses.popperContentText}>
                  {t("userDataDeleteDoc")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  size="small"
                  onClick={handleCancel}
                  className={commonClasses.leftButton}
                >
                  {t("no")}
                </CorreosButton>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleAction}
                  className={commonClasses.rightButton}
                  circularProgress={circularProgress}
                >
                  {t("yes")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Popper>
    </Grid>
  );
}
