import React, { useRef, useEffect, useState } from "react";
import {
  Typography,
  Container,
  Grid,
  Paper,
  Button,
  Popper,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { CommonStyles } from "../../../commons/CommonStyles";
import { EnterpriseTeamStyles } from "../EnterpriseTeamStyles";
import { DeleteEnterpriseDataEnterpriseItems } from "../../../services/EnterpriseDataCallsService";
import {
  getErrorMessage,
  useOutsideAlerter,
} from "../../../commons/Utils";
import { MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";

export function DialogDeleteItem(props) {
  const { finishOperation, item, idEnterprise, loadItems } = props;
  const commonClasses = CommonStyles();
  const classes = EnterpriseTeamStyles();
  const wrapperRef = useRef(null);
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(null);

  useEffect(() => { }, []);

  const handleCancel = () => {
    setAnchorEl(null);
    setOpenDelete(false);
  };

  const handleConfirm = () => {
    DeleteEnterpriseDataEnterpriseItems(idEnterprise, item.id).then((response) => {
    if (response && !response.Status) {
      loadItems();
      finishOperation("success", t("teamPageItemDeleteSuccess"));
    } else {
      finishOperation("error", getErrorMessage(response, t));
    }
    setOpenDelete(false);
    });
  };

  const handleDelete = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenDelete(true);
  };

  useOutsideAlerter(wrapperRef, handleCancel);

  return (
    <Grid>
      <Tooltip title={t("teamPageItemDelete")}>
        <IconButton
          className={classes.iconButton}
          onClick={(event) => handleDelete(event)}
          style={{ float: "right" }}
        >
          <MdDelete className={commonClasses.iconActionStyle}/>
        </IconButton>
      </Tooltip>
      <Popper
        open={openDelete}
        anchorEl={anchorEl}
        placement={"top"}
        style={{ zIndex: 100 }}
        ref={wrapperRef}
      >
        <Paper>
          <Container>
            <Grid
              container
              style={{ paddingTop: "1em", paddingBottom: "1em" }}
            >
              <Grid item xs={12}>
                <Typography className={commonClasses.popperContentText}>
                  {t("verificatinosDeleteElement")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="small"
                  onClick={() => handleCancel()}
                  className={commonClasses.leftButton}
                  id={"teamPageEnterpriseItem"}
                >
                  {t("no")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => handleConfirm()}
                  className={commonClasses.rightButton}
                >
                  {t("yes")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Popper>
      
    </Grid>
  );
}
