export const VATYC1 = {

    //TyCs and Privacies Page

    tycUrlsText: "Si us plau, llegiu atentament els termes i condicions d'ús fent clic sobre els següents enllaços:",
    privacyUrlsText: "Si us plau, llegiu atentament les polítiques de privadesa fent clic sobre els següents enllaços:",
    privacyNoUrlsText: "Para la aplicación y el tipo de persona indicados no existe privacidad que firmar.",
    tycNoUrlsText: "Para la aplicación y el tipo de persona indicados no existen TyC's que firmar.",
    atcText: "Un cop comunicat el codi de verificació a l'operari ATC es consideraran acceptats els termes i condicions d'ús.",

    //Terms and Conditions

    tycHeaderTitle: "Termes i condicions",

    //WarningLegalTyC Subtittles

    tycWarningLegalSubtittle1:
        "DRETS DE PROPIETAT INTEL·LECTUAL I DE PROPIETAT INDUSTRIAL",
    tycWarningLegalSubtittle2: "PROTECCIÓN DE DADES DE CARÀCTER PERSONAL",
    tycWarningLegalSubtittle3: "MESURES DE SEGURETAT",
    tycWarningLegalSubtittle4: "RESPONSABILITATS DE CORREOS",
    tycWarningLegalSubtittle5: "RESPONSABILITAT DE L’USUARI",
    tycWarningLegalSubtittle6: "LLEI APLICABLE I JURISDICCIÓ",

    //WarningLegalTyC texts

    tycWarningLegalText1:
        "En compliment del que establix l’art. 10 de la Llei 34/2002, d’11 de juliol, de Servicis de la Societat de la Informació i Comerç Electrònic (LSSI-CE), s’informa que esta pàgina web és titularitat de Correos.",
    tycWarningLegalText2:
        "Este document conté l’Avís legal de Correos ID proporcionat per la Sociedad Estatal Correos y Telégrafos, SA (d’ara endavant, “Correos”), amb domicili social a la via Dublín, núm. 7 (Campo de las Naciones) 28070 Madrid (Espanya), CIF número A83052407.",
    tycWarningLegalText3:
        "El contacte per a l’atenció al client del servici és per mitjà telefònic a través del número d’Atenció al Client de Correos: 902.197.197 o a la pàgina web de Correos.",
    tycWarningLegalText4:
        "Tant el disseny del portal i els seus codis font, com els logotips, les marques i altres signes distintius que hi apareixen pertanyen a Correos i estan protegits pels corresponents drets de propietat intel·lectual i industrial.",
    tycWarningLegalText5:
        "El seu ús, reproducció, distribució, comunicació pública, transformació o qualsevol altra activitat similar o anàloga queda totalment prohibit, llevat que hi haja autorització expressa per escrit de Correos.",
    tycWarningLegalText6:
        "Els continguts del portal estan igualment protegits per drets de propietat intel·lectual de Correos. L’ús dels continguts elaborats per Correos o per alguna de les entitats del seu Grup es permet únicament en l’àmbit d’una relació contractual amb esta o amb les entitats esmentades.",
    tycWarningLegalText7:
        "Correos declara el seu respecte als drets de propietat intel·lectual i industrial de tercers; per això, si considera que este lloc pot estar violant els seus drets, li preguem que es pose en contacte amb Correos al número de telèfon següent: 902.197.197.",
    tycWarningLegalText8_1:
        "La navegació a través de la nostra pàgina web no requerix el seu registre previ. No obstant això, quan vosté visita la nostra pàgina web, els nostres servidors web emmagatzemen, de manera estàndard, informació com l’adreça IP i el domini des del qual s’obté l’accés, la data i l’hora de la visita, etc. D’altra banda, certes funcionalitats de la nostra pàgina web exigixen que facilite informació addicional a través del formulari corresponent (p. ex. el seu nom i cognoms, adreça postal o electrònica de contacte, telèfon...). Correos tractarà les seues dades de caràcter personal d’acord amb les condicions publicades en la ",
    tycWarningLegalText8_B: "Política de privacitat web ",
    tycWarningLegalText8_2:
        "i, si escau, en la Política de protecció de dades que resulte aplicable als diferents servicis sol·licitats.",
    tycWarningLegalText9:
        "L’informem que s’han adoptat totes les mesures de seguretat d’índole tècnica, organitzativa i de seguretat informàtica necessàries per a la protecció de dades de caràcter personal per a evitar-ne l’alteració, la pèrdua, el tractament i/o l’accés no autoritzat, tot això d’acord amb el que establix la normativa aplicable.",
    tycWarningLegalText10:
        "Ús incorrecte del portal: Correos ha creat el portal per a la promoció dels seus productes i per a facilitar l’accés als seus servicis, però no pot controlar-ne la utilització d’una manera diferent de la prevista en este Avís legal; per tant, l’accés al portal i l’ús correcte de la informació que hi conté són responsabilitat de qui realitza estes accions, i Correos no és responsable per l’ús incorrecte, il·lícit o negligent que en poguera fer l’usuari.",
    tycWarningLegalText11:
        "Utilització dels continguts: Correos facilita tots els continguts del seu portal (Correos ID) de bona fe i farà els seus millors esforços perquè estiguen permanentment actualitzats i vigents. No obstant això, Correos no pot assumir cap responsabilitat respecte de l’ús o l’accés que facen els usuaris fora de l’àmbit a què es dirigix el portal, ni de les conseqüències que poguera portar l’aplicació pràctica de les opinions, les recomanacions o els estudis a què es puga accedir a través del portal, i la seua responsabilitat final recaurà sempre sobre l’usuari. Així mateix, Correos no pot controlar els continguts que no hagen sigut elaborats per ella o per tercers complint el seu encàrrec i, per tant, no respondrà en cap cas dels danys que es pogueren causar per eixos continguts de tercers.",
    tycWarningLegalText12:
        "Publicitat: Correos inclourà en el portal publicitat pròpia o de tercers per a oferir-li productes o servicis que entenga que poden ser del seu interés. Tanmateix, Correos no pot controlar l’aparença d’eixa publicitat, ni la qualitat i l’adequació d’eixos productes o servicis i, en conseqüència, Correos no respondrà de cap dany que es poguera generar a l’usuari per estes causes.",
    tycWarningLegalText13:
        "Virus: Correos es compromet a aplicar totes les mesures necessàries per a intentar garantir a l’usuari l’absència de virus informàtics i elements similars al seu portal. No obstant això, estes mesures no són infal·libles i, per això, Correos no pot assegurar totalment l’absència d’eixos elements nocius. En conseqüència, Correos no serà responsable dels danys que estos puguen produir a l’usuari.",
    tycWarningLegalText14:
        "Fallades tecnològiques: Correos ha conclòs tots els contractes necessaris per a la continuïtat del seu portal i farà els seus millors esforços perquè no patisca interrupcions, però no pot garantir l’absència de fallades tecnològiques, ni la permanent disponibilitat del portal i dels productes o servicis que hi conté i, en conseqüència, no assumix cap responsabilitat pels danys i els perjuís que es puguen generar per la falta de disponibilitat i per les fallades en l’accés ocasionades per desconnexions, avaries, sobrecàrregues o caigudes de la xarxa no imputables a Correos.",
    tycWarningLegalText15:
        "Intercanvi d’informació: el portal pot contindre determinades zones pensades perquè els diferents usuaris puguen posar en comú les seues experiències i per a fomentar l’intercanvi d’informació. Tanmateix, Correos únicament actua en estos fòrums com a lloc de reunió i no controla les expressions que s’hi aboquen, per la qual cosa no assumix cap responsabilitat per la veracitat o pertinència de les manifestacions que els usuaris hi efectuen.",
    tycWarningLegalText16:
        "L’usuari es compromet a utilitzar els servicis del portal d’acord amb els termes expressats en este Avís legal, i la mera utilització dels servicis n’implica l’acceptació.",
    tycWarningLegalText18:
        "L’usuari que actue contra la imatge, el bon nom o la reputació de Correos i qui utilitze de manera il·lícita o fraudulenta els dissenys, els logotips o els continguts del portal serà responsable davant de Correos de la seua actuació.",
    tycWarningLegalText19:
        "La llei aplicable en cas de disputa o conflicte d’interpretació dels termes que conformen este Avís legal, així com qualsevol qüestió relacionada amb els productes o servicis d’este portal, és la llei espanyola, llevat que les normes de protecció dels consumidors i usuaris establisquen l’aplicabilitat d’una legislació diferent.",
    tycWarningLegalText20:
        "Per a la resolució de comunicats, se sotmeten a la jurisdicció dels jutjats i tribunals de la capital de província d’Espanya que corresponga.",

    //PrivacyPoliticyTyC Subtittles

    tycPrivacyPoliticyTyCSubtittle1: "Responsable del tractament",
    tycPrivacyPoliticyTyCSubtittle2: "Finalitat del tractament i legitimació",
    tycPrivacyPoliticyTyCSubtittle3: "Tipus de dades tractades",
    tycPrivacyPoliticyTyCSubtittle4: "Termini de conservació de les dades",
    tycPrivacyPoliticyTyCSubtittle5: "Els seus drets",
    tycPrivacyPoliticyTyCSubtittle6: "Accés a tercers",

    //PrivacyPoliticyTyC list tittles

    tycPrivacyPoliticyTyCListTittle1: "Prestació del servici Correos ID",
    tycPrivacyPoliticyTyCListTittle2:
        "Enviament d’ofertes i promocions de productes i servicis de Correos",
    tycPrivacyPoliticyTyCListTittle3:
        "Enviament d’ofertes i promocions d’altres empreses del Grup Correos i de terceres empreses",
    tycPrivacyPoliticyTyCListTittle4:
        "Comunicació de dades a altres empreses del Grup Correos i a terceres empreses",
    tycPrivacyPoliticyTyCListTittle5: "Complir les obligacions legals",
    tycPrivacyPoliticyTyCListTittle6:
        "Informació estadística i enquestes de satisfacció",
    tycPrivacyPoliticyTyCListTittle7: "Enriquir la informació",
    tycPrivacyPoliticyTyCListTittle8: "Dades proporcionades de manera directa:",
    tycPrivacyPoliticyTyCListTittle9: "Dades obtingudes de manera indirecta:",
    tycPrivacyPoliticyTyCListTittle10:
        "Dades derivades de la mateixa prestació del servici:",

    //PrivacyPoliticyTyC texts

    tycPrivacyPoliticyTyCText1:
        "Per a totes les empreses del Grup Correos, respectar la seua privacitat i complir la normativa de protecció de dades és un aspecte de vital importància.",
    tycPrivacyPoliticyTyCText2_1:
        "El nostre desig és que en tot moment conega per a què utilitzem, o volem utilitzar, les seues dades i quins són els seus drets. Amb este objectiu, hem redactat la següent Política de protecció de dades, que regula el tractament de dades derivat del servici Correos ID, així com la navegació a través de la pàgina web ",
    tycPrivacyPoliticyTyCText2_B1: "id.correos.post",
    tycPrivacyPoliticyTyCText2_2: " (anteriorment, ",
    tycPrivacyPoliticyTyCText2_B2: "emiidentidad.correos.post",
    tycPrivacyPoliticyTyCText2_3:
        " ) i qualsevol dels seus subdominis o d’altres webs de Correos que mostren, al peu de pàgina, esta Política de privacitat.",
    tycPrivacyPoliticyTyCText3:
        "La informació següent pot ser objecte de modificació per a qualsevol adaptació legal a futures novetats legislatives o jurisprudencials, així com pràctiques de la indústria. En qualsevol cas, esta modificació es comunicarà a l’usuari mitjançant qualsevol via de comunicació vàlida.",
    tycPrivacyPoliticyTyCText4:
        "El responsable del tractament és Sociedad Estatal Correos y Telégrafos, SA, SME (d’ara endavant “Correos”), amb CIF A-83052407 i domicili social en la Vía Dublín núm. 7 (Campo de las Naciones) 28070 Madrid (Espanya).",
    tycPrivacyPoliticyTyCText5:
        "Per a garantir la gestió adequada d’este tractament, Correos té designat un delegat de protecció de dades (DPD o DPO), a qui es pot dirigir per a qualsevol qüestió que necessite en relació amb les dades a través de l’adreça electrònica següent: ",
    tycPrivacyPoliticyTyCText6:
        "El tractament de les seues dades perseguix les finalitats següents:",
    tycPrivacyPoliticyTyCText7: "Les seues dades seran tractades per a",
    tycPrivacyPoliticyTyCText8:
        "Tramitar el seu registre com a usuari i donar-lo d’alta a través del formulari establit a este efecte.",
    tycPrivacyPoliticyTyCText9:
        "Per a donar-lo d’alta en alguns dels servicis digitals a través de Correos ID és possible que se sol·licite informació addicional mitjançant un formulari web establit a l’efecte.",
    tycPrivacyPoliticyTyCText10:
        "Prestar els servicis de Correos ID, de conformitat amb les Condicions particulars del servici.",
    tycPrivacyPoliticyTyCText11:
        "En este sentit, Correos ID és un gestor centralitzat d’identitat digital que permet a l’usuari autenticar-se davant dels diferents servicis digitals de Correos i/o d’altres servicis de tercers que accepten este mètode com a sistema d’identificació; és a dir, de manera resumida, permet a l’usuari accedir a diferents servicis a través d’una sola instància d’identificació digital, i evita haver de disposar d’una contrasenya per a cadascun d’estos servicis.",
    tycPrivacyPoliticyTyCText12:
        "En cas que el servici Correos ID s’utilitze com a plataforma digital d’identificació davant de servicis que presten altres empreses del Grup Correos o tercers col·laboradors, cal que les dades de l’usuari es comuniquen a estes empreses per a poder dur a terme la verificació d’identitat que s’ha sol·licitat.",
    tycPrivacyPoliticyTyCText13:
        "Donar resposta als seus dubtes, consultes o reclamacions.",
    tycPrivacyPoliticyTyCText14:
        "Tramitar la verificació de la seua identitat, resultat d’un acord de col·laboració i/o prestació de servicis que Correos haja subscrit amb un tercer.",
    tycPrivacyPoliticyTyCText15:
        "Així mateix, a través del gestor centralitzat d’identitat digital es pot tramitar la verificació de la seua identitat, resultat d’un acord de col·laboració i/o prestació de servici subscrit entre Correos i un tercer.",
    tycPrivacyPoliticyTyCText16:
        "Si presta el seu consentiment, Correos li podrà remetre per qualsevol canal electrònic o no electrònic informació comercial relacionada amb la prestació, la millora i l’actualització dels nostres productes i servicis, noves tarifes i descomptes.",
    tycPrivacyPoliticyTyCText17:
        "Esta informació comercial pot ser genèrica o personalitzada. Este últim supòsit implica el previ tractament de les seues dades amb l’objectiu de poder procedir al seu perfilat i carterització, determinar gustos, preferències i necessitats i, partint d’estes, identificar les ofertes que puguen resultar més interessants.",
    tycPrivacyPoliticyTyCText18:
        "En cas que preste el seu consentiment, Correos podrà remetre-li per qualsevol canal electrònic o no electrònic ofertes i promocions de:",
    tycPrivacyPoliticyTyCText19:
        "Altres empreses del Grup Correos que presten servicis relacionats amb els sectors que pertanyen a les activitats de servicis de paqueteria, de valor afegit al servici postal, logística, màrqueting i de telecomunicació.",
    tycPrivacyPoliticyTyCText20:
        "Altres empreses amb les quals Correos haja subscrit diferents convenis de col·laboració i que poden pertànyer a sectors com ara telecomunicacions, financer, gran consum, sanitat i ONG.",
    tycPrivacyPoliticyTyCText21:
        "Esta informació comercial pot ser genèrica o personalitzada. Este últim supòsit implica el previ tractament de les seues dades amb l’objectiu de poder procedir al seu perfilat i carterització, determinar gustos, preferències i necessitats i, partint d’estes, identificar les ofertes que puguen resultar més interessants.",
    tycPrivacyPoliticyTyCText22:
        "En cas que preste el seu consentiment, Correos podrà comunicar les seues dades a les empreses del Grup Correos i a terceres empreses els sectors de les quals es detallen en la finalitat anterior amb l’objectiu que estes empreses puguen fer-li arribar, de manera directa i sense la intermediació de Correos, ofertes i promocions relatives als seus productes i servicis.",
    tycPrivacyPoliticyTyCText23:
        "Dins de les diferents obligacions legals que assumix Correos hi ha les derivades de l’aplicació de la normativa postal (Llei 43/2010 del Servici Postal Universal, dels drets dels usuaris i del mercat postal) i el deure de secret de les comunicacions (art. 18 de la Constitució), així com la normativa tributària.",
    tycPrivacyPoliticyTyCText24:
        "En el compliment d’estes obligacions, Correos podrà comunicar les seues dades a les administracions públiques i tribunals, sempre que tal informació siga requerida conforme als processos legals establits.",
    tycPrivacyPoliticyTyCText25:
        "Correos té un interés legítim a conéixer tant l’opinió dels seus clients respecte als seus productes i servicis com els seus hàbits de consum (p. ex. la freqüència/recurrència en la contractació dels diferents servicis) amb l’objectiu de poder oferir productes i servicis més ajustats a les seues necessitats i millorar tant els productes i servicis actuals com el seu procés de contractació.",
    tycPrivacyPoliticyTyCText26:
        "Per a aconseguir l’objectiu exposat en el paràgraf anterior, Correos pot tractar les seues dades i:",
    tycPrivacyPoliticyTyCText27:
        "Fer enquestes de satisfacció tant telefòniques com per mitjans electrònics, fins i tot una vegada deixe de ser client, en este últim cas per a conéixer els motius de la seua marxa.",
    tycPrivacyPoliticyTyCText28:
        "En cas que durant l’enquesta de satisfacció el client manifeste la seua opinió sobre els productes i servicis d’alguna de les empreses del Grup Correos, el resultat de l’enquesta i les dades identificatives del participant podran ser comunicats a l’empresa del Grup Correos els servicis de la qual es valoren.",
    tycPrivacyPoliticyTyCText29:
        "Procedir a la seua anonimització, de manera que no puga tornar a vincular-se la informació dels seus hàbits de consum amb la seua persona i, després d’això, fer estudis estadístics i economètrics (p. ex. conéixer el cicle de vida del client i/o identificar models de propensió a l’abandonament).",
    tycPrivacyPoliticyTyCText30:
        "En cas que preste el seu consentiment, Correos podrà obtindre més informació sobre les seues característiques, gustos i necessitats a través de la informació proporcionada per diferents fonts externes com registres públics, cadastre, xarxes socials, ferramentes de geomàrqueting, bases de dades d’informació econòmica com INFORMA, fitxers d’informació creditícia com ASNEF, BADEXCUG... amb l’objectiu d’introduir millores en els nostres productes i servicis i/o adaptar o escollir les ofertes d’eixos productes i servicis que millor s’ajusten al seu perfil concret.",
    tycPrivacyPoliticyTyCText31:
        "Per a les finalitats exposades en l’apartat anterior, es tracta un conjunt de dades de caràcter personal que podem dividir en les fonts i categories següents:",
    tycPrivacyPoliticyTyCText32:
        "En el moment del registre, l’usuari ha de proporcionar una informació mínima que resulta necessària per a la seua verificació i alta, per la qual cosa, llevat que s’advertisca el contrari, la falta d’emplenament impedirà la prestació del servici. Certes funcionalitats (p. ex. l’accés a determinats servicis) exigixen que facilite informació addicional a través del mateix servici.",
    tycPrivacyPoliticyTyCText33:
        "Així mateix, juntament amb la informació proporcionada a través del servici es tractaran dades obtingudes pel servidor, com l’adreça IP des de la qual hi accedix, el domini des del qual s’obté l’accés, la data i l’hora de la visita, la galleta de sessió (esta última s’elimina en tancar el navegador), etc.",
    tycPrivacyPoliticyTyCText34:
        "El client garantix que les dades facilitades a Correos per a la prestació dels servicis sol·licitats responen amb veracitat a la situació real i que comunicarà qualsevol modificació que les afecte. En conseqüència, el client respondrà davant de Correos i tercers de qualssevol danys o perjuís ocasionats a conseqüència de l’incompliment de les obligacions assumides en esta clàusula.",
    tycPrivacyPoliticyTyCText35_1:
        "Todos aquellos datos que, en caso de haber prestado su consentimiento, Correos recaba de la tipología de fuentes " +
        'externas enumerada en la finalidad "H" del apartado ',
    tycPrivacyPoliticyTyCText35_B: "“Finalitat del tractament i legitimació”.",
    tycPrivacyPoliticyTyCText36:
        "A més del cas anterior, terceres entitats, com ara administracions públiques, l’empresa en què treballa o altres institucions amb què Correos tinga subscrit un acord de col·laboració i/o prestació de servicis i que estiguen interessades a contractar este mètode com a sistema d’identificació, ens poden comunicar o donar accés a les seues dades, a fi que puguem verificar la seua identitat. Les entitats amb què Correos haja subscrit acords de col·laboració i/o prestació de servicis poden, si escau, inhabilitar el sistema d’identificació o aplicar condicions diferents o addicionals d’acord amb el que recullen els acords de col·laboració i/o de prestació de servicis subscrits.",
    tycPrivacyPoliticyTyCText37:
        "Dins d’esta informació s’inclouen aspectes com: (i) l’historial de productes i servicis contractats amb Correos, el tipus de servici i les seues característiques (p. ex. servicis digitals de Correos en els quals està donat d’alta, freqüència d’ús d’estos servicis, ús d’altres servicis de Correos com enviaments postals, paqueteria, Citypaq...); (ii) reclamacions o sol·licituds d’informació, etc.; (iii) informació sobre la seua navegació a través de les galletes que es poden instal·lar en el seu dispositiu, amb el seu consentiment previ —en cas necessari—, de conformitat amb el que establix la nostra Política de galletes.",
    tycPrivacyPoliticyTyCText38:
        "Les seues dades personals es conservaran mentres siguen necessàries per a la prestació del servici de Correos ID, llevat que en sol·licite la supressió. El procés d’eliminació d’identitat implica un bloqueig o restricció d’ús de la seua identitat i dades durant un període de trenta (30) dies, i després d’este període el qual es procedirà a la seua efectiva eliminació. Durant el període de restricció de l’ús de la identitat, podrà recuperar la seua identitat seguint el procés de Correos ID per a això.",
    tycPrivacyPoliticyTyCText39:
        "Correos bloquejarà aquells usuaris que es troben inactius —és a dir, que no accedisquen a cap dels servicis digitals de Correos o d’altres empreses que utilitzen Correos ID com a plataforma d’identificació— per un termini de cinc anys.",
    tycPrivacyPoliticyTyCText40:
        "Quan ja no siga necessari el tractament de les seues dades, estes se suprimiran d’acord amb el que disposa la normativa de protecció de dades, la qual cosa n’implica el bloqueig, i només estaran disponibles a sol·licitud de jutges i tribunals, el ministeri fiscal o les administracions públiques competents durant el termini de prescripció de les accions que pogueren derivar, i posteriorment seran eliminades. Generalment, seran esborrades una vegada transcorreguts tres anys.",
    tycPrivacyPoliticyTyCText41:
        "La nostra normativa de protecció de dades li conferix una sèrie de drets en relació amb el tractament de dades que impliquen els nostres servicis que podem resumir en els següents:",
    tycPrivacyPoliticyTyCText42:
        "- Dret d’accés: Conéixer quin tipus de dades estem tractant i les característiques del tractament que estem duent a terme.",
    tycPrivacyPoliticyTyCText43:
        "- Dret de rectificació: Poder sol·licitar la modificació de les seues dades per ser inexactes o no veraces.",
    tycPrivacyPoliticyTyCText44:
        "- Dret de portabilitat: Poder obtindre una còpia en un format interoperable de les dades que estiguen sent tractades.",
    tycPrivacyPoliticyTyCText45:
        "- Dret a la limitació del tractament en els casos recollits en la Llei.",
    tycPrivacyPoliticyTyCText46:
        "- Dret de supressió: Sol·licitar la supressió de les seues dades quan el tractament ja no resulte necessari.",
    tycPrivacyPoliticyTyCText47:
        "- Dret d’oposició: Este dret li permet, per exemple, sol·licitar la cessació en l’enviament de comunicacions comercials.",
    tycPrivacyPoliticyTyCText48: "- Dret a revocar el consentiment prestat.",
    tycPrivacyPoliticyTyCText49:
        "Pot exercir els seus drets a través d’algun dels canals següents:",
    tycPrivacyPoliticyTyCText50:
        "- Adreça postal: Via Dublín núm. 7 (Campo de las Naciones) 28070 Madrid (Espanya)",
    tycPrivacyPoliticyTyCText51: "- Adreça electrònica: ",
    tycPrivacyPoliticyTyCText52:
        "Així mateix, totes les comunicacions comercials electròniques inclouran un mecanisme de baixa o similar que li permetrà, si així ho desitja, no rebre noves comunicacions.",
    tycPrivacyPoliticyTyCText53:
        "En la pàgina web de l’Agència Espanyola de Protecció de Dades (AEPD) pot trobar una sèrie de models que l’ajudaran a l’exercici dels seus drets. Tanmateix, l’informem que té dret a interposar una reclamació davant de l’autoritat de control (a Espanya, l’AEPD) si considera infringits els seus drets.",
    tycPrivacyPoliticyTyCText54:
        "Per a la prestació de determinats servicis pot ser necessari que ens facilite les dades personals i de contacte de terceres persones com, per exemple, el nom, els cognoms, l’adreça postal, l’adreça electrònica, el telèfon de contacte i altres dades o mitjans equivalents de contacte del destinatari d’una comunicació o enviament.",
    tycPrivacyPoliticyTyCText55:
        "Addicionalment, per a aconseguir la millor prestació del servici, el número de telèfon o l’adreça electrònica es poden utilitzar amb l’objectiu d’informar el destinatari sobre la prestació del servici i tractar qualsevol incidència que el poguera afectar.",
    tycPrivacyPoliticyTyCText56:
        "En este sentit, el client garantix la veracitat i l’actualització de les dades del destinatari i, si escau, assegura haver complit totes aquelles obligacions que, en matèria de protecció de dades, li resulten atribuïbles, havent obtingut el consentiment del destinatari que Correos duga a terme el tractament exposat en els paràgrafs precedents, en cas de ser eixe consentiment necessari.",
    tycPrivacyPoliticyTyCText57:
        "Per la seua part, en cas que Correos accedisca a dades personals que resulten necessàries per a la prestació del servici de verificació d’identitat, davall la responsabilitat d’una empresa o professional. Correos es compromet a complir totes les obligacions inherents a la seua posició com a encarregat de tractament i, en este sentit:",
    tycPrivacyPoliticyTyCText58:
        "Accedirà a les dades a què tinga accés o les tractarà d'acord amb les seues instruccions, i no les utilitzarà per a finalitats diferents de la prestació del servici. Per a això, podrà disposar dels servicis d’altres tercers (subencarregats), si resulta necessari per a la prestació del servici i operacions del contracte i/o per a la seua conservació i sempre que (i) el nou encarregat quede subjecte a les mateixes condicions (instruccions, obligacions, mesures de seguretat...) i amb els mateixos requisits formals que ell, referent a l’adequat tractament de les dades personals i a la garantia dels drets de les persones afectades; (ii) Correos, a sol·licitud expressa, pose a la seua disposició una llista en què s’identifiquen els servicis subencarregats i la identitat dels subencarregats; (iii) en cas d’incompliment per part del subencarregat, Correos continue sent plenament responsable.",
    tycPrivacyPoliticyTyCText59:
        "Implementarà les mesures de seguretat legals, tècniques i organitzatives apropiades per a garantir un nivell de seguretat adequat al risc, conforme a l’exposat a l’art. 32 del RGPD i, a la seua sol·licitud, l’informarà de la metodologia d’anàlisi de risc utilitzada.",
    tycPrivacyPoliticyTyCText60:
        "Complida la prestació contractual, destruirà o tornarà les dades tractades, així com qualssevol suports o documents en què consten dades objecte del tractament, sense perjuí de la possibilitat de conservar estes dades, degudament bloquejades, en els termes previstos per la nostra normativa de protecció de dades.",
    tycPrivacyPoliticyTyCText61:
        "Assegurarà el compliment de la resta d’obligacions recollides en la normativa de protecció de dades.",
};
