import { ManageAddressFirstIteration } from "../../../commons/AddressManager";
import { AttributeCodes, Attributes } from "../../../commons/Attributes";
import { emptyEnterpriseNew } from "../../../commons/ConstantObjects";
import { EnumPersonType, sessionValues } from "../../../commons/EnumsGeneral";
import { checkHasFavouriteAddress, concatFormAttr, fillAddressValues, filterForm, getFinalModel, getGroups, isResultOk, obtainAttribute, obtainFieldTypeCode, putDataType, showAlert, sortAttributes } from "../../../commons/FormManager";
import { checkIsNullUndefined } from "../../../commons/Utils";
import { getApplicationOid, getReplaceApplicationOid, getReplaceMode, getSessionItem } from "../../../services/Commons";
import { GetMasterCountry, GetMasterAttribute, GetMasterFieldType, GetMasterRoadType, GetMasterTypeUserDocument } from "../../../services/MasterCallsService";
import { GetProfileUser } from "../../../services/ReasonService";
import { GetEnterpriseDataRole, GetEnterpriseData, } from "../../../services/EnterpriseDataCallsService";
import { GetApplicationAttributeInfo, } from "../../../services/ApplicationCallsService";
import { GetUtilitiesCorreosIdConfigApplication } from "../../../services/UtilitiesCallsService";
import { cleanAttr, disableAttr } from "./FormFunctions";
import { Constants } from "../../../commons/Constants";

export const userPreparation = (userCompleteForm, auxUserDataForm, auxUserAddressForm, onlyMandatories, registerPage) => {
  if (onlyMandatories) {
    let auxAttrSurname2 = obtainAttribute(userCompleteForm[0], AttributeCodes.Surname2);
    auxUserDataForm.value = userCompleteForm[0].filter(
      (attr) => attr.mandatory === true
    );
    let auxAttrSurname1Manda = obtainAttribute(auxUserDataForm.value, AttributeCodes.Surname1);
    let auxAttrSurname2Manda = obtainAttribute(auxUserDataForm.value, AttributeCodes.Surname2);
    fixDocumentAttr(auxUserDataForm, userCompleteForm);
    if (!checkIsNullUndefined(auxAttrSurname1Manda) && checkIsNullUndefined(auxAttrSurname2Manda)) {
      auxUserDataForm.value.push(auxAttrSurname2);
    }
    sortAttributes(auxUserDataForm.value);
  } else {
    auxUserDataForm.value = sortAttributes(userCompleteForm[0]);
  }
  auxUserAddressForm.value = sortAttributes(userCompleteForm[1]);
  if (registerPage) {
    let auxiliarUserDataForm = auxUserDataForm.value.filter((attr) => attr.codeAttribute !== AttributeCodes.Email && attr.codeAttribute !== AttributeCodes.Phone && attr.codeAttribute !== AttributeCodes.Prefix);
    auxUserDataForm.value = auxiliarUserDataForm;
  }
}

const fixDocumentAttr = (auxUserDataForm, userCompleteForm) => {
  let auxList = auxUserDataForm.value.map((attr) => attr.codeAttribute);

  let docCodeList = [
    AttributeCodes.DocType,
    AttributeCodes.DocNumber,
    AttributeCodes.ExpirDate,
    AttributeCodes.ExpedCountry];
  if (auxList.includes(AttributeCodes.DocType) || auxList.includes(AttributeCodes.DocNumber)) {
    docCodeList.forEach((code) => {
      if (checkIsNullUndefined(auxUserDataForm.value.find((attr) => attr.codeAttribute == code))) {
        let auxAttr = obtainAttribute(userCompleteForm[0], code);
        auxUserDataForm.value.push(auxAttr);
      }
    })
  }
}

export const enterprisePreparation = (enterpriseCompleteForm, auxEnterpriseDataForm, auxEnterpriseAddressForm, onlyMandatories) => {
  if (onlyMandatories) {
    auxEnterpriseDataForm.value = sortAttributes(
      enterpriseCompleteForm[0]
    ).filter((attr) => attr.mandatory === true);
  } else {
    auxEnterpriseDataForm.value = sortAttributes(enterpriseCompleteForm[0]);
  }
  auxEnterpriseAddressForm.value = sortAttributes(enterpriseCompleteForm[1]);
}

export const prepareData = (
  auxAttrlist,
  masterAttributeList,
  masterFieldTypeList,
  auxData,
  dataType,
  auxConst,
) => {
  auxAttrlist.forEach((attr) => {
    attr.fieldType = obtainFieldTypeCode(
      attr,
      masterAttributeList,
      masterFieldTypeList
    );
    if (dataType === "userData" || dataType === "enterpriseData") {
      const auxDocCodes = [
        AttributeCodes.DocType,
        AttributeCodes.DocNumber,
        AttributeCodes.ExpedCountry,
        AttributeCodes.ExpirDate];
      attr.value = auxData[Attributes[attr.codeAttribute].attributeName];

      if (attr.codeAttribute === AttributeCodes.Email && auxData.userEmail.length == 1) {
        attr.value = auxData.userEmail[0][Attributes[attr.codeAttribute].attributeName];
      }
      if (attr.codeAttribute === AttributeCodes.Phone && auxData.userPhone.length == 1) {
        attr.value = auxData.userPhone[0][Attributes[attr.codeAttribute].attributeName];
        obtainAttribute(auxAttrlist, AttributeCodes.Prefix).value = auxData.userPhone[0].idCountry;
      }
      if (auxDocCodes.includes(attr.codeAttribute) && auxData.userDocument.length == 1) {
        attr.value = auxData.userDocument[0][Attributes[attr.codeAttribute].attributeName];
      }

    } else if (
      dataType === "userAddress" ||
      dataType === "enterpriseAddress"
    ) {
      fillAddressValues(attr, auxData, auxConst);
    }
  });
};

export const auxFunction = () => {
  return true;
};

const auxGetSessionItem = (sessionValue) => {
  return getSessionItem(sessionValue);
};

const preparePage = (
  auxLists,
  auxConfig,
  auxConst,
  auxFunctions,
  getBothFormSettersObject,
  getUserFormSetersObject,
  getBothAddressSettersObject,
) => {
  let auxFormSettersObject = getBothFormSettersObject();
  let auxUserFormSettersObject = getUserFormSetersObject()
  let auxAddressSettersObject = getBothAddressSettersObject();
  let userAuxForm = auxConst.forUser ? auxConfig.userConfig : [];
  let enterpriseAuxForm = auxConst.forEnterprise ? auxConfig.enterpriseConfig : [];
  let enterpriseCidAuxForm = auxConst.forEnterprise && auxConst.isRegistry ? auxConfig.enterpriseCidConfig : [];
  let finalEnterpriseAuxForm = auxConst.isRegistry ? concatFormAttr(enterpriseAuxForm, enterpriseCidAuxForm) : enterpriseAuxForm;
  let userGroups = getGroups(userAuxForm);
  let enterpriseGroups = getGroups(finalEnterpriseAuxForm);
  if (auxConst.forUser && userGroups.length > 0) {
    auxFormSettersObject[EnumPersonType.User].SetTab(userGroups[0].code);
  }
  if (auxConst.forEnterprise && enterpriseGroups.length > 0) {
    auxFormSettersObject[EnumPersonType.Enterprise].SetTab(enterpriseGroups[0].code);
  }
  let userCompleteForm = getFinalModel(userAuxForm, userGroups);
  let enterpriseCompleteForm = getFinalModel(
    finalEnterpriseAuxForm,
    enterpriseGroups
  );
  auxFormSettersObject[EnumPersonType.User].SetGroups(userGroups);
  auxFormSettersObject[EnumPersonType.Enterprise].SetGroups(enterpriseGroups);
  let auxUserDataForm = { value: [] };
  let auxUserAddressForm = { value: [] };
  let auxEnterpriseDataForm = { value: [] };
  let auxEnterpriseAddressForm = { value: [] };
  if (auxConst.forUser) {
    userPreparation(userCompleteForm, auxUserDataForm, auxUserAddressForm, auxConst.onlyMandatories, auxConst.registerPage);
  }
  if (auxConst.forEnterprise) {
    enterprisePreparation(enterpriseCompleteForm, auxEnterpriseDataForm, auxEnterpriseAddressForm, auxConst.onlyMandatories);
  }
  let auxEnterpriseData = emptyEnterpriseNew;
  let auxIsTitular = true;
  let getProfileUser = auxConst.forUser && !auxConst.registerPage ? GetProfileUser() : auxFunction();
  let getEnterpriseDataRole = !checkIsNullUndefined(auxConst.idEnterprise)
    ? GetEnterpriseDataRole(auxConst.idEnterprise)
    : auxFunction();
  let getProfileEnterprise = !checkIsNullUndefined(auxConst.idEnterprise)
    ? GetEnterpriseData(auxConst.idEnterprise)
    : auxFunction();
  let getMustRegistry = auxConst.forUser ? auxConst.registerPage ? auxGetSessionItem(sessionValues.attributeInfo) : GetApplicationAttributeInfo(getSessionItem(sessionValues.appOid)) : auxFunction();

  Promise.all([
    getProfileUser,
    getEnterpriseDataRole,
    getProfileEnterprise,
    getMustRegistry
  ]).then((results) => {
    let auxUserData = auxConst.registerPage ? auxConst.emptyUser : results[0];
    auxFormSettersObject[EnumPersonType.User].SetOriginalData(auxUserData);

    if (!checkIsNullUndefined(auxConst.idEnterprise)) {
      auxIsTitular = results[1] == 1;
      auxEnterpriseData = results[2];
      auxFormSettersObject[EnumPersonType.Enterprise].SetOriginalData(auxEnterpriseData);
    }
    let auxUserEditableEmails = [];
    let auxUserEditablePhones = [];
    let auxUserEditableDocuments = [];
    let auxUserEditableAddresses = [];
    if (auxUserData !== true) {
      auxUserEditableEmails = auxUserData.userEmail;
      auxUserEditablePhones = auxUserData.userPhone;
      auxUserEditableDocuments = auxUserData.userDocument;
      auxUserEditableAddresses = auxUserData.userAddress;
    }
    auxUserFormSettersObject.SetEditableEmails(auxUserEditableEmails);
    auxUserFormSettersObject.SetEditablePhones(auxUserEditablePhones);

    if (results[3].email?.mustRegistry) {
      auxUserEditableEmails.forEach((email) => {
        if (email.idConTypeVerification === 2) {
          sessionStorage.setItem("verifyEmail", false);
        }
      })
    };

    if (results[3].phone?.mustRegistry) {
      auxUserEditablePhones.forEach((phone) => {
        if (phone.idConTypeVerification === 5) {
          sessionStorage.setItem("verifyPhone", false);
        }
      })
    };

    if (results[3].document?.mustIdVerification == 7) {
      sessionStorage.setItem("verifyDocument", true);
    }

    auxUserFormSettersObject.SetEditableDocuments(auxUserEditableDocuments);
    auxAddressSettersObject[EnumPersonType.User].SetEditableAddresses(auxUserEditableAddresses);
    auxAddressSettersObject[EnumPersonType.Enterprise].SetEditableAddresses(auxEnterpriseData?.enterpriseAddress);
    auxFunctions.setIsTitular(auxIsTitular);
    auxAddressSettersObject[EnumPersonType.User].SetHasFavouriteAddress(
      checkHasFavouriteAddress(auxUserEditableAddresses)
    );
    auxAddressSettersObject[EnumPersonType.Enterprise].SetHasFavouriteAddress(
      checkHasFavouriteAddress(auxEnterpriseData?.enterpriseAddress)
    );
    putDataType(auxUserDataForm.value, "user");
    putDataType(auxUserAddressForm.value, "user");
    putDataType(auxEnterpriseDataForm.value, "enterprise");
    putDataType(auxEnterpriseAddressForm.value, "enterprise");
    prepareData(
      auxUserDataForm.value,
      auxLists.masterAttributeList,
      auxLists.masterFieldTypeList,
      auxUserData,
      "userData"
    );
    prepareData(
      auxEnterpriseDataForm.value,
      auxLists.masterAttributeList,
      auxLists.masterFieldTypeList,
      auxEnterpriseData,
      "enterpriseData"
    );
    if (auxConst.forUser) {
      const auxDocCodes = ["NDO", "PEX", "FCA"];
      auxDocCodes.forEach((docCode) => {
        let auxAttr = obtainAttribute(auxUserDataForm.value, docCode);
        if (!checkIsNullUndefined(auxAttr)) {
          auxAttr.disabled = true;
        }
      });
    }
    let auxUserForm = auxUserDataForm.value;
    let auxEnterpriseForm = disableAttr(auxEnterpriseDataForm.value, !auxIsTitular);
    if (!auxConst.auxiliarTab) {
      auxUserForm = filterForm(auxUserDataForm.value, auxUserData);
      auxEnterpriseForm = filterForm(auxEnterpriseForm, auxEnterpriseData);
    }
    auxFormSettersObject[EnumPersonType.User].SetDataForm(auxUserForm);
    let auxUserDocAttribute = obtainAttribute(auxUserDataForm.value, "NDO");
    let auxUserAddressAttribute = obtainAttribute(auxUserAddressForm.value, "DIR");
    auxUserFormSettersObject.SetNeedsDocument(auxUserDocAttribute?.mandatory);
    auxAddressSettersObject[EnumPersonType.User].SetNeedsAddress(auxUserAddressAttribute?.mandatory);
    auxFormSettersObject[EnumPersonType.Enterprise].SetDataForm(auxEnterpriseForm);
    let auxEnterpriseAddressAttribute = obtainAttribute(
      auxEnterpriseAddressForm.value,
      "EDR"
    );
    auxAddressSettersObject[EnumPersonType.Enterprise].SetNeedsAddress(auxEnterpriseAddressAttribute?.mandatory);
    prepareData(
      auxUserAddressForm.value,
      auxLists.masterAttributeList,
      auxLists.masterFieldTypeList,
      auxUserData.userAddress,
      "userAddress",
      auxConst
    );
    prepareData(
      auxEnterpriseAddressForm.value,
      auxLists.masterAttributeList,
      auxLists.masterFieldTypeList,
      auxEnterpriseData.enterpriseAddress,
      "enterpriseAddress",
      auxConst
    );
    auxAddressSettersObject[EnumPersonType.User].SetAddressEditableForm(auxUserAddressForm.value);
    auxAddressSettersObject[EnumPersonType.Enterprise].SetAddressEditableForm(auxEnterpriseAddressForm.value);
    let finalUserAddressForm = disableAttr(auxUserAddressForm.value, true);
    let finalEnterpriseAddressForm = disableAttr(
      auxEnterpriseAddressForm.value,
      true
    );
    let finalUserAddressBasicForm = cleanAttr(auxUserAddressForm.value);
    let finalEnterpriseAddressBasicForm = cleanAttr(auxEnterpriseAddressForm.value);
    auxAddressSettersObject[EnumPersonType.User].SetAddressForm(finalUserAddressForm);
    auxAddressSettersObject[EnumPersonType.Enterprise].SetAddressForm(finalEnterpriseAddressForm);
    auxAddressSettersObject[EnumPersonType.User].SetAddressBasicForm(finalUserAddressBasicForm);
    auxAddressSettersObject[EnumPersonType.Enterprise].SetAddressBasicForm(finalEnterpriseAddressBasicForm);
    auxFunctions.setPageCircularProgress(false);
  });
};

export const prePreparePage = (
  auxConst,
  auxFunctions,
  getBothFormSettersObject,
  getUserFormSetersObject,
  getBothAddressSettersObject,
) => {
  auxFunctions.setPageCircularProgress(true);
  if (auxConst.registerPage) {
    prepareRegisterPage(auxFunctions, auxConst, getBothFormSettersObject, getUserFormSetersObject, getBothAddressSettersObject);
  } else {
    prepareNotRegisterPage(auxFunctions, auxConst, getBothFormSettersObject, getUserFormSetersObject, getBothAddressSettersObject);
  };
};

const prepareRegisterPage = (auxFunctions, auxConst, getBothFormSettersObject, getUserFormSetersObject, getBothAddressSettersObject) => {
  auxFunctions.setPageCircularProgress(true);
  let masterAttributeList = JSON.parse(getSessionItem(sessionValues.attributes));
  let masterFieldTypeList = JSON.parse(getSessionItem(sessionValues.fieldType));
  let masterCountry = [];
  let masterTypeUserDocument = [];
  let masterRoadType = [];
  let userConfig = JSON.parse(getSessionItem(sessionValues.userConfiguration));
  let enterpriseConfig = auxConst.forEnterprise ? JSON.parse(getSessionItem(sessionValues.enterpriseConfiguration)) : [];
  let enterpriseCidConfig = [];
  let dataObject = {
    masterAttributeList: masterAttributeList,
    masterFieldTypeList: masterFieldTypeList,
    userConfig: userConfig,
    enterpriseConfig: enterpriseConfig,
    enterpriseCidConfig: enterpriseCidConfig,
    masterCountry: masterCountry,
    masterTypeUserDocument: masterTypeUserDocument,
    masterRoadType: masterRoadType,
  };
  setPreparePageData(auxFunctions, auxConst, getBothFormSettersObject, getUserFormSetersObject, getBothAddressSettersObject, dataObject);
};

const prepareNotRegisterPage = (auxFunctions, auxConst, getBothFormSettersObject, getUserFormSetersObject, getBothAddressSettersObject) => {
  let getMasterAttribute = GetMasterAttribute();
  let getMasterFieldType = GetMasterFieldType();
  let getMasterCountry = GetMasterCountry();
  let getMasterTypeUserDocument = GetMasterTypeUserDocument();
  let getMasterRoadType = GetMasterRoadType();
  let auxApplicationOid = getReplaceMode() == "true" ? getReplaceApplicationOid() : getApplicationOid();
  let getUserConfig = auxConst.forUser ? GetUtilitiesCorreosIdConfigApplication(1, auxApplicationOid) : auxFunction();
  let getEnterpriseConfig = auxConst.forEnterprise
    ? GetUtilitiesCorreosIdConfigApplication(2, auxApplicationOid)
    : auxFunction();
  let getEnterpriseCidConfig = auxConst.forEnterprise && auxConst.isRegistry
    ? GetUtilitiesCorreosIdConfigApplication(2, process?.env?.REACT_APP_APP_OID)
    : auxFunction();
  Promise.all([
    getMasterAttribute,
    getMasterFieldType,
    getUserConfig,
    getEnterpriseConfig,
    getEnterpriseCidConfig,
    getMasterCountry,
    getMasterTypeUserDocument,
    getMasterRoadType,
  ]).then((results) => {
    let masterAttributeList = results[0];
    let masterFieldTypeList = results[1];
    let userConfig = results[2];
    let enterpriseConfig = results[3];
    let enterpriseCidConfig = results[4];
    let masterCountry = results[5];
    let masterTypeUserDocument = results[6];
    let masterRoadType = results[7];
    if (
      isResultOk([
        masterAttributeList,
        masterFieldTypeList,
        userConfig,
        enterpriseConfig,
        enterpriseCidConfig,
        masterCountry,
        masterTypeUserDocument,
        masterRoadType,
      ])
    ) {
      let dataObject = {
        masterAttributeList: masterAttributeList,
        masterFieldTypeList: masterFieldTypeList,
        userConfig: userConfig,
        enterpriseConfig: enterpriseConfig,
        enterpriseCidConfig: enterpriseCidConfig,
        masterCountry: masterCountry,
        masterTypeUserDocument: masterTypeUserDocument,
        masterRoadType: masterRoadType,
      };
      setPreparePageData(auxFunctions, auxConst, getBothFormSettersObject, getUserFormSetersObject, getBothAddressSettersObject, dataObject);
    } else {
      //Alerta
      showAlert([
        masterAttributeList,
        masterFieldTypeList,
        userConfig,
        enterpriseConfig,
        enterpriseCidConfig,
        masterCountry,
        masterTypeUserDocument,
        masterRoadType,
      ], auxFunctions.finishOperation, auxFunctions.doTraduction);
    }
  });
};

const setPreparePageData = (auxFunctions, auxConst, getBothFormSettersObject, getUserFormSetersObject, getBothAddressSettersObject, dataObject) => {
  auxFunctions.setCountryList(dataObject.masterCountry);
  auxFunctions.setUserDocumentType(dataObject.masterTypeUserDocument);
  auxFunctions.setRoadType(dataObject.masterRoadType);
  let auxLists = { masterAttributeList: dataObject.masterAttributeList, masterFieldTypeList: dataObject.masterFieldTypeList };
  let auxConfig = { userConfig: dataObject.userConfig, enterpriseConfig: dataObject.enterpriseConfig, enterpriseCidConfig: dataObject.enterpriseCidConfig };
  auxConst.countryList = dataObject.masterCountry;
  auxConst.roadTypeList = dataObject.masterRoadType;
  preparePage(
    auxLists,
    auxConfig,
    auxConst,
    auxFunctions,
    getBothFormSettersObject,
    getUserFormSetersObject,
    getBothAddressSettersObject,
  );
};

export const manageEntryDialog = (doTraduction) => {
  let appCode = getSessionItem(sessionValues.appCode);
  let text = doTraduction("userDataInfoCid");
  if (!checkIsNullUndefined(appCode) && appCode != Constants.cidCode) {
    let appName = getSessionItem(sessionValues.appName);
    text = doTraduction("userDataInfo").replace("{appName}", appName);
  };
  return text;
};

export const manageExitDialogTitle = (doTraduction) => {
  let realAppOid = getReplaceApplicationOid();
  let appName = getSessionItem(sessionValues.appName);
  let text = doTraduction("userDataInfoQuestion").replace("{appName}", appName);
  if (realAppOid === process?.env?.REACT_APP_APP_OID) {
    text = doTraduction("userDataInfoQuestionCid");
  };
  return text;
};

export const manageExitDialog = (doTraduction) => {
  let realAppOid = getReplaceApplicationOid();
  let appName = getSessionItem(sessionValues.appName);
  let text = doTraduction("userDataExitInfo").replace("{appName}", appName);
  if (realAppOid === process?.env?.REACT_APP_APP_OID) {
    text = doTraduction("userDataExitInfoCid");
  };
  return text;
};

export const resetEnterprise = (getBothFormSettersObject, getBothAddressSettersObject) => {
  let auxBothFormSettersObject = getBothFormSettersObject(EnumPersonType.Enterprise);
  let auxBothAddressSettersObject = getBothAddressSettersObject(EnumPersonType.Enterprise);
  auxBothFormSettersObject.SetDataForm([]);
  auxBothFormSettersObject.SetOriginalData({});
  auxBothFormSettersObject.SetTab(0);
  auxBothAddressSettersObject.SetAddressForm([]);
  auxBothAddressSettersObject.SetAddressEditableForm([]);
  auxBothAddressSettersObject.SetAddressBasicForm([]);
  auxBothAddressSettersObject.SetEditableAddresses([]);
  auxBothAddressSettersObject.SetAddressOptionList([]);
}