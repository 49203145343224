import { AttributeCodes, Attributes } from "../../../commons/Attributes";
import { emptyAddress } from "../../../commons/ConstantObjects";
import { EnumPersonType } from "../../../commons/EnumsGeneral";
import { checkEditableDirOkNew, getAddresses, obtainAttribute, prepareAddChangeAddressNew } from "../../../commons/FormManager";
import { DeleteVerificationDataAddress, DeleteVerificationDataEnterpriseAddress, PostProfileUserCreateOrUpdateAddress } from "../../../services/ReasonService";
import { PostEnterpriseDataCreateOrUpdateAddress, } from "../../../services/EnterpriseDataCallsService";
import { checkIsNullUndefined, getCountryForCode, getErrorMessage } from "../../../commons/Utils";
import { EnumCountries } from "../../../commons/EnumCountries";
import { ManageAddressRelation } from "../../../commons/AddressManager";

export const prepareAuxBasicAddressForm = (isUser, auxAddressValuesObject, auxAddressSettersObject, personType) => {
    let auxAddressBasicForm = [];
    let auxAttr = {};
    let auxCode = isUser ? AttributeCodes.Alias : AttributeCodes.EntAlias;
    auxAddressBasicForm = JSON.parse(JSON.stringify(auxAddressValuesObject[personType].AddressBasicForm));
    auxAttr = obtainAttribute(auxAddressBasicForm, auxCode);
    auxAttr.value = "";
    auxAddressSettersObject[personType].SetAddressEditableForm(auxAddressBasicForm);
    auxAddressSettersObject[personType].SetAddressEditableFormOk(
        checkEditableDirOkNew(
            auxAddressBasicForm,
            getAddresses(auxAddressValuesObject[personType].EditableAddresses),
            "add"
        )
    );
};

export const prepareAuxEditableAddressForm = (isUser, auxAddressValuesObject, setAll) => {
    let personType = isUser ? EnumPersonType.User : EnumPersonType.Enterprise;
    let auxAddressForm = JSON.parse(JSON.stringify(auxAddressValuesObject[personType].AddressForm));
    setAll(auxAddressForm, false, true);
};

export const handleAuxAddAddress = (isFavourite, personType, auxAddressSettersObject, auxFunctions, auxAddressValuesObject, auxInfo) => {
    auxAddressSettersObject[personType].SetAddressCircularProgress(true);
    auxAddressSettersObject[personType].SetActionCircularProgress(true);
    auxFunctions.setIsNewAddress(true);
    let auxAddress = JSON.parse(JSON.stringify(emptyAddress));
    let auxAddressEditableForm = JSON.parse(
        JSON.stringify(auxAddressValuesObject[personType].AddressEditableForm)
    );
    let auxAddressForm = JSON.parse(JSON.stringify(auxAddressValuesObject[personType].AddressForm));
    let auxEditableAddresses = JSON.parse(
        JSON.stringify(auxAddressValuesObject[personType].EditableAddresses)
    );
    prepareAddChangeAddressNew(
        auxAddress,
        auxAddressEditableForm,
        auxAddressForm
    );
    setIsFavouriteAdd(isFavourite, auxEditableAddresses, auxAddress, personType, auxAddressSettersObject);
    if (personType == EnumPersonType.User) {
        PostProfileUserCreateOrUpdateAddress(auxAddress, true).then((response) => {
            prepareAddAddress(response, auxAddress, auxEditableAddresses, auxAddressForm, personType, auxAddressSettersObject, auxFunctions);
        });
    } else {
        if (!checkIsNullUndefined(auxInfo.idEnterprise)) {
            PostEnterpriseDataCreateOrUpdateAddress(auxAddress, auxInfo.idEnterprise, true).then((response) => {
                prepareAddAddress(response, auxAddress, auxEditableAddresses, auxAddressForm, personType, auxAddressSettersObject, auxFunctions);
            }
            );
        } else {
            prepareSetAddAddress(auxAddress, auxEditableAddresses, auxAddressForm, personType, auxAddressSettersObject);
            auxFunctions.finishOperation("warning", auxFunctions.doTraduction("enterpriseDataAddressWarning"));
        }
    }
};

const setIsFavouriteAdd = (
    isFavourite,
    auxEditableAddresses,
    auxAddress,
    personType,
    auxAddressSettersObject
) => {
    if (isFavourite) {
        auxEditableAddresses.forEach((address) => {
            address.favourite = false;
        });
        auxAddressSettersObject[personType].SetHasFavouriteAddress(true);
    }
    auxAddress.favourite = isFavourite;
};

const prepareAddAddress = (response, auxAddress, auxEditableAddresses, auxAddressForm, personType, auxAddressSettersObject, auxFunctions) => {
    if (response && !response.Status) {
        auxAddress.id = response;
        refillAddressValues(auxAddressForm, auxAddress, personType);
        prepareSetAddAddress(
            auxAddress,
            auxEditableAddresses,
            auxAddressForm,
            personType,
            auxAddressSettersObject
        );
        auxFunctions.finishOperation("success", auxFunctions.doTraduction("userDataAddDirectionSuccess"));
    } else {
        auxFunctions.finishOperation("error", getErrorMessage(response, auxFunctions.doTraduction));
        auxAddressSettersObject[personType].SetAddressCircularProgress(false);
        auxAddressSettersObject[personType].SetActionCircularProgress(false);
        auxFunctions.setIsNewAddress(false);
    }
};

const prepareSetAddAddress = (
    auxAddress,
    auxEditableAddresses,
    auxAddressForm,
    personType,
    auxAddressSettersObject
) => {
    auxEditableAddresses.push(auxAddress);
    auxAddressSettersObject[personType].SetEditableAddresses(auxEditableAddresses);
    auxAddressSettersObject[personType].SetAddressForm(auxAddressForm);
    auxAddressSettersObject[personType].SetActiveAddress(auxAddress.id);
    auxAddressSettersObject[personType].SetIsFavouriteAddress(auxAddress.favourite);
    auxAddressSettersObject[personType].SetAddressCircularProgress(false);
    auxAddressSettersObject[personType].SetActionCircularProgress(false);
};

export const handleAuxChangeAddress = (isFavourite, personType, auxAddressSettersObject, auxAddressValuesObject, auxFunctions, auxInfo) => {
    auxAddressSettersObject[personType].SetAddressCircularProgress(true);
    auxAddressSettersObject[personType].SetActionCircularProgress(true);
    let auxEditableAddresses = JSON.parse(
        JSON.stringify(auxAddressValuesObject[personType].EditableAddresses)
    );
    let auxAddressForm = JSON.parse(JSON.stringify(auxAddressValuesObject[personType].AddressForm));
    let auxAddressEditableForm = JSON.parse(
        JSON.stringify(auxAddressValuesObject[personType].AddressEditableForm)
    );
    let auxAddress = JSON.parse(
        JSON.stringify(auxEditableAddresses.find((add) => add.id == [auxAddressValuesObject[personType].ActiveAddress]))
    );
    let wasFavourite = auxAddress.favourite;
    prepareAddChangeAddressNew(
        auxAddress,
        auxAddressEditableForm,
        auxAddressForm,
        auxAddressValuesObject[personType].ActiveAddress
    );
    setIsFavouriteChange(
        isFavourite,
        auxEditableAddresses,
        auxAddress,
        wasFavourite,
        personType,
        auxAddressValuesObject,
        auxAddressSettersObject
    );
    auxInfo.isFavourite = isFavourite;
    auxInfo.personType = personType;
    if (personType == EnumPersonType.User) {
        PostProfileUserCreateOrUpdateAddress(auxAddress, true).then((response) => {
            auxInfo.response = response;
            prepareUpdateAddress(
                auxEditableAddresses,
                auxAddressForm,
                auxAddress,
                auxInfo,
                auxFunctions,
                auxAddressValuesObject,
                auxAddressSettersObject
            );
            auxFunctions.finishOperation("success", auxFunctions.doTraduction("userDataChangeDirectionSuccess"));
        });
    } else {
        if (!checkIsNullUndefined(auxInfo.idEnterprise)) {
            PostEnterpriseDataCreateOrUpdateAddress(auxAddress, auxInfo.idEnterprise, true).then((response) => {
                auxInfo.response = response;
                prepareUpdateAddress(
                    auxEditableAddresses,
                    auxAddressForm,
                    auxAddress,
                    auxInfo,
                    auxFunctions,
                    auxAddressValuesObject,
                    auxAddressSettersObject
                );
                auxFunctions.finishOperation("success", auxFunctions.doTraduction("userDataChangeDirectionSuccess"));
            });
        } else {
            prepareSetChangeAddress(
                auxEditableAddresses,
                auxAddressForm,
                auxAddress,
                auxAddressValuesObject,
                auxAddressSettersObject,
                auxInfo
            );
            auxFunctions.finishOperation("warning", auxFunctions.doTraduction("enterpriseDataAddressWarning"));
        }
    }
};

const setIsFavouriteChange = (
    isFavourite,
    auxEditableAddresses,
    auxAddress,
    wasFavourite,
    personType,
    auxAddressValuesObject,
    auxAddressSettersObject
) => {
    let auxActiveAddress = auxAddressValuesObject[personType].ActiveAddress;
    if (isFavourite) {
        auxEditableAddresses.forEach((address) => {
            if (address.id === auxActiveAddress) {
                address.favourite = true;
            } else {
                address.favourite = false;
            }
        });
        auxAddressSettersObject[personType].SetHasFavouriteAddress(true);
    } else if (wasFavourite) {
        auxAddressSettersObject[personType].SetHasFavouriteAddress(false);
    }
    auxAddress.favourite = isFavourite;
};

const prepareUpdateAddress = (
    auxEditableAddresses,
    auxAddressForm,
    auxAddress,
    auxInfo,
    auxFunctions,
    auxAddressValuesObject,
    auxAddressSettersObject
) => {
    if (auxInfo.response && !auxInfo.response.Status) {
        prepareSetChangeAddress(
            auxEditableAddresses,
            auxAddressForm,
            auxAddress,
            auxAddressValuesObject,
            auxAddressSettersObject,
            auxInfo
        );
    } else {
        auxFunctions.finishOperation("error", getErrorMessage(auxInfo.response, auxFunctions.doTraduction));
        auxAddressSettersObject[auxInfo.personType].SetAddressCircularProgress(false);
        auxAddressSettersObject[auxInfo.personType].SetActionCircularProgress(false);
    }
};

const prepareSetChangeAddress = (
    auxEditableAddresses,
    auxAddressForm,
    auxAddress,
    auxAddressValuesObject,
    auxAddressSettersObject,
    auxInfo
) => {
    refillAddressValues(auxAddressForm, auxAddress, auxInfo.personType);
    let index = auxEditableAddresses.findIndex((address) => address.id == auxAddressValuesObject[auxInfo.personType].ActiveAddress);
    auxEditableAddresses[index] = auxAddress;
    auxAddressSettersObject[auxInfo.personType].SetEditableAddresses(auxEditableAddresses);
    auxAddressSettersObject[auxInfo.personType].SetAddressForm(auxAddressForm);
    auxAddressSettersObject[auxInfo.personType].SetIsFavouriteAddress(auxInfo.isFavourite);
    auxAddressSettersObject[auxInfo.personType].SetAddressCircularProgress(false);
    auxAddressSettersObject[auxInfo.personType].SetActionCircularProgress(false);
};

export const handleAuxRemoveAddress = (personType, auxAddressSettersObject, auxAddressValuesObject, auxFunctions) => {
    auxAddressSettersObject[personType].SetAddressCircularProgress(true);
    auxAddressSettersObject[personType].SetActionCircularProgress(true);
    let auxEditableAddresses = JSON.parse(
        JSON.stringify(auxAddressValuesObject[personType].EditableAddresses)
    );
    let auxAddressForm = JSON.parse(JSON.stringify(auxAddressValuesObject[personType].AddressForm));
    let auxAddress = JSON.parse(
        JSON.stringify(auxEditableAddresses.find((add) => add.id == [auxAddressValuesObject[personType].ActiveAddress]))
    );
    if (auxAddress.id == 0) {
        prepapreSetRemoveAddress(
            auxAddress,
            auxEditableAddresses,
            auxAddressForm,
            personType,
            auxAddressValuesObject,
            auxAddressSettersObject,
        );
    } else {
        let auxDeleteFunction = personType == EnumPersonType.User ? DeleteVerificationDataAddress : DeleteVerificationDataEnterpriseAddress;
        auxDeleteFunction(auxAddress.id).then((response) => {
            if (response && !response.Status) {
                prepapreSetRemoveAddress(
                    auxAddress,
                    auxEditableAddresses,
                    auxAddressForm,
                    personType,
                    auxAddressValuesObject,
                    auxAddressSettersObject,
                );
            } else {
                auxFunctions.finishOperation("error", getErrorMessage(response, auxFunctions.doTraduction));
                auxAddressSettersObject[personType].SetAddressCircularProgress(false);
                auxAddressSettersObject[personType].SetActionCircularProgress(false);
            }
        });
    }
};

const prepapreSetRemoveAddress = (
    auxAddress,
    auxEditableAddresses,
    auxAddressForm,
    personType,
    auxAddressValuesObject,
    auxAddressSettersObject,
) => {
    let index = auxEditableAddresses.findIndex((address) => address.id == auxAddressValuesObject[personType].ActiveAddress);
    auxEditableAddresses.splice(index, 1);
    auxAddressForm.forEach((attribute) => {
        attribute.value = "";
    });
    if (auxAddress.favourite) {
        auxAddressSettersObject[personType].SetHasFavouriteAddress(false);
    };
    auxAddressSettersObject[personType].SetIsFavouriteAddress(false);
    auxAddressSettersObject[personType].SetEditableAddresses(auxEditableAddresses);
    auxAddressSettersObject[personType].SetAddressForm(auxAddressForm);
    auxAddressSettersObject[personType].SetActionCircularProgress(false);
    auxAddressSettersObject[personType].SetAddressCircularProgress(false);
};

export const handleAuxIsControlledCountry = (personType, auxAddressValuesObject, auxAddressSettersObject) => {
    let auxAddressForm = auxAddressValuesObject[personType].AddressForm;
    let auxAttr = auxAddressForm.find(
        (attr) =>
            (attr.codeAttribute === AttributeCodes.Country || attr.codeAttribute === AttributeCodes.EntCountry) &&
            (attr.value == EnumCountries.ESP ||
                attr.value == EnumCountries.AND ||
                checkIsNullUndefined(attr.value))
    );
    auxAddressSettersObject[personType].SetIsControlledCountry(!checkIsNullUndefined(auxAttr));
};

export const changeAddressSelected = (isUserData, auxValue, auxAddressSettersObject, auxAddressValuesObject) => {
    let personType = isUserData ? EnumPersonType.User : EnumPersonType.Enterprise;
    let auxAddresses = auxAddressValuesObject[personType].EditableAddresses;;
    let auxAddress = auxAddresses.find((address) => address.id == auxValue);
    if (!checkIsNullUndefined(auxAddress)) {
        auxAddressSettersObject[personType].SetActiveAddress(auxValue);
    };
};

export const changeAddressParam = (isUserData, attribute, auxValue, first, auxAddressSettersObject, auxAddressValuesObject, auxFunctions) => {
    let addressSpecialCodes = [
        AttributeCodes.Country,
        AttributeCodes.Province,
        AttributeCodes.City,
        AttributeCodes.PostalCode,
        AttributeCodes.EntCountry,
        AttributeCodes.EntProvince,
        AttributeCodes.EntCity,
        AttributeCodes.EntPostalCode,
    ];
    let personType = isUserData ? EnumPersonType.User : EnumPersonType.Enterprise;
    let auxAddressForm = JSON.parse(JSON.stringify(auxAddressValuesObject[personType].AddressEditableForm));
    if (addressSpecialCodes.includes(attribute.codeAttribute) && first) {
        ManageAddressRelation(
            auxAddressForm,
            attribute.codeAttribute,
            auxFunctions.setAll,
            auxValue,
            auxFunctions.finishOperation,
            auxFunctions.doTraduction
        );
    } else {
        let auxAttribute = obtainAttribute(
            auxAddressForm,
            attribute.codeAttribute
        );
        auxAttribute.value = auxValue;
        auxAddressSettersObject[personType].SetAddressEditableForm(auxAddressForm);
        auxAddressSettersObject[personType].SetAddressEditableFormOk(
            checkEditableDirOkNew(
                auxAddressForm,
                getAddresses(auxAddressValuesObject[personType].EditableAddress),
                auxAddressValuesObject[personType].AddressOperationType
            )
        );
    }
};

const refillAddressValues = (auxAddressForm, auxAddress, personType) => {
    let auxCountryCode = personType == EnumPersonType.User ? AttributeCodes.Country : AttributeCodes.EntCountry;
    let auxCountryAttr = obtainAttribute(auxAddressForm, auxCountryCode);
    let auxCountry = getCountryForCode(auxCountryAttr.dropdownValues, auxCountryAttr.value);
    auxAddress.countryName = auxCountry.name;
    let auxViaCode = personType == EnumPersonType.User ? AttributeCodes.Via : AttributeCodes.EntVia;
    setAddressValues(auxAddressForm, auxAddress, auxViaCode);
    let auxProvinceCode = personType == EnumPersonType.User ? AttributeCodes.Province : AttributeCodes.EntProvince;
    let auxCityCode = personType == EnumPersonType.User ? AttributeCodes.City : AttributeCodes.EntCity;
    if (auxCountryAttr.value == EnumCountries.ESP || auxCountryAttr.value == EnumCountries.AND) {
        setAddressValues(auxAddressForm, auxAddress, auxProvinceCode);
        setAddressValues(auxAddressForm, auxAddress, auxCityCode);
    } else {
        auxAddress[Attributes[auxProvinceCode].attributeValue] = null;
        auxAddress[Attributes[auxCityCode].attributeValue] = null;
    };
};

const setAddressValues = (auxAddressForm, auxAddress, code) => {
    let auxSearchValue = (code != AttributeCodes.City && code != AttributeCodes.EntCity) ? "code" : "codeLocalidad";
    let auxAttr = obtainAttribute(auxAddressForm, code);
    let auxElement = auxAttr.dropdownValues.find((elem) => elem[auxSearchValue] == auxAttr.value);
    auxAddress[Attributes[code].attributeValue] = auxElement.name;
};