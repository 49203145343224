export const VATYC2 = {

    //Cookies Politicy TyC Tittles

    tycCookiesPolicityTittle1: "Què són les galletes?",
    tycCookiesPolicityTittle2: "Quin tipus de galletes hi ha?",
    tycCookiesPolicityTittle3:
        "Quines galletes concretes utilitza este lloc web?",
    tycCookiesPolicityTittle4: "Com modifique la configuració de les galletes?",

    //Cookies Politicy TyC Texts

    tycCookiesPolicityText1:
        "La Sociedad Estatal Correos y Telégrafos, SA (d’ara endavant, “Correos”) l’informa com a usuari sobre les galletes d’esta pàgina web. Esta Política de galletes pot ser objecte de modificacions, per la qual cosa li recomanem que la consulte cada vegada que hi accedisca.",
    tycCookiesPolicityText2:
        "Una galleta és un arxiu o dispositiu que es descarrega a l’ordinador, el telèfon o la tauleta en accedir a determinades pàgines web per a emmagatzemar i recuperar informació de l’equip terminal. Entre altres funcions, permet emmagatzemar i recuperar informació sobre els seus hàbits de navegació a fi de millorar el servici oferit. Una galleta és un xicotet fitxer de text que un lloc web col·loca al PC, el telèfon o qualsevol altre dispositiu amb informació sobre la seua navegació en este lloc. Les galletes són necessàries per a facilitar la navegació i fer-la més amigable i no danyen l’ordinador.",
    tycCookiesPolicityText3:
        "Hi ha diferents tipus de galletes que es poden classificar segons:",
    tycCookiesPolicityText4:
        "L’entitat que gestiona el domini des d’on s’envien les galletes i tracten les dades.",
    tycCookiesPolicityText5:
        "Galletes pròpies: s’envien al seu equip terminal des dels nostres equips o dominis propis.",
    tycCookiesPolicityText6:
        "Galletes de tercers: s’envien a l’equip terminal des d’un equip o domini d’una altra entitat col·laboradora.",
    tycCookiesPolicityText7:
        "El termini de temps que romanen emmagatzemades al navegador",
    tycCookiesPolicityText8:
        "Galletes de sessió: s’activen mentres accedix a la pàgina web o per a la prestació del servici sol·licitat.",
    tycCookiesPolicityText9:
        "Galletes persistents: s’emmagatzemen per un temps determinat en l’equip terminal. El responsable hi té accés quan es connecta a la seua pàgina web.",
    tycCookiesPolicityText10:
        "La finalitat del tractament de les dades obtingudes",
    tycCookiesPolicityText11:
        "Galletes tècniques: són necessàries per a l’ús del lloc web i per a la prestació de servicis.",
    tycCookiesPolicityText12:
        "Galletes de personalització: permeten accedir al servici amb algunes característiques de caràcter general predefinides en funció d’una sèrie de criteris al terminal (idioma, tipus de navegador...).",
    tycCookiesPolicityText13:
        "Galletes d’anàlisi: permeten el seguiment i l’anàlisi estadística del comportament del conjunt dels usuaris dels llocs web als quals estan vinculades.",
    tycCookiesPolicityText14:
        "Galletes publicitàries: permeten la gestió dels espais publicitaris a la pàgina web, l’aplicació o la plataforma des de la qual es presta el servici sol·licitat.",
    tycCookiesPolicityText15:
        "Galletes de publicitat comportamental: emmagatzemen informació del seu comportament obtinguda a través dels hàbits de navegació, la qual cosa permet desenrotllar un perfil específic per a mostrar publicitat.",
    tycCookiesPolicityText16:
        "A continuació, s’identifiquen les galletes utilitzades en el nostre lloc web, així com la seua descripció, la duració de l’emmagatzematge i la titularitat:",
    tycCookiesPolicityText17:
        "Pot prestar el seu consentiment a l’ús de galletes o revocar-lo en qualsevol moment a través de l’enllaç ",
    tycCookiesPolicityText18_B: "Configuració de galletes.",
    tycCookiesPolicityText18:
        "També pot fer-ho utilitzant el navegador, a través del qual pot permetre, restringir, bloquejar o esborrar les galletes utilitzades des del nostre lloc web.",
    tycCookiesPolicityText19:
        "La manera de fer-ho serà diferent en funció del tipus de navegador utilitzat durant la navegació. En els enllaços següents té a la seua disposició tota la informació per a configurar o deshabilitar les galletes en cada navegador.",
    tycCookiesPolicityText20:
        "Internet Explorer: microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=»ie-10″",
    tycCookiesPolicityText21: "Firefox: mozilla.org/es/kb/Borrar%20cookies",
    tycCookiesPolicityText22: "Chrome: google.com/chrome/answer/95647?hl=»es»",
    tycCookiesPolicityText23: "Safari: apple.com/es/privacy/use-of-cookies/",

    //Cookies Politicy TyC Tables Texts

    tycCookiesPolicityTableHead1: "Denominació",
    tycCookiesPolicityTableHead2: "Finalitat",
    tycCookiesPolicityTableHead3: "Duració",
    tycCookiesPolicityTableHead4: "Titular",

    //Table Tittles

    tycCookiesPolicityTableTittle1: "Galletes tècniques i de personalització",
    tycCookiesPolicityTableTittle2: "Galletes analítiques",
    tycCookiesPolicityTableTittle3: "Galletes de publicitat comportamental",

    //Table Text

    session: "Sessió",
    persistent: "Persistent",

    //Tale 1

    tycCookiesPolicityTable1_Text1:
        "S’utilitzen per a identificar i autenticar l’usuari. Conté, a més, dades tècniques de la sessió d’usuari com, per exemple, temps d’espera de connexió, identificador de sessió, etc.",
    tycCookiesPolicityTable1_Text2:
        "Identifica la sessió http de l’usuari. És comú en totes les aplicacions web per a identificar peticions d’un usuari en una sessió",
    tycCookiesPolicityTable1_Text3:
        "Permet identificar en quin estat de navegació es troba l’usuari (inici de la sessió, primera pàgina, primer accés, estat d’un scroll, estat d’una votació, etc.).",
    tycCookiesPolicityTable1_Text4:
        "Emmagatzemen els valors de sessió seleccionats per l’usuari com ara la tenda, l’idioma, la moneda, els productes, la talla, etc.",

    //Table 2 and 3

    tycCookiesPolicityDoesntExist: "No n’hi ha a Correos ID",

    // Cookie Banner

    cookieBannerAcceptAll: "Aceptar",
    cookieBannerConfigure: "Configurar",
    cookieBannerWeUseCookies1: "Utilizamos cookies para personalizar el contenido, adaptar los anuncios, medir su eficacia y ofrecer una experiencia más segura. Al navegar por el sitio web, aceptas el uso de cookies para recopilar información dentro y fuera de Correos.",
    cookieBannerWeUseCookies2: "Lee nuestra ",
    cookieBannerWeUseCookies3: "Política de Cookies",
    cookieBannerWeUseCookies4: " para obtener más información o accede a las preferencias de cookies para gestionar tu configuración",
    cookieBannerLearnMore: "Puedes obtener más información en nuestra",
    cookieDialogDescriptionTPE: "Permiten la navegación a través de nuestra web y ejecutar la acción solicitada por el usuario. Por ejemplo, permitir la reproducción de contenido multimedia, registrar sus pedidos en el carrito de compra, reconocer inicios de sesión y la selección de idioma en función del lugar de acceso, etc.",
    cookieDialogDescriptionANA: "Ofrecen distintas métricas de uso de la web que nos permiten mejorar la navegación del usuario, conocer qué apartados de la web resultan más atractivos, desde dónde acceden los usuarios, etc. Se trata información de carácter estadístico y que es proporcionada a nivel agregado, salvo por el registro de la dirección IP desde la que accede.",
    cookieDialogDescriptionPCO: "Son aquéllas que almacenan información del comportamiento de los usuarios obtenida a través de los hábitos de navegación del usuario, lo que permite desarrollar un perfil específico para mostrar publicidad.",
    cookieDialogMandatoryCookie: "(Cookies estrictamente necesarias)",
    cookieDialogMandatory: "Obligatorio",
    cookieDialogAllow: "Permitir",
    cookieDialogDisallow: "No permitir",
    cookieDialogSave: "Guardar Preferencias",
    cookieDialogAllowAll: "Permitir Todo",
    cookieDialogDisallowAll: "No permitir nada",
    cookieDialogCookiesPolicyText: "Puedes obtener más información en nuestra",
};
