import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { EnumPages } from "../../commons/EnumPages";
import { Alerts } from "../../components/Alerts/Alerts";
import { checkIsNullUndefined, decode64, encode64 } from "../../commons/Utils";
import { LoginPage } from "../LoginPage/LoginPage";
import { RegisterPage } from "../RegisterPage/RegisterPage";
import { ResetPasswordPage } from "../ResetPassword/ResetPasswordPage";
import { GetApplicationAuxCountry, PostApplicationAuxSSOLogin } from "../../services/ApplicationAuxCallsService";
import { getSessionItem, setUserSession } from "../../services/Commons";
import { Constants } from "../../commons/Constants";
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid } from "@material-ui/core";
import { PublicBackGroundPage } from "../../components/Templates/PublicBackGroundPage";
import { sessionValues } from "../../commons/EnumsGeneral";

export function PublicPage(props) {
    const { location } = props;
    const cookies = new Cookies();
    const { t, i18n } = useTranslation();

    const [alert, setAlert] = useState({
        color: "",
        message: "",
    });
    const [openPage, setOpenPage] = useState(location?.pathname);
    const [personType, setPersonType] = useState();
    const [pageReady, setPageReady] = useState(false);
    const [personTypeSelected, setPersonTypeSelected] = useState("");
    const [isSSOPath, setIsSSOPath] = useState(false);
    const [masterCountryList, setMasterCountryList] = useState([]);

    useEffect(() => {
        let auxOpenPage = EnumPages.Login;
        if (!checkIsNullUndefined(location?.pathname) && location.pathname != "/") {
            auxOpenPage = (location?.pathname).replace("/", "");
        };
        setOpenPage(auxOpenPage);
        setPersonType(getSessionItem(sessionValues.appPersonType));
        if (masterCountryList.length == 0) {
            GetApplicationAuxCountry().then((response) => {
                if (response && !response.Status) {
                    setMasterCountryList(response);
                };
                manageSessionCookie();
            });
        } else {
            manageSessionCookie();
        };
    }, [location]);

    const manageSessionCookie = () => {
        let sessionCookie = cookies.get(Constants.cookieValue);
        if (!checkIsNullUndefined(sessionCookie) && process.env.REACT_APP_ENVELOP_ACTIVE_SESSION_COOKIE == "true" && checkCookieExp(sessionCookie)) {
            let auxCookie = decode64(sessionCookie);
            let encodedCookie = encode64(auxCookie.IdCookie);
            PostApplicationAuxSSOLogin(encodedCookie).then((response) => {
                if (response && !response.Status) {
                    setUserSession(response, i18n, null).then(() => {
                        setIsSSOPath(true);
                        setOpenPage(EnumPages.Login);
                        setPageReady(true);
                    });
                } else {
                    setPageReady(true);
                };
            });
        } else {
            setPageReady(true);
        };
    };

    const checkCookieExp = (sessionCookie) => {
        let auxCookie = decode64(sessionCookie);
        let current = new Date();
        let expireDate = new Date(auxCookie.ExpirationDate);
        return current < expireDate;
    };

    const renderPage = () => {
        switch (openPage) {
            case EnumPages.Login:
                return <LoginPage personType={personType} personTypeSelected={personTypeSelected} isSSOPath={isSSOPath} masterCountryList={masterCountryList} />;
            case EnumPages.Registry:
                return <RegisterPage personType={personType} masterCountryList={masterCountryList} />;
            case EnumPages.ResetPassword:
                return <ResetPasswordPage masterCountryList={masterCountryList} />;
            default:
                return <LoginPage personType={personType} personTypeSelected={personTypeSelected} isSSOPath={isSSOPath} masterCountryList={masterCountryList} />;
        };
    };

    return (
        <Grid>
            <Alerts alert={alert} setAlert={setAlert}></Alerts>
            <PublicBackGroundPage
                login={true}
                personType={personType}
                setPersonTypeSelected={setPersonTypeSelected}
                openPage={openPage}
            >
                {pageReady ? renderPage() : <CircularProgress />}
            </PublicBackGroundPage>
        </Grid>
    );
};