import { makeStyles } from "@material-ui/core/styles";

export const StylesBackgroundLogin = makeStyles((theme) => ({
  mainPaperLogin: {
    marginTop: "3rem",
    maxWidth: "28rem",
    minHeight: "20rem",
    margin: "auto",
    padding: "3.5rem 3rem 2rem 3rem",
    display: "flow-root",
  },
  mainPaper: {
    marginTop: "3rem",
    minHeight: "20rem",
    margin: "auto",
    padding: "3.5rem 1.2rem 2rem 2.2rem",
    display: "flow-root",
  },
  imgLoginDiv: {
    position: "absolute",
    width: "100%",
  },
  imgLogin: {
    maxWidth: "6rem",
    marginLeft: "auto",
    marginRight: "auto",
  },
  legalTypography: {
    marginTop: "1.5vh",
  },
  legalUrl: {
    textDecoration: "none",
    color: "black",
    fontSize: "small",
  },
  mainDiv: {
    textAlign: "center",
    display: "flow-root",
    position: "relative",
    paddingTop: "7.9em",
    [theme.breakpoints.down("md")]: {
      paddingTop: "5.8em"
    },
  },
  mainNewDiv: {
    textAlign: "left",
    display: "flow-root",
    position: "relative",
    paddingTop: "5em",
    [theme.breakpoints.down("md")]: {
      paddingTop: "3em"
    },
  },
  logNewDiv: {
    textAlign: "center",
    display: "flow-root",
    position: "relative",
  },
  regNewDiv: {
    textAlign: "center",
    display: "flow-root",
    position: "relative",
  },
  publicDiv: {
    textAlign: "center",
    display: "flow-root",
    position: "relative",
    marginLeft: "10%",
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto"
    },
  },
  publicLoginDiv: {
    marginLeft: "10% !important",
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto !important"
    },
  },
  publicRegistryDiv: {
    paddingLeft: "36px",
    paddingBottom: "2em"
  },
  customTooltip: {
    backgroundColor: "#002453",
  },
  customArrow: {
    color: "#002453",
  },
  leftImgCover: {
    maxWidth: "100%",
    height: window.screen.height - 270,
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
  },
  loginImageDiv: {
    float: "left",
    position: "fixed",
    width: "42%",
    height: window.screen.height - 270,
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
  },
  leftImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  rightContainer: {
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
  },
  goToRegistryLink: {
    color: "#002453",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": { color: "#002453", textDecoration: "underline" },
  },
}));
