export const CATYC1 = {

    //TyCs and Privacies Page

    tycUrlsText: "Si us plau, llegiu atentament els termes i condicions d'ús fent clic sobre els següents enllaços:",
    privacyUrlsText: "Si us plau, llegiu atentament les polítiques de privadesa fent clic sobre els següents enllaços:",
    privacyNoUrlsText: "Para la aplicación y el tipo de persona indicados no existe privacidad que firmar.",
    tycNoUrlsText: "Para la aplicación y el tipo de persona indicados no existen TyC's que firmar.",
    atcText: "Un cop comunicat el codi de verificació a l'operari ATC es consideraran acceptats els termes i condicions d'ús.",

    //Terms and Conditions

    tycHeaderTitle: "Termes i condicions",

    //WarningLegalTyC Subtittles

    tycWarningLegalSubtittle1:
        "DRETS DE PROPIETAT INTEL·LECTUAL I DE PROPIETAT INDUSTRIAL",
    tycWarningLegalSubtittle2: "PROTECCIÓ DE DADES DE CARÀCTER PERSONAL",
    tycWarningLegalSubtittle3: "MESURES DE SEGURETAT",
    tycWarningLegalSubtittle4: "RESPONSABILITATS DE CORREOS",
    tycWarningLegalSubtittle5: "RESPONSABILITAT DE L’USUARI",
    tycWarningLegalSubtittle6: "LLEI APLICABLE I JURISDICCIÓ",

    //WarningLegalTyC texts

    tycWarningLegalText1:
        "En compliment del que estableix l’art. 10 de la Llei 34/2002, d’11 de juliol, de serveis de la societat de la informació i comerç electrònic (LSSI-CE), s’informa que aquesta pàgina web és titularitat de Correos.",
    tycWarningLegalText2:
        "Aquest document conté l’Avís legal de Correos ID proporcionat per la Sociedad Estatal Correos y Telégrafos, SA (d’ara endavant, “Correos”), amb domicili social a la via Dublín, núm. 7 (Campo de las Naciones) 28070 Madrid (Espanya), CIF número A83052407.",
    tycWarningLegalText3:
        "El contacte per a l’atenció al client del servei és per mitjà telefònic a través del número d’Atenció al Client de Correos: 902.197.197 o a la pàgina web de Correos.",
    tycWarningLegalText4:
        "Tant el disseny del portal i els seus codis font, com els logotips, les marques i altres signes distintius que hi apareixen pertanyen a Correos i estan protegits pels corresponents drets de propietat intel·lectual i industrial.",
    tycWarningLegalText5:
        "El seu ús, reproducció, distribució, comunicació pública, transformació o qualsevol altra activitat similar o anàloga queda totalment prohibit, tret que hi hagi autorització expressa per escrit de Correos.",
    tycWarningLegalText6:
        "Els continguts del portal estan igualment protegits per drets de propietat intel·lectual de Correos. L’ús dels continguts elaborats per Correos o per alguna de les entitats del seu Grup es permet únicament en l’àmbit d’una relació contractual amb aquesta o amb les entitats esmentades.",
    tycWarningLegalText7:
        "Correos declara el seu respecte als drets de propietat intel·lectual i industrial de tercers; per això, si considereu que aquest lloc pot estar violant els vostres drets, us preguem que us poseu en contacte amb Correos al número de telèfon següent: 902.197.197.",
    tycWarningLegalText8_1:
        "La navegació a través de la nostra pàgina web no requereix el registre previ. No obstant això, quan visiteu la nostra pàgina web, els nostres servidors web emmagatzemen, de manera estàndard, informació com l’adreça IP i el domini des del qual s’obté l’accés, la data i l’hora de la visita, etc. D’altra banda, certes funcionalitats de la nostra pàgina web exigeixen que faciliteu informació addicional a través del corresponent formulari (p. ex. el nom i cognoms, l’adreça postal o electrònica de contacte, el telèfon...). Correos tractarà les vostres dades de caràcter personal d’acord amb les condicions publicades a la ",
    tycWarningLegalText8_B: "Política de privacitat web ",
    tycWarningLegalText8_2:
        "i, si escau, a la Política de protecció de dades que resulti aplicable als diferents serveis sol·licitats.",
    tycWarningLegalText9:
        "Us informem que s’han adoptat totes les mesures de seguretat d’índole tècnica, organitzativa i de seguretat informàtica necessàries per a la protecció de dades de caràcter personal per evitar-ne l’alteració, la pèrdua, el tractament i/o l’accés no autoritzat, tot això d’acord amb el que estableix la normativa aplicable.",
    tycWarningLegalText10:
        "Ús incorrecte del portal: Correos ha creat el portal per a la promoció dels seus productes i per facilitar l’accés als seus serveis, però no pot controlar-ne la utilització d’una manera diferent de la prevista en aquest Avís legal; per tant, l’accés al portal i l’ús correcte de la informació que hi conté són responsabilitat de qui realitza aquestes accions, i Correos no és responsable per l’ús incorrecte, il·lícit o negligent que en pogués fer l’usuari.",
    tycWarningLegalText11:
        "Utilització dels continguts: Correos facilita tots els continguts del seu portal (Correos ID) de bona fe i farà els seus millors esforços perquè estiguin permanentment actualitzats i vigents. No obstant això, Correos no pot assumir cap responsabilitat respecte de l’ús o l’accés que facin els usuaris fora de l’àmbit a què es dirigeix el portal, ni de les conseqüències que pogués portar l’aplicació pràctica de les opinions, les recomanacions o els estudis a què es pugui accedir a través del portal, i la seva responsabilitat final recaurà sempre sobre l’usuari. Així mateix, Correos no pot controlar els continguts que no hagin estat elaborats per ella o per tercers complint el seu encàrrec i, per tant, no respondrà en cap cas dels danys que es poguessin causar per aquests continguts de tercers.",
    tycWarningLegalText12:
        "Publicitat: Correos inclourà al portal publicitat pròpia o de tercers per oferir-vos productes o serveis que entengui que poden ser del vostre interès. Tanmateix, Correos no pot controlar l’aparença d’aquesta publicitat, ni la qualitat i l’adequació d’aquests productes o serveis i, en conseqüència, Correos no respondrà de cap dany que es pogués generar a l’usuari per aquestes causes.",
    tycWarningLegalText13:
        "Virus: Correos es compromet a aplicar totes les mesures necessàries per intentar garantir a l’usuari l’absència de virus informàtics i elements similars al seu portal. No obstant això, aquestes mesures no són infal·libles i, per això, Correos no pot assegurar totalment l’absència d’aquests elements nocius. En conseqüència, Correos no serà responsable dels danys que aquests puguin produir a l’usuari.",
    tycWarningLegalText14:
        "Fallades tecnològiques: Correos ha conclòs tots els contractes necessaris per a la continuïtat del seu portal i farà els seus millors esforços perquè no pateixi interrupcions, però no pot garantir l’absència de fallades tecnològiques, ni la permanent disponibilitat del portal i dels productes o serveis que hi conté i, en conseqüència, no assumeix cap responsabilitat pels danys i els perjudicis que es puguin generar per la manca de disponibilitat i per les fallades en l’accés ocasionades per desconnexions, avaries, sobrecàrregues o caigudes de la xarxa no imputables a Correos.",
    tycWarningLegalText15:
        "Intercanvi d’informació: el portal pot contenir determinades zones pensades perquè els diferents usuaris puguin posar en comú les seves experiències i per fomentar l’intercanvi d’informació. Tanmateix, Correos únicament actua en aquests fòrums com a lloc de reunió i no controla les expressions que s’hi aboquen, per la qual cosa no assumeix cap responsabilitat per la veracitat o pertinència de les manifestacions que els usuaris hi efectuen.",
    tycWarningLegalText16:
        "L’usuari es compromet a utilitzar els serveis del portal d’acord amb els termes expressats en aquest Avís legal, i la mera utilització dels serveis n’implica l’acceptació.",
    tycWarningLegalText18:
        "L’usuari que actuï contra la imatge, el bon nom o la reputació de Correos, i qui utilitzi de manera il·lícita o fraudulenta els dissenys, els logotips o els continguts del portal, serà responsable davant Correos de la seva actuació.",
    tycWarningLegalText19:
        "La llei aplicable en cas de disputa o conflicte d’interpretació dels termes que conformen aquest Avís legal, així com qualsevol qüestió relacionada amb els productes o serveis d’aquest portal, és la llei espanyola, tret que les normes de protecció dels consumidors i usuaris estableixin l’aplicabilitat d’una legislació diferent.",
    tycWarningLegalText20:
        "Per a la resolució de comunicats, se sotmeten a la jurisdicció dels jutjats i tribunals de la capital de província d’Espanya que correspongui.",

    //PrivacyPoliticyTyC Subtittles

    tycPrivacyPoliticyTyCSubtittle1: "Responsable del tractament",
    tycPrivacyPoliticyTyCSubtittle2: "Finalitat del tractament i legitimació",
    tycPrivacyPoliticyTyCSubtittle3: "Tipus de dades tractades",
    tycPrivacyPoliticyTyCSubtittle4: "Termini de conservació de les dades",
    tycPrivacyPoliticyTyCSubtittle5: "Els vostres drets",
    tycPrivacyPoliticyTyCSubtittle6: "Accés a tercers",

    //PrivacyPoliticyTyC list tittles

    tycPrivacyPoliticyTyCListTittle1: "Prestació del servei Correos ID",
    tycPrivacyPoliticyTyCListTittle2:
        "Tramesa d’ofertes i promocions de productes i serveis de Correos",
    tycPrivacyPoliticyTyCListTittle3:
        "Tramesa d’ofertes i promocions d’altres empreses del Grupo Correos i de terceres empreses",
    tycPrivacyPoliticyTyCListTittle4:
        "Comunicació de dades a altres empreses del Grupo Correos i a terceres empreses",
    tycPrivacyPoliticyTyCListTittle5: "Complir les obligacions legals",
    tycPrivacyPoliticyTyCListTittle6:
        "Informació estadística i enquestes de satisfacció",
    tycPrivacyPoliticyTyCListTittle7: "Enriquir la informació",
    tycPrivacyPoliticyTyCListTittle8: "Dades proporcionades de manera directa:",
    tycPrivacyPoliticyTyCListTittle9: "Dades obtingudes de manera indirecta:",
    tycPrivacyPoliticyTyCListTittle10:
        "Dades derivades de la mateixa prestació del servei:",

    //PrivacyPoliticyTyC texts

    tycPrivacyPoliticyTyCText1:
        "Per a totes les empreses del grup Correos, respectar la vostra privacitat i complir la normativa de protecció de dades és un aspecte de vital importància.",
    tycPrivacyPoliticyTyCText2_1:
        "El nostre desig és que a tota hora conegueu per a què utilitzem, o volem utilitzar, les vostres dades i quins són els vostres drets. Amb aquest objectiu, hem redactat la següent Política de protecció de dades, que regula el tractament de dades derivat del servei Correos ID, així com la navegació a través de la pàgina web ",
    tycPrivacyPoliticyTyCText2_B1: "id.correos.post",
    tycPrivacyPoliticyTyCText2_2: " (anteriorment, ",
    tycPrivacyPoliticyTyCText2_B2: "emiidentidad.correos.post",
    tycPrivacyPoliticyTyCText2_3:
        " ) i qualsevol dels seus subdominis o d’altres webs de Correos que mostrin, al peu de pàgina, aquesta Política de privacitat.",
    tycPrivacyPoliticyTyCText3:
        "La informació següent pot ser objecte de modificació per a qualsevol adaptació legal a futures novetats legislatives o jurisprudencials, així com pràctiques de la indústria. En qualsevol cas, aquesta modificació es comunicarà a l’usuari mitjançant qualsevol via de comunicació vàlida.",
    tycPrivacyPoliticyTyCText4:
        "El responsable del tractament és Sociedad Estatal Correos y Telégrafos, SA, SME (d’ara endavant “Correos”), amb CIF A-83052407 i domicili social a la via Dublín, núm. 7 (Campo de las Naciones), 28070 Madrid (Espanya).",
    tycPrivacyPoliticyTyCText5:
        "Per garantir la gestió adequada d’aquest tractament, Correos té designat un delegat de protecció de dades (DPD o DPO), a qui us podeu dirigir per a qualsevol qüestió que necessiteu en relació amb les dades a través de l’adreça electrònica següent: ",
    tycPrivacyPoliticyTyCText6:
        "El tractament de les vostres dades persegueix aquestes finalitats:",
    tycPrivacyPoliticyTyCText7: "Les vostres dades seran tractades per",
    tycPrivacyPoliticyTyCText8:
        "Tramitar el vostre registre com a usuari i donar-vos d’alta a través del formulari establert a aquest efecte.",
    tycPrivacyPoliticyTyCText9:
        "Per donar-vos d’alta en alguns dels serveis digitals a través de Correos ID és possible que se sol·liciti informació addicional mitjançant un formulari web establert a l’efecte.",
    tycPrivacyPoliticyTyCText10:
        "Prestar els serveis de Correos ID, de conformitat amb les Condicions particulars del servei.",
    tycPrivacyPoliticyTyCText11:
        "En aquest sentit, Correos ID és un gestor centralitzat d’identitat digital que permet a l’usuari autenticar-se davant dels diferents serveis digitals de Correos i/o d’altres serveis de tercers que acceptin aquest mètode com a sistema d’identificació; és a dir, de manera resumida, permet a l’usuari accedir a diferents serveis a través d’una sola instància d’identificació digital, i evita haver de disposar d’una contrasenya per a cadascun d’aquests serveis.",
    tycPrivacyPoliticyTyCText12:
        "En cas que el servei Correos ID s’utilitzi com a plataforma digital d’identificació davant de serveis que prestin altres empreses del Grup Correos o tercers col·laboradors, cal que les dades de l’usuari es comuniquin a aquestes empreses per poder dur a terme la verificació d’identitat que s’ha sol·licitat.",
    tycPrivacyPoliticyTyCText13:
        "Donar resposta als vostres dubtes, consultes o reclamacions.",
    tycPrivacyPoliticyTyCText14:
        "Tramitar la verificació de la vostra identitat, resultat d’un acord de col·laboració i/o prestació de serveis que Correos hagi subscrit amb un tercer.",
    tycPrivacyPoliticyTyCText15:
        "Així mateix, a través del gestor centralitzat d’identitat digital es pot tramitar la verificació de la vostra identitat, resultat d’un acord de col·laboració i/o prestació de servei subscrit entre Correos i un tercer.",
    tycPrivacyPoliticyTyCText16:
        "En cas que hi presteu el vostre consentiment, Correos us podrà remetre, per qualsevol canal electrònic o no electrònic, informació comercial relacionada amb la prestació, la millora i l’actualització dels nostres productes i serveis, noves tarifes i descomptes.",
    tycPrivacyPoliticyTyCText17:
        "Aquesta informació comercial podrà ser genèrica o personalitzada. En aquest darrer supòsit implicarà el tractament previ de les vostres dades amb l’objectiu de poder procedir al vostre perfilat i carterització, determinar gustos, preferències i necessitats i, partint d’això, identificar les ofertes que us puguin resultar més interessants.",
    tycPrivacyPoliticyTyCText18:
        "En cas que presteu el vostre consentiment, Correos us podrà remetre per qualsevol canal, electrònic o no electrònic, ofertes i promocions de:",
    tycPrivacyPoliticyTyCText19:
        "Altres empreses del Grup Correos que prestin serveis relacionats amb els sectors que pertanyin a les activitats de serveis de paqueteria, de valor afegit al servei postal, logística, màrqueting i telecomunicació.",
    tycPrivacyPoliticyTyCText20:
        "Altres empreses amb les quals Correos hagi subscrit diferents convenis de col·laboració i que poden pertànyer a sectors com les telecomunicacions, financer, gran consum, sanitat i ONG.",
    tycPrivacyPoliticyTyCText21:
        "Aquesta informació comercial podrà ser genèrica o personalitzada. En aquest darrer supòsit implicarà el tractament previ de les vostres dades amb l’objectiu de poder procedir al vostre perfilat i carterització, determinar gustos, preferències i necessitats i, partint d’això, identificar les ofertes que us puguin resultar més interessants.",
    tycPrivacyPoliticyTyCText22:
        "En cas que presteu el vostre consentiment, Correos podrà comunicar les vostres dades a les empreses del Grupo Correos i a terceres empreses els sectors de les quals es detallen en la finalitat anterior amb l’objectiu que aquestes empreses us puguin fer arribar, de manera directa i sense la intermediació de Correos, ofertes i promocions relatives als seus productes i serveis.",
    tycPrivacyPoliticyTyCText23:
        "Dins de les diferents obligacions legals que assumeix Correos, hi ha les derivades de l’aplicació de la normativa postal (Llei 43/2010 del servei postal universal, dels drets dels usuaris i del mercat postal) i el deure de secret de les comunicacions (art. 18 de la Constitució), així com la normativa tributària.",
    tycPrivacyPoliticyTyCText24:
        "En el compliment d’aquestes obligacions, Correos podrà comunicar les vostres dades a les administracions públiques i els tribunals, sempre que aquesta informació sigui requerida conforme als processos legals establerts.",
    tycPrivacyPoliticyTyCText25:
        "Correos té un interès legítim a conèixer tant l’opinió dels seus clients respecte als seus productes i serveis com els seus hàbits de consum (p. ex. la freqüència/recurrència en la contractació dels diferents serveis) amb l’objectiu de poder oferir productes i serveis més ajustats a les seves necessitats i millorar tant els productes i serveis actuals com el seu procés de contractació.",
    tycPrivacyPoliticyTyCText26:
        "Per assolir l’objectiu que s’exposa al paràgraf anterior, Correos pot tractar les teves dades i:",
    tycPrivacyPoliticyTyCText27:
        "Fer enquestes de satisfacció tant telefòniques com per mitjans electrònics, fins i tot una vegada deixeu de ser-ne client; en aquest darrer cas per conèixer els motius de la vostra marxa.",
    tycPrivacyPoliticyTyCText28:
        "En cas que durant l’enquesta de satisfacció el client manifesti la seva opinió sobre els productes i serveis d’alguna de les empreses del Grupo Correos, el resultat de l’enquesta i les dades identificatives del participant podran ser comunicats a l’empresa del Grupo Correos de la qual es valoren els serveis.",
    tycPrivacyPoliticyTyCText29:
        "Procedir a la vostra anonimització, de manera que no es pugui tornar a vincular la informació dels vostres hàbits de consum amb la vostra persona i, després d’això, fer estudis estadístics i economètrics (p. ex. conèixer el cicle de vida del client i/o identificar models de propensió a l’abandonament).",
    tycPrivacyPoliticyTyCText30:
        "En cas que presteu el vostre consentiment, Correos podrà obtenir més informació sobre les vostres característiques, gustos i necessitats a través de la informació proporcionada per diferents fonts externes, com ara registres públics, cadastre, xarxes socials, eines de geomàrqueting, bases de dades d’informació econòmica com INFORMA, fitxers d’informació creditícia com ASNEF, BADEXCUG... amb l’objectiu d’introduir millores en els nostres productes i serveis i/o adaptar o escollir les ofertes d’aquests productes i serveis que millor s’ajustin al vostre perfil concret.",
    tycPrivacyPoliticyTyCText31:
        "Per a les finalitats exposades a l’apartat anterior, es tracta un conjunt de dades de caràcter personal que podem dividir en les fonts i categories següents:",
    tycPrivacyPoliticyTyCText32:
        "En el moment del registre, l’usuari ha de proporcionar una informació mínima que resulta necessària per a la seva verificació i alta, per la qual cosa, tret que s’adverteixi el contrari, la manca d’emplenament impedirà la prestació del servei. Certes funcionalitats (p. ex. l’accés a determinats serveis) exigeixen que faciliteu informació addicional a través del mateix servei.",
    tycPrivacyPoliticyTyCText33:
        "Així mateix, juntament amb la informació proporcionada a través del servei es tractaran dades obtingudes pel servidor, com l’adreça IP des de la qual hi accediu, el domini des del qual s’obté l’accés, la data i l’hora de la visita, la galeta de sessió (aquesta darrera s’elimina en tancar el navegador), etc.",
    tycPrivacyPoliticyTyCText34:
        "El client garanteix que les dades facilitades a Correos per a la prestació dels serveis sol·licitats responen amb veracitat a la situació real i que comunicarà qualsevol modificació que les afecti. En conseqüència, el client respondrà davant de Correos i tercers de qualssevol danys i perjudicis ocasionats a conseqüència de l’incompliment de les obligacions assumides en aquesta clàusula.",
    tycPrivacyPoliticyTyCText35_1:
        "Todos aquellos datos que, en caso de haber prestado su consentimiento, Correos recaba de la tipología de fuentes " +
        'externas enumerada en la finalidad "H" del apartado ',
    tycPrivacyPoliticyTyCText35_B: "“Finalitat del tractament i legitimació”.",
    tycPrivacyPoliticyTyCText36:
        "A més del cas anterior, terceres entitats, com ara administracions públiques, l’empresa en què treballeu o altres institucions amb què Correos tingui subscrit un acord de col·laboració i/o prestació de serveis i que estiguin interessades a contractar aquest mètode com a sistema d’identificació, ens poden comunicar o donar accés a les vostres dades, a fi que puguem verificar la vostra identitat. Les entitats amb què Correos hagi subscrit acords de col·laboració i/o prestació de serveis poden, si escau, inhabilitar el sistema d’identificació o aplicar condicions diferents o addicionals d’acord amb el que recullen els acords de col·laboració i/o de prestació de serveis subscrits.",
    tycPrivacyPoliticyTyCText37:
        "Dins d’aquesta informació s’inclouen aspectes com: (i) l’historial de productes i serveis contractats amb Correos, el tipus de servei i les seves característiques (p. ex. serveis digitals de Correos en els quals esteu donat d’alta, freqüència d’ús d’aquests serveis, ús d’altres serveis de Correos com trameses postals, paqueteria, Citypaq...); (ii) reclamacions o sol·licituds d’informació, etc.; (iii) informació sobre la vostra navegació a través de les galetes que es poden instal·lar al vostre dispositiu, amb el vostre consentiment previ —en cas necessari—, de conformitat amb el que estableix la nostra Política de galetes.",
    tycPrivacyPoliticyTyCText38:
        "Les vostres dades personals es conservaran mentre siguin necessàries per a la prestació del servei de Correos ID, tret que en sol·liciteu la supressió. El procés d’eliminació d’identitat implica un bloqueig o restricció d’ús de la vostra identitat i dades durant un període de trenta (30) dies, després del qual es procedirà a la seva efectiva eliminació. Durant el període de restricció de l’ús de la identitat, podreu recuperar la vostra identitat seguint el procés de Correos ID per a això.",
    tycPrivacyPoliticyTyCText39:
        "Correos bloquejarà aquells usuaris que es trobin inactius —és a dir, que no accedeixin a cap dels serveis digitals de Correos o d’altres empreses que utilitzin Correos ID com a plataforma d’identificació— per un termini de cinc anys.",
    tycPrivacyPoliticyTyCText40:
        "Quan ja no sigui necessari el tractament de les vostres dades, aquestes se suprimiran d’acord amb el que disposa la normativa de protecció de dades, la qual cosa n’implica el bloqueig, i només estaran disponibles a sol·licitud de jutges i tribunals, el ministeri fiscal o les administracions públiques competents durant el termini de prescripció de les accions que poguessin derivar, i posteriorment seran eliminades. Generalment, s’esborraran una vegada transcorreguts tres anys.",
    tycPrivacyPoliticyTyCText41:
        "La nostra normativa de protecció de dades us confereix una sèrie de drets en relació amb el tractament de dades que impliquen els nostres serveis, que podem resumir en els següents:",
    tycPrivacyPoliticyTyCText42:
        "- Dret d’accés: conèixer quin tipus de dades estem tractant i les característiques del tractament que estem duent a terme.",
    tycPrivacyPoliticyTyCText43:
        "- Dret de rectificació: poder sol·licitar la modificació de les vostres dades perquè són inexactes o no veraces.",
    tycPrivacyPoliticyTyCText44:
        "- Dret de portabilitat: poder obtenir una còpia en un format interoperable de les dades que s’estiguin tractant.",
    tycPrivacyPoliticyTyCText45:
        "- Dret a la limitació del tractament en els casos recollits en la llei.",
    tycPrivacyPoliticyTyCText46:
        "- Dret de supressió: sol·licitar la supressió de les vostres dades quan el tractament ja no sigui necessari.",
    tycPrivacyPoliticyTyCText47:
        "- Dret d’oposició: aquest dret us permet, per exemple, sol·licitar el cessament en la tramesa de comunicacions comercials.",
    tycPrivacyPoliticyTyCText48: "- Dret a revocar el consentiment prestat.",
    tycPrivacyPoliticyTyCText49:
        "Podeu exercir els vostres drets a través d’algun dels canals següents:",
    tycPrivacyPoliticyTyCText50:
        "- Adreça postal: via Dublín, núm. 7 (Campo de las Naciones), 28070 Madrid (Espanya)",
    tycPrivacyPoliticyTyCText51: "- Adreça electrònica: ",
    tycPrivacyPoliticyTyCText52:
        "Així mateix, totes les comunicacions comercials electròniques inclouran un mecanisme de baixa o similar que us permetrà, si així ho desitgeu, no rebre noves comunicacions.",
    tycPrivacyPoliticyTyCText53:
        "A la pàgina web de l’Agència Espanyola de Protecció de Dades (AEPD) podeu trobar una sèrie de models que us ajudaran a exercir els vostres drets. Així mateix, us informem que teniu dret a interposar una reclamació davant de l’autoritat de control (a Espanya, l’AEPD) en cas que considereu infringits els vostres drets.",
    tycPrivacyPoliticyTyCText54:
        "Per a la prestació de determinats serveis pot ser necessari que ens faciliteu les dades personals i de contacte de terceres persones com, p. ex., el nom, cognoms, adreça postal, adreça electrònica, telèfon de contacte i altres dades o mitjans equivalents de contacte del destinatari d’una comunicació o tramesa.",
    tycPrivacyPoliticyTyCText55:
        "Addicionalment, per aconseguir la millor prestació del servei, el número de telèfon o l’adreça electrònica es poden utilitzar amb l’objectiu d’informar el destinatari sobre la prestació del servei i tractar qualsevol incidència que el pogués afectar.",
    tycPrivacyPoliticyTyCText56:
        "En aquest sentit, el client garanteix la veracitat i l’actualització de les dades del destinatari i, si escau, assegura haver complert totes les obligacions que, en matèria de protecció de dades, li siguin atribuïbles, de manera que ha obtingut el consentiment del destinatari perquè Correos dugui a terme el tractament que s’ha exposat als paràgrafs precedents, en cas que aquest consentiment sigui necessari.",
    tycPrivacyPoliticyTyCText57:
        "Per la seva part, en cas que Correos accedeixi a dades personals que resultin necessàries per a la prestació del servei de verificació d’identitat, sota la responsabilitat d’una empresa o professional. Correos es compromet a complir totes les obligacions inherents a la seva posició com a encarregat de tractament i, en aquest sentit:",
    tycPrivacyPoliticyTyCText58:
        "Accedirà i tractarà les dades a les quals tingui accés subjecte a les seves instruccions, i no les farà servir per a finalitats diferents de la prestació del servei. Per fer-ho, podrà disposar dels serveis d’altres tercers (subencarregats), si és necessari per a la prestació del servei i les operacions del contracte i/o per a la seva conservació i sempre que (i) el nou encarregat quedi subjecte a les mateixes condicions (instruccions, obligacions, mesures de seguretat...) i amb els mateixos requisits formals que aquest, pel que fa al tractament adequat de les dades personals i a la garantia dels drets de les persones afectades; (ii) Correos, amb sol·licitud expressa, posi a la seva disposició una llista en què s’identifiquin els serveis subencarregats i la identitat dels subencarregats; (iii) en cas d’incompliment per part del subencarregat, Correos continuï essent-ne plenament responsable.",
    tycPrivacyPoliticyTyCText59:
        "Implementarà les mesures de seguretat legals, tècniques i organitzatives apropiades per garantir un nivell de seguretat adequat al risc, conforme a allò que exposa l’art. 32 del RGPD i, a la seva sol·licitud, l’informarà de la metodologia d’anàlisi de risc utilitzada.",
    tycPrivacyPoliticyTyCText60:
        "Una vegada complerta la prestació contractual, destruirà o tornarà les dades tractades, i també qualssevol suports o documents en què constin dades objecte del tractament, sens perjudici de la possibilitat de conservar aquestes dades, degudament bloquejades, en els termes previstos per la nostra normativa de protecció de dades.",
    tycPrivacyPoliticyTyCText61:
        "Assegurarà el compliment de la resta d’obligacions recollides en la normativa de protecció de dades.",
};
