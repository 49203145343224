import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { CommonStyles } from "../../commons/CommonStyles";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import defaultLogin from "../../assets/images/defaultLogin.jpg"
import { checkIsNullUndefined } from "../../commons/Utils";
import { Constants } from "../../commons/Constants";
import { StylesBackgroundLogin } from "./Styles/StylesBackgroundLogin";
import { sessionValues } from "../../commons/EnumsGeneral";
import { getSessionItem } from "../../services/Commons";
import { EnumPages } from "../../commons/EnumPages";


export function PublicBackGroundPage(props) {
    const { personType, setPersonTypeSelected, openPage } = props;
    const pageImageMatch = { [EnumPages.Login]: sessionValues.imageLogin, [EnumPages.Registry]: sessionValues.imageRegistry, [EnumPages.ResetPassword]: sessionValues.imagePassword };
    const commonClasses = CommonStyles();
    const classes = StylesBackgroundLogin();
    const [appImage, setAppImage] = useState(null);

    useEffect(() => {
        let appImage = checkIsNullUndefined(openPage) ? sessionValues.imageLogin : pageImageMatch[openPage];
        setAppImage(getSessionItem(appImage));
    }, [openPage]);

    const checkIsLogin = () => {
        return checkIsNullUndefined(openPage) || openPage === EnumPages.Login;
    };

    return (
        <Grid container style={{ height: "100%" }}>
            <Header
                setShowSideMenu="false"
                removeAppNameLogout="true"
                showName={true}
                personType={personType}
                setPersonTypeSelected={setPersonTypeSelected}
                openPage={openPage}
            />
            <Grid container item xs={12} className={commonClasses.mainPublicDiv} >
                <Grid item xs={12} md={5} className={classes.leftImgCover}></Grid>
                <Grid className={classes.loginImageDiv}>
                    <img className={classes.leftImg} src={checkIsNullUndefined(appImage) ? defaultLogin : appImage} alt={""}></img>
                </Grid>
                <Grid item xs={12} md={7} className={classes.rightContainer} style={{ paddingTop: checkIsLogin() ? "5.5em" : Constants.MarginTop }}>
                    {props.children}
                </Grid>
            </Grid>
            <Footer />
        </Grid>
    );
}
