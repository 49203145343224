import React, { useEffect, useState } from "react";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import {
  Grid,
  Dialog,
  Container,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  IconButton,
  Typography,
} from "@material-ui/core";
import { CommonStyles } from "../../../commons/CommonStyles";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { EnterpriseTeamStyles } from "../EnterpriseTeamStyles";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { PostEnterpriseDataSendChangeEnterpriseHolderProposal } from "../../../services/EnterpriseDataCallsService";
import { checkIsNullUndefined, getErrorMessage } from "../../../commons/Utils";
import { useTranslation } from "react-i18next";
import { DialogPutPassword } from "./DialogPutPassword";

export function DialogChangeHolder(props) {
  const { usersList, finishOperation, idEnterprise, getInfo } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const classes = EnterpriseTeamStyles();
  const { t, i18n } = useTranslation();

  const [openChange, setOpenChange] = useState(false);
  const [openPutPass, setOpenPutPass] = useState(false);
  const [userSelected, setUserSelected] = useState("");
  const [usableUserList, setUsableUserList] = useState([]);
  const [confirmCircular, setConfirmCircular] = useState(false);

  useEffect(() => {
    if(!checkIsNullUndefined(usersList) && usersList.length > 0){
      let auxUserList = usersList.filter((user) => {return user.idRole !== 1});
      setUsableUserList(auxUserList); 
      setUserSelected(auxUserList.length === 0 ? "" : auxUserList[0].userOid);
    }
  }, [usersList]);

  const handleHolderChange = (event) => {
    setUserSelected(event.target.value);
  };

  const handleCancel = () => {
    setOpenChange(false);
    setOpenPutPass(false);
    setUserSelected("");
  };

  const handleConfirm = () => {
    setConfirmCircular(true);
    PostEnterpriseDataSendChangeEnterpriseHolderProposal(idEnterprise, userSelected).then(
      (response) => {
        if (response && !response.Status) {
          //Alerta
          getInfo();
          finishOperation("success", t("teamPageTitularChangeSuccess"));
          setConfirmCircular(false);
        } else {
          //Alerta
          finishOperation("error", getErrorMessage(response, t));
          setConfirmCircular(false);
        }
        setOpenChange(false);
      }
    );
  };

  const handleOpen = () => {
    setOpenPutPass(true);
  };

  const switchDialogs = () => {
    setOpenPutPass(false);
    setOpenChange(true);
  }

  return (
    <Grid>
      <Tooltip title={t("teamPageButtonChangeHolder")}>
        <IconButton
          aria-label="menu"
          className={classes.iconButton}
          onClick={() => handleOpen()}
          style={{ float: "right" }}
        >
          <AiOutlineUserSwitch className={commonClasses.iconActionStyle} />
        </IconButton>
      </Tooltip>
      <DialogPutPassword openPutPass={openPutPass} handleCancel={handleCancel} switchDialogs={switchDialogs} finishOperation={finishOperation}/>
      <Dialog
        fullWidth
        open={openChange}
        onClose={() => handleCancel()}
        PaperProps={{ classes: {root: dialogClasses.dialogWindow }}}
      >
        <Container className={commonClasses.dialogTitle}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={commonClasses.dialogTitleText}
              >
                {t("teamPageChangeHolder")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={commonClasses.dialogContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth color="secondary">
                <InputLabel>{t("teamPageSelectHolder")}</InputLabel>
                <Select
                  native
                  labelId="holderSelector-label"
                  id="holderSelector"
                  label={t("teamPageSelectHolder")}
                  value={userSelected}
                  onChange={handleHolderChange}
                >
                  {usableUserList.map((user) => {
                    return (
                      <option
                        key={user.userOid}
                        value={user.userOid}
                      >
                        {user.userEmail ? user.userEmail : user.userPhone}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              container
              xs={12}
              className={commonClasses.dialogButtonContainer}
            >
              <Grid item xs={6}>
                <CorreosButton
                  color="secondary"
                  className={commonClasses.dialogButtonLeft}
                  onClick={handleCancel}
                >
                  {t("cancel")}
                </CorreosButton>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  variant="contained"
                  color="primary"
                  className={commonClasses.dialogButtonRight}
                  onClick={handleConfirm}
                  circularProgress={confirmCircular}
                >
                  {t("change")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </Grid>
  );
}
