import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { CommonStyles } from "../../commons/CommonStyles";
import { UserCancelAccountStyles } from "./UserCancelAccountStyles";
import {
  PostUtilitiesCorreosIdCancelAccount,
} from "../../services/UtilitiesCallsService";
import {
  GetUtilitiesCorreosIdSubscriptionUserTycPrivacyUrls,
} from "../../services/UtilitiesCallsService";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { backToLogin, getSessionItem } from "../../services/Commons";
import { getErrorMessage } from "../../commons/Utils";
import { useTranslation } from "react-i18next";
import { Alerts } from "../Alerts/Alerts";
import { BasicDialog } from "../CommonComponents/BasicDialog";
import { sessionValues } from "../../commons/EnumsGeneral";

export function UserCancelAccount() {
  const commonClasses = CommonStyles();
  const classes = UserCancelAccountStyles();
  const { t, i18n } = useTranslation();

  const [isUserTitular, setIsUserTitular] = useState(false);
  const [circularProgress, setCircularProgress] = useState(true);
  const [
    circularProgressCancelingAccount,
    setCircularProgressCancelingAccount,
  ] = useState(false);
  const [userSubscriptions, setUserSubcriptions] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    setIsUserTitular(getSessionItem(sessionValues.isUserTitular) === "true");
    getAppsSubscribedByUser();
  }, []);

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  const getAppsSubscribedByUser = () => {
    GetUtilitiesCorreosIdSubscriptionUserTycPrivacyUrls().then((response) => {
      if (response && !response.Status) {
        setUserSubcriptions(response);
        setCircularProgress(false);
      } else {
        finishOperation("error", getErrorMessage(response, t));
        setCircularProgress(false);
      }
    });
  };

  const handleSend = () => {
    setOpenPopup(true);
  };

  const confirmCancelAccount = () => {
    setCircularProgressCancelingAccount(true);
    PostUtilitiesCorreosIdCancelAccount().then((response) => {
      if (response && !response.Status) {
        backToLogin();
      } else {
        finishOperation("error", getErrorMessage(response, t));
        setCircularProgressCancelingAccount(false);
      }
    });
  };

  return (
    <Grid>
      <Typography variant="h4" className={commonClasses.title}>
        {t("askUnsubscribeService")}
      </Typography>
      <Typography
        variant="subtitle2"
        className={commonClasses.title}
        style={{ textAlign: "center", marginBottom: "2em", color: "#666666", fontSize: "120%" }}
      >
        {t("accountCancelDisableAccountAsk")}
      </Typography>
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      <BasicDialog
        open={openPopup}
        setOpen={setOpenPopup}
        title={t("warning")}
        text={t("accountCancelDisableAccountLastWarn")}
        action={confirmCancelAccount}
      />
      <Container className={commonClasses.mainContainer}>
        {circularProgress ? (<CircularProgress />) : <Paper>
          <Container>
            <Grid container className={classes.cardContainer}>
              <Container style={{ marginTop: "1.5em" }}>

                <Typography
                  variant="h6"
                  className={commonClasses.title}
                  style={{ textAlign: "left", marginTop: "1em" }}
                >
                  {t("accountCancelDisableAccountTitle")}
                </Typography>

                <Typography
                  className={commonClasses.textDiv}
                  style={{ textAlign: "left" }}
                >
                  <ul>
                    {userSubscriptions.map((app) => (
                      <li>{app.application}</li>
                    ))}
                  </ul>
                  <Typography
                    variant="h6"
                    className={commonClasses.title}
                    style={{ textAlign: "left" }}
                  >
                    {t("accountCancelDisableAccountListTitle")}
                  </Typography>

                  <ul>
                    <li>{t("accountCancelDisableAccountList")}</li>
                    {isUserTitular ? (
                      <li>
                        {t("accountCancelDisableAccountListEnterprise")}
                      </li>
                    ) : null}
                  </ul>
                  <p>{t("accountCancelDisableAccountP1")}</p>
                </Typography>
              </Container>
              <Grid
                container
                item
                spacing={1}
                xs={12}
                className={commonClasses.dialogButtonContainer}
              >
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <CorreosButton
                    color="secondary"
                    onClick={() => {
                      handleSend();
                    }}
                    circularProgress={circularProgressCancelingAccount}
                  >
                    {t("accountCancelDisableAccountConfirm")}
                  </CorreosButton>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Paper>}
      </Container>
    </Grid>
  );
}
