import React, { useEffect, useState } from "react";
import { GetMasterCountry } from "../../services/MasterCallsService";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { checkIsNullUndefined } from "../../commons/Utils";
import { EnumCountries } from "../../commons/EnumCountries";
import { CommonStyles } from "../../commons/CommonStyles";


export function PrefixPhone(props) {
  const { handleSelectorChange, masterCountryList, disabled, country } = props;
  const commonClasses = CommonStyles();
  const { t, i18n } = useTranslation();

  const [countryList, setCountryList] = useState([]);
  const [countrySelected, setCountrySelected] = useState({ id: "" });

  useEffect(() => {
    if (
      checkIsNullUndefined(masterCountryList) ||
      masterCountryList.length == 0
    ) {
      GetMasterCountry().then((response) => {
        //comprobamos que no sea null o error
        if (response && !response.Status) {
          setCountryList(response);
          response.forEach((country) => {
            if (country.code === EnumCountries.ESP) {
              setCountrySelected(country);
              props.handleSelectorChange(country);
            }
          });
        }
      });
    } else {
      prepareComponent(masterCountryList);
    }
  }, []);

  const prepareComponent = (auxCountryList) => {
    setCountryList(auxCountryList);
    if (checkIsNullUndefined(country) || checkIsNullUndefined(country.id)) {
      auxCountryList.forEach((auxCountry) => {
        if (auxCountry.code === EnumCountries.ESP) {
          setCountrySelected(auxCountry);
          handleSelectorChange(auxCountry, true);
        }
      });
    } else {
      setCountrySelected(country);
      handleSelectorChange(country, true);
    }
  };

  //controla el cambio en el selector de prefijo
  const handlePrefixChange = (event) => {
    countryList.forEach((country) => {
      if (country.id == event.target.value) {
        setCountrySelected(country);
        handleSelectorChange(country);
      }
    });
  };

  return (
    <FormControl variant={"filled"} fullWidth className={commonClasses.inputsStyle}>
      <InputLabel htmlFor="phoneSelector" id="phoneSelector-label" color="secondary">
        {t("prefixSelector")}
      </InputLabel>
      <Select
        color="secondary"
        native
        labelId="phoneSelector-label"
        id="phoneSelector"
        label={t("prefixSelector")}
        value={countrySelected.id}
        disabled={disabled}
        onChange={handlePrefixChange}
      >
        {countryList.map((auxCountry) => {
          return (
            <option key={auxCountry.code} value={auxCountry.id}>
              {auxCountry.code + " (+" + auxCountry.phonePrefix + ")"}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
}
