export const LateralBarOptions = [{key: "user", value: "User"}, {key: "ent", value: "Enterprise"}];
export const LateralBarPages = {
  User: ["UserData", "UserVerifications", "UserPrivacy","UserServices"],
  UserBlocked: ["UserData", "UserVerifications"],
  Enterprise: [
    "EnterpriseData",
    "EnterpriseVerifications",
    "EnterprisePrivacy",    
    // "EnterpriseCreate",
    "EnterpriseServices",
    "EnterpriseTeam",
    "EnterpriseDelete"
  ],
  EnterpriseBlocked: [
    "EnterpriseData",
    // "EnterpriseDelete" Si se permite borrar hay que controlar los estados después
  ],
  Services: ["UserServices", "EnterpriseServices"],
};
