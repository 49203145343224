import React, { useEffect, useState } from "react";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import {
  Grid,
  Dialog,
  Container,
  TextField,
  FormControl,
  Select,
  InputLabel,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { checkIsNullUndefined, getErrorMessage } from "../../../commons/Utils";
import { CommonStyles } from "../../../commons/CommonStyles";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { EnterpriseTeamStyles } from "../EnterpriseTeamStyles";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { PostEnterpriseDataEnterpriseItems } from "../../../services/EnterpriseDataCallsService";
import { useTranslation } from "react-i18next";

export function DialogAddItem(props) {
  const { idEnterprise, finishOperation, objects, loadItems } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const classes = EnterpriseTeamStyles();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);

  const [option, setOption] = useState("");
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => { }, []);

  const loadSelect = () => {
    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel color="secondary">{t("teamPageItemType")}</InputLabel>
        <Select
          color="secondary"
          native
          required
          id="objects"
          label={t("teamPageItemType")}
          value={option}
          onChange={(value) => {
            handleOption(value);
          }}
          displayEmpty
        >
          {objects.map((element, i) => {
            return <option value={element.id}>{element.name}</option>;
          })}
        </Select>
      </FormControl>
    );
  };

  const handleClickOpen = () => {
    setOption(objects[0].id);
    setOpen(true);
  };

  const handleAddObject = () => {
    setLoading(true);
    PostEnterpriseDataEnterpriseItems(
      idEnterprise,
      parseInt(option, 10),
      value
    ).then((response) => {
      if (response && !response.Status) {
        //Alerta
        finishOperation("success", t("teamPageItemAddSuccess"));
        loadItems();
      } else {
        //Alerta
        finishOperation("error", getErrorMessage(response, t));
      }
      setValue(null);
      setLoading(false);
      setOpen(false);
    });
  };

  const handleOption = (value) => {
    setOption(value.target.value);
  };

  const handleChange = (event) => {
    let koItem = checkIsNullUndefined(event.target.value);
    setValue(event.target.value);
    setError(koItem);
  };

  const handleCancelAdd = () => {
    setOpen(false);
    setValue("");
    setOption(objects[0].id);
  };

  return (
    <Grid>
      <Tooltip title={t("add")}>
        <IconButton
          className={classes.iconButton}
          onClick={() => handleClickOpen()}
          style={{ float: "right" }}
        >
          <AiOutlineAppstoreAdd className={commonClasses.iconActionStyle} />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        open={open}
        onClose={() => handleCancelAdd()}
        PaperProps={{ classes: {root: dialogClasses.dialogWindow }}}
      >
        <Container className={commonClasses.dialogTitle}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={commonClasses.dialogTitleText}
              >
                {t("teamPageAddItem")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={commonClasses.dialogContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {loadSelect()}
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={value}
                color="secondary"
                variant="outlined"
                label={t("teamPageItemValue")}
                fullWidth
                id="titem"
                autoFocus
                required
                onChange={handleChange}
                error={error}
                helperText={error ? t("teamPageItemError") : ""}
                className={commonClasses.inputsStyle}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={commonClasses.dialogButtonContainer}
            >
              <Grid item xs={6}>
                <CorreosButton
                  color="secondary"
                  className={commonClasses.dialogButtonLeft}
                  onClick={handleCancelAdd}
                >
                  {t("cancel")}
                </CorreosButton>
              </Grid>
              <Grid item xs={6}>
                {/* {loading ? (
                  <CircularProgress
                    className={commonClasses.dialogButtonRight}
                  />
                ) : ( */}
                  <CorreosButton
                    variant="contained"
                    color="primary"
                    className={commonClasses.dialogButtonRight}
                    onClick={handleAddObject}
                    disabled={
                      checkIsNullUndefined(value) ||
                      checkIsNullUndefined(option) ||
                      error
                    }
                    circularProgress={loading}
                  >
                    {t("save")}
                  </CorreosButton>
                {/* )} */}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </Grid>
  );
}
