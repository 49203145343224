import { fetchMethod, secureValues } from "../commons/EnumsGeneral";
import { checkIsNullUndefined } from "../commons/Utils";
import { getApplicationOid, getReplaceApplicationOid, getSecureItem } from "./Commons";
import { CommonFetch, buildHeader } from "./ReasonService";

const fetchApi = {
    GetUtilitiesCorreosIdUserAccessControl: "UtilitiesCorreosId/UserAccessControl",
    GetUtilitiesCorreosIdConfigApplication: "UtilitiesCorreosId/ConfigApplication/{personType}",
    GetUtilitiesCorreosIdUserServices: "UtilitiesCorreosId/UserServices",
    GetUtilitiesCorreosIdEnterpriseServices: "UtilitiesCorreosId/EnterpriseServices",
    GetUtilitiesCorreosIdSubscriptionUserTycPrivacyUrls: "UtilitiesCorreosId/Subscription/User/TycPrivacyUrls",
    GetUtilitiesCorreosIdSubscriptionEnterpriseTycPrivacyUrls: "UtilitiesCorreosId/Subscription/Enterprise/TycPrivacyUrls",
    PostUtilitiesCorreosIdCancelAccount: "UtilitiesCorreosId/CancelAccount",
    GetUtilitiesCorreosIdExist: "UtilitiesCorreosId/Exist/{user}",
    GetUtilitiesCorreosIdRedirectApplication: "UtilitiesCorreosId/RedirectApplication",
    GetUtilitiesCorreosIdGetUrlRedirectOauth: "UtilitiesCorreosId/GetUrlRedirectOauth",
    GetUtilitiesCorreosIdApplicationOid: "UtilitiesCorreosId/ApplicationOid/{code}",
    PostUtilitiesCorreosIdVerifyRecaptcha: "UtilitiesCorreosId/VerifyRecaptcha",
    GetUtilitiesCorreosIdUserAndEnterprisesStatuses: "UtilitiesCorreosId/UserAndEnterprises/Application/Statuses",
    GetUtilitiesCorreosIdUserExtendedInfoByDataVerified: "UtilitiesCorreosId/User/ExtendedInfoByDataVerified",
    PostUtilitiesCorreosIdUnifyAccounts: "UtilitiesCorreosId/UnifyAccounts",
    PostUtilitiesCorreosGetTokenFromRedirectCode: "UtilitiesCorreosId/GetTokenFromRedirectCode",
    PostUtilitiesCorreosGetIdentifierFromRedirectCode: "UtilitiesCorreosId/GetIdentifierFromRedirectCode",
    GetUtilitiesCorreosIdGetEnterpriseRegistryFlowFlags: "UtilitiesCorreosId/GetEnterpriseRegistryFlowFlags",
    PostUtilitiesCorreosIdGetUserIdentifierByOauthCode: "UtilitiesCorreosId/GetUserIdentifierByOauthCode",
    PostUtilitiesCorreosIdLastSignInDate: "UtilitiesCorreosId/LastSignInDate",
    GetUtilitiesCorreosIdRedirectInvitation: "UtilitiesCorreosId/RedirectInvitation",
    GetUtilitiesCorreosIdEnterpriseInvitation: "UtilitiesCorreosId/Enterprise/Invitation/{idInvitation}",
    GetUtilitiesCorreosIdUserExpiredPass: "UtilitiesCorreosId/User/ExpiredPass/{applicationCode}",
    DeleteUtilitiesCorreosIdEnterprise: "UtilitiesCorreosId/Enterprise/{idEnterprise}",
};

export const GetUtilitiesCorreosIdUserAccessControl = async () => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.GetUtilitiesCorreosIdUserAccessControl;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const GetUtilitiesCorreosIdConfigApplication = async (personType, applicationOid) => {
    let body = "";
    let header = buildHeader("*/*", false, false, applicationOid);
    let apiUrl = fetchApi.GetUtilitiesCorreosIdConfigApplication.replace(
        "{personType}",
        personType
    );
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const GetUtilitiesCorreosIdUserServices = async () => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.GetUtilitiesCorreosIdUserServices;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const GetUtilitiesCorreosIdEnterpriseServices = async () => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.GetUtilitiesCorreosIdEnterpriseServices;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const GetUtilitiesCorreosIdSubscriptionUserTycPrivacyUrls = async () => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.GetUtilitiesCorreosIdSubscriptionUserTycPrivacyUrls;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const GetUtilitiesCorreosIdSubscriptionEnterpriseTycPrivacyUrls = async (
    idEnterprise
) => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl =
        fetchApi.GetUtilitiesCorreosIdSubscriptionEnterpriseTycPrivacyUrls +
        "?enterpriseId=" +
        idEnterprise;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const PostUtilitiesCorreosIdCancelAccount = async () => {
    let oidUser = getSecureItem(secureValues.userOid);
    let body = {
        userOid: oidUser,
    };
    let header = buildHeader("*/*", true, "application/json");
    let apiUrl = fetchApi.PostUtilitiesCorreosIdCancelAccount;
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return response;
};

export const GetUtilitiesCorreosIdExist = async (user) => {
    let body = "";
    let header = buildHeader("*/*", false, false);
    let apiUrl = fetchApi.GetUtilitiesCorreosIdExist.replace("{user}", user);
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const GetUtilitiesCorreosIdRedirectApplication = async (appOid) => {
    let body = "";
    let header = buildHeader("application/json", false, false, appOid);
    let apiUrl = fetchApi.GetUtilitiesCorreosIdRedirectApplication;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const GetUtilitiesCorreosIdGetUrlRedirectOauth = async (
    applicationOid
) => {
    let body = "";
    let header = buildHeader("*/*", false, false);
    let apiUrl =
        fetchApi.GetUtilitiesCorreosIdGetUrlRedirectOauth +
        "?applicationOid=" +
        applicationOid;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const GetUtilitiesCorreosIdApplicationOid = async (code, applicationOid) => {
    let body = "";
    let header = buildHeader("application/json", false, "application/json", applicationOid);
    let apiUrl = fetchApi.GetUtilitiesCorreosIdApplicationOid.replace(
        "{code}",
        code
    );
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return await response;
};

export const PostUtilitiesCorreosIdVerifyRecaptcha = async (token) => {
    let body = {
        token: token
    };
    let header = buildHeader("*/*", false, "application/json");
    let apiUrl = fetchApi.PostUtilitiesCorreosIdVerifyRecaptcha;
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return response;
};

export const GetUtilitiesCorreosIdUserAndEnterprisesStatuses = async () => {
    let body = "";
    let header = buildHeader("text/plain", true, false);
    let apiUrl = fetchApi.GetUtilitiesCorreosIdUserAndEnterprisesStatuses + "?applicationOidToCheck=" + getApplicationOid();
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return await response;
};

export const GetUtilitiesCorreosIdUserExtendedInfoByDataVerified = async (userIdentifier) => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    header.append("userIdentifier", userIdentifier);
    let apiUrl = fetchApi.GetUtilitiesCorreosIdUserExtendedInfoByDataVerified;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const PostUtilitiesCorreosIdUnifyAccounts = async (unifyBody) => {
    let body = unifyBody;
    let header = buildHeader("*/*", true, "application/json");
    let apiUrl = fetchApi.PostUtilitiesCorreosIdUnifyAccounts;
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return await response;
};

export const PostUtilitiesCorreosGetTokenFromRedirectCode = async (code) => {
    let body = { redirectCode: code };
    let header = buildHeader("*/*", false, "application/json");
    let apiUrl = fetchApi.PostUtilitiesCorreosGetTokenFromRedirectCode;
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return await response;
};

export const PostUtilitiesCorreosGetIdentifierFromRedirectCode = async (code) => {
    let body = { redirectCode: code };
    let header = buildHeader("application/json", true, "application/json");
    let apiUrl = fetchApi.PostUtilitiesCorreosGetIdentifierFromRedirectCode;
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return await response;
};

export const GetUtilitiesCorreosIdGetEnterpriseRegistryFlowFlags = async () => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.GetUtilitiesCorreosIdGetEnterpriseRegistryFlowFlags;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const PostUtilitiesCorreosIdGetUserIdentifierByOauthCode = async (code) => {
    let body = { oauthCode: code };
    let header = buildHeader("application/json", false, "application/json");
    let apiUrl = fetchApi.PostUtilitiesCorreosIdGetUserIdentifierByOauthCode;
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return await response;
};

export const PostUtilitiesCorreosIdLastSignInDate = async (oidList) => {
    let body = { oidList: oidList };
    let header = buildHeader("application/json", true, "application/json");
    let apiUrl = fetchApi.PostUtilitiesCorreosIdLastSignInDate;
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl);
    return await response;
};

export const GetUtilitiesCorreosIdRedirectInvitation = async () => {
    let body = "";
    let header = buildHeader("application/json", false, "application/json");
    let apiUrl = fetchApi.GetUtilitiesCorreosIdRedirectInvitation;
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return await response;
};

export const GetUtilitiesCorreosIdEnterpriseInvitation = async (idInvitation) => {
    let body = "";
    let header = buildHeader("application/json", false, "application/json");
    let apiUrl = fetchApi.GetUtilitiesCorreosIdEnterpriseInvitation.replace(
        "{idInvitation}",
        idInvitation
    );
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return await response;
};

export const GetUtilitiesCorreosIdUserExpiredPass = async (applicationCode, postAuthorizeTokenResult) => {
    let body = "";
    let header = buildHeader("*/*", false, false);
    header.append("Authorization", "Bearer " + postAuthorizeTokenResult.idToken);
    let apiUrl = fetchApi.GetUtilitiesCorreosIdUserExpiredPass.replace(
        "{applicationCode}", applicationCode);
    let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
    return response;
};

export const DeleteUtilitiesCorreosIdEnterprise = async (idEnterprise) => {
    let body = "";
    let header = buildHeader("*/*", true, false);
    let apiUrl = fetchApi.DeleteUtilitiesCorreosIdEnterprise.replace(
        "{idEnterprise}",
        idEnterprise
    );
    let response = await CommonFetch(header, body, fetchMethod.DELETE, apiUrl);
    return await response;
};