export const GLTYC2 = {

    //Cookies Politicy TyC Tittles

    tycCookiesPolicityTittle1: "Que son as cookies?",
    tycCookiesPolicityTittle2: "Que tipo de cookies existen?",
    tycCookiesPolicityTittle3: "Que cookies concretas utiliza este sitio web?",
    tycCookiesPolicityTittle4: "Como modifico a configuración das cookies?",

    //Cookies Politicy TyC Texts

    tycCookiesPolicityText1:
        'A Sociedad Estatal Correos y Telégrafos, S.A. (en diante, "Correos") infórmao como usuario acerca das cookies nesta páxina web. A presente Política de Cookies poderá ser obxecto de modificacións, polo que lle recomendamos consultala cada vez que acceda.',
    tycCookiesPolicityText2:
        "Unha cookie é un arquivo ou dispositivo que se descarga no ordenador/smartphone/tableta ao acceder a determinadas páxinas web para almacenar e recuperar información do equipo terminal. Entre outras funcións, permite almacenar e recuperar información sobre os seus hábitos de navegación co fin de mellorar o servizo ofrecido. Unha cookie é un pequeno ficheiro de texto que un sitio web coloca no seu PC, teléfono ou calquera outro dispositivo, con información sobre a súa navegación no devandito sitio. As cookies son necesarias para facilitar a navegación e facela máis amigable, e non danan o seu ordenador.",
    tycCookiesPolicityText3:
        "Existen distintos tipos de cookies que se poden clasificar segundo:",
    tycCookiesPolicityText4:
        "A entidade que xestiona o dominio dende onde se envían as cookies e tratan os datos",
    tycCookiesPolicityText5:
        "Cookies propias: envíanse ao seu equipo terminal dende os nosos equipos ou dominios propios.",
    tycCookiesPolicityText6:
        "Cookies de terceiros: envíanse ao seu equipo terminal dende un equipo ou dominio doutra entidade colaboradora.",
    tycCookiesPolicityText7:
        "O prazo de tempo que permanecen almacenadas no navegador",
    tycCookiesPolicityText8:
        "Cookies de sesión: actívanse mentres accede á páxina web ou para a prestación do servizo solicitado.",
    tycCookiesPolicityText9:
        "Cookies persistentes: almacénanse por un tempo determinado no seu equipo terminal. O responsable ten acceso cando se conecta á súa páxina web.",
    tycCookiesPolicityText10: "A finalidade do tratamento dos datos obtidos",
    tycCookiesPolicityText11:
        "Cookies técnicas: son necesarias para o uso do sitio web e para a prestación de servizos.",
    tycCookiesPolicityText12:
        "Cookies de personalización: acceder ao servizo con algunhas características de carácter xeral predefinidas en función dunha serie de criterios no seu terminal (idioma, tipo de navegador...).",
    tycCookiesPolicityText13:
        "Cookies de análise: permiten o seguimento e análise estatística do comportamento do conxunto dos usuarios dos sitios web aos que están vinculadas.",
    tycCookiesPolicityText14:
        "Cookies publicitarias: permiten a xestión dos espazos publicitarios na páxina web, aplicación ou plataforma dende a que se presta o servizo solicitado.",
    tycCookiesPolicityText15:
        "Cookies de publicidade comportamental: almacenan información do seu comportamento obtida a través dos hábitos de navegación, o que permite desenvolver un perfil específico para amosar publicidade.",
    tycCookiesPolicityText16:
        "A continuación identifícanse as cookies utilizadas no noso sitio web, así como a súa descrición, a duración de almacenamento e a súa titularidade.",
    tycCookiesPolicityText17:
        "Pode prestar o seu consentimento ao uso de cookies ou revogalo en calquera momento a través da ligazón ",
    tycCookiesPolicityText18_B: "Configuración de cookies.",
    tycCookiesPolicityText18:
        "Tamén pode facelo utilizando o seu navegador, polo que pode permitir, restrinxir, bloquear ou borrar as cookies utilizadas dende o noso Sitio Web.",
    tycCookiesPolicityText19:
        "A forma de facelo será diferente en función do tipo de navegador utilizado durante a navegación. Nas seguintes ligazóns ten á súa disposición toda a información para configurar ou inhabilitar as cookies en cada navegador.",
    tycCookiesPolicityText20:
        "Internet Explorer: microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=»ie-10″",
    tycCookiesPolicityText21: "Firefox: mozilla.org/es/kb/Borrar%20cookies",
    tycCookiesPolicityText22: "Chrome: google.com/chrome/answer/95647?hl=»es»",
    tycCookiesPolicityText23: "Safari: apple.com/es/privacy/use-of-cookies/",

    //Cookies Politicy TyC Tables Texts

    tycCookiesPolicityTableHead1: "Denominación",
    tycCookiesPolicityTableHead2: "Finalidade",
    tycCookiesPolicityTableHead3: "Duración",
    tycCookiesPolicityTableHead4: "Titular",

    //Table Tittles

    tycCookiesPolicityTableTittle1: "Cookies técnicas e de personalización",
    tycCookiesPolicityTableTittle2: "Cookies analíticas",
    tycCookiesPolicityTableTittle3: "Cookies de publicidade comportamental",

    //Table Text

    session: "Sesión",
    persistent: "Persistente",

    //Tale 1

    tycCookiesPolicityTable1_Text1:
        "Utilízanse para identificar e autenticar o usuario. Contén, ademais, datos técnicos da sesión de usuario como, por exemplo, tempo de espera de conexión, identificador de sesión etc.",
    tycCookiesPolicityTable1_Text2:
        "Identifica a sesión http do usuario. É común en todas as aplicacións web para identificar peticións dun usuario nunha sesión.",
    tycCookiesPolicityTable1_Text3:
        "Permite identificar en que estado de navegación se encontra o usuario (inicio da sesión, primeira páxina, primeiro acceso, estado dun scroll, estado dunha votación etc.).",
    tycCookiesPolicityTable1_Text4:
        "Almacenan os valores de sesión seleccionados polo usuario tales como: a tenda, o idioma, a moeda, os produtos, o talle etc.",

    //Table 2 and 3

    tycCookiesPolicityDoesntExist: "Non existen en Correos ID",

    // Cookie Banner

    cookieBannerAcceptAll: "Aceptar",
    cookieBannerConfigure: "Configurar",
    cookieBannerWeUseCookies1: "Utilizamos cookies para personalizar el contenido, adaptar los anuncios, medir su eficacia y ofrecer una experiencia más segura. Al navegar por el sitio web, aceptas el uso de cookies para recopilar información dentro y fuera de Correos.",
    cookieBannerWeUseCookies2: "Lee nuestra ",
    cookieBannerWeUseCookies3: "Política de Cookies",
    cookieBannerWeUseCookies4: " para obtener más información o accede a las preferencias de cookies para gestionar tu configuración",
    cookieBannerLearnMore: "Puedes obtener más información en nuestra",
    cookieDialogDescriptionTPE: "Permiten la navegación a través de nuestra web y ejecutar la acción solicitada por el usuario. Por ejemplo, permitir la reproducción de contenido multimedia, registrar sus pedidos en el carrito de compra, reconocer inicios de sesión y la selección de idioma en función del lugar de acceso, etc.",
    cookieDialogDescriptionANA: "Ofrecen distintas métricas de uso de la web que nos permiten mejorar la navegación del usuario, conocer qué apartados de la web resultan más atractivos, desde dónde acceden los usuarios, etc. Se trata información de carácter estadístico y que es proporcionada a nivel agregado, salvo por el registro de la dirección IP desde la que accede.",
    cookieDialogDescriptionPCO: "Son aquéllas que almacenan información del comportamiento de los usuarios obtenida a través de los hábitos de navegación del usuario, lo que permite desarrollar un perfil específico para mostrar publicidad.",
    cookieDialogMandatoryCookie: "(Cookies estrictamente necesarias)",
    cookieDialogMandatory: "Obligatorio",
    cookieDialogAllow: "Permitir",
    cookieDialogDisallow: "No permitir",
    cookieDialogSave: "Guardar Preferencias",
    cookieDialogAllowAll: "Permitir Todo",
    cookieDialogDisallowAll: "No permitir nada",
    cookieDialogCookiesPolicyText: "Puedes obtener más información en nuestra",
};
