import React, { useEffect } from "react";
import {
  Typography,
  Container,
  Dialog,
  Grid,
  Button,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CommonStyles } from "../../../commons/CommonStyles";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { useTranslation } from "react-i18next";

export function DialogFavouriteWarning(props) {
  const { openPopUp, setOpenPopup, doAction } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const { t, i18n } = useTranslation();

  useEffect(() => {}, []);

  const handleCancel = () => {
    setOpenPopup(false);
  };

  const handleConfirm = () => {
    doAction();
    handleCancel();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openPopUp}
      onClose={() => handleCancel()}
      PaperProps={{ classes: {root: dialogClasses.dialogWindow}}}
    >
      <Container className={commonClasses.dialogTitle}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              className={commonClasses.dialogTitleText}
            >
              {t("warning")}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container className={commonClasses.dialogContainer}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography className={commonClasses.dialogContentText}>
              {t("userDataFavouriteDirectionWarning")}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            className={commonClasses.dialogButtonContainer}
          >
            <Grid item xs={6}>
              <Button
                size="small"
                onClick={() => handleCancel()}
                className={commonClasses.dialogButtonLeft}
              >
                {t("cancel")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => handleConfirm()}
                className={commonClasses.dialogButtonRight}
              >
                {t("accept")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}
