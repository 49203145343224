import { makeStyles } from "@material-ui/core/styles";
import { Constants } from "../../../commons/Constants";


export const DialogCookiesStyles = makeStyles((theme) => ({
  containerMargin:{
    margin:"4em"
  }, 
  cookiesTitleText: { 
    color: "#333333",
    marginTop:"auto",
    marginBottom:"auto",
    marginLeft:"1em",
    fontSize:"150%"
  },  
  imgIcon: {
    marginTop:"auto",
    marginBottom:"auto",
    height:"1.5em",
    width: "auto",
    overflow:"auto",
    color: "#002453",   
    borderRadius: "50%",
    backgroundColor: "#f3f2f2",
    border: "7px solid #f3f2f2"
  },
  divider:{   
    width:"100%",
    height:"0.15em",
    backgroundColor: "#FDCD00", 
    marginTop:"1em",
    marginBottom:"2em"
  },
  buttonLabel:{
    textAlign:"right",
    color: "#666666",
    fontSize:"80%",
    marginTop:"auto",
    marginBottom:"auto"
  },
  switchButton: {
    width: "3em",
  },
  switchButtonNoActive: {
    width: "3em",
    color: "white",
  },
  agreeConditionsTermsConsent: {
    marginTop:"auto",
    marginBottom:"auto",
    fontSize: "110%",
    textAlign: "left",
    color: "#666666",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em !important",
    },
  },
  cookieName:{
    color: "#333333",
  },
  cookieDescription:{
    fontSize:"80%",
    [theme.breakpoints.down("md")]: {
      paddingRight:"2em"
     },
    
  },
  dialogLockIcon:{
    marginTop:"1em"
  },
  policyCookiesUrl:{
    color:"#002453",
    textDecoration:"underline"
  },
  buttonContainer:{
   
    [theme.breakpoints.down("sm")]: {
      alignItems:"center",
      justifyContent:"center",
      display:"flex"
    },
  },
  dialogButton:{
    float: "right",    
    fontSize:"80%",
    [theme.breakpoints.down("md")]: {
    
     marginBottom:"0.25em",
      marginTop:"0.25em"
    },
  },
  dialogButtonDisallow:{
    float: "right", 
    fontSize:"80%",
    color:"white",
    [theme.breakpoints.down("md")]: {
      width:"100%",
      marginBottom:"0.25em",
      marginTop:"0.25em"
    },
  },
  cookiesActions: {    
    display:"flex", 
    marginTop:"1em",
    marginBottom:"2em",
    [theme.breakpoints.down("md")]: {      
      paddingBottom:"1em"
    },
    
   },
   urlText:{
    textAlign: 'left', 
    alignSelf: 'flex-end',   
    [theme.breakpoints.down("md")]: {      
      paddingBottom:"1em"
    },
   }

  
}));
