import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Container,
} from "@material-ui/core";
import { CommonStyles } from "../../commons/CommonStyles";
import {
  GetMasterPrivacyApplication,
  GetMasterTycApplication,
} from "../../services/MasterCallsService";
import { checkIsNullUndefined, getErrorMessage } from "../../commons/Utils";
import { useTranslation } from "react-i18next";
import { getApplicationOid, getSessionItem, setSessionItem } from "../../services/Commons";
import { isResultOk, showAlert } from "../../commons/FormManager";
import { GetLegalEnterprisePendingTyc, GetLegalUserPendingTycCid } from "../../services/LegalCallsService";
import { GetApplicationAuxPrivacyAndTycApplication } from "../../services/ApplicationAuxCallsService";
import { sessionValues } from "../../commons/EnumsGeneral";
import { Constants } from "../../commons/Constants";

export function AgreeConditionsLabel(props) {
  const { finishOperation, setOk, reset, personType, applicationOid, separatedChecks, setIsFinished, isLogin, setTycsObjects, registerPage, enterpriseId, personTypeSelected } = props;
  const commonClasses = CommonStyles();
  const { t, i18n } = useTranslation();

  const [masterPrivacy, setMasterPrivacy] = useState([]);
  const [masterTyc, setMasterTyc] = useState([]);

  useEffect(() => {
    let actualAppOid = checkIsNullUndefined(applicationOid) ? getApplicationOid() : applicationOid;
    let auxIsCorreosID = actualAppOid === process?.env?.REACT_APP_APP_OID;
    if (registerPage) {
      getRegistryPrivacyAndTyc();
    } else if (isLogin && ((!checkIsNullUndefined(personTypeSelected) && personTypeSelected == 2) || !checkIsNullUndefined(enterpriseId))) {
      getPendings();
    } else if (checkIsNullUndefined(setTycsObjects) || (personType != 1 && checkIsNullUndefined(enterpriseId))) {
      getMasters(auxIsCorreosID, actualAppOid);
    } else {
      getPendings();
    };
  }, [personType]);

  useEffect(() => {
    checkAllChecked();
  }, [masterPrivacy, masterTyc]);

  useEffect(() => {
    if (reset) {
      let auxPriList = JSON.parse(JSON.stringify(masterPrivacy));
      let auxTycList = JSON.parse(JSON.stringify(masterTyc));
      unCheck(auxPriList);
      unCheck(auxTycList);
      setMasterPrivacy(auxPriList);
      setMasterTyc(auxTycList);
    };
  }, [reset]);

  const unCheck = (auxList) => {
    auxList.forEach((obj) => {
      obj.check = false;
    });
  };

  const getRegistryPrivacyAndTyc = () => {
    let auxCidPrivAndTyc = getSessionItem(sessionValues.cidPrivacyAndTyc);
    if (checkIsNullUndefined(auxCidPrivAndTyc)) {
      GetApplicationAuxPrivacyAndTycApplication(Constants.cidCode).then((response) => {
        if (response && !response.Status) {
          prepareRegistryCidPrivAndTyc(response);
          setSessionItem(sessionValues.cidPrivacyAndTyc, JSON.stringify(response));
        } else {
          finishOperation("error", getErrorMessage(response, t));
        };
      });
    } else {
      let cidPriAndTyc = JSON.parse(auxCidPrivAndTyc);
      prepareRegistryCidPrivAndTyc(cidPriAndTyc);
    };
  };

  const prepareRegistryCidPrivAndTyc = (cidPriAndTyc) => {
    let appPrivacyList = cidPriAndTyc.privacy;
    let appTycList = cidPriAndTyc.tyc;
    setPrivacyAndTycValues(appPrivacyList, appTycList);
    if (!checkIsNullUndefined(setIsFinished)) {
      setIsFinished(true);
    };
  };

  const getPendings = () => {
    let auxCall = ((personType === 1 || isLogin) && checkIsNullUndefined(enterpriseId)) ? GetLegalUserPendingTycCid : GetLegalEnterprisePendingTyc;
    auxCall().then((response) => {
      if (response && !response.Status) {
        let appPrivacyList = response.filter((priv) => { return !priv.isTyc });
        let appTycList = response.filter((priv) => { return priv.isTyc });
        setTycsObjects(response);
        setPrivacyAndTycValues(appPrivacyList, appTycList);
        if (!checkIsNullUndefined(setIsFinished)) {
          setIsFinished(true);
        };
      } else {
        finishOperation("error", getErrorMessage(response, t));
      };
    });
  };

  const getMasters = (auxIsCorreosID, actualAppOid) => {
    let auxMasterPrivacy = GetMasterPrivacyApplication(actualAppOid);
    let ayxMasterTyc = GetMasterTycApplication(actualAppOid);
    Promise.all([auxMasterPrivacy, ayxMasterTyc]).then((results) => {
      let auxMasterPrivacyResult = results[0];
      let ayxMasterTycResult = results[1];
      if (isResultOk([auxMasterPrivacyResult, ayxMasterTycResult])) {
        let appPrivacyList = auxIsCorreosID ? checkIsArray(auxMasterPrivacyResult) : checkIsArray(auxMasterPrivacyResult).filter(
          (priv) => priv.appName != "Correos ID"
        );
        let appTycList = checkIsArray(ayxMasterTycResult);
        setPrivacyAndTycValues(appPrivacyList, appTycList);
        if (!checkIsNullUndefined(setIsFinished)) {
          setIsFinished(true);
        };
      } else {
        showAlert([auxMasterPrivacyResult, ayxMasterTycResult], finishOperation, t);
      };
    }).catch((error) => {
      finishOperation("error", getErrorMessage(error, t));
    });
  };

  const setPrivacyAndTycValues = (appPrivacyList, appTycList) => {
    setCheckAndInfo(appPrivacyList);
    setMasterPrivacy(filterPersonType(appPrivacyList));
    setCheckAndInfo(appTycList);
    setMasterTyc(filterPersonType(appTycList));
  };

  const filterPersonType = (auxList) => {
    let auxilList = auxList;
    let particularList = auxList.filter((obj) => obj.personType == 1);
    let enterpriseList = auxList.filter((obj) => obj.personType == 2);
    if (particularList.length !== 0 && enterpriseList.length !== 0) {
      if (checkIsNullUndefined(personType) || personType == 1) {
        auxilList = particularList;
      } else {
        auxilList = enterpriseList;
      }
    }
    return auxilList;
  }

  const checkIsArray = (response) => {
    let auxList = [];
    if (!Array.isArray(response)) {
      auxList.push(response);
    } else {
      auxList = response;
    }
    return auxList;
  };

  const setCheckAndInfo = (auxList) => {
    auxList.forEach((obj) => {
      obj.check = false;
    });
  };

  const mountControlLabel = (objectToAccept, index, listToChange) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={objectToAccept.check}
            onChange={() => handleCheck(index, listToChange)}
            name="checkedA"
          />
        }
        label={mountCheckBoxLabel(objectToAccept)}
        style={separatedChecks ? { marginBottom: "0em", margin: "auto" } : { marginBottom: "0em" }}
        classes={{ label: separatedChecks ? commonClasses.agreeConditionsCid : commonClasses.agreeConditions }}
        key={"TyCs" + index}
      />
    );
  };

  const mountCheckBoxLabel = (objectToAccept) => {
    return (
      <Grid item xs={12}>
        <Typography style={{ fontSize: "1em" }}>
          <span>{t("registerPageAgreeConditionsTerms1")}</span>
          <a
            className={commonClasses.linkDecoration}
            href={objectToAccept.url}
            target="_blank"
            rel={"noopener noreferrer"}
          >
            {objectToAccept.name}
          </a>
          <span>
            {t("registerPageAgreeConditionsTerms3").replace(
              "{appName}",
              objectToAccept.appName
            )}
          </span>
        </Typography>
      </Grid>
    );
  };

  const handleCheck = (index, listToChange) => {
    switch (listToChange) {
      case "masterPrivacy":
        let auxPriList = JSON.parse(JSON.stringify(masterPrivacy));
        auxPriList[index].check = !auxPriList[index].check;
        setMasterPrivacy(auxPriList);
        break;
      case "masterTyc":
        let auxTycList = JSON.parse(JSON.stringify(masterTyc));
        auxTycList[index].check = !auxTycList[index].check;
        setMasterTyc(auxTycList);
        break;
      default:
        break;
    }
  };

  const checkAllChecked = () => {
    let allOk = true;
    let hasChecks = (masterPrivacy.length != 0 && masterTyc.length != 0);
    if (allOk && hasChecks) {
      masterPrivacy.forEach((obj) => {
        if (!obj.check) {
          allOk = false;
        }
      });
    }
    if (allOk && hasChecks) {
      masterTyc.forEach((obj) => {
        if (!obj.check) {
          allOk = false;
        }
      });
    }
    setOk(allOk);
  };

  return (
    <FormControl component="fieldset" style={{ width: "100%" }}>
      <FormGroup>
        <Container style={separatedChecks ? { paddingLeft: 0, paddingRight: 0 } : {}}>
          {separatedChecks ?
            <Grid container item xs={12} style={{ paddingRight: "4px" }}>
              <Grid container item xs={12} md={6}>
                {masterPrivacy.map((objectToAccept, index) => {
                  return mountControlLabel(objectToAccept, index, "masterPrivacy");
                })}
              </Grid>
              <Grid container item xs={12} md={6}>
                {masterTyc.map((objectToAccept, index) => {
                  return mountControlLabel(objectToAccept, index, "masterTyc");
                })}
              </Grid>
            </Grid>
            :
            <Grid container item xs={12}>
              {masterPrivacy.map((objectToAccept, index) => {
                return mountControlLabel(objectToAccept, index, "masterPrivacy");
              })}
              {masterTyc.map((objectToAccept, index) => {
                return mountControlLabel(objectToAccept, index, "masterTyc");
              })}
            </Grid>}
        </Container>
      </FormGroup>
    </FormControl>
  );
}
