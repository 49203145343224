import { makeStyles } from '@material-ui/core/styles';

export const StylesCorreosButton = makeStyles((theme) => ({
    correosButton: {
        borderRadius: "8px 8px 8px 8px",
        border: "1px solid #002453",
    },
    correosButtonCentered:{
        margin: "auto",
    }
}));