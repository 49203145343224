import { makeStyles } from "@material-ui/core/styles";

export const EnterpriseDeleteStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingBottom: "1em",
  },
  cardFile: {
    alignItems: "center",
  },
  agreeButtonsLeft: {
    margin: "2px",
    border: "2px solid #FFCD00",
    "&:hover": {
      border: "2px solid #FFCD00",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "75%",
    },
  },
  title: {
    margin: "1em",
    color: "#002453",
  },

  titleColumn: {
    marginBottom: "0.2em",
    color: "#002453",
  },
  permissionsText: {
    color: "#666666",
    textAlign: "center",
    marginBottom: "1em",
  },
  privacyText: {
    color: "#666666",
    marginBottom: "1em",
  },
  signedText: {
    fontSize: "90%",
    textAlign: "left",
    color: "#666666",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em !important",
    }
  },
  agreeConditionsTermsConsent: {
    fontSize: "90%",
    textAlign: "left",
    color: "#666666",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em !important",
    },
  },
  switchButton: {
    width: "3em",
  },
  switchButtonNoActive: {
    width: "3em",
    color: "white",
  },
  divider:{
    marginBottom:"1.25em",
    width: "100%"
  }
}));
