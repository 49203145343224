import React from "react";
import { HeaderTyC } from "./components/HeaderTyC";
import { SideMenuTyC } from "./components/SideMenuTyC";
import { EnumTyC } from "./components/EnumTyC";
import { WarninLegalTyC } from "./components/WarninLegalTyC";
import { PrivacyPolicyTyC } from "./components/PrivacyPolicyTyC";
import { CookiesPolicityTyC } from "./components/CookiesPolicityTyC";
import { useParams }  from "react-router-dom";

export function TyCPage() {
  const [idPage, setIdPage] = React.useState();
  const [showSideMenu, setShowSideMenu] = React.useState(false);
  const { codePage } = useParams();

  const changeId = (newId) => {
    setIdPage(newId);
  };
  const handleShowSideMenu = () => {
    const flag = showSideMenu;
    setShowSideMenu(!flag);
  };
  //Aqui poner el renderpage con el switch case
  const renderPage = (id) => {
    if (id === undefined){
      id = codePage;
    }

    switch (id) {
      case EnumTyC.WarninLegal:
        return <WarninLegalTyC />;
      case EnumTyC.PrivacyPolicy:
        return <PrivacyPolicyTyC />;
      case EnumTyC.Cookies:
        return <CookiesPolicityTyC changeId={changeId}/>;
      default:
        return (
          <div>
            {changeId()}
            <WarninLegalTyC />
          </div>
        );
    }
  };

  return (
    <div>
      <HeaderTyC setShowSideMenu={handleShowSideMenu} />
      <SideMenuTyC
        changeId={changeId}
        idPage={idPage}
        showSideMenu={showSideMenu}
        setShowSideMenu={handleShowSideMenu}
      />
      {renderPage(idPage)}
    </div>
  );
}
