import React from "react";
import PropTypes from "prop-types";
import {
    Tooltip,
    Box
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { RegisterDataStyles, BorderLinearProgressStyles } from "./ProfileBarStyles";

const BorderLinearProgress = BorderLinearProgressStyles;

function LinearProgressWithLabel(props) {
    const getColor = () => {
        let color = "#002453";
        if(props.value === 25){
            color = "#C3E4EB";
        }else if(props.value === 50){
            color = "#F32735";
        }else if(props.value === 75){
            color = "#1ECAD3";
        }
        return color;
    }
    let color = getColor();
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                {props.tooltip
                    ?
                    <Tooltip title={props.tooltip} aria-label="ProfileBarProgress" placement="bottom-end">
                        <BorderLinearProgress variant="determinate" {...props} />
                    </Tooltip>
                    :
                    <BorderLinearProgress
                    variant="determinate"
                    style = {{backgroundColor: color}}
                    {...props} />
                }
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired
};

export function ProfileBar(props) {
    const classes = RegisterDataStyles();
    const { progress, tooltip } = props;

    return (
        <div className={classes.root} {...props}>
            <LinearProgressWithLabel value={progress} tooltip = {tooltip}/>
        </div>
    );
}
