import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Dialog,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import {
  PostVerificationSendEmail,
  PostVerificationSendPhone,
} from "../../services/ReasonService";
import {
  PutApplicationResetPassword,
} from "../../services/ApplicationCallsService";
import {
  GetMasterCountry,
} from "../../services/MasterCallsService";
import ico_pass_on from "../../assets/images/ico_pass_on.svg";
import ico_pass_off from "../../assets/images/ico_pass_off.svg";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { CommonStyles } from "../../commons/CommonStyles";
import { DialogStyles } from "../CommonComponents/Dialogs/DialogStyles";
import { UserVerificationsStyles } from "../UserVerifications/UserVerificationsStyles";
import { checkIsNullUndefined, getErrorMessage, getScreenWidth } from "../../commons/Utils";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

export function DialogResetPassword(props) {
  const { verifiedEmails, verifiedPhones, open, setOpen, setAlert } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const classes = UserVerificationsStyles();
  const { t, i18n } = useTranslation();

  const [emailsVerified, setEmailsVerified] = useState([]);
  const [phonesVerified, setPhonesVerified] = useState([]);
  const [otp, setOtp] = useState();
  const [otpError, setOtpError] = useState(false);
  const [otpHelper, setOtpHelper] = useState();
  const [sendedEmail, setSendedEmail] = useState(false);
  const [sendedPhone, setSendedPhone] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedPhone, setSelectedPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [checkPassword, setCheckPassword] = useState("");
  const [checkPasswordError, setCheckPasswordError] = useState(false);
  const [showCheckPassword, setShowCheckPassword] = useState(false);
  const [checkAllOk, setCheckAllOk] = useState(false);
  const [allOk, setAllOk] = useState(false);
  const [name, setName] = useState();
  const [reCaptchaToken, setReCaptchaToken] = useState(null);
  const [reCaptchaError, setReCaptchaError] = useState("");
  const [reCaptchaRef, setReCaptchaRef] = useState(null);

  useEffect(() => {
    if (verifiedEmails !== emailsVerified) {
      setEmailsVerified(verifiedEmails);
      if (verifiedEmails.length > 0) {
        setSelectedEmail(verifiedEmails[0].email);
      }
    }
    if (verifiedPhones !== phonesVerified) {
      setPhonesVerified(verifiedPhones);
      if (verifiedPhones.length > 0) {
        setSelectedPhone(verifiedPhones[0].phone);
      }
    }
  }, [verifiedEmails, verifiedPhones]);

  useEffect(() => {
    if (checkAllOk) {
      handleCheckAllOk();
    }
  }, [checkAllOk]);

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  const handleCancel = () => {
    setOpen(false);
    setOtp(null);
    setOtpError(false);
    setOtpHelper("");
    setSendedEmail(false);
    setSendedPhone(false);
    setConfirmed(false);
    setPassword("");
    setPasswordError(false);
    setCheckPassword("");
    setCheckPasswordError(false);
    setAllOk(false);
    setReCaptchaToken(null);
    reCaptchaRef?.reset();
  };

  const handleSend = (type) => {
    switch (type) {
      case "email":
        PostVerificationSendEmail(selectedEmail).then((response) => {
          if (response & !response.Status) {
            setSendedEmail(true);
            setName(selectedEmail);
          } else {
            finishOperation("error", getErrorMessage(response, t));
          }
        });
        break;
      case "phone":
          handleSendPhone();
        break;
      default:
        break;
    }
  };

  const handleSendPhone = () => {
    let phone = phonesVerified.filter(
      (phone) => phone.phone === selectedPhone
    );
    GetMasterCountry(phone[0].idCountry).then((pais) => {
      if (pais && !pais.Status) {
        let completePhone = pais[0].phonePrefix + "-" + selectedPhone;
        PostVerificationSendPhone(completePhone, reCaptchaToken).then((response) => {
          if (response && !response.Status) {
            setSendedPhone(true);
            setName(completePhone);
          } else {
            finishOperation("error", getErrorMessage(response, t));
          };
          reCaptchaRef?.reset();
          setReCaptchaToken(null);
        });
      } else {
        finishOperation("error", getErrorMessage(pais, t));
      }
    });
  }

  const handleChange = (type, value) => {
    switch (type) {
      case "email":
        setSelectedEmail(value.target.value);
        break;
      case "phone":
        setSelectedPhone(value.target.value);
        break;
      default:
        break;
    }
  };

  const handleResetPassword = () => {
    PutApplicationResetPassword(name, password, checkPassword, otp).then(
      (response) => {
        if (response && !response.Status) {
          finishOperation("success", t("resetPasswordChanged"));
          handleCancel();
        } else {
          finishOperation("error", getErrorMessage(response, t));
        }
      }
    );
  };

  const handleChangeOtp = (otp) => {
    setOtp(otp);
    if (!checkIsNullUndefined(otp)) {
      setOtpError(false);
      setOtpHelper("");
    } else {
      setOtpError(true);
      setOtpHelper(t("required"));
    }
    setCheckAllOk(true);
  };

  const handleChangePassword = (value) => {
    const hasNumber = new RegExp("[0-9]");
    const hasUpperChar = new RegExp("[A-Z]");
    const hasLowerChar = new RegExp("[a-z]");
    const hasNumberCharacters = new RegExp(".{8,16}");
    const hasSimbols = new RegExp("[@#$%^&*_+=[{}|\\:',?/`~\"();.-]");
    const hasSimbol = !checkIsNullUndefined(value.target.value)
      ? value.target.value.indexOf("]")
      : -1;
    let okPass = hasNumberCharacters.test(value.target.value);
    let okCheckPass = true;
    if (okPass) {
      let flag = 0;
      if (hasNumber.test(value.target.value)) flag++;
      if (hasUpperChar.test(value.target.value)) flag++;
      if (hasLowerChar.test(value.target.value)) flag++;
      if (hasSimbols.test(value.target.value) || hasSimbol >= 0) flag++;
      if (flag < 3) okPass = false;
    }
    if (value.target.value === "") okPass = true;

    if (checkPassword !== "" && checkPassword === value.target.value) {
      okCheckPass = false;
    }
    setPassword(value.target.value);
    setPasswordError(!okPass);
    if (checkPassword !== "") {
      setCheckPasswordError(okCheckPass);
      setCheckAllOk(true);
    } else {
      setCheckAllOk(true);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeCheckPassword = (value) => {
    let flag = false;
    if (password !== "" && password !== value.target.value) flag = true;
    setCheckPassword(value.target.value);
    setCheckPasswordError(flag);
    setCheckAllOk(true);
  };

  const handleClickShowCheckPassword = () => {
    setShowCheckPassword(!showCheckPassword);
  };

  const handleMouseDownCheckPassword = (event) => {
    event.preventDefault();
  };

  const handleCheckAllOk = () => {
    if (
      checkIsNullUndefined(otp) ||
      checkIsNullUndefined(password) ||
      checkIsNullUndefined(checkPassword)
    ) {
      setAllOk(false);
    } else if (otpError || passwordError || checkPasswordError) {
      setAllOk(false);
    } else {
      setAllOk(true);
    }
    setCheckAllOk(false);
  };

  const handleOnChangeReCaptcha = (value) => {
    let auxRecaptchaToken = checkIsNullUndefined(value) ? null : value;
    if (!checkIsNullUndefined(value)) {
      setReCaptchaError("");
    };
    setReCaptchaToken(auxRecaptchaToken);
  };

  const handleGoBack = () => {
    setConfirmed(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleCancel()}
      PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
    >
      <Container className={commonClasses.dialogTitle}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" className={commonClasses.dialogTitleText}>
              {!confirmed ? t("resetPasswordSend") : t("resetPasswordChange")}
            </Typography>
          </Grid>
        </Grid>
      </Container>

      {confirmed ? (
        <Container className={commonClasses.dialogContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                color="secondary"
                value={otp}
                onChange={(e) => handleChangeOtp(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
                name="Otp"
                autoFocus
                label={t("registerPageOtp")}
                type="text"
                id="Otp"
                error={otpError}
                helperText={otpHelper}
                required
                className={commonClasses.inputsStyle}
              />
            </Grid>
            <Grid style={{ margin: "1.5em" }} />
            <Grid item xs={12}>
              <TextField
                color="secondary"
                value={password}
                onInput={handleChangePassword}
                variant="outlined"
                fullWidth
                name="password"
                label={t("registerPagePassword")}
                type={showPassword ? "text" : "password"}
                id="password"
                error={passwordError}
                required
                InputProps={{
                  className: classes.underline,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <img src={ico_pass_on} alt="pass_on" />
                        ) : (
                          <img src={ico_pass_off} alt="pass_off" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                className={commonClasses.inputsStyle}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="secondary"
                value={checkPassword}
                onInput={handleChangeCheckPassword}
                variant="outlined"
                fullWidth
                name="checkPassword"
                label={t("registerPageCheckPassword")}
                type={showCheckPassword ? "text" : "password"}
                id="checkPassword"
                error={checkPasswordError}
                required
                InputProps={{
                  className: classes.underline,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowCheckPassword}
                        onMouseDown={handleMouseDownCheckPassword}
                      >
                        {showCheckPassword ? (
                          <img src={ico_pass_on} alt="pass_on" />
                        ) : (
                          <img src={ico_pass_off} alt="pass_off" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                className={commonClasses.inputsStyle}
              />
            </Grid>
            {passwordError || checkPasswordError ? (
              <Grid item sm={12} xs={12} className={commonClasses.gridIdentity}>
                <Typography className={classes.errorText}>
                  {passwordError
                    ? t("registerPagePasswordError")
                    : t("registerPageCheckPasswordError")}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Container>
      ) : (
        <Container className={commonClasses.dialogContainer}>
          {verifiedEmails.length > 0 ? (
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <Typography className={classes.dialogContentText} noWrap>
                  {t("resetPasswordSendEmail")}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <FormControl variant="filled" color="secondary" fullWidth className={commonClasses.inputsStyle}>
                  <InputLabel>{t("resetPasswordEmail")}</InputLabel>
                  <Select
                    native
                    label={t("resetPasswordEmail")}
                    value={selectedEmail}
                    onChange={(value) => handleChange("email", value)}
                  >
                    {verifiedEmails.map((email) => {
                      return (
                        <option key={email.id} value={email.email}>
                          {email.email}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3} className={classes.dialogSendIconContainer} style={{ margin: "auto" }}>
                <CorreosButton
                  color={sendedEmail ? "secondary" : "primary"}
                  variant={sendedEmail ? "outlined" : "contained"}
                  onClick={() => handleSend("email")}
                  style={{ width: "100%" }}
                >
                  {sendedEmail ? t("reSend") : t("send")}
                </CorreosButton>
              </Grid>
            </Grid>
          ) : null}
          {verifiedPhones.length > 0 && verifiedEmails.length > 0 ? (
            <Grid style={{ margin: "1.5em" }} />
          ) : null}
          {verifiedPhones.length > 0 ? (
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <Typography className={classes.dialogContentText} noWrap>
                  {t("resetPasswordSendPhone")}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <FormControl variant="outlined" color="secondary" fullWidth>
                  <InputLabel>{t("resetPasswordPhone")}</InputLabel>
                  <Select
                    native
                    label={t("resetPasswordPhone")}
                    value={selectedPhone}
                    onChange={(value) => handleChange("phone", value)}
                  >
                    {verifiedPhones.map((phone) => {
                      return (
                        <option key={phone.id} value={phone.phone}>
                          {phone.phone}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3} className={classes.dialogSendIconContainer} style={{ margin: "auto" }}>
                <CorreosButton
                  color={sendedPhone ? "secondary" : "primary"}
                  variant={sendedPhone ? "outlined" : "contained"}
                  onClick={() => handleSend("phone")}
                  style={{ width: "100%" }}
                  disabled={checkIsNullUndefined(reCaptchaToken)}
                >
                  {sendedPhone ? t("reSend") : t("send")}
                </CorreosButton>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                xs={12}
                className={commonClasses.gridIdentity}
                style={{ marginBottom: "1em" }}
              >
                <Grid style={{ display: 'inline-block', marginTop: '2em' }}>
                  <ReCAPTCHA
                    ref={(r) => setReCaptchaRef(r)}
                    sitekey={process?.env?.REACT_APP_RECAPTCHA_KEY}
                    onChange={handleOnChangeReCaptcha}
                    size={getScreenWidth() > 440 ? "normal" : "compact"} />

                  {checkIsNullUndefined(reCaptchaError) ? null : (
                    <p style={{ color: "#f32735", fontSize: "80% !important", textAlign: "start" }}>{reCaptchaError}</p>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Container>
      )}
      <Container className={commonClasses.dialogContainer}>
        <Grid container spacing={1}>
          <Grid
            container
            item
            xs={12}
            className={commonClasses.dialogButtonContainer}
          >
            <Grid item xs={6}>
              {confirmed ? (
                <CorreosButton
                  onClick={handleGoBack}
                  className={commonClasses.dialogButtonLeft}
                >
                  {t("backButton")}
                </CorreosButton>
              ) : (
                <CorreosButton
                  onClick={handleCancel}
                  className={commonClasses.dialogButtonLeft}
                >
                  {t("cancel")}
                </CorreosButton>
              )}
            </Grid>
            <Grid item xs={6}>
              {confirmed ? (
                <CorreosButton
                  variant="contained"
                  color="primary"
                  onClick={handleResetPassword}
                  className={commonClasses.dialogButtonRight}
                  disabled={!allOk}
                >
                  {t("accept")}
                </CorreosButton>
              ) : (
                <CorreosButton
                  variant="contained"
                  color="primary"
                  onClick={() => setConfirmed(true)}
                  className={commonClasses.dialogButtonRight}
                  disabled={!sendedEmail && !sendedPhone}
                >
                  {t("next")}
                </CorreosButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}
