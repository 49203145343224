import {
    Grid,
    Tabs,
    Tab,
    Box,
    Divider,
    Paper,
} from "@material-ui/core";
import { TabPanel } from "./TabPanel";
import { a11yProps } from "./a11yProps";
import { groupAttributes } from "../../../commons/EnumAttributes";
import { EnumPersonType } from "../../../commons/EnumsGeneral";
import { checkIsNullUndefined } from "../../../commons/Utils";
import { DialogAddressActions } from "./DialogAddressActions";
import { AgreeConditionsLabel } from "../../CommonComponents/AgreeConditionsLabel";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { getReplaceApplicationOid } from "../../../services/Commons";
import { PendingDataWarning } from "../../CommonComponents/PendingDataWarning";
import { AddressCard } from "./AddressCard";

const handleTabChange = (event, tabValue, auxFormSettersObject, checkAllOk) => {
    auxFormSettersObject.SetTab(tabValue);
    checkAllOk();
};

const handleHideAddresTab = (tabGroupCode, personType, auxAddressValuesObject, auxFormValuesObject, auxiliarTab, onlyMandatories) => {
    let flag = false;
    let isUserAddressTab = tabGroupCode == groupAttributes.userAddress;
    let isEntepriseAddressTab = tabGroupCode == groupAttributes.enterpriseAddress;
    if (isUserAddressTab || isEntepriseAddressTab) {
        let addressAttributeName = personType == EnumPersonType.User ? "userAddress" : "enterpriseAddress";
        let auxNeedsAddress = auxAddressValuesObject.NeedsAddress;
        let auxAddressList = auxFormValuesObject.OriginalData[addressAttributeName];
        if (auxiliarTab) {
            flag = !auxNeedsAddress && onlyMandatories;
        } else {
            flag = !auxNeedsAddress && (checkIsNullUndefined(auxAddressList) || auxAddressList.length == 0);
        }
    }
    return flag;
};

const mountTabText = (name, code, auxAddressValuesObject) => {
    let needsAddress = auxAddressValuesObject.NeedsAddress;
    return (code == groupAttributes.userAddress || code == groupAttributes.enterpriseAddress) && needsAddress ? name + " *" : name;
};

export const mountButtons = (
    auxCompInfo,
    auxCompConst,
    auxRgpdsInfo,
    auxFunctions,
    auxCompVariab,
) => {
    return <Grid
        container
        item
        xs={12}
        className={auxCompConst.commonClasses.mainButtonContainer}
    >
        {auxCompVariab.showPendingData && auxCompVariab.pendingData.length > 0 ? (
            <PendingDataWarning pendingData={auxCompVariab.pendingData} />
        ) : null}
        {getNeedsConditions(auxCompConst) ?
            <Grid item xs={12} style={{ marginBottom: "2em" }}>
                <AgreeConditionsLabel
                    setOk={auxRgpdsInfo.setAgreeTyCs}
                    finishOperation={auxFunctions.finishOperation}
                    personType={auxCompInfo.auxPersonType}
                    applicationOid={getReplaceApplicationOid()}
                    separatedChecks={true}
                />
            </Grid> : null}
        {(auxCompInfo.auxTab !== groupAttributes.userAddress && auxCompInfo.auxTab != groupAttributes.enterpriseAddress) ? (
            <Grid item xs={6}>
                <CorreosButton
                    onClick={auxFunctions.handleCancel}
                    className={auxCompConst.commonClasses.leftButton}
                >
                    {auxCompConst.isRegistry ? auxFunctions.doTraduction("cancel") : auxFunctions.doTraduction("reset")}
                </CorreosButton>
            </Grid>
        ) : (
            <Grid item sm={6} xs={12}></Grid>
        )}
        <Grid item xs={6}>
            <CorreosButton
                variant="contained"
                color="primary"
                className={auxCompConst.commonClasses.rightButton}
                disabled={auxCompVariab.pendingData.length > 3}
                onClick={auxFunctions.handleSave}
                circularProgress={auxCompConst.nextCircularProgress}
            >
                {auxCompConst.isRegistry ? auxFunctions.doTraduction("next") : auxCompInfo.auxText}
            </CorreosButton>
        </Grid>
    </Grid>
};

const getNeedsConditions = (auxCompConst) => {
    let auxNeeds = false;
    if (auxCompConst.isRegistry) {
        let isCid = getReplaceApplicationOid() == process?.env?.REACT_APP_APP_OID;
        auxNeeds = !isCid;
    };
    return auxNeeds;
}

export const mountFormBlock = (
    auxInfoGetters,
    auxFunctions,
    auxCompConst,
    auxCompVariab,
    auxAddresses,
    setActiveAddress
) => {
    let personType = auxCompVariab.personType
    let auxFormValuesObject = auxInfoGetters.getBothFormValuesObject(personType);
    let auxAddressValuesObject = auxInfoGetters.getBothAddressValuesObject(personType);
    let auxAddressSettersObject = auxInfoGetters.getBothAddressSettersObject(personType);
    let auxFormSettersObject = auxInfoGetters.getBothFormSettersObject(personType);
    let objectAddressActions = {
        actionCircularProgress: auxAddressValuesObject.ActionCircularProgress,
        addressCircularProgress: auxAddressValuesObject.AddressCircularProgress,
        addressEditableForm: auxAddressValuesObject.AddressEditableForm,
        prepareBasicAddressForm: auxFunctions.prepareBasicAddressForm,
        prepareEditableAddressForm: auxFunctions.prepareEditableAddressForm,
        selectGenerator: auxFunctions.selectGenerator,
        handleAddAddress: auxFunctions.handleAddAddress,
        handleEditAddress: auxFunctions.handleChangeAddress,
        handleRemoveAddress: auxFunctions.handleRemoveAddress,
        editableAddressOk: auxAddressValuesObject.AddressEditableFormOk,
        activeAddress: auxAddressValuesObject.ActiveAddress,
        setActiveAddress: setActiveAddress,
        handleIsControlledCountry: auxFunctions.handleIsControlledCountry,
        needsAddress: auxAddressValuesObject.NeedsAddress,
        isFavourite: auxAddressValuesObject.IsFavouriteAddress,
        hasFavourite: auxAddressValuesObject.HasFavouriteAddress,
        isUser: personType == EnumPersonType.User,
        noAddress: (checkIsNullUndefined(auxAddressValuesObject.EditableAddresses) || auxAddressValuesObject.EditableAddresses.length === 0),
        setAddressOperationType: auxAddressSettersObject.SetAddressOperationType,
    };
    return (
        <Grid container item xs={12}>
            <Paper style={{ width: "100%" }}>
                <Grid
                    container
                    item
                    xs={12}
                    className={auxCompConst.commonClasses.formContainer}
                >
                    <Grid container item xs={12} style={{ width: "100%" }}>
                        <Grid item xs={12}>
                            <Tabs
                                value={auxFormValuesObject.Tab}
                                onChange={(event, tabValue) => handleTabChange(event, tabValue, auxFormSettersObject, auxFunctions.callCheckAllOk)}
                                aria-label="Attributes Tab"
                            >
                                {auxFormValuesObject.Groups.map((group) => {
                                    return handleHideAddresTab(group.code, personType, auxAddressValuesObject, auxFormValuesObject, auxCompVariab.auxiliarTab, auxCompConst.onlyMandatories) ? null : (
                                        <Tab
                                            key={"Tab" + group.name}
                                            label={mountTabText(group.name, group.code, auxAddressValuesObject)}
                                            {...a11yProps(group.code)}
                                            value={group.code}
                                        />
                                    );
                                })}
                            </Tabs>
                            <Divider></Divider>
                        </Grid>
                        {auxFormValuesObject.Groups.map((group) => {
                            return (
                                <TabPanel
                                    key={"Panel" + group.name}
                                    value={auxFormValuesObject.Tab}
                                    index={group.code}
                                    style={{ width: "100%" }}
                                >
                                    <Box style={{ marginTop: "2em" }}>
                                        <Grid
                                            container
                                            item
                                            spacing={(group.code == groupAttributes.userData || group.code == groupAttributes.enterpriseData) ? 1 : 2}
                                            xs={12}
                                            style={{ width: "100%" }}
                                        >
                                            {(group.code == groupAttributes.userData || group.code == groupAttributes.enterpriseData) ? (
                                                auxFormValuesObject.DataForm.map((attr) => {
                                                    return auxFunctions.selectGenerator(attr);
                                                })
                                            ) : group.code == groupAttributes.userAddress || group.code == groupAttributes.enterpriseAddress ? (
                                                auxAddresses?.map((address) => {
                                                    return <AddressCard
                                                        address={address}
                                                        addressForm={auxAddressValuesObject.AddressEditableForm}
                                                        isUser={personType == EnumPersonType.User}
                                                        objectAddressActions={objectAddressActions}
                                                    />
                                                })
                                            ) : null}
                                        </Grid>
                                    </Box>
                                </TabPanel>
                            );
                        })}
                    </Grid>
                    {(auxFormValuesObject.Tab === groupAttributes.userAddress || auxFormValuesObject.Tab === groupAttributes.enterpriseAddress) ? (
                        <Grid
                            container
                            item
                            xs={12}
                            style={{ marginTop: "0.5em" }}
                        >
                            <Grid item xs={12}>
                                <DialogAddressActions
                                    objectAddressActions={objectAddressActions}
                                />
                            </Grid>
                        </Grid>
                    ) : null}
                    {!auxCompConst.subscriptionPage && !auxCompConst.pendingDataPage ? (
                        auxFunctions.getButtons(auxFormValuesObject.Tab,
                            auxFunctions.doTraduction("userDataSaveProfile"),
                            1)
                    ) : null}
                </Grid>
            </Paper>
        </Grid>
    );
}