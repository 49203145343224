import { makeStyles } from "@material-ui/core/styles";
import { Constants } from "./Constants";
import { getReplaceMode } from "../services/Commons";

export const CommonStyles = makeStyles((theme) => ({
  //Login
  gridIdentity: {
    display: "flex",
    margin: "auto"
  },
  gridIdentityCenter: {
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
  },
  divButtons: {
    marginTop: "3rem",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "right",
  },
  leftButton: {
    float: "left",
    marginRight: "auto",
  },
  rightButton: {
    float: "right",
    marginLeft: "auto",
  },
  linkDecoration: {
    textDecoration: "none",
    fontWeight: "bold",
    color: "#333333",
    "&:hover": { textDecoration: "none", fontWeight: "bold", color: "#333333" },
  },
  linkDecorationCorreos: {
    textDecoration: "none",
    color: "#002453",
    "&:hover": { textDecoration: "none" }
  },
  agreeConditions: {
    fontSize: "80% !important",
  },
  agreeConditionsCid: {
    textAlign: "left",
  },
  loginTitleColor: {
    color: "#333333",
  },

  leftButtonMobile: {
    float: "left",
    marginRight: "auto",
    marginLeft: "1.5em",
    marginTop: "1em",
  },
  rightButtonMobile: {
    float: "right",
    marginLeft: "auto",
    marginRight: "1.5em",
    marginTop: "1em",

  },

  //Dialogs
  dialogWindow: {
    marginTop: "-15%",
  },
  dialogWindowAux: {
    marginTop: "0%",
  },
  dialogTitle: {
    padding: "1.5em 0em 0em 0em",
    color: "#002453",
    width: "98%",
  },
  dialogTitleText: {
    margin: "auto",
    textAlign: "center",
  },
  dialogContainer: {
    padding: "1.5em 2em 1em 2em",
    width: "98%",
  },
  dialogClosebutton: {
    marginTop: "-0.7em",
    marginRight: "-0.15em",
    float: "right",
    color: "#666666",
  },
  dialogContentText: {
    textAlign: "center",
    color: "#666666",
  },
  dialogContentTextOptions: {
    textAlign: "left",
    color: "#666666",
  },
  dialogButtonContainer: {
    margin: "2em 0em 1em 0em !important",
  },
  dialogButtonLeft: {
    float: "left",
    marginRight: "auto",
  },
  dialogButtonRight: {
    float: "right",
    marginLeft: "auto",
  },
  dialogButtonCenter: {
    margin: "auto",
  },
  dialogItems: {
    marginBottom: "1em",
  },

  // DeletePopper
  popperContainer: {
    width: "20em",
    height: "10em",
    padding: "1em",
  },
  popperContentText: {
    textAlign: "center",
  },

  //Divs generales
  container: {
    marginTop: Constants.MarginTopMobile,
    [theme.breakpoints.up("sm")]: {
      marginTop: Constants.MarginTopApp,
      marginLeft: getReplaceMode() != "true" ? Constants.DrawerWidth : 0,
    },
  },
  unifyContainer: {
    marginTop: Constants.MarginTopMobile,
    [theme.breakpoints.up("sm")]: {
      marginTop: Constants.MarginTopApp,
    },
  },
  hideContainer: {
    marginTop: Constants.MarginTopApp,
    marginLeft: Constants.DrawerWidth,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mainDiv: {
    textAlign: "center",
    display: "flow-root",
    position: "relative",
  },
  textDiv: {
    marginTop: "1rem",
    marginBottom: "1rem",
    margin: "auto",
  },
  mainContainer: {
    margin: "auto",
    paddingLeft: "6%",
    paddingRight: "6%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "2%",
      paddingRight: "2%",
    },
  },
  subscriptionContainer: {
    margin: "auto",
    // paddingLeft: "8%",
    // paddingRight: "8%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "2%",
      paddingRight: "2%",
    },
  },
  subscriptionPaper: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "2%",
      paddingRight: "2%",
    },
  },
  registerContainer: {
    padding: 0,
  },
  registerSubContainer: {
    padding: "inherit",
  },
  formContainer: {
    padding: "1em 1em 1em 1.6em",
  },
  mainButtonContainer: {
    margin: "2em 0em 1em 0em !important",
    paddingRight: "0.6em",
  },
  gridTitle: {
    color: "#002453",
  },

  //Iconos generales
  iconGridStyle: {
    margin: "auto",
    color: "#333333",
  },
  iconActionStyle: {
    width: "1.4em",
    height: "1.4em",
    color: "#333333",
    padding: "0.1em",
    [theme.breakpoints.down("sm")]: {
      width: "1em",
      height: "1em",
      padding: "0.1em",
    },
  },
  iconStyle: {
    width: "2.3em",
    height: "2.3em",
    color: "#002453",
    padding: "0.3em",
    [theme.breakpoints.down("sm")]: {
      width: "1.9em",
      height: "1.9em",
      padding: "0.2em",
    },
  },
  iconDisabledStyle: {
    width: "1.4em",
    height: "1.4em",
    color: "#CBCBCB",
    padding: "0.1em",
    [theme.breakpoints.down("sm")]: {
      width: "1em",
      height: "1em",
      padding: "0.1em",
    },
  },
  iconButton: {
    padding: "0px",
    margin: "0px",
    color: "#333333",
  },
  listStyle: {
    width: "100%",
    textAlign: "left",
  },
  listItemStyle: {
    textAlign: "left",
    margin: "10px 0px",
  },
  listTextStyleShow: {
    width: "100%",
    color: "#666666",
  },
  listTextStyleGreen: {
    color: "#348f41",
    marginLeft: "5px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  listTextStyleOrange: {
    color: "#DBB000",
    marginLeft: "5px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  buttonGridStyle: {
    margin: "auto",
    textAlign: "left",
  },

  textGridStyle: {
    height: "100%",
  },
  textVerificationStyle: {
    padding: "8px",
    float: "left",
    color: "#348f41",
  },
  textNoVerificationStyle: {
    padding: "8px",
    float: "left",
    color: "#f32735",
  },
  textPendingVerificationStyle: {
    padding: "8px",
    float: "left",
    color: "#dbb000",
  },
  actionCardsContainer: {
    height: "fit-content",
  },
  generalDialog: {
    marginTop: "-15em",
  },
  greyColor: {
    color: "grey !important",
  },
  title: {
    margin: "0.15em",
    color: "#002453",
  },
  mainPublicDiv: {
    textAlign: "center",
    position: "relative",
    marginTop: Constants.MarginTop,
  },
  leftImgContainer: {
    height: window.screen.height,
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
  },
  leftImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    overflow: "hidden",
    position: "fixed",
  },
  inputsStyle: {
    "& .MuiFilledInput-root": {
      backgroundColor: "#F2F2F2",
      borderTopLeftRadius: "9px",
      borderTopRightRadius: "9px",
      borderTop: "1px solid #cbcbcb", borderLeft: "1px solid #cbcbcb", borderRight: "1px solid #cbcbcb",
      "&:hover": { backgroundColor: "#cbcbcb" },
      "&:before": { borderBottom: "1px solid #ffcd00" },
    }
  },
  divider: {
    backgroundColor: "#FDCD00",
    marginRight: "1em",
    marginTop: "1em",
    marginBottom: "1em",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1em",
    }
  },
  generalCardContainer: {
    width: "100%",
    marginBottom: "0.5em",
    paddingLeft: "5em",
    paddingRight: "5em",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.5em",
      paddingRight: "0.5em",
    }

  },
  button_link: {
    background: "none",
    border: "none",
    color: "#002453",
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: "1em",
    "&:hover": { color: "#002453" }
  },
  circularProgress: {
    margin: "auto",
  },
  buttonLeftCenter: {
    float: "left",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      float: "none",
      margin: "0.3em",
    }
  },
  buttonRightCenter: {
    float: "right",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      float: "none",
      margin: "0.3em",
    }
  },
}));
