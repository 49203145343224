import { makeStyles } from '@material-ui/core/styles';

export const ChooseEnterpriseStyles = makeStyles((theme) => ({
    mainDiv: {
      marginTop: "11rem",
      textAlign: "center",
      minHeight: "100vh",
      display: "flow-root",
      position: "relative",
    },
  
    mainPaperLogin: {
      marginTop: "11rem",
      maxWidth: "28rem",
      minHeight: "20rem",
      margin: "auto",
      padding: "2rem",
      display: "flow-root",
    },
  
    mainPaperRegister: {
      marginTop: "2.5rem",
      maxWidth: "28rem",
      minHeight: "20rem",
      margin: "auto",
      padding: "2rem",
      display: "flow-root",
    },
  
    imgLoginDiv: {
      position: "absolute",
      top: "7.9rem",
      width: "100%",
    },
  
    imgLogin: {
      maxWidth: "6rem",
      marginLeft: "auto",
      marginRight: "auto",
    },
  
    divTitle: {
      marginBottom: "1rem",
      marginTop: "1.5rem"
    },
  
    userCompleteName: {
      marginTop: "2.5em",
      marginBottom: "1em",
    },
  
    logInSubtitle: {
      marginTop: "1rem"
    },
  
    userNameTypography: {
      color: "#002453",
      cursor: "pointer",
      fontSize: "1rem",
      lineHeight: "1.5",
    },
  
    gridIdentity: {
      display: "flex",
      margin: "auto",
    },
  
    divButtons: {
      marginTop: "3rem",
      marginLeft: "auto",
      marginRight: "auto",
      textAlign: "right",
    },
  
    nextButton: {
      float: "right",
      marginLeft: "auto",
    },
  
    createAccountButton: {
      float: "right",
      marginRight: "auto",
    },
  
    circularProgresLogin: {
      marginLeft: "auto",
      marginRight: "auto",
    },

    generalDialog: {
      marginTop: "-200px",
    },
    dialogTitle: {
      padding: "15px 0px 0px 20px",
      color: "#002453",
      width: "98%",
    },
    dialogContainer: {
      padding: "15px 0px 15px 15px",
      width: "93%",
    },
    dialogClosebutton: {
      marginRight: "-55px",
      marginTop: "-20px",
    },
    dialogContent: {
      alignItems: "center",
      padding: "0.75em",
    },
    selectWidth: {
      minWidth: "50px",
      marginTop: "0.75em",
      marginRight: "-1em",
    },
    selectOpt: {
      display: "flex", 
      flexWrap: "wrap",
    }
  }));