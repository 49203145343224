import { AttributeCodes, Attributes } from "./Attributes";
import { GetTextTraduction, checkIsNullUndefined, getErrorMessage } from "./Utils";
import { EnumCountries } from "../commons/EnumCountries";

export const insertValue = (attribute) => {
  if (attribute.options.length === 1 || attribute.options.length > 2) {
    attribute.value = attribute.options[0];
  } else {
    attribute.value = attribute.options[1];
  }
};

export const getGroups = (model) => {
  var auxiliarGroups = [];
  let auxGroupCode = [];
  model.forEach((element) => {
    if (auxGroupCode.indexOf(element.codeGroup) == -1) {
      auxGroupCode.push(element.codeGroup);
      auxiliarGroups.push({ name: element.nameGroup, code: element.codeGroup });
    }
  });
  return auxiliarGroups;
};

export const getFinalModel = (model, groups) => {
  var auxiliarModel = [];
  groups.forEach((group) => {
    var auxiliarGroup = [];
    model.forEach((element) => {
      if (element.codeGroup === group.code) {
        auxiliarGroup.push(element);
      }
    });
    auxiliarModel.push(auxiliarGroup);
  });
  return auxiliarModel;
};

export const obtainFieldTypeCode = (attribute, attributeFields, fieldTypes) => {
  let auxAttributeField = attributeFields.find(
    (attributeField) => attribute.codeAttribute === attributeField.code
  );
  let auxFieldType = fieldTypes.find(
    (fieldType) => auxAttributeField.idFieldType === fieldType.id
  );
  return (auxAttributeField.code === "POB" || auxAttributeField.code === "EPO") ? "DRO" : auxFieldType.code;
};

export const checkHasFavouriteAddress = (addressList) => {
  return !checkIsNullUndefined(addressList) ? addressList.filter((address) => address.favourite == true).length !== 0 : false;
};

export const fillAddressValues = (attribute, auxData, auxConst) => {
  let attributeName = Attributes[attribute.codeAttribute].attributeName;
  let dicGroupInputs = ["PRO", "POB", "CPO", "EPR", "EPO", "ECP"];
  attribute.mandatory = mandatoryFields.includes(attribute.codeAttribute);
  if (attribute.codeAttribute === AttributeCodes.Country || attribute.codeAttribute === AttributeCodes.EntCountry) {
    attribute.dropdownValues = auxConst.countryList;
  } else if (attribute.codeAttribute === AttributeCodes.Via || attribute.codeAttribute === AttributeCodes.EntVia) {
    attribute.dropdownValues = auxConst.roadTypeList;
  };
  if (auxData.length === 1) {
    attribute.value = auxData[0][attributeName];
  };
  if (dicGroupInputs.indexOf(attribute.codeAttribute) !== -1) {
    attribute.dropdownValues = [];
    attribute.originalValues = [];
  };
};

export const checkEditableDirOkNew = (
  auxAddressForm,
  auxAddressList,
  operationType
) => {
  let flag = { flag: true };
  let countryAttribute = auxAddressForm.find(
    (attr) => attr.codeAttribute === "PAI" || attr.codeAttribute === "EPI"
  );
  let aliasAttribute = auxAddressForm.find(
    (attr) => attr.codeAttribute === "ALI" || attr.codeAttribute === "EAL"
  );
  let auxCodeList = ["PRO", "POB", "CPO", "EPR", "EPO", "ECP"];
  auxAddressForm.forEach((attribute) => {
    manageMandatoryAddressData(auxCodeList, attribute, countryAttribute, flag);
  });
  if (!checkIsNullUndefined(aliasAttribute)) {
    let auxAddress = auxAddressList.filter(
      (address) => address == aliasAttribute.value
    );
    let value = operationType == "add" ? 0 : 1;
    if (auxAddress.length > value) {
      flag.flag = false;
    }
  }
  return flag.flag;
};

const manageMandatoryAddressData = (auxCodeList, attribute, countryAttribute, flag) => {
  if (attribute.mandatory && checkIsNullUndefined(attribute.value)) {
    if (auxCodeList.includes(attribute.codeAttribute)) {
      if (countryAttribute.value == EnumCountries.ESP || countryAttribute.value == EnumCountries.AND) {
        flag.flag = false;
      }
    } else {
      flag.flag = false;
    }
  }
}

export const getFavouriteDirection = (addresses) => {
  let position = 0;
  if (addresses.length > 0) {
    addresses.forEach((address, index) => {
      if (address.favourite) {
        position = index;
      }
    });
  }
  return position;
};

export const getActualDirection = (alias, editableDirections) => {
  let actualAddress = 0;
  editableDirections.forEach((address, index) => {
    if (address.alias === alias) {
      actualAddress = index;
    }
  });
  return actualAddress;
};

export const fillAddressEditableModelNew = (
  auxiliarModel,
  auxActiveDirection
) => {
  if (!checkIsNullUndefined(auxiliarModel) && auxiliarModel.length != 0) {
    auxiliarModel.forEach((element) => {
      element.value =
        auxActiveDirection[Attributes[element.codeAttribute].attributeName];
    });
  }
};

export const getValue = (code, usableModel, originalForm) => {
  let value = null;
  let auxiliar = obtainAttribute(usableModel, code);
  if (checkIsNullUndefined(auxiliar)) {
    value = originalForm[Attributes[code].attributeName];
  } else if (!checkIsNullUndefined(auxiliar.value)) {
    value = auxiliar.value;
  }
  return value;
};

export const getId = (value) => {
  return checkIsNullUndefined(value) ? null : parseInt(value, 10);
};

export const insertValueAddress = (attribute) => {
  if (attribute.options.length === 2) {
    attribute.value = 0;
  } else {
    attribute.value = -1;
  }
};

export const prepareAddChangeAddressNew = (
  auxAddress,
  auxAddressEditableForm,
  auxAddressForm
) => {
  auxAddressEditableForm.forEach((attribute) => {
    auxAddress[Attributes[attribute.codeAttribute].attributeName] = checkIsNullUndefined(attribute.value) ? null : attribute.value;
    let auxAttr = auxAddressForm.find(
      (element) => element.codeAttribute === attribute.codeAttribute
    );
    auxAttr.value = attribute.value;
    if (!checkIsNullUndefined(attribute.options)) {
      auxAttr.options = attribute.options;
    }
    if (!checkIsNullUndefined(attribute.dropdownValues)) {
      auxAttr.dropdownValues = attribute.dropdownValues;
    }
    if (!checkIsNullUndefined(attribute.originalValues)) {
      auxAttr.originalValues = attribute.originalValues;
    }
  });
};

export const mandatoryFields = [
  "VIA",
  "DIR",
  "POB",
  "CPO",
  "PRO",
  "PAI",
  "ALI",
  "EVI",
  "EDR",
  "EPO",
  "ECP",
  "EPR",
  "EPI",
  "EAL",
];

export const sortAttributes = (auxAttrList) => {
  let auxList = [];
  if (!checkIsNullUndefined(auxAttrList)) {
    auxList = auxAttrList.sort(function (a, b) {
      return (
        Attributes[a.codeAttribute].position -
        Attributes[b.codeAttribute].position
      );
    });
  }
  return auxList;
};

export const obtainAttribute = (auxAttrList, attributeCode) => {
  let auxAttr = auxAttrList.find(
    (attr) => attr.codeAttribute === attributeCode
  );
  return auxAttr;
};

export const putDataType = (auxAttrList, dataType) => {
  auxAttrList.forEach((attr) => {
    attr.dataType = dataType;
  });
};

export const getEmails = (userEmailsList) => {
  let auxEmailList = [];
  if (!checkIsNullUndefined(userEmailsList)) {
    userEmailsList.forEach((email) => {
      auxEmailList.push(email.email);
    });
  }
  return auxEmailList;
};

export const getPhones = (userPhonesList) => {
  let auxPhoneList = [];
  if (!checkIsNullUndefined(userPhonesList)) {
    userPhonesList.forEach((phone) => {
      auxPhoneList.push(phone.phone);
    });
  }
  return auxPhoneList;
};

export const getAddresses = (addressesList) => {
  let auxAddressList = [];
  if (!checkIsNullUndefined(addressesList)) {
    addressesList.forEach((address) => {
      auxAddressList.push(address.alias);
    });
  }
  return auxAddressList;
};

export const isResultOk = (resultsList) => {
  let flag = true;
  resultsList.forEach((result) => {
    if (checkIsNullUndefined(result) || !checkIsNullUndefined(result.Status)) {
      flag = false;
    }
  });
  return flag;
};

export const showAlert = (resultsList, finishOperation, t) => {
  resultsList.forEach((result) => {
    if (!checkIsNullUndefined(result) || !checkIsNullUndefined(result?.Status)) {
      finishOperation("error", getErrorMessage(result, t));
    }
  });
};

export const concatFormAttr = (listA, listB) => {
  let auxNewList = JSON.parse(JSON.stringify(listA));
  listB.forEach((attrB) => {
    let auxAttr = listA.find((attrA) => attrB.codeAttribute == attrA.codeAttribute);
    if (checkIsNullUndefined(auxAttr)) {
      auxNewList.push(attrB);
    } else if (attrB.mandatory) {
      obtainAttribute(auxNewList, attrB.codeAttribute).mandatory = true;
    }
  })
  return auxNewList;
}

export const filterForm = (auxForm, auxInfo) => {
  let auxUserSurnameMandatory = obtainAttribute(auxForm, AttributeCodes.Surname1)?.mandatory;
  let auxUserEmailMandatory = obtainAttribute(auxForm, AttributeCodes.Email)?.mandatory;
  let auxUserPhoneMandatory = obtainAttribute(auxForm, AttributeCodes.Phone)?.mandatory;
  let auxUserDocMandatory = obtainAttribute(auxForm, AttributeCodes.DocNumber)?.mandatory;
  let auxUserAddressMandatory = obtainAttribute(auxForm, AttributeCodes.Address)?.mandatory;
  let auxEnterpriseNameMandatory = obtainAttribute(auxForm, AttributeCodes.EntName)?.mandatory;
  let auxEnterpriseAddressMandatory = obtainAttribute(auxForm, AttributeCodes.EntAddress)?.mandatory;
  let auxCodEmailAttr = [AttributeCodes.Email];
  let auxCodPhoneAttr = [AttributeCodes.Phone, AttributeCodes.Prefix];
  let auxUserCodDocAttr = [AttributeCodes.DocType, AttributeCodes.DocNumber, AttributeCodes.ExpedCountry, AttributeCodes.ExpirDate];
  let auxCodUserAddressAttr = [AttributeCodes.Via, AttributeCodes.Address, AttributeCodes.City, AttributeCodes.PostalCode, AttributeCodes.Province, AttributeCodes.Country, AttributeCodes.Alias];
  let auxCodEnterpriseAddressAttr = [AttributeCodes.EntVia, AttributeCodes.EntAddress, AttributeCodes.EntCity, AttributeCodes.EntPostalCode, AttributeCodes.EntProvince, AttributeCodes.EntCountry, AttributeCodes.EntAlias];
  let auxCodUserSurnameAttr = [AttributeCodes.Surname1, AttributeCodes.Surname2];
  let auxCodEnterpriseNameAttr = [AttributeCodes.EntName, AttributeCodes.EntComName];
  let auxFilteredForm = auxForm.filter((attr) => {
    let flag = true;
    if (auxCodEmailAttr.includes(attr.codeAttribute)) {
      flag = auxUserEmailMandatory || auxInfo.userEmail.length > 0;
    } else if (auxCodPhoneAttr.includes(attr.codeAttribute)) {
      flag = auxUserPhoneMandatory || auxInfo.userPhone.length > 0;
    } else if (auxUserCodDocAttr.includes(attr.codeAttribute)) {
      flag = auxUserDocMandatory || auxInfo.userDocument.length > 0;
    } else if (auxCodUserAddressAttr.includes(attr.codeAttribute)) {
      flag = auxUserAddressMandatory || auxInfo.userAddress.length > 0;
    } else if (auxCodEnterpriseAddressAttr.includes(attr.codeAttribute)) {
      flag = auxEnterpriseAddressMandatory || auxInfo.enterpriseAddress.length > 0;
    } else if (auxCodUserSurnameAttr.includes(attr.codeAttribute)) {
      flag = auxUserSurnameMandatory || !checkIsNullUndefined(attr.value);
    } else if (auxCodEnterpriseNameAttr.includes(attr.codeAttribute)) {
      flag = auxEnterpriseNameMandatory || !checkIsNullUndefined(attr.value);
    } else {
      return attr.mandatory || !checkIsNullUndefined(attr.value)
    };
    return flag;
  });
  return auxFilteredForm;
};