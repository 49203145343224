import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { CommonStyles } from "../../../commons/CommonStyles";
import { EnterpriseTeamStyles } from "../EnterpriseTeamStyles";
import { DialogChangeHolder } from "./DialogChangeHolder";
import { useTranslation } from "react-i18next";

export function HolderCard(props) {
  const { isTitular, datosTitular, authorized, finishOperation, idEnterprise, getInfo } = props;
  const commonClasses = CommonStyles();
  const classes = EnterpriseTeamStyles();
  const { t, i18n } = useTranslation();

  const showDataHolder = (titularInfo, indice) => {
    return (
      <div>
        {titularInfo.map((elem) => (
          <Grid container key={indice}>
            <Grid item xs={3}>
              <Typography
                align="left"
                variant="h6"
                className={commonClasses.iconStyle}
              >
                {elem.atribute}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                align="left"
                noWrap
                className={classes.dataAuthorizedName}
              >
                {elem.value}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </div>
    );
  };

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <Typography
                variant="h5"
                align="center"
                className={commonClasses.gridTitle}
              >
                {" " + t("teamPageHolderTitle")}
              </Typography>
            </Grid>
            {isTitular ? (
              <Grid item xs={2}>
                <DialogChangeHolder
                  usersList={authorized}
                  finishOperation={finishOperation}
                  idEnterprise={idEnterprise}
                  getInfo={getInfo}
                />
              </Grid>
            ) : (
              <Grid item xs={2}></Grid>
            )}
          </Grid>
          {datosTitular.length === 0 ? (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                align="left"
                style={{ marginTop: "1em" }}
              >
                {t("teamPageTitularEmpty")}
              </Typography>
            </Grid>
          ) : (
            datosTitular.map((elem, indice) => {
              return showDataHolder(elem, indice);
            })
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
