import React, { useEffect, useState } from "react";
import {
  Grid,
  Dialog,
  Typography,
  Container,
  IconButton,
  CircularProgress,
  CardMedia
} from "@material-ui/core";
import { Alerts } from "../../Alerts/Alerts";
import { DialogStyles } from "./DialogStyles";

import subscriptionOkImage from "../../../assets/images/subok.svg"

import { useTranslation } from "react-i18next";

export function DialogSubscribeOk(props) {
  const {
    open,
    setOpen
    
  } = props;
  
  const dialogClasses = DialogStyles();
  const { t, i18n } = useTranslation();

  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [reset, setReset] = useState(false);

  useEffect(() => { }, []);


  const handleCancel = () => {
    setOpen(false);  
    setReset(true);  
  };

  return (
    <Grid> 
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      <Dialog
        fullWidth
        onClose={handleCancel}
        open={open}
        PaperProps={{ classes: {root: dialogClasses.dialogWindow }}}
      >
        <Container className={dialogClasses.dialogTitle}>
       
          <Grid container>
          <Grid item xs={12} style={{margin:"auto"}}>
            <CardMedia className={dialogClasses.mediaSubOk}>
                <img
                  className={dialogClasses.imgCardMediaSubOk}
                  src={subscriptionOkImage}
                  alt="Logo"
                />
              </CardMedia>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={dialogClasses.dialogTitleText}              >
                {t("subscriptionOkTitle")}
              </Typography>
            </Grid>
            
          </Grid>
        </Container>
        <Container className={dialogClasses.dialogTextContainer}>
          <Grid container item xs={12} className={dialogClasses.gridIdentity}>
            
            <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography className={dialogClasses.dialogTitleText}>
                  {t("subscriptionOkText")}
            </Typography>             
            </Grid>            
          </Grid>
        </Container>
      </Dialog>
    </Grid>
  );
}
