export const ESTYC1 = {
    //TyCs and Privacies Page
    tycUrlsText: "Por favor, lea atentamente los términos y condiciones de uso haciendo click sobre los siguientes enlaces:",
    privacyUrlsText: "Por favor, lea atentamente las politicas de privacidad haciendo click sobre los siguientes enlaces:",
    privacyNoUrlsText: "Para la aplicación y el tipo de persona indicados no existe privacidad que firmar.",
    tycNoUrlsText: "Para la aplicación y el tipo de persona indicados no existen TyC's que firmar.",
    atcText: "Una vez comunicado el código de verificación al operario ATC se considerarán aceptados los términos y condiciones de uso.",
  
    //Terms and Conditions
    tycHeaderTitle: "Terminos y Condiciones",
  
    //WarningLegalTyC Subtittles
    tycWarningLegalSubtittle1: "DERECHOS DE PROPIEDAD INTELECTUAL Y DE PROPIEDAD INDUSTRIAL",
    tycWarningLegalSubtittle2: "PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL",
    tycWarningLegalSubtittle3: "MEDIDAS DE SEGURIDAD",
    tycWarningLegalSubtittle4: "RESPONSABILIDADES DE CORREOS",
    tycWarningLegalSubtittle5: "RESPONSABILIDAD DEL USUARIO",
    tycWarningLegalSubtittle6: "LEY APLICABLE Y JURISDICCIÓN",
  
    //WarningLegalTyC texts
    tycWarningLegalText1: "En cumplimiento de lo establecido en el Art. 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico (LSSI-CE), se informa que esta página web es titularidad de Correos.",
    tycWarningLegalText2: "El presente documento contiene el Aviso legal de “Correos ID” proporcionados por la Sociedad Estatal Correos y Telégrafos, S.A (en adelante, Correos), con domicilio social en Calle Conde de Peñalver, 19, 28006 Madrid (España), CIF número A83052407.",
    tycWarningLegalText3: "El contacto para la atención al cliente del servicio es por medio telefónico a través del número de Atención al Cliente de Correos: 902.197.197 o página web de Correos.",
    tycWarningLegalText4: "Tanto el diseño del Portal y sus códigos fuente, como los logos, marcas, y demás signos distintivos que aparecen en el mismo, pertenecen a Correos y están protegidos por los correspondientes derechos de propiedad intelectual e industrial.",
    tycWarningLegalText5: "Su uso, reproducción, distribución, comunicación pública, transformación o cualquier otra actividad similar o análoga, queda totalmente prohibida salvo que medie expresa autorización por escrito de Correos.",
    tycWarningLegalText6: "Los contenidos del Portal están igualmente protegidos por derechos de propiedad intelectual de Correos. El uso de los contenidos elaborados por Correos o por alguna de las entidades de su Grupo será permitido únicamente en el ámbito de una relación contractual con la misma o con dichas entidades.",
    tycWarningLegalText7: "Correos declara su respeto a los derechos de propiedad intelectual e industrial de terceros; por ello, si considera que este sitio pudiera estar violando sus derechos, rogamos se ponga en contacto con Correos en el siguiente número de teléfono: 902.197.197.",
    tycWarningLegalText8_1: "La navegación a través de nuestra página web no requiere su registro previo. No obstante, cuando usted visita nuestra página web, nuestros servidores web almacenan, de forma estándar, información como la dirección IP y el dominio desde el que se obtiene el acceso, la fecha y la hora de la visita, etc. Por otro lado, ciertas funcionalidades de nuestra página web exigen que facilite información adicional a través del correspondiente formulario (p.ej. Su nombre y apellidos, dirección postal o electrónica de contacto, teléfono…). Correos tratará sus datos de carácter personal conforme las condiciones publicadas en la ",
    tycWarningLegalText8_B: "Política de Privacidad Web ",
    tycWarningLegalText8_2: "y, en su caso, a la Política de Protección de Datos que resulte aplicable a los distintos servicios solicitados.",
    tycWarningLegalText9: "Le informamos que han sido adoptadas todas las medidas de seguridad de índole técnica, organizativa y de seguridad informática necesarias para la protección de datos de carácter personal para evitar su alteración, pérdida, tratamiento y/ o acceso no autorizado, todo ello de acuerdo a lo establecido en la normativa aplicable.",
    tycWarningLegalText10: "Uso incorrecto del Portal: Correos ha creado el Portal para la promoción de sus productos y para facilitar el acceso a sus servicios, pero no puede controlar la utilización del mismo de forma distinta a la prevista en el presente Aviso Legal; por tanto, el acceso al Portal y el uso correcto de la información contenida en el mismo son responsabilidad de quien realiza estas acciones, no siendo responsable Correos por el uso incorrecto, ilícito o negligente que del mismo pudiere hacer el Usuario.",
    tycWarningLegalText11: "Utilización de los contenidos: Correos facilita todos los contenidos de su Portal (Correos ID) de buena fe y realizará sus mejores esfuerzos para que los mismos estén permanentemente actualizados y vigentes; no obstante, Correos no puede asumir responsabilidad alguna respecto al uso o acceso que realicen los Usuarios fuera del ámbito al que se dirige el Portal, ni de las consecuencias que pudiera acarrear la aplicación práctica de las opiniones, recomendaciones o estudios a que se pueda acceder a través del Portal, cuya responsabilidad final recaerá siempre sobre el Usuario. Asimismo, Correos no va a poder controlar los contenidos que no hayan sido elaborados por ella o por terceros cumpliendo su encargo y, por tanto, no responderá en ningún caso de los daños que pudieran causarse por dichos contenidos de terceros.",
    tycWarningLegalText12: "Publicidad: Correos incluirá en el Portal publicidad propia o de terceros para ofrecerle productos o servicios que entienda que pueden ser de su interés. Sin embargo, Correos no puede controlar la apariencia de dicha publicidad, ni la calidad y adecuación de dichos productos o servicios y, en consecuencia, Correos no responderá de ningún daño que se pudiera generar al usuario por dichas causas.",
    tycWarningLegalText13: "Virus: Correos se compromete a aplicar todas las medidas necesarias para intentar garantizar al Usuario la ausencia de virus informáticos y elementos similares en su Portal. No obstante, estas medidas no son infalibles y, por ello, Correos no puede asegurar totalmente la ausencia de dichos elementos nocivos. En consecuencia, Correos no será responsable de los daños que los mismos pudieran producir al Usuario.",
    tycWarningLegalText14: "Fallos tecnológicos: Correos ha concluido todos los contratos necesarios para la continuidad de su Portal y realizará sus mejores esfuerzos para que el mismo no sufra interrupciones, pero no puede garantizar la ausencia de fallos tecnológicos, ni la permanente disponibilidad del Portal y de los productos o servicios contenidos en él y, en consecuencia, no asume responsabilidad alguna por los daños y perjuicios que puedan generarse por la falta de disponibilidad y por los fallos en el acceso ocasionados por desconexiones, averías, sobrecargas o caídas de la red no imputables a Correos.",
    tycWarningLegalText15: "Intercambio de información: el Portal puede contener determinadas zonas pensadas para que los distintos Usuarios puedan poner en común sus experiencias y para fomentar el intercambio de información. Sin embargo, Correos únicamente actúa en estos foros como lugar de reunión y no controla las expresiones vertidas en los mismos, por lo que no asume responsabilidad alguna por la veracidad o pertinencia de las manifestaciones efectuadas en ellos por los Usuarios.",
    tycWarningLegalText16: "El Usuario se compromete a utilizar los servicios del Portal de acuerdo con los términos expresados en el presente Aviso Legal y la mera utilización de los mismos implicará la aceptación de éste.",
    tycWarningLegalText18: "El Usuario que actúe contra la imagen, buen nombre o reputación de Correos, así como quien utilice ilícita o fraudulentamente los diseños, logos o contenidos del Portal, será responsable frente a Correos de su actuación.",
    tycWarningLegalText19: "La ley aplicable en caso de disputa o conflicto de interpretación de los términos que conforman este Aviso Legal, así como cualquier cuestión relacionada con los productos o servicios del presente Portal, será la ley española, salvo que las normas de protección de los consumidores y usuarios establezcan la aplicabilidad de una legislación diferente.",
    tycWarningLegalText20: "Para la resolución departes se someten a la jurisdicción de los Juzgados y Tribunales de la capital de provincia de España que corresponda.",
  
    //PrivacyPoliticyTyC Subtittles
    tycPrivacyPoliticyTyCSubtittle1: "Responsable de tratamiento",
    tycPrivacyPoliticyTyCSubtittle2: "Finalidad del tratamiento y legitimación",
    tycPrivacyPoliticyTyCSubtittle3: "Tipo de datos tratados",
    tycPrivacyPoliticyTyCSubtittle4: "Plazo de conservación de los datos",
    tycPrivacyPoliticyTyCSubtittle5: "Sus derechos",
    tycPrivacyPoliticyTyCSubtittle6: "Acceso a terceros",
  
    //PrivacyPoliticyTyC list tittles
    tycPrivacyPoliticyTyCListTittle1: "Prestación del servicio Correos ID",
    tycPrivacyPoliticyTyCListTittle2: "Envío de ofertas y promociones de productos y servicios de Correos",
    tycPrivacyPoliticyTyCListTittle3: "Envío de ofertas y promociones de otras empresas del Grupo Correos y de terceras empresas",
    tycPrivacyPoliticyTyCListTittle4: "Comunicación de datos a otras empresas del Grupo Correos y a terceras empresas",
    tycPrivacyPoliticyTyCListTittle5: "Cumplir con las obligaciones legales",
    tycPrivacyPoliticyTyCListTittle6: "Información estadística y encuestas de satisfacción",
    tycPrivacyPoliticyTyCListTittle7: "Enriquecer tu información",
    tycPrivacyPoliticyTyCListTittle8: "Datos proporcionados de forma directa:",
    tycPrivacyPoliticyTyCListTittle9: "Datos recabados de forma indirecta:",
    tycPrivacyPoliticyTyCListTittle10: "Datos derivados de la propia prestación del servicio:",
  
    //PrivacyPoliticyTyC texts
    tycPrivacyPoliticyTyCText1: "Para todas las empresas del Grupo Correos respetar su privacidad y cumplir con la normativa de protección de datos es un aspecto de vital importancia.",
    tycPrivacyPoliticyTyCText2_1: "Nuestro deseo es que en todo momento conozca para qué utilizamos, o queremos utilizar, sus datos y cuáles son sus derechos. Con este objetivo, hemos redactado la siguiente Política de Protección de Datos que regula el tratamiento de datos derivado del servicio Correos ID, así como de la navegación a través de la página web ",
    tycPrivacyPoliticyTyCText2_B1: "id.correos.post",
    tycPrivacyPoliticyTyCText2_2: " (con anterioridad ",
    tycPrivacyPoliticyTyCText2_B2: "emiidentidad.correos.post",
    tycPrivacyPoliticyTyCText2_3: " ) y de cualquiera de sus subdominios o de otras webs de Correos en cuyo footer se muestre esta Política de Privacidad",
  
    tycPrivacyPoliticyTyCText3: "Lo siguiente podrá ser objeto de modificación para cualquier adaptación legal a futuras novedades legislativas o jurisprudenciales, así como prácticas de la industria. En cualquier caso, dicha modificación será comunicada al Usuario mediante cualquier vía de comunicación válida.",
  
    tycPrivacyPoliticyTyCText4: "El responsable del tratamiento es “Sociedad Estatal Correos y Telégrafos, S.A., S.M.E” (en adelante, “Correos”), con CIF A-83052407 y domicilio social en Calle Conde de Peñalver, 19, 28006 Madrid (España).",
    tycPrivacyPoliticyTyCText5: "Para garantizar la adecuada gestión de dicho tratamiento, Correos tiene designado un Delegado de Protección de Datos (DPD o DPO), a quién puede dirigirse en para cualquier cuestión que precise con relación a los mismos a través de la siguiente dirección de correo electrónico: ",
    tycPrivacyPoliticyTyCText6: "El tratamiento de sus datos persigue las siguientes finalidades:",
    tycPrivacyPoliticyTyCText7: "Sus datos serán tratados para",
    tycPrivacyPoliticyTyCText8: "Tramitar su registro como usuario y proceder a su alta a través del formulario establecido a tal efecto.",
    tycPrivacyPoliticyTyCText9: "Para darte de alta en algunos de los servicios digitales a través de Correos ID es posible que se solicite información adicional mediante un formulario web establecido al efecto.",
    tycPrivacyPoliticyTyCText10: "Prestar los servicios de “Correos ID”, de conformidad con las Condiciones Particulares del Servicio.",
    tycPrivacyPoliticyTyCText11: "En este sentido, Correos ID es un gestor centralizado de identidad digital que te permite al usuario autentificarse ante los distintos servicios digitales de Correos y/o de otros servicios de terceros que acepten este método como sistema de identificación; es decir, de forma resumida, al usuario acceder a distintos servicios a través de una sola instancia de identificación digital, evitando tener que disponer de una contraseña para cada uno de estos servicios.",
    tycPrivacyPoliticyTyCText12: "En caso de que el servicio Correos ID se utilice como plataforma digital de identificación ante servicios que presten otras empresas del Grupo Correos o terceros colaboradores es necesario que los datos del usuario se comuniquen a estas empresas para poder llevar a cabo la verificación de identidad que ha sido solicitada.",
    tycPrivacyPoliticyTyCText13: "Dar respuesta a sus dudas, consultas o reclamaciones.",
    tycPrivacyPoliticyTyCText14: "Tramitar la verificación de su identidad, derivado de un acuerdo de colaboración y/o prestación de servicios que Correos haya suscrito con un tercero.",
    tycPrivacyPoliticyTyCText15: "Asimismo, a través del gestor centralizado de identidad digital se podrá tramitar la verificación de su identidad, derivada de un acuerdo de colaboración y/o prestación de servicio suscritos entre Correos y un tercero.",
    tycPrivacyPoliticyTyCText16: "En caso de que preste su consentimiento, Correos podrá remitirle por cualquier canal electrónico o no electrónico información comercial relacionada con la prestación, mejora y actualización de nuestros productos y servicios, nuevas tarifas y descuentos.",
    tycPrivacyPoliticyTyCText17: "Esta información comercial podrá ser genérica o personalizada. En este último supuesto implicará el previo tratamiento de sus datos con el objetivo de poder proceder a su perfilado y carterización, determinar gustos, preferencias y necesidades y, en base a éstas, identificar las ofertas que puedan resultar más interesantes.",
  
    tycPrivacyPoliticyTyCText18: "En caso de que preste su consentimiento, Correos podrá remitirle por cualquier canal electrónico o no electrónico ofertas y promociones de:",
    tycPrivacyPoliticyTyCText19: "Otras empresas del Grupo Correos que prestan servicios relacionados con los sectores de: que pertenecen a las actividades de servicios de paquetería, de valor añadido al servicio postal, logística, marketing y de telecomunicación",
    tycPrivacyPoliticyTyCText20: "Otras empresas con las que Correos haya suscrito distintos convenios de colaboración y que pueden pertenecer a sectores como telecomunicaciones, financiero, gran consumo, sanidad y ONG´S.",
    tycPrivacyPoliticyTyCText21: "Esta información comercial podrá ser genérica o personalizada. En este último supuesto implicará el previo tratamiento de sus datos con el objetivo de poder proceder a su perfilado y carterización, determinar gustos, preferencias y necesidades y, en base a éstas, identificar las ofertas que puedan resultar más interesantes.",
  
    tycPrivacyPoliticyTyCText22: "En caso de que preste su consentimiento, Correos podrá comunicar sus datos a las empresas del Grupo Correos y a terceras empresas cuyos sectores se detallan en la finalidad anterior con el objetivo de que estas empresas puedan hacerle llegar, de forma directa y sin la intermediación de Correos, ofertas y promociones relativas a sus productos y servicios.",
    tycPrivacyPoliticyTyCText23: "Dentro de las distintas obligaciones legales que asume Correos se encuentran las derivadas de la aplicación de la normativa postal (Ley 43/2010 del Servicio Postal Universal, de los derechos de los usuarios y del mercado postal) y el deber de secreto de las comunicaciones (art. 18 de la Constitución), así como la normativa tributaria.",
    tycPrivacyPoliticyTyCText24: "En el cumplimiento de estas obligaciones, Correos podrá comunicar sus datos a las Administraciones Públicas y tribunales, siempre que tal información sea requerida conforme a los procesos legales establecidos.",
  
    tycPrivacyPoliticyTyCText25: "Correos tiene un interés legítimo en conocer tanto la opinión de sus clientes con respecto a sus productos y servicios como sus hábitos de consumo (p.ej. la frecuencia/recurrencia en la contratación de los distintos servicios) con el objetivo de poder ofrecer productos y servicios más ajustados a sus necesidades y mejorar tanto los productos y servicios actuales como su proceso de contratación.",
    tycPrivacyPoliticyTyCText26: "Para lograr el objetivo expuesto en el párrafo anterior, Correos podrá tratar sus datos y:",
    tycPrivacyPoliticyTyCText27: "Realizar encuestas de satisfacción tanto telefónicas como por medios electrónicos, incluso una vez deje de ser cliente, en este último caso para conocer los motivos de su marcha.",
    tycPrivacyPoliticyTyCText28: "En caso de que durante la encuesta de satisfacción el cliente manifieste su opinión sobre los productos y servicios de alguna de las empresas del Grupo Correos, el resultado de la encuesta y los datos identificativos del participante podrán ser comunicados a la empresa del Grupo Correos cuyos servicios se valoran.",
    tycPrivacyPoliticyTyCText29: "Proceder a su anonimización, de forma que no pueda volver a vincularse la información de sus hábitos de consumo con su persona y, tras ello, realizar estudios estadísticos y econométricos (p.ej. conocer el ciclo de vida del cliente y/o identificar modelos de propensión al abandono).",
    tycPrivacyPoliticyTyCText30: "En caso de que prestes tu consentimiento, Correos podrá obtener una mayor información sobre tus características, gustos y necesidades a través de la información proporcionada por distintas fuentes externas tales como Registros Públicos, Catastro, Redes Sociales, herramientas de geomarketing, bases de datos de información económica como INFORMA, ficheros de información crediticia como ASNEF, BADEXCUG… con el objetivo de introducir mejoras en nuestros productos y servicios y/o adaptar o escoger las ofertas de dichos productos y servicios que mejor se ajusten a tu concreto perfil.",
    tycPrivacyPoliticyTyCText31: "Para las finalidades expuestas en el apartado anterior se trata un conjunto de datos de carácter personal que podemos dividir en las siguientes fuentes y categorías:",
    tycPrivacyPoliticyTyCText32: "En el momento del registro el usuario debe proporcionar una información mínima que resulta necesaria para su verificación y alta, por lo que, salvo que se advierta de lo contrario, la falta de cumplimentación impedirá la prestación del servicio. Ciertas funcionalidades (e.j acceso a determinados servicios) exigen que facilites información adicional a través del propio servicio.",
    tycPrivacyPoliticyTyCText33: "Asimismo, junto con la información proporcionada a través del servicio se tratarán datos recabados por el servidor, como la dirección IP desde la que accede, el dominio desde el que se obtiene el acceso, la fecha y la hora de la visita, la cookie de sesión (esta última se elimina al cerrar el navegador), etc.",
    tycPrivacyPoliticyTyCText34: "El cliente garantiza que, los datos facilitados a Correos para la prestación de los servicios solicitados responden con veracidad a la situación real y que comunicará cualquier modificación que afecte a los mismos. En consecuencia, el cliente responderá frente a Correos y terceros de cualesquiera daños o perjuicios ocasionados como consecuencia del incumplimiento de las obligaciones asumidas en esta cláusula.",
    tycPrivacyPoliticyTyCText35_1: "Todos aquellos datos que, en caso de haber prestado su consentimiento, Correos recaba de la tipología de fuentes " + 'externas enumerada en la finalidad "H" del apartado ',
    tycPrivacyPoliticyTyCText35_B: '"Finalidad del tratamiento y legitimación".',
    tycPrivacyPoliticyTyCText36: "Además de en el caso anterior, terceras entidades, como Administraciones públicas, la empresa en la que trabajas u otras instituciones con la que Correos tenga suscrito un acuerdo de colaboración y/ o prestación de servicios, y que estén interesados en contratar este método como sistema de identificación, pueden comunicarnos o darnos acceso a tus datos, con el fin de que podamos verificar tu identidad. Las entidades con las que Correos haya suscrito acuerdos de colaboración y/ o prestación de servicios podrán, en su caso, inhabilitar el sistema de identificación, o aplicar condiciones distintas o adicionales de acuerdo con lo recogido en los acuerdos de colaboración y/o de prestación de servicios suscritos.",
    tycPrivacyPoliticyTyCText37: "Dentro de esta información se incluyen aspectos como: (i) El histórico de productos y servicios contratados con Correos, el tipo de servicio y sus características (p.ej. Servicios digitales de Correos en los que se encuentra dado de alta, frecuencia de uso de estos servicios, uso de otros servicios de Correos como envíos postales, paquetería, Citypaq…); (ii) Reclamaciones o solicitudes de información, etc. (iii) Información sobre su navegación a través de las cookies que pueden instalarse en su dispositivo, previo su consentimiento -de ser necesario- de conformidad con lo establecido en nuestra Política de Cookies.",
    tycPrivacyPoliticyTyCText38: "Tus datos personales serán conservados mientras sean necesarios para la prestación del servicio de Correos ID, salvo que solicites su supresión. El proceso de eliminación de Identidad implica un bloqueo o restricción de uso de tu Identidad y datos durante un periodo de treinta (30) días, tras el cual se procederá a su efectiva eliminación. Durante el periodo de restricción del uso de la Identidad, podrás recuperar tu Identidad siguiendo el proceso de Correos ID para ello.",
    tycPrivacyPoliticyTyCText39: "Correos procederá al bloqueo de aquellos usuarios que se encuentren inactivos -es decir, que no accedan a ninguno de los servicios digitales de Correos o de otras empresas que usen Correos ID como plataforma de identificación- por un plazo de 5 años.",
    tycPrivacyPoliticyTyCText40: "Cuando ya no sea necesario el tratamiento de tus datos, se suprimirán conforme a lo dispuesto en la normativa de protección de datos lo que implica su bloqueo, estando disponibles tan solo a solicitud de Jueces y tribunales, el Ministerio Fiscal o las Administraciones Públicas competentes, durante el plazo de prescripción de las acciones que pudieran derivar, para ser posteriormente eliminados. Generalmente, serán borrados una vez transcurridos 3 años.",
    tycPrivacyPoliticyTyCText41: "Nuestra normativa de protección de datos le confiere una serie de derechos en relación con el tratamiento de datos que implican nuestros servicios que podemos resumir en los siguientes:",
    tycPrivacyPoliticyTyCText42: "- Derecho de acceso: Conocer qué tipo de datos estamos tratando y las características del tratamiento que estamos llevando a cabo.",
    tycPrivacyPoliticyTyCText43: "- Derecho de rectificación: Poder solicitar la modificación de sus datos por ser éstos inexactos o no veraces.",
    tycPrivacyPoliticyTyCText44: "- Derecho de portabilidad: Poder obtener una copia en un formato interoperable de los datos que estén siendo tratados.",
    tycPrivacyPoliticyTyCText45: "- Derecho a la limitación del tratamiento en los casos recogidos en la Ley.",
    tycPrivacyPoliticyTyCText46: "- Derecho de supresión: Solicitar la supresión de sus datos cuando el tratamiento ya no resulte necesario.",
    tycPrivacyPoliticyTyCText47: "- Derecho de oposición: Este derecho le permite, por ejemplo, solicitar el cese en el envío de comunicaciones comerciales.",
    tycPrivacyPoliticyTyCText48: "- Derecho a revocar el consentimiento prestado.",
    tycPrivacyPoliticyTyCText49: "Puede ejercitar sus derechos a través de alguno de los siguientes canales:",
    tycPrivacyPoliticyTyCText50: "- Dirección Postal: Calle Conde de Peñalver, 19, 28006 Madrid (España)",
    tycPrivacyPoliticyTyCText51: "-Correo Electrónico: ",
    tycPrivacyPoliticyTyCText52: "Asimismo, todas las comunicaciones comerciales electrónicas incluirán un mecanismo de baja o similar que le permitirá, si así lo desea, no recibir nuevas comunicaciones.",
    tycPrivacyPoliticyTyCText53: "En la página web de la Agencia Española de Protección de Datos (AEPD) puede encontrar una serie de modelos que le ayudarán en el ejercicio de sus derechos. Asimismo, le informamos tiene derecho a interponer una reclamación ante la autoridad de control (en España, la AEPD) en caso de que considere infringidos sus derechos.",
    tycPrivacyPoliticyTyCText54: "Para la prestación de determinados servicios puede ser preciso que nos facilite los datos personales y de contacto de terceras personas como, p.ej., el nombre, apellidos, dirección postal, dirección de correo electrónico, teléfono de contacto y otros datos o medios equivalentes de contacto del destinatario de una comunicación o envío.",
    tycPrivacyPoliticyTyCText55: "Adicionalmente, con el objetivo de la mejor prestación del servicio, el número de teléfono o la dirección de correo electrónico podrán ser utilizados con el objetivo de informar al destinatario sobre la prestación del servicio y tratar cualquier incidencia que pudiera afectar al mismo.",
    tycPrivacyPoliticyTyCText56: "En este sentido, el cliente garantiza la veracidad y actualización de los datos del destinatario y, en su caso, asegura haber cumplido con todas aquellas obligaciones que, en materia de protección de datos, le resulten atribuibles, habiendo obtenido el consentimiento del destinatario a que Correos lleve a cabo el tratamiento expuesto en los párrafos precedentes, en caso de ser tal consentimiento necesario.",
    tycPrivacyPoliticyTyCText57: "Por su parte, en caso de que Correos acceda a datos personales que resulten necesarios para la prestación del servicio de verificación de identidad, bajo la responsabilidad de una empresa o profesional. Correos se compromete a cumplir todas las obligaciones inherentes a su posición como encargado de tratamiento y, en este sentido:",
    tycPrivacyPoliticyTyCText58: "Accederá y tratará los datos a los que tenga acceso sujeto a sus instrucciones, no utilizándolos para finalidades distintas de la prestación del servicio. Para ello, podrá contar con los servicios de otros terceros (subencargados), si resulta necesario para la prestación del servicio y operaciones del contrato y/o para su conservación y siempre que (i) el nuevo encargado quede sujeto a las mismas condiciones (instrucciones, obligaciones, medidas de seguridad…) y con los mismos requisitos formales que él, en lo referente al adecuado tratamiento de los datos personales y a la garantía de los derechos de las personas afectadas; (ii) Correos a solicitud expresa ponga a su disposición un listado en el  que se identifiquen los servicios subencargados y la identidad de los subencargados; (iii) En caso de incumplimiento por parte del subencargado, Correos continúe siendo plenamente responsable.",
    tycPrivacyPoliticyTyCText59: "Implementará las medidas de seguridad legales, técnicas y organizativas apropiadas para garantizar un nivel de seguridad adecuado al riesgo, conforme a lo expuesto en el art. 32 del RGPD y, a su solicitud, le informará de la metodología de análisis de riesgo utilizada.",
    tycPrivacyPoliticyTyCText60: "Cumplida la prestación contractual, destruirá o devolverá los datos tratados, así como también cualesquiera soportes o documentos en que consten datos objeto del tratamiento, sin perjuicio de la posibilidad de conservar estos datos, debidamente bloqueados, en los términos previstos por nuestra normativa de protección de datos.",
    tycPrivacyPoliticyTyCText61: "Asegurará el cumplimiento del resto de obligaciones recogidas en la normativa de protección de datos.",
  
};
  