import React from "react";
import { Router, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import PublicRedirectRoute from "./PublicRedirectRoute";
import { PublicPage } from "../../pages/PublicPage/PublicPage";
import { TyCPage } from "../../pages/TyC/TyCPage";
import { TyCPrivacyPage } from "../../pages/TyCPrivacy/TyCPrivacyPage";
import { RedirectPage } from "../../pages/RedirectPage/RedirectPage";
import { MainPage } from "../../pages/MainPage/MainPage";
import { SubscriptionPage } from "../../pages/SubscriptionPage/SubscriptionPage";
import { PendingDataPage } from "../../pages/PendingDataPage/PendingDataPage";
import { UnifyAccountsPage } from "../../pages/UnifyAccountsPage/UnifyAccountsPage";
import history from "../../services/history.js";

export function Routes() {
  return (
    <Router history={history}>
      <Switch>
        {/* Públicas */}
        <PublicRoute exact path="/" component={PublicPage} />
        <PublicRoute exact path="/Login" component={PublicPage} />
        <PublicRoute exact path="/Registry" component={PublicPage} />
        <PublicRoute exact path="/ResetPassword" component={PublicPage} />
        <PublicRoute exact path="/TyC/:codePage" component={TyCPage} />
        <PublicRoute exact path="/Tcpp/" component={TyCPrivacyPage} />
        <PublicRedirectRoute exact path="/RedirectPage" component={RedirectPage} />
        {/* Privadas */}
        <PrivateRoute exact path="/Index" component={MainPage} />
        <PrivateRoute exact path="/Index/:idPage" component={MainPage} />
        <PrivateRoute exact path="/Index/:idEnterprise/:idPage" component={MainPage} />
        <PrivateRoute exact path="/Registry/:idPage" component={SubscriptionPage} />
        <PrivateRoute exact path="/PendingData" component={PendingDataPage} />
        <PrivateRoute exact path="/UnifyAccounts" component={UnifyAccountsPage} />
      </Switch>
    </Router>
  );
}
